import React, { useContext, useState, useEffect,useRef } from "react";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/AppContext";
import swal from "sweetalert";
import LoaderCenter from "../../sharedComponent/LoaderCenter";
import "./AllPropertyHousing.css";
import { useParams, Link, useNavigate } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faBuilding,
  faUsers,
  faBed,
  faBath,
  faMoneyBill,
  faDollarSign,
  faUser,
  faClock ,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

import {
  faMap,
  faCity,
  faHome,
  faTag,
} from "@fortawesome/free-solid-svg-icons";

const Manage = () => {
  const baseUrl = window.location.origin;
  const { customerData } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [houseDetails, setHouseDetails] = useState(null);
  const [locationData, setLocationData] = useState({});
  const placeInputRef = useRef(null);
  let [marker, setMarker] = useState(null)
const [circle, setCircle] = useState(null)
const [place, setPlace] = useState({
  center: { lat: 19.8762, lng: 75.3433 },
});

  const { id } = useParams();
  useEffect(() => {
    getHouseDetails();
  }, [id]);



  const getHouseDetails = async () => {
    setLoading(true);

    console.log("check id", id);

    await fetch(`${ApiConfig.BASE_NEW_URL}posts/view/${id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setLoading(false);
        console.log("housing_properties", data);

        setHouseDetails(data);
      })
      .catch((error) => {
        setLoading(false);
        console.error("There was a problem fetching the data:", error);
      });
  };

    

  // useEffect( () => {
  //   initPlaceAPI();
  // }, []);
  const initPlaceAPI = async () => {
    if (
      window.google &&
      window.google.maps &&
      window.google.maps.places &&
      window.google.maps.places.Autocomplete
    ) {
      let autocomplete = new window.google.maps.places.Autocomplete(
        placeInputRef.current
      );
      new window.google.maps.event.addListener(
        autocomplete,
        "place_changed",
        function () {
          let place = autocomplete.getPlace();

          //  setEventData({
          //   ...eventData,
          //   address: place.name,
          //   center: {
          //     lat: place.geometry.location.lat(),
          //     lng: place.geometry.location.lng(),
          //   },
          // });

          // setPlace({
          //   center: {
          //     lat: place.geometry.location.lat(),
          //     lng: place.geometry.location.lng(),
          //   },
          //   address: place.formatted_address,
          // });
        }
      );
    }
  };

  useEffect(() => {
   
  }, [place])
  
   useEffect(() => {
    if (locationData.maps && locationData.map) {
      const { map, maps } = locationData;
      // or else call that isApiLoaded function and pass-on these arguments
      renderMarkers(map, maps);
    }
  }, [locationData, place]);
    const getLocation = (lat, lng) => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBxqcBX2r8cybljkDigXBgyOByjeQOmqr0`,
      {
        method: "GET", // or 'PUT'
        headers: {
          // "Content-Type": "application/json",
          // 'Access-Control-Allow-Credentials': "*"
        },
        //body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data.results[1].formatted_address);
        setPlace({
          center: { lat, lng },
          address: data.results[1].formatted_address,
        });
        
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const renderMarkers = (map, maps) => {

    marker && marker.setMap(null);
    circle && circle.setMap(null);
    const cityCircle = new maps.Circle({
      strokeColor: "#00b4bd",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#00b4bd",
      fillOpacity: 0.35,
      map,
      center: place.center,
      // radius: { ...place.center.radius },
      radius: place.radius ? Number(place.radius) : 1000,
    });
    marker = new maps.Marker({
      draggable: true,
      position: { ...place.center },
      map,
    });
    maps.event.addListener(marker, "dragend", async function (event) {
      getLocation(event.latLng.lat(), event.latLng.lng());
    });
    setMarker(marker);
    setCircle(cityCircle);

    return { marker, cityCircle };
  };


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDeleteClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const navigate = useNavigate();

  const handleDeleteConfirm = async () => {
    try {
      const response = await fetch(
        `${ApiConfig.BASE_NEW_URL}posts/delete/${id}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
        swal({
          title: "Deletion unsuccesfull",
          icon: "error",
        });
      }

      await response.json();
      setIsModalOpen(false);
      swal({ title: "Deleted successfully", icon: "success" });
      navigate("/house_listing/all");
    } catch (error) {
      console.error("Failed to delete the post", error);
      swal({
        title: "Error Occurred",
        icon: "error",
      });
    }
  };

  const handleDelete = () => {
    // Handle delete functionality here
    swal("Deleted succesfully");
  };

  const handleInterested = () => {
    // Handle interested functionality here
    swal("Interested functionality is not implemented yet.");
  };

  const newYorkLat = 40.7128;
  const newYorkLong = -74.0060;
  const mapSrc = `https://maps.google.com/maps?q=${newYorkLat},${newYorkLong}&hl=es;z=14&output=embed`;


  // const mapSrc = `https://maps.google.com/maps?q=${houseDetails.housingDetails.lat},${houseDetails.housingDetails.long}&hl=es;z=14&output=embed`;


  const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) => 
            prevIndex === 0 ? houseDetails.images.length - 1 : prevIndex - 1
        );
    };

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) => 
            prevIndex === houseDetails.images.length - 1 ? 0 : prevIndex + 1
        );
    };

    const handleThumbnailClick = (index) => {
        setCurrentIndex(index);
    };

  return (
    <div>
      {/* <div style={{ display: 'flex', flexDirection: 'row', margin: '10px', borderRadius: '15px', overflow: 'hidden', position: 'relative' }}> */}
      <div>
        {loading ? (
          <LoaderCenter />
        ) : houseDetails ? (
          <div
            style={{
              display: "flex",
              position: "relative",
              width: "100%",
              padding: "20px",
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "1px",
                right: "20px",
                display: "flex",
                gap: "10px",
              }}
            >
              <Link
                style={{ padding: "5px" }}
                to={`/house_listing/edit/${houseDetails._id}`}
              >
                Edit
              </Link>
     <button className="btns toolbar" onClick={handleDeleteClick} >
     <FontAwesomeIcon icon={faTrashAlt} style={{ color: "white",marginTop: "2px",width:"25px" }} />
              </button>
            </div>

            <div
              style={{
                marginTop: "10px",
                flex: 2,
                marginRight: "20px",
                borderRadius: "10px",
                padding: "20px",
                backgroundColor: "white",
              }}
            >
              <h3>{houseDetails.title}</h3>

{houseDetails.images.length > 0 && (
   <div className="slider-container" style={{ marginTop: '30px' }}>
   <div className="main-image">
     <button className="nav prev" onClick={handlePrevClick} >
       &#10094;
     </button>
     <img
   src={houseDetails.images[currentIndex]}
   alt="current slide"
   style={{width:"100%", borderRadius:"10px" }}
 />
     <button className="nav next" onClick={handleNextClick}>
       &#10095;
     </button>
   </div>
   <div className="thumbnail-container" style={{ display: 'flex', justifyContent: 'center'}}>
     {houseDetails.images.slice(0, 3).map((image, index) => (
       <img
         key={index}
         src={image}
         alt={`thumbnail ${index}`}
         className={`thumbnail ${index === currentIndex ? 'active' : ''}`}
         style={{
           width: '70px',
           height: '60px',
           objectFit: 'cover',
           borderRadius: '5px',
           margin: '0 2px',
           cursor: 'pointer',
           border: index === currentIndex ? '2px solid blue' : '2px solid transparent',
         }}
         onClick={() => handleThumbnailClick(index)}
       />
     ))}
   </div>
 </div>
)}
             

              <h5 style={{ marginTop: "25px" }}>Description</h5>
              <p style={{ marginTop: "10px",minHeight:"70px" }}>{houseDetails.description}</p>
              <h5 style={{ marginTop: "20px" }}>Terms</h5>
              <p style={{ marginTop: "10px" }}>
                {houseDetails.housingDetails?.term}
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              >
                <div style={{ textAlign: "center", marginRight: "10px" }}>
                  <h5>Bills Included</h5>
                  {houseDetails.housingDetails?.billsIncluded ? (
                    <div
                      style={{
                        border: "1px solid",
                        padding: "5px 10px",
                        marginTop: "5px",
                        borderRadius: "5px",
                        color: "green",
                      }}
                    >
                      Included
                    </div>
                  ) : (
                    <div
                      style={{
                        border: "1px solid",
                        padding: "5px 10px",
                        marginTop: "5px",
                        borderRadius: "5px",
                        color: "red",
                      }}
                    >
                      Not Included
                    </div>
                  )}
                </div>
                <div style={{ textAlign: "center", marginRight: "10px" }}>
                  <h5>Smoke Allowed</h5>
                  {houseDetails.housingDetails?.smokedAllowed ? (
                    <div
                      style={{
                        border: "1px solid",
                        marginTop: "5px",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        color: "green",
                      }}
                    >
                      Allowed
                    </div>
                  ) : (
                    <div
                      style={{
                        border: "1px solid",
                        padding: "5px 10px",
                        marginTop: "5px",
                        borderRadius: "5px",
                        color: "red",
                      }}
                    >
                      Not Allowed
                    </div>
                  )}
                </div>
                <div style={{ textAlign: "center", marginRight: "0" }}>
                  <h5>Pets Allowed</h5>
                  {houseDetails.housingDetails?.petsAllowed ? (
                    <div
                      style={{
                        border: "1px solid",
                        padding: "5px 10px",
                        marginTop: "5px",
                        borderRadius: "5px",
                        color: "green",
                      }}
                    >
                      Allowed
                    </div>
                  ) : (
                    <div
                      style={{
                        border: "1px solid",
                        padding: "5px 10px",
                        marginTop: "5px",
                        borderRadius: "5px",
                        color: "red",
                      }}
                    >
                      Not Allowed
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{
                flex: 1,
                marginTop: "30px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <div
                style={{
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  borderRadius: "10px",
                  padding: "20px",
                  backgroundColor: "white",
                }}
              >
                <div
                  // className="mapWrapper"
                  style={{
                    height: "50vh",
                    border: "1px solid #e1e1e1",
                    borderRadius: "10px",
                    overflow: "hidden",
                  }}
                >
                  {/* <GoogleMapReact
              bootstrapURLKeys={{
                key: 'AIzaSyBxqcBX2r8cybljkDigXBgyOByjeQOmqr0',
              }}
              defaultZoom={11}
              onGoogleApiLoaded={({ map, maps }) => {
                renderMarkers({ map, maps });
                console.log({ map, maps });
              }}
            /> */}
                         
                              <iframe
      className="actAsDiv"
      frameBorder="0"
      scrolling="no"
      marginHeight="0"
      marginWidth="0"
      src={mapSrc}
      style={{ border: 0 , width: '100%', height: '100%' }}
      allowFullScreen=""
      aria-hidden="false"
      tabIndex="0"
    ></iframe>
                </div>
              </div>
              <div
                style={{
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  borderRadius: "10px",
                  padding: "20px",
                  backgroundColor: "white",
                }}
              >
                <div style={{ flex: 1, marginTop: "10px", marginLeft: "10px" }}>
                  <div style={{ display: "flex" }}>
                    <FontAwesomeIcon icon={faCalendarAlt} style={{ color: "black",marginTop: "2px"  }} />
                    <div style={{marginLeft:"10px"}}>
                      <p>Date Posted</p>
                      <h6 style={{ marginTop: "10px" }}>
                        {formatDate(houseDetails.postedDate)}
                      </h6>
                    </div>
                  </div>

                  <div style={{ display: "flex",marginTop: "25px"  }}>
                    <FontAwesomeIcon icon={faClock} style={{ color: "black",marginTop: "2px" }} />
                    <div style={{marginLeft:"10px"}}>
                    <p>Property Type</p>
                  <h6 style={{ marginTop: "10px" }}>
                    {houseDetails.housingDetails?.propertyType}
                  </h6>
                    </div>
                  </div>

                  <div style={{ display: "flex" ,marginTop: "25px" }}>
                    <FontAwesomeIcon icon={faDollarSign} style={{ color: "black",marginTop: "2px" }} />
                    <div style={{marginLeft:"10px"}}>
                    <p >Monthly Rent</p>
                  <h6 style={{ marginTop: "10px" }}>
                    {houseDetails.housingDetails?.monthlyRent.value}
                  </h6>
                    </div>
                  </div>

                  <div style={{ display: "flex",marginTop: "25px"  }}>
                  <FontAwesomeIcon icon={faClock} style={{ color: "black",marginTop: "2px" }} />                    <div style={{marginLeft:"10px"}}>
                    <p>Availability</p>
                  <h6 style={{ marginTop: "10px" }}>
                    {houseDetails.housingDetails?.availability}
                  </h6>
                    </div>
                  </div>

                  <div style={{ display: "flex" ,marginTop: "25px" }}>
                    <FontAwesomeIcon icon={faUser} style={{ color: "black" ,marginTop: "2px"}} />
                    <div style={{marginLeft:"10px"}}>        
                  <p>Sharing Type</p>
                  <h6 style={{ marginTop: "10px" }}>
                    {houseDetails.housingDetails?.sharingType}
                  </h6>
                    </div>
                  </div>

                  <div style={{ display: "flex",marginTop: "25px" }}>
                    <FontAwesomeIcon icon={faBuilding} style={{ color: "black",marginTop: "2px" }} />
                    <div style={{marginLeft:"10px"}}>        
                    <p >No of Rooms</p>
                  <h6 style={{ marginTop: "10px" }}>
                    {houseDetails.housingDetails?.rooms}
                  </h6>
                    </div>
                  </div>

                  {/* <div style={{ display: "flex",marginTop: "25px" }}>
                    <FontAwesomeIcon icon={faBuilding} style={{ color: "black" ,marginTop: "2px"}} />
                    <div style={{marginLeft:"10px" }}>        
                    <p >No of Bathrooms</p>
                  <h6 style={{ marginTop: "10px" }}>
                    {houseDetails.housingDetails.bathrooms}
                  </h6>
                    </div>
                  </div> */}
                  
                </div>
              </div>
              <div
                style={{
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  borderRadius: "10px",
                  padding: "20px",
                  backgroundColor: "white",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    marginTop: "10px",
                    marginLeft: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "60px",
                      width: "60px",
                      backgroundColor: "#FFA51B",
                      borderRadius: "10px",
                      padding: "5px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faUser}
                      size="2x"
                      style={{ color: "black" }}
                    />
                  </div>
                  <div style={{ flex: 3, marginLeft: "15px" }}>
                    <h6>Longitude: {houseDetails.housingDetails?.long}</h6>
                    <h6 style={{ marginTop: "15px" }}>
                      Latitude: {houseDetails.housingDetails?.lat}
                    </h6>
                    <h6 style={{ marginTop: "15px" }}>
                      Contact: {houseDetails.contactInfo}
                    </h6>
                    <h6 style={{ marginTop: "15px" }}>
                      Address: {houseDetails.housingDetails?.address1}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p
            className="error-message"
            style={{
              textAlign: "center",
              padding: "20px",
              backgroundColor: "#fff",
              borderRadius: "15px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            Oops! No details found for this property.
          </p>
        )}
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "10px",
              width: "350px",
              height: "150px",
              textAlign: "center",
            }}
          >
            <h4 style={{ marginBottom: "20px" }}>Delete this post?</h4>
            <button
              onClick={handleModalClose}
              style={{
                padding: "10px 20px",
                marginTop: "10px",
                marginRight: "10px",
                backgroundColor: "#ccc",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Cancel
            </button>
            <button 
              onClick={handleDeleteConfirm}
              style={{
                padding: "10px 20px",
                backgroundColor: "#f44336",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Delete
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Manage;

import React, { useContext, useState, useEffect } from 'react'
import { Link, useLocation, NavLink } from 'react-router-dom'
import { AppContext } from '../../context/AppContext'
import Sidebar from '../../components/Sidebar'
import Header from '../../components/Header'
import thumbImg from '../../assets/images/house01.png'
import location_icon from '../../assets/images/location_icon.svg'
import gray_location_icon from '../../assets/images/gray_location_icon.svg'
import green_arrow_icon from '../../assets/images/green_arrow_icon.svg'
import fillter_icon from '../../assets/images/fillter_icon.svg'
import Tabs from './Tabs'
import { simpleGetCallWithErrorResponse } from '../../api/ApiServices'
import LoaderCenter from '../../sharedComponent/LoaderCenter'
import ApiConfig from '../../api/ApiConfig'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import swal from 'sweetalert'

const DiningVoucher = () => {
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }
  const [vouchers, setVouchers] = useState([])
  useEffect(() => {
    getVouchers()
  }, [])

  const getVouchers = async () => {
    const data = await simpleGetCallWithErrorResponse(ApiConfig.DINING_VOUCHERS)
    setVouchers(data?.json?.dining)
    console.log(data?.json?.dining)
  }

  return (
    <div
      className="row"
      style={{
        rowGap: '20px',
      }}
    >
      {!vouchers.length ? (
        <p
          style={{
            fontSize: '25px',
            margin: 'auto',
          }}
          className=" text-center justify-content-center"
        >
          Data not found
        </p>
      ) : (
        <>
          {vouchers.map((voucher, index) => {
            return (
              <div className="col-12 col-lg-6 col-xl-4 col-xxl-3">
                <div
                  className="offersThumbWrapper"
                  style={{
                    height: '249px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    margin: '0',
                  }}
                >
                  <h1>{voucher.offer_name}</h1>
                  <div className="couponWrapper">
                    <div className="codeTitle">
                      <h2>CODE</h2>
                    </div>
                    <div className="codeapplied d-flex justify-content-between">
                      <h1>{voucher.code}</h1>
                      <CopyToClipboard
                        text={voucher.code}
                        onCopy={() => swal('Copied to Clipboard')}
                      >
                        <Link to="#">
                          <i class="fa-solid fa-copy"></i>
                        </Link>
                      </CopyToClipboard>
                    </div>
                  </div>
                  <div className="swaipWrapper text-truncate">
                    <Link
                      to=""
                      style={{
                        padding: '0 10px',
                      }}
                    >
                      {voucher.link}
                    </Link>

                    <img
                      src={green_arrow_icon}
                      alt=""
                      onClick={() => openInNewTab(voucher.link)}
                    />
                  </div>
                </div>
              </div>
            )
          })}
        </>
      )}
    </div>
  )
}

export default DiningVoucher

import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import scr from "../../assets/images/search.svg";
import fillter_icon from "../../assets/images/fillter_icon.svg";
import { AppContext } from "../../context/AppContext";

import search_icon from "../../assets/images/search_icon.svg";
import location from "../../assets/images/location.svg";
import ApiConfig from "../../api/ApiConfig";
import { postMultipartWithAuthCallWithErrorResponse } from "../../api/ApiServices";

function HousingTabsHeader() {
  const [propertyfilterType, setPropertyFilterType] = useState({
    type: "",
    property_type: "",
    gender: "",
    term: "",
    availability: "",
    bills_included: "",
    smokers_allowed: "",
    minimum: "",
    maximum: "",
  });
  const currentRoute = useLocation().pathname;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { customerData } = useContext(AppContext);

  const propertyFilter = (e) => {
 

    setLoading(true);

    let datails = JSON.stringify({
      id: customerData.id,
      type: propertyfilterType.type,

      property_type: propertyfilterType.property_type,

      gender: propertyfilterType.gender,
      term: propertyfilterType.term,
      availability: propertyfilterType.availability,
      bills_included: propertyfilterType.bills_included,
      smokers_allowed: propertyfilterType.smokers_allowed,
      minimum: propertyfilterType.minimum,
      maximum: propertyfilterType.maximum,
    });

    const formData = new FormData();
;
    formData.append("details", datails);
  

    postMultipartWithAuthCallWithErrorResponse(
      ApiConfig.UNIVERSR_HOUSING_ALL_PROPTIE_FILTER,
      formData
    )
      .then((res) => {
        setLoading(false);
        //  swal(" Add  Property Successfully");
        navigate("/house_listing/all");
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="globalPages_tabWrapper">
        <div
          className="gb_tabs"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="first-div">
            <Link
              style={{
                height: "40px",
                width: "142px",
                padding: "10px",
                fontWeight: "600",
                fontSize: "14px",
                fontFamily: "Nunito",
                // color: "#FFFFFF",
              }}
              to="/house_listing"
              className={
                currentRoute === "/house_listing" ? "ct-all-forms" : ""
              }
            >
              All Properties
            </Link>{" "}
          </div>
          <div>
            <Link
              to="/house_manage"
              className={currentRoute === "/house_manage" ? "ct-all-forms" : ""}
              style={{
                height: "40px",
                width: "142px",
                padding: "10px",
                fontWeight: "600",
                fontSize: "14px",
                fontFamily: "Nunito",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Manages
            </Link>{" "}
          </div>
          <div>
            <Link
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal2"
              style={{ border: "none", margin: "0", padding: "0px 0px" }}
            >
              <img src={fillter_icon} alt="" />
            </Link>
          </div>
          <div>
            <Link
              to="#"
              style={{
                border: "1px solid #00B4BD",
                borderRadius: "21px",
                fontSize: "14px",
                color: "#00B4BD",
                height: "40px",
                width: "200px",
                padding: "10px",
                fontWeight: "600",
                fontSize: "14px",
                fontFamily: "Nunito",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0",
              }}
            >
              <img src={location} style={{ paddingRight: "15px" }} />
              Las Vegas{" "}
              <span
                style={{
                  padding: "6px",
                }}
              >
                .
              </span>
              <span>20 kms</span>
            </Link>
          </div>
          <div
            className="forumsSearchWrapper"
            style={{
              width: "273px",
              height: "40px",
              borderRadius: "25px",
              position: "relative",
              border: "1px solid #E1E1E1",
            }}
          >
            <input
              type="text"
              id="hif"
              placeholder="Search property"
              style={{
                display: "block",
                height: "100%",
                width: "100%",
                backgroundColor: "transparent",
                color: "#BBBBBB",
                fontWeight: "600",
                fontSize: "16px",
              }}
            />
            <button style={{ position: "absolute", top: "8px", right: "20px" }}>
              <img src={search_icon} alt="" />
            </button>
          </div>
          <div
            style={{
              width: "165px",
            }}
            className="last-div"
          >
            <Link
              className=""
              to="/create_property"
              style={{
                backgroundColor: "#00FFDC",
                color: "black",
                border: "none",
                fontWeight: "600",
                fontSize: "14px",
                fontFamily: "Nunito",
              }}
            >
              Create Property
            </Link>{" "}
          </div>
        </div>
      </div>
     
    </>
  );
}

export default HousingTabsHeader;

// housing modal

import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import profile_picture from "../../assets/images/profile_picture.jpg";
import createselectAvatar from "../../assets/images/createselectAvatar.png";
import createadd_avatar from "../../assets/images/createadd_avatar.png";
import down from "../../assets/images/drop_down.svg";
import "./Group.scss";

function EditGropu() {
  const { sidebar, setSidebar } = useContext(AppContext);

  const [showElement, setShowElement] = React.useState(true);
  useEffect(() => {
    setTimeout(function () {
      setShowElement(false);
    }, 5000);
  }, []);
  return (
    <>
      <Sidebar />

      {/* <Header /> */}
      <main id="cxMain" className={sidebar ? "cx-main cx-active" : "cx-main"}>
        <section
          className="content-main"
          style={{
            borderRadius: "20px",
          }}
        >
          <div className="row">
            <div className="col-md-12">
              <div
                className="create_screenWrapper border-radius"
                style={{
                  padding: "20px",
                  borderRadius: "20px",
                }}
              >
                <div class="modal-header">
                  <h5
                    style={{
                      fontSize: "20px",
                      color: "#1D1D25",
                      fontWeight: "bold",
                    }}
                    class="modal-title"
                    id="exampleModalLabel"
                  >
                    Edit Group
                  </h5>
                  {/* <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button> */}
                  <Link to="/groups">
                    <i class="fa-solid fa-xmark"></i>
                  </Link>
                </div>

                <div className="profile_modal_contentWrapper mt-3">
                  <div className="row">
                    <div className="col-12 col-lg-2">
                      <div className="uploadPPWrapper d-flex flex-column align-items-center">
                        <div className="uploadPP_imgHolder d-flex justify-content-center">
                          <img
                            src={profile_picture}
                            alt=""
                            style={{
                              height: "129px",
                              width: "121px",
                              margin: "0",
                              borderRadius: "33px",
                              border: "1px solid #00B4BD",
                            }}
                          />
                        </div>
                        <div className="uploadPP_input02">
                          <div
                            class="form-group"
                            style={{ width: "121px", borderRadius: "20px" }}
                          >
                            <input
                              type="file"
                              name="file"
                              id="file"
                              class="input-file"
                            />
                            <label
                              for="file"
                              class="btn btn-tertiary js-labelFile mt-3"
                            >
                              {/* <i class="icon fa fa-check"></i> */}
                              <span class="js-fileName">Update</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        padding: "0px 20px 2px 20px",
                      }}
                      className="col-12 col-lg-10  profile_form"
                    >
                      <div style={{ marginTop: "-20px" }} className="row">
                        <div
                          className="col-12 col-sm-6"
                          style={{ marginTop: "20px" }}
                        >
                          <div className="profile-input-details">
                            <label
                              style={{
                                fontFamily: "Source Sans Pro, sans-serif",
                                fontSize: "14px",
                                lineHeight: "19px",
                                fontStyle: "normal",
                                color: "#707070",
                              }}
                              class="form-label"
                            >
                              Group Name
                            </label>
                            <input
                              style={{
                                color: "#1D1D25",
                                fontSize: "16px ",
                                fontWeight: "600",
                              }}
                              type="text"
                              class="form-control"
                              placeholder="Enter group name"
                            />
                          </div>
                        </div>

                        {/* <div
                          className="col-12  col-sm-6"
                          style={{ marginTop: "20px" }}
                        >
                          <div
                            class="input-group mb-3"
                            className="profile-input-details-1"
                          >
                            <label
                              style={{
                                fontFamily: "Source Sans Pro, sans-serif",
                                fontSize: "14px",
                                lineHeight: "19px",
                                fontStyle: "normal",
                                color: "#707070",
                              }}
                              class="form-label"
                            >
                              Group Type
                            </label>
                            <select
                              class="form-select"
                              id="inputGroupSelect01"
                              style={{
                                backgroundColor: "#FBF8F8",
                                color: "#1D1D25",
                                fontSize: "16px ",
                                fontWeight: "600",
                              }}
                            >
                              <option selected>Work</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                          </div>
                        </div> */}
                      </div>

                      <div className="row mt-3">
                        <div
                          className="col-md-12"
                          style={{ marginTop: "10px" }}
                        >
                          <div className="c_selectUserWrapper">
                            <h1
                              style={{
                                color: "#1D1D25",
                                fontSize: "16px",
                                fontWeight: "600",
                              }}
                            >
                              Selected Friends{" "}
                              <span
                                style={{
                                  marginLeft: "20px",
                                  color: "#00B4BD",
                                  fontSize: "16px",
                                  fontWeight: "bold",
                                }}
                              >
                                02
                              </span>{" "}
                            </h1>
                            <ul>
                              <li>
                                <div className="cselect_item">
                                  <div className="ci_avatar">
                                    <img
                                      src={createselectAvatar}
                                      style={{
                                        borderRadius: "22px",
                                        border: "1px solid #40F5CD",
                                      }}
                                      alt=""
                                    />
                                    <button type="button" aria-label="Close">
                                      <i class="fa-solid fa-xmark"></i>
                                    </button>
                                  </div>
                                  <h1
                                    style={{
                                      // font: "normal normal normal 14px/16px SF Pro Text",
                                      fontFamily: "Source Sans Pro, sans-serif",
                                      fontSize: "14px",
                                      lineHeight: "16px",
                                      fontStyle: "normal",
                                      letterSpacing: "0px",
                                      fontWeight: "normal",

                                      color: "#21212C",
                                    }}
                                  >
                                    Joseph
                                  </h1>
                                </div>
                              </li>

                              <li>
                                <div className="cselect_item">
                                  <div className="ci_avatar">
                                    <img
                                      src={createselectAvatar}
                                      style={{
                                        borderRadius: "22px",
                                        border: "1px solid #40F5CD",
                                      }}
                                      alt=""
                                    />
                                    <button type="button" aria-label="Close">
                                      <i class="fa-solid fa-xmark"></i>
                                    </button>
                                  </div>
                                  <h1
                                    style={{
                                      fontFamily: "Source Sans Pro, sans-serif",
                                      fontSize: "14px",
                                      lineHeight: "16px",
                                      fontStyle: "normal",
                                      fontWeight: "normal",
                                      color: "#21212C",
                                      letterSpacing: "0px",
                                      opacity: "1",
                                    }}
                                  >
                                    Joseph
                                  </h1>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div
                          className="col-md-12 c_addUserWrapper-model "
                          style={{ marginTop: "px" }}
                        >
                          <div className="c_addUserWrapper">
                            <h1
                              style={{
                                // font: "normal normal 600 16px/22px Nunito",
                                color: "#1D1D25",
                                fontSize: "16px",
                                fontWeight: "600",
                              }}
                            >
                              Add Friends
                            </h1>
                            <div className="row">
                              <div className="col-12 col-md-6 col-xl-4">
                                <div className="ip-label2">
                                  <input type="checkbox" id="check1" />
                                  <label htmlFor="check1">
                                    <img
                                      className="me-3 "
                                      src={createadd_avatar}
                                      alt=""
                                      style={{
                                        height: "50px",
                                        width: "50px",
                                        borderRadius: "5px",
                                      }}
                                    />
                                    Fred Cooper
                                  </label>
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-xl-4">
                                <div className="ip-label2">
                                  <input type="checkbox" id="check2" />
                                  <label htmlFor="check2">
                                    <img
                                      className="me-3 "
                                      src={createadd_avatar}
                                      alt=""
                                      style={{
                                        height: "50px",
                                        width: "50px",
                                        borderRadius: "5px",
                                      }}
                                    />
                                    Fred Cooper
                                  </label>
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-xl-4">
                                <div className="ip-label2">
                                  <input type="checkbox" id="check3" />
                                  <label htmlFor="check3">
                                    <img
                                      className="me-3 "
                                      src={createadd_avatar}
                                      alt=""
                                      style={{
                                        height: "50px",
                                        width: "50px",
                                        borderRadius: "5px",
                                      }}
                                    />
                                    Fred Cooper
                                  </label>
                                </div>
                              </div>

                              <div className="col-12 col-md-6 col-xl-4">
                                <div className="ip-label2">
                                  <input type="checkbox" id="check4" />
                                  <label htmlFor="check4">
                                    <img
                                      className="me-3 "
                                      src={createadd_avatar}
                                      alt=""
                                      style={{
                                        height: "50px",
                                        width: "50px",
                                        borderRadius: "5px",
                                      }}
                                    />
                                    Fred Cooper
                                  </label>
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-xl-4">
                                <div className="ip-label2">
                                  <input type="checkbox" id="check5" />
                                  <label htmlFor="check5">
                                    <img
                                      className="me-3 "
                                      src={createadd_avatar}
                                      alt=""
                                      style={{
                                        height: "50px",
                                        width: "50px",
                                        borderRadius: "5px",
                                      }}
                                    />
                                    Fred Cooper
                                  </label>
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-xl-4">
                                <div className="ip-label2">
                                  <input type="checkbox" id="check6" />
                                  <label htmlFor="check6">
                                    <img
                                      className="me-3 "
                                      src={createadd_avatar}
                                      alt=""
                                      style={{
                                        height: "50px",
                                        width: "50px",
                                        borderRadius: "5px",
                                      }}
                                    />
                                    Fred Cooper
                                  </label>
                                </div>
                              </div>
                            </div>
                            {/* <div className="row">
                              <div className="col-12 col-md-6">
                                <div className="caddUserItemWrapper">
                                  <div className="caddUser_avatarItem">
                                    <img src={<div className="col-12 col-md-6">
                                <div className="caddUserItemWrapper">
                                  <div className="caddUser_avatarItem">
                                    <img src={createadd_avatar} alt="" />
                                  </div>

                                  <div className="caddUserChackbox">
                                    <div className="checkboxWrapper">
                                      <div className="checkboxWrapper_holder">
                                        <label
                                          style={{
                                            // font: "normal normal normal 14px/16px SF Pro Text",
                                            color: "#21212C",
                                            fontSize: "14px",
                                            fontWeight: "normal",
                                          }}
                                        >
                                          Joseph Fernandez
                                          <input
                                            type="checkbox"
                                            class="default__check"
                                          />
                                          <span class="custom__check"></span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-12 col-md-6">
                                <div className="caddUserItemWrapper">
                                  <div className="caddUser_avatarItem">
                                    <img src={createadd_avatar} alt="" />
                                  </div>

                                  <div className="caddUserChackbox">
                                    <div className="checkboxWrapper">
                                      <div className="checkboxWrapper_holder">
                                        <label
                                          style={{
                                            color: "#21212C",
                                            fontSize: "14px",
                                            fontWeight: "normal",
                                          }}
                                        >
                                          Joseph Fernandez
                                          <input
                                            type="checkbox"
                                            class="default__check"
                                          />
                                          <span class="custom__check"></span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>} alt="" />
                                  </div>

                                  <div className="caddUserChackbox">
                                    <div className="checkboxWrapper">
                                      <div className="checkboxWrapper_holder">
                                        <label
                                          style={{
                                            // font: "normal normal normal 14px/16px SF Pro Text",
                                            color: "#21212C",
                                            fontSize: "14px",
                                            fontWeight: "normal",
                                          }}
                                        >
                                          Joseph Fernandez
                                          <input
                                            type="checkbox"
                                            class="default__check"
                                          />
                                          <span class="custom__check"></span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-12 col-md-6">
                                <div className="caddUserItemWrapper">
                                  <div className="caddUser_avatarItem">
                                    <img src={createadd_avatar} alt="" />
                                  </div>

                                  <div className="caddUserChackbox">
                                    <div className="checkboxWrapper">
                                      <div className="checkboxWrapper_holder">
                                        <label
                                          style={{
                                            color: "#21212C",
                                            fontSize: "14px",
                                            fontWeight: "normal",
                                          }}
                                        >
                                          Joseph Fernandez
                                          <input
                                            type="checkbox"
                                            class="default__check"
                                          />
                                          <span class="custom__check"></span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-12 col-md-6">
                                <div className="caddUserItemWrapper">
                                  <div className="caddUser_avatarItem">
                                    <img src={createadd_avatar} alt="" />
                                  </div>

                                  <div className="caddUserChackbox">
                                    <div className="checkboxWrapper">
                                      <div className="checkboxWrapper_holder">
                                        <label
                                          style={{
                                            color: "#21212C",
                                            fontSize: "14px",
                                            fontWeight: "normal",
                                          }}
                                        >
                                          Joseph Fernandez
                                          <input
                                            type="checkbox"
                                            class="default__check"
                                          />
                                          <span class="custom__check"></span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-12 col-md-6">
                                <div className="caddUserItemWrapper">
                                  <div className="caddUser_avatarItem">
                                    <img src={createadd_avatar} alt="" />
                                  </div>

                                  <div className="caddUserChackbox">
                                    <div className="checkboxWrapper">
                                      <div className="checkboxWrapper_holder">
                                        <label
                                          style={{
                                            color: "#21212C",
                                            fontSize: "14px",
                                            fontWeight: "normal",
                                          }}
                                        >
                                          Joseph Fernandez
                                          <input
                                            type="checkbox"
                                            class="default__check"
                                          />
                                          <span class="custom__check"></span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-12 col-md-6">
                                <div className="caddUserItemWrapper">
                                  <div className="caddUser_avatarItem">
                                    <img src={createadd_avatar} alt="" />
                                  </div>

                                  <div className="caddUserChackbox">
                                    <div className="checkboxWrapper">
                                      <div className="checkboxWrapper_holder">
                                        <label
                                          style={{
                                            color: "#21212C",
                                            fontSize: "14px",
                                            fontWeight: "normal",
                                          }}
                                        >
                                          Joseph Fernandez
                                          <input
                                            type="checkbox"
                                            class="default__check"
                                          />
                                          <span class="custom__check"></span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-12 col-md-6">
                                <div className="caddUserItemWrapper">
                                  <div className="caddUser_avatarItem">
                                    <img src={createadd_avatar} alt="" />
                                  </div>

                                  <div className="caddUserChackbox">
                                    <div className="checkboxWrapper">
                                      <div className="checkboxWrapper_holder">
                                        <label
                                          style={{
                                            color: "#21212C",
                                            fontSize: "14px",
                                            fontWeight: "normal",
                                          }}
                                        >
                                          Joseph Fernandez
                                          <input
                                            type="checkbox"
                                            class="default__check"
                                          />
                                          <span class="custom__check"></span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="create_screenControls"
                  style={{ marginBottom: "30px", textAlign: "center" }}
                >
                  <Link
                    to="/groups"
                    style={{
                      width: "140px",
                      padding: "13px",
                      backgroundColor: "white",
                    }}
                  >
                    Cancel
                  </Link>
                  <Link
                    className="primary"
                    to="#"
                    style={{ width: "140px", padding: "13px" }}
                  >
                    Update Group
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default EditGropu;

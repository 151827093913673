// import React from "react";
// import BarLoader from "react-bar-loader";

// export default function Barloader() {
//   return <BarLoader color="#38847C" height="5" />;
// }

import React from "react";
import BeatLoader from "react-spinners/BeatLoader";

export default function Barloader() {
  return <BeatLoader color="#38847C" height="70px" />;
}

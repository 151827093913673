import React, { useContext, useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from '../context/AppContext'
import src from '../assets/images/search.svg'
import ApiConfig from '../api/ApiConfig'
import GoogleMapReact from 'google-map-react'
import user_icon from '../assets/images/default.jpg'
import location from '../assets/images/location.svg'
import {
  simpleGetCallWithErrorResponse,
  putMultipartWithAuthCallWithErrorResponse,
} from '../api/ApiServices'
import swal from 'sweetalert'
import AutoComplete from './AutoComplete'
import GoogleMap from './GoogleMap'
import GoogleMapNew from './GoogleMapNew'

function Header({ setLoggedIn }) {
  const {
    customerData,
    setCustomerData,
    place,
    setPlace,
    sidebar,
  } = useContext(AppContext)

  const [loading, setLoading] = useState(false)
  const [Profilelist, setProfileList] = useState([])

  const logout = (e) => {
    e.preventDefault()
    localStorage.clear()
    setLoggedIn(false)
  }

  useEffect(() => {
    getprofile()
    return () => {
      setProfileList([])
    }
  }, [])

  const getprofile = () => {
    setLoading(true)

    simpleGetCallWithErrorResponse(
      ApiConfig.UNIVERSR_USER_PROFILE,
      JSON.stringify({ ...customerData }),
    ).then((res) => {
      setLoading(false)
      setProfileList(res.json.user_profile)
    })
  }

  useEffect(() => {}, [place])

  // initialize the google place autocomplete

  const applyLocation = () => {
    if (customerData.location == null) {
      swal('Please enter your location')
      return
    }
    // console.log("location",location,customerData.radius,place.center.lat,place.center.lng);

    const formData = new FormData()
    formData.append('location', customerData.location)
    formData.append('radius', customerData.radius)
    formData.append('lat', place.center.lat)
    formData.append('long', place.center.lng)

    putMultipartWithAuthCallWithErrorResponse(
      ApiConfig.UNIVERSR_CHANGE_LOCATION,
      formData,
    )
      .then((data) => {
        console.log(data)
        if (data) {
          setCustomerData({
            ...customerData,
          })
          localStorage.setItem('location', data.json.location)
          localStorage.setItem('lat', data.json.lat)
          localStorage.setItem('long', data.json.long)
          localStorage.setItem('radius', data.json.radius)
          // swal("Location updated successfully");
          // this.props.navigation.goBack();
        }
      })
      .catch((error) => {
        console.log('error looking', error)
      })

    // console.log("this.state.region.longitude>>>",this.state.region.longitude)
  }

  //////////////////////// New Start///////////

  return (
    <>
      <main style={{}} class="main-header">
        <header className={sidebar ? 'cx-header cx-active' : 'cx-header'}>
          <nav
            class="navbar navbar-expand-lg "
            style={{
              position: 'sticky',
              top: '0',
              right: '0',
              zIndex: '1000',
            }}
          >
            <div class="container-fluid header-container">
              {/* <a class="navbar-brand" href="#">Navbsar</a> */}
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon">
                  <i class="fa-solid fa-bars"></i>
                </span>
              </button>
              <div
                class="collapse navbar-collapse col-lg-12"
                id="navbarSupportedContent"
              >
                <ul
                  className=" mainnaveresponsive navbar-nav  profile-menu row flex-column flex-sm-row align-items-center"
                  style={{
                    margin: '0',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'start',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div
                    class="col p-0"
                    style={{
                      padding: '0',
                    }}
                  >
                    <li
                      class="nav-item navmedianinpute"
                      style={{
                        position: 'relative',
                        maxWidth: '800px',
                      }}
                    >
                      {/* <img
                        src={src}
                        style={{
                          float: "right",
                          position: "absolute",
                          top: "10px",
                          right: "20px",
                        }}
                      /> */}
                      {/* <input
                        type="search"
                        placeholder="Search my univrs"
                        style={{
                          width: "100%",

                          height: "40px",
                          backgroundColor: "#313131",
                          padding: "0px 0px 0px 20px",
                          color: "white",
                          borderRadius: "10px",
                          border: "none",
                        }}
                      /> */}
                    </li>
                  </div>

                  <div
                    class=" col-lg-3 d-flex align-items-center p-0"
                    style={{ width: 'fit-content', height: '44px' }}
                  >
                    <li
                      class="nav-item secnd-navbar "
                      style={{ width: 'fit-content', marginLeft: '40px' }}
                    >
                      <Link
                        className="nav-link  sLocation"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalset"
                        to="#"
                        style={{
                          padding: '0px',
                          width: 'fit-content',
                        }}
                      >
                        Set Location
                      </Link>
                    </li>
                  </div>

                  {/* modal starts */}

                  {/* modal ends */}
                  <div
                    class="  col-lg-3 p-0"
                    style={{
                      width: 'fit-content',

                      marginRight: '10px',
                    }}
                  >
                    <li
                      class="nav-item dropdown "
                      style={{
                        // position: "relative",
                        float: 'left',
                        justifySelf: 'center',
                        marginLeft: '40px',
                        width: 'fit-content',
                        // bottom: "15px",
                      }}
                    >
                      <a
                        class="nav-link dropdown-toggle navimglogo d-flex justify-content-between align-items-center"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          display: 'block',

                          width: 'fit-content',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <img
                          src={
                            Profilelist.profile_pic
                              ? Profilelist.profile_pic
                              : user_icon
                          }
                          style={{
                            justifySelf: 'center',
                            height: '48px',
                            width: '48px',
                            borderRadius: '24px',
                            objectFit: 'cover',
                            outline: '2px solid #00B4BD',
                            outlineOffset: '2px',
                          }}
                        />
                      </a>
                      <ul
                        class="dropdown-menu "
                        aria-labelledby="navbarDropdown"
                        style={{
                          border: '1px solid #2a2a2a',
                        }}
                      >
                        <li>
                          <Link class="dropdown-item" to="/settings">
                            <i class="fas fa-sliders-h fa-fw pt-1 me-2"></i>{' '}
                            Account
                          </Link>
                        </li>
                        <li>
                          <Link to="/settings" class="dropdown-item" href="#">
                            <i class="fas fa-cog fa-fw pt-1 me-2"></i> Settings
                          </Link>
                        </li>
                        {/* <li>
                          <hr class="dropdown-divider" />
                        </li> */}
                        <li>
                          <Link to="#" class="dropdown-item" onClick={logout}>
                            <i class="fas fa-sign-out-alt fa-fw me-2 pt-1"></i>{' '}
                            Log Out
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </div>
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </main>

      {/* modal */}
      <div
        class="modal fade "
        id="exampleModalset"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        style={{
          height: '100vh',
        }}
      >
        <div
          class="modal-dialog modal-dialog-centered"
          style={{
            height: '100%',
          }}
        >
          <div
            class="modal-content "
            style={{
              borderRadius: '20px',
              padding: '30px',
              backgroundColor: '#F5F5F5',
              width: '75%',
              margin: 'auto',
            }}
          >
            <div class="modal-header" style={{ borderBottom: 'none' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <h5
                  class="modal-title"
                  id="staticBackdropLabel"
                  style={{ padding: '0', marginLeft: '-15px' }}
                >
                  Change Location
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </div>
            <div>
              <p
                style={{
                  color: '#707070',
                  fontSize: '14px',
                  marginBottom: '20px',
                  marginTop: '-10px',
                }}
              >
                Search by town, city, neighbourhood or postal code.
              </p>
            </div>
            <div style={{ marginTop: '20px' }}>
              <p
                style={{
                  color: '#707070',
                  fontSize: '14px',
                  marginBottom: '10px',
                }}
              >
                Location
              </p>
              <AutoComplete setPlace={setPlace} />
              <div
                className="uploadPP_inputTextRow"
                style={{ marginBottom: '30px' }}
              ></div>
            </div>
            <div>
              <p
                style={{
                  color: '#707070',
                  fontSize: '14px',
                  marginBottom: '10px',
                }}
              >
                Radius
              </p>
              <div
                className="upp_selectWrapper"
                style={{ marginBottom: '40px' }}
              >
                <select
                  value={place.radius}
                  onChange={(e) => {
                    setCustomerData({
                      ...customerData,
                      radius: e.target.value,
                    })
                    setPlace({
                      ...place,
                      radius: e.target.value,
                    })
                  }}
                  class="form-select"
                  aria-label="Default select example"
                  style={{
                    height: '50px',
                    fontSize: '18px',
                    fontWeight: '600',
                    color: 'black',
                  }}
                >
                  <option selected value={place.radius}>
                    {place.radius} Miles
                  </option>
                  <option value={5.0}>5 Miles</option>
                  <option value={10.0}>10 Miles</option>
                  <option value={15.0}>15 Miles</option>
                  <option value={20.0}>20 Miles</option>
                  <option value={30.0}>30 Miles</option>
                </select>
              </div>
              <div
                className="mapWrapper"
                style={{ height: '129px', borderRadius: '20px' }}
              >
                <GoogleMap />
              </div>
            </div>
            <div class="modal-footer ">
              <div
                className="usa-btn"
                style={{ width: '100%', alignItems: 'center' }}
              >
                <button
                  className="create"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    applyLocation()
                  }}
                  style={{
                    padding: '13px',
                    width: '130px',
                    backgroundColor: '#00B4BD',
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header

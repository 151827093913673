import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/AppContext";
import { ShareSocial } from "react-share-social";
import { Modal, Button } from "react-bootstrap";
import {
  deleteWithAuthCall,
  postMultipartWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
  simplePostCall,
  simpleGetCall,
} from "../../api/ApiServices";
import swal from "sweetalert";

import CardView from "./CardView";

import eventthumbImg01 from "../../assets/images/house01.png";
import LoaderCenter from "../../sharedComponent/LoaderCenter";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Slider } from "../../components/Slider";
import './AllPropertyCareer.css'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faBuilding,
  faUsers,
  faBed,
  faBath,
  faMoneyBill,
  faDollarSign,
  faUser,
  faClock ,
  faTrashAlt,
  faHeart,faArrowAltCircleUp,faArrowAltCircleDown
} from "@fortawesome/free-solid-svg-icons";



const AllCareerProperty = () => {
  const { keyword } = useContext(AppContext);
  const [dismiss, setDismiss] = useState(false);
  const [ShareData, setShare] = useState("");


  const filterModal = useRef(null);

  // filter api starts
  const [errorMsg, setErrorMsg] = useState({
    title: "",
    property_type: "",
    gender: "",
    term: "",
    availability: "",
    minimum: "",
    maximum: "",
  });
  const { customerData } = useContext(AppContext);
  console.log("customerData", customerData.id);
  const [propertyfilterType, setPropertyFilterType] = useState({

    jobType: "",

  });
  const style = {
    background: "#F5F5F5",
    borderRadius: 3,
    border: 0,
    color: "white",
    padding: "0 0px",
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    height: "200px",
    marginTop: "20px",
    // width: '20px',
  };
  const [showElement, setShowElement] = React.useState(true);
  const currentRoute = useLocation().pathname;
  const [loading, setLoading] = useState(false);
  //const [HouseingList, setHouseingList] = useState([]);
  const [HouseingList, setHouseingList] = useState([]);
  const [HouseingAdsList, setHouseingAdsList] = useState([])
  const [houseingListadd, setHouseingAdd] = useState([]);
  const [HouseingMange, setHouseingMange] = useState([]);
  const [selectedID, setselectedID] = useState("");

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  // const handleClose = () => setShow(false);
  // const handleShow = () => {
  //   setShow(true);
  //   ShareHandal();
  // };
  const navigate = useNavigate();

  useEffect(() => {
    getHouselist();
  }, []);
  useEffect(() => {
    getHouselist();
  }, [keyword]);
  // useEffect(() => {
  //   getHouselistMange();
  // }, [houseingListadd]);

  // const getHouselist = () => {
  //   setLoading(true);

  //   // simpleGetCallWithErrorResponse(
  //     simpleGetCall(
  //     ApiConfig.UNIVERSR_HOUSING_ALL_PROPTIE_LIST
  //     // JSON.stringify({
  //     //   radius: customerData.radius,
  //     //   lat: customerData.lat,
  //     //   long: customerData.long,
  //     // })
  //   ).then((res) => {
  //     setLoading(false);
  //     console.log("radius", res.housing_properties);
  //     if (keyword.length > 0) {
  //       getSearchedProducts(res.json.housing_properties);
  //     } else {
  //       setHouseingList(res.housing_properties);
  //       setHouseingAdsList(res.housing_properties.filter((e) => e.featured_ads == true))
  //     }
  //   });
  // };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    // Options for formatting the date as you wish
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true, // Whether to use 12-hour time (true) or 24-hour time (false)
    };
    return date.toLocaleString('en-US', options);
  };

//   const getHouselist =async() => {
//     setLoading(true);

//    await fetch(ApiConfig.UNIVERSR_HOUSING_CAREER_PROPTIE_LIST)
//       .then(response => {
//         if (!response.ok) {
//           throw new Error('Network response was not ok');
//         }
//         return response.json();
//       })
//       .then(data => {
//         setLoading(false);
//        console.log("housing_properties", data.posts);
//         // if (keyword.length > 0) {
//         //   getSearchedProducts(data.housing_properties);
//         // } else {
//           setHouseingList(data.posts);
//           console.log("res housing", HouseingList);
//          // setHouseingAdsList(data.housing_properties.filter(e => e.featured_ads == true));
//          //}
//       })
//       .catch(error => {
//         setLoading(false);
//         console.error('There was a problem fetching the data:', error);
//       });
// };


const getHouselist = async () => {
  setLoading(true);
  try {
    const response = await fetch(ApiConfig.UNIVERSR_HOUSING_CAREER_PROPTIE_LIST);
    if (!response.ok) throw new Error('Network response was not ok');
    const data = await response.json();
    setLoading(false);
    if (keyword.length > 0) {
      getSearchedProducts(data.posts);
    } else {
      setHouseingList(data.posts);
    }
  } catch (error) {
    setLoading(false);
    console.error('There was a problem fetching the data:', error);
  }
};

const getFilteredlist = async () => {
  setLoading(true);

  await fetch(ApiConfig.UNIVERSR_HOUSING_CAREER_PROPTIE_LIST)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => { 
      handleCloseModal();
      setLoading(false);

        getFilteredProducts(data.posts);

      //   setHouseingList(data.posts);
      // }
    })
    .catch(error => {
      setLoading(false);
      console.error('There was a problem fetching the data:', error);
    });
};


  const Delete = (id) => {
    deleteWithAuthCall(ApiConfig.UNIVERSR_HOUSING_MANGE_DELET + id)
      .then((res) => {
        console.log(res);
        swal(res.detail);
        getHouselist();
        navigate("/career/all");
      })
      .catch((err) => {
        setLoading(false);

        console.log(err);
      });
  };

  const getSearchedProducts = (data) => {
    let matches = [];
    matches = data.filter((product, index) => {
      if (
        product &&
        product.title.toLowerCase().includes(keyword.toLowerCase())
      )
        return product;
    });

    setHouseingList(matches);
  };

  const filters = {
    jobType: propertyfilterType.jobType,

  };

  const getFilteredProducts = (data) => {
    let matches = [...data]; // Copying the original data array

    if (filters.jobType && !filters.jobType=='') {
      matches = matches.filter(product => {
        return product && product.careerDetails && product.careerDetails.jobType === filters.jobType;
      });
    }
    setHouseingList(matches);
  };

  const AddIntersted = (id) => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.UNIVERSR_ADD_USER_INTERSTED,
      JSON.stringify({ housing_property_id: id })
    )
      .then((res) => {
        setHouseingAdd(res);

        console.log(res);
        swal(res?.json);
        getHouselist();
      })
      .then((data) => {
        swal(data?.json);
        setHouseingAdd(data);
      })
      .catch((err) => {
        setLoading(false);

        console.log(err);
      });
  };
  // ===========> search start =================

  // ================= end ================
  const propertyFilter = (e) => {
    setDismiss(false);

    let formData = new FormData();
    formData.append("id", customerData.id);
    formData.append("type", propertyfilterType.title);
    formData.append("propertyType", propertyfilterType.propertyType);
    formData.append("gender", propertyfilterType.gender);
    formData.append("term", propertyfilterType.term);
    formData.append("availability", propertyfilterType.availability);
    formData.append("bills_included", propertyfilterType.billsIncluded);
    formData.append("smokers_allowed", propertyfilterType.smokedAllowed);
    formData.append("minimum", propertyfilterType.minimum);
    formData.append("maximum", propertyfilterType.maximum);

    if (!(propertyfilterType.type === "")) {
      setErrorMsg({ title: "" });

      if (!(propertyfilterType.property_type === "select")) {
        setErrorMsg({ propertyType: "" });
        if (!(propertyfilterType.gender === "select")) {
          setErrorMsg({ gender: "" });
          if (!(propertyfilterType.term === "select")) {
            setErrorMsg({ term: "" });
            if (!(propertyfilterType.availability === "select")) {
              setErrorMsg({ availability: "" });
              if (!(propertyfilterType.minimum === "")) {
                setErrorMsg({ minimum: "" });
                if (!(propertyfilterType.maximum === "")) {
                  setErrorMsg({ maximum: "" });
                  // =======> api call starts <==========
                  setLoading(true);
                  // filterModal.current.modal("hide");
                  postMultipartWithAuthCallWithErrorResponse(
                    ApiConfig.UNIVERSR_HOUSING_ALL_PROPTIE_FILTER,
                    formData
                  )
                    .then((res) => {
                      setHouseingList(res?.json?.housing_property);
                      setHouseingMange([]);
                      setLoading(false);
                    })
                    .catch((err) => console.log(err));
                  // =======> api call end <==============
                } else {
                  setErrorMsg({
                    maximum: "Please enter  maximum price",
                  });
                }
              } else {
                setErrorMsg({
                  minimum: "Please enter  mininum price",
                });
              }
            } else {
              setErrorMsg({
                availability: "Please enter  availability",
              });
            }
          } else {
            setErrorMsg({
              term: "Please enter term",
            });
          }
        } else {
          setErrorMsg({
            gender: "Please enter  gender",
          });
        }
      } else {
        setErrorMsg({
          propertyType: "Please enter  property type",
        });
      }
    } else {
      setErrorMsg({ type: "Please enter type" });
    }
  };
  const HousingId = (id) => {
    setselectedID(id);
  };
  const ShareHandal = async () => {
    let requestBody = JSON.stringify({
      dynamicLinkInfo: {
        domainUriPrefix: "https://myuniverse.page.link",

        link:
          "https://my-univrs.app.redbytes.in/housing-details/" +
          String(selectedID) +
          "/?user=" +
          String(customerData.id),

        androidInfo: {
          androidPackageName: "com.my_universe",
        },

        iosInfo: {
          iosBundleId: "org.myUniverse",
        },
      },
    });

    await fetch(
      "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBmt92Aby2xlZWOCIHeTutj8ywpF-4reyw",

      {
        method: "POST",

        headers: {
          Accept: "application/json",

          "Content-Type": "application/json",
        },

        body: requestBody,
      }
    )
      .then((response) => response.json())

      .then((data) => {
        console.log("response", data);
        setShare(data.shortLink);
      })

      .catch((err) => {
        // this.setState({isVisible:false})

        console.log("error", err);
      });
  };

  const [showPopup, setShowPopup] = useState(false);

  // Function to toggle popup visibility
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const [clicked,setClicked]=useState(false);

  const handleHeartClick = (event) => {
    setClicked(!clicked)
    event.preventDefault(); // Prevent default action
    event.stopPropagation(); // Stop event propagation
    // Add your logic for what happens when the heart icon is clicked
    console.log('Heart icon clicked');
  };


  return (
    <>
      <div
      className="row  g-4"
      >
         <div style={{ width: "100%", textAlign: 'right' }}>
  <button
    style={{
      height:"35px",
      marginRight:"20px",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '150px',
      backgroundColor: 'rgba(211, 211, 211, 0.5)',  // Light transparent grey background
      color: '#333333',            // Black text color
      border: '1px solid #333333', // Grey border
      borderRadius: '20px',        // Rounded corners
      cursor: 'pointer',           // Pointer cursor on hover
      fontSize: '15px',            // Font size
      fontWeight: '600',            // Font weight
      transition: 'background-color 0.3s',  // Smooth background color transition
      float: 'right',              // Align button to right
      padding: '10px',             // Padding inside the button
    }}
    onClick={handleShowModal}
  >
    {showModal ? (  <span style={{ marginRight: '10px' }}>
      <FontAwesomeIcon icon={faArrowAltCircleUp}  color="#808080"/>
    </span>):(
        <span style={{ marginRight: '10px' }}>
        <FontAwesomeIcon icon={faArrowAltCircleDown} color="#808080"/>
      </span>
    )}
  
  
    filter
  </button>
  </div>

        {loading ? (
          <LoaderCenter/>
        ) : 
        HouseingList && HouseingList.length > 0 ?
         (  
          <div style={{ width: "1120px", padding: "20px", margin: "0 auto" }}>
          <div style={{ display: "flex", flexWrap: "wrap", gap:'25px' }}>
            {HouseingList.map((house, index) => (
              <CardView key={index} house={house} index={index} />
            ))}
          </div>
        </div>
        
        ) 
        : (
          <p
            style={{
              fontSize: "20px",
              marginTop: "200px",
            }}
            className=" text-center justify-content-center align-items-center"
          >
            Oops! It doesn't look like there are any properties in your area.
            <br /> Why don't you try expanding your search criteria?
          </p>
        )
        }
      </div>

         {/* filter modal */}
         <Modal
          show={showModal}
          onHide={handleCloseModal}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          // centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Filter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="mb-3">
                <label htmlFor="propertyType" className="form-label">
                  Job Type
                </label>
                <select
                  className="form-control"
                  id="propertyType"
                  value={propertyfilterType.jobType}
                  onChange={(e) =>
                    setPropertyFilterType({
                      ...propertyfilterType,
                      jobType: e.target.value,
                    })
                  }
                >
                     <option value="">
                                  Select Job Type
                                </option>
                                <option value="Full Time">Full Time</option>
                                <option value="Part Time">Part Time</option>
                                <option value="Volunteer">Volunteer</option>
                                <option value="Remote">Remote</option>
                                <option value="Internship">Internship</option>
                                <option value="Work-Experience">Work-Experience</option>
                </select>
              </div>
 
              {/* <Button variant="primary" onClick={propertyFilter}>
                Apply Filters
              </Button> */}
            </form>
          </Modal.Body>
          <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={getFilteredlist}>
            Apply Filters
          </Button>
        </Modal.Footer>
        </Modal>

     
    </>
  );
};

export default AllCareerProperty;

import React, { useContext, useState, useEffect } from 'react'
import { Link, NavLink, Outlet } from 'react-router-dom'
import { AppContext } from '../../context/AppContext'
import Sidebar from '../../components/Sidebar'
import Header from '../../components/Header'
import fillter_icon from '../../assets/images/fillter_icon.svg'
import eventthumbImg01 from '../../assets/images/eventThumb01.png'
import eventthumbImg02 from '../../assets/images/eventThumb02.png'
import eventthumbImg03 from '../../assets/images/eventThumb03.png'
import location_icon from '../../assets/images/location_icon.svg'
import gray_location_icon from '../../assets/images/gray_location_icon.svg'

function EventsList() {
  const { sidebar, setSidebar, customerData } = useContext(AppContext)
  const [manage, setManage] = useState(false)
  const [showElement, setShowElement] = React.useState(true)
  useEffect(() => {
    setTimeout(function () {
      setShowElement(false)
    }, 5000)
  }, [])
  return (
    <div>
      <Sidebar />

      {/* <Header /> */}
      <main id="cxMain" className={sidebar ? 'cx-main cx-active' : 'cx-main'}>
        <section className="content-main">
          <div className="row">
            <div className="col-md-12">
              <div
                className="row"
                style={{
                  overflow: 'auto',
                }}
              >
                <div className="col-md-12">
                  <div className="globalPages_tabWrapper">
                    <div className="gb_tabs">
                      <div className="tabs">
                        <div>
                          <NavLink
                            onClick={() => setManage(false)}
                            to="/sell_list/all"
                            className={({ isActive }) =>
                              isActive ? 'nav-active' : 'nav-inactive'
                            }
                          >
                            All Items
                          </NavLink>
                        </div>
                        <div>
                          <NavLink
                            onClick={() => setManage(true)}
                            to="/sell_list/manage"
                            className={({ isActive }) =>
                              isActive ? 'nav-active' : 'nav-inactive'
                            }
                          >
                            Manage
                          </NavLink>
                        </div>

                        <div>
                          <NavLink
                            onClick={() => setManage(true)}
                            to="/sell_list/saved"
                            className={({ isActive }) =>
                              isActive ? 'nav-active' : 'nav-inactive'
                            }
                          >
                            Saved
                          </NavLink>
                        </div>
                        {!manage ? (
                          <button className="filter-ic-btn border-0">
                            <Link
                              to="#"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                              style={{
                                border: 'none',
                                margin: '0',
                                padding: '0px 0px',
                                marginRight:
                                  '17nm,mn,l                                                                                                                                                                                                                                                                 m,mm,mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,llllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllll,l,l,,,ll                             ,l  mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,l,llllllllllllllllllllllllllllllllllmmmmmmm,lllllllllllllllllllllllllllllll                                                                                                                                                                                                                                                                                                                                                                                                                                                  0px',
                              }}
                            >
                              <img src={fillter_icon} alt="" />
                            </Link>
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>

                      <div></div>

                      <div className="create-location">
                        <div
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModalset"
                        >
                          <Link to="#" className="location-button">
                            <p>
                              <span
                                className="content"
                                style={{
                                  marginLeft: '8px',
                                  width: 'fit-content',
                                  maxWidth: '100px',
                                  textAlign: 'start',
                                }}
                              >
                                {customerData.location}{' '}
                              </span>
                              <span className="dot mx-1"></span>
                              <span>{customerData.radius} Miles </span>
                            </p>
                          </Link>
                        </div>
                        <div>
                          <Link
                            className="create-event-button"
                            // to="/create_sell"
                            to="/sell_list/create"
                          >
                            Create Sell
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="gblThumb_outerWrapper no-scroll"
                style={{
                  height: 'fit-content',
                  minHeight: 'calc(100vh - 216px)',
                  maxHeight: 'calc(100vh - 216px )',
                }}
              >
                <Outlet />
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}

export default EventsList

import React, { useContext, useState, useEffect } from 'react'
import { AppContext } from '../../context/AppContext'
import { Link, useParams } from 'react-router-dom'
import chatpp from '../../assets/images/chatListAvatar01.png'
import ApiConfig from '../../api/ApiConfig'
import down from '../../assets/images/123.svg'
import Nav from 'react-bootstrap/Nav'
import send_icon from '../../assets/images/send_icon.svg'
import chatusa from '../../assets/images/chat_icon_sidebar.svg'
import addImage_file_icon from '../../assets/images/addImage_file_icon.svg'
import gc_img from '../../assets/images/groupCallImg.png'
import speaker_notselected_icon from '../../assets/images/speaker_notselected_icon.svg'
import mute_icon from '../../assets/images/mute_icon.svg'
import endcall_icon from '../../assets/images/endcall_icon.svg'
import Draggable from 'react-draggable'
import profile_img from '../../assets/images/Chating_images/profile_img.svg'
import double_tick from '../../assets/images/double_tick.svg'
import call_icon from '../../assets/images/call_icon.svg'
import vCall_icon from '../../assets/images/Chat_Video_Call.svg'
import LoaderCenter from '../../sharedComponent/LoaderCenter'
import userDemo from '../../assets/images/userDemo.png'
import { Button, Dropdown } from 'react-bootstrap'
import send_btn from '../../assets/images/Chating_images/send_btn.svg'
import Three_dot from '../../assets/images/Chating_images/Three_dot.svg'
import { Tab, Tabs } from 'react-bootstrap'
import {
  postMultipartWithAuthCallWithErrorResponseNodeCreate,
  simpleGetCallWithErrorResponseNode,
  simplePostCallNode,
} from '../../api/ApiServices'
import './Chating.scss'
import ChatBody from './ChatBody'
import GroupChatNew from './GroupChatNew'
import { useRef } from 'react'
import Moment from 'react-moment'
import { Mention, MentionsInput } from 'react-mentions'
import GroupMentuinStyles from './GroupMentuinStyles'
import GpmentionInputstyles from './GpmentionInputstyles'
// import mentionsInputStyles from '..'
// import mentionStyles from './mentionStyles'
import Highlighter from 'react-highlight-words'

function ChatSidebar() {
  const [Profilelistfriend, setProfileListFriend] = useState([])
  const [InterstedList, setInterstedList] = useState([])
  const [GroupList, setGroupList] = useState([])
  const [loading, setLoading] = useState(false)
  const params = useParams()
  let InterstedId = params.id
  const [ChatUser, setChatUser] = useState('')
  const [ChatUserIntersted, setChatUserIntersted] = useState('')
  const [ChatUserGroup, setChatUserGroup] = useState('')
  const [ChatId, setChatId] = useState('')
  const [UserIndex, setIndex] = useState('')
  const [UserIndexGroup, setIndexGroup] = useState('')
  const [UserIndexIntersted, setIndexIntersted] = useState('')
  console.log(UserIndexIntersted)
  const [message, setMessage] = useState('')
  const [messageintersted, setMessageintersted] = useState('')
  const [messageGroup, setMessageGroup] = useState('')
  const [messageId, setMessageId] = useState('')
  const [filesfromserver, setfilesfromserver] = useState([])
  const [filesIntersted, setfilesIntersted] = useState([])
  const [filesGroups, setfilesGroups] = useState([])
  const [hasmore, sethasmore] = useState(true)
  const [hasmoreGroup, sethasmoreGroup] = useState(true)
  const [hasmoreIntersted, sethasmoreIntersted] = useState(true)
  const loadMoreRef = useRef()
  const loadMoreRefNew = useRef()
  const loadMoreReGroup = useRef()
  const [showResults, setShowResults] = useState(false)
  const [showResultsUrl, setShowResultsUrl] = useState(false)
  const [page, setPage] = useState(0)
  const [pageIntersted, setPageIntersted] = useState(0)
  const [Total, setTotal] = useState('')
  const [pageGroup, setPageGroup] = useState(0)
 

  const {
    customerData,
    socket,
    InterstedItem,
    ActiveTab,
    setInterstedItem,
  } = useContext(AppContext)
  const lastMessageRef = useRef(null)
  const NewlastMessageRef = useRef(null)
  const lastMessageRefGroup = useRef(null)
  const [showMap, setShowMap] = useState(false)
  const [friendChatdata, setfriendChatdata] = useState([])
  const [InterstedChatdata, setInterstedChatdata] = useState([])
  const [GroupChatdata, setGroupChatdata] = useState([])
  let tokan = customerData.auth_token
  const [newmessage, setnewmessage] = useState([])
  const [newmessageGroup, setnewmessageGroup] = useState([])
  const [newmessageInt, setnewmessageInt] = useState([])
  const [GroupTaglist, setGroupTaglist] = useState([])
  const [GroupTagItem, setGroupTagItem] = useState([])
  const [TextColor, setTextColor] = useState('')
  const [commenttext, setcommentsetText] = useState('')
  const [commenttextID, setcommentTextID] = useState('')

  useEffect(() => {
    let coursesData = GroupTaglist.map((subject, index) => {
      return {
        id: subject.member_id.toString(),
        display: subject.member_name,
      }
    })

    let choiceArry = []
    const data = GroupTaglist.map((value, index) => {
      choiceArry.push(value.member_name)
    })

    setTextColor(choiceArry)
    setGroupTagItem(coursesData)
  }, [GroupTaglist])

  //  tag list //
  useEffect(() => {
    if (messageGroup !== '') {
      let regex = /@\[.+?\]\(.+?\)/gm
      let displayRegex = /@\[.+?\]/g
      let idRegex = /\(.+?\)/g
      let matches = messageGroup.match(regex)
      let arr = []

      matches &&
        matches.forEach((m) => {
          let id = m.match(idRegex)[0].replace('(', '').replace(')', '')
          let display = m
            .match(displayRegex)[0]
            .replace('@[', '')
            .replace(']', '')

          arr.push({ id: id, display: display })
        })
      let newComment = messageGroup.split(regex)
      let output = ''
      let output2 = ''

      for (let i = 0; i < newComment.length; i++) {
        const c = newComment[i]

        if (i === newComment.length - 1) output += c
        else {
          output += c + `${arr[i].display}`
          output2 += c + `${arr[i].id}`
        }
      }

      setcommentsetText(output)
      setcommentTextID(output2)
    }
  }, [messageGroup])

  useEffect(() => {
    setChatId(ChatUser.id)
  }, [ChatUser])
  useEffect(() => {
    sethasmore(true)
    setPage(1)
    setfriendChatdata([])
    if(InterstedId){
      setShowResultsUrl(true)
      setIndexIntersted(1)
    }
  }, [])
  useEffect(() => {
    getprofilefriend()
    getInterstedList()
    getGroupList()
  }, [])
  useEffect(() => {
    setfriendChatdata([])

    handleHistery(ChatId)
  }, [page])

  useEffect(() => {
    if (page === 1) {
      handleHistery(ChatId)
    }
  }, [])
  const getprofilefriend = () => {
    setLoading(true)

    simpleGetCallWithErrorResponseNode(
      ApiConfig.FRIENDS_LIST_CHAT,
      JSON.stringify({ ...tokan }),
    ).then((res) => {
      setLoading(false)

      setProfileListFriend(res.json.recent_chats)
    })
  }
  const getInterstedList = () => {
    setLoading(true)

    simpleGetCallWithErrorResponseNode(
      ApiConfig.INTERSTED_LIST_CHAT,
      JSON.stringify({ ...tokan }),
    ).then((res) => {
      setLoading(false)
      if (res.json.result === true) {
        setInterstedList(res.json.recent_chats)
      } else {
        setInterstedList([])
      }
    })
  }

  const getGroupList = () => {
    setLoading(true)

    simpleGetCallWithErrorResponseNode(
      ApiConfig.GROUP_LIST,
      JSON.stringify({ ...tokan }),
    ).then((res) => {
      setLoading(false)
      if (res.json.result === true) {
        setGroupList(res.json.data)
      } else {
        setGroupList([])
      }
    })
  }

  //  Friends Secation Start /////

  // Chat Histery Friends ///
  const handleHistery = async (data) => {
    // setUserdata(data)
    setfriendChatdata([])
    setnewmessage([])

    // setIndex(index + 1)
    let newRequestBody = JSON.stringify({
      receiverId: data,
      page: page,
    })
    simplePostCallNode(ApiConfig.FRIENDS_CHAT_HISTERY, newRequestBody)
      .then((data) => {
        if (data.result === true) {
          setfriendChatdata([...data.chat_data.reverse()])

          if (data.total_pages - data.current_page === 0) {
            sethasmore(false)
          }
        } else {
          setfriendChatdata([])
        }
      })
      .catch((error) => {
        console.log('api response', error)
      })
  }

  // ======================================= pagination starts ======================
  //  const [pageCount, setPageCount] = useState(0);
  const loadMore = () => {
    setPage((page) => page + 1)
  }

  // ======================================= PAGINATION ENDS ========================

  const sendMessage = () => {
    if (message.length > 0) {
      setMessage('')
      socket.emit('new message', {
        senderId: customerData.id,
        message: message,
        type: 'private',
        receiverId: ChatUser.id,
        chat_type: 'friends',
      })
    }
    // handleHistery()
  }

  useEffect(() => {
    // setnewmessage([])
    socket.on('message created', (value) => {
      setnewmessage((prev) => [...prev, ...value])
    })
    return () => {
      socket.off()
    }
  }, [])

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [friendChatdata, newmessage])

  const handleFileUpload = (files) => {
    setfilesfromserver([files])
    files = Object.values(files)
    const data = new FormData()
    files.map((file) => {
      data.append('files', file)
    })
    data.append('receiverId', ChatUser.id)
    data.append('type', 'friends')
    postMultipartWithAuthCallWithErrorResponseNodeCreate(
      ApiConfig.SAVE_CHAT_FILE,
      data,
    )
      .then((data) => {
        if (data.json.success) {
          let filesArr = data.json.data
          setfilesfromserver([...filesfromserver, ...filesArr])
        }
      })
      .catch((error) => {
        console.log('api response', error)
      })
  }

  // Friends Secation End   ///////

  // Intersted Chats /////

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    NewlastMessageRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [InterstedChatdata, newmessageInt])

  useEffect(() => {
    sethasmoreIntersted(true)
    setPageIntersted(1)
    setGroupChatdata([])
  }, [])

  useEffect(() => {
    setInterstedChatdata([])

    handleIntersted(InterstedId, messageId)
  }, [pageIntersted])

  useEffect(() => {
    if (pageIntersted === 1) {
      handleIntersted(InterstedId, messageId)
    }
  }, [])

  const sendMessageint = () => {
    if (messageintersted.length > 0) {
      setMessageintersted('')
      socket.emit('new message', {
        senderId: customerData.id,
        message: messageintersted,
        productId: '',
        type: 'private',
        receiverId: InterstedId ? InterstedId : ChatUserIntersted.id,
        chat_type: 'intrested',
      })
    }
    // handleHistery()
  }

  useEffect(() => {
    // setnewmessage([])
    socket.on('message send', (value) => {
      setnewmessageInt((prev) => [...prev, ...value])
    })
    return () => {
      socket.off()
    }
  }, [])

  const handleIntersted = async (data, messageId) => {
    setMessageId(data)
    // setUserdata(data)
    setInterstedChatdata([])
    setnewmessageInt([])
    // setIndex(index + 1)
    let newRequestBody = JSON.stringify({
      receiverId: data,
      type: 'intrested',
      page: pageIntersted,
    })
    simplePostCallNode(ApiConfig.HoUSING_CHAT_HISTERY, newRequestBody)
      .then((data) => {
        if (data.result === true) {
          setInterstedChatdata([...data.data.reverse()])

          if (data.total_page - data.current_page === 0) {
            sethasmoreIntersted(false)
          }
        } else {
          setInterstedChatdata([])
          // sethasmoreIntersted(false)
        }
      })
      .catch((error) => {
        console.log('api response', error)
      })
  }

  const handleUpload = (files) => {
    setfilesIntersted([files])
    files = Object.values(files)
    const data = new FormData()
    files.map((file) => {
      data.append('files', file)
    })
    data.append('receiverId', InterstedId ? InterstedId : ChatUserIntersted.id)
    data.append('type', 'intrested')
    postMultipartWithAuthCallWithErrorResponseNodeCreate(
      ApiConfig.SAVE_CHAT_FILE,
      data,
    )
      .then((data) => {
        if (data.json.success) {
          let filesArr = data.json.data
          setfilesIntersted([...filesIntersted, ...filesArr])
        }
      })
      .catch((error) => {
        console.log('api response', error)
      })
  }

  // ======================================= pagination starts ======================
  //  const [pageCount, setPageCount] = useState(0);
  const loadMoreIntersted = () => {
    setPageIntersted((pageIntersted) => pageIntersted + 1)
  }

  // ======================================= PAGINATION ENDS ========================

  //   Group chat histery ////

  useEffect(() => {
    setfriendChatdata([])

    handleGroupHistery(ChatUserGroup.group_id)
  }, [pageGroup])

  useEffect(() => {
    if (pageGroup === 1) {
      handleGroupHistery(ChatUserGroup.group_id)
    }
  }, [])

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    lastMessageRefGroup.current?.scrollIntoView({ behavior: 'smooth' })
  }, [GroupChatdata, newmessageGroup])
  useEffect(() => {
    sethasmoreGroup(true)
    setPageGroup(1)
  }, [])

  const sendMessageGroup = () => {
    if (commenttext.length > 0) {
      setMessageGroup('')
      setcommentsetText('')
      socket.emit('new message', {
        senderId: customerData.id,
        message: commenttext,
        type: 'group',
        tagged_user_id: commenttextID,
        file: [],
        receiverId: ChatUserGroup.group_id,
      })
    }
    // handleHistery()
  }

  useEffect(() => {
    // setnewmessage([])
    socket.on('message sent', (value) => {
      console.log("value For Group",value)
      setnewmessageGroup((prev) => [...prev, ...value])
    })
    return () => {
      socket.off()
    }
  }, [])

  const handleGroupHistery = async (data) => {
 
    setGroupChatdata([])
    
    let newRequestBody = JSON.stringify({
      group_id: data,
      page: pageGroup,
    })
    simplePostCallNode(ApiConfig.GROUP_CHAT_HISTORY, newRequestBody)
      .then((data) => {
        if (data.result === true) {
          setGroupChatdata([...data.data.reverse()])

          if (data.total_page - data.current_page === 0) {
            sethasmoreGroup(false)
          }
        } else {
          setGroupChatdata([])
        }
      })
      .catch((error) => {
        console.log('api response', error)
      })
  }

  const handleUploadGroup = (files) => {
    setfilesGroups([files])
    files = Object.values(files)
    const data = new FormData()
    files.map((file) => {
      data.append('files', file)
    })
    data.append('receiverId', ChatUserGroup.group_id)
    postMultipartWithAuthCallWithErrorResponseNodeCreate(
      ApiConfig.GROUP_SAVE_CHAT_FILE,
      data,
    )
      .then((data) => {
        if (data.json.success) {
          let filesArr = data.json.data
          setfilesGroups([...filesGroups, ...filesArr])
        }
      })
      .catch((error) => {
        console.log('api response', error)
      })
  }

  // ======================================= pagination starts ======================
  //  const [pageCount, setPageCount] = useState(0);
  // const loadMoreHistery = () => {
  //   setPageGroup((pageGroup) => pageGroup + 1)
  // }

  const loadMoreHistery = () => {
    setPageGroup((pageGroup) => pageGroup + 1)
  }
  // ======================================= PAGINATION ENDS ========================

  return (
    <>
      {/* <div id="main-chat">
        <Tab.Container id="left-tabs-example" defaultActiveKey="1">
          <div className="row d-flex flex-wrap justify-content-around"> */}
      {loading ? (
        <LoaderCenter />
      ) : (
        <div className="main_tab_wrapper">
          <Tabs
            defaultActiveKey={ActiveTab}
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="Friends" title="Friends">
              <div id="main-chat">
                <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                  <div className="row d-flex flex-wrap justify-content-around">
                    <div
                      className="col-lg-3 left-chat-section"
                      id="Frinds_Chat_section"
                    >
                      <div className="chat-user-lists">
                        <Nav variant="pills" className="flex-column">
                          <div className="pinned-section">
                            <div className="left">
                              <label htmlFor="">Messages</label>
                            </div>
                          </div>
                          {Profilelistfriend && Profilelistfriend.length > 0 ? (
                            Profilelistfriend.map((item, index) => {
                              return (
                                <Nav.Item>
                                  <Nav.Link
                                    eventKey={index + 1}
                                    onClick={() => {
                                      setShowResults(true)
                                      setfriendChatdata([])
                                      setPage(1)
                                      handleHistery(item.receiver_details.id)
                                      setChatUser(item.receiver_details)
                                      setIndex(index + 1)
                                      setnewmessage([])
                                    }}
                                  >
                                    <div className="user-chat-tab">
                                      <div className="left-profile-pic">
                                        <Link to="">
                                          {item.receiver_details.profile_pic ? (
                                            <img
                                              src={
                                                item.receiver_details
                                                  .profile_pic
                                              }
                                              alt=""
                                            />
                                          ) : (
                                            <img src={userDemo} alt="" />
                                          )}
                                        </Link>
                                      </div>

                                      <div className="right-name-date ">
                                        <div className="top-section ">
                                          <div className="name ">
                                            <label htmlFor="">
                                              {item.receiver_details.full_name}
                                            </label>
                                          </div>
                                          <div></div>
                                          <div className="date">
                                            {/* <label htmlFor="">10:26</label> */}
                                          </div>
                                        </div>

                                        <div className="bottom-section ">
                                          <label htmlFor="">
                                            {item.last_recived_message}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </Nav.Link>
                                </Nav.Item>
                              )
                            })
                          ) : (
                            <p
                              style={{
                                fontSize: '20px',
                                margin: 'auto',
                                marginTop: '40px',
                              }}
                              className=" text-center justify-content-center"
                            >
                              Oops! It doesn't look like there are any Friend in
                              your area.
                              <br /> Why don't you try expanding your search
                              criteria?
                            </p>
                          )}
                        </Nav>
                      </div>

                      <div
                        className="button-container Frinds_Add_btn"
                        style={{
                          marginTop: '20px',
                        }}
                      >
                        {' '}
                        <Link
                          to="#"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasRight"
                          aria-controls="offcanvasRight"
                        >
                          <button
                          // style={{
                          //   color: '#21212C',
                          //   fontSize: '18px',
                          //   fontWeight: 900,
                          //   backgroundColor: '#00FFDC',
                          //   height: '50px',
                          //   width: 'auto',
                          //   padding: '0 20px',
                          //   border: 'none',
                          //   borderRadius: '50%',
                          //   textAlign: 'end',
                          // }}
                          >
                            +
                          </button>
                        </Link>
                        {/* <div>
                          <div
                            class="offcanvas offcanvas-end"
                            tabindex="-1"
                            id="offcanvasRight"
                            aria-labelledby="offcanvasRightLabel"
                            style={{
                              margin: ' 30px 0 30px 0',
                              borderRadius: '30px 0 0 30px ',
                              padding: '30px !important',
                              width: '360px !important',
                            }}
                          >
                            <div className="of-head d-flex justify-content-between align-items-center mt-3 mb-2">
                              <span
                                style={{
                                  font: 'normal normal bold 16px/22px Nunito',
                                  fontSize: '16px',
                                }}
                              >
                                Add Friends
                              </span>
                              <span
                                style={{
                                  font: 'normal normal bold 16px/22px Nunito',
                                  fontSize: '16px',
                                }}
                              >
                                10
                              </span>
                            </div>
                            <hr />
                            <div
                              class="offcanvas-body"
                              style={{
                                marginTop: '-10px',
                                paddingTop: '0px',

                                padding: '0',
                              }}
                            >
                              <Link to="/groups">
                                <div className="frnd-botm">
                                  <div className="fir-frnd">
                                    <div>
                                      <img
                                        className="ofImage"
                                        src={profile_img}
                                        alt="img"
                                      />
                                    </div>
                                    <div>
                                      <Link
                                        style={{
                                          background: 'transparent ',
                                        }}
                                        className="fName"
                                       
                                        to={ChatBody}
                                      >
                                       
                                        john Deo
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="chaticon">
                                    <img src={chatusa} />
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div
                              style={{
                                paddingTop: '20px',
                                width: '100%',
                                textAlign: 'center',
                              }}
                            >
                              <img src={down} style={{ color: 'gray' }} />
                            </div>
                          </div>
                          
                        </div> */}
                      </div>
                    </div>
                    {/* <ChatBody /> */}
                    <div className="col-lg-8 right-chat-section right-chat-custom-height-1">
                      <Tab.Content>
                        <Tab.Pane eventKey={UserIndex}>
                          <div className="heading-chat-section">
                            <div className="left">
                              <div className="left-profile-pic">
                                <Link to="">
                                  {ChatUser && ChatUser.profile_pic ? (
                                    <img
                                      src={ChatUser && ChatUser.profile_pic}
                                      alt=""
                                    />
                                  ) : (
                                    <img src={userDemo} alt="" />
                                  )}
                                </Link>
                              </div>
                              <div className="name">
                                <label htmlFor="">
                                  {ChatUser && ChatUser.full_name}
                                </label>
                              </div>
                            </div>
                            <div className="right">
                              <Link
                                to=""
                                onClick={() => {
                                  setShowMap(true)
                                }}
                                className="me-4"
                              >
                                <img src={call_icon} alt="" />
                              </Link>
                              <Link
                                to=""
                                onClick={() => {
                                  setShowMap(true)
                                }}
                                className="me-2"
                              >
                                <img src={vCall_icon} alt="" />
                              </Link>
                            </div>
                          </div>

                          <div className="chatting-section">
                            <div ref={loadMoreRef}></div>

                            {hasmore ? (
                              <div
                                className="load-btn-chat"
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <button
                                  onClick={() => loadMore()}
                                  id="load-btn"
                                  style={{
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    // backgroundColor: '#91efdc',
                                    padding: '7px 30px',
                                    border: 'none',
                                    color: 'white',
                                    borderRadius: '10px',
                                  }}
                                >
                                  <Link to="">Load More</Link>
                                </button>
                              </div>
                            ) : (
                              <div
                                className="No-more-chats pb-2"
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <p style={{ color: '#49c5b6' }}>
                                  No more chats
                                </p>
                              </div>
                            )}

                            <div className="message-main">
                              {friendChatdata.map((chatData, i) => {
                                return chatData.conversation_from ==
                                  customerData.id ? (
                                  <>
                                    <div key={i} className="send-msg">
                                      <Link to="">
                                        <img
                                          src={double_tick}
                                          alt="double_tick"
                                          className="ms-3"
                                        />
                                      </Link>
                                      {chatData.conversation_message ? (
                                        <div className="send-msg-main">
                                          <p className="msg-text">
                                            {chatData.conversation_message}
                                          </p>
                                          <p className="time-msg">
                                            <Moment format="h:mm:ss a">
                                              {chatData.conversation_datetime}
                                            </Moment>
                                          </p>
                                        </div>
                                      ) : null}
                                      {chatData.files &&
                                        chatData.files.images &&
                                        chatData.files.images.map(
                                          (file, index) => {
                                            return (
                                              <div
                                                className="send-msg"
                                                key={index}
                                              >
                                                <div className="send-msg-main">
                                                  <div className="msg-img">
                                                    <img
                                                      src={file.file_path}
                                                      key={
                                                        'file' + file.file_id
                                                      }
                                                      alt=""
                                                    />
                                                  </div>
                                                  <p className="time-msg">
                                                    <Moment format="h:mm:ss a">
                                                      {
                                                        chatData.conversation_datetime
                                                      }
                                                    </Moment>
                                                  </p>
                                                </div>
                                              </div>
                                            )
                                          },
                                        )}
                                    </div>
                                  </>
                                ) : (
                                  <div
                                    className="receive-msg"
                                    id="FrindsSingleChat"
                                  >
                                    <div className="right-receive-msg">
                                      {chatData.conversation_message ? (
                                        <div className="receive-msg-main">
                                          <p className="msg-text">
                                            {chatData.conversation_message}
                                          </p>
                                          <p className="time-msg">
                                            <Moment format="h:mm:ss a">
                                              {chatData.conversation_datetime}
                                            </Moment>
                                          </p>
                                        </div>
                                      ) : null}
                                      {chatData.files &&
                                        chatData.files.images &&
                                        chatData.files.images.map(
                                          (filedata, index) => {
                                            return (
                                              <div
                                                className="receive-msg"
                                                key={index}
                                              >
                                                <div className="receive-msg-main">
                                                  <div className="msg-img">
                                                    <img
                                                      src={filedata.file_path}
                                                      key={
                                                        'filedata' + filedata.id
                                                      }
                                                      alt=""
                                                    />
                                                  </div>
                                                  <p className="time-msg">
                                                    <Moment format="h:mm:ss a">
                                                      {
                                                        chatData.conversation_datetime
                                                      }
                                                    </Moment>
                                                  </p>
                                                </div>
                                              </div>
                                            )
                                          },
                                        )}
                                    </div>
                                  </div>
                                )
                              })}

                              {newmessage.map((NewData, i) => {
                                return NewData.conversation_from ==
                                  customerData.id ? (
                                  <div key={i} className="send-msg">
                                    <Link to="">
                                      <img
                                        src={double_tick}
                                        alt="double_tick"
                                        className="ms-3"
                                      />
                                    </Link>
                                    {NewData.conversation_message ? (
                                      <div className="send-msg-main">
                                        <p className="msg-text">
                                          {NewData.conversation_message}
                                        </p>
                                        <p className="time-msg">
                                          <Moment format="h:mm:ss a">
                                            {NewData.conversation_datetime}
                                          </Moment>
                                        </p>
                                        {NewData.files.map((file, index) => {
                                          return (
                                            <div
                                              className="send-msg"
                                              key={index}
                                            >
                                              <div className="send-msg-main">
                                                <div className="msg-img">
                                                  <img
                                                    src={file.file_path}
                                                    key={'file' + file.file_id}
                                                    alt=""
                                                  />
                                                </div>
                                                <p className="time-msg">
                                                  <Moment format="h:mm:ss a">
                                                    {
                                                      NewData.conversation_datetime
                                                    }
                                                  </Moment>
                                                </p>
                                              </div>
                                            </div>
                                          )
                                        })}
                                      </div>
                                    ) : null}
                                    {NewData.files &&
                                      NewData.files &&
                                      NewData.files.map((filedata, index) => {
                                        return (
                                          <div className="send-msg" key={index}>
                                            <div className="send-msg-main">
                                              <div className="msg-img">
                                                <img
                                                  src={filedata.file_path}
                                                  key={
                                                    'file' + filedata.file_id
                                                  }
                                                  alt=""
                                                />
                                              </div>
                                              <p className="time-msg">
                                                <Moment format="h:mm:ss a">
                                                  {
                                                    NewData.conversation_datetime
                                                  }
                                                </Moment>
                                              </p>
                                            </div>
                                          </div>
                                        )
                                      })}
                                  </div>
                                ) : (
                                  <div className="receive-msg">
                                    <div className="right-receive-msg">
                                      {NewData.conversation_message ? (
                                        <div className="receive-msg-main">
                                          <p className="msg-text">
                                            {NewData.conversation_message}
                                          </p>
                                        </div>
                                      ) : null}
                                      {NewData.files &&
                                        NewData.files &&
                                        NewData.files.map((filedata, index) => {
                                          return (
                                            <div
                                              className="receive-msg"
                                              key={index}
                                            >
                                              <div className="receive-msg-main">
                                                <div className="msg-img">
                                                  <img
                                                    src={filedata.file_path}
                                                    key={
                                                      'filedata' + filedata.id
                                                    }
                                                    alt=""
                                                  />
                                                </div>
                                                <p className="time-msg">
                                                  <Moment format="h:mm:ss a">
                                                    {
                                                      filedata.conversation_datetime
                                                    }
                                                  </Moment>
                                                </p>
                                              </div>
                                            </div>
                                          )
                                        })}
                                    </div>
                                  </div>
                                )
                              })}
                              <div ref={lastMessageRef} />
                            </div>
                          </div>

                          <div className="send_section_btn_inputs">
                            <div className="chat_bodyFooter">
                              <div
                                className="chat_footer_inputWrapper "
                                style={{}}
                              >
                                <input
                                  className="chat-body-footer"
                                  type="text"
                                  placeholder="Type here..."
                                  // value={message}
                                  // onChange={(e) => setMessage(e.target.value)}
                                  value={message}
                                  onChange={(e) => setMessage(e.target.value)}
                                  onKeyUp={(e) => {
                                    if (e.key == 'Enter') sendMessage()
                                  }}
                                />
                              </div>

                              {/* here I dont know which bot will be used so I used buttons only, once confirmed I will make changes accordingly */}

                              <div className="chat_footerControls">
                                <form
                                  action=""
                                  style={{ position: 'relative' }}
                                >
                                  <label htmlFor="file">
                                    <input
                                      autoComplete="off"
                                      multiple
                                      onChange={(e) => {
                                        handleFileUpload(e.target.files)
                                      }}
                                      name="file"
                                      type="file"
                                      id="file"
                                      className="d-none"
                                    />
                                    <img
                                      src={addImage_file_icon}
                                      alt=""
                                      className="input_option_file"
                                    />
                                  </label>

                                  <a>
                                    <img
                                      onClick={sendMessage}
                                      onKeyUp={sendMessage}
                                      src={send_icon}
                                      alt=""
                                      className="send-icon-1-send"
                                    />
                                  </a>
                                </form>
                              </div>
                            </div>
                            <Draggable>
                              <div
                                className="group_callingWrapper"
                                style={{
                                  boxShadow: '0px 3px 12px #0000000F',
                                  display: `${showMap ? 'block' : 'none'}`,
                                }}
                              >
                                <div className="gc_imgWrapper">
                                  <img src={gc_img} alt="" />
                                </div>
                                <h1
                                  style={{
                                    font:
                                      ' normal normal normal 20px/24px SF Pro Text',
                                    color: '#21212C',
                                    letterSpacing: '0px',
                                  }}
                                >
                                  Work Friends
                                </h1>
                                <p style={{ color: 'lightgrey' }}>Calling...</p>
                                <div className="gc_dailup">
                                  <Link to="/">
                                    <img
                                      className="gc_dailup-1"
                                      src={speaker_notselected_icon}
                                      alt=""
                                    />
                                  </Link>
                                  <Link to="/">
                                    <img
                                      className="gc_dailup-2"
                                      src={mute_icon}
                                      alt=""
                                    />
                                  </Link>
                                  <Link to="/groups">
                                    <img
                                      className="gc_dailup-3"
                                      src={endcall_icon}
                                      alt=""
                                    />
                                  </Link>
                                </div>
                              </div>
                            </Draggable>
                          </div>
                        </Tab.Pane>
                        {/*  Demo */}
                        {showResults ? null : (
                          <Tab.Pane eventKey="1">
                            <p
                              style={{
                                fontSize: '20px',
                                margin: 'auto',
                                marginTop: '40px',
                              }}
                              className=" text-center justify-content-center"
                            >
                              Follow friends and then click on the the plus icon
                              to chat
                            </p>
                          </Tab.Pane>
                        )}
                      </Tab.Content>
                    </div>
                  </div>
                </Tab.Container>
              </div>
            </Tab>
            <Tab eventKey="Groups" title="Groups">
              <div id="main-chat">
                <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                  <div className="row d-flex flex-wrap justify-content-around">
                    <div className="col-lg-3 left-chat-section">
                      <div className="chat-user-lists">
                        <Nav variant="pills" className="flex-column">
                          <div className="pinned-section">
                            <div className="left">
                              <label htmlFor="">Messages</label>
                            </div>
                          </div>
                          {GroupList.length > 0 ? (
                            GroupList.map((itemGroup, index) => {
                              return (
                                <Nav.Item>
                                  <Nav.Link
                                    eventKey={index + 1}
                                    onClick={() => {
                                      setGroupChatdata([])
                                      setShowResults(true)
                                      setTotal(itemGroup.MembersArray)
                                      setGroupTaglist(itemGroup.MembersArray)
                                      setPageGroup(1)
                                      handleGroupHistery(itemGroup.group_id)
                                      setChatUserGroup(itemGroup)
                                      setIndexGroup(index + 1)

                                      setnewmessageGroup([])
                                    }}
                                  >
                                    <div className="user-chat-tab">
                                      <div className="left-profile-pic">
                                        <Link to="">
                                          {itemGroup.group_image ? (
                                            <img
                                              src={itemGroup.group_image}
                                              alt=""
                                            />
                                          ) : (
                                            <img src={userDemo} alt="" />
                                          )}
                                        </Link>
                                      </div>

                                      <div className="right-name-date ">
                                        <div className="top-section ">
                                          <div className="name ">
                                            <label htmlFor="">
                                              {' '}
                                              {itemGroup.group_name}
                                            </label>
                                          </div>
                                          <div></div>
                                          <div className="date">
                                            {/* <label htmlFor="">10:26</label> */}
                                          </div>
                                        </div>

                                        <div className="bottom-section ">
                                          <label htmlFor="">
                                            {/* {itemGroup.last_recived_message} */}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </Nav.Link>
                                </Nav.Item>
                              )
                            })
                          ) : (
                            <p
                              style={{
                                fontSize: '20px',
                                margin: 'auto',
                                marginTop: '40px',
                              }}
                              className=" text-center justify-content-center"
                            >
                              Oops! It doesn't look like there are any Friend in
                              your area.
                              <br /> Why don't you try expanding your search
                              criteria?
                            </p>
                          )}
                        </Nav>
                      </div>
                      <div
                        className="button-container"
                        style={{
                          marginTop: '20px',
                        }}
                      >
                        <button
                          style={{
                            color: '#21212C',
                            fontSize: '14px',
                            fontWeight: 600,
                            // backgroundColor: '#00FFDC',

                            height: '50px',
                            width: '100%',
                            border: 'none',
                            borderRadius: '25px',
                          }}
                        >
                          <Link to="/create_group"> Create Group</Link>
                        </button>
                      </div>
                    </div>
                    {/* <GroupChatNew /> */}
                    <div className="col-lg-8 right-chat-section right-chat-custom-height-1">
                      <Tab.Content>
                        <Tab.Pane eventKey={UserIndexGroup}>
                          <div className="heading-chat-section">
                            <div className="left">
                              <div className="left-profile-pic">
                                <Link to="">
                                  <img
                                    src={
                                      ChatUserGroup.group_image
                                        ? ChatUserGroup.group_image
                                        : ChatUserIntersted.group_image
                                    }
                                    alt=""
                                  />
                                </Link>
                              </div>
                              <div className="name">
                                <label htmlFor="">
                                  {ChatUserGroup && ChatUserGroup.group_name}{' '}
                                  <br />
                                  <div
                                    style={{
                                      marginLeft: '10px',
                                      fontSize: '12px',
                                    }}
                                  >
                                    {Total.length}
                                  </div>
                                </label>
                              </div>
                            </div>

                            <div className="right">
                              <Link
                                to=""
                                // onClick={() => {
                                //   setShowMap(true)
                                // }}
                                className="me-4"
                              >
                                <img src={call_icon} alt="" />
                              </Link>
                              <Link
                                to=""
                                // onClick={() => {
                                //   setShowMap(true)
                                // }}
                                className="me-2"
                              >
                                <img src={vCall_icon} alt="" />
                              </Link>
                              <div className="leaveGropu">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant=""
                                    id="dropdown-basic"
                                  >
                                    <img src={Three_dot} alt="" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item href="#">
                                      <Link
                                        //  to="/EditGropu"
                                        to={
                                          '/create_group/' +
                                          ChatUserGroup.group_id
                                        }
                                      >
                                        Edit Group
                                      </Link>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                          <div ref={loadMoreReGroup}></div>

                          {hasmoreGroup ? (
                            <div
                              className="load-btn-chat"
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop:"10px"
                              }}
                            >
                              <button
                                onClick={() => loadMoreHistery()}
                                id="load-btn"
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 600,
                                  // backgroundColor: '#91efdc',
                                  padding: '7px 30px',
                                  border: 'none',
                                  color: 'white',
                                  borderRadius: '10px',
                                }}
                              >
                                <Link to="">Load More</Link>
                              </button>
                            </div>
                          ) : (
                            <div
                              className="No-more-chats pb-2"
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <p style={{ color: '#49c5b6' }}>No more chats</p>
                            </div>
                          )}
                          <div
                            className="chatting-section"
                            id="customWidthForGroups"
                          >
                            <div className="message-main">
                              {GroupChatdata.map((chatData, i) => {
                                return chatData.sender_id == customerData.id ? (
                                  <>
                                    <div key={i} className="send-msg">
                                      <Link to="">
                                        <img
                                          src={double_tick}
                                          alt="double_tick"
                                          className="ms-3"
                                        />
                                      </Link>
                                      {chatData.conversation_tagged_user_id ? (
                                        <div className="send-msg-main">
                                          <Link
                                            to={
                                              '/connectionGroup/' +
                                              chatData.conversation_tagged_user_id
                                            }
                                          >
                                            <p className="msg-text">
                                              {/* {chatData.conversation_message} */}
                                              <Highlighter
                                                highlightClassName="YourHighlightClass"
                                                searchWords={TextColor}
                                                autoEscape={true}
                                                textToHighlight={
                                                  chatData.conversation_message
                                                }
                                              />
                                            </p>
                                          </Link>

                                          <p className="time-msg">
                                            <Moment format="h:mm:ss a">
                                              {chatData.conversation_datetime}
                                            </Moment>
                                          </p>
                                        </div>
                                      ) : (
                                        <div className="send-msg-main">
                                          <p className="msg-text">
                                            {/* {chatData.conversation_message} */}
                                            <Highlighter
                                              highlightClassName="YourHighlightClass"
                                              searchWords={TextColor}
                                              autoEscape={true}
                                              textToHighlight={
                                                chatData.conversation_message
                                              }
                                            />
                                          </p>

                                          <p className="time-msg">
                                            <Moment format="h:mm:ss a">
                                              {chatData.conversation_datetime}
                                            </Moment>
                                          </p>
                                        </div>
                                      )}

                                      {chatData.fileData &&
                                        chatData.fileData.images &&
                                        chatData.fileData.images.map(
                                          (file, index) => {
                                            return (
                                              <div
                                                className="send-msg"
                                                key={index}
                                              >
                                                <div className="send-msg-main">
                                                  <div className="msg-img">
                                                    <img
                                                      src={file.file_path}
                                                      key={
                                                        'file' + file.file_id
                                                      }
                                                      alt=""
                                                    />
                                                  </div>
                                                  <p className="time-msg">
                                                    <Moment format="h:mm:ss a">
                                                      {
                                                        chatData.conversation_datetime
                                                      }
                                                    </Moment>
                                                  </p>
                                                </div>
                                              </div>
                                            )
                                          },
                                        )}
                                    </div>
                                  </>
                                ) : (
                                  <div
                                    className="receive-msg"
                                    id="FrindsSingleChat"
                                  >
                                    <div className="msg-with-img">
                                      <img
                                        src={chatData.sender_profile}
                                        alt=""
                                      />
                                      <p className="Sender_name">
                                        {chatData.sender_name}
                                      </p>
                                    </div>
                                    <div className="right-receive-msg">
                                      {chatData.conversation_tagged_user_id ? (
                                        <div className="receive-msg-main">
                                          <Link
                                            to={
                                              '/connectionGroup/' +
                                              chatData.conversation_tagged_user_id
                                            }
                                          >
                                            <p className="msg-text">
                                              {/* {chatData.conversation_message} */}
                                              <Highlighter
                                                highlightClassName="YourHighlightClass"
                                                searchWords={TextColor}
                                                autoEscape={true}
                                                textToHighlight={
                                                  chatData.conversation_message
                                                }
                                              />
                                            </p>
                                          </Link>
                                          <p className="time-msg">
                                            <Moment format="h:mm:ss a">
                                              {chatData.conversation_datetime}
                                            </Moment>
                                          </p>
                                        </div>
                                      ) : (
                                        <div className="receive-msg-main">
                                          <p className="msg-text">
                                            {/* {chatData.conversation_message} */}
                                            <Highlighter
                                              highlightClassName="YourHighlightClass"
                                              searchWords={TextColor}
                                              autoEscape={true}
                                              textToHighlight={
                                                chatData.conversation_message
                                              }
                                            />
                                          </p>

                                          <p className="time-msg">
                                            <Moment format="h:mm:ss a">
                                              {chatData.conversation_datetime}
                                            </Moment>
                                          </p>
                                        </div>
                                      )}
                                      {chatData.fileData &&
                                        chatData.fileData.images &&
                                        chatData.fileData.images.map(
                                          (filedata, index) => {
                                            return (
                                              <div
                                                className="receive-msg"
                                                key={index}
                                              >
                                                <div className="receive-msg-main">
                                                  <div className="msg-img">
                                                    <img
                                                      src={filedata.file_path}
                                                      key={
                                                        'filedata' + filedata.id
                                                      }
                                                      alt=""
                                                    />
                                                  </div>
                                                  <p className="time-msg">
                                                    <Moment format="h:mm:ss a">
                                                      {
                                                        chatData.conversation_datetime
                                                      }
                                                    </Moment>
                                                  </p>
                                                </div>
                                              </div>
                                            )
                                          },
                                        )}
                                    </div>
                                  </div>
                                )
                              })}

                              {newmessageGroup &&
                                newmessageGroup.map((NewData, i) => {
                                  return NewData.sender_id ==
                                    customerData.id ? (
                                    <div key={i} className="send-msg">
                                      <Link to="">
                                        <img
                                          src={double_tick}
                                          alt="double_tick"
                                          className="ms-3"
                                        />
                                      </Link>
                                      {NewData.conversation_tagged_user_id ? (
                                        <div className="send-msg-main">
                                          <Link
                                            to={
                                              '/connectionGroup/' +
                                              NewData.conversation_tagged_user_id
                                            }
                                          >
                                            <p className="msg-text">
                                              {/* {NewData.conversation_message} */}
                                              <Highlighter
                                                highlightClassName="YourHighlightClass"
                                                searchWords={TextColor}
                                                autoEscape={true}
                                                textToHighlight={
                                                  NewData.conversation_message
                                                }
                                              />
                                            </p>
                                          </Link>
                                          <p className="time-msg">
                                            <Moment format="h:mm:ss a">
                                              {NewData.conversation_datetime}
                                            </Moment>
                                          </p>
                                          {/* {NewData.fileData.map((file, index) => {
                                          return (
                                            <div
                                              className="send-msg"
                                              key={index}
                                            >
                                              <div className="send-msg-main">
                                                <div className="msg-img">
                                                  <img
                                                    src={file.file_path}
                                                    key={'file' + file.file_id}
                                                    alt=""
                                                  />
                                                </div>
                                                <p className="time-msg">
                                                  <Moment format="h:mm:ss a">
                                                    {
                                                      NewData.conversation_datetime
                                                    }
                                                  </Moment>
                                                </p>
                                              </div>
                                            </div>
                                          )
                                        })} */}
                                        </div>
                                      ) : (
                                        <div className="send-msg-main">
                                          <p className="msg-text">
                                            {/* {NewData.conversation_message} */}
                                            <Highlighter
                                              highlightClassName="YourHighlightClass"
                                              searchWords={TextColor}
                                              autoEscape={true}
                                              textToHighlight={
                                                NewData.conversation_message
                                              }
                                            />
                                          </p>

                                          <p className="time-msg">
                                            <Moment format="h:mm:ss a">
                                              {NewData.conversation_datetime}
                                            </Moment>
                                          </p>
                                          {/* {NewData.fileData.map((file, index) => {
                                          return (
                                            <div
                                              className="send-msg"
                                              key={index}
                                            >
                                              <div className="send-msg-main">
                                                <div className="msg-img">
                                                  <img
                                                    src={file.file_path}
                                                    key={'file' + file.file_id}
                                                    alt=""
                                                  />
                                                </div>
                                                <p className="time-msg">
                                                  <Moment format="h:mm:ss a">
                                                    {
                                                      NewData.conversation_datetime
                                                    }
                                                  </Moment>
                                                </p>
                                              </div>
                                            </div>
                                          )
                                        })} */}
                                        </div>
                                      )}
                                      {NewData.fileData &&
                                        NewData.fileData.images &&
                                        NewData.fileData.images.map(
                                          (filedataItem, index) => {
                                            return (
                                              <div
                                                className="send-msg"
                                                key={index}
                                              >
                                                <div className="send-msg-main">
                                                  <div className="msg-img">
                                                    <img
                                                      src={
                                                        filedataItem.file_path
                                                      }
                                                      key={
                                                        'file' +
                                                        filedataItem.file_id
                                                      }
                                                      alt=""
                                                    />
                                                  </div>
                                                  <p className="time-msg">
                                                    <Moment format="h:mm:ss a">
                                                      {
                                                        NewData.conversation_datetime
                                                      }
                                                    </Moment>
                                                  </p>
                                                </div>
                                              </div>
                                            )
                                          },
                                        )}
                                    </div>
                                  ) : (
                                    <div className="receive-msg">
                                      <div className="right-receive-msg">
                                        {NewData.conversation_tagged_user_id ? (
                                          <div className="receive-msg-main">
                                            <Link
                                              to={
                                                '/connectionGroup/' +
                                                NewData.conversation_tagged_user_id
                                              }
                                            >
                                              <p className="msg-text">
                                                {/* {NewData.conversation_message} */}
                                                <Highlighter
                                                  highlightClassName="YourHighlightClass"
                                                  searchWords={TextColor}
                                                  autoEscape={true}
                                                  textToHighlight={
                                                    NewData.conversation_message
                                                  }
                                                />
                                              </p>
                                            </Link>
                                          </div>
                                        ) : (
                                          <div className="receive-msg-main">
                                            <Link
                                              to={
                                                '/connectionGroup/' +
                                                NewData.conversation_tagged_user_id
                                              }
                                            >
                                              <p className="msg-text">
                                                {/* {NewData.conversation_message} */}
                                                <Highlighter
                                                  highlightClassName="YourHighlightClass"
                                                  searchWords={TextColor}
                                                  autoEscape={true}
                                                  textToHighlight={
                                                    NewData.conversation_message
                                                  }
                                                />
                                              </p>
                                            </Link>
                                          </div>
                                        )}
                                        {NewData.files &&
                                          NewData.files.images &&
                                          NewData.files.images.map(
                                            (filedata, index) => {
                                              return (
                                                <div
                                                  className="receive-msg"
                                                  key={index}
                                                >
                                                  <div className="receive-msg-main">
                                                    <div className="msg-img">
                                                      <img
                                                        src={filedata.file_path}
                                                        key={
                                                          'filedata' +
                                                          filedata.id
                                                        }
                                                        alt=""
                                                      />
                                                    </div>
                                                    <p className="time-msg">
                                                      <Moment format="h:mm:ss a">
                                                        {
                                                          filedata.conversation_datetime
                                                        }
                                                      </Moment>
                                                    </p>
                                                  </div>
                                                </div>
                                              )
                                            },
                                          )}
                                      </div>
                                    </div>
                                  )
                                })}
                              <div ref={lastMessageRefGroup} />
                            </div>
                          </div>
                          <div className="send_section_btn_inputs">
                            <div className="chat_bodyFooter">
                              <div
                                className="chat_footer_inputWrapper "
                              >
                                {/* <input
                                  className="chat-body-footer"
                                  type="text"
                                  placeholder="Type here..."
                                  // value={message}
                                  // onChange={(e) => setMessage(e.target.value)}
                                  value={messageGroup}
                                  onChange={(e) =>
                                    setMessageGroup(e.target.value)
                                  }
                                  onKeyUp={(e) => {
                                    if (e.key == 'Enter') sendMessageGroup()
                                  }}
                                /> */}

                                <MentionsInput
                                  allowSpaceInQuery={true}
                                  placeholder="Type here..."
                                    singleLine
                                  allowSuggestionsAboveCursor={true}
                                  forceSuggestionsAboveCursor={true} // <-- this does not work when suggestionsPortalHost is not used
                                  scrollFocusedIntoView={true}
                                  style={GpmentionInputstyles}
                                  value={messageGroup}
                                  onChange={(e) =>
                                    setMessageGroup(e.target.value)
                                  }
                                  onKeyUp={(e) => {
                                    if (e.key == 'Enter') sendMessageGroup()
                                  }}
                                >
                                  <Mention
                                    style={GroupMentuinStyles}
                                    data={GroupTagItem}
                                    // data={users}
                                    appendSpaceOnAdd={true}
                                    scrollFocusedIntoView={true}
                                  />
                                </MentionsInput>
                              </div>

                              {/* here I dont know which bot will be used so I used buttons only, once confirmed I will make changes accordingly */}

                              <div className="chat_footerControls">
                                <form
                                  action=""
                                  style={{ position: 'relative' }}
                                >
                                  <label htmlFor="file2">
                                    <input
                                      autoComplete="off"
                                      multiple
                                      onChange={(e) => {
                                        handleUploadGroup(e.target.files)
                                      }}
                                      name="file"
                                      type="file"
                                      id="file2"
                                      className="d-none"
                                    />
                                    <img
                                      src={addImage_file_icon}
                                      alt=""
                                      className="input_option_file"
                                    />
                                  </label>

                                  <input
                                    type="file"
                                    className="footer_input "
                                    placeholder="Type a message"
                                    // onChange={(e) => uploadFile(e.target.files[0])}
                                    style={{ display: 'none' }}
                                    id="uploadFile2"
                                  />

                                  {/* <button className="chat_footerControls-2">
                    <img
                      className="chat_footerControls-2-mic"
                      src={micrphone_icon}
                      alt=""
                    />
                  </button> */}
                                  <a>
                                    <img
                                      onClick={sendMessageGroup}
                                      onKeyUp={sendMessageGroup}
                                      src={send_icon}
                                      alt=""
                                      className="send-icon-1-send"
                                    />
                                  </a>
                                </form>
                              </div>
                            </div>
                            <Draggable>
                              <div
                                className="group_callingWrapper"
                                style={{
                                  boxShadow: '0px 3px 12px #0000000F',
                                  display: `${showMap ? 'block' : 'none'}`,
                                }}
                              >
                                <div className="gc_imgWrapper">
                                  <img src={gc_img} alt="" />
                                </div>
                                <h1
                                  style={{
                                    font:
                                      ' normal normal normal 20px/24px SF Pro Text',
                                    color: '#21212C',
                                    letterSpacing: '0px',
                                  }}
                                >
                                  Work Friends
                                </h1>
                                <p style={{ color: 'lightgrey' }}>Calling...</p>
                                <div className="gc_dailup">
                                  <Link to="/">
                                    <img
                                      className="gc_dailup-1"
                                      src={speaker_notselected_icon}
                                      alt=""
                                    />
                                  </Link>
                                  <Link to="/">
                                    <img
                                      className="gc_dailup-2"
                                      src={mute_icon}
                                      alt=""
                                    />
                                  </Link>
                                  <Link to="/groups">
                                    <img
                                      className="gc_dailup-3"
                                      src={endcall_icon}
                                      alt=""
                                    />
                                  </Link>
                                </div>
                              </div>
                            </Draggable>
                          </div>
                        </Tab.Pane>
                        {showResults ? null : (
                          <Tab.Pane eventKey="1">
                            <p
                              style={{
                                fontSize: '20px',
                                margin: 'auto',
                                marginTop: '40px',
                              }}
                              className=" text-center justify-content-center"
                            >
                              Follow friends and then click on the the plus icon
                              to chat
                            </p>
                          </Tab.Pane>
                        )}
                      </Tab.Content>
                    </div>
                  </div>
                </Tab.Container>
              </div>
            </Tab>
            <Tab eventKey="Intersted" title="Interested">
              <div id="main-chat">
                <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                  <div className="row d-flex flex-wrap justify-content-around">
                    <div className="col-lg-3 left-chat-section">
                      <div className="chat-user-lists" id="customise_intrest">
                        <Nav variant="pills" className="flex-column">
                          <div className="pinned-section">
                            <div className="left">
                              <label htmlFor="">Messages</label>
                            </div>
                          </div>
                          {InterstedList.length > 0 ? (
                            InterstedList.map((itemIntersted, index) => {
                              return (
                                <Nav.Item>
                                  <Nav.Link
                                    eventKey={index + 1}
                                    onClick={() => {
                                      setShowResultsUrl(true)
                                      setInterstedChatdata([])
                                      setInterstedItem('')
                                      handleIntersted(
                                        itemIntersted.receiver_data.id,
                                      )
                                      setChatUserIntersted(
                                        itemIntersted.receiver_data,
                                      )
                                      setIndexIntersted(index + 1)
                                      setPageIntersted(1)
                                      setnewmessageInt([])
                                    }}
                                  >
                                    <div className="user-chat-tab">
                                      <div className="left-profile-pic">
                                        <Link to="">
                                          {itemIntersted.receiver_data
                                            .profile_pic ? (
                                            <img
                                              src={
                                                itemIntersted.receiver_data
                                                  .profile_pic
                                              }
                                              alt=""
                                            />
                                          ) : (
                                            <img src={userDemo} alt="" />
                                          )}
                                        </Link>
                                      </div>

                                      <div className="right-name-date ">
                                        <div className="top-section ">
                                          <div className="name ">
                                            <label htmlFor="">
                                              {' '}
                                              {itemIntersted.receiver_data.name}
                                            </label>
                                          </div>
                                          <div></div>
                                          <div className="date">
                                            {/* <label htmlFor="">10:26</label> */}
                                          </div>
                                        </div>

                                        <div className="bottom-section ">
                                          <label htmlFor="">
                                            {itemIntersted.last_recived_message}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </Nav.Link>
                                </Nav.Item>
                              )
                            })
                          ) : (
                            <p
                              style={{
                                fontSize: '20px',
                                margin: 'auto',
                                marginTop: '40px',
                              }}
                              className=" text-center justify-content-center"
                            >
                              Oops! It doesn't look like there are any Friend in
                              your area.
                              <br /> Why don't you try expanding your search
                              criteria?
                            </p>
                          )}
                        </Nav>
                      </div>
                    </div>
                    {/* <InterestChat /> */}
                    <div className="col-lg-8 right-chat-section right-chat-custom-height-1">
                      <Tab.Content>
                        <Tab.Pane eventKey={UserIndexIntersted}>
                          <div className="heading-chat-section">
                            <div className="left">
                              <div className="left-profile-pic">
                                <Link to="">
                                  <img
                                    src={
                                      InterstedItem.profile_pic
                                        ? InterstedItem.profile_pic
                                        : ChatUserIntersted.profile_pic
                                    }
                                    alt=""
                                  />
                                </Link>
                              </div>
                              <div className="name">
                                <label htmlFor="">
                                  {InterstedItem.full_name
                                    ? InterstedItem.full_name
                                    : ChatUserIntersted.name}
                                </label>
                              </div>
                            </div>
                            <div className="right">
                              <Link
                                to=""
                                onClick={() => {
                                  setShowMap(true)
                                }}
                                className="me-4"
                              >
                                <img src={call_icon} alt="" />
                              </Link>
                              <Link
                                to=""
                                onClick={() => {
                                  setShowMap(true)
                                }}
                                className="me-2"
                              >
                                <img src={vCall_icon} alt="" />
                              </Link>
                            </div>
                          </div>
                          <div className="chatting-section">
                            <div ref={loadMoreRefNew}></div>

                            {hasmoreIntersted ? (
                              <div
                                className="load-btn-chat"
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <button
                                  onClick={() => loadMoreIntersted()()}
                                  id="load-btn"
                                  style={{
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    // backgroundColor: '#91efdc',
                                    padding: '7px 30px',
                                    border: 'none',
                                    color: 'white',
                                    borderRadius: '10px',
                                  }}
                                >
                                  <Link to="">Load More</Link>
                                </button>
                              </div>
                            ) : (
                              <div
                                className="No-more-chats pb-2"
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <p style={{ color: '#49c5b6' }}>
                                  No more chats
                                </p>
                              </div>
                            )}
                            <div className="message-main">
                              {InterstedChatdata.map((chatData, i) => {
                                return chatData.conversation_from ==
                                  customerData.id ? (
                                  <>
                                    <div key={i} className="send-msg">
                                      <Link to="">
                                        <img
                                          src={double_tick}
                                          alt="double_tick"
                                          className="ms-3"
                                        />
                                      </Link>
                                      {chatData.conversation_message ? (
                                        <div className="send-msg-main">
                                          <p className="msg-text">
                                            {chatData.conversation_message}
                                          </p>
                                          <p className="time-msg">
                                            <Moment format="h:mm:ss a">
                                              {chatData.conversation_datetime}
                                            </Moment>
                                          </p>
                                        </div>
                                      ) : null}
                                      {chatData.files &&
                                        chatData.files.images &&
                                        chatData.files.images.map(
                                          (file, index) => {
                                            return (
                                              <div
                                                className="send-msg"
                                                key={index}
                                              >
                                                <div className="send-msg-main">
                                                  <div className="msg-img">
                                                    <img
                                                      src={file.file_path}
                                                      key={
                                                        'file' + file.file_id
                                                      }
                                                      alt=""
                                                    />
                                                  </div>
                                                  <p className="time-msg">
                                                    <Moment format="h:mm:ss a">
                                                      {
                                                        chatData.conversation_datetime
                                                      }
                                                    </Moment>
                                                  </p>
                                                </div>
                                              </div>
                                            )
                                          },
                                        )}
                                    </div>
                                  </>
                                ) : (
                                  <div
                                    className="receive-msg"
                                    id="FrindsSingleChat"
                                  >
                                    <div className="right-receive-msg">
                                      {chatData.conversation_message ? (
                                        <div className="receive-msg-main">
                                          <p className="msg-text">
                                            {chatData.conversation_message}
                                          </p>
                                          <p className="time-msg">
                                            <Moment format="h:mm:ss a">
                                              {chatData.conversation_datetime}
                                            </Moment>
                                          </p>
                                        </div>
                                      ) : null}
                                      {chatData.files &&
                                        chatData.files.images &&
                                        chatData.files.images.map(
                                          (filedata, index) => {
                                            return (
                                              <div
                                                className="receive-msg"
                                                key={index}
                                              >
                                                <div className="receive-msg-main">
                                                  <div className="msg-img">
                                                    <img
                                                      src={filedata.file_path}
                                                      key={
                                                        'filedata' + filedata.id
                                                      }
                                                      alt=""
                                                    />
                                                  </div>
                                                  <p className="time-msg">
                                                    <Moment format="h:mm:ss a">
                                                      {
                                                        chatData.conversation_datetime
                                                      }
                                                    </Moment>
                                                  </p>
                                                </div>
                                              </div>
                                            )
                                          },
                                        )}
                                    </div>
                                  </div>
                                )
                              })}

                              {newmessageInt.map((NewData, i) => {
                                return NewData.conversation_from ==
                                  customerData.id ? (
                                  <div key={i} className="send-msg">
                                    <Link to="">
                                      <img
                                        src={double_tick}
                                        alt="double_tick"
                                        className="ms-3"
                                      />
                                    </Link>
                                    {NewData.conversation_message ? (
                                      <div className="send-msg-main">
                                        <p className="msg-text">
                                          {NewData.conversation_message}
                                        </p>
                                        <p className="time-msg">
                                          <Moment format="h:mm:ss a">
                                            {NewData.conversation_datetime}
                                          </Moment>
                                        </p>
                                        {NewData.files.map((file, index) => {
                                          return (
                                            <div
                                              className="send-msg"
                                              key={index}
                                            >
                                              <div className="send-msg-main">
                                                <div className="msg-img">
                                                  <img
                                                    src={file.file_path}
                                                    key={'file' + file.file_id}
                                                    alt=""
                                                  />
                                                </div>
                                                <p className="time-msg">
                                                  <Moment format="h:mm:ss a">
                                                    {
                                                      NewData.conversation_datetime
                                                    }
                                                  </Moment>
                                                </p>
                                              </div>
                                            </div>
                                          )
                                        })}
                                      </div>
                                    ) : null}
                                    {NewData.files &&
                                      NewData.files &&
                                      NewData.files.map((filedata, index) => {
                                        return (
                                          <div className="send-msg" key={index}>
                                            <div className="send-msg-main">
                                              <div className="msg-img">
                                                <img
                                                  src={filedata.file_path}
                                                  key={
                                                    'file' + filedata.file_id
                                                  }
                                                  alt=""
                                                />
                                              </div>
                                              <p className="time-msg">
                                                <Moment format="h:mm:ss a">
                                                  {
                                                    NewData.conversation_datetime
                                                  }
                                                </Moment>
                                              </p>
                                            </div>
                                          </div>
                                        )
                                      })}
                                  </div>
                                ) : (
                                  <div className="receive-msg">
                                    <div className="right-receive-msg">
                                      {NewData.conversation_message ? (
                                        <div className="receive-msg-main">
                                          <p className="msg-text">
                                            {NewData.conversation_message}
                                          </p>
                                        </div>
                                      ) : null}
                                      {NewData.files &&
                                        NewData.files &&
                                        NewData.files.map((filedata, index) => {
                                          return (
                                            <div
                                              className="receive-msg"
                                              key={index}
                                            >
                                              <div className="receive-msg-main">
                                                <div className="msg-img">
                                                  <img
                                                    src={filedata.file_path}
                                                    key={
                                                      'filedata' + filedata.id
                                                    }
                                                    alt=""
                                                  />
                                                </div>
                                                <p className="time-msg">
                                                  <Moment format="h:mm:ss a">
                                                    {
                                                      filedata.conversation_datetime
                                                    }
                                                  </Moment>
                                                </p>
                                              </div>
                                            </div>
                                          )
                                        })}
                                    </div>
                                  </div>
                                )
                              })}
                              <div ref={NewlastMessageRef} />
                            </div>
                          </div>
                          <div className="send_section_btn_inputs">
                            <div className="chat_bodyFooter">
                              <div
                                className="chat_footer_inputWrapper "
                                style={{}}
                              >
                                <input
                                  className="chat-body-footer"
                                  type="text"
                                  placeholder="Type here..."
                                  // value={message}
                                  // onChange={(e) => setMessage(e.target.value)}
                                  value={messageintersted}
                                  onChange={(e) =>
                                    setMessageintersted(e.target.value)
                                  }
                                  onKeyUp={(e) => {
                                    if (e.key == 'Enter') sendMessageint()
                                  }}
                                />
                              </div>

                              {/* here I dont know which bot will be used so I used buttons only, once confirmed I will make changes accordingly */}

                              <div className="chat_footerControls">
                                <form
                                  action=""
                                  style={{ position: 'relative' }}
                                >
                                  <label htmlFor="file1">
                                    <input
                                      autoComplete="off"
                                      multiple
                                      onChange={(e) => {
                                        handleUpload(e.target.files)
                                      }}
                                      name="file"
                                      type="file"
                                      id="file1"
                                      className="d-none"
                                    />
                                    <img
                                      src={addImage_file_icon}
                                      alt=""
                                      className="input_option_file"
                                    />
                                  </label>
                                  {/* <button className="chat_footerControls-2">
                                  <img
                                    className="chat_footerControls-2-mic"
                                    src={micrphone_icon}
                                    alt=""
                                  />
                                </button> */}
                                  <a>
                                    <img
                                      onClick={sendMessageint}
                                      onKeyUp={sendMessageint}
                                      src={send_icon}
                                      alt=""
                                      className="send-icon-1-send"
                                    />
                                  </a>
                                </form>
                              </div>
                            </div>
                            {/* <Draggable>
                            <div
                              className="group_callingWrapper"
                              style={{
                                boxShadow: '0px 3px 12px #0000000F',
                                display: `${showMap ? 'block' : 'none'}`,
                              }}
                            >
                              <div className="gc_imgWrapper">
                                <img src={gc_img} alt="" />
                              </div>
                              <h1
                                style={{
                                  font:
                                    ' normal normal normal 20px/24px SF Pro Text',
                                  color: '#21212C',
                                  letterSpacing: '0px',
                                }}
                              >
                                Work Friends
                              </h1>
                              <p style={{ color: 'lightgrey' }}>Calling...</p>
                              <div className="gc_dailup">
                                <Link to="/">
                                  <img
                                    className="gc_dailup-1"
                                    src={speaker_notselected_icon}
                                    alt=""
                                  />
                                </Link>
                                <Link to="/">
                                  <img
                                    className="gc_dailup-2"
                                    src={mute_icon}
                                    alt=""
                                  />
                                </Link>
                                <Link to="/groups">
                                  <img
                                    className="gc_dailup-3"
                                    src={endcall_icon}
                                    alt=""
                                  />
                                </Link>
                              </div>
                            </div>
                          </Draggable> */}
                          </div>
                        </Tab.Pane>

                        {/*  Demo */}
                        {showResultsUrl ? null : (
                          <Tab.Pane eventKey='1'>
                            <p
                              style={{
                                fontSize: '20px',
                                margin: 'auto',
                                marginTop: '40px',
                              }}
                              className=" text-center justify-content-center"
                            >
                              Follow friends and then click on the the plus icon
                              to chat
                            </p>
                          </Tab.Pane>
                        )}
                      </Tab.Content>
                    </div>
                  </div>
                </Tab.Container>
              </div>
            </Tab>
          </Tabs>
        </div>
      )}
    </>
  )
}

export default ChatSidebar

import {useContext, useEffect, useState} from "react";

import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckOutForm";
import { loadStripe } from "@stripe/stripe-js";
import {AppContext} from "../../context/AppContext";

export default function Payment({setOpenPaymentAddModel,onSuccess}) {
    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState("");
    const { baseUrl } = useContext(AppContext);

  
    useEffect(() => {
      fetch(`${baseUrl}/payment/config`).then(async (r) => {
        const { publishableKey } = await r.json();
        setStripePromise(loadStripe(publishableKey));
      });
    }, []);
  
    useEffect(() => {
      fetch(`${baseUrl}/payment/create-payment-intent`, {
        method: "POST",
        body: JSON.stringify({}),
      }).then(async (result) => {
        var { clientSecret } = await result.json();
        setClientSecret(clientSecret);
      });
    }, []);
  
    return (
      <>
        <span>React Stripe and the Payment Element</span>
        {clientSecret && stripePromise && (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm onSuccess={onSuccess} setOpenPaymentAddModel={setOpenPaymentAddModel}/>
          </Elements>
        )}
      </>
    );
  }  
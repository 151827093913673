import React, { useContext, useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { AppContext } from '../../context/AppContext'
import Sidebar from '../../components/Sidebar'
import Header from '../../components/Header'
import GoogleMapReact from 'google-map-react'
// import HousingTabsHeader from "./HousingTabsHeader";
import thumbImg from '../../assets/images/house01.png'
import location_icon from '../../assets/images/location_icon.svg'
import gray_location_icon from '../../assets/images/gray_location_icon.svg'
import tooltip_icon from '../../assets/images/tooltip_icon.svg'
import ApiConfig from '../../api/ApiConfig'
import LoaderCenter from '../../sharedComponent/LoaderCenter'
import {
  postWithAuthCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
  simplePostCall,
} from '../../api/ApiServices'
import { ShareSocial } from 'react-share-social'
import { Modal, Button } from 'react-bootstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import swal from 'sweetalert'

const style = {
  background: '#F5F5F5',
  borderRadius: 3,
  border: 0,
  color: 'white',
  padding: '0 0px',
  // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  height: '200px',
  marginTop: '20px',
  // width: '20px',
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

function HousingDetails() {
  const params = useParams()
  let id = params.id
  const [map, setMap] = useState({
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  })

  const { sidebar, setSidebar } = useContext(AppContext)
  const { customerData } = useContext(AppContext)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [showElement, setShowElement] = React.useState(true)
  const [loading, setLoading] = useState(false)
  const [HouseingDetalis, setHouseingDetalis] = useState([])
  const [showModal, setShow] = useState(false)
  const [ShareData, setShare] = useState('')
  console.log(ShareData)
  const handleClose = () => setShow(false)
  const handleShow = () => {
    
    setShow(true)
    ShareHandal()
  }
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  )
  const [toolOpen, setToolOpen] = useState(false)

  useEffect(() => {
    setTimeout(function () {
      setShowElement(false)
    }, 5000)

    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    geDetails()
  }, [])
  const geDetails = () => {
    setLoading(true)

    simpleGetCallWithErrorResponse(
      ApiConfig.UNIVERSR_HOUSING_ALL_PROPTIE_DETALES + id,
      JSON.stringify({ ...customerData }),
    ).then((res) => {
      console.log(res)
      setLoading(false)
      setHouseingDetalis(res.json)
    })
  }

  const ShareHandal = async () => {
    let requestBody = JSON.stringify({
      dynamicLinkInfo: {
        domainUriPrefix: 'https://myuniverse.page.link',

        link:
          'https://my-univrs.app.redbytes.in/housing-details/' +
          String(id) +
          '/?user=' +
          String(customerData.id),

        androidInfo: {
          androidPackageName: 'com.my_universe',
        },

        iosInfo: {
          iosBundleId: 'org.myUniverse',
        },
      },
    })

    await fetch(
      'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBmt92Aby2xlZWOCIHeTutj8ywpF-4reyw',

      {
        method: 'POST',

        headers: {
          Accept: 'application/json',

          'Content-Type': 'application/json',
        },

        body: requestBody,
      },
    )
      .then((response) => response.json())

      .then((data) => {
        console.log('response', data)
        setShare(data.shortLink)
      })

      .catch((err) => {
        // this.setState({isVisible:false})

        console.log('error', err)
      })
  }

  return (
    <div>
      <Sidebar />

      <main
        id="cxMain"
        className={sidebar ? 'cx-main cx-active' : 'cx-main'}
        style={{
          height: 'calc(100vh - 156px)',
        }}
      >
        {loading ? (
          <LoaderCenter />
        ) : (
          <section
            className="content-main"
            style={{
              height: 'calc(100vh - 156px)',
            }}
          >
            <Modal
              className="custom-dialog"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={showModal}
              onHide={handleClose}
            >
              <Modal.Header
                style={{ padding: '12px', width: '99%' }}
                closeButton
              >
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
              {/* Are you sure you want to cancel this Friend Request? */}
              <div class="modal-body" style={{ marginTop: '-37px' }}>
                <ShareSocial
                  style={style}
                  url={ShareData}
                  socialTypes={[
                    'facebook',
                    'twitter',
                    'reddit',
                    'linkedin',
                    'email',
                    'pinterest',
                  ]}
                />
                <CopyToClipboard
                    text={ShareData}
                    onCopy={() => swal('Copied to Clipboard')}
                  >
                    <Link to="#" className='copy-icon'>
                      <i class="fa-solid fa-copy"></i>
                    </Link>
                  </CopyToClipboard>
              </div>
            </Modal.Body>
            </Modal>
            {HouseingDetalis &&
              HouseingDetalis.length &&
              HouseingDetalis.map((HouseingListMange, index) => {
                return (
                  <div key={'HouseingListMange' + index} className="row">
                    <div
                      className="col-xl-7 detail-section col-12 order-2 order-xl-1"
                      style={{
                        height: 'calc(100vh - 156px)',
                        overflow: 'hidden auto',
                      }}
                    >
                      <div
                        className="gbl_thumbWrapper gbl_thumbWrapper02"
                        style={{ marginTop: '0 ' }}
                      >
                        <div
                          className="img_thumbWrapper img_thumbWrapperAdj01"
                          style={{ padding: '20px' }}
                        >
                          <Link to="/house_listing/all">
                            <i class="fa-solid fa-arrow-left"></i>
                          </Link>

                          <div class="dropdown dot-button">
                            <button
                              class="btn"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style={{
                                height: '34px',
                                width: '34px',
                                color: 'black',
                                border:'none'
                              }}
                            >
                              <i class="fa-solid fa-ellipsis-vertical"></i>
                            </button>
                            <ul
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              
                                <li>
                                  <Link
                                    onClick={handleShow}
                                    to="#"
                                    class="dropdown-item"
                                    href="#"
                                  >
                                    Share
                                  </Link>
                                </li>
                          
                            </ul>
                          </div>
                          {/* <img
                          className="thumbImage"
                          src={thumbImg}
                          alt=""
                          style={{ objectFit: "cover" }}
                        /> */}
                          {HouseingListMange.property_image[0] ? (
                            <img
                              src={HouseingListMange.property_image[0]}
                              alt=""
                              className="thumbImage"
                              style={{ objectFit: 'cover' }}
                            />
                          ) : (
                            <img
                              src={thumbImg}
                              alt=""
                              className="thumbImage"
                              style={{ objectFit: 'cover' }}
                            />
                          )}
                        </div>

                        <div className="gbl_thumbContent">
                          <div className="detail-heading">
                            <h1
                              style={{
                                color: '#1D1D25',
                                fontSize: '20px',
                                fontWeight: 'bold',
                              }}
                            >
                              {HouseingListMange.title}
                            </h1>
                            <div className="icons-container d-flex align-items-center">
                              <div className="details-actions ms-4">
                                {/* <Link
                                  className=""
                                  to=""
                                  onClick={() => setToolOpen(!toolOpen)}
                                >
                                  <img src={tooltip_icon} alt="" />
                                </Link> */}
                              </div>
                            </div>
                          </div>

                          <h6
                            style={{
                              color: '#1D1D25',
                              fontSize: '16px ',
                              fontWeight: '600',
                            }}
                          >
                            by{' '}
                            <Link to="" style={{ textDecoration: 'underline' }}>
                              {HouseingListMange.posted_by_name}
                            </Link>
                          </h6>
                          <div className="row mt-2">
                            <div className="col-5">
                              <span
                                style={{
                                  color: '#707070',
                                  fontSize: '16px',
                                  fontWeight: 'normal',
                                }}
                              >
                                Monthly Rent
                              </span>
                            </div>
                            <div className="col-7">
                              <span
                                style={{
                                  color: '#1D1D25',
                                  fontSize: '16px',
                                  fontWeight: '600',
                                }}
                              >
                                £ {HouseingListMange.monthly_rent}
                              </span>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-5">
                              <span
                                style={{
                                  color: '#707070',
                                  fontSize: '16px',
                                  fontWeight: 'normal',
                                }}
                              >
                                Deposit
                              </span>
                            </div>
                            <div className="col-7">
                              <span
                                style={{
                                  color: '#1D1D25',
                                  fontSize: '16px',
                                  fontWeight: '600',
                                }}
                              >
                                £ {HouseingListMange.deposit}
                              </span>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-5">
                              <span
                                style={{
                                  color: '#707070',
                                  fontSize: '16px',
                                  fontWeight: 'normal',
                                }}
                              >
                                Availability
                              </span>
                            </div>
                            <div className="col-7">
                              <span
                                style={{
                                  color: '#1D1D25',
                                  fontSize: '16px',
                                  fontWeight: '600',
                                }}
                              >
                                {HouseingListMange.availability}
                              </span>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-5">
                              <span
                                style={{
                                  color: '#707070',
                                  fontSize: '16px',
                                  fontWeight: 'normal',
                                }}
                              >
                                Number of rooms
                              </span>
                            </div>
                            <div className="col-7">
                              <span
                                style={{
                                  color: '#1D1D25',
                                  fontSize: '16px',
                                  fontWeight: '600',
                                }}
                              >
                                {HouseingListMange.numbers_of_rooms}
                              </span>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-5">
                              <span
                                style={{
                                  color: '#707070',
                                  fontSize: '16px',
                                  fontWeight: 'normal',
                                }}
                              >
                                Property Type
                              </span>
                            </div>
                            <div className="col-7">
                              <span
                                style={{
                                  color: '#1D1D25',
                                  fontSize: '16px',
                                  fontWeight: '600',
                                }}
                              >
                                {HouseingListMange.type}
                              </span>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-5">
                              <span
                                style={{
                                  color: '#707070',
                                  fontSize: '16px',
                                  fontWeight: 'normal',
                                }}
                              >
                                Gender
                              </span>
                            </div>
                            <div className="col-7">
                              <span
                                style={{
                                  color: '#1D1D25',
                                  fontSize: '16px',
                                  fontWeight: '600',
                                }}
                              >
                                {HouseingListMange.gender}
                              </span>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-5">
                              <span
                                style={{
                                  color: '#707070',
                                  fontSize: '16px',
                                  fontWeight: 'normal',
                                }}
                              >
                                Term
                              </span>
                            </div>
                            <div className="col-7">
                              <span
                                style={{
                                  color: '#1D1D25',
                                  fontSize: '16px',
                                  fontWeight: '600',
                                }}
                              >
                                {HouseingListMange.term}
                              </span>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-5">
                              <span
                                style={{
                                  color: '#707070',
                                  fontSize: '16px',
                                  fontWeight: 'normal',
                                }}
                              >
                                Smokers allowed
                              </span>
                            </div>
                            <div className="col-7">
                              <span
                                style={{
                                  color: '#1D1D25',
                                  fontSize: '16px',
                                  fontWeight: '600',
                                }}
                              >
                                {HouseingListMange.smokers_allowed}
                              </span>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-5">
                              <span
                                style={{
                                  color: '#707070',
                                  fontSize: '16px',
                                  fontWeight: 'normal',
                                }}
                              >
                                Pets allowed
                              </span>
                            </div>
                            <div className="col-7">
                              <span
                                style={{
                                  color: '#1D1D25',
                                  fontSize: '16px',
                                  fontWeight: '600',
                                }}
                              >
                                {HouseingListMange.pets_allowed}
                              </span>
                            </div>
                          </div>
                          <div className="gbl_thumbControlWrapper gbl_thumbControlWrapper02 mt-3">
                            <h1
                              style={{
                                color: '#1D1D25',
                                fontSize: '16px',
                                fontWeight: '600',
                              }}
                            >
                              About Property
                            </h1>
                            <p
                              style={{
                                color: '#707070',
                                fontSize: '14px',
                                fontWeight: '600',
                                marginTop: '10px',
                              }}
                            >
                              {HouseingListMange.property_description}
                            </p>
                            <h1
                              style={{
                                color: '#1D1D25',
                                fontSize: '16px',
                                fontWeight: '600',
                              }}
                            >
                              Property Address
                            </h1>
                            <p
                              style={{
                                color: '#707070',
                                fontSize: '14px',
                                fontWeight: '600',
                                marginTop: '10px',
                              }}
                            >
                              {HouseingListMange.address}
                            </p>
                          </div>
                        </div>
                        <div
                          className={`mainLocWrapper ${
                            windowDimensions.width >= 1200
                              ? 'right-popup'
                              : 'left-popup'
                          }`}
                        >
                          {' '}
                          <div
                            className={`tooltipInfoWrapper ${
                              toolOpen ? 'tool-open' : 'tool-close'
                            }`}
                          >
                            <div
                              className={`triangle-left ${
                                windowDimensions.width >= 1200
                                  ? 'right-icon'
                                  : 'left-icon'
                              } `}
                            ></div>
                            <div className="ttInfoHeader">
                              <h1>Location & Address</h1>
                              <Link
                                to="#"
                                style={{ margin: '0' }}
                                onClick={() => setToolOpen(false)}
                              >
                                <i class="fa-solid fa-xmark"></i>
                              </Link>
                            </div>

                            <div className="ttMapWrapper">
                              <iframe
                                className="actAsDiv"
                                frameborder="0"
                                scrolling="no"
                                marginheight="0"
                                marginwidth="0"
                                src="https://maps.google.com/maps?f=q&amp;source=s_q&amp;q=Adobe%20Systems%20Inc%2C%20Park%20Avenue%2C%20San%20Jose%2C%20CA&amp;aq=0&amp;ie=UTF8&amp;t=m&amp;z=12&amp;iwloc=A&amp;output=embed"
                              ></iframe>
                            </div>

                            <div className="ttAddressWrapper">
                              <h1>Address</h1>
                              <p> {HouseingListMange.address}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-5  col-12  order-1 order-xl-2 d-flex justify-content-end align-items-start mb-3">
                      <div className="globalPages_tabWrapper">
                        <div className="gb_tabs">
                          <div className="create-location">
                            <div>
                              <Link
                                className="create-event-button"
                                to="/create_property"
                              >
                                Create Property
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}

            
          </section>
        )}
      </main>
    </div>
  )
}

export default HousingDetails

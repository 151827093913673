import React, { useContext, useEffect } from "react";
import location from "../assets/images/location.svg";
import GoogleMapReact from "google-map-react";
import { AppContext } from "../context/AppContext";

export default function GoogleMap() {
  let {
    customerData,
    setCustomerData,
    locationData,
    setLocationData,
    place,
    setPlace,
    sidebar,
    setSidebar,
    marker,
    setMarker,
    circle,
    setCircle,
  } = useContext(AppContext);


  useEffect(() => {
    console.log("location", locationData);
    if (locationData.maps && locationData.map) {
      const { map, maps } = locationData;
      // or else call that isApiLoaded function and pass-on these arguments
      renderMarkers(map, maps);
    }
  }, [locationData, place]);
  const getLocation = (lat, lng) => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBxqcBX2r8cybljkDigXBgyOByjeQOmqr0`,
      {
        method: "GET", // or 'PUT'
        headers: {
          // "Content-Type": "application/json",
          // 'Access-Control-Allow-Credentials': "*"
        },
        //body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data.results[1].formatted_address);
        setPlace({
          ...place,
          center: { lat, lng },
          address: data.results[1].formatted_address,
        });
        setCustomerData({
          ...customerData,
          location: data.results[1].formatted_address,
          center: {
            lat,
            lng,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const renderMarkers = (map, maps) => {

    marker && marker.setMap(null);
    circle && circle.setMap(null);
    const cityCircle = new maps.Circle({
      strokeColor: "#00b4bd",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#00b4bd",
      fillOpacity: 0.35,
      map,
      center: { ...place.center },
      // radius: { ...place.center.radius },
      radius: place.radius ? Number(place.radius) : 1000,
    });
    marker = new maps.Marker({
      draggable: true,
      position: { ...place.center },
      map,
    });
    maps.event.addListener(marker, "dragend", async function (event) {
      getLocation(event.latLng.lat(), event.latLng.lng());
    });
    setMarker(marker);
    setCircle(cityCircle);

    return { marker, cityCircle };
  };
  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: "AIzaSyBxqcBX2r8cybljkDigXBgyOByjeQOmqr0",
      }}
      center={place.center}
      defaultZoom={11}
      onGoogleApiLoaded={({ map, maps }) => {
        // renderMarkers({ map, maps });
        setLocationData({ map, maps });
      }}
    />
  );
}

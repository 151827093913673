import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import ApiConfig from '../../api/ApiConfig'
import { RWebShare } from 'react-web-share'

import {
  deleteWithAuthCall,
  simpleGetCallWithErrorResponse,
  simplePostCall,
} from '../../api/ApiServices'
import eventthumbImg01 from '../../assets/images/saleThumb03.png'
import { AppContext } from '../../context/AppContext'
import LoaderCenter from '../../sharedComponent/LoaderCenter'
import userDemo from '../../assets/images/userDemo.png'
import swal from 'sweetalert'
import { ShareSocial } from 'react-share-social'
import { Modal, Button } from 'react-bootstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import chatusa from '../../assets/images/chat_icon_sidebar.svg'


const style = {
  background: '#F5F5F5',
  borderRadius: 3,
  border: 0,
  color: 'white',
  padding: '0 0px',
  // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  height: '200px',
  marginTop: '20px',
  // width: '20px',
}

function SellManage() {
  const { customerData,setActiveTab,setInterstedItem } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const [listTotal, setListTotal] = useState([])
  const [selectedID, setselectedID] = useState("") 

  const [sellIntersted, setsellIntersted] = useState([])
  const [sellList, setSellList] = useState([])
  const [showModal, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => {
    setShow(true)
    ShareHandal()
  }
  const [ShareData, setShare] = useState('')

  useEffect(() => {
    getSelllist()
  }, [])

  const getSelllist = () => {
    setLoading(true)

    simpleGetCallWithErrorResponse(
      ApiConfig.MANAGE_ITEM,
      JSON.stringify({ ...customerData }),
    ).then((res) => {
      console.log(res)
      setLoading(false)
      setSellList(res.json.list)
    })
  }

  const getSellintersted = (id) => {
    setLoading(true)

    simpleGetCallWithErrorResponse(
      ApiConfig.INTERESTED_USERS + id,
      JSON.stringify({ ...customerData }),
    ).then((res) => {
      setListTotal(res.json.list.length ? res.json.list.length : 0)
      console.log(res)
      setLoading(false)
      setsellIntersted(res.json.list)
    })
  }

  const DeleteItem = (id) => {
    deleteWithAuthCall(ApiConfig.DELETE_ITEM + id + '/')
      .then((res) => {
        console.log(res)
        swal(res.message)
        getSelllist()
      })
      .catch((err) => {
        setLoading(false)

        console.log(err)
      })
  }
  

  const HandalId = (id) => {
    setselectedID(id)
  }

  const ShareHandal = async () => {
    let requestBody = JSON.stringify({
      dynamicLinkInfo: {
        domainUriPrefix: 'https://myuniverse.page.link',

        link:
          'https://my-univrs.app.redbytes.in/Detail-sell/' +
          String(selectedID) +
          '/?user=' +
          String(customerData.id),

        androidInfo: {
          androidPackageName: 'com.my_universe',
        },

        iosInfo: {
          iosBundleId: 'org.myUniverse',
        },
      },
    })

    await fetch(
      'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBmt92Aby2xlZWOCIHeTutj8ywpF-4reyw',

      {
        method: 'POST',

        headers: {
          Accept: 'application/json',

          'Content-Type': 'application/json',
        },

        body: requestBody,
      },
    )
      .then((response) => response.json())

      .then((data) => {
        console.log('response', data)
        setShare(data.shortLink)
      })

      .catch((err) => {
        // this.setState({isVisible:false})

        console.log('error', err)
      })
  }

  return (
    <>
      <div>
        {loading ? (
          <LoaderCenter />
        ) : (
          <div className="row no-scroll row-gap-20">
            <Modal
              className="custom-dialog"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={showModal}
              onHide={handleClose}
            >
              <Modal.Header
                style={{ padding: '12px', width: '99%' }}
                closeButton
              >
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
              {/* Are you sure you want to cancel this Friend Request? */}
              <div class="modal-body" style={{ marginTop: '-37px' }}>
                <ShareSocial
                  style={style}
                  url={ShareData}
                  socialTypes={[
                    'facebook',
                    'twitter',
                    'reddit',
                    'linkedin',
                    'email',
                    'pinterest',
                  ]}
                />
                <CopyToClipboard
                    text={ShareData}
                    onCopy={() => swal('Copied to Clipboard')}
                  >
                    <Link to="#" className='copy-icon'>
                      <i class="fa-solid fa-copy"></i>
                    </Link>
                  </CopyToClipboard>
              </div>
            </Modal.Body>
            </Modal>
            {sellList && sellList.length > 0 ? (
              sellList.map((sellList, index) => {

                return (
                  <div className="col-12 col-lg-6  col-xl-4 col-xxl-3">
                    <div className="gbl_thumbWrapper">
                      <div className="img_thumbWrapper">
                        <div class="dropdown">
                          <button
                          onClick={() => {
                            HandalId(sellList.id)
                          }}
                            class="eventMenu"
                            type="button"
                            id="dropdownMenuButton2"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i class="fa-solid fa-ellipsis-vertical"></i>
                          </button>
                          <ul
                            class="dropdown-menu career-drop"
                            aria-labelledby="dropdownMenuButton2"
                            style={{
                              rigth: '10px',
                            }}
                          >
                            <li>
                              <Link
                                // to={`/update-sell/${sellList.id}`}
                                state={sellList}
                                to={'/update-sell/' + sellList.id}
                                // to={"/sellList"}
                                class="dropdown-item"
                              >
                                Edit
                              </Link>
                            </li>

                            <li>
                              {/* <RWebShare
                                // data={{
                                //   text: "Like humans, flamingos make friends for life",
                                //   url: "https://on.natgeo.com/2zHaNup",
                                //   title: "Share this article on Flamingos",
                                // }}
                                state={sellList}
                                data={{ sellList }}
                                onClick={() =>
                                  console.info("share successful!")
                                }
                              > */}
                              <a onClick={handleShow} class="dropdown-item">
                                share
                              </a>
                              {/* </RWebShare> */}
                            </li>
                            <li>
                              <a
                                onClick={() => {
                                  DeleteItem(sellList.id)
                                }}
                                class="dropdown-item"
                                href="#"
                              >
                                Delete
                              </a>
                            </li>
                          </ul>
                        </div>
                        <img
                          src={sellList.image}
                          alt=""
                          className="event-thumbnail"
                        />
                      </div>

                      <div className="event-details">
                        <h1 className="event-title">{sellList.title}</h1>
                        <h2
                          className="event-price"
                          style={{ marginTop: '16px' }}
                        >
                          £ {sellList.price}
                        </h2>

                        <div className="event-action-wrapper d-flex flex-column align-items-start align-items-sm-end flex-sm-row">
                          <label
                            style={{
                              color: '#707070',
                              fontSize: '14px',
                              fontWeight: 'normal',
                              marginBottom: '4px',
                            }}
                          >
                            {sellList.item_type}
                          </label>
                          <Link
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal-1"
                            to="#"
                            className="mt-2  mt-sm-0"
                            style={{ fontSize: '12px' }}
                            onClick={() => {
                              getSellintersted(sellList.id)
                            }}
                          >
                            Interested Users
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            ) : (
              <p
                style={{
                  fontSize: '20px',
                  marginTop: '200px',
                }}
                className=" text-center justify-content-center align-items-center"
              >
                Oops! It doesn't look like there are any Item in your area.
                <br /> Why don't you try expanding your search criteria?
              </p>
            )}
          </div>
        )}
      </div>
      <div
        class="modal fade"
        id="exampleModal-1"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog  modal-dialog-centered"
          style={{
            width: '80%',
            maxWidth: '338px',
            margin: '0 auto',
          }}
        >
          <div
            class="modal-content"
            style={{
              padding: '20px',
              backgroundColor: 'white',
              borderRadius: '20px',
              height: 'calc(100vh - 40px)',
            }}
          >
            <div className="of-head d-flex justify-content-between align-items-center mt-3 mb-2">
              <span
                style={{
                  font: 'normal normal bold 16px/22px Nunito',
                  fontSize: '16px',
                }}
              >
                Interested Users
              </span>
              <span
                style={{
                  font: 'normal normal bold 16px/22px Nunito',
                  fontSize: '16px',
                }}
              >
                {listTotal}
              </span>
            </div>
            <hr />
            <div
              class="offcanvas-body"
              style={{
                marginTop: '-10px',
                paddingTop: '0px',

                padding: '0',
              }}
            >
              {sellIntersted && sellIntersted.length > 0 ? (
                sellIntersted.map((sellIntersteditem, index) => {

                  return (
                    <div className="frnd-botm">
                      <div className="fir-frnd">
                        <div>
                          {/* <img
                            className="ofImage"
                            // src={EventIntersteditem.user.}
                            alt="img"
                          /> */}
                          {sellIntersteditem.user.profile_pic ? (
                            <img
                              src={sellIntersteditem.user.profile_pic}
                              style={{
                                borderRadius: '10px',
                                objectFit: 'cover',
                              }}
                            />
                          ) : (
                            <img
                              src={userDemo}
                              style={{
                                borderRadius: '10px',
                                objectFit: 'cover',
                              }}
                            />
                          )}
                        </div>
                        <div>
                          <p className="fName">
                            {sellIntersteditem.user.full_name}
                          </p>
                        </div>
                      </div>
                      <div className="chaticon">
                      <Link 
                               
                               onClick={() => { setActiveTab('Intersted');setInterstedItem(sellIntersteditem.user)  }}
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              // to="/groups">
                              to={'/groups/' + sellIntersteditem.user.id}>
                                <img src={chatusa} />
                                </Link>
                      </div>
                    </div>
                  )
                })
              ) : (
                <div
                  style={{
                    fontSize: '25px',
                    margin: 'auto',
                    height: 'calc(100vh - 300px)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  className=" text-center justify-content-center"
                >
                  No Interested users.
                </div>
              )}
            </div>
            <div
              style={{
                paddingTop: '20px',
                width: '100%',
                textAlign: 'center',
              }}
            >
              {/* <img src={down} style={{ color: "gray" }} /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SellManage

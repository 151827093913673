import React, { useContext, useState, useEffect, useRef } from 'react'
import { Link, useParams, useNavigate, NavLink } from 'react-router-dom'
import Sidebar from '../../components/Sidebar'
import rpt from '../../assets/images/report.svg'
import search_icon from '../../assets/images/search_icon.svg'
import { AppContext } from '../../context/AppContext'
import forumsListThumb01 from '../../assets/images/forumsListThumb01.jpg'
import forumsListSmallThumb01 from '../../assets/images/forumsListSmallThumb01.jpg'
import like from '../../assets/images/likeImage.png'
import unlike from '../../assets/images/not_like.svg'
import share from '../../assets/images/share.svg'
import Comments from '../../assets/images/comments.svg'
import ApiConfig from '../../api/ApiConfig'
import LoaderCenter from '../../sharedComponent/LoaderCenter'
import swal from 'sweetalert'
import fillter_icon from '../../assets/images/fillter_icon.svg'
import block from '../../assets/images/block.svg'
import ReactReadMoreReadLess from "react-read-more-read-less";
import Select from 'react-select'
import InfiniteScroll from "react-infinite-scroll-component";
import {
  simplePostCall,
  deleteWithAuthCall,
  postWithAuthCallWithErrorResponse,
  simpleGetCallWithErrorResponseNode,
  simpleGetCallWithErrorResponse,
  simpleGetCallWithErrorResponseNodeCreate,
  postMultipartWithAuthCallWithErrorResponseNodeCreate,
  simplePostCallNode,
} from '../../api/ApiServices'
import { ShareSocial } from 'react-share-social'
import { Modal, Button } from 'react-bootstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const style = {
  background: '#F5F5F5',
  borderRadius: 3,
  border: 0,
  color: 'white',
  padding: '0 0px',
  // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  height: '200px',
  marginTop: '20px',
  // width: '20px',
}

function ForumList() {
  const [page, setPage] = useState(1);
  const [showMore, setShowMore] = useState(false);
  const [manage, setManage] = useState(false);
  const [TotalCourses, setTotalCourses] = useState(0);
  const { sidebar, setSidebar, customerData, socket } = useContext(AppContext)
  const [forumList, setForumList] = useState([])
  const [FilterList, setFilterList] = useState([])
  const [FilterListSubtype, setFilterListSubtype] = useState([])
 const joindata = [...FilterList,...FilterListSubtype]
  const handleCloseDelete = () => setShowDelete(false)
  const [userreport, setUserReport] = useState({
    type: '',
    message: '',
  })
  const [State, setState] = useState({
    selectedOptionList: [],
    selectedOptionFollwer:[]

  })
 const [TypeList, setTypeList] = useState([])
 const [FolloweTypeList, setTypeFolloweList] = useState([])
  const [valueData, setValueData] = useState('')
  const [forumid, seForumId] = useState('')
  const [selectedID, setselectedID] = useState("")

  const handleShowDelete = () => setShowDelete(true)
  const [showModalDelete, setShowDelete] = useState(false)
  const [keyword, setKeyword] = useState({})
  const [loading, setLoading] = useState(true)
  const ipRef = useRef(null)
  const [showModal, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => {
    setShow(true)
    ShareHandal()
  }
  const navigate = useNavigate()
  // const page = 2
  const [ShareData, setShare] = useState('')
  console.log(ShareData)
  useEffect( () => {
     getForumList(page)
    getTypeList()
    getFolloweList()
  }, [])
  useEffect(() => {
    getForumList(page)
  }, [keyword])

  useEffect(() => {
    setKeyword('')
  }, [])
  useEffect(() => {
    console.log("forum liked")
    socket.on("forum liked",(value)=>{  
      let data=value[0]
      console.log(data);
      let newData=forumList.map(forun=>{
        return forun.id===data.forum_id ? {...forun,like_count:data.like_count,is_like:data.is_like,dislike_count:data.dislike_count,is_dislike:data.is_dislike}: forun
      })
      setForumList(newData)
    })
   
    socket.on("forum disliked",(value)=>{  
      let data=value[0]
      console.log(data);

      let newData=forumList.map(newmsg=>{
        return newmsg.id===data.forum_id  ? {...newmsg,like_count:data.like_count,is_like:data.is_like,dislike_count:data.dislike_count,is_dislike:data.is_dislike}: newmsg
      })
      setForumList(newData)
    })
    return () => {
      socket.off()
    }
  }, [forumList])

  const socketLikedata = (data) => {
    console.log(data)
    let Likelist = data.map((cartData) => {
      return cartData
    })
  
   console.log(Likelist)
  
  };

  const getSearchedProducts = (data) => {
    let matches = []
    matches = data.filter((product, index) => {
      if (
        (product &&
          product.forum_posted_by.full_name
            .toLowerCase()
            .includes(keyword.toLowerCase())) ||
        product.text.toLowerCase().includes(keyword.toLowerCase())
      )
        return product
    })
   
    setForumList(matches)
  }

  const getForumList = (currentPage) => {
  
    // setLoading(true);

    simpleGetCallWithErrorResponseNode(ApiConfig.UNIVERSR_ALL_FORUM_LIST + currentPage)
      .then((res) => {
       console.log(res)
        if (keyword.length > 0) {
          getSearchedProducts(res.json.forum_listing)
        } else {
          let total = res.json.total_count ? res.json.total_count : 0;
          setTotalCourses(total);
          let coursesData = res.json.forum_listing ? res.json.forum_listing : [];
          let ForumDataList = coursesData.map((eachteam, index) => {
            
            return {
              ...eachteam,
              forum_posted_by: eachteam.forum_posted_by.full_name || "",
           
            };
          });
       
          if (currentPage === 1) {
            if(res.json.result ){
              setForumList(res.json.forum_listing)
           

            }
            else {
              setForumList([])
            }
            
          
          } else {
            setForumList([...forumList, ...ForumDataList]);
           
          
          }
        }
        
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }

  const searchData = async (dataArray) => {
    if (dataArray == 'All') {
      getForumList()
    } else {
      setLoading(true)
      let requestBody = JSON.stringify({
        filter_choices: [dataArray],
      })
      postWithAuthCallWithErrorResponse(
        ApiConfig.UNIVERSR_FILTER_FORUM,
        requestBody,
      )
        .then((data) => {
          
    
          setForumList(data.json.list_forum)
          setLoading(false)
     
        })
        .catch((error) => {
          console.log('register error', error)
          setLoading(false)
        })
    }
  }
  const blockuser = (id) => {
    simplePostCall(
      ApiConfig.UNIVERSR_BLOCK_USER,
      JSON.stringify({
        blocked_to: id,
        blocked_status: 'block',
      }),
    )
      .then((res) => {
        swal('User Blocked Successfully.')

  
      })
      .catch((err) => {
     
        console.log(err)
      })
  }
  const handleSearch = () => {
    // setKeyword("");
    // setKeywordh(ipRef.current.value);
    setKeyword(ipRef.current.value)
  }


  const forumDelete = (id) => {
 
    simpleGetCallWithErrorResponseNodeCreate(
      ApiConfig.UNIVERSR_DELETE_FORUM_NEW + id,
    )
      .then((res) => {
        console.log(res)
        swal(res.json.detail)
        getForumList(page)
      })
      .catch((err) => {
        setLoading(false)

        console.log(err)
      })
  }
  // Like
  const likeDislikeForum = (id) => {
    socket &&
      socket.emit('forum like', {
        senderId: customerData.id,
        forum_id: id,
      })
    // getForumList(page)
  }
  const DislikeForum = (id) => {
    socket &&
      socket.emit('forum dislike', {
        senderId: customerData.id,
        forum_id: id,
      })
    // getForumList(page)
  }

  const ForumFallow = (id) => {
    socket &&
      socket.emit('forum follow', {
        senderId: customerData.id,
        type_id: id,
      })
    getForumList(page)
  }

  const HandalForumdata = (data, item) => {
    console.log(item);

    seForumId(item)
    setValueData(data)
  }

  const reportuser = (id) => {
    simplePostCall(
      ApiConfig.UNIVERSR_REPORT_USER,
      JSON.stringify({ ...userreport, reported_to: id }),
    )
      .then((res) => {
        swal(res.detail)
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }

//    Filter api ////
const getTypeList = () => {
  simpleGetCallWithErrorResponseNodeCreate(ApiConfig.UNIVERSR_ALL_FORUM_TYPE).then(
    (data) => {

      parseUserDataList(data.json.data);
    
    }
  );
};
const getFolloweList = () => {
  simpleGetCallWithErrorResponseNodeCreate(ApiConfig.UNIVERSR_ALL_Following_TYPE).then(
    (data) => {
   
      parseUserDataFFollowe(data.json);
    
    }
  );
};
const parseUserDataFFollowe = (data) => {
    
    let FollwerData = []
    data.list.map((item, index) => {
      FollwerData.push({
        value: item.type_id,
        label: item.type_name,
  
      });
    });
  
    setTypeFolloweList(FollwerData)
  
  };
const parseUserDataList = (data) => {

  let category = []
  data.map((item, index) => {
    category.push({
      value: item.type_id,
      label: item.name,

    });
  });

  setTypeList(category)

};

function ontypeChangeUniversity(selectedOption) {
  
  setState({
    ...State,
    selectedOptionList: selectedOption,
 
  })

  onFilterAdd(selectedOption)


}
function ontypeFollwer(selectedOption) {

  setState({
    ...State,
    selectedOptionFollwer: selectedOption,
  
  })
  onFilterSubtype(selectedOption)


}



const onFilterAdd = (selectedOption) => {
  let choiceArry = []

  selectedOption.map((value, index) => {

    choiceArry.push(
     value.value,
      
    )
  })
  setFilterList(choiceArry)
}
const onFilterSubtype = (selectedOption) => {
  let choiceArry = []

  selectedOption.map((value, index) => {
   
    choiceArry.push(
     value.value,
      
    )
  })
  setFilterListSubtype(choiceArry)
}


// Filter Api data  //
const handleFilter = async (e) => {

  let newRequestBody = JSON.stringify({
    type_id:joindata,
    page: 1,
 
   
  })
  simplePostCallNode(ApiConfig.UNIVERSR_ALL_FORUM_FILTER, newRequestBody)
    .then((data) => {
    console.log(data);
   
    if (data.result === true) {
      setForumList(data.forum_listing)
    } else {
      getForumList(page)
    }
       
       
      
    })
    .catch((error) => {
    
      console.log('api response', error)
    })
  
}

  // ======================================= pagination starts ======================
  //  const [pageCount, setPageCount] = useState(0);
  const onPageLoad = () => {
    setPage(page + 1);
    getForumList(page + 1);
  };

  // ======================================= PAGINATION ENDS ========================
  const handalId = (id) => {
    setselectedID(id)
  }


  const ShareHandal = async () => {
    let requestBody = JSON.stringify({
      dynamicLinkInfo: {
        domainUriPrefix: 'https://myuniverse.page.link',

        link:
          'https://my-univrs.app.redbytes.in/comment/' +
          String(selectedID) +
          '/?user=' +
          String(customerData.id),

        androidInfo: {
          androidPackageName: 'com.my_universe',
        },

        iosInfo: {
          iosBundleId: 'org.myUniverse',
        },
      },
    })

    await fetch(
      'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBmt92Aby2xlZWOCIHeTutj8ywpF-4reyw',

      {
        method: 'POST',

        headers: {
          Accept: 'application/json',

          'Content-Type': 'application/json',
        },

        body: requestBody,
      },
    )
      .then((response) => response.json())

      .then((data) => {
        console.log('response', data)
        setShare(data.shortLink)
      })

      .catch((err) => {
        // this.setState({isVisible:false})

        console.log('error', err)
      })
  }

  return (
    <>
      <Sidebar />

      {/* <Header /> */}
      <main id="cxMain" className={sidebar ? 'cx-main cx-active' : 'cx-main'}>
        <section className="content-main">
          <Modal
            className="custom-dialog"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showModal}
            onHide={handleClose}
          >
            <Modal.Header style={{ padding: '12px', width: '99%' }} closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* Are you sure you want to cancel this Friend Request? */}
              <div class="modal-body" style={{ marginTop: '-37px' }}>
                <ShareSocial
                  style={style}
                  url={ShareData}
                  socialTypes={[
                    'facebook',
                    'twitter',
                    'reddit',
                    'linkedin',
                    'email',
                    'pinterest',
                  ]}
                />
                <CopyToClipboard
                    text={ShareData}
                    onCopy={() => swal('Copied to Clipboard')}
                  >
                    <Link to="#" className='copy-icon'>
                      <i class="fa-solid fa-copy"></i>
                    </Link>
                  </CopyToClipboard>
              </div>
            </Modal.Body>
          </Modal>
          <div className="row" style={{ overflow: 'auto' }}>
            <div className="col-md-12">
              <div className="row" style={{ overflow: 'auto' }}>
                <div className="col-md-12">
                  <div className="globalPages_tabWrapper">
                    <div className="gb_tabs">
                      <div style={{ display: 'flex' }}>
                        {/* <div style={{ marginRight: '20px' }}>
                          <Link to="/forum_list" className="ct-all-forms">
                            All forums
                          </Link>{' '}
                        </div>
                        <div>
                          <li>
                            <Link className="inActiveButton" to="/manage_forum">
                              Manage
                            </Link>{' '}
                          </li>
                        </div> */}
                        <div>
                  <NavLink
                    onClick={() => setManage(false)}
                    to="/house_listing/all"
                    className={({ isActive }) =>
                      isActive ? "nav-active" : "nav-inactive"
                    }
                  >
                    All Properties
                  </NavLink>
                </div>
                <div>
                  <NavLink
                    onClick={() => setManage(true)}
                    to="/house_listing/manage"
                    className={({ isActive }) =>
                      isActive ? "nav-active" : "nav-inactive"
                    }
                  >
                    Manage
                  </NavLink>
                </div>






                      </div>
                      <div style={{ display: 'flex' }}>
                        <div className="search-input">
                          <input
                            onChange={(e) => setKeyword(e.target.value)}
                            ref={ipRef}
                            type="text"
                            name="text"
                            id="text"
                            placeholder="Search forum"
                            style={{
                              width: '100%',
                            }}
                          />

                          <img
                            onClick={() => handleSearch()}
                            src={search_icon}
                            alt="search"
                          />
                        </div>
                        <div>
                          <Link
                            className="ct_active-forum"
                            to="/create_forum"
                            style={{
                              height: '40px',
                              width: '180px',
                              marginLeft: '20px',
                              color: '#21212c',
                              fontSize: '14px',
                              fontWeight: '600',
                            }}
                          >
                            Create Forum
                          </Link>{' '}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="create_screenWrapper formsscreenAdj01">
                <div className="profile_modal_contentWrapper">
               
                  <button
                    className="filter-ic-btn border-0"
                    style={{
                      float: 'right',
                    }}
                  >
                    <Link
                      to="#"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal2"
                      style={{
                        border: 'none',
                        margin: '0',
                        padding: '0px 0px',

                        marginRight:
                          '17nm,mn,l                                                                                                                                                                                                                                                                 m,mm,mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,llllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllll,l,l,,,ll                             ,l  mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,l,llllllllllllllllllllllllllllllllllmmmmmmm,lllllllllllllllllllllllllllllll                                                                                                                                                                                                                                                                                                                                                                                                                                                  0px',
                      }}
                    >
                      <img src={fillter_icon} alt="" />
                    </Link>
                  </button>
                </div>
                {/* Modal Filter */}
                <div
                  class="modal fade "
                  id="exampleModal2"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div
                    class="modal-dialog modal-dialog-centered "
                    style={{ width: 'fit-content', margin: 'auto' }}
                  >
                    <div
                      class="modal-content cmo-content"
                      style={{
                        borderRadius: '20px',
                        padding: '20px',
                        width: '378px',
                        maxWidth: '378px',
                        position: 'relative',
                        top: '-92px',
                      }}
                    >
                      <p
                        style={{
                          color: '#1D1D25',
                          fontSize: '20px',
                          fontWeight: 'bold',
                        }}
                      >
                        Filter Forum
                      </p>
                      <p
                        className="job-type"
                        style={{
                          color: '#707070',
                          fontSize: '14px',
                          fontWeight: 'normal',
                          marginTop: '20px',
                          marginBottom: '10px',
                        }}
                      >
                        Forum SubType
                      </p>
                      <div className="cr-check-list">
                        <div className="ip-label">
                        <Select
                                placeholder="Forum SubType"
                                class="js-example-basic-single form-select"
                                // value={State.selectedOptionList}
                                onChange={ontypeChangeUniversity}
                                options={TypeList}
                                isMulti={true}


                              />
                        </div>
                      </div>
                      <p
                        className="job-type"
                        style={{
                          color: '#707070',
                          fontSize: '14px',
                          fontWeight: 'normal',
                          marginTop: '20px',
                          marginBottom: '10px',
                        }}
                      >
                        Followed SubType
                      </p>
                      <div className="cr-check-list">
                        <div className="ip-label">
                        <Select
                                placeholder="Followed SubType"
                                class="js-example-basic-single form-select"
                                // value={State.selectedOptionList}
                                onChange={ontypeFollwer}

                                options={FolloweTypeList}
                                isMulti={true}


                              />
                        </div>
                      </div>
                      <div
                        className="text-center"
                        style={{
                          marginTop: '30px',
                        }}
                      >
                        <button
                          className="ep-action"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          style={{
                            margin: '0px',
                            color: '#FFFFFF',
                            fontSize: '14px',
                            fontWeight: '600',
                            padding: '16px 40px',
                          }}
                          onClick={handleFilter}
                        >
                          Apply Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End */}
              </div>
              {loading ? (
                <LoaderCenter />
              ) : (
                <InfiniteScroll
                dataLength={forumList.length}
                next={onPageLoad}
                hasMore={forumList.length !== TotalCourses}
                style={{
                  overflow: "hidden",
                }}
                // loader={
                //   <h4 style={{ textAlign: "center", marginTop: "20px" }}>
                //     Loading....
                //   </h4>
                // }
                // endMessage={
                //   <p style={{ textAlign: "center", marginTop: "20px" }}>
                //     <b>No More data Found</b>
                //   </p>
                // }
              >
                <>
                  {forumList.length > 0 ? (
                    forumList.map((item, index) => {
                      return (
                        <div
                          className=" forumListWrapper forumListWrapperi"
                          style={{
                            marginTop: '20px',
                            height: 'auto',
                          }}
                        >
                          <div className="inner-wrapper">
                            <>
                              <div className="flInnerItemsWrapper m-0">
                                <div className="f-custom-wrapper">
                                  <div
                                    className="fl_section02"
                                    style={{
                                      width: '100%',
                                    }}
                                  >
                                    <div className="fl_userTitle">
                                      {item.forum_posted_by.profile_img ? (
                                        <img
                                          src={item.forum_posted_by.profile_img}
                                          alt=""
                                          style={{
                                            width: '50px',
                                            height: '50px',
                                            objectFit: 'cover',
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={forumsListSmallThumb01}
                                          alt=""
                                          style={{
                                            width: '50px',
                                            height: '50px',
                                            objectFit: 'cover',
                                          }}
                                        />
                                      )}
                                      <div className="d-flex flex-column">
                                        <h1>
                                          {item.forum_posted_by.full_name}
                                        </h1>
                                        <h1
                                          style={{
                                            color: '#00b4bd',
                                          }}
                                        >
                                          {item.type}
                                        </h1>
                                      </div>
                                    </div>
                                    <p
                                     
                                    >
                                      <ReactReadMoreReadLess
        charLimit={240}
        readMoreText={"Read more"}
        readLessText={"Read less"}
        readMoreClassName="read-more-less--more"
        readLessClassName="read-more-less--less"
      >
        {item.text}
      </ReactReadMoreReadLess>
                                    </p>
                                   
                                    <div className="fl_section01 ">
                                      {item.forum_image ? (
                                        <img
                                          src={item.forum_image[0]}
                                          alt=""
                                          style={{
                                            width: '110px',
                                            height: '110px',
                                            objectFit: 'cover',
                                          }}
                                        />
                                      ) : (
                                        <img src={forumsListThumb01} alt="" />
                                      )}
                                    </div>
                                    <div className="fl_controlsWrapper mt-3">
                                    {/* {socketLike &&
                                socketLike.forum_image.map((item, index) => { */}
                                  
                                  {/* return ( */}
                                      <ul>
                                        {item.is_like == true ? (
                                          <li className="active">
                                            <img
                                              style={{
                                                width: '18px',
                                                height: '18px',
                                              }}
                                              src={like}
                                              onClick={(e) => {
                                                e.preventDefault()
                                                likeDislikeForum(item.id)
                                              }}
                                            />
                                            {item.like_count !== 0 ? (
                                              <h4>{item.like_count}</h4>
                                            ) : null}
                                          </li>
                                        ) : (
                                          <li>
                                            <img
                                              src={unlike}
                                              onClick={(e) => {
                                                e.preventDefault()
                                                likeDislikeForum(item.id)
                                              }}
                                            />
                                            {item.like_count !== 0 ? (
                                              <h4>{item.like_count}</h4>
                                            ) : null}
                                          </li>
                                        )}
                                        {item.is_dislike == true ? (
                                          <li className="active">
                                            <img
                                              style={{
                                                width: '18px',
                                                height: '18px',
                                                transform: 'rotateX(180deg)',
                                              }}
                                              src={like}
                                              onClick={() => {
                                                DislikeForum(item.id)
                                              }}
                                            />
                                            {item.dislike_count !== 0 ? (
                                              <h4>{item.dislike_count}</h4>
                                            ) : null}
                                          </li>
                                        ) : (
                                          <li>
                                            <img
                                              src={unlike}
                                              style={{
                                                transform: 'rotateX(180deg)',
                                              }}
                                              onClick={() => {
                                                DislikeForum(item.id)
                                              }}
                                            />
                                            {item.dislike_count !== 0 ? (
                                              <h4>{item.dislike_count}</h4>
                                            ) : null}
                                          </li>
                                        )}
                                        <Link
                                          to={`/comment/${item.id}`}
                                          state={{ ...item }}
                                        >
                                          <li>
                                            <img src={Comments} />
                                            {item.comment_count !== 0 ? (
                                              <h4>{item.comment_count}</h4>
                                            ) : null}
                                          </li>
                                        </Link>

                                        <li>
                                     
                                        </li>
                                      </ul>
                                       {/* )
                                      })} */}
                                    </div>
                                  </div>
                                </div>
                                {item.forum_posted_by.user_id == customerData.id ? (
                                   <div className="fl_section03">
                                   <div
                                     class="dropdown"
                                     style={{
                                       borderRadius: '8px',
                                       height: '34px',
                                       width: '34px',
                                     }}
                                   >
                                     <button
                                       style={{
                                         color: 'black',
                                       }}
                                     
                                       class="btn"
                                       type="button"
                                       id="dropdownMenuButton1"
                                       data-bs-toggle="dropdown"
                                       aria-expanded="false"
                                       onClick={() => {
                                         HandalForumdata(
                                           item.forum_posted_by,
                                           item,
                                         )
                                         ;handalId(item.id)
                                       }}
                                     >
                                       <i class="fa-solid fa-ellipsis-vertical"></i>
                                     </button>
                                     <ul
                                       style={{
                                         borderRadius: '10px',
                                         border: '1px solid #ECECEC',
                                         backgroundColor: '#ECECEC',
                                         width: '50px !important',
                                       }}
                                       class="dropdown-menu house-manage-dropdown mmmmm"
                                       aria-labelledby="dropdownMenuButton1"
                                     >
                                      
                                       <li style={{ padding: '0 20px' }}>
                                         <Link
                                           to="#"
                                           class="dropdown-item"
                                           onClick={handleShow}
                                           style={{
                                             borderBottom: '1px solid #e1e1e1',
                                             fontSize: '14px',
                                             fontFamily: 'Nunito',
                                             fontStyle: 'normal',
                                           }}
                                         >
                                           Share
                                         </Link>
                                       </li>
                                       {/* <li style={{ padding: '0 20px' }}>
                                         <Link
                                           to="#"
                                           class="dropdown-item"
                                           onClick={() => {
                                             ForumFallow(item.type_id)
                                           }}
                                           style={{
                                             borderBottom: '1px solid #e1e1e1',
                                             fontSize: '14px',
                                             fontFamily: 'Nunito',
                                             fontStyle: 'normal',
                                           }}
                                         >
                                           {item.is_followed === true
                                             ? 'Following'
                                             : 'Follow'}
                                         </Link>
                                       </li>
                                       <li style={{ padding: '0 20px' }}>
                                         <Link
                                           to="#"
                                           class="dropdown-item"
                                           data-bs-toggle="modal"
                                           data-bs-target="#exampleModal3"
                                           style={{
                                             borderBottom: '1px solid #e1e1e1',
                                             fontSize: '14px',
                                             fontFamily: 'Nunito',
                                             fontStyle: 'normal',
                                           }}
                                         >
                                           Report
                                         </Link>
                                       </li>
                                       <li style={{ padding: '0 20px' }}>
                                     <Link
                                       to="#"
                                       class="dropdown-item"
                                       data-bs-toggle="modal"
                                       data-bs-target="#exampleModalBlock"
                                       style={{
                                         borderBottom: '1px solid #e1e1e1',
                                         fontSize: '14px',
                                         fontFamily: 'Nunito',
                                         fontStyle: 'normal',
                                       }}
                                     >
                                       Block
                                     </Link>
                                   </li> */}
                                   <li style={{ padding: '0 20px' }}>
                                      <Link
                                        to={`/edit_forum/${item.id}`}
                                        // state={{ from: item }}
                                        class="dropdown-item"
                                        href="#"
                                        style={{
                                          borderBottom: '1px solid #e1e1e1',
                                          fontSize: '14px',
                                          fontFamily: 'Nunito',
                                          fontStyle: 'normal',
                                        }}
                                      >
                                        Edit
                                      </Link>
                                    </li>
                                    <li
                                      style={{
                                        padding: '0 20px',
                                        fontSize: '14px',
                                        fontFamily: 'Nunito',
                                        fontStyle: 'normal',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <a
                                        onClick={() => {
                                          forumDelete(item.id)
                                        }}
                                        // data-bs-toggle="modal"
                                        // data-bs-target="#exampleModal"
                                        // class="dropdown-item"
                                        // href="#"
                                      >
                                        Delete
                                      </a>
                                    </li>
                                     </ul>
                                   </div>
                                 </div>
                                ) : (
                                  <div className="fl_section03">
                                    <div
                                      class="dropdown"
                                      style={{
                                        borderRadius: '8px',
                                        height: '34px',
                                        width: '34px',
                                      }}
                                    >
                                      <button
                                        style={{
                                          color: 'black',
                                        }}
                                        class="btn"
                                        type="button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        onClick={() => {
                                          HandalForumdata(
                                            item.forum_posted_by,
                                            item,
                                          )
                                        }}
                                      >
                                        <i class="fa-solid fa-ellipsis-vertical"></i>
                                      </button>
                                      <ul
                                        style={{
                                          borderRadius: '10px',
                                          border: '1px solid #ECECEC',
                                          backgroundColor: '#ECECEC',
                                          width: '50px !important',
                                        }}
                                        class="dropdown-menu house-manage-dropdown mmmmm"
                                        aria-labelledby="dropdownMenuButton1"
                                      >
                                        <li style={{ padding: '0 20px' }}>
                                          <Link
                                            to="#"
                                            class="dropdown-item"
                                            onClick={handleShow}
                                            style={{
                                              borderBottom: '1px solid #e1e1e1',
                                              fontSize: '14px',
                                              fontFamily: 'Nunito',
                                              fontStyle: 'normal',
                                            }}
                                          >
                                            Share
                                          </Link>
                                        </li>
                                        <li style={{ padding: '0 20px' }}>
                                          <Link
                                            to="#"
                                            class="dropdown-item"
                                            onClick={() => {
                                              ForumFallow(item.type_id)
                                            }}
                                            style={{
                                              borderBottom: '1px solid #e1e1e1',
                                              fontSize: '14px',
                                              fontFamily: 'Nunito',
                                              fontStyle: 'normal',
                                            }}
                                          >
                                            {item.is_followed === true
                                              ? 'Following'
                                              : 'Follow'}
                                          </Link>
                                        </li>
                                        <li style={{ padding: '0 20px' }}>
                                          <Link
                                            to="#"
                                            class="dropdown-item"
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal3"
                                            style={{
                                              borderBottom: '1px solid #e1e1e1',
                                              fontSize: '14px',
                                              fontFamily: 'Nunito',
                                              fontStyle: 'normal',
                                            }}
                                          >
                                            Report
                                          </Link>
                                        </li>
                                        <li style={{ padding: '0 20px' }}>
                                      <Link
                                        to="#"
                                        class="dropdown-item"
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModalBlock"
                                        style={{
                                          borderBottom: '1px solid #e1e1e1',
                                          fontSize: '14px',
                                          fontFamily: 'Nunito',
                                          fontStyle: 'normal',
                                        }}
                                      >
                                        Block
                                      </Link>
                                    </li>
                                    
                                      </ul>
                                    </div>
                                  </div>
                                )}
                              </div>
                              {/* <Modal
                              className="custom-dialog"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              show={showModalDelete}
                              onHide={handleCloseDelete}
                            >
                              <Modal.Header
                                style={{ padding: '12px', width: '99%' }}
                                closeButton
                              >
                                <Modal.Title></Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div
                                  class="modal-body"
                                  style={{
                                    marginTop: '-37px',
                                    padding: '20px',
                                  }}
                                >
                                  <p
                                    style={{
                                      margin: '20px 10px',
                                      fontWeight: '600',
                                      fontSize: '19px',
                                      fontFamily: 'Nunito',
                                      color: '#1D1D25',
                                    }}
                                  >
                                    Are you sure you want to delete your post?
                                  </p>
                                </div>
                              </Modal.Body>

                              <Modal.Footer>
                                <div
                                  className="usa-btn"
                                  style={{
                                    marginTop: '-30px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginRight: '83px',
                                  }}
                                >
                                  <Link
                                    onClick={handleCloseDelete}
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    to="/forum_list"
                                    className="fir"
                                    style={{ padding: '13px', width: '130px' }}
                                  >
                                    Cancel
                                  </Link>

                                  <Link
                                    onClick={() => {
                                      forumDelete(item.id)
                                    }}
                                    className="primary"
                                    to="#"
                                    style={{ padding: '13px', width: '130px' }}
                                  >
                                    Confirm
                                  </Link>

                  
                                </div>
                              </Modal.Footer>
                            </Modal> */}

                              <div
                                class="modal fade"
                                id="exampleModal"
                                tabindex="-1"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                              >
                                <div
                                  class="modal-dialog modal-dialog-centered"
                                  style={{
                                    margin: 'auto',
                                    width: '80%',
                                    maxWidth: '378px',
                                  }}
                                >
                                  <div
                                    class="modal-content"
                                    style={{
                                      padding: '20px',
                                      borderRadius: '20px',
                                    }}
                                  >
                                    <p
                                      style={{
                                        margin: '20px 10px',
                                        fontWeight: '600',
                                        fontSize: '16px',
                                        fontFamily: 'Nunito',
                                        color: '#1D1D25',
                                      }}
                                    >
                                      Are you sure you want to delete your post?
                                    </p>

                                    <div className="d-flex justify-content-around">
                                      {/* <Link
                                      onClick={handleCloseDelete}
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                      to="/forum_list"
                                      className="fir"
                                      style={{
                                        padding: '13px',
                                        width: '130px',
                                      }}
                                    >
                                      Cancel
                                    </Link>

                                    <Link
                                      onClick={() => {
                                        forumDelete(item.id)
                                      }}
                                      className="primary"
                                      to="#"
                                      style={{
                                        padding: '13px',
                                        width: '130px',
                                      }}
                                    >
                                      Confirm
                                    </Link> */}

                                      <div>
                                        <button
                                          onClick={handleCloseDelete}
                                          className="ep-action"
                                          data-bs-toggle="modal"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                          data-bs-target="#cm-modal"
                                          style={{
                                            color: '#FFFFFF',
                                            fontSize: '14px',
                                            fontWeight: '400',
                                          }}
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                      <div>
                                        <button
                                          onClick={() => {
                                            forumDelete(item.id)
                                          }}
                                          className="ep-action"
                                          data-bs-toggle="modal"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                          data-bs-target="#cm-modal"
                                          style={{
                                            color: '#FFFFFF',
                                            fontSize: '14px',
                                            fontWeight: '400',
                                          }}
                                        >
                                          Confirm
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <p
                      style={{
                        fontSize: '20px',
                        margin: 'auto',
                        marginTop: '40px',
                      }}
                      className=" text-center justify-content-center"
                    >
                      Oops! It doesn't look like there are any Friend in your
                      area.
                      <br /> Why don't you try expanding your search criteria?
                    </p>
                  )}
                </>
                </InfiniteScroll>
              )}
            </div>
          </div>
          <div
            class="modal fade modal-backdrp-2 "
            id="exampleModal3"
            tabindex="-1"
            aria-labelledby="exampleModalLabel3"
            aria-hidden="true"
            modal-backdrp="rgba(0, 0, 0, .5)"
          >
            <div class="modal-dialog modal-dialog-centered modal-dialog-centered-profile ">
              <div
                class="modal-content"
                style={{
                  borderRadius: '30px',
                  padding: '15px',
                  backgroundColor: '#F5F5F5',
                  width: '393px',
                }}
              >
                <div class="modal-header ">
                  <img src={rpt} />
                  <div className="modal-title-block">
                    {' '}
                    <h5 class="modal-title ms-3" id="exampleModalLabel3">
                      Report {valueData.full_name}
                    </h5>
                  </div>

                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    style={{
                      marginRight: '4px',
                      border: '1px solid #ECECEC',
                      backgroundColor: 'white',
                      borderRadius: '10px',
                    }}
                  ></button>
                </div>
                <div class="modal-body">
                  <p
                    className="modal-body-user-1"
                    style={{ marginBottom: '10px' }}
                  >
                    Why are you reporting this user ?
                  </p>
                  <div
                    className="upp_selectWrapper-block-1"
                    style={{ marginBottom: '40px' }}
                  >
                    <select
                      value={userreport.type}
                      onChange={(e) => {
                        setUserReport({
                          ...userreport,
                          type: e.target.value,
                        })
                      }}
                      class="form-select"
                      aria-label="Default select example"
                      style={{
                        height: '50px',
                        fontSize: '18px',
                        fontWeight: '600px',
                        color: '#1D1D25',
                      }}
                    >
                      <option value="1">Inappropriate Behaviour</option>
                      <option value="2">Abusive Behaviour</option>
                    </select>
                  </div>
                  <div className="connection-p-model">
                    <p style={{ marginBottom: '10px', marginTop: '-20px' }}>
                      Please describe your reason for reporting.
                    </p>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="uploadPP_inputTextRow"
                      style={{ height: '150px' }}
                    >
                      <textarea
                        value={userreport.message}
                        onChange={(e) => {
                          setUserReport({
                            ...userreport,
                            message: e.target.value,
                          })
                        }}
                        className="textareaAdj01"
                        cols="45"
                        style={{
                          height: '150px',
                          width: '198%',
                          borderRadius: '10px',
                          padding: '20px',
                          color: '#BBBBBB',
                          backgroundColor: '#FFFFFF',
                          border: '1px solid #E1E1E1',
                          fontSize: '14px',
                          fontStyle: 'normal',
                        }}
                        placeholder="Type Here..."
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="modal-footer ">
                  <div
                    className="usa-btn-model"
                    style={{ width: '100%', alignItems: 'baseline' }}
                  >
                    <Link
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      to="/ConnectionAbout"
                      className="fir"
                      style={{
                        width: '129px',
                        height: '50px',
                        color: '#707070',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        letterSpacing: '0px',
                        textAlign: 'center',
                      }}
                    >
                      Cancel
                    </Link>
                    <Link
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      className="primary"
                      to="#"
                      onClick={() => {
                        reportuser(forumid.forum_posted_by
                          .user_id)
                      }}
                      style={{
                        width: '129px',
                        height: '50px',
                        color: '#FFFFFF',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        letterSpacing: '0px',
                        textAlign: 'center',
                      }}
                    >
                      Confirm
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
              class="modal fade main-model-of-block"
              id="exampleModalBlock"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div
                class="modal-dialog modal-dialog-centered "
                style={{ width: '393px' }}
              >
                <div
                  class="modal-content"
                  style={{
                    borderRadius: '30px',
                    padding: '10px',
                    backgroundColor: '#F5F5F5',
                  }}
                >
                  <div
                    style={{
                      padding: '13px 14px 20px 1px',
                      fontSize: '20px',
                      color: '#21212C',
                      fontFamily: 'Nunito',
                      fontWeight: 'bold',
                      letterSpacing: '0px',
                      textAlign: 'center',
                    }}
                    class="modal-header"
                  >
                    <img src={block} style={{ marginLeft: '8px' }} />
                    <h5
                      class="modal-title"
                      id="exampleModalLabel"
                      style={{ marginLeft: '20px' }}
                    >
                      Block {forumid.forum_posted_by && forumid.forum_posted_by.
full_name}
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      style={{
                        backgroundColor: 'white',
                        border: '1px solid #ECECEC',
                        borderRadius: '5px',
                      }}
                    ></button>
                  </div>
                  <div class="modal-body" style={{ marginTop: '-20px' }}>
                    <p
                      style={{
                        margin: '20px 10px',
                        fontSize: '14px',
                        color: '#707070',
                        fontFamily: 'Nunito',
                      }}
                    >
                      {forumid.forum_posted_by && forumid.forum_posted_by.
full_name} will no longer be able to :
                    </p>
                    <p
                      style={{
                        // font: "normal normal 600 16px/ 22px Nunito",
                        fontWeight: '600',
                        fontSize: '16px',
                        color: '#1D1D25',
                        margin: '20px 10px 20px 10px ',
                        fontFamily: 'Nunito',
                      }}
                    >
                      See your posts Invite you to events or groups Message you
                      Add you as a friend
                    </p>
                    <p
                      style={{
                        margin: '20px 10px',
                        fontWeight: '600',
                        fontSize: '16px',
                        fontFamily: 'Nunito',
                        color: '#1D1D25',
                      }}
                    >
                      If you're friends, blocking  will also unfriend her.
                    </p>
                  </div>
                  <div class="modal-footer ">
                    <div
                      className="usa-btn"
                      style={{
                        marginTop: '-30px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: '25px',
                      }}
                    >
                      <Link
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        to="/ConnectionAbout"
                        className="fir"
                        style={{ padding: '13px', width: '130px' }}
                      >
                        Cancel
                      </Link>
                      <Link
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        className="primary"
                        to="#"
                        onClick={() => {
                          blockuser(forumid.forum_posted_by
                            .user_id)
                        }}
                        style={{ padding: '13px', width: '130px' }}
                      >
                        Confirm
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </section>
      </main>
    </>
  )
}

export default ForumList

import React from "react";
import { useState } from "react";
import login_logo from "../assets/images/login_logo.svg";
import { Link, useNavigate } from "react-router-dom";
import email_icon from "../assets/images/email_icon.svg";
import lock_icon from "../assets/images/lock_icon.svg";
import facebook_icon from "../assets/images/facebook_icon.svg";
import gmail_icon from "../assets/images/gmail_icon.svg";
import profile_inputIcon from "../assets/images/profile_inputIcon.svg";
import phone_icon from "../assets/images/phone_icon.svg";
import university_icon from "../assets/images/university_icon.svg";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { signInWithFaceBook, signInWithGoogle } from "./Firebase";
import swal from "sweetalert";
import ApiConfig from "../api/ApiConfig";
import { createUser, saveUserDetails } from "../api/ApiServices";

function SignUp() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [socialLogin, setSocialLogin] = useState(false);
  const [facebookLogin, setFacebookLogin] = useState(false);
  const [errMsg, setErrMsg] = useState({
    // fullName: "",
    email: "",
    // phoneNumber: "",
    // countryCode: "",
    password: "",
    // university: "",
  });
  const [userDetails, setUserDetails] = useState({
    // fullName: "",
    email: "",
    // phoneNumber: "",
    // countryCode: "",
    password: "",
    // university: "",
  });
  const navigate = useNavigate();

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const singUpUser = async (requestBody) => {
    // let url;
    let data;

    if (socialLogin || facebookLogin) {
      // url = ApiConfig.SOCIAL_LOGIN;
      data = requestBody;
    } else {
      // url = ApiConfig.UNIVERSR_LOGIN_API;
      data = userDetails;
      console.log("userDetails", userDetails);
    }

    try {
      const userCreationResult = await createUser(data);
      if (userCreationResult.error) {
        console.error("Failed to create user:", userCreationResult.message);
        return;
      }

      console.log("User created and details saved successfully");
    } catch (error) {
      console.error("Error during user sign up:", error);
    }
  };

  const UserSignUp = (e) => {
    e && e.preventDefault();
    const regex = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
    if (socialLogin || facebookLogin) {
      let requestBody = {
        social_id: userDetails.uid,
        login_type: facebookLogin ? "facebook" : "gmail",
        email: userDetails.email.toLocaleLowerCase(),
        full_name: userDetails.displayName,
        username: userDetails.uid,
        log_in_status: true,
      };
      singUpUser(requestBody);
    } else {
      if (userDetails.email === "" || userDetails.password === "") {
        if (userDetails.email === "") {
          // setErrMsg({ ...errMsg, email: 'Enter Email ID ' })
          swal("Please enter Email!");
          return;
        }
        if (!regex.test(userDetails.email)) {
          // setErrMsg({ ...errMsg, email: 'Please Enter Valid Email ID ' })
          swal("Please Enter Valid Email ID");
          return;
        }
        if (userDetails.password === "") {
          // setErrMsg({ ...errMsg, password: 'Please enter Password!' })
          swal("Please enter Password!");
          return;
        }
      } else {
        // setLoading(true);
        singUpUser();
      }
    }
    swal("Please verified your email");
    navigate("/");
  };

  const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      marginTop: "15px",
      marginBottom: "15px",
    },
    icon: {
      color: "teal",
      marginRight: "8px", // Adjust this value as needed
    },
  };
  return (
    <div className="auth_mainWrapper">
      <div className="auth_innerWrapper">
        <div className="auth_twoPane">
          <div className="auth_banner">
            <div className="auth_logoWrapper">
              <div className="auth_logoHolder">
                <img src={login_logo} alt="login logo" />
                <h1>
                  We use tech to create,
                  <br /> connect, and grow
                </h1>
              </div>
            </div>
          </div>
          <div>
            <div className="auth_formWrapper">
              <div className="auth_innerformWrapper">
                <div className="auth_header_control auth_formbg2">
                  <Link to="/">
                    <h2>Login</h2>
                  </Link>
                  <Link className="authLink" to="#">
                    <h1>Sign Up</h1>
                  </Link>
                </div>

                <div className="auth_formHolder auth_formHolderBG2">
                  <form>
                    <div
                      className="auth_inputWrapper auth_Gapper2"
                      style={{
                        height: "280px",
                      }}
                    >
                      {/* <div className="auth_iHolder">
                        <img src={profile_inputIcon} alt="user name" />
                        <input
                          autoComplete="off"
                          type="text"
                          placeholder="Full Name"
                          className="form-control"
                          id="inputFullName"
                          value={userDetails.fullName}
                          onChange={(e) => {
                            setErrMsg({ ...errMsg, fullName: "" });
                            setUserDetails({
                              ...userDetails,
                              fullName: e.target.value,
                            });
                          }}
                          style={{
                            backgroundColor: "transparent",
                            border: "0",
                            color: "#000",
                            outline: "none",
                          }}
                        />
                      </div> */}

                      <div className="auth_iHolder">
                        <img src={email_icon} alt="user name" />
                        <input
                          autoComplete="off"
                          type="email"
                          placeholder="Email Address"
                          class="form-control "
                          id="inputEmail"
                          // required
                          value={userDetails.email}
                          onChange={(e) => {
                            setErrMsg({ ...errMsg, email: "" });
                            setUserDetails({
                              ...userDetails,
                              email: e.target.value,
                            });
                          }}
                        />
                      </div>

                      {/* <div className="auth_iHolder auth_iHolder03">
                        <img src={phone_icon} alt="user name" />
                        <div className="auth_selectWrapper">
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            value={userDetails.countryCode}
                            onChange={(e) => {
                              setErrMsg({ ...errMsg, countryCode: "" });
                              setUserDetails({
                                ...userDetails,
                                countryCode: e.target.value,
                              });
                            }}
                          >
                            <option value="+44">+44</option>
                            <option value="+91">+91</option>
                            <option value="+01">+01</option>
                            <option value="+02">+02</option>
                          </select>
                        </div>
                        <input
                          type="text"
                          placeholder="Telephone"
                          className="form-control"
                          value={userDetails.phoneNumber}
                          onChange={(e) => {
                            setErrMsg({ ...errMsg, phoneNumber: "" });
                            setUserDetails({
                              ...userDetails,
                              phoneNumber: e.target.value,
                            });
                          }}
                          style={{
                            borderLeft: "0",
                            borderRight: "0",
                            borderTop: "0",
                            backgroundColor: "transparent",
                            borderBottom: "1px solid #707070",
                            color: "#000",
                            outline: "none",
                          }}
                        />
                      </div> */}
                      <div className="auth_iHolder">
                        <img src={lock_icon} alt="user name" />
                        <input
                          type="password"
                          placeholder="Password"
                          className="form-control"
                          id="inputPassword"
                          required
                          value={userDetails.password}
                          onChange={(e) => {
                            setErrMsg({ ...errMsg, password: "" });
                            setUserDetails({
                              ...userDetails,
                              password: e.target.value,
                            });
                          }}
                        />
                      </div>

                      {/* <div className="auth_iHolder">
                        <img src={university_icon} alt="user name" />
                        <div className="auth_selectWrapper">
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            value={userDetails.university}
                            onChange={(e) => {
                              setErrMsg({ ...errMsg, university: "" });
                              setUserDetails({
                                ...userDetails,
                                university: e.target.value,
                              });
                            }}
                          >
                            <option selected>Select your University</option>
                            <option value="University of Oxford">
                              University of Oxford
                            </option>
                            <option value="University of Cambridge">
                              University of Cambridge
                            </option>
                            <option value="Imperial College London">
                              Imperial College London
                            </option>
                          </select>
                        </div>
                      </div> */}

                      {/* <div className="checkboxWrapper">
                        <div
                          className="checkboxWrapper_holder"
                          style={{ display: "flex" }}
                        >
                          <label>
                            <input type="checkbox" className="default__check" />
                            <span className="custom__check"></span>
                          </label>
                          <span
                            onClick={openModal}
                            style={{
                              cursor: "pointer",
                              color: "blue",
                              fontSize: "14px",
                            }}
                          >
                            I accept all terms and conditions
                          </span>
                        </div>
                      </div> */}
                    </div>
                    <div className="auth_controlWrapper">
                      {/* <Link to="/interests">Signup</Link> */}

                      <button type="submit" onClick={UserSignUp}>
                        Signup
                      </button>
                    </div>
                  </form>
                </div>

                <div className="oAuthWrapper">
                  <h1>or signup with</h1>
                  <ul>
                    <li>
                      <Link to="#" className="google">
                        <img
                          src={gmail_icon}
                          alt=""
                          onClick={() => {
                            signInWithGoogle().then((res) => {
                              console.log(res);

                              if (res.user) {
                                setUserDetails({ ...res.user.providerData[0] });
                                setSocialLogin(true);
                              }
                            });
                          }}
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="google">
                        <img
                          src={facebook_icon}
                          alt=""
                          onClick={() => {
                            signInWithFaceBook().then((res) => {
                              console.log(res);
                              if (res.user) {
                                setUserDetails({ ...res.user.providerData[0] });
                                setFacebookLogin(true);
                              }
                            });
                          }}
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Terms & Conditions"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "35%",
            maxWidth: "400px",
            padding: "25px",
            borderRadius: "15px",
          },
        }}
      >
        <h3 style={{ marginTop: "15px" }}>Terms & Conditions</h3>
        <div>
          <p style={{ marginTop: "15px" }}>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.<br></br>
            <br></br> It was popularised in the 1960s with the release of
            Letraset sheets containing Lorem Ipsum passages
          </p>
          <div style={styles.container}>
            <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
            <span style={{ color: "teal" }}>I accept terms and conditions</span>
          </div>
        </div>
        <button
          onClick={closeModal}
          style={{
            position: "absolute",
            top: 8,
            right: 16,
            backgroundColor: "transparent",
            border: "0",
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </Modal>
    </div>
    //     <div className="auth_mainWrapper">
    //       <div className="auth_innerWrapper">
    //         <div className="auth_twoPane">
    //           <div className="auth_banner">
    //             <div className="auth_logoWrapper">
    //               <div className="auth_logoHolder">
    //                 <img src={login_logo} alt="login logo" />
    //                 <h1>
    //                   We use tech to create,
    //                   <br /> connect, and grow
    //                 </h1>
    //               </div>
    //             </div>
    //           </div>
    //           <div>
    //             <div className="auth_formWrapper">
    //               <div className="auth_innerformWrapper">

    //                 <div className="auth_header_control auth_formbg2">
    //                   <Link to="/">
    //                     <h2>Login</h2>
    //                   </Link>
    //                   <Link className="authLink" to="#">
    //                     <h1>Sign Up</h1>
    //                   </Link>
    //                 </div>

    //                 <div className="auth_formHolder auth_formHolderBG2">
    //                   <form>
    //                     <div className="auth_inputWrapper auth_Gapper2">
    //                       <div className="auth_iHolder">
    //                         <img src={profile_inputIcon} alt="user name" />
    //                         {/* <input
    //                           type="text"
    //                           placeholder="Full Name"
    //                           class="form-control"
    //                         /> */}
    //                         <input
    //   type="text"
    //   placeholder="Full Name"
    //   className="form-control"
    //   style={{
    //     backgroundColor: 'transparent',
    //     border: '0',
    //     color: '#000', // Ensuring the text color is visible
    //     outline: 'none' // Removing the default focus outline
    //   }}
    // />

    //                       </div>

    //                       <div className="auth_iHolder">
    //                         <img src={email_icon} alt="user name" />
    //                         {/* <input
    //                           type="text"
    //                           placeholder="Email Address"
    //                           class="form-control"
    //                         /> */}
    //                                              <input
    //   type="text"
    //   placeholder="Email Address"
    //   className="form-control"
    //   style={{
    //     backgroundColor: 'transparent',
    //     border: '0',
    //     color: '#000', // Ensuring the text color is visible
    //     outline: 'none' // Removing the default focus outline
    //   }}
    // />
    //                       </div>

    //                       <div className="auth_iHolder auth_iHolder03">
    //                         <img src={phone_icon} alt="user name" />
    //                         <div className="auth_selectWrapper">
    //                           <select
    //                             class="form-select"
    //                             aria-label="Default select example"
    //                           >
    //                             <option selected>+44</option>
    //                             <option value="1">+91</option>
    //                             <option value="2">+01</option>
    //                             <option value="3">+02</option>
    //                           </select>
    //                         </div>

    //                         {/* <input
    //                           type="text"
    //                           placeholder="Phone Number"
    //                           class="form-control"
    //                         /> */}
    //                                           <input
    //   type="text"
    //   placeholder="Telephone"
    //   className="form-control"
    //   style={{
    //     borderLeft: '0',
    //     borderRight: '0',
    //     borderTop: '0',
    //     backgroundColor: 'transparent',
    //     borderBottom: '1px solid #707070',
    //     color: '#000', // Ensuring the text color is visible
    //     outline: 'none' // Removing the default focus outline
    //   }}
    // />
    //                       </div>
    //                       <div className="auth_iHolder">
    //                         <img src={lock_icon} alt="user name" />
    //                         <input
    //                           type="password"
    //                           placeholder="Password"
    //                           class="form-control"
    //                         />
    //                       </div>

    //                       <div className="auth_iHolder">
    //                         <img src={university_icon} alt="user name" />
    //                         <div className="auth_selectWrapper">
    //                           <select
    //                             class="form-select"
    //                             aria-label="Default select example"
    //                           >
    //                             <option selected>Select your University</option>
    //                             <option value="1">University of Oxford</option>
    //                             <option value="2">University of Cambridge</option>
    //                             <option value="3">Imperial College London</option>
    //                           </select>
    //                         </div>
    //                       </div>

    //                       <div className="checkboxWrapper">
    //                         <div className="checkboxWrapper_holder">
    //                           <label>
    //                             <input type="checkbox" class="default__check" />
    //                             <span class="custom__check"></span>I accept all
    //                             terms and conditions
    //                           </label>
    //                         </div>
    //                       </div>
    //                     </div>
    //                     <div className="auth_controlWrapper">
    //                       {/* <button>Login</button> */}
    //                       {/* <Link
    //                       to="/interests">
    //                         Signup
    //                         </Link> */}
    //                         <Link
    //                       to="/interests">
    //                         Signup
    //                         </Link>
    //                     </div>
    //                   </form>
    //                 </div>

    //                 <div className="oAuthWrapper">
    //                   <h1>or signup with</h1>
    //                   <ul>
    //                     <li>
    //                       <Link to="#" className="google">
    //                         <img src={gmail_icon} alt="" />
    //                       </Link>
    //                     </li>

    //                     <li>
    //                       <Link to="#" className="google">
    //                         <img src={facebook_icon} alt="" />
    //                       </Link>
    //                     </li>
    //                   </ul>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
  );
}

export default SignUp;

import React, { useContext, useState, useEffect } from "react";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/AppContext";
import swal from "sweetalert";
import LoaderCenter from "../../sharedComponent/LoaderCenter";
import './AllPropertyEvent.css';
import { useParams } from "react-router-dom";

const ManageEvent = () => {
  const { customerData } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [eventDetails, setHouseDetails] = useState(null);

  useEffect(() => {
    getHouseDetails();
  }, []);

  const { id } = useParams();

  const getHouseDetails = async () => {
    setLoading(true);

    console.log("check id", id);

    await fetch(`http://localhost:3080/posts/view/${id}`)


      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setLoading(false);
        console.log("housing_properties", data);
        
        setHouseDetails(data);
        
      })
      .catch(error => {
        setLoading(false);
        console.error('There was a problem fetching the data:', error);
      });

  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    };
    return date.toLocaleString('en-US', options);
  };

  const handleDelete = () => {
    // Handle delete functionality here
    swal("Delete functionality is not implemented yet.");
  };

  const handleInterested = () => {
    // Handle interested functionality here
    swal("Interested functionality is not implemented yet.");
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ maxWidth: '1300px', display: 'flex', flexDirection: 'row', margin: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', borderRadius: '15px', overflow: 'hidden' }}>
        {loading ? (
          <LoaderCenter />
        ) : eventDetails ? (
          <>
            <div style={{ flex: 1, backgroundColor: '#fff', padding: '20px' }}>
              <h2 style={{ fontWeight: 'bold', color: '#333', marginBottom: '20px' }}>{eventDetails.title}</h2>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                <img src={eventDetails.images[0]} alt="Main" style={{ width: '70%', maxWidth: '400px', height: 'auto', borderRadius: '15px', padding: '4px' }} />
                <div style={{ width: '30%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                  {eventDetails.images.slice(1, 4).map((image, index) => (
                    <img key={index} src={image} alt={`Image ${index + 1}`} style={{ width: '100%', maxWidth: '200px', height: 'auto', borderRadius: '10px', marginBottom: '10px' }} />
                  ))}
                </div>
              </div>
              <section>
                <h5 style={{ fontWeight: 'bold', color: '#333', marginBottom: '10px' }}>Description</h5>
                <p>{eventDetails.description}</p>
              </section>
            </div>
            <div style={{ flex: 1, backgroundColor: '#fff', padding: '20px', marginTop: '20px', marginLeft: '20px', borderRadius: '15px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
              <section>
                <h5 style={{ fontWeight: 'bold', color: '#333', marginBottom: '10px' }}>Address Details</h5>
                {/* <p><strong>County:</strong> {houseDetails.housingDetails.county}</p>
                <p><strong>City:</strong> {houseDetails.housingDetails.city}</p>
                <p><strong>Address:</strong> {houseDetails.housingDetails.address1}</p>
                <p><strong>PostCode:</strong> {houseDetails.housingDetails.postCode}</p> */}
              </section>
              <img src={eventDetails.images[1]} alt="Address" style={{ width: '100%', maxWidth: '400px', height: 'auto', borderRadius: '15px', marginTop: '20px' }} />
            </div>
            <div style={{ flex: 1, backgroundColor: '#fff', padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '20px', marginLeft: '20px', borderRadius: '15px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
              <section style={{ padding: '20px'}}>
                <h5 style={{ fontWeight: 'bold', color: '#333', marginBottom: '10px' }}>Other Details</h5>
                {/* <p><strong>Availability:</strong> {houseDetails.housingDetails.availability}</p>
                <p><strong>Property Type:</strong> {houseDetails.housingDetails.propertyType}</p>
                <p><strong>Sharing Type:</strong> {houseDetails.housingDetails.sharingType}</p>
                <p><strong>Rooms:</strong> {houseDetails.housingDetails.rooms}</p>
                
                <p><strong>Bathrooms:</strong> {houseDetails.housingDetails.bathrooms}</p>
                <p><strong>Deposit:</strong> {houseDetails.housingDetails.deposit}</p>
                <p><strong>Rent:</strong> {houseDetails.housingDetails.monthlyRent.value} </p> */}
              </section>
            </div>
          </>
        ) : (
          <p className="error-message" style={{ textAlign: 'center', padding: '20px', backgroundColor: '#fff', borderRadius: '15px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
            Oops! No details found for this property.
          </p>
        )}
      </div>
    </div>
  );


};

export default ManageEvent;









import React from "react";
import { useState } from "react";
import login_logo from "../assets/images/login_logo.svg";
import { Link } from "react-router-dom";
import email_icon from "../assets/images/email_icon.svg";
import lock_icon from "../assets/images/lock_icon.svg";
import facebook_icon from "../assets/images/facebook_icon.svg";
import gmail_icon from "../assets/images/gmail_icon.svg";
import profile_inputIcon from "../assets/images/profile_inputIcon.svg";
import phone_icon from "../assets/images/phone_icon.svg";
import university_icon from "../assets/images/university_icon.svg";
import scan_icon from "../assets/images/scan_icon.svg"
import verification03_pp from "../assets/images/verification03_PP.png";
import profile_picture from "../assets/images/profile_picture.jpg";
import id_card from "../assets/images/id_card.jpg";
import close_btn_icon from "../assets/images/close_btn_icon.svg";


function Verification4() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewURL(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewURL(null);
    }
  };
  return (
    <div className="auth_mainWrapper">
      <div className="auth_innerWrapper">
        <div className="auth_twoPane">
          <div className="auth_banner">
            <div className="auth_logoWrapper">
              <div className="auth_logoHolder">
                <img src={login_logo} alt="login logo" />
                <h1>
                  We use tech to create,
                  <br /> connect, and grow
                </h1>
              </div>
            </div>
          </div>
          <div>
            <div className="auth_formWrapper verificationAdj01_height">
              <div className="auth_innerformWrapper verificationAdj01_height">
                {/* <div className="auth_wizardWrapper">
                  <ul>
                    <li>
                      <Link className="auth_completed" to="#">
                        01
                      </Link>
                    </li>

                    <li>
                      <Link className="auth_completed" to="#">
                        02
                      </Link>
                    </li>

                    <li>
                      <Link className="auth_completed" to="#">03</Link>
                    </li>

                    <li>
                      <Link className="auth_completed" to="#">04</Link>
                    </li>
                  </ul>
                </div> */}

                <div className="verification_wrapper">
                  <h1>
                  Verify your <br />University ID
                  </h1>
            
                  <p>We need picture of your student card to help verify your student status.</p>
                  <div className="uploadPPWrapper">
                      
                      <div className="uploadPP_input">
                        <div class="form-group">
                          <input
                            type="file"
                            name="file"
                            id="file"
                            class="input-file"
                            onChange={handleFileChange}
                          />
                          <label
                            for="file"
                            class="btn btn-tertiary js-labelFile"
                          >
                            <span class="js-fileName">Update a file</span>
                          </label>
                        </div>
                      </div>
      {/* <input
        type="file"
        name="file"
        id="file"
        className="input-file"
        onChange={handleFileChange}
      /> */}
      {previewURL && <img src={previewURL} alt="File preview" style={{ width:"300px",height:"160px", marginTop:"5px",borderRadius:"10px"}} />}
    </div>
                  {/* <div className="uploadPPWrapper">
                      
                        <div className="uploadPP_input">
                          <div class="form-group">
                            <input
                              type="file"
                              name="file"
                              id="file"
                              class="input-file"
                            />
                            <label
                              for="file"
                              class="btn btn-tertiary js-labelFile"
                            >
                             
                              <span class="js-fileName">Update a file</span>
                            </label>
                          </div>
                        </div>
                      </div>

<div className="scannerWrapper verification04_pp">
  <Link to="#">  
  <img src={close_btn_icon} alt="" />
  </Link>
  <img src={id_card} alt="" />
</div> */}

        
               <div className="vari_controlWrapper1 vari_controlWrapper3">
                    {/* <button>Continue</button> */}
                    <Link className="cancelbtn" to="/signup">Cancel</Link>
                    <Link to="/dashboard">Save</Link>
               </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Login
<Link to="/dashboard" >dashboard</Link> */}
    </div>
  );
}

export default Verification4;

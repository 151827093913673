// import React, { useContext, useState, useEffect } from 'react'
// import { Link, useNavigate } from 'react-router-dom'
// import Sidebar from '../../components/Sidebar'
// import { AppContext } from '../../context/AppContext'
// import forumsListThumb01 from '../../assets/images/forumsListThumb01.jpg'
// import forumsListSmallThumb01 from '../../assets/images/forumsListSmallThumb01.jpg'
// import like from '../../assets/images/likeImage.png'
// import share from '../../assets/images/share.svg'
// import Comments from '../../assets/images/comments.svg'
// import ApiConfig from '../../api/ApiConfig'
// import unlike from '../../assets/images/not_like.svg'
// import swal from 'sweetalert'
// import LoaderCenter from '../../sharedComponent/LoaderCenter'
// import fillter_icon from '../../assets/images/fillter_icon.svg'

// import {
//   postWithAuthCallWithErrorResponse,
//   simpleGetCallWithErrorResponseNode,
//   simpleGetCallWithErrorResponseNodeCreate,
// } from '../../api/ApiServices'
// function ManageForum() {
//   const { sidebar, setSidebar,socket,customerData } = useContext(AppContext)
//   const [forumList, setForumList] = useState([])
//   const [loading, setLoading] = useState(false)

//   const navigate = useNavigate()
//   useEffect(() => {
//     setLoading(true)
//     getForumList()
//   }, [])

//   const page = 1
//   const getForumList = () => {
    

//     simpleGetCallWithErrorResponseNode(
//       ApiConfig.UNIVERSR_ALL_FORUM_LIST_MANAGE + page,
//     )
//       .then((res) => {
//         if (res.json.forum_listing) {
//           setForumList(res.json.forum_listing)
//         }
//         setLoading(false)
//       })
//       .catch((err) => {
//         setLoading(false)
//         console.log(err)
//       })
//   }

//   const forumDelete = (id) => {
 
//     simpleGetCallWithErrorResponseNodeCreate(
//       ApiConfig.UNIVERSR_DELETE_FORUM_NEW + id,
//     )
//       .then((res) => {
//         console.log(res)
//         swal(res.json.detail)
//         getForumList()
//       })
//       .catch((err) => {
//         setLoading(false)

//         console.log(err)
//       })
//   }

//   const searchData = async (dataArray) => {
//     if (dataArray == 'General') {
//       getForumList()
//     } else {
//       setLoading(true)
//       let requestBody = JSON.stringify({
//         filter_choices: dataArray,
//       })
//       postWithAuthCallWithErrorResponse(
//         ApiConfig.UNIVERSR_FILTER_FORUM,
//         requestBody,
//       )
//         .then((data) => {
//           if (data.lenght > 0) {
//             console.log(data)
//             setForumList(data)
//             setLoading(false)
//           }
//           if (data.lenght == undefined) {
//             setForumList([])
//             setLoading(false)
//           }
//         })
//         .catch((error) => {
//           console.log('register error', error)
//           setLoading(false)
//         })
//     }
//   }

//   const editForum = (item) => {
//     navigate('/edit_forum', { state: { item } })
//     console.log('Selected Item', item)
//   }



//    // Like 
//    const likeDislikeForum = (id) => {
//     console.log(id)
//     socket && socket.emit("forum like", {
//       senderId: customerData.id,
//       forum_id: id,
   
    
//     }
    
//     );
//     getForumList()

//   };
//   const DislikeForum = (id) => {
//     console.log(id)

//     socket && socket.emit("forum dislike", {
//       senderId: customerData.id,
//       forum_id: id,
   
    
//     }
    
//     );
//     getForumList()

//   };
//   return (
//     <div>
//       <Sidebar />

//       {/* <Header /> */}
//       <main id="cxMain" className={sidebar ? 'cx-main cx-active' : 'cx-main'}>
//         <section className="content-main">
//           <div className="row" style={{ overflow: 'auto' }}>
//             <div className="col-md-12">
//               <div className="row" style={{ overflow: 'auto' }}>
//                 <div className="col-md-12">
//                   <div className="globalPages_tabWrapper">
//                     <div className="gb_tabs">
//                       <div style={{ display: 'flex' }}>
//                         <div style={{ marginRight: '20px' }}>
//                           <Link to="/forum_list" className="inActiveButton">
//                             All forums
//                           </Link>{' '}
//                         </div>
//                         <div>
//                           <li>
//                             <Link className="ct-all-forms" to="#">
//                               Manage
//                             </Link>{' '}
//                           </li>
//                         </div>
//                       </div>
//                       <div style={{ display: 'flex' }}>
//                         <div>
//                           <Link
//                             className="ct_active-forum"
//                             to="/create_forum"
//                             style={{
//                               height: '40px',
//                               width: '180px',
//                               marginLeft: '20px',
//                               color: '#21212c',
//                               fontSize: '14px',
//                               fontWeight: '600',
//                             }}
//                           >
//                             Create Forum
//                           </Link>{' '}
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="create_screenWrapper formsscreenAdj01">
//                 {/* <div className="profile_modal_contentWrapper">
//                   <button
//                     className="filter-ic-btn border-0"
//                     style={{
//                       float: 'right',
//                     }}
//                   >
//                     <Link
//                       to="#"
//                       data-bs-toggle="modal"
//                       data-bs-target="#exampleModal2"
//                       style={{
//                         border: 'none',
//                         margin: '0',
//                         padding: '0px 0px',

//                         marginRight:
//                           '17nm,mn,l                                                                                                                                                                                                                                                                 m,mm,mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,llllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllll,l,l,,,ll                             ,l  mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,l,llllllllllllllllllllllllllllllllllmmmmmmm,lllllllllllllllllllllllllllllll                                                                                                                                                                                                                                                                                                                                                                                                                                                  0px',
//                       }}
//                     >
//                       <img src={fillter_icon} alt="" />
//                     </Link>
//                   </button>
//                 </div> */}
//                 {/* Modal Filter */}
//                 <div
//                   class="modal fade "
//                   id="exampleModal2"
//                   tabindex="-1"
//                   aria-labelledby="exampleModalLabel"
//                   aria-hidden="true"
//                 >
//                   <div
//                     class="modal-dialog modal-dialog-centered "
//                     style={{ width: 'fit-content', margin: 'auto' }}
//                   >
//                     <div
//                       class="modal-content cmo-content"
//                       style={{
//                         borderRadius: '20px',
//                         padding: '20px',
//                         width: '378px',
//                         maxWidth: '378px',
//                         position: 'relative',
//                         top: '-92px',
//                       }}
//                     >
//                       <p
//                         style={{
//                           color: '#1D1D25',
//                           fontSize: '20px',
//                           fontWeight: 'bold',
//                         }}
//                       >
//                         Filter Forum
//                       </p>
//                       <p
//                         className="job-type"
//                         style={{
//                           color: '#707070',
//                           fontSize: '14px',
//                           fontWeight: 'normal',
//                           marginTop: '20px',
//                           marginBottom: '10px',
//                         }}
//                       >
//                         Choose Job Type
//                       </p>
//                       <div className="cr-check-list">
//                         <div className="ip-label">
//                           <input
//                             type="checkbox"
//                             id="check1"
//                             // checked={filterStatus['Full Time']}
//                             // onChange={(e) =>
//                             //   setFilterStatus({
//                             //     ...filterStatus,
//                             //     'Full Time': e.target.checked,
//                             //   })
//                             // }
//                           />
//                           <label htmlFor="check1">Full Time</label>
//                         </div>
//                       </div>
//                       <div
//                         className="text-center"
//                         style={{
//                           marginTop: '30px',
//                         }}
//                       >
//                         <button
//                           className="ep-action"
//                           data-bs-dismiss="modal"
//                           aria-label="Close"
//                           style={{
//                             margin: '0px',
//                             color: '#FFFFFF',
//                             fontSize: '14px',
//                             fontWeight: '600',
//                             padding: '16px 40px',
//                           }}
//                           // onClick={(e) => handleFilter(e)}
//                         >
//                           Apply Filter
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 {/* End */}
//               </div>
//               {loading ? (
//                 <LoaderCenter />
//               ) : (
//                 <>
//                   {forumList.length > 0 ? (
//                     forumList.map((item, index) => {
//                       return (
//                         <div
//                           className=" forumListWrapper forumListWrapperi"
//                           style={{
//                             marginTop: '20px',
//                             height: 'auto',
//                           }}
//                         >
//                           <div className="inner-wrapper">
//                             <div className="flInnerItemsWrapper">
//                               <div className="f-custom-wrapper">
//                                 <div
//                                   className="fl_section02"
//                                   style={{
//                                     width: '100%',
//                                   }}
//                                 >
//                                   <div className="fl_userTitle">
//                                     {item.forum_posted_by.profile_img ? (
//                                       <img
//                                         src={item.forum_posted_by.profile_img}
//                                         alt=""
//                                         style={{
//                                           width: '35px',
//                                           height: '35px',
//                                           objectFit: 'cover',
//                                         }}
//                                       />
//                                     ) : (
//                                       <img
//                                         src={forumsListSmallThumb01}
//                                         alt=""
//                                         style={{
//                                           objectFit: 'cover',
//                                         }}
//                                       />
//                                     )}
//                                     <div className="d-flex flex-column">
//                                       <h1>{item.forum_posted_by.full_name}</h1>
//                                       <h1
//                                         style={{
//                                           color: '#00b4bd',
//                                         }}
//                                       >
//                                         {item.type}
//                                       </h1>
//                                     </div>
//                                   </div>

//                                   <div>
//                                     <p
//                                       style={{ width: '500px' }}
//                                       className="text-truncate"
//                                     >
//                                       {item.forum_text}
//                                     </p>
//                                   </div>

//                                   <div className="fl_section01 ">
//                                     {item.forum_image && item.forum_image.length !== 0 ? (
//                                       <img
//                                         src={item.forum_image[0]}
//                                         alt=""
//                                         style={{
//                                           width: '110px',
//                                           height: '110px',
//                                         }}
//                                       />
//                                     ) : (
//                                       <img src={forumsListThumb01} alt="" />
//                                     )}
//                                   </div>
//                                   <div className="fl_controlsWrapper mt-3">
//                                   <ul>
//                                     {item.is_like == true ? (
//                                       <li className="active">
//                                         <img
//                                           style={{
//                                             width: '18px',
//                                             height: '18px',
//                                           }}
//                                           src={like}
//                                           onClick={() => {
//                                             likeDislikeForum(item.id)
//                                           }}
//                                         />
//                                         {item.like_count !== 0 ? (
//                                           <h4>{item.like_count}</h4>
//                                         ) : null}
//                                       </li>
//                                     ) : (
//                                       <li>
//                                         <img
//                                           src={unlike}
//                                           onClick={() => {
//                                             likeDislikeForum(item.id)
//                                           }}
//                                         />
//                                         {item.like_count !== 0 ? (
//                                           <h4>{item.like_count}</h4>
//                                         ) : null}
//                                       </li>
//                                     )}
//                                     {item.is_dislike == true ? (
//                                       <li className="active">
//                                         <img
//                                           style={{
//                                             width: '18px',
//                                             height: '18px',
//                                             transform: 'rotateX(180deg)',
//                                           }}
//                                           src={like}
//                                           onClick={() => {
//                                             DislikeForum( item.id)
//                                           }}
//                                         />
//                                         {item.dislike_count !== 0 ? (
//                                           <h4>{item.dislike_count}</h4>
//                                         ) : null}
//                                       </li>
//                                     ) : (
//                                       <li>
//                                         <img
//                                           src={unlike}
//                                           style={{
//                                             transform: 'rotateX(180deg)',
//                                           }}
//                                           onClick={() => {
//                                             DislikeForum(item.id)
//                                           }}
//                                         />
//                                         {item.dislike_count !== 0 ? (
//                                           <h4>{item.dislike_count}</h4>
//                                         ) : null}
//                                       </li>
//                                     )}
//                                     <Link to="/comment" state={{ ...item }}>
//                                       <li>
//                                         <img src={Comments} />
//                                         {item.comment_count !== 0 ? (
//                                           <h4>{item.comment_count}</h4>
//                                         ) : null}
//                                       </li>
//                                     </Link>

//                                     <li>
//                                       {/* <img src={share} /> */}
//                                       {/* <h4>Share</h4> */}
//                                     </li>
//                                   </ul>
//                                   </div>
//                                 </div>
//                               </div>
//                               <div className="fl_section03">
//                                 <div
//                                   class="dropdown"
//                                   style={{
//                                     borderRadius: '8px',
//                                     height: '34px',
//                                     width: '34px',
//                                   }}
//                                 >
//                                   <button
//                                   style={{
//                                     color: 'black'
//                                   }}
//                                     class="btn"
//                                     type="button"
//                                     id="dropdownMenuButton1"
//                                     data-bs-toggle="dropdown"
//                                     aria-expanded="false"
//                                   >
//                                     <i class="fa-solid fa-ellipsis-vertical"></i>
//                                   </button>
//                                   <ul
//                                     style={{
//                                       borderRadius: '10px',
//                                       border: '1px solid #ECECEC',
//                                       backgroundColor: '#ECECEC',
//                                       width: '30px',
//                                     }}
//                                     class="dropdown-menu house-manage-dropdown mmmmm"
//                                     aria-labelledby="dropdownMenuButton1"
//                                   >
//                                     <li style={{ padding: '0 20px' }}>
//                                       <Link
//                                         to={`/edit_forum/${item.id}`}
//                                         // state={{ from: item }}
//                                         class="dropdown-item"
//                                         href="#"
//                                         style={{
//                                           borderBottom: '1px solid #e1e1e1',
//                                           fontSize: '14px',
//                                           fontFamily: 'Nunito',
//                                           fontStyle: 'normal',
//                                         }}
//                                       >
//                                         Edit
//                                       </Link>
//                                     </li>
//                                     <li
//                                       style={{
//                                         padding: '0 20px',
//                                         fontSize: '14px',
//                                         fontFamily: 'Nunito',
//                                         fontStyle: 'normal',
//                                         cursor: 'pointer',
//                                       }}
//                                     >
//                                       <a
//                                         onClick={() => {
//                                           forumDelete(item.id)
//                                         }}
//                                         // data-bs-toggle="modal"
//                                         // data-bs-target="#exampleModal"
//                                         // class="dropdown-item"
//                                         // href="#"
//                                       >
//                                         Delete
//                                       </a>
//                                     </li>
//                                   </ul>
//                                 </div>
//                               </div>
//                             </div>
//                             {/* <div
//                               class="modal fade"
//                               id="exampleModal"
//                               tabindex="-1"
//                               aria-labelledby="exampleModalLabel"
//                               aria-hidden="true"
//                             >
//                               <div
//                                 class="modal-dialog modal-dialog-centered"
//                                 style={{
//                                   margin: 'auto',
//                                   width: '80%',
//                                   maxWidth: '378px',
//                                 }}
//                               >
//                                 <div
//                                   class="modal-content"
//                                   style={{
//                                     padding: '20px',
//                                     borderRadius: '20px',
//                                   }}
//                                 >
//                                   <p
//                                     style={{
//                                       margin: '20px 10px',
//                                       fontWeight: '600',
//                                       fontSize: '16px',
//                                       fontFamily: 'Nunito',
//                                       color: '#1D1D25',
//                                     }}
//                                   >
//                                     Are you sure you want to delete your post?
//                                   </p>

//                                   <div className="d-flex justify-content-around">
//                                     <div>
//                                       <button
//                                         // onClick={handleCloseDelete}
//                                         className="ep-action"
//                                         data-bs-toggle="modal"
//                                         data-bs-dismiss="modal"
//                                         aria-label="Close"
//                                         data-bs-target="#cm-modal"
//                                         style={{
//                                           color: '#FFFFFF',
//                                           fontSize: '14px',
//                                           fontWeight: '400',
//                                         }}
//                                       >
//                                         Cancel
//                                       </button>
//                                     </div>
//                                     <div>
//                                       <button
//                                         onClick={() => {
//                                           forumDelete.bind(this, item.id)
//                                         }}
//                                         className="ep-action"
//                                         data-bs-toggle="modal"
//                                         data-bs-dismiss="modal"
//                                         aria-label="Close"
//                                         data-bs-target="#cm-modal"
//                                         style={{
//                                           color: '#FFFFFF',
//                                           fontSize: '14px',
//                                           fontWeight: '400',
//                                         }}
//                                       >
//                                         Confirm
//                                       </button>
//                                     </div>
//                                   </div>
//                                 </div>
//                               </div>
//                             </div> */}
//                           </div>
//                         </div>
//                       )
//                     })
//                   ) : (
//                     <p
//                       style={{
//                         fontSize: '25px',
//                         margin: 'auto',
//                       }}
//                       className=" text-center justify-content-center"
//                     >
//                       Data not found
//                     </p>
//                   )}
//                 </>
//               )}
//             </div>
//           </div>
//         </section>
//       </main>
//     </div>
//   )
// }

// export default ManageForum


import React, { useContext, useState, useEffect } from "react";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/AppContext";
import swal from "sweetalert";
import LoaderCenter from "../../sharedComponent/LoaderCenter";
import './AllProperty.css';
import { useParams } from "react-router-dom";

const ManageForum = () => {
  const { customerData } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [eventDetails, setHouseDetails] = useState(null);

  useEffect(() => {
    getHouseDetails();
  }, []);

  const { id } = useParams();

  const getHouseDetails = async () => {
    setLoading(true);

    console.log("check id", id);

    await fetch(`http://localhost:3080/posts/view/${id}`)


      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setLoading(false);
        console.log("housing_properties", data);
        
        setHouseDetails(data);
        
      })
      .catch(error => {
        setLoading(false);
        console.error('There was a problem fetching the data:', error);
      });

  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    };
    return date.toLocaleString('en-US', options);
  };

  const handleDelete = () => {
    // Handle delete functionality here
    swal("Delete functionality is not implemented yet.");
  };

  const handleInterested = () => {
    // Handle interested functionality here
    swal("Interested functionality is not implemented yet.");
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ maxWidth: '1300px', display: 'flex', flexDirection: 'row', margin: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', borderRadius: '15px', overflow: 'hidden' }}>
        {loading ? (
          <LoaderCenter />
        ) : eventDetails ? (
          <>
            <div style={{ flex: 1, backgroundColor: '#fff', padding: '20px' }}>
              <h2 style={{ fontWeight: 'bold', color: '#333', marginBottom: '20px' }}>{eventDetails.title}</h2>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                <img src={eventDetails.images[0]} alt="Main" style={{ width: '70%', maxWidth: '400px', height: 'auto', borderRadius: '15px', padding: '4px' }} />
                <div style={{ width: '30%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                  {eventDetails.images.slice(1, 4).map((image, index) => (
                    <img key={index} src={image} alt={`Image ${index + 1}`} style={{ width: '100%', maxWidth: '200px', height: 'auto', borderRadius: '10px', marginBottom: '10px' }} />
                  ))}
                </div>
              </div>
              <section>
                <h5 style={{ fontWeight: 'bold', color: '#333', marginBottom: '10px' }}>Description</h5>
                <p>{eventDetails.description}</p>
              </section>
            </div>
            <div style={{ flex: 1, backgroundColor: '#fff', padding: '20px', marginTop: '20px', marginLeft: '20px', borderRadius: '15px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
              <section>
                <h5 style={{ fontWeight: 'bold', color: '#333', marginBottom: '10px' }}>Address Details</h5>
                {/* <p><strong>County:</strong> {houseDetails.housingDetails.county}</p>
                <p><strong>City:</strong> {houseDetails.housingDetails.city}</p>
                <p><strong>Address:</strong> {houseDetails.housingDetails.address1}</p>
                <p><strong>PostCode:</strong> {houseDetails.housingDetails.postCode}</p> */}
              </section>
              <img src={eventDetails.images[1]} alt="Address" style={{ width: '100%', maxWidth: '400px', height: 'auto', borderRadius: '15px', marginTop: '20px' }} />
            </div>
            <div style={{ flex: 1, backgroundColor: '#fff', padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '20px', marginLeft: '20px', borderRadius: '15px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
              <section style={{ padding: '20px'}}>
                <h5 style={{ fontWeight: 'bold', color: '#333', marginBottom: '10px' }}>Other Details</h5>
                {/* <p><strong>Availability:</strong> {houseDetails.housingDetails.availability}</p>
                <p><strong>Property Type:</strong> {houseDetails.housingDetails.propertyType}</p>
                <p><strong>Sharing Type:</strong> {houseDetails.housingDetails.sharingType}</p>
                <p><strong>Rooms:</strong> {houseDetails.housingDetails.rooms}</p>
                
                <p><strong>Bathrooms:</strong> {houseDetails.housingDetails.bathrooms}</p>
                <p><strong>Deposit:</strong> {houseDetails.housingDetails.deposit}</p>
                <p><strong>Rent:</strong> {houseDetails.housingDetails.monthlyRent.value} </p> */}
              </section>
            </div>
          </>
        ) : (
          <p className="error-message" style={{ textAlign: 'center', padding: '20px', backgroundColor: '#fff', borderRadius: '15px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
            Oops! No details found for this property.
          </p>
        )}
      </div>
    </div>
  );


};

export default ManageForum;











import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, useLocation, NavLink, Outlet } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import thumbImg from "../../assets/images/watch.png";
import location_icon from "../../assets/images/location_icon.svg";
import back from "../../assets/images/back.svg";
import gray_location_icon from "../../assets/images/gray_location_icon.svg";
import chatListAvatar01 from "../../assets/images/chatListAvatar01.png";
import chat_icon_sidebar from "../../assets/images/chat_icon_sidebar.svg";
import search_icon from "../../assets/images/search_icon.svg";
import result_icon from "../../assets/images/saleThumb02.png";
import fillter_icon from "../../assets/images/fillter_icon.svg";
import DiningVoucher from "./DiningVoucher";
import DiningCard from "./DiningCard";
import MyWishlist from "./MyWishlist";
import DiningWishList from "./DiningWishList";
function Dining() {
  const [keyword, setKeyword] = useState("");
  const ipRef = useRef(0);
  const [filter, setFilter] = useState(
    useLocation().pathname === "/electronics/products"
  );
  const [showElement, setShowElement] = React.useState(true);

  function handleSearch() {
    setKeyword(ipRef.current.value);
  }
  const { sidebar, setSidebar } = useContext(AppContext);

  const Accordion = ({ title, children }) => {
    const [isOpen, setOpen] = React.useState(false);
    return (
      <div className="accordion-wrapper">
        <div
          className={`accordion-title ${isOpen ? "open" : ""}`}
          onClick={() => setOpen(!isOpen)}
        >
          {title}
        </div>
        <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
          <div className="accordion-content">{children}</div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div>
        <Sidebar />

        {/* <Header /> */}
        <main
          id="cxMain"
          className={sidebar ? "cx-main cx-active" : "cx-main"}
          style={{
            Height: "calc(100vh - 156px)",
          }}
        >
          <section
            className="content-main"
            style={{
              backgroundColor: "white",
              borderRadius: "20px",

              height: "calc(100vh - 156px)",
              padding: "20px",
            }}
          >
            <ul class="nav nav-pills mb-3 " id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link tab-btn active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Voucher
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link tab-btn"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Restaurent
                </button>
              </li>
              <li
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                style={{
                  cursor: "pointer",
                }}
              >
                <img src={fillter_icon} alt="" />
              </li>
              <li>
                <div
                  className="search-input"
                  style={{
                    width: "224px",
                  }}
                >
                  <input
                    ref={ipRef}
                    type="text"
                    name="text"
                    id="text"
                    placeholder="Search property"
                    style={{
                      width: "100%",
                    }}
                    onChange={() => handleSearch()}
                  />
                  <img
                    style={{
                      padding: "10px",
                    }}
                    src={search_icon}
                    alt="search"
                    // onClick={() => handleSearch()}
                  />
                </div>
              </li>
              <li
                class="nav-item"
                role="presentation"
                style={{
                  marginLeft: "auto",
                }}
              >
                <button
                  class="nav-link tab-btn"
                  id="pills-contact-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-contact"
                  type="button"
                  role="tab"
                  aria-controls="pills-contact"
                  aria-selected="false"
                >
                  My Wishlist
                </button>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
                tabindex="0"
              >
                <DiningVoucher />
              </div>
              <div
                class="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
                tabindex="0"
              >
                {/* ========> Dining card start <============= */}

                {/* =========> Dining card ends <============= */}
              </div>
              <div
                class="tab-pane fade"
                id="pills-contact"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
                tabindex="0"
              >
                <DiningWishList />
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
}

export default Dining;

import { useEffect, useState } from "react";
import "../assets/css/slider.css";
import ApiConfig from "../api/ApiConfig";
import eventthumbImg01 from "../assets/images/house01.png";
import { Link } from "react-router-dom";

export const Slider = ({ sliderImages: sliderItems }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const slider = document.getElementById("slider");

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) =>
                prevIndex === sliderItems.length - 1 ? 0 : prevIndex + 1
            );
        }, 3000); // Change image every 3 seconds

        return () => clearInterval(interval);
    }, [sliderItems]);

    // return (
    //     <div style={{ height: '50vh', display: 'flex', flexDirection: 'row', width: '100%', position: 'relative' , borderRadius: '20px' , overflow: 'clip' , marginBottom: '20px' }}>
    //         <img src={sliderImages[currentImageIndex]} alt="Slider Image" style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }} />
    //     </div>
    // )


    const scrollLeft = () => {
        slider.scrollLeft += 300;
    }

    const scrollRight = () => {
        slider.scrollLeft -= 300;
    }

    return (
        <div style={{ marginBottom: '20px' }}>
            <div className="slider">
                <div className="slider-body" id="slider">
                    {/* {
                        sliderItems.map((item) => <Link to={`/housing-details/${item.id}`}>
                            <div className="slider-content">
                                <img src={item.image ? `${ApiConfig.BASE_NEW_FOR_IMAGES + item.image}` : eventthumbImg01} className="slider-content-image" />
                            </div>
                        </Link>)
                    } */}

                    {sliderItems}
                    <div className="slider-action" id="left" >
                        <div className="slider-action-button" onClick={scrollRight}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="text-[gray] hover:text-black" viewBox="0 0 24 24"><path fill="currentColor" d="m7.825 13l4.9 4.9q.3.3.288.7t-.313.7q-.3.275-.7.288t-.7-.288l-6.6-6.6q-.15-.15-.213-.325T4.425 12q0-.2.063-.375T4.7 11.3l6.6-6.6q.275-.275.688-.275t.712.275q.3.3.3.713t-.3.712L7.825 11H19q.425 0 .713.288T20 12q0 .425-.288.713T19 13H7.825Z" /></svg>
                        </div>
                    </div>

                    <div className="slider-action" id="right" >
                        <div className="slider-action-button" onClick={scrollLeft}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-[gray] hover:text-black" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M16.175 13H5q-.425 0-.713-.288T4 12q0-.425.288-.713T5 11h11.175l-4.9-4.9q-.3-.3-.288-.7t.313-.7q.3-.275.7-.288t.7.288l6.6 6.6q.15.15.213.325t.062.375q0 .2-.063.375t-.212.325l-6.6 6.6q-.275.275-.687.275T11.3 19.3q-.3-.3-.3-.713t.3-.712L16.175 13Z" /></svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
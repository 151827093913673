import React from "react";
import { Link } from "react-router-dom";

const Button = () => {
  return (
    <div>
      <div className="chat_createGroupBTN-1">
        <Link
          style={{
            fontFamily: "Source Sans Pro, sans-serif",
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "19px",
            fontStyle: "Nunito",
            letterSpacing: "0px",
            color: "#21212C",
          }}
          to="/create_grpup"
        >
          Create Group
        </Link>
      </div>
    </div>
  );
};

export default Button;

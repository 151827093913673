import React, { useContext, useState, useEffect } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import Sidebar from '../../components/Sidebar'
import thumbImg from '../../assets/images/house01.png'
import userDemo from '../../assets/images/userDemo.png'
import search_icon from '../../assets/images/search_icon.svg'
import { AppContext } from '../../context/AppContext'
import like from '../../assets/images/likeImage.png'
import unlike from '../../assets/images/not_like.svg'
import share from '../../assets/images/share.svg'
import Comments from '../../assets/images/comments.svg'
import ApiConfig from '../../api/ApiConfig'
import LoaderCenter from '../../sharedComponent/LoaderCenter'
import dots from '../../assets/images/dropdown3d_icon.svg'
import down from '../../assets/images/drop_down_greenIcon.svg'
import send from '../../assets/images/send_icon.svg'
import arrow from '../../assets/images/arrow-left-short.svg'
import swal from 'sweetalert'
import Accordion from 'react-bootstrap/Accordion'
import Fade from 'react-bootstrap/Fade'
import Highlighter from 'react-highlight-words'
import { Mention, MentionsInput } from 'react-mentions'
import {
  deleteWithAuthCall,
  deleteWithAuthCallNode,
  simpleGetCallWithErrorResponse,
  simpleGetCallWithErrorResponseNodeCreate,
  simplePostCallNode,
} from '../../api/ApiServices'

//theme
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import { CopyToClipboard } from 'react-copy-to-clipboard'

//core
import 'primereact/resources/primereact.min.css'
import { ShareSocial } from 'react-share-social'
import { Modal, Button } from 'react-bootstrap'
//icons
import 'primeicons/primeicons.css'
import mentionsInputStyles from './mentionsInputStyles'
import mentionStyles from './mentionStyles'
const style = {
  background: '#F5F5F5',
  borderRadius: 3,
  border: 0,
  color: 'white',
  padding: '0 0px',
  // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  height: '200px',
  marginTop: '20px',
  // width: '20px',
}

function ForumComments(route) {
  // window.onload = () => {
  //   window.location = '/forum_list'
  // }
  const params = useParams()
  let Shareid = params.id
  const [showModal, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => {
    setShow(true)
    ShareHandal()
  }
  const location = useLocation()
  const from = location ? location.state : {}
  const { sidebar, setSidebar, customerData, socket } = useContext(AppContext)
  const [forumList, setForumList] = useState([])
  const [loading, setLoading] = useState(false)
  const [allCommentList, setAllCommentList] = useState([])
  const [recentCommentList, setRecentCommentList] = useState([])
  console.log(recentCommentList)
  const [commentTextData, setCommentText] = useState('')
  const [SubcommentTextData, setSubCommentText] = useState('')
  const [TextColor, setTextColor] = useState('')
  const [ShareData, setShare] = useState('')
  console.log(ShareData)
  const [open, setOpen] = useState(false)
  useEffect(() => {
    getForumCommentList()
    getprofilefriend()
    getForumList()
  }, [])
  const [text, setText] = useState('')
  const [textID, setTextID] = useState('')
  const [commenttext, commentsetText] = useState('')
  const [commenttextID, setcommentTextID] = useState('')
  const [Profilelistfriend, setProfileListFriend] = useState([])
  console.log(Profilelistfriend)

  const getprofilefriend = () => {
    setLoading(true)

    simpleGetCallWithErrorResponse(
      ApiConfig.UNIVERSR_FRIEND_LIST_ACCEPTED,
      JSON.stringify({ ...customerData }),
    ).then((res) => {
      setLoading(false)

      let coursesData = res.json.friends.map((subject, index) => {
        return {
          id: subject.id.toString(),
          display: subject.full_name,
        }
      })
      let choiceArry = []
      const data = res.json.friends.map((value, index) => {
        console.log('value', value)
        choiceArry.push(value.full_name)
      })

      setTextColor(choiceArry)

      setProfileListFriend(coursesData)
    })
  }

  useEffect(() => {
    if (commentTextData !== '') {
      let regex = /@\[.+?\]\(.+?\)/gm
      let displayRegex = /@\[.+?\]/g
      let idRegex = /\(.+?\)/g
      let matches = commentTextData.match(regex)
      let arr = []

      matches &&
        matches.forEach((m) => {
          let id = m.match(idRegex)[0].replace('(', '').replace(')', '')
          let display = m
            .match(displayRegex)[0]
            .replace('@[', '')
            .replace(']', '')

          arr.push({ id: id, display: display })
        })
      let newComment = commentTextData.split(regex)
      let newComment2 = commentTextData.split(regex)
      let output = ''
      let output2 = ''

      for (let i = 0; i < newComment.length; i++) {
        const c = newComment[i]

        if (i === newComment.length - 1) output += c
        else {
          output += c + `${arr[i].display}`
          output2 += c + `${arr[i].id}`
        }
      }

      commentsetText(output)
      setcommentTextID(output2)
    }
  }, [commentTextData])

  // Sub Comments ////
  useEffect(() => {
    if (SubcommentTextData !== '') {
      let regex = /@\[.+?\]\(.+?\)/gm
      let displayRegex = /@\[.+?\]/g
      let idRegex = /\(.+?\)/g
      let matches = SubcommentTextData.match(regex)
      let arr = []

      matches &&
        matches.forEach((m) => {
          let id = m.match(idRegex)[0].replace('(', '').replace(')', '')
          let display = m
            .match(displayRegex)[0]
            .replace('@[', '')
            .replace(']', '')

          arr.push({ id: id, display: display })
        })
      let newComment = SubcommentTextData.split(regex)
      let output = ''
      let output2 = ''

      for (let i = 0; i < newComment.length; i++) {
        const c = newComment[i]

        if (i === newComment.length - 1) output += c
        else {
          output += c + `${arr[i].display}`
          output2 += c + `${arr[i].id}`
        }
      }

      setText(output)
      setTextID(output2)
    }
  }, [SubcommentTextData])
  // ADD COMMENT API CALL
  const subCommenthandal = (id) => {
    if (commenttextID) {
      tagaddComment(id)
    } else {
      addComment(id)
    }
  }
  const addComment = (id) => {
    socket &&
      socket.emit('forum comment', {
        senderId: customerData.id,
        forum_id: id,
        comment: commenttext,
        tag: false,
        tagged_user_id: '',
      })

    getForumCommentList()
    getForumList()
    setCommentText('')
  }
  const tagaddComment = (id) => {
    socket &&
      socket.emit('forum comment', {
        senderId: customerData.id,
        forum_id: id,
        comment: commenttext,
        tag: true,
        tagged_user_id: commenttextID,
      })

    getForumCommentList()
    getForumList()
    setCommentText('')
  }

  const TagsubCommenthandal = (id) => {
    debugger
    if (textID) {
      TagsubComment(id)
    } else {
      subComment(id)
    }
  }

  const subComment = (id) => {
    socket &&
      socket.emit('forum sub comment', {
        senderId: customerData.id,
        forum_id: Shareid,
        sub_comment: text,
        tag: false,
        comment_id: id,
      })

    getForumCommentList()
    setSubCommentText('')
  }
  const TagsubComment = (id) => {
    socket &&
      socket.emit('forum sub comment', {
        senderId: customerData.id,
        tagged_user_id: textID,
        forum_id: Shareid,
        sub_comment: text,
        tag: true,
        comment_id: id,
      })

    getForumCommentList()
    setSubCommentText('')
  }
  const getForumList = async () => {
    // setisVisible(true)
    fetch(ApiConfig.UNIVERSR_MAIN_COMMENT_LIST_FORUM + Shareid, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('auth_token'),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setForumList(data.data)
        }
        // setisVisible(false)
      })
      .catch((error) => {
        console.log('error while fetching list', error)
      })
  }
  const getForumCommentList = async () => {
    // setisVisible(true)
    fetch(ApiConfig.UNIVERSR_GET_COMMENT_LIST_FORUM + Shareid, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('auth_token'),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          // recentComment(data.list_forum)

          setRecentCommentList(data.list_forum)
        }
        // setisVisible(false)
      })
      .catch((error) => {
        console.log('error while fetching list', error)
      })
  }

  const handleClickColap = (id) => {
    setOpen((prevState) => ({ ...prevState, [id]: !prevState[id] }))
  }
  // Like recentCommentList

  //   useEffect(() => {
  //     console.log('socket Calling forum')
  //     socket.once('comment liked', (value) => {
  //       let data = value[0]
  // console.log("comment liked",data)
  //       let newData =
  //         recentCommentList &&
  //         recentCommentList.map((forun) => {
  //           return forun.id === data.forum_id
  //             ? { ...forun, like_count: data.like_count, is_like: data.is_like }
  //             : { ...forun, like_count: data.like_count, is_like: data.is_like }
  //         })
  //       setRecentCommentList(newData)
  //     })
  //     // socket.on("forum disliked",(value)=>console.log("value",value))
  //     socket.once('comment disliked', (value) => {
  //       let data = value[0]
  //       console.log('comment disliked',data)

  //       let newData =
  //         recentCommentList &&
  //         recentCommentList.map((forun) => {
  //           console.log(forun)
  //           return forun.id === data.forum_id
  //             ? {
  //                 ...forun,
  //                 dislike_count: data.dislike_count,
  //                 is_dislike: data.is_dislike,
  //               }
  //             : {
  //                 ...forun,
  //                 dislike_count: data.dislike_count,
  //                 is_dislike: data.is_dislike,
  //               }
  //         })

  //       setRecentCommentList(newData)
  //     })
  //   }, [recentCommentList])

  // main Like

  useEffect(() => {
    socket.on('forum liked', (value) => {
      let data = value[0]

      // debugger
      let newData = forumList.map((forun) => {
        console.log(forun)
        return forun.id === data.forum_id
          ? { ...forun, like_count: data.like_count, is_like: data.is_like }
          : { ...forun, like_count: data.like_count, is_like: data.is_like }
      })
      setForumList(newData)
      getForumList()
    })

    socket.on('forum disliked', (value) => {
      let data = value[0]
      let newData = forumList.map((forun) => {
        return forun.id === data.forum_id
          ? {
              ...forun,
              dislike_count: data.dislike_count,
              is_dislike: data.is_dislike,
            }
          : {
              ...forun,
              dislike_count: data.dislike_count,
              is_dislike: data.is_dislike,
            }
      })
      setForumList(newData)
      getForumList()
    })

    return () => {
      socket.off()
    }
  }, [forumList])

  const likeDislikeForum = (id) => {
    socket &&
      socket.emit('forum like', {
        senderId: customerData.id,
        forum_id: id,
      })
  }
  const DislikeForum = (id) => {
    socket &&
      socket.emit('forum dislike', {
        senderId: customerData.id,
        forum_id: id,
      })
    // getForumList()
  }

  const SublikeDislikeForum = (id) => {
    socket &&
      socket.emit('comment like', {
        senderId: customerData.id,
        forum_id: Shareid,
        comment_id: id,
      })
    getForumCommentList()
  }
  const SubDislikeForum = (id) => {
    socket &&
      socket.emit('comment dislike', {
        senderId: customerData.id,
        forum_id: Shareid,
        comment_id: id,
      })
    getForumCommentList()
  }
  const ForumSubCommentLike = (subforumid, forumid) => {
    console.log('subforumid', subforumid)
    console.log('forumid', forumid)

    socket &&
      socket.emit('sub comment like', {
        senderId: customerData.id,
        forum_id: Shareid,
        comment_id: forumid,
        sub_comment_id: subforumid,
      })
    getForumCommentList()
  }
  const ForumSubCommentDisLike = (subforumid, forumid) => {
    socket &&
      socket.emit('sub comment unlike', {
        senderId: customerData.id,
        forum_id: Shareid,
        comment_id: forumid,
        sub_comment_id: subforumid,
      })
    // getForumCommentList()
  }

  // Dublications ================================= Delete =======================================////
  const forumDelete = (id) => {
    let newRequestBody = {
      forum_id: Shareid,
      comment_id: id,
    }
    deleteWithAuthCallNode(
      ApiConfig.UNIVERSR_MY_DELETE_FORUM_NEW,
      JSON.stringify(newRequestBody),
    )
      .then((res) => {
        console.log(res)
        swal(res.message)
        getForumCommentList()
      })
      .catch((err) => {
        setLoading(false)

        console.log(err)
      })
  }

  const ShareHandal = async () => {
    let requestBody = JSON.stringify({
      dynamicLinkInfo: {
        domainUriPrefix: 'https://myuniverse.page.link',

        link:
          'https://my-univrs.app.redbytes.in/comment/' +
          String(Shareid) +
          '/?user=' +
          String(customerData.id),

        androidInfo: {
          androidPackageName: 'com.my_universe',
        },

        iosInfo: {
          iosBundleId: 'org.myUniverse',
        },
      },
    })

    await fetch(
      'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBmt92Aby2xlZWOCIHeTutj8ywpF-4reyw',

      {
        method: 'POST',

        headers: {
          Accept: 'application/json',

          'Content-Type': 'application/json',
        },

        body: requestBody,
      },
    )
      .then((response) => response.json())

      .then((data) => {
        console.log('response', data)
        setShare(data.shortLink)
      })

      .catch((err) => {
        // this.setState({isVisible:false})

        console.log('error', err)
      })
  }


  return (
    <>
      <Sidebar />
      <Modal
        className="custom-dialog"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={handleClose}
      >
        <Modal.Header style={{ padding: '12px', width: '99%' }} closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
              {/* Are you sure you want to cancel this Friend Request? */}
              <div class="modal-body" style={{ marginTop: '-37px' }}>
                <ShareSocial
                  style={style}
                  url={ShareData}
                  socialTypes={[
                    'facebook',
                    'twitter',
                    'reddit',
                    'linkedin',
                    'email',
                    'pinterest',
                  ]}
                />
                <CopyToClipboard
                    text={ShareData}
                    onCopy={() => swal('Copied to Clipboard')}
                  >
                    <Link to="#" className='copy-icon'>
                      <i class="fa-solid fa-copy"></i>
                    </Link>
                  </CopyToClipboard>
              </div>
            </Modal.Body>
      </Modal>
      {/* <Header /> */}
      <main
        id="cxMain"
        className={sidebar ? 'cx-main cx-active' : 'cx-main'}
        style={{
          // height: "calc(100vh - 156px)",
          height: 'calc(100vh - 40px)',
          overflow: 'unset',
          // margin: 0,
          padding: '10',
        }}
      >
        <section
          className="content-main"
          style={{
            // height: "calc(100vh - 156px)",
            height: '100%',
            overflow: 'unset',
          }}
        >
          <Link to="/forum_list">
            <button
              style={{
                height: '34px',
                width: '34px',
                backgroundColor: '#e3e3e3',
                border: 'none',
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src={arrow} alt="arrow" />
            </button>
          </Link>

          <div
            className="row"
            style={{
              overflow: 'auto',
              height: 'calc(100vh - 94px)',
              marginTop: '20px',
              padding: '20px',
            }}
          >
            <div className="col-md-12">
              {loading ? (
                <LoaderCenter />
              ) : (
                <div
                  className=" forumListWrapper forumListWrapperi"
                  style={{
                    // marginTop: "20px",
                    // height: "calc(100vh - 308px) ",
                    height: '100%',
                  }}
                >
                  <div
                    className="inner-wrapper"
                    style={{
                      position: 'relative',
                    }}
                  >
                    <div>
                      <div
                        className="flInnerItemsWrapper"
                        style={{
                          position: 'relative',
                          margin: '0',
                        }}
                      >
                        <div className="f-custom-wrapper">
                          <div className="fl_section02">
                            <div className="fl_userTitle">
                              {forumList &&
                                forumList.map((item, index) => {
                                  return (
                                    <>
                                      {item.forum_posted_by.profile_img ? (
                                        <img
                                          src={item.forum_posted_by.profile_img}
                                          alt=""
                                          style={{
                                            width: '70px',
                                            height: '70px',
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={userDemo}
                                          alt=""
                                          style={{
                                            width: '70px',
                                            height: '70px',
                                          }}
                                        />
                                      )}

                                      <h1>{item.forum_posted_by.full_name}</h1>

                                      <h1>{item.type}</h1>
                                    </>
                                  )
                                })}
                            </div>
                            {forumList &&
                              forumList.map((item, index) => {
                                return (
                                  <>
                                    <p>{item.text}</p>
                                  </>
                                )
                              })}

                            <div className="d-flex gap-3">
                              {forumList &&
                                forumList.map((item, index) => {
                                  return (
                                    <div
                                      className="fl_section01"
                                      key={'item' + index}
                                    >
                                      <img
                                        src={item.forum_image[0]}
                                        alt=""
                                        style={{
                                          width: '120px',
                                          height: '120px',
                                          borderRadius: '10px',
                                          objectFit: 'cover',
                                        }}
                                      />
                                    </div>
                                  )
                                })}
                            </div>

                            <div className="fl_controlsWrapper mt-3">
                              {forumList &&
                                forumList.map((item, index) => {
                                  return (
                                    <ul>
                                      {item.is_like == true ? (
                                        <li className="active">
                                          <img
                                            style={{
                                              width: '18px',
                                              height: '18px',
                                            }}
                                            src={like}
                                            onClick={() => {
                                              likeDislikeForum(Shareid)
                                            }}
                                          />
                                          {item.like_count !== 0 ? (
                                            <h4>{item.like_count}</h4>
                                          ) : null}
                                        </li>
                                      ) : (
                                        <li>
                                          <img
                                            src={unlike}
                                            onClick={() => {
                                              likeDislikeForum(Shareid)
                                            }}
                                          />
                                          {item.like_count !== 0 ? (
                                            <h4>{item.like_count}</h4>
                                          ) : null}
                                        </li>
                                      )}
                                      {item.is_dislike == true ? (
                                        <li className="active">
                                          <img
                                            style={{
                                              width: '18px',
                                              height: '18px',
                                              transform: 'rotateX(180deg)',
                                            }}
                                            src={like}
                                            onClick={() => {
                                              DislikeForum(Shareid)
                                            }}
                                          />
                                          {item.dislike_count !== 0 ? (
                                            <h4>{item.dislike_count}</h4>
                                          ) : null}
                                        </li>
                                      ) : (
                                        <li>
                                          <img
                                            src={unlike}
                                            style={{
                                              transform: 'rotateX(180deg)',
                                            }}
                                            onClick={() => {
                                              DislikeForum(Shareid)
                                            }}
                                          />
                                          {item.dislike_count !== 0 ? (
                                            <h4>{item.dislike_count}</h4>
                                          ) : null}
                                        </li>
                                      )}

                                      <li>
                                        <img src={Comments} />
                                        {item.comment_count !== 0 ? (
                                          <h4>{item.comment_count}</h4>
                                        ) : null}
                                      </li>

                                      <li onClick={handleShow}>
                                        <img src={share} />
                                        <h4>Share</h4>
                                      </li>
                                    </ul>
                                  )
                                })}
                            </div>
                          </div>
                        </div>
                        {/* <div className="fl_section03 bg-dark">
                          <div
                            class="dropdown"
                            style={{
                              borderRadius: '8px',
                              height: '34px',
                              width: '34px',
                            }}
                          >
                            <button
                              class="btn"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i class="fa-solid fa-ellipsis-vertical"></i>
                            </button>
                            <ul
                              style={{
                                borderRadius: '10px',
                                border: '1px solid #ECECEC',
                                backgroundColor: '#ECECEC',
                                width: '30px !important',
                              }}
                              class="dropdown-menu house-manage-dropdown mmmmm"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li style={{ padding: '0 20px' }}>
                                <Link
                                  to="/device_details"
                                  class="dropdown-item"
                                  href="#"
                                  style={{
                                    borderBottom: '1px solid #e1e1e1',
                                    fontSize: '14px',
                                    fontFamily: 'Nunito',
                                    fontStyle: 'normal',
                                  }}
                                >
                                  Share
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div> */}
                      </div>

                      <div className="mt-2">
                        <div
                          className="f-input-wrapper"
                          style={{
                            height: '60px',
                            backgroundColor: '#ECECEC',
                            // border: '1px solid #BBBBBB',
                            borderRadius: '30px',
                            alignItems: 'center',
                            position: 'relative',
                          }}
                        >
                          <MentionsInput
                            allowSpaceInQuery={true}
                            placeholder="Leave a comment..."
                            allowSuggestionsAboveCursor={true}
                            forceSuggestionsAboveCursor={true} // <-- this does not work when suggestionsPortalHost is not used
                            scrollFocusedIntoView={true}
                            style={mentionsInputStyles}
                            value={commentTextData}
                            onChange={(e) => {
                              setCommentText(e.target.value)
                            }}
                          >
                            <Mention
                              style={mentionStyles}
                              data={Profilelistfriend}
                              appendSpaceOnAdd={true}
                              scrollFocusedIntoView={true}
                            />
                          </MentionsInput>
                          {/* <input
                            type="text"
                            name="text"
                            id="te"
                            value={commentTextData}
                            placeholder="Type your comment here…"
                            onChange={(e) => {
                              setCommentText(e.target.value)
                            }}
                            style={{
                              display: 'block',
                              width: '100%',
                              height: '100%',
                              backgroundColor: 'transparent',
                              padding: '0 75px 0 30px',
                              border: 'none',
                            }}
                          /> */}
                          <button
                            style={{
                              height: 'fit-content',
                              width: 'fit-content',
                              backgroundColor: 'transparent',
                              border: 'none',
                              position: 'absolute',
                              top: '50%',
                              right: '5px',
                              transform: 'translateY(-50%)',
                            }}
                          >
                            <img
                              src={send}
                              onClick={() => {
                                subCommenthandal(Shareid)
                              }}
                              alt=""
                            />
                          </button>
                        </div>
                      </div>

                      <div className="comments-container mt-3">
                        <div
                          className="fc-wrap"
                          style={{
                            overflow: 'auto',
                            padding: '20px',
                          }}
                        >
                          {recentCommentList &&
                            recentCommentList.map((data, index) => {
                              console.log(data.comment_tagged_user_id)
                              return (
                                <>
                                  <div className="d-flex">
                                    <div>
                                      {data.commented_user_data.user_profile ? (
                                        <img
                                          src={
                                            data.commented_user_data
                                              .user_profile
                                          }
                                          alt="thumb"
                                          style={{
                                            height: '30px',
                                            width: '30px',
                                            borderRadius: 'px',
                                            objectFit: 'cover',
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={userDemo}
                                          alt=""
                                          style={{
                                            height: '30px',
                                            width: '30px',
                                            borderRadius: 'px',
                                            objectFit: 'cover',
                                          }}
                                        />
                                      )}
                                    </div>

                                    <div
                                      class="accordion col-6 ms-3"
                                      id="accordionExample"
                                      style={{
                                        marginTop: '-12px',
                                      }}
                                    >
                                      <div class="accordion-item">
                                        <h2
                                          class="accordion-header"
                                          id="headingOne"
                                        >
                                          <div
                                            class="accordion-button d-flex justify-content-between"
                                            type="button"
                                            aria-expanded="true"
                                            style={{
                                              background: '#F2F2F2',
                                              backgroundImage: 'none',
                                            }}
                                          >
                                            <p
                                              style={{
                                                color: 'black',
                                                fontSize: '14px',
                                                fontWeight: '600',
                                              }}
                                              className="m-0"
                                            >
                                              {
                                                data.commented_user_data
                                                  .user_name
                                              }
                                            </p>
                                            {data.user == customerData.id ? (
                                              <div className="fl_section03 ">
                                                <div
                                                  class="dropdown"
                                                  style={{
                                                    borderRadius: '8px',
                                                    height: '34px',
                                                    width: '34px',
                                                    color: 'black',
                                                    marginLeft: '360px',
                                                    // float:'right'
                                                  }}
                                                >
                                                  <button
                                                    class="btn"
                                                    type="button"
                                                    id="dropdownMenuButton1"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                  >
                                                    <i class="fa-solid fa-ellipsis-vertical"></i>
                                                  </button>
                                                  <ul
                                                    style={{
                                                      borderRadius: '10px',
                                                      border:
                                                        '1px solid #ECECEC',
                                                      backgroundColor:
                                                        '#ECECEC',
                                                      width: '30px !important',
                                                    }}
                                                    class="dropdown-menu house-manage-dropdown mmmmm"
                                                    aria-labelledby="dropdownMenuButton1"
                                                  >
                                                    <li
                                                      style={{
                                                        padding: '0 20px',
                                                        fontSize: '14px',
                                                        fontFamily: 'Nunito',
                                                        fontStyle: 'normal',
                                                        cursor: 'pointer',
                                                      }}
                                                    >
                                                      <a
                                                        onClick={() => {
                                                          forumDelete(data.id)
                                                        }}
                                                        // data-bs-toggle="modal"
                                                        // data-bs-target="#exampleModal"
                                                        // class="dropdown-item"
                                                        // href="#"
                                                      >
                                                        Delete
                                                      </a>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </h2>

                                        <div
                                          id="collapseOne"
                                          class="accordion-collapse collapse show"
                                          aria-labelledby="headingOne"
                                          data-bs-parent="#accordionExample"
                                        >
                                          <div
                                            class="accordion-body"
                                            style={{
                                              backgroundColor: '#F2F2F2',
                                              height: 'auto',
                                            }}
                                          >

                                      {data.comment_tagged_user_id ? (

                                            <div className="fl_section03">
                                              {/* {data.comment} */}
                                              <Link
                                            to={
                                              '/ForunGroup/' +
                                              data.comment_tagged_user_id
                                            }
                                          >
                                              <Highlighter
                                                highlightClassName="YourHighlightClass"
                                                searchWords={TextColor}
                                                autoEscape={true}
                                                textToHighlight={data.comment}
                                              />
                                              </Link>
                                            </div>
                                      ) : (
                                             <div className="fl_section03">
                                              {/* {data.comment} */}
                                              <Highlighter
                                                highlightClassName="YourHighlightClass"
                                                searchWords={TextColor}
                                                autoEscape={true}
                                                textToHighlight={data.comment}
                                              />
                                            </div>
                                      )}

                                            <div className="fl_controlsWrapper">
                                              <ul
                                                className="gap-5"
                                                style={{
                                                  display: 'flex',
                                                  marginTop: '15px',
                                                }}
                                              >
                                                {data.is_like == true ? (
                                                  <li className="active d-flex">
                                                    <img
                                                      style={{
                                                        width: '16px',
                                                        height: '16px',
                                                        marginRight: '8px',
                                                      }}
                                                      src={like}
                                                      onClick={() => {
                                                        SublikeDislikeForum(
                                                          data.id,
                                                        )
                                                      }}
                                                    />
                                                    {data.like_count !== 0 ? (
                                                      <h4
                                                        style={{
                                                          color: '#707070',
                                                          fontSize: '17px',
                                                        }}
                                                      >
                                                        {data.like_count}
                                                      </h4>
                                                    ) : null}
                                                  </li>
                                                ) : (
                                                  <li class="d-flex ">
                                                    <img
                                                      src={unlike}
                                                      onClick={() => {
                                                        SublikeDislikeForum(
                                                          data.id,
                                                        )
                                                      }}
                                                      style={{
                                                        width: '16px',
                                                        height: '16px',
                                                        marginRight: '8px',
                                                      }}
                                                    />
                                                    {data.like_count !== 0 ? (
                                                      <h4
                                                        style={{
                                                          color: '#707070',
                                                          fontSize: '17px',
                                                        }}
                                                      >
                                                        {data.like_count}
                                                      </h4>
                                                    ) : null}
                                                  </li>
                                                )}
                                                {data.is_dislike == true ? (
                                                  <li className="active d-flex ">
                                                    <img
                                                      style={{
                                                        width: '16px',
                                                        height: '16px',
                                                        transform:
                                                          'rotateX(180deg)',
                                                        marginRight: '8px',
                                                      }}
                                                      src={like}
                                                      onClick={() => {
                                                        SubDislikeForum(data.id)
                                                      }}
                                                    />
                                                    {data.dislike_count !==
                                                    0 ? (
                                                      <h4
                                                        style={{
                                                          color: '#707070',
                                                          fontSize: '17px',
                                                        }}
                                                      >
                                                        {data.dislike_count}
                                                      </h4>
                                                    ) : null}
                                                  </li>
                                                ) : (
                                                  <li class="d-flex ">
                                                    <img
                                                      src={unlike}
                                                      style={{
                                                        width: '16px',
                                                        height: '16px',
                                                        marginRight: '8px',
                                                        transform:
                                                          'rotateX(180deg)',
                                                      }}
                                                      onClick={() => {
                                                        SubDislikeForum(data.id)
                                                      }}
                                                    />
                                                    {data.dislike_count !==
                                                    0 ? (
                                                      <h4
                                                        style={{
                                                          color: '#707070',
                                                          fontSize: '17px',
                                                        }}
                                                      >
                                                        {data.dislike_count}
                                                      </h4>
                                                    ) : null}
                                                  </li>
                                                )}
                                                <Link
                                                  to="/comment"
                                                  state={{
                                                    ...data,
                                                  }}
                                                >
                                                  <li>
                                                    {data.comment_count !==
                                                    0 ? (
                                                      <h4>
                                                        {data.comment_count}
                                                      </h4>
                                                    ) : null}
                                                  </li>
                                                </Link>
                                              </ul>
                                            </div>
                                            <div className="row col-12 mt-0">
                                              <div className="col-12 p-0">
                                                <Accordion defaultActiveKey="0">
                                                  <Accordion.Item
                                                    eventKey="0"
                                                    className="p-0"
                                                  >
                                                    <Accordion.Header
                                                      style={{
                                                        background: '#F2F2F2',
                                                        backgroundImage: 'none',
                                                      }}
                                                    >
                                                      {' '}
                                                      <p
                                                        style={{
                                                          fontSize: '14px',
                                                          fontWeight: 'normal',
                                                        }}
                                                        // class="btn "
                                                        data-bs-toggle="collapse"
                                                        href="#collapseExample"
                                                        role="button"
                                                        aria-expanded="false"
                                                        aria-controls="collapseExample"
                                                      >
                                                        Reply
                                                      </p>
                                                      <div className="col-6">
                                                        <p
                                                          style={{
                                                            fontSize: '14px',
                                                            fontWeight:
                                                              'normal',
                                                            float: 'right',
                                                          }}
                                                          data-bs-toggle="collapse"
                                                          href="#collapseExample"
                                                          role="button"
                                                          aria-expanded="false"
                                                          aria-controls="collapseExample"
                                                        >
                                                          View Replies
                                                        </p>{' '}
                                                      </div>
                                                    </Accordion.Header>

                                                    <Accordion.Body>
                                                      <div
                                                        class="card card-body text-dark p-0"
                                                        style={{
                                                          backgroundColor:
                                                            '#F2F2F2',
                                                          height: 'auto',
                                                          border: 'none',
                                                          margin: '20px',
                                                        }}
                                                      >
                                                        <div
                                                          id="f-input-wrapper" 
                                                          style={{
                                                            height: '55px',
                                                            backgroundColor:
                                                              '#ECECEC',
                                                            border:
                                                              '1px solid #BBBBBB',
                                                            borderRadius:
                                                              '30px',
                                                            display: 'flex',
                                                            alignItems:
                                                              'center',
                                                            position:
                                                              'relative',
                                                            bottom: '8px',
                                                          }}
                                                        >
                                                          <MentionsInput
                                                            allowSpaceInQuery={
                                                              true
                                                            }
                                                            placeholder="Leave a comment..."
                                                            allowSuggestionsAboveCursor={
                                                              true
                                                            }
                                                            forceSuggestionsAboveCursor={
                                                              true
                                                            } // <-- this does not work when suggestionsPortalHost is not used
                                                            scrollFocusedIntoView={
                                                              true
                                                            }
                                                            style={
                                                              mentionsInputStyles
                                                            }
                                                            value={
                                                              SubcommentTextData
                                                            }
                                                            onChange={(e) => {
                                                              setSubCommentText(
                                                                e.target.value,
                                                              )
                                                            }}
                                                          >
                                                            <Mention
                                                              className="maintain_list_subcomment"
                                                              data={
                                                                Profilelistfriend
                                                              }
                                                              appendSpaceOnAdd={
                                                                true
                                                              }
                                                              scrollFocusedIntoView={
                                                                true
                                                              }
                                                            />
                                                          </MentionsInput>

                                                          <button
                                                            style={{
                                                              height:
                                                                'fit-content',
                                                              width:
                                                                'fit-content',
                                                              backgroundColor:
                                                                'transparent',
                                                              border: 'none',
                                                              position:
                                                                'absolute',
                                                              top: '50%',
                                                              right: '5px',
                                                              transform:
                                                                'translateY(-50%)',
                                                            }}
                                                          >
                                                            <img
                                                              src={send}
                                                              onClick={() => {
                                                                TagsubCommenthandal(
                                                                  data.id,
                                                                )
                                                              }}
                                                              alt=""
                                                            />
                                                          </button>
                                                        </div>
                                                        {data.sub_comments &&
                                                          data.sub_comments.map(
                                                            (
                                                              subdata,
                                                              index,
                                                            ) => {
                                                              console.log(
                                                                subdata,
                                                              )
                                                              return (
                                                                <div
                                                                  className="my-2"
                                                                  style={{
                                                                    display:
                                                                      'flex',
                                                                  }}
                                                                >
                                                                  <div
                                                                    className="img"
                                                                    style={{
                                                                      marginRight:
                                                                        '30px',
                                                                    }}
                                                                  >
                                                                    <img
                                                                      src={
                                                                        subdata
                                                                          .commented_user_data
                                                                          .user_profile
                                                                      }
                                                                      alt="thumb"
                                                                      style={{
                                                                        height:
                                                                          '24px',
                                                                        width:
                                                                          '24px',
                                                                        borderRadius:
                                                                          '5px',
                                                                        objectFit:
                                                                          'cover',
                                                                      }}
                                                                    />
                                                                  </div>
                                                                  <div className="contain">
                                                                    <p
                                                                      style={{
                                                                        color:
                                                                          '#008A91',
                                                                        fontSize:
                                                                          '14px',
                                                                        fontWeight:
                                                                          '600',
                                                                        // marginTop:"15px"
                                                                      }}
                                                                      // className="ms-3"
                                                                    >
                                                                      {
                                                                        subdata
                                                                          .commented_user_data
                                                                          .user_name
                                                                      }
                                                                    </p>
                                      {subdata.tagged_user_id ? (

                                                                    <p
                                                                      style={{
                                                                        color:
                                                                          '#1D1D25',
                                                                        fontSize:
                                                                          '14px',
                                                                        fontWeight:
                                                                          'normal',
                                                                        marginTop:
                                                                          '5px',
                                                                      }}
                                                                      // dangerouslySetInnerHTML={{
                                                                      //   __html: subdata.comment.replace(
                                                                      //     /\n\r?/g,
                                                                      //     '<br />',
                                                                      //   ),
                                                                      // }}
                                                                    >
                                                                      <Link
                                            to={
                                              '/ForunGroup/' +
                                              subdata.tagged_user_id
                                            }
                                          >
                                                                      <Highlighter
                                                                        highlightClassName="YourHighlightClass"
                                                                        searchWords={
                                                                          TextColor
                                                                        }
                                                                        autoEscape={
                                                                          true
                                                                        }
                                                                        textToHighlight={
                                                                          subdata.comment
                                                                        }
                                                                      />
                                                                      </Link>
                                                                    </p>

                                                                    
                                      ) : (
                                        <p
                                                                      style={{
                                                                        color:
                                                                          '#1D1D25',
                                                                        fontSize:
                                                                          '14px',
                                                                        fontWeight:
                                                                          'normal',
                                                                        marginTop:
                                                                          '5px',
                                                                      }}
                                                                      // dangerouslySetInnerHTML={{
                                                                      //   __html: subdata.comment.replace(
                                                                      //     /\n\r?/g,
                                                                      //     '<br />',
                                                                      //   ),
                                                                      // }}
                                                                    >
                                                                      <Highlighter
                                                                        highlightClassName="YourHighlightClass"
                                                                        searchWords={
                                                                          TextColor
                                                                        }
                                                                        autoEscape={
                                                                          true
                                                                        }
                                                                        textToHighlight={
                                                                          subdata.comment
                                                                        }
                                                                      />
                                                                    </p>
                                      )}


                                                                    <div className="fl_controlsWrapper">
                                                                      <ul
                                                                        className="gap-5"
                                                                        style={{
                                                                          display:
                                                                            'flex',
                                                                          marginTop:
                                                                            '15px',
                                                                        }}
                                                                      >
                                                                        {subdata.is_like ==
                                                                        true ? (
                                                                          <li className="active d-flex">
                                                                            <img
                                                                              style={{
                                                                                width:
                                                                                  '16px',
                                                                                height:
                                                                                  '16px',
                                                                                marginRight:
                                                                                  '8px',
                                                                              }}
                                                                              src={
                                                                                like
                                                                              }
                                                                              onClick={() => {
                                                                                ForumSubCommentLike(
                                                                                  subdata.comment_id,
                                                                                  data.id,
                                                                                )
                                                                              }}
                                                                            />
                                                                            {subdata.like_count !==
                                                                            0 ? (
                                                                              <h4
                                                                                style={{
                                                                                  color:
                                                                                    '#707070',
                                                                                  fontSize:
                                                                                    '17px',
                                                                                }}
                                                                              >
                                                                                {
                                                                                  subdata.like_count
                                                                                }
                                                                              </h4>
                                                                            ) : null}
                                                                          </li>
                                                                        ) : (
                                                                          <li class="d-flex ">
                                                                            <img
                                                                              src={
                                                                                unlike
                                                                              }
                                                                              onClick={() => {
                                                                                ForumSubCommentLike(
                                                                                  subdata.comment_id,
                                                                                  data.id,
                                                                                )
                                                                              }}
                                                                              style={{
                                                                                width:
                                                                                  '16px',
                                                                                height:
                                                                                  '16px',
                                                                                marginRight:
                                                                                  '8px',
                                                                              }}
                                                                            />
                                                                            {subdata.like_count !==
                                                                            0 ? (
                                                                              <h4
                                                                                style={{
                                                                                  color:
                                                                                    '#707070',
                                                                                  fontSize:
                                                                                    '17px',
                                                                                }}
                                                                              >
                                                                                {
                                                                                  subdata.like_count
                                                                                }
                                                                              </h4>
                                                                            ) : null}
                                                                          </li>
                                                                        )}
                                                                        {subdata.is_dislike ==
                                                                        true ? (
                                                                          <li
                                                                            className="active"
                                                                            class="d-flex "
                                                                          >
                                                                            <img
                                                                              style={{
                                                                                width:
                                                                                  '16px',
                                                                                height:
                                                                                  '16px',
                                                                                transform:
                                                                                  'rotateX(180deg)',
                                                                                marginRight:
                                                                                  '8px',
                                                                              }}
                                                                              src={
                                                                                like
                                                                              }
                                                                              onClick={() => {
                                                                                ForumSubCommentDisLike(
                                                                                  subdata.comment_id,
                                                                                  data.id,
                                                                                )
                                                                              }}
                                                                            />
                                                                            {subdata.dislike_count !==
                                                                            0 ? (
                                                                              <h4
                                                                                style={{
                                                                                  color:
                                                                                    '#707070',
                                                                                  fontSize:
                                                                                    '17px',
                                                                                }}
                                                                              >
                                                                                {
                                                                                  subdata.dislike_count
                                                                                }
                                                                              </h4>
                                                                            ) : null}
                                                                          </li>
                                                                        ) : (
                                                                          <li class="d-flex ">
                                                                            <img
                                                                              src={
                                                                                unlike
                                                                              }
                                                                              onClick={() => {
                                                                                ForumSubCommentDisLike(
                                                                                  subdata.comment_id,
                                                                                  data.id,
                                                                                )
                                                                              }}
                                                                              style={{
                                                                                width:
                                                                                  '16px',
                                                                                height:
                                                                                  '16px',
                                                                                marginRight:
                                                                                  '8px',
                                                                                transform:
                                                                                  'rotateX(180deg)',
                                                                              }}
                                                                            />
                                                                            {subdata.dislike_count !==
                                                                            0 ? (
                                                                              <h4
                                                                                style={{
                                                                                  color:
                                                                                    '#707070',
                                                                                  fontSize:
                                                                                    '17px',
                                                                                }}
                                                                              >
                                                                                {
                                                                                  subdata.dislike_count
                                                                                }
                                                                              </h4>
                                                                            ) : null}
                                                                          </li>
                                                                        )}
                                                                        {/* <Link
                                                                          to="/comment"
                                                                          state={{
                                                                            ...subdata,
                                                                          }}
                                                                        >
                                                                          <li>
                                                                            <img
                                                                              src={
                                                                                Comments
                                                                              }
                                                                              onClick={() =>
                                                                                handleClickColap(
                                                                                  subdata.id,
                                                                                )
                                                                              }
                                                                              aria-controls={
                                                                                subdata.id
                                                                              }
                                                                              aria-expanded={
                                                                                open
                                                                              }
                                                                            />
                                                                            {subdata.comment_count !==
                                                                            0 ? (
                                                                              <h4>
                                                                                {
                                                                                  subdata.comment_count
                                                                                }
                                                                              </h4>
                                                                            ) : null}
                                                                          </li>
                                                                        </Link> */}
                                                                      </ul>
                                                                      <Fade
                                                                        className={
                                                                          open[
                                                                            subdata
                                                                              .id
                                                                          ]
                                                                            ? 'mt-4'
                                                                            : 'd-none mt-4'
                                                                        }
                                                                        in={
                                                                          open[
                                                                            subdata
                                                                              .id
                                                                          ]
                                                                        }
                                                                      >
                                                                        <div
                                                                          id={
                                                                            subdata.id
                                                                          }
                                                                        >
                                                                          <div
                                                                            className="f-input-wrapper"
                                                                            style={{
                                                                              height:
                                                                                '55px',
                                                                              backgroundColor:
                                                                                '#ECECEC',
                                                                              border:
                                                                                '1px solid #BBBBBB',
                                                                              borderRadius:
                                                                                '30px',
                                                                              display:
                                                                                'flex',
                                                                              alignItems:
                                                                                'center',
                                                                              position:
                                                                                'relative',
                                                                              bottom:
                                                                                '8px',
                                                                              width:
                                                                                '157%',
                                                                            }}
                                                                          >
                                                                            <input
                                                                              type="text"
                                                                              name="text"
                                                                              id="te"
                                                                              // value={
                                                                              //   commentTextData
                                                                              // }
                                                                              placeholder="Type your comment here…"
                                                                              style={{
                                                                                display:
                                                                                  'block',
                                                                                width:
                                                                                  '100%',
                                                                                height:
                                                                                  '100%',
                                                                                backgroundColor:
                                                                                  'transparent',
                                                                                padding:
                                                                                  '0 75px 0 30px',
                                                                                border:
                                                                                  'none',
                                                                              }}
                                                                            />
                                                                            <button
                                                                              style={{
                                                                                height:
                                                                                  'fit-content',
                                                                                width:
                                                                                  'fit-content',
                                                                                backgroundColor:
                                                                                  'transparent',
                                                                                border:
                                                                                  'none',
                                                                                position:
                                                                                  'absolute',
                                                                                top:
                                                                                  '50%',
                                                                                right:
                                                                                  '5px',
                                                                                transform:
                                                                                  'translateY(-50%)',
                                                                              }}
                                                                            >
                                                                              <img
                                                                                src={
                                                                                  send
                                                                                }
                                                                                onClick={() => {
                                                                                  addComment(
                                                                                    Shareid,
                                                                                  )
                                                                                }}
                                                                                alt=""
                                                                              />
                                                                            </button>
                                                                          </div>
                                                                        </div>
                                                                      </Fade>
                                                                    </div>
                                                                  </div>
                                                                </div>

                                                                // </div>
                                                              )
                                                            },
                                                          )}
                                                      </div>
                                                    </Accordion.Body>
                                                  </Accordion.Item>
                                                </Accordion>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* old Card */}
                                </>
                              )
                            })}
                        </div>

                        <div className="d-flex justify-content-center my-2">
                          <img
                            style={{
                              cursor: 'pointer',
                            }}
                            src={down}
                            onClick={() => {
                              setRecentCommentList(allCommentList)
                            }}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default ForumComments

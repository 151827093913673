import React, { useContext, useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { AppContext } from '../../context/AppContext'
import thumbImg from '../../assets/images/watch.png'
import status from '../../assets/images/status.svg'
import ApiConfig from '../../api/ApiConfig'
import { simpleGetCallWithErrorResponse } from '../../api/ApiServices'
import LoaderCenter from '../../sharedComponent/LoaderCenter'

const OrderDetails = () => {
  const params = useParams()
  let id = params.id
  const { customerData } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const [orderDetails, setOrderDetails] = useState([])

  useEffect(() => {
    geDetails()
  }, [])

  const geDetails = () => {
    setLoading(true)

    simpleGetCallWithErrorResponse(
      ApiConfig.ORDER_DETAILS + id,
      JSON.stringify({ ...customerData }),
    ).then((res) => {
      console.log(res)
      setLoading(false)
      setOrderDetails(res.json.order)
    })
  }

  return (
    <>
      {loading ? (
        <LoaderCenter />
      ) : (
        <div
          className="row"
          style={{
            maxWidth: '725px',
            margin: '0 auto',
          }}
        >
          <div
            className="row"
            style={{
              maxWidth: '725px',
              margin: '0 auto',
            }}
          >
            {/* {orderDetails && orderDetails.length > 0 ? (
              orderDetails.map((orderDetailsitem, index) => {
                console.log('orderDetailsitem', orderDetailsitem)
                return ( */}
            <>
              <div className="col-12">
                <span
                  style={{
                    color: '#1D1D25',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    display: 'block',
                    marginBottom: '20px',
                  }}
                >
                  Order Details
                </span>
              </div>
              <div className="col-12 p-0 col-lg-6 h-padding">
                <div className="gbl_thumbWrapper">
                  <div className="img_thumbWrapper">
                    <img
                      src={orderDetails.order_image}
                      height="158px"
                      alt=""
                      style={{
                        objectFit: 'cover',
                      }}
                    />
                  </div>

                  <div
                    className="gbl_thumbContent"
                    style={{
                      padding: '20px',
                    }}
                  >
                    <h1
                      style={{
                        fontSize: '16px',
                        fontWeight: '500',
                        color: 'hsla(240, 12%, 13%, 1)',
                        marginTop: '5px',
                        letterSpacing: '0',
                        font: 'normal normal 600 16px/22px Nunito',
                        opacity: '0px',
                      }}
                    >
                      {/* {orderDetails.order_items.subtype} */}
                    </h1>
                    <p
                      style={{
                        color: 'var(--unnamed-color-707070)',
                        font: 'normal normal normal 14px/19px Nunito',
                        letterSpacing: '0px',
                        color: 'hsla(0, 0%, 44%, 1)',
                        opacity: '1px',
                        marginTop: '12px',
                      }}
                    >
                      {orderDetails.order_status}
                    </p>
                  </div>
                </div>

                <div
                  className="detail-address"
                  style={{
                    marginTop: '20px',
                    padding: '20px',
                    backgroundColor: '#F5F5F5',
                    borderRadius: '20px',
                  }}
                >
                  <p
                    style={{
                      color: '#1D1D25',
                      fontSize: '16px',
                      fontWeight: '600',
                    }}
                  >
                    {orderDetails.order_address &&
                      orderDetails.order_address.full_name}
                  </p>
                  <p
                    style={{
                      color: '#707070',
                      fontSize: '14px',
                      fontWeight: 'normal',
                      marginTop: '10px',
                    }}
                  >
                    {orderDetails.order_address &&
                      orderDetails.order_address.address}
                    <br />
                    {orderDetails.order_address &&
                      orderDetails.order_address.city}{' '}
                    -{' '}
                    {orderDetails.order_address &&
                      orderDetails.order_address.pincode}{' '}
                    <br />
                    Phone Number :-{' '}
                    {orderDetails.order_address &&
                      orderDetails.order_address.mobile_number}
                  </p>
                </div>
              </div>
              <div className="col-12 p-0 col-lg-6 h-padding">
                <div
                  className="order-status"
                  style={{
                    backgroundColor: '#F5F5F5',
                    padding: '20px',
                    borderRadius: '20px',
                  }}
                >
                  <div
                    className="status-heading d-flex  justify-content-between "
                    style={{
                      paddingBottom: '10px',
                      borderBottom: '1px solid #E1E1E1 ',
                    }}
                  >
                    <span
                      style={{
                        color: '#1D1D25',
                        fontSize: '16px',
                        fontWeight: '600',
                      }}
                    >
                      Order Status
                    </span>
                    <span
                      style={{
                        color: '#707070',
                        fontSize: '14px',
                        fontWeight: 'normal',
                      }}
                    >
                      Order ID #64895
                    </span>
                  </div>
                  <div
                    className="status-stepper"
                    style={{
                      marginTop: '20px',
                      display: 'flex',
                      paddingBottom: '20px',
                      borderBottom: '1px solid #E1E1E1',
                    }}
                  >
                    <div className="stepper">
                      <img src={status} alt="status" />
                    </div>
                    <div className="titles ms-4">
                      <div className="ordered">
                        <p
                          style={{
                            color: '#21212C',
                            fontSize: '14px',
                            fontWeight: 'bold',
                          }}
                        >
                          Ordered
                        </p>
                        <p
                          style={{
                            color: '#707070',
                            fontSize: '14px',
                            fontWeight: 'normal',
                          }}
                        >
                          {orderDetails.order_date}
                        </p>
                      </div>
                      <div
                        className="ordered"
                        style={{
                          marginTop: '24px',
                        }}
                      >
                        <p
                          style={{
                            color: '#21212C',
                            fontSize: '14px',
                            fontWeight: 'bold',
                          }}
                        >
                          Delivered
                        </p>
                        <p
                          style={{
                            color: '#707070',
                            fontSize: '14px',
                            fontWeight: 'normal',
                          }}
                        >
                          {orderDetails.last_updated}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="status-info d-flex justify-content-between "
                    style={{
                      marginTop: '20px',
                    }}
                  >
                    <span
                      style={{
                        color: '#BBBBBB',
                        fontSize: '14px',
                        fontWeight: 'normal',
                      }}
                    >
                      Tracking ID
                    </span>
                    <span
                      style={{
                        color: '#21212C',
                        fontSize: '14px',
                        fontWeight: 'bold',
                      }}
                    >
                      {/* ARX846
                       */}
                      N/N
                    </span>
                  </div>
                  <div
                    className="status-info d-flex justify-content-between "
                    style={{
                      marginTop: '12px',
                    }}
                  >
                    <span
                      style={{
                        color: '#BBBBBB',
                        fontSize: '14px',
                        fontWeight: 'normal',
                      }}
                    >
                      Dealer
                    </span>
                    <span
                      style={{
                        color: '#21212C',
                        fontSize: '14px',
                        fontWeight: 'bold',
                      }}
                    >
                      {/* By Bluedart */}
                      N/N
                    </span>
                  </div>
                </div>
                <div
                  className="order-price"
                  style={{
                    marginTop: '20px',
                    backgroundColor: '#F5F5F5',
                    padding: '20px',
                    borderRadius: '20px',
                  }}
                >
                  <div className="status-info d-flex justify-content-between ">
                    <span
                      style={{
                        color: '#BBBBBB',
                        fontSize: '14px',
                        fontWeight: 'normal',
                      }}
                    >
                      Tracking ID
                    </span>
                    <span
                      style={{
                        color: '#21212C',
                        fontSize: '14px',
                        fontWeight: 'bold',
                      }}
                    >
                      {/* ARX846 */}
                      N/N
                    </span>
                  </div>
                  <div
                    className="status-info d-flex justify-content-between "
                    style={{
                      marginTop: '12px',
                    }}
                  >
                    <span
                      style={{
                        color: '#BBBBBB',
                        fontSize: '14px',
                        fontWeight: 'normal',
                      }}
                    >
                      Dealer
                    </span>
                    <span
                      style={{
                        color: '#21212C',
                        fontSize: '14px',
                        fontWeight: 'bold',
                      }}
                    >
                      {/* By Bluedart */}
                      N/N
                    </span>
                  </div>
                </div>
              </div>
            </>
            {/* )
              })
            ) : (
              <p
                style={{
                  fontSize: '20px',
                  marginTop: '200px',
                }}
                className=" text-center justify-content-center align-items-center"
              >
                Oops! It doesn't look like there are any Event in your area.
                <br /> Why don't you try expanding your search criteria?
              </p>
            )} */}
          </div>
        </div>
      )}
    </>
  )
}

export default OrderDetails

// import React from "react";
// import { Link } from "react-router-dom";
// import chatpp from "../../assets/images/chatListAvatar01.png";

// function ChatSidebar() {
//   return (
//     <>
//       <div>

//         <div className="chat_sidebarWrapper">
//           <div className="chat_tabList">
//             <ul className="scrollbar thin-scroll">
//               <div class="force-overflow"></div>
//               <li style={{ marginBottom: "5px" }}>
//                 <div className="chat_listItem">
//                   <div className="chatListAvatar">
//                     <img src={chatpp} alt="" />
//                   </div>
//                   <div className="chatListTextcontent">
//                     <h1>Alfred Wilson</h1>
//                     <p>
//                       Can I call you back later? I'm in a hurry to communicate
//                     </p>
//                   </div>
//                   <h2>10:26</h2>
//                 </div>
//               </li>

//               <li className="active" style={{ marginBottom: "5px" }}>
//                 <div className="chat_listItem">
//                   <div className="chatListAvatar">
//                     <img src={chatpp} alt="" />
//                   </div>
//                   <div className="chatListTextcontent">
//                     <h1>Alfred Wilson</h1>
//                     <p>
//                       Can I call you back later? I'm in a hurry to communicate
//                     </p>
//                   </div>
//                   <h2>10:26</h2>
//                 </div>
//               </li>

//               <li style={{ marginBottom: "5px" }}>
//                 <div className="chat_listItem">
//                   <div className="chatListAvatar">
//                     <img src={chatpp} alt="" />
//                   </div>
//                   <div className="chatListTextcontent">
//                     <h1>Alfred Wilson</h1>
//                     <p>
//                       Can I call you back later? I'm in a hurry to communicate
//                     </p>
//                   </div>
//                   <h2>10:26</h2>
//                 </div>
//               </li>

//               <li style={{ marginBottom: "5px" }}>
//                 <div className="chat_listItem">
//                   <div className="chatListAvatar">
//                     <img src={chatpp} alt="" />
//                   </div>
//                   <div className="chatListTextcontent">
//                     <h1>Alfred Wilson</h1>
//                     <p>
//                       Can I call you back later? I'm in a hurry to communicate
//                     </p>
//                   </div>
//                   <h2>10:26</h2>
//                 </div>
//               </li>

//               <li style={{ marginBottom: "5px" }}>
//                 <div className="chat_listItem">
//                   <div className="chatListAvatar">
//                     <img src={chatpp} alt="" />
//                   </div>
//                   <div className="chatListTextcontent">
//                     <h1>Alfred Wilson</h1>
//                     <p>
//                       Can I call you back later? I'm in a hurry to communicate
//                     </p>
//                   </div>
//                   <h2>10:26</h2>
//                 </div>
//               </li>

//               <li style={{ marginBottom: "5px" }}>
//                 <div className="chat_listItem">
//                   <div className="chatListAvatar">
//                     <img src={chatpp} alt="" />
//                   </div>
//                   <div className="chatListTextcontent">
//                     <h1>Alfred Wilson</h1>
//                     <p>
//                       Can I call you back later? I'm in a hurry to communicate
//                     </p>
//                   </div>
//                   <h2>10:26</h2>
//                 </div>
//               </li>

//               <li style={{ marginBottom: "5px" }}>
//                 <div className="chat_listItem">
//                   <div className="chatListAvatar">
//                     <img src={chatpp} alt="" />
//                   </div>
//                   <div className="chatListTextcontent">
//                     <h1>Alfred Wilson</h1>
//                     <p>
//                       Can I call you back later? I'm in a hurry to communicate
//                     </p>
//                   </div>
//                   <h2>10:26</h2>
//                 </div>
//               </li>

//               <li style={{ marginBottom: "5px" }}>
//                 <div className="chat_listItem">
//                   <div className="chatListAvatar">
//                     <img src={chatpp} alt="" />
//                   </div>
//                   <div className="chatListTextcontent">
//                     <h1>Alfred Wilson</h1>
//                     <p>
//                       Can I call you back later? I'm in a hurry to communicate
//                     </p>
//                   </div>
//                   <h2>10:26</h2>
//                 </div>
//               </li>

//               <li style={{ marginBottom: "5px" }}>
//                 <div className="chat_listItem">
//                   <div className="chatListAvatar">
//                     <img src={chatpp} alt="" />
//                   </div>
//                   <div className="chatListTextcontent">
//                     <h1>Alfred Wilson</h1>
//                     <p>
//                       Can I call you back later? I'm in a hurry to communicate
//                     </p>
//                   </div>
//                   <h2>10:26</h2>
//                 </div>
//               </li>

//               <li style={{ marginBottom: "5px" }}>
//                 <div className="chat_listItem">
//                   <div className="chatListAvatar">
//                     <img src={chatpp} alt="" />
//                   </div>
//                   <div className="chatListTextcontent">
//                     <h1>Alfred Wilson</h1>
//                     <p>
//                       Can I call you back later? I'm in a hurry to communicate
//                     </p>
//                   </div>
//                   <h2>10:26</h2>
//                 </div>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default ChatSidebar;

// import React from 'react'
// import { Link } from "react-router-dom";
// import chatpp from "../../assets/images/chatListAvatar01.png";

// function ChatSidebar() {
//   return (
//     <div className="chat_sidebarWrapper">
//     <div className="chat_tabs_header">
//       <ul>
//         <li>
//           <Link to="#" className="ct_active" style={{boxShadow: "0px 3px 12px #017C8266",width:"90%"}}>
//             Friends
//           </Link>{" "}
//         </li>
//         <li>
//           <Link to="#" style={{width:"90%"}}>Work</Link>{" "}
//         </li>
//         <li>
//           <Link to="#" style={{width:"90%"}}>Study</Link>{" "}
//         </li>
//       </ul>
//     </div>
//     <div className="chat_tabList">

//       <ul className="scrollbar thin-scroll">
//       <div class="force-overflow"></div>
//         <li style={{marginBottom:"10px"}}>
//           <div className="chat_listItem">
//             <div className="chatListAvatar">
//               <img src={chatpp} alt="" />
//             </div>
//             <div className="chatListTextcontent">
//               <h1>Alfred Wilson</h1>
//               <p>
//                 Can I call you back later? I'm in a hurry to
//                 communicate
//               </p>
//             </div>
//             <h2>10:26</h2>
//           </div>
//         </li>

//         <li className="active" style={{marginBottom:"10px"}}>
//           <div className="chat_listItem">
//             <div className="chatListAvatar">
//               <img src={chatpp} alt="" />
//             </div>
//             <div className="chatListTextcontent">
//               <h1>Alfred Wilson</h1>
//               <p>
//                 Can I call you back later? I'm in a hurry to
//                 communicate
//               </p>
//             </div>
//             <h2>10:26</h2>
//           </div>
//         </li>

//         <li style={{marginBottom:"10px"}}>
//           <div className="chat_listItem">
//             <div className="chatListAvatar">
//               <img src={chatpp} alt="" />
//             </div>
//             <div className="chatListTextcontent">
//               <h1>Alfred Wilson</h1>
//               <p>
//                 Can I call you back later? I'm in a hurry to
//                 communicate
//               </p>
//             </div>
//             <h2>10:26</h2>
//           </div>
//         </li>

//         <li style={{marginBottom:"10px"}}>
//           <div className="chat_listItem">
//             <div className="chatListAvatar">
//               <img src={chatpp} alt="" />
//             </div>
//             <div className="chatListTextcontent">
//               <h1>Alfred Wilson</h1>
//               <p>
//                 Can I call you back later? I'm in a hurry to
//                 communicate
//               </p>
//             </div>
//             <h2>10:26</h2>
//           </div>
//         </li>

//         <li style={{marginBottom:"10px"}}>
//           <div className="chat_listItem">
//             <div className="chatListAvatar">
//               <img src={chatpp} alt="" />
//             </div>
//             <div className="chatListTextcontent">
//               <h1>Alfred Wilson</h1>
//               <p>
//                 Can I call you back later? I'm in a hurry to
//                 communicate
//               </p>
//             </div>
//             <h2>10:26</h2>
//           </div>
//         </li>

//         <li style={{marginBottom:"10px"}}>
//           <div className="chat_listItem">
//             <div className="chatListAvatar">
//               <img src={chatpp} alt="" />
//             </div>
//             <div className="chatListTextcontent">
//               <h1>Alfred Wilson</h1>
//               <p>
//                 Can I call you back later? I'm in a hurry to
//                 communicate
//               </p>
//             </div>
//             <h2>10:26</h2>
//           </div>
//         </li>

//         <li style={{marginBottom:"10px"}}>
//           <div className="chat_listItem">
//             <div className="chatListAvatar">
//               <img src={chatpp} alt="" />
//             </div>
//             <div className="chatListTextcontent">
//               <h1>Alfred Wilson</h1>
//               <p>
//                 Can I call you back later? I'm in a hurry to
//                 communicate
//               </p>
//             </div>
//             <h2>10:26</h2>
//           </div>
//         </li>

//         <li style={{marginBottom:"10px"}}>
//           <div className="chat_listItem">
//             <div className="chatListAvatar">
//               <img src={chatpp} alt="" />
//             </div>
//             <div className="chatListTextcontent">
//               <h1>Alfred Wilson</h1>
//               <p>
//                 Can I call you back later? I'm in a hurry to
//                 communicate
//               </p>
//             </div>
//             <h2>10:26</h2>
//           </div>
//         </li>

//         <li style={{marginBottom:"10px"}}>
//           <div className="chat_listItem">
//             <div className="chatListAvatar">
//               <img src={chatpp} alt="" />
//             </div>
//             <div className="chatListTextcontent">
//               <h1>Alfred Wilson</h1>
//               <p>
//                 Can I call you back later? I'm in a hurry to
//                 communicate
//               </p>
//             </div>
//             <h2>10:26</h2>
//           </div>
//         </li>

//         <li style={{marginBottom:"10px"}}>
//           <div className="chat_listItem">
//             <div className="chatListAvatar">
//               <img src={chatpp} alt="" />
//             </div>
//             <div className="chatListTextcontent">
//               <h1>Alfred Wilson</h1>
//               <p>
//                 Can I call you back later? I'm in a hurry to
//                 communicate
//               </p>
//             </div>
//             <h2>10:26</h2>
//           </div>
//         </li>
//       </ul>
//     </div>

//     <div className="chat_createGroupBTN">
//     <Link to="/create_grpup">Create Group</Link>
// {/* <button>Create Group</button> */}
//     </div>
//   </div>
//   )
// }

// export default ChatSidebar

import React from "react";
import { Link } from "react-router-dom";
import chatpp from "../../assets/images/chatListAvatar01.png";

function ChatSidebar() {
  return (
    <>
      <div>
        <div className="chat_sidebarWrapper">
          <div className="chat_tabList">
            <ul className="scrollbar thin-scroll">
              <div class="force-overflow"></div>
              <li style={{ marginBottom: "5px" }}>
                <div className="d-flex justify-content-between">
                  <div className="chat_listItem">
                    <div className="chatListAvatar">
                      <img src={chatpp} alt="" />
                    </div>
                    <div className="chatListTextcontent">
                      <h1>Alfred Wilson</h1>
                      <p>
                        Can I call you back later? I'm in a hurry to communicate
                      </p>
                    </div>

                    {/* <h2 className="time1">10:26</h2> */}
                  </div>
                  <div>
                    <h2 className="time1">10:26</h2>
                  </div>
                </div>
                {/* <div className="chat_listItem">
                  <div className="chatListAvatar">
                    <img src={chatpp} alt="" />
                  </div>
                  <div className="chatListTextcontent">
                    <h1>Alfred Wilson</h1>
                    <p>
                      Can I call you back later? I'm in a hurry to communicate
                    </p>
                  </div>

                  <h2 className="time1">10:26</h2>
                </div> */}
              </li>

              <li className="active" style={{ marginBottom: "5px" }}>
                {/* <div className="chat_listItem">
                  <div className="chatListAvatar">
                    <img src={chatpp} alt="" />
                  </div>
                  <div className="chatListTextcontent">
                    <h1>Alfred Wilson</h1>
                    <p>
                      Can I call you back later? I'm in a hurry to communicate
                    </p>
                  </div>
                  <h2>10:26</h2>
                </div> */}
                <div className="d-flex justify-content-between">
                  <div className="chat_listItem">
                    <div className="chatListAvatar">
                      <img src={chatpp} alt="" />
                    </div>
                    <div className="chatListTextcontent">
                      <h1>Alfred Wilson</h1>
                      <p>
                        Can I call you back later? I'm in a hurry to communicate
                      </p>
                    </div>

                    {/* <h2 className="time1">10:26</h2> */}
                  </div>
                  <div>
                    <h2 className="time1">10:26</h2>
                  </div>
                </div>
              </li>

              <li style={{ marginBottom: "5px" }}>
                {/* <div className="chat_listItem">
                  <div className="chatListAvatar">
                    <img src={chatpp} alt="" />
                  </div>
                  <div className="chatListTextcontent">
                    <h1>Alfred Wilson</h1>
                    <p>
                      Can I call you back later? I'm in a hurry to communicate
                    </p>
                  </div>
                  <h2>10:26</h2>
                </div> */}
                <div className="d-flex justify-content-between">
                  <div className="chat_listItem">
                    <div className="chatListAvatar">
                      <img src={chatpp} alt="" />
                    </div>
                    <div className="chatListTextcontent">
                      <h1>Alfred Wilson</h1>
                      <p>
                        Can I call you back later? I'm in a hurry to communicate
                      </p>
                    </div>

                    {/* <h2 className="time1">10:26</h2> */}
                  </div>
                  <div>
                    <h2 className="time1">10:26</h2>
                  </div>
                </div>
              </li>

              <li style={{ marginBottom: "5px" }}>
                {/* <div className="chat_listItem">
                  <div className="chatListAvatar">
                    <img src={chatpp} alt="" />
                  </div>
                  <div className="chatListTextcontent">
                    <h1>Alfred Wilson</h1>
                    <p>
                      Can I call you back later? I'm in a hurry to communicate
                    </p>
                  </div>
                  <h2>10:26</h2>
                </div> */}
                <div className="d-flex justify-content-between">
                  <div className="chat_listItem">
                    <div className="chatListAvatar">
                      <img src={chatpp} alt="" />
                    </div>
                    <div className="chatListTextcontent">
                      <h1>Alfred Wilson</h1>
                      <p>
                        Can I call you back later? I'm in a hurry to communicate
                      </p>
                    </div>

                    {/* <h2 className="time1">10:26</h2> */}
                  </div>
                  <div>
                    <h2 className="time1">10:26</h2>
                  </div>
                </div>
              </li>

              <li style={{ marginBottom: "5px" }}>
                {/* <div className="chat_listItem">
                  <div className="chatListAvatar">
                    <img src={chatpp} alt="" />
                  </div>
                  <div className="chatListTextcontent">
                    <h1>Alfred Wilson</h1>
                    <p>
                      Can I call you back later? I'm in a hurry to communicate
                    </p>
                  </div>
                  <h2>10:26</h2>
                </div> */}
                <div className="d-flex justify-content-between">
                  <div className="chat_listItem">
                    <div className="chatListAvatar">
                      <img src={chatpp} alt="" />
                    </div>
                    <div className="chatListTextcontent">
                      <h1>Alfred Wilson</h1>
                      <p>
                        Can I call you back later? I'm in a hurry to communicate
                      </p>
                    </div>

                    {/* <h2 className="time1">10:26</h2> */}
                  </div>
                  <div>
                    <h2 className="time1">10:26</h2>
                  </div>
                </div>
              </li>

              <li style={{ marginBottom: "5px" }}>
                {/* <div className="chat_listItem">
                  <div className="chatListAvatar">
                    <img src={chatpp} alt="" />
                  </div>
                  <div className="chatListTextcontent">
                    <h1>Alfred Wilson</h1>
                    <p>
                      Can I call you back later? I'm in a hurry to communicate
                    </p>
                  </div>
                  <h2>10:26</h2>
                </div> */}
                <div className="d-flex justify-content-between">
                  <div className="chat_listItem">
                    <div className="chatListAvatar">
                      <img src={chatpp} alt="" />
                    </div>
                    <div className="chatListTextcontent">
                      <h1>Alfred Wilson</h1>
                      <p>
                        Can I call you back later? I'm in a hurry to communicate
                      </p>
                    </div>

                    {/* <h2 className="time1">10:26</h2> */}
                  </div>
                  <div>
                    <h2 className="time1">10:26</h2>
                  </div>
                </div>
              </li>

              <li style={{ marginBottom: "5px" }}>
                {/* <div className="chat_listItem">
                  <div className="chatListAvatar">
                    <img src={chatpp} alt="" />
                  </div>
                  <div className="chatListTextcontent">
                    <h1>Alfred Wilson</h1>
                    <p>
                      Can I call you back later? I'm in a hurry to communicate
                    </p>
                  </div>
                  <h2>10:26</h2>
                </div> */}
                <div className="d-flex justify-content-between">
                  <div className="chat_listItem">
                    <div className="chatListAvatar">
                      <img src={chatpp} alt="" />
                    </div>
                    <div className="chatListTextcontent">
                      <h1>Alfred Wilson</h1>
                      <p>
                        Can I call you back later? I'm in a hurry to communicate
                      </p>
                    </div>

                    {/* <h2 className="time1">10:26</h2> */}
                  </div>
                  <div>
                    <h2 className="time1">10:26</h2>
                  </div>
                </div>
              </li>

              <li style={{ marginBottom: "5px" }}>
                {/* <div className="chat_listItem">
                  <div className="chatListAvatar">
                    <img src={chatpp} alt="" />
                  </div>
                  <div className="chatListTextcontent">
                    <h1>Alfred Wilson</h1>
                    <p>
                      Can I call you back later? I'm in a hurry to communicate
                    </p>
                  </div>
                  <h2>10:26</h2>
                </div> */}
                <div className="d-flex justify-content-between">
                  <div className="chat_listItem">
                    <div className="chatListAvatar">
                      <img src={chatpp} alt="" />
                    </div>
                    <div className="chatListTextcontent">
                      <h1>Alfred Wilson</h1>
                      <p>
                        Can I call you back later? I'm in a hurry to communicate
                      </p>
                    </div>

                    {/* <h2 className="time1">10:26</h2> */}
                  </div>
                  <div>
                    <h2 className="time1">10:26</h2>
                  </div>
                </div>
              </li>

              <li style={{ marginBottom: "5px" }}>
                {/* <div className="chat_listItem">
                  <div className="chatListAvatar">
                    <img src={chatpp} alt="" />
                  </div>
                  <div className="chatListTextcontent">
                    <h1>Alfred Wilson</h1>
                    <p>
                      Can I call you back later? I'm in a hurry to communicate
                    </p>
                  </div>
                  <h2>10:26</h2>
                </div> */}
                <div className="d-flex justify-content-between">
                  <div className="chat_listItem">
                    <div className="chatListAvatar">
                      <img src={chatpp} alt="" />
                    </div>
                    <div className="chatListTextcontent">
                      <h1>Alfred Wilson</h1>
                      <p>
                        Can I call you back later? I'm in a hurry to communicate
                      </p>
                    </div>

                    {/* <h2 className="time1">10:26</h2> */}
                  </div>
                  <div>
                    <h2 className="time1">10:26</h2>
                  </div>
                </div>
              </li>

              <li style={{ marginBottom: "5px" }}>
                {/* <div className="chat_listItem">
                  <div className="chatListAvatar">
                    <img src={chatpp} alt="" />
                  </div>
                  <div className="chatListTextcontent">
                    <h1>Alfred Wilson</h1>
                    <p>
                      Can I call you back later? I'm in a hurry to communicate
                    </p>
                  </div>
                  <h2>10:26</h2>
                </div> */}
                <div className="d-flex justify-content-between">
                  <div className="chat_listItem">
                    <div className="chatListAvatar">
                      <img src={chatpp} alt="" />
                    </div>
                    <div className="chatListTextcontent">
                      <h1>Alfred Wilson</h1>
                      <p className="side-bar-p-1">
                        Can I call you back later? I'm in a hurry to communicate
                      </p>
                    </div>

                    {/* <h2 className="time1">10:26</h2> */}
                  </div>
                  <div>
                    <h2 className="time1">10:26</h2>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatSidebar;

import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/AppContext";
import { ShareSocial } from "react-share-social";
import { Modal, Button } from "react-bootstrap";
import {
  deleteWithAuthCall,
  postMultipartWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
} from "../../api/ApiServices";
import swal from "sweetalert";

import eventthumbImg01 from "../../assets/images/house01.png";
import LoaderCenter from "../../sharedComponent/LoaderCenter";
import './AllPropertyEvent.css'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faUsers,
  faBed,
  faBath,
  faMoneyBill,
  faDollarSign,
  faUser,
  faClock,
  faTrashAlt,
  faHeart
} from "@fortawesome/free-solid-svg-icons";

import CardView from "./CardView";

const AllEvent = () => {
  const { keyword } = useContext(AppContext);
  const [dismiss, setDismiss] = useState(false);
  const [ShareData, setShare] = useState("");

  const filterModal = useRef(null);

  const { customerData } = useContext(AppContext);
  const [propertyfilterType, setPropertyFilterType] = useState({
    title: "",
    propertyType: "select",
    gender: "select",
    term: "select",
    availability: "select",
    billsIncluded: "No",
    smokedAllowed: "No",
    minimum: "",
    maximum: "",
  });
  
  const [loading, setLoading] = useState(false);
  const [HouseingList, setHouseingList] = useState([]);
  const [selectedID, setselectedID] = useState("");

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const navigate = useNavigate();

  useEffect(() => {
    getHouselist();
  }, []);

  useEffect(() => {
    getHouselist();
  }, [keyword]);

  const getHouselist = async () => {
    setLoading(true);
    try {
      const response = await fetch(ApiConfig.UNIVERSR_EVENT_ALL_LIST);
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      setLoading(false);
      if (keyword.length > 0) {
        getSearchedProducts(data.posts);
      } else {
        setHouseingList(data.posts);
      }
    } catch (error) {
      setLoading(false);
      console.error('There was a problem fetching the data:', error);
    }
  };

  const getSearchedProducts = (data) => {
    const matches = data.filter((product) =>
      product?.title.toLowerCase().includes(keyword.toLowerCase())
    );
    setHouseingList(matches);
  };

  const getFilteredlist = async () => {
    setLoading(true);
    try {
      const response = await fetch(ApiConfig.UNIVERSR_HOUSING_ALL_PROPTIE_LIST);
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      setLoading(false);
      getFilteredProducts(data.posts);
    } catch (error) {
      setLoading(false);
      console.error('There was a problem fetching the data:', error);
    }
  };

  const filters = {
    propertyType: 'house',
    availability: 'Immediately',
    smokedAllowed: true,
    term: '',
    billsIncluded: '',
  };

  const getFilteredProducts = (data) => {
    let matches = data;

    if (filters.propertyType) {
      matches = matches.filter(product =>
        product?.housingDetails?.propertyType === filters.propertyType
      );
    }

    if (filters.availability) {
      matches = matches.filter(product =>
        product?.housingDetails?.availability === filters.availability
      );
    }

    if (filters.term) {
      matches = matches.filter(product =>
        product?.housingDetails?.term === filters.term
      );
    }

    if (filters.smokedAllowed !== undefined) {
      matches = matches.filter(product =>
        product?.housingDetails?.smokedAllowed === filters.smokedAllowed
      );
    }

    if (filters.billsIncluded !== undefined) {
      matches = matches.filter(product =>
        product?.housingDetails?.billsIncluded === filters.billsIncluded
      );
    }

    setHouseingList(matches);
  };

  const Delete = (id) => {
    deleteWithAuthCall(ApiConfig.UNIVERSR_HOUSING_MANGE_DELET + id)
      .then((res) => {
        swal(res.detail);
        getHouselist();
        navigate("/house_listing/all");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const AddIntersted = (id) => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.UNIVERSR_ADD_USER_INTERSTED,
      JSON.stringify({ housing_property_id: id })
    )
      .then((res) => {
        swal(res?.json);
        getHouselist();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const HousingId = (id) => {
    setselectedID(id);
  };

  const ShareHandal = async () => {
    const requestBody = JSON.stringify({
      dynamicLinkInfo: {
        domainUriPrefix: "https://myuniverse.page.link",
        link: `https://my-univrs.app.redbytes.in/housing-details/${selectedID}/?user=${customerData.id}`,
        androidInfo: { androidPackageName: "com.my_universe" },
        iosInfo: { iosBundleId: "org.myUniverse" },
      },
    });

    try {
      const response = await fetch(
        "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBmt92Aby2xlZWOCIHeTutj8ywpF-4reyw",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: requestBody,
        }
      );

      const data = await response.json();
      setShare(data.shortLink);
    } catch (err) {
      console.log("error", err);
    }
  };

  return (
    <>
      <div className="row g-4">
      {/* <div>
          <button onClick={handleShowModal}>Filter</button>
        </div> */}
        {loading ? (
          <LoaderCenter />
        ) : HouseingList && HouseingList.length > 0 ? (
          <div style={{ width: "1120px", padding: "20px", margin: "0 auto" }}>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "25px" }}>
              {HouseingList.map((house, index) => (
                <CardView key={index} house={house} index={index} />
              ))}
            </div>
          </div>
        ) : (
          <p
            style={{
              fontSize: "20px",
              marginTop: "200px",
            }}
            className="text-center justify-content-center align-items-center"
          >
            Oops! It doesn't look like there are any properties in your area.
            <br /> Why don't you try expanding your search criteria?
          </p>
        )}
         {/* filter modal */}
         <Modal
          show={showModal}
          onHide={handleCloseModal}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Filter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              {/* <div className="mb-3">
                <label htmlFor="title" className="form-label">
                  Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  value={propertyfilterType.title}
                  onChange={(e) =>
                    setPropertyFilterType({
                      ...propertyfilterType,
                      title: e.target.value,
                    })
                  }
                />
              </div> */}
              <div className="mb-3">
                <label htmlFor="propertyType" className="form-label">
                  Property Type
                </label>
                <select
                  className="form-control"
                  id="propertyType"
                  value={propertyfilterType.propertyType}
                  onChange={(e) =>
                    setPropertyFilterType({
                      ...propertyfilterType,
                      propertyType: e.target.value,
                    })
                  }
                >
                  <option value="select">Select</option>
                  <option value="house">House</option>
                                <option value="studio">Studio</option>
                                <option value="flat">Flat</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="gender" className="form-label">
                  Gender
                </label>
                <select
                  className="form-control"
                  id="gender"
                  value={propertyfilterType.gender}
                  onChange={(e) =>
                    setPropertyFilterType({
                      ...propertyfilterType,
                      gender: e.target.value,
                    })
                  }
                >
                  <option value="select">Select</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="any">Any</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="term" className="form-label">
                  Term
                </label>
                <select
                  className="form-control"
                  id="term"
                  value={propertyfilterType.term}
                  onChange={(e) =>
                    setPropertyFilterType({
                      ...propertyfilterType,
                      term: e.target.value,
                    })
                  }
                >
                  <option value="select">Select</option>
                  <option value="short">Short</option>
                  <option value="long">Long</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="availability" className="form-label">
                  Availability
                </label>
                <select
                  className="form-control"
                  id="availability"
                  value={propertyfilterType.availability}
                  onChange={(e) =>
                    setPropertyFilterType({
                      ...propertyfilterType,
                      availability: e.target.value,
                    })
                  }
                >
                    <option value="select">
                                    Availability
                                    </option>
                                    <option value="Immediately">Immediately</option>
                                    <option value="Within 1 week">Within 1 week</option>
                                    <option value="Within 2 weeks">Within 2 weeks</option>
                </select>
              </div>
              <div className="mb-3">
                <label className="form-check-label" htmlFor="billsIncluded">
                  Bills Included
                </label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="billsIncluded"
                  checked={propertyfilterType.billsIncluded}
                  onChange={(e) =>
                    setPropertyFilterType({
                      ...propertyfilterType,
                      billsIncluded: e.target.checked,
                    })
                  }
                />
              </div>
              <div className="mb-3">
                <label className="form-check-label" htmlFor="smokedAllowed">
                  Smoked Allowed
                </label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="smokedAllowed"
                  checked={propertyfilterType.smokedAllowed}
                  onChange={(e) =>
                    setPropertyFilterType({
                      ...propertyfilterType,
                      smokedAllowed: e.target.checked,
                    })
                  }
                />
              </div>
              <div className="mb-3">
                <label htmlFor="minimum" className="form-label">
                  Minimum Price
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="minimum"
                  value={propertyfilterType.minimum}
                  onChange={(e) =>
                    setPropertyFilterType({
                      ...propertyfilterType,
                      minimum: e.target.value,
                    })
                  }
                />
              </div>
              <div className="mb-3">
                <label htmlFor="maximum" className="form-label">
                  Maximum Price
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="maximum"
                  value={propertyfilterType.maximum}
                  onChange={(e) =>
                    setPropertyFilterType({
                      ...propertyfilterType,
                      maximum: e.target.value,
                    })
                  }
                />
              </div>
              {/* <Button variant="primary" onClick={propertyFilter}>
                Apply Filters
              </Button> */}
            </form>
          </Modal.Body>
          <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={getFilteredlist}>
            Apply Filters
          </Button>
        </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default AllEvent;


// import React, { useContext, useState, useEffect } from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import ApiConfig from "../../api/ApiConfig";
// import {
//   simpleGetCallWithErrorResponse,
//   simplePostCall,
// } from "../../api/ApiServices";
// import eventthumbImg01 from "../../assets/images/eventThumb01.png";
// import LoaderCenter from "../../sharedComponent/LoaderCenter";
// import { AppContext } from "../../context/AppContext";
// import swal from "sweetalert";
// import { Modal, Button } from 'react-bootstrap'
// import { CopyToClipboard } from 'react-copy-to-clipboard'
// import { ShareSocial } from 'react-share-social'
// import { Slider } from "../../components/Slider";

// const style = {
//   background: '#F5F5F5',
//   borderRadius: 3,
//   border: 0,
//   color: 'white',
//   padding: '0 0px',
//   // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
//   height: '200px',
//   marginTop: '20px',
//   // width: '20px',
// }
// const AllEvent = () => {
//   const { customerData } = useContext(AppContext);
//   const [showModal, setShow] = useState(false)
//   const [ShareData, setShare] = useState('')
//   const [selectedID, setselectedID] = useState("") 

//   const [loading, setLoading] = useState(false);
//   const [EventList, setEventList] = useState([]);
//   const [EventDetalis, setEventDetalis] = useState([]);
//   const handleClose = () => setShow(false)
//   const handleShow = () => {
    
//     setShow(true)
//     ShareHandal()
//   }
//   useEffect(() => {
//     getEventlist();
//   }, [EventDetalis]);

//   const getEventlist = () => {
//     setLoading(true);

//     simpleGetCallWithErrorResponse(
//       ApiConfig.UNIVERSR_EVENT_ALL_LIST,
//       JSON.stringify({ ...customerData })
//     ).then((res) => {
//       console.log(res);
//       setLoading(false);
//       setEventList(res.json.list);
//     });
//   };

//   const buyEvent = (id) => {
//     simplePostCall(
//       ApiConfig.EVENTS_IM_INTERESTED,
//       JSON.stringify({ event_id: id })
//     )
//       .then((res) => {
//         console.log(res);
//         setEventDetalis(res);
//         swal(res.message);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };

//   const EventSave = (id) => {
//     simplePostCall(
//       ApiConfig.UNIVERSR_EVENT_ALL_LIST_SAVE,
//       JSON.stringify({ event_id: id })
//     )
//       .then((res) => {
//         console.log(res);

//         swal(res.message);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };



//   const handalId = (id) => {
//     setselectedID(id)
//   }

//   const ShareHandal = async () => {
//     let requestBody = JSON.stringify({
//       dynamicLinkInfo: {
//         domainUriPrefix: 'https://myuniverse.page.link',

//         link:
//         'https://my-univrs.app.redbytes.in/event_details/' +
//         String(selectedID) +
//         '/?user=' +
//         String(customerData.id),

//         androidInfo: {
//           androidPackageName: 'com.my_universe',
//         },

//         iosInfo: {
//           iosBundleId: 'org.myUniverse',
//         },
//       },
//     })

//     await fetch(
//       'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBmt92Aby2xlZWOCIHeTutj8ywpF-4reyw',

//       {
//         method: 'POST',

//         headers: {
//           Accept: 'application/json',

//           'Content-Type': 'application/json',
//         },

//         body: requestBody,
//       },
//     )
//       .then((response) => response.json())

//       .then((data) => {
//         console.log('response', data)
//         setShare(data.shortLink)
//       })

//       .catch((err) => {
//         // this.setState({isVisible:false})

//         console.log('error', err)
//       })
//   }



//   return (
//     <>
//       {loading ? (
//         <LoaderCenter />
//       ) : (
//         <>
//          <Modal
//               className="custom-dialog"
//               aria-labelledby="contained-modal-title-vcenter"
//               centered
//               show={showModal}
//               onHide={handleClose}
//             >
//               <Modal.Header
//                 style={{ padding: '12px', width: '99%' }}
//                 closeButton
//               >
//                 <Modal.Title></Modal.Title>
//               </Modal.Header>
//               <Modal.Body>
//               {/* Are you sure you want to cancel this Friend Request? */}
//               <div class="modal-body" style={{ marginTop: '-37px' }}>
//                 <ShareSocial
//                   style={style}
//                   url={ShareData}
//                   socialTypes={[
//                     'facebook',
//                     'twitter',
//                     'reddit',
//                     'linkedin',
//                     'email',
//                     'pinterest',
//                   ]}
//                 />
//                 <CopyToClipboard
//                     text={ShareData}
//                     onCopy={() => swal('Copied to Clipboard')}
//                   >
//                     <Link to="#" className='copy-icon'>
//                       <i class="fa-solid fa-copy"></i>
//                     </Link>
//                   </CopyToClipboard>
//               </div>
//             </Modal.Body>
//             </Modal>

           

//         <div className="row no-scroll row-gap-20">
//           {EventList && EventList.length > 0 ? (
//             EventList.map((EventList, index) => {
     
//               return (
//                 <div className="col-12 col-lg-6  col-xl-4 col-xxl-3">
//                   <div className="gbl_thumbWrapper">
//                     <div className="img_thumbWrapper">
//                       <div class="dropdown">
//                         <button
//                           onClick={() => {
//                             handalId(EventList.id)
//                           }}
//                           class="eventMenu"
//                           type="button"
//                           id="dropdownMenuButton1"
//                           data-bs-toggle="dropdown"
//                           aria-expanded="false"
//                         >
//                           <i class="fa-solid fa-ellipsis-vertical"></i>
//                         </button>
//                         <ul
//                           class="dropdown-menu career-drop"
//                           aria-labelledby="dropdownMenuButton1"
//                           style={{
//                             backgroundColor: "#F5F5F5",
//                             border: "1px solid #ECECEC",
//                             position: "absolute",
//                             top: "10px",
//                             right: "10px",
//                           }}
//                         >
//                           <li>
//                             <a
//                               onClick={() => {
//                                 EventSave(EventList.id);
//                               }}
//                               class="dropdown-item"
//                               href="#"
//                             >
//                               Save
//                             </a>
//                           </li>
//                           <li>
//                           <Link
//                             onClick={handleShow}
//                             to="#"
//                             class="dropdown-item"
//                             href="#"
//                           >
//                             Share
//                           </Link>
//                         </li>
//                           {/* <li>
//                             <Link
//                               to={`/event_details/${EventList.id}`}
//                               class="dropdown-item"
//                               href="#"
//                             >
//                               Details
//                             </Link>
//                           </li> */}
//                         </ul>
//                       </div>

//                       <img
//                         src={EventList.image}
//                         alt=""
//                         className="event-thumbnail"
//                       />
//                     </div>
//                     <Link to={`/event_details/${EventList.id}`}>
//                       <div className="event-details">
//                         <h1 className="event-title">{EventList.name}</h1>
//                         <h2 className="event-price">
//                           {
//                             EventList.ticket_price==0?'Free':` $ ${EventList.ticket_price}`
//                           }
                       
//                         </h2>
//                         <h4 className="event-address content mx-0">
//                           {EventList.address}
//                         </h4>
//                         <div className="event-action-wrapper d-flex flex-column align-items-start align-items-sm-end flex-sm-row">
//                           <p className="event-time">
//                             {EventList.start_time} - {EventList.end_time}
//                           </p>
//                           <Link
//                             onClick={() => {
//                               buyEvent(EventList.id);
//                             }}
//                             to="#"
//                             className="mt-2  mt-sm-0"
//                           >
//                             {/* I`m Interested */}
//                             {EventList.i_am_interested === "not applied"
//                               ? "I'm Interested"
//                               : "Interested"}
//                           </Link>
//                         </div>
//                       </div>
//                     </Link>
//                   </div>
//                 </div>
//               );
//             })
//           ) : (
//            <p
//             style={{
//               fontSize: "20px",
//               marginTop: "200px",
              
//             }}
//             className=" text-center justify-content-center align-items-center"
//           >
//           Oops! It doesn't look like there are any Event in your area.<br/> Why don't you try expanding your search criteria?
//           </p>
//           )}
//         </div>
//         </>
//       )}
//     </>
//   );
// };

// export default AllEvent;

// import React, { useContext, useState, useEffect, useRef } from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import ApiConfig from "../../api/ApiConfig";
// import { AppContext } from "../../context/AppContext";
// import { ShareSocial } from "react-share-social";
// import { Modal, Button } from "react-bootstrap";
// import {
//   deleteWithAuthCall,
//   postMultipartWithAuthCallWithErrorResponse,
//   postWithAuthCallWithErrorResponse,
//   simpleGetCallWithErrorResponse,
//   simplePostCall,
//   simpleGetCall,
// } from "../../api/ApiServices";
// import swal from "sweetalert";

// import eventthumbImg01 from "../../assets/images/house01.png";
// import LoaderCenter from "../../sharedComponent/LoaderCenter";
// import { CopyToClipboard } from "react-copy-to-clipboard";
// import { Slider } from "../../components/Slider";
// import './AllPropertyEvent.css'

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faCalendarAlt,
//   faBuilding,
//   faUsers,
//   faBed,
//   faBath,
//   faMoneyBill,
//   faDollarSign,
//   faUser,
//   faClock ,
//   faTrashAlt,
//   faHeart
// } from "@fortawesome/free-solid-svg-icons";

// import CardView from "./CardView";


// const AllEvent = () => {
//   const { keyword } = useContext(AppContext);
//   const [dismiss, setDismiss] = useState(false);
//   const [ShareData, setShare] = useState("");

//   const filterModal = useRef(null);

//   // filter api starts
//   const [errorMsg, setErrorMsg] = useState({
//     title: "",
//     property_type: "",
//     gender: "",
//     term: "",
//     availability: "",
//     minimum: "",
//     maximum: "",
//   });
//   const { customerData } = useContext(AppContext);
//   console.log("customerData", customerData.id);
//   const [propertyfilterType, setPropertyFilterType] = useState({
//     title: "",
//     propertyType: "select",
//     gender: "select",
//     term: "select",
//     availability: "select",
//     billsIncluded: "No",
//     smokedAllowed: "No",
//     minimum: "",
//     maximum: "",
//   });
//   const style = {
//     background: "#F5F5F5",
//     borderRadius: 3,
//     border: 0,
//     color: "white",
//     padding: "0 0px",
//     // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
//     height: "200px",
//     marginTop: "20px",
//     // width: '20px',
//   };
//   const [showElement, setShowElement] = React.useState(true);
//   const currentRoute = useLocation().pathname;
//   const [loading, setLoading] = useState(false);
//   //const [HouseingList, setHouseingList] = useState([]);
//   const [HouseingList, setHouseingList] = useState([]);
//   const [HouseingAdsList, setHouseingAdsList] = useState([])
//   const [houseingListadd, setHouseingAdd] = useState([]);
//   const [HouseingMange, setHouseingMange] = useState([]);
//   const [selectedID, setselectedID] = useState("");

//   // const [showModal, setShow] = useState(false);
//   const [showModal, setShowModal] = useState(false);
//     const handleCloseModal = () => setShowModal(false);
//   const handleShowModal = () => setShowModal(true);
//   const handleClose = () => setShow(false);
//   // const handleShow = () => {
//   //   setShow(true);
//   //   ShareHandal();
//   // };
//   const navigate = useNavigate();

//   useEffect(() => {
//     getHouselist();
//   }, []);
//   useEffect(() => {
//     getHouselist();
//   }, [keyword]);
  

//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     // Options for formatting the date as you wish
//     const options = {
//       year: 'numeric',
//       month: 'long',
//       day: 'numeric',
//       hour: 'numeric',
//       minute: 'numeric',
//       second: 'numeric',
//       hour12: true, // Whether to use 12-hour time (true) or 24-hour time (false)
//     };
//     return date.toLocaleString('en-US', options);
//   };

// //   const getHouselist =async() => {
// //     setLoading(true);

// //    await fetch(ApiConfig.UNIVERSR_EVENT_ALL_LIST)
// //       .then(response => {
// //         if (!response.ok) {
// //           throw new Error('Network response was not ok');
// //         }
// //         return response.json();
// //       })
// //       .then(data => {
// //         setLoading(false);
// //        console.log("housing_properties", data.posts);
// //         // if (keyword.length > 0) {
// //         //   getSearchedProducts(data.housing_properties);
// //         // } else {
// //           setHouseingList(data.posts);
// //           console.log("res housing", HouseingList);
// //          // setHouseingAdsList(data.housing_properties.filter(e => e.featured_ads == true));
// //          //}
// //       })
// //       .catch(error => {
// //         setLoading(false);
// //         console.error('There was a problem fetching the data:', error);
// //       });
// // };

// const getHouselist = async () => {
//   setLoading(true);

//   await fetch(ApiConfig.UNIVERSR_EVENT_ALL_LIST)
//     .then(response => {
//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }
//       return response.json();
//     })
//     .then(data => {
//       setLoading(false);
//       if (keyword.length > 0) {
//         getSearchedProducts(data.posts);
//       } else {
//         setHouseingList(data.posts);
//         // setHouseingAdsList(data.posts.filter(e => e.featured_ads === true));
//       }
//     })
//     .catch(error => {
//       setLoading(false);
//       console.error('There was a problem fetching the data:', error);
//     });
// };

// const getSearchedProducts = (data) => {
//   let matches = [];
//   matches = data.filter((product) => {
//     return product && product.title.toLowerCase().includes(keyword.toLowerCase());
//   });
//   setHouseingList(matches);
// };

// const getFilteredlist = async () => {
//   setLoading(true);

//   await fetch(ApiConfig.UNIVERSR_HOUSING_ALL_PROPTIE_LIST)
//     .then(response => {
//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }
//       return response.json();
//     })
//     .then(data => { 
//       setLoading(false);

//         getFilteredProducts(data.posts);

//       //   setHouseingList(data.posts);
//       // }
//     })
//     .catch(error => {
//       setLoading(false);
//       console.error('There was a problem fetching the data:', error);
//     });
// };


// const filters = {
//   propertyType: 'house',
//   availability:'Immediately',
//   smokedAllowed:true,
//   term:'',
//   billsIncluded:'',

//   // Add more filters as needed
// };

// const getFilteredProducts = (data) => {
//   let matches = [...data]; // Copying the original data array

//   if (filters.propertyType) {
//     matches = matches.filter(product => {
//       return product && product.housingDetails && product.housingDetails.propertyType === filters.propertyType;
//     });
//   }

//   if (filters.availability) {
//     matches = matches.filter(product => {
//       return product && product.housingDetails && product.housingDetails.availability === filters.availability;
//     });
//   }

//   if (filters.term) {
//     matches = matches.filter(product => {
//       return product && product.housingDetails && product.housingDetails.term === filters.term;
//     });
//   }

//   if (filters.smokedAllowed !== undefined) {
//     matches = matches.filter(product => {
//       return product && product.housingDetails && product.housingDetails.smokedAllowed === filters.smokedAllowed;
//     });
//   }

//   if (filters.billsIncluded !== undefined) {
//     matches = matches.filter(product => {
//       return product && product.housingDetails && product.housingDetails.billsIncluded === filters.billsIncluded;
//     });
//   }



//   // if (filters.propertyType) {
//   //   matches = matches.filter(product => {
//   //     return product && product.housingDetails.propertyType === filters.propertyType;
//   //   });
//   // }

//   // if (filters.availability) {
//   //   matches = matches.filter(product => {
//   //     return product  && product.housingDetails.availability === filters.availability;
//   //   });
//   // }
//   // Add more filters as needed, for example:
//   // if (filters.minPrice) {
//   //   matches = matches.filter(product => product.price >= filters.minPrice);
//   // }

//   setHouseingList(matches);
// };



//   const Delete = (id) => {
//     deleteWithAuthCall(ApiConfig.UNIVERSR_HOUSING_MANGE_DELET + id)
//       .then((res) => {
//         console.log(res);
//         swal(res.detail);
//         getHouselist();
//         navigate("/house_listing/all");
//       })
//       .catch((err) => {
//         setLoading(false);

//         console.log(err);
//       });
//   };

//   // const getSearchedProducts = (data) => {
//   //   let matches = [];
//   //   matches = data.filter((product, index) => {
//   //     if (
//   //       product &&
//   //       product.title.toLowerCase().includes(keyword.toLowerCase())
//   //     )
//   //       return product;
//   //   });

//   //   setHouseingList(matches);
//   // };

//   const AddIntersted = (id) => {
//     postWithAuthCallWithErrorResponse(
//       ApiConfig.UNIVERSR_ADD_USER_INTERSTED,
//       JSON.stringify({ housing_property_id: id })
//     )
//       .then((res) => {
//         setHouseingAdd(res);

//         console.log(res);
//         swal(res?.json);
//         getHouselist();
//       })
//       .then((data) => {
//         swal(data?.json);
//         setHouseingAdd(data);
//       })
//       .catch((err) => {
//         setLoading(false);

//         console.log(err);
//       });
//   };
//   // ===========> search start =================

//   // ================= end ================
//   const propertyFilter = (e) => {
//     setDismiss(false);

//     let formData = new FormData();
//     formData.append("id", customerData.id);
//     formData.append("type", propertyfilterType.title);
//     formData.append("propertyType", propertyfilterType.propertyType);
//     formData.append("gender", propertyfilterType.gender);
//     formData.append("term", propertyfilterType.term);
//     formData.append("availability", propertyfilterType.availability);
//     formData.append("bills_included", propertyfilterType.billsIncluded);
//     formData.append("smokers_allowed", propertyfilterType.smokedAllowed);
//     formData.append("minimum", propertyfilterType.minimum);
//     formData.append("maximum", propertyfilterType.maximum);

//     if (!(propertyfilterType.type === "")) {
//       setErrorMsg({ title: "" });

//       if (!(propertyfilterType.property_type === "select")) {
//         setErrorMsg({ propertyType: "" });
//         if (!(propertyfilterType.gender === "select")) {
//           setErrorMsg({ gender: "" });
//           if (!(propertyfilterType.term === "select")) {
//             setErrorMsg({ term: "" });
//             if (!(propertyfilterType.availability === "select")) {
//               setErrorMsg({ availability: "" });
//               if (!(propertyfilterType.minimum === "")) {
//                 setErrorMsg({ minimum: "" });
//                 if (!(propertyfilterType.maximum === "")) {
//                   setErrorMsg({ maximum: "" });
//                   // =======> api call starts <==========
//                   setLoading(true);
//                   // filterModal.current.modal("hide");
//                   postMultipartWithAuthCallWithErrorResponse(
//                     ApiConfig.UNIVERSR_HOUSING_ALL_PROPTIE_FILTER,
//                     formData
//                   )
//                     .then((res) => {
//                       setHouseingList(res?.json?.housing_property);
//                       setHouseingMange([]);
//                       setLoading(false);
//                     })
//                     .catch((err) => console.log(err));
//                   // =======> api call end <==============
//                 } else {
//                   setErrorMsg({
//                     maximum: "Please enter  maximum price",
//                   });
//                 }
//               } else {
//                 setErrorMsg({
//                   minimum: "Please enter  mininum price",
//                 });
//               }
//             } else {
//               setErrorMsg({
//                 availability: "Please enter  availability",
//               });
//             }
//           } else {
//             setErrorMsg({
//               term: "Please enter term",
//             });
//           }
//         } else {
//           setErrorMsg({
//             gender: "Please enter  gender",
//           });
//         }
//       } else {
//         setErrorMsg({
//           propertyType: "Please enter  property type",
//         });
//       }
//     } else {
//       setErrorMsg({ type: "Please enter type" });
//     }
//   };
//   const HousingId = (id) => {
//     setselectedID(id);
//   };
//   const ShareHandal = async () => {
//     let requestBody = JSON.stringify({
//       dynamicLinkInfo: {
//         domainUriPrefix: "https://myuniverse.page.link",

//         link:
//           "https://my-univrs.app.redbytes.in/housing-details/" +
//           String(selectedID) +
//           "/?user=" +
//           String(customerData.id),

//         androidInfo: {
//           androidPackageName: "com.my_universe",
//         },

//         iosInfo: {
//           iosBundleId: "org.myUniverse",
//         },
//       },
//     });

//     await fetch(
//       "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBmt92Aby2xlZWOCIHeTutj8ywpF-4reyw",

//       {
//         method: "POST",

//         headers: {
//           Accept: "application/json",

//           "Content-Type": "application/json",
//         },

//         body: requestBody,
//       }
//     )
//       .then((response) => response.json())

//       .then((data) => {
//         console.log("response", data);
//         setShare(data.shortLink);
//       })

//       .catch((err) => {
//         // this.setState({isVisible:false})

//         console.log("error", err);
//       });
//   };

//   const [showPopup, setShowPopup] = useState(false);

//   // Function to toggle popup visibility
//   const togglePopup = () => {
//     setShowPopup(!showPopup);
//   };

//   const [clicked,setClicked]=useState(false);

//   const handleHeartClick = (event) => {
//     setClicked(!clicked)
//     event.preventDefault(); // Prevent default action
//     event.stopPropagation(); // Stop event propagation
//     // Add your logic for what happens when the heart icon is clicked
//     console.log('Heart icon clicked');
//   };



//   return (
//     <>
//       <div className="row  g-4">
//         {loading ? (
//           <LoaderCenter/>
//         ) : 
//         HouseingList && HouseingList.length > 0 ?
//          (  
//           <div style={{ width: "1120px", padding: "20px", margin: "0 auto" }}>
//           <div style={{ display: "flex", flexWrap: "wrap", gap:"25px" }}>
//             {HouseingList.map((house, index) => (
//               <CardView key={index} house={house} index={index} />
//             ))}
//           </div>
//         </div>
//         ) 
//         : (
//           <p
//             style={{
//               fontSize: "20px",
//               marginTop: "200px",
//             }}
//             className=" text-center justify-content-center align-items-center"
//           >
//             Oops! It doesn't look like there are any properties in your area.
//             <br /> Why don't you try expanding your search criteria?
//           </p>
//         )
//         }
//           {/* filter modal */}
//           <Modal
//           show={showModal}
//           onHide={handleCloseModal}
//           size="md"
//           aria-labelledby="contained-modal-title-vcenter"
//           centered
//         >
//           <Modal.Header closeButton>
//             <Modal.Title>Filter</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <form>
//               {/* <div className="mb-3">
//                 <label htmlFor="title" className="form-label">
//                   Title
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="title"
//                   value={propertyfilterType.title}
//                   onChange={(e) =>
//                     setPropertyFilterType({
//                       ...propertyfilterType,
//                       title: e.target.value,
//                     })
//                   }
//                 />
//               </div> */}
//               <div className="mb-3">
//                 <label htmlFor="propertyType" className="form-label">
//                   Property Type
//                 </label>
//                 <select
//                   className="form-control"
//                   id="propertyType"
//                   value={propertyfilterType.propertyType}
//                   onChange={(e) =>
//                     setPropertyFilterType({
//                       ...propertyfilterType,
//                       propertyType: e.target.value,
//                     })
//                   }
//                 >
//                   <option value="select">Select</option>
//                   <option value="house">House</option>
//                                 <option value="studio">Studio</option>
//                                 <option value="flat">Flat</option>
//                 </select>
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="gender" className="form-label">
//                   Gender
//                 </label>
//                 <select
//                   className="form-control"
//                   id="gender"
//                   value={propertyfilterType.gender}
//                   onChange={(e) =>
//                     setPropertyFilterType({
//                       ...propertyfilterType,
//                       gender: e.target.value,
//                     })
//                   }
//                 >
//                   <option value="select">Select</option>
//                   <option value="male">Male</option>
//                   <option value="female">Female</option>
//                   <option value="any">Any</option>
//                 </select>
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="term" className="form-label">
//                   Term
//                 </label>
//                 <select
//                   className="form-control"
//                   id="term"
//                   value={propertyfilterType.term}
//                   onChange={(e) =>
//                     setPropertyFilterType({
//                       ...propertyfilterType,
//                       term: e.target.value,
//                     })
//                   }
//                 >
//                   <option value="select">Select</option>
//                   <option value="short">Short</option>
//                   <option value="long">Long</option>
//                 </select>
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="availability" className="form-label">
//                   Availability
//                 </label>
//                 <select
//                   className="form-control"
//                   id="availability"
//                   value={propertyfilterType.availability}
//                   onChange={(e) =>
//                     setPropertyFilterType({
//                       ...propertyfilterType,
//                       availability: e.target.value,
//                     })
//                   }
//                 >
//                     <option value="select">
//                                     Availability
//                                     </option>
//                                     <option value="Immediately">Immediately</option>
//                                     <option value="Within 1 week">Within 1 week</option>
//                                     <option value="Within 2 weeks">Within 2 weeks</option>
//                 </select>
//               </div>
//               <div className="mb-3">
//                 <label className="form-check-label" htmlFor="billsIncluded">
//                   Bills Included
//                 </label>
//                 <input
//                   className="form-check-input"
//                   type="checkbox"
//                   id="billsIncluded"
//                   checked={propertyfilterType.billsIncluded}
//                   onChange={(e) =>
//                     setPropertyFilterType({
//                       ...propertyfilterType,
//                       billsIncluded: e.target.checked,
//                     })
//                   }
//                 />
//               </div>
//               <div className="mb-3">
//                 <label className="form-check-label" htmlFor="smokedAllowed">
//                   Smoked Allowed
//                 </label>
//                 <input
//                   className="form-check-input"
//                   type="checkbox"
//                   id="smokedAllowed"
//                   checked={propertyfilterType.smokedAllowed}
//                   onChange={(e) =>
//                     setPropertyFilterType({
//                       ...propertyfilterType,
//                       smokedAllowed: e.target.checked,
//                     })
//                   }
//                 />
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="minimum" className="form-label">
//                   Minimum Price
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="minimum"
//                   value={propertyfilterType.minimum}
//                   onChange={(e) =>
//                     setPropertyFilterType({
//                       ...propertyfilterType,
//                       minimum: e.target.value,
//                     })
//                   }
//                 />
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="maximum" className="form-label">
//                   Maximum Price
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="maximum"
//                   value={propertyfilterType.maximum}
//                   onChange={(e) =>
//                     setPropertyFilterType({
//                       ...propertyfilterType,
//                       maximum: e.target.value,
//                     })
//                   }
//                 />
//               </div>
//               {/* <Button variant="primary" onClick={propertyFilter}>
//                 Apply Filters
//               </Button> */}
//             </form>
//           </Modal.Body>
//           <Modal.Footer>
//           <Button variant="secondary" onClick={handleCloseModal}>
//             Close
//           </Button>
//           <Button variant="primary" onClick={getFilteredlist}>
//             Apply Filters
//           </Button>
//         </Modal.Footer>
//         </Modal>
//       </div>
//     </>
//   );
// };

// export default AllEvent;


import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";

import eventthumbImg01 from "../../assets/images/eventThumb01.png";
import eventthumbImg02 from "../../assets/images/eventThumb02.png";
import eventthumbImg03 from "../../assets/images/eventThumb03.png";
import location_icon from "../../assets/images/location_icon.svg";
import gray_location_icon from "../../assets/images/gray_location_icon.svg";

function EventPurchased() {
  const { sidebar, setSidebar } = useContext(AppContext);

  const [showElement, setShowElement] = React.useState(true);
  useEffect(() => {
    setTimeout(function () {
      setShowElement(false);
    }, 5000);
  }, []);
  return (
    <div class="sitemainBGwrapper">
      <Sidebar />

      {/* <Header /> */}
      <main
        id="cxMain"
        className={sidebar ? "cx-main ct-all-forms" : "cx-main"}
      >
        <section className="content-main">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="globalPages_tabWrapper">
                    <div className="gb_tabs">
                      <div>
                        <Link to="/house_listing">All Events</Link>{" "}
                      </div>
                      <div>
                        <Link to="/manage_event">Hosting</Link>{" "}
                      </div>

                      <div>
                        <Link to="/event_purchased" className="ct-all-forms">
                          My Events
                        </Link>{" "}
                      </div>

                      <div>
                        <Link to="/saved_events">Saved</Link>{" "}
                      </div>
                      <div>
                        <Link to="#">
                          Las Vegas <span>20 kms</span>
                        </Link>
                      </div>
                      <div>
                        <Link className="ct_active" to="/create_event">
                          Create Event
                        </Link>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="gblThumb_outerWrapper">
                <div className="row">
                  <div className="col-md-4">
                    <div className="gbl_thumbWrapper">
                      <div className="img_thumbWrapper">
                        <div class="dropdown">
                          <button
                            class="btn"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i class="fa-solid fa-ellipsis-vertical"></i>
                          </button>
                          <ul
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <Link to="#" class="dropdown-item" href="#">
                                Share
                              </Link>
                            </li>

                            <li>
                              <Link to="#" class="dropdown-item" href="#">
                                Invite
                              </Link>
                            </li>

                            <li>
                              <Link to="#" class="dropdown-item" href="#">
                                Edit
                              </Link>
                            </li>

                            <li>
                              <Link to="#" class="dropdown-item" href="#">
                                Delete
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <img src={eventthumbImg01} alt="" />
                      </div>

                      <div className="gbl_thumbContent gbl_thumbContent02">
                        <h1>Nature and Technology Event</h1>

                        <h4>
                          {" "}
                          <i>
                            <img src={gray_location_icon} alt="" />
                          </i>{" "}
                          Phoenix Market City
                        </h4>
                        <div className="gbl_thumbControlWrapper">
                          <p>
                            <i class="fa-regular fa-calendar"></i>
                            10:00 - 20:30
                          </p>
                          <Link className="eventsthumbBtn" to="#">
                            <i class="fa-solid fa-caret-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="gbl_thumbWrapper">
                      <div className="img_thumbWrapper">
                        <div class="dropdown">
                          <button
                            class="btn"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i class="fa-solid fa-ellipsis-vertical"></i>
                          </button>
                          <ul
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <Link to="#" class="dropdown-item" href="#">
                                Share
                              </Link>
                            </li>

                            <li>
                              <Link to="#" class="dropdown-item" href="#">
                                Invite
                              </Link>
                            </li>

                            <li>
                              <Link to="#" class="dropdown-item" href="#">
                                Edit
                              </Link>
                            </li>

                            <li>
                              <Link to="#" class="dropdown-item" href="#">
                                Delete
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <img src={eventthumbImg01} alt="" />
                      </div>

                      <div className="gbl_thumbContent gbl_thumbContent02">
                        <h1>Nature and Technology Event</h1>

                        <h4>
                          {" "}
                          <i>
                            <img src={gray_location_icon} alt="" />
                          </i>{" "}
                          Phoenix Market City
                        </h4>
                        <div className="gbl_thumbControlWrapper">
                          <p>
                            <i class="fa-regular fa-calendar"></i>
                            10:00 - 20:30
                          </p>
                          <Link className="eventsthumbBtn" to="#">
                            <i class="fa-solid fa-caret-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default EventPurchased;

import React, { useContext, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AppContext } from '../../context/AppContext'
import Sidebar from '../../components/Sidebar'
import Header from '../../components/Header'
import profile_picture from '../../assets/images/profile_picture.jpg'
import createselectAvatar from '../../assets/images/createselectAvatar.png'
import createadd_avatar from '../../assets/images/createadd_avatar.png'
import ImageUplode from '../../sharedComponent/ImageUplode'
import ApiConfig from '../../api/ApiConfig'
import Loader from '../../sharedComponent/Loader'
import swal from 'sweetalert'
import {
  multipartPostCallWithErrorResponse,
  postMultipartWithAuthCall,
  postMultipartWithAuthCallWithErrorResponse,
  postMultipartWithAuthCallWithErrorResponseNodeCreate,
  postWithAuthCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
  simpleGetCallWithErrorResponseNodeCreate,
} from '../../api/ApiServices'
import { getByDisplayValue } from '@testing-library/react'

function CreateForum() {
  const navigate = useNavigate()
  const { sidebar, setSidebar } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const [showElement, setShowElement] = React.useState(true)
  const [addForum, setaddForum] = useState({
    title: '',
    property_image: [],
    type: 0,
    text: '',
    other_type: ""
  })
  console.log("Forum Form", addForum);

  const [forumTypeList, setForumTypeList] = useState([]);

  const forumtypeId = forumTypeList.map((forumType) => {
    return forumType.type_id == 4;
  })
  

  useEffect(() => {
    getForumType();
    setTimeout(function () {
      setShowElement(false)
    }, 5000)
  }, [])

  // FORUM CREATION API CALL
  const createForum = (e) => {
    e.preventDefault();

    if (addForum.title == '') {
      swal('Please enter title');
      return
    }
    if (addForum.type == 0) {
      swal('Please select type');
      return
    }
    if (addForum.text == '') {
      swal('Please enter text');
      return
    }
    if (addForum.property_image.length == 0) {
      swal("Please select image");
      return;
    }
    setLoading(true)
    const formData = new FormData()
    formData.append('title', addForum.title)
    formData.append('forum_images', addForum.property_image[0])
    formData.append('type', addForum.type);
    formData.append('text', addForum.text);
    formData.append("other_type", addForum.other_type);

    postMultipartWithAuthCallWithErrorResponseNodeCreate(
      ApiConfig.CREATE_FORUM,
      formData,
    )
      .then((res) => {
        console.log('res', res)
        setLoading(false)
        swal('Forum added successfully')
        navigate('/manage_forum')
      })
      .catch((err) => console.log(err))
  }

  // IMAGE REMOVE FUNCTION
  const removeImage = (index) => {
    let property_image = addForum.property_image

    property_image = property_image.filter(
      (img) => property_image.indexOf(img) != index,
    )

    setaddForum({ ...addForum, property_image: property_image })
  }

  const getForumType = () => {
    simpleGetCallWithErrorResponseNodeCreate(ApiConfig.UNIVERSR_ALL_FORUM_TYPE)
      .then((res) => {
        console.log("Response Hai ", res.json.data)
        setForumTypeList(res.json.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <>
      <Sidebar />

      {/* <Header /> */}
      <main id="cxMain" className={sidebar ? 'cx-main cx-active' : 'cx-main'}>
        {loading ? (
          <Loader />
        ) : (
          <section
            className="content-main"
            style={{
              backgroundColor: 'white',
              borderRadius: '24px',
              padding: '20px',
              minHeight: 'calc(100vh - 156px)',
            }}
          >
            <p className="fo-heading" style={{ marginBottom: '10px' }}>
              Create Forum
            </p>
            <div className="row">
              <div className='col-md-6'>
                <label class="form-label">Title</label>
                <input
                  type="text"
                  class="form-control"
                  style={{ height: '40px' }}
                  value={addForum.title}
                  placeholder="Enter your title here"
                  onChange={(e) => {
                    setaddForum({
                      ...addForum,
                      title: e.target.value
                    })
                    // setErrMsg({ ...errMsg, title: "" });

                    // setAddProperty({
                    //   ...addForum,
                    //   title: e.target.value,
                    // })
                  }}
                />
              </div>
              <div className="col-md-6">
                <label class="form-label">Forum Sub Type</label>
                <div className="upp_selectWrapper ">
                  <select
                    onChange={(e) => {
                      setaddForum({
                        ...addForum,
                        type: Number(e.target.value)
                      })
                      // setErrMsg({ ...errMsg, title: "" });

                      // setAddProperty({
                      //   ...AddProperty,
                      //   property_type: e.target.value,
                      // })
                    }}
                    className="Property-Type-midal form-select  ce-input"
                    aria-label="Default select example"
                    style={{
                      height: '40px',
                      backgroundColor: '#FBF8F8',
                      margin: '0',
                    }}
                  >
                    <option value="">
                      Select forum Type
                    </option>
                    {forumTypeList.map((forumType) => {
                      return <option key={forumType.type_id} value={forumType.type_id}>{forumType.name}</option>;
                    })}

                  </select>

                </div>
              </div>

              {
                forumtypeId && addForum.type == 4 ?
                  <>
                    <div className="col-lg-6"></div>
                    <div className="col-md-6 mt-3 text-end ">
                      <input type="text" onChange={(e) => {
                        setaddForum({
                          ...addForum,
                          other_type : e.target.value
                        })
                      }} placeholder="Enter Type" class="form-control" />
                    </div> 
                  </> : null
              }

              <div className="col-md-12 mt-4">
                <label class="form-label">Text</label>
                <textarea
                  value={addForum.text}
                  onChange={(e) => {
                    // setErrMsg({ ...errMsg, title: "" });

                    setaddForum({
                      ...addForum,
                      text: e.target.value,
                    })
                  }}
                  type="text"
                  name="desc"
                  id="desc"
                  className="ce-input"
                  placeholder="Enter your text here"
                  maxlength="4000"
                  style={{ height: '150px', paddingTop: '20px' }}
                />
              </div>
            </div>
            <div className="row mb-4 d-flex align-items-center gy-3">
              <div className="col-12 col-sm-6 col-lg-4 col-xl-auto custom-resp-1">
                <p
                  style={{
                    color: 'black',
                    marginTop: '-15px',
                    padding: '3px',
                  }}
                >
                  Upload Image
                </p>
                <div className="up-icon">
                  {' '}
                  <ImageUplode
                    setFile={(files) => {
                      setaddForum({
                        ...addForum,
                        property_image: [...addForum.property_image, ...files],
                      })
                    }}
                  />
                </div>
              </div>

              {addForum.property_image.map((img, index) => {
                return (
                  <div className="col-12 col-sm-6 col-lg-4 col-xl-auto">
                    <div className="uploaded-image-1 uploaded-reps-1 up-img">
                      <div className="item_list" key={'img' + index}>
                        <img
                          width={500}
                          height="500"
                          className="img"
                          src={URL.createObjectURL(img)}
                          alt=""
                        />
                        <button
                          style={{
                            position: 'absolute',
                            width: '34px',
                            height: '34px',
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            border: 'none',
                            top: '10px',
                            right: '10px',
                          }}
                          href="#"
                          className=""
                          onClick={() => removeImage(index)}
                        >
                          <i class="fa-solid fa-xmark"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <div
              className="row"
              style={{
                marginTop: '60px',
              }}
            >
              <div className="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                <Link to="/forum_list">
                  <button className="cancel">Cancel</button>
                </Link>
              </div>
              <div className="col-12 col-sm-6 d-flex align-items-center justify-content-center mt-3 mt-sm-0 justify-content-sm-start  ">
                <button className="create" onClick={(e) => createForum(e)}>
                  {' '}
                  Post
                </button>
              </div>
            </div>
          </section>
        )}
      </main>
    </>
  )
}

export default CreateForum

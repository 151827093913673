import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import ApiConfig from '../../api/ApiConfig'
import {
  postWithAuthCall,
  simpleGetCallWithErrorResponse,
} from '../../api/ApiServices'
import eventthumbImg01 from '../../assets/images/saleThumb03.png'
import { AppContext } from '../../context/AppContext'
import LoaderCenter from '../../sharedComponent/LoaderCenter'
import swal from 'sweetalert'
import { ShareSocial } from 'react-share-social'

import { Modal, Button } from 'react-bootstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard'


const style = {
  background: '#F5F5F5',
  borderRadius: 3,
  border: 0,
  color: 'white',
  padding: '0 0px',
  // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  height: '200px',
  marginTop: '20px',
  // width: '20px',
}

function SavedSell() {
  const { customerData } = useContext(AppContext)
  
  const [loading, setLoading] = useState(false)
  // const [EventList, setEventList] = useState([]);
  const [saveList, setSaveList] = useState([])
  const [showModal, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  useEffect(() => {
    getSevelist()
  }, [])

  const getSevelist = () => {
    setLoading(true)

    simpleGetCallWithErrorResponse(
      ApiConfig.SAVE_ITEM_ALL,
      JSON.stringify({ ...customerData }),
    ).then((res) => {
      console.log(res)
      setLoading(false)
      setSaveList(res.json.list)
    })
  }

  const UnsaveItem = (id) => {
    postWithAuthCall(ApiConfig.UNSAVE_ITEM, JSON.stringify({ item_id: id }))
      .then((res) => {
        console.log(res)
        swal(res.message)
        getSevelist()
      })
      .catch((err) => {
        setLoading(false)

        console.log(err)
      })
  }

  return (
    <>
      {loading ? (
        <LoaderCenter />
      ) : (
        <div className="row no-scroll row-gap-20">
          <Modal
            className="custom-dialog"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showModal}
            onHide={handleClose}
          >
            <Modal.Header style={{ padding: '12px', width: '99%' }} closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* Are you sure you want to cancel this Friend Request? */}
              <div class="modal-body" style={{ marginTop: '-37px' }}>
                <ShareSocial
                  style={style}
                  url={`https://my-univrs.app.redbytes.in/sell_list/saved/`}
                  socialTypes={[
                    'facebook',
                    'twitter',
                    'reddit',
                    'linkedin',
                    'email',
                    'pinterest',
                  ]}
                />
                   <CopyToClipboard
                    text={`https://my-univrs.app.redbytes.in/sell_list/manage/`}
                    onCopy={() => swal('Copied to Clipboard')}
                  >
                    <Link to="#" className='copy-icon'>
                      <i class="fa-solid fa-copy"></i>
                    </Link>
                  </CopyToClipboard>
              </div>
            </Modal.Body>
          </Modal>
          {saveList && saveList.length > 0 ? (
            saveList.map((saveList, index) => {
              console.log(saveList)
              return (
                <div className="col-12 col-lg-6  col-xl-4 col-xxl-3">
                  <div className="gbl_thumbWrapper">
                    <div className="img_thumbWrapper">
                      <div class="dropdown">
                        <button
                          class="eventMenu"
                          type="button"
                          id="dropdownMenuButton2"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i class="fa-solid fa-ellipsis-vertical"></i>
                        </button>
                        <ul
                          class="dropdown-menu career-drop"
                          aria-labelledby="dropdownMenuButton2"
                          style={{
                            rigth: '10px',
                          }}
                        >
                          {/* <li>
                            <a
                              onClick={handleShow}
                              class="dropdown-item"
                              href="#"
                            >
                              Share
                            </a>
                          </li> */}

                          <li>
                            <a
                              onClick={() => {
                                UnsaveItem(saveList.item.id)
                              }}
                              class="dropdown-item"
                              href="#"
                            >
                              Unsavad
                            </a>
                          </li>
                        </ul>
                      </div>
                      <img
                        src={saveList.item.image}
                        alt=""
                        className="event-thumbnail"
                      />
                    </div>

                    <div className="event-details">
                      <h1 className="event-title">{saveList.item.title}</h1>
                      <h2 className="event-price" style={{ marginTop: '16px' }}>
                        £ {saveList.item.price}
                      </h2>

                      <div className="event-action-wrapper d-flex flex-column align-items-start align-items-sm-end flex-sm-row">
                        <label
                          style={{
                            color: '#707070',
                            fontSize: '14px',
                            fontWeight: 'normal',
                            marginBottom: '4px',
                          }}
                        >
                          {saveList.item.item_type}
                        </label>
                        <Link
                          to="#"
                          className="mt-2  mt-sm-0"
                          style={{
                            height: '34px',
                          }}
                        >
                          I'm Interested
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          ) : (
            <p
              style={{
                fontSize: '20px',
                marginTop: '200px',
              }}
              className=" text-center justify-content-center align-items-center"
            >
              Oops! It doesn't look like there are any Item in your area.
              <br /> Why don't you try expanding your search criteria?
            </p>
          )}
        </div>
      )}
    </>
  )
}

export default SavedSell

import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import HousingTabsHeader from "./HousingTabsHeader";
import thumbImg from "../../assets/images/house01.png";
import location_icon from "../../assets/images/location_icon.svg";
import gray_location_icon from "../../assets/images/gray_location_icon.svg";
import chatListAvatar01 from "../../assets/images/chatListAvatar01.png";
import chat_icon_sidebar from "../../assets/images/chat_icon_sidebar.svg";
import fillter_icon from "../../assets/images/fillter_icon.svg";
import search_icon from "../../assets/images/search_icon.svg";
function HouseListing() {
  const { sidebar, setSidebar, customerData, setKeyword } =
    useContext(AppContext);
  const [manage, setManage] = useState(false);
  const [showElement, setShowElement] = React.useState(true);

  const ipRef = useRef(null);
  useEffect(() => {
    setTimeout(function () {
      setShowElement(false);
    }, 5000);
  }, []);

  useEffect(() => {
    setKeyword("");
  }, []);

  const handleSearch = () => {
    // setKeyword("");
    // setKeywordh(ipRef.current.value);
    setKeyword(ipRef.current.value);
  };
  return (
    <>
      <Sidebar />

      {/* <Header /> */}
      <main
        id="cxMain"
        className={sidebar ? "cx-main cx-active" : "cx-main"}
        style={{
          height: "calc(100vh - 156px)",
        }}
      >
        <section
          className="content-main"
          style={{
            height: "calc(100vh - 156px)",
          }}
        >
          <div className="globalPages_tabWrapper">
            <div className="gb_tabs">
              <div className="tabs">
                <div>
                  <NavLink
                    onClick={() => setManage(false)}
                    to="/house_listing/all"
                    className={({ isActive }) =>
                      isActive ? "nav-active" : "nav-inactive"
                    }
                  >
                    All Properties
                  </NavLink>
                </div>
                <div>
                  <NavLink
                    onClick={() => setManage(true)}
                    to="/house_listing/manage"
                    className={({ isActive }) =>
                      isActive ? "nav-active" : "nav-inactive"
                    }
                  >
                    Manage
                  </NavLink>
                </div>
                 <div>
                  <NavLink
                    onClick={() => setManage(true)}
                    to="/house_listing/interested"
                    className={({ isActive }) =>
                      isActive ? "nav-active" : "nav-inactive"
                    }
                  >
                    Interested
                  </NavLink>
                </div>
                {!manage ? (
                  <button className="filter-ic-btn border-0">
                    <Link
                      to="#"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal2"
                      style={{
                        border: "none",
                        margin: "0",
                        padding: "0px 0px",
                        marginRight:
                          "17nm,mn,l                                                                                                                                                                                                                                                                 m,mm,mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,llllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllll,l,l,,,ll                             ,l  mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,l,llllllllllllllllllllllllllllllllllmmmmmmm,lllllllllllllllllllllllllllllll                                                                                                                                                                                                                                                                                                                                                                                                                                                  0px",
                      }}
                    >
                      <img src={fillter_icon} alt="" />
                    </Link>
                  </button>
                ) : (
                  <></>
                )}

                <div>
                  <Link
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalset"
                    to="#"
                    className="location-button"
                    // onClick={() => setManage(true)}
                  >
                    <p>
                      <span
                        className="content"
                        style={{
                          marginLeft: "8px",
                          width: "fit-content",
                          maxWidth: "100px",
                          textAlign: "start",
                        }}
                      >
                        {customerData.location}{" "}
                      </span>
                      <span className="dot mx-1"></span>
                      <span>{customerData.radius} Miles </span>
                    </p>
                  </Link>
                </div>
                {!manage ? (
                  <div
                    className="search-input"
                    onClick={() => setManage(false)}
                  >
                    <input
                      ref={ipRef}
                      type="text"
                      name="text"
                      id="text"
                      placeholder="Search property"
                      style={{
                        width: "100%",
                      }}
                    />
                    <img
                      src={search_icon}
                      alt="search"
                      onClick={() => handleSearch()}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div>
                <div>
                  <Link
                    onClick={() => setManage(false)}
                    className="create-event-button"
                    to="/create_property"
                  >
                    Create Property
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            className="property-container no-scroll"
            style={{
              backgroundColor: "white",
              borderRadius: "20px",
              marginTop: "20px",
              height: "calc(100vh - 216px)",
              padding: "20px",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            <div
              className="no-scroll"
              style={{
                overflow: "hidden auto",
                height: "100%",
              }}
            >
              <Outlet />
            </div>
          </div>
        </section>
      </main>

      {/* modal */}
    </>
  );
}

export default HouseListing;
{
  /*<div className="row">
  <div className="col-md-12">
    <div
      className="row"
      style={{
        overflow: "auto",
      }}
    >
      <div className="col-md-12">
        <div className="globalPages_tabWrapper">
          <div className="gb_tabs">
            <div className="tabs">
              <div>
                <NavLink
                  to="/house_listing/all"
                  className={({ isActive }) =>
                    isActive ? "nav-active" : "nav-inactive"
                  }
                >
                  All Properties
                </NavLink>
              </div>
              <div>
                <NavLink
                  to="/house_listing/manage"
                  className={({ isActive }) =>
                    isActive ? "nav-active" : "nav-inactive"
                  }
                >
                  Manage
                </NavLink>
              </div>
              <button className="filter-ic-btn border-0">
                <Link
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal2"
                  style={{
                    border: "none",
                    margin: "0",
                    padding: "0px 0px",
                    marginRight:
                      "17nm,mn,l                                                                                                                                                                                                                                                                 m,mm,mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,llllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllll,l,l,,,ll                             ,l  mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,l,llllllllllllllllllllllllllllllllllmmmmmmm,lllllllllllllllllllllllllllllll                                                                                                                                                                                                                                                                                                                                                                                                                                                  0px",
                  }}
                >
                  <img src={fillter_icon} alt="" />
                </Link>
              </button>
              <div>
                <Link to="#" className="location-button">
                  <p>
                    Las Vegas <span className="dot"></span>
                    <span>20 kms</span>
                  </p>
                </Link>
              </div>

              <div className="search-input">
                <input
                  type="text"
                  name="text"
                  id="text"
                  placeholder="Search property"
                  style={{
                    width: "100%",
                  }}
                />
                <img src={search_icon} alt="search" />
              </div>

              <div>
                <Link className="create-event-button" to="/create_property">
                  Create Property
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="gblThumb_outerWrapper no-scroll"
      style={{
        padding: "20px",
      }}
    >
      <Outlet />
    </div>
  </div>
</div> */
}

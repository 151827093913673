import React, { useContext, useState, useEffect } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { AppContext } from "../context/AppContext";
import profile from "../assets/images/profile_picture.jpg";
import chat from "../assets/images/white_chat.svg";
import request from "../assets/images/requested.png";
function SearchResults() {
  const { sidebar, setSidebar } = useContext(AppContext);

  const [showElement, setShowElement] = React.useState(true);
  useEffect(() => {
    setTimeout(function () {
      setShowElement(false);
    }, 5000);
  }, []);

  return (
    <div class="sitemainBGwrapper">
      <Sidebar />

      <Header />
      <main id="cxMain" className={sidebar ? "cx-main cx-active" : "cx-main"}>
        <section
          className="content-main"
          style={{
            backgroundColor: "white",
            borderRadius: "20px",
            padding: "20px",
            height: "fit-content",
            minHeight: "calc(100vh - 156px)",
          }}
        >
          <p
            style={{
              color: "#1D1D25",
              fontSize: "16px",
              fontWeight: "600",
              paddingBottom: "20px",
              marginBottom: "20px",
              borderBottom: "1px solid #E8E8E8",
            }}
          >
            Search Result{" "}
            <span
              style={{
                color: "#00B4BD",
                fontSize: "16px",
                fontWeight: "600",
                marginLeft: "20px",
              }}
            >
              63
            </span>
          </p>
          <div
            className="row"
            style={{
              rowGap: "20px",
            }}
          >
            <div className="col-12 col-sm-6 col-xl-4">
              <div
                className="res-card d-flex flex-column align-items-center flex-lg-row"
                style={{
                  backgroundColor: "#F5F5F5",
                  borderRadius: "20px",
                  padding: "10px",
                }}
              >
                <img
                  src={profile}
                  alt="profile"
                  style={{
                    height: "80px",
                    width: "80px",
                    borderRadius: "10px",
                  }}
                />
                <div className="res-details w-100 d-flex flex-column align-items-center mt-2 mt-lg-0 align-items-lg-start ms-lg-4">
                  <p
                    style={{
                      color: "#21212C",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    Thomas Abraham
                  </p>
                  <p
                    style={{
                      color: "#707070",
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                  >
                    12 Mutual Friends
                  </p>
                  <button
                    style={{
                      width: "100%",
                      maxWidth: "120px",
                      backgroundColor: "#00B4BD",
                      height: "30px",
                      borderRadius: "15px",
                      border: "none",
                      marginTop: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img src={chat} alt="" />
                    <span
                      style={{
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "bold",
                        marginLeft: "10px",
                      }}
                    >
                      Contact
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4">
              <div
                className="res-card d-flex flex-column align-items-center flex-lg-row"
                style={{
                  backgroundColor: "#F5F5F5",
                  borderRadius: "20px",
                  padding: "10px",
                }}
              >
                <img
                  src={profile}
                  alt="profile"
                  style={{
                    height: "80px",
                    width: "80px",
                    borderRadius: "10px",
                  }}
                />
                <div className="res-details w-100 d-flex flex-column align-items-center mt-2 mt-lg-0 align-items-lg-start ms-lg-4">
                  <p
                    style={{
                      color: "#21212C",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    Thomas Abraham
                  </p>
                  <p
                    style={{
                      color: "#707070",
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                  >
                    12 Mutual Friends
                  </p>
                  <button
                    style={{
                      width: "100%",
                      maxWidth: "120px",
                      backgroundColor: "#00B4BD",
                      height: "30px",
                      borderRadius: "15px",
                      border: "none",
                      marginTop: "10px",
                    }}
                  >
                    <img src={chat} alt="" />
                    <span
                      style={{
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "bold",
                        marginLeft: "10px",
                      }}
                    >
                      Contact
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4">
              <div
                className="res-card d-flex flex-column align-items-center flex-lg-row"
                style={{
                  backgroundColor: "#F5F5F5",
                  borderRadius: "20px",
                  padding: "10px",
                }}
              >
                <img
                  src={profile}
                  alt="profile"
                  style={{
                    height: "80px",
                    width: "80px",
                    borderRadius: "10px",
                  }}
                />
                <div className="res-details w-100 d-flex flex-column align-items-center mt-2 mt-lg-0 align-items-lg-start ms-lg-4">
                  <p
                    style={{
                      color: "#21212C",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    Thomas Abraham
                  </p>
                  <p
                    style={{
                      color: "#707070",
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                  >
                    12 Mutual Friends
                  </p>
                  <button
                    style={{
                      width: "100%",
                      maxWidth: "120px",
                      backgroundColor: "#00B4BD",
                      height: "30px",
                      borderRadius: "15px",
                      border: "none",
                      marginTop: "10px",
                    }}
                  >
                    <img src={chat} alt="" />
                    <span
                      style={{
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "bold",
                        marginLeft: "10px",
                      }}
                    >
                      Contact
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4">
              <div
                className="res-card d-flex flex-column align-items-center flex-lg-row"
                style={{
                  backgroundColor: "#F5F5F5",
                  borderRadius: "20px",
                  padding: "10px",
                }}
              >
                <img
                  src={profile}
                  alt="profile"
                  style={{
                    height: "80px",
                    width: "80px",
                    borderRadius: "10px",
                  }}
                />
                <div className="res-details w-100 d-flex flex-column align-items-center mt-2 mt-lg-0 align-items-lg-start ms-lg-4">
                  <p
                    style={{
                      color: "#21212C",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    Thomas Abraham
                  </p>
                  <p
                    style={{
                      color: "#707070",
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                  >
                    12 Mutual Friends
                  </p>
                  <button
                    style={{
                      width: "100%",
                      maxWidth: "120px",
                      backgroundColor: "#00B4BD",
                      height: "30px",
                      borderRadius: "15px",
                      border: "none",
                      marginTop: "10px",
                    }}
                  >
                    <img src={chat} alt="" />
                    <span
                      style={{
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "bold",
                        marginLeft: "10px",
                      }}
                    >
                      Contact
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4">
              <div
                className="res-card d-flex flex-column align-items-center flex-lg-row"
                style={{
                  backgroundColor: "#F5F5F5",
                  borderRadius: "20px",
                  padding: "10px",
                }}
              >
                <img
                  src={profile}
                  alt="profile"
                  style={{
                    height: "80px",
                    width: "80px",
                    borderRadius: "10px",
                  }}
                />
                <div className="res-details w-100 d-flex flex-column align-items-center mt-2 mt-lg-0 align-items-lg-start ms-lg-4">
                  <p
                    style={{
                      color: "#21212C",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    Thomas Abraham
                  </p>
                  <p
                    style={{
                      color: "#707070",
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                  >
                    12 Mutual Friends
                  </p>
                  <button
                    style={{
                      width: "100%",
                      maxWidth: "120px",

                      height: "30px",
                      borderRadius: "15px",
                      border: "1px solid #00B4BD ",
                      marginTop: "10px",
                    }}
                  >
                    <img src={request} alt="" />
                    <span
                      style={{
                        color: "#00B4BD ",
                        fontSize: "14px",
                        fontWeight: "bold",
                        marginLeft: "10px",
                      }}
                    >
                      Requested
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4">
              <div
                className="res-card d-flex flex-column align-items-center flex-lg-row"
                style={{
                  backgroundColor: "#F5F5F5",
                  borderRadius: "20px",
                  padding: "10px",
                }}
              >
                <img
                  src={profile}
                  alt="profile"
                  style={{
                    height: "80px",
                    width: "80px",
                    borderRadius: "10px",
                  }}
                />
                <div className="res-details w-100 d-flex flex-column align-items-center mt-2 mt-lg-0 align-items-lg-start ms-lg-4">
                  <p
                    style={{
                      color: "#21212C",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    Thomas Abraham
                  </p>
                  <p
                    style={{
                      color: "#707070",
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                  >
                    12 Mutual Friends
                  </p>
                  <button
                    style={{
                      width: "100%",
                      maxWidth: "120px",

                      height: "30px",
                      borderRadius: "15px",
                      border: "1px solid #00B4BD ",
                      marginTop: "10px",
                    }}
                  >
                    <img src={request} alt="" />
                    <span
                      style={{
                        color: "#00B4BD ",
                        fontSize: "14px",
                        fontWeight: "bold",
                        marginLeft: "10px",
                      }}
                    >
                      Requested
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4">
              <div
                className="res-card d-flex flex-column align-items-center flex-lg-row"
                style={{
                  backgroundColor: "#F5F5F5",
                  borderRadius: "20px",
                  padding: "10px",
                }}
              >
                <img
                  src={profile}
                  alt="profile"
                  style={{
                    height: "80px",
                    width: "80px",
                    borderRadius: "10px",
                  }}
                />
                <div className="res-details w-100 d-flex flex-column align-items-center mt-2 mt-lg-0 align-items-lg-start ms-lg-4">
                  <p
                    style={{
                      color: "#21212C",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    Thomas Abraham
                  </p>
                  <p
                    style={{
                      color: "#707070",
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                  >
                    12 Mutual Friends
                  </p>
                  <button
                    style={{
                      width: "100%",
                      maxWidth: "120px",

                      height: "30px",
                      borderRadius: "15px",
                      border: "1px solid #00B4BD ",
                      marginTop: "10px",
                    }}
                  >
                    <img src={request} alt="" />
                    <span
                      style={{
                        color: "#00B4BD ",
                        fontSize: "14px",
                        fontWeight: "bold",
                        marginLeft: "10px",
                      }}
                    >
                      Requested
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4">
              <div
                className="res-card d-flex flex-column align-items-center flex-lg-row"
                style={{
                  backgroundColor: "#F5F5F5",
                  borderRadius: "20px",
                  padding: "10px",
                }}
              >
                <img
                  src={profile}
                  alt="profile"
                  style={{
                    height: "80px",
                    width: "80px",
                    borderRadius: "10px",
                  }}
                />
                <div className="res-details w-100 d-flex flex-column align-items-center mt-2 mt-lg-0 align-items-lg-start ms-lg-4">
                  <p
                    style={{
                      color: "#21212C",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    Thomas Abraham
                  </p>
                  <p
                    style={{
                      color: "#707070",
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                  >
                    12 Mutual Friends
                  </p>
                  <button
                    style={{
                      width: "100%",
                      maxWidth: "120px",

                      height: "30px",
                      borderRadius: "15px",
                      border: "1px solid #00B4BD ",
                      marginTop: "10px",
                    }}
                  >
                    <img src={request} alt="" />
                    <span
                      style={{
                        color: "#00B4BD ",
                        fontSize: "14px",
                        fontWeight: "bold",
                        marginLeft: "10px",
                      }}
                    >
                      Add Friend
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4">
              <div
                className="res-card d-flex flex-column align-items-center flex-lg-row"
                style={{
                  backgroundColor: "#F5F5F5",
                  borderRadius: "20px",
                  padding: "10px",
                }}
              >
                <img
                  src={profile}
                  alt="profile"
                  style={{
                    height: "80px",
                    width: "80px",
                    borderRadius: "10px",
                  }}
                />
                <div className="res-details w-100 d-flex flex-column align-items-center mt-2 mt-lg-0 align-items-lg-start ms-lg-4">
                  <p
                    style={{
                      color: "#21212C",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    Thomas Abraham
                  </p>
                  <p
                    style={{
                      color: "#707070",
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                  >
                    12 Mutual Friends
                  </p>
                  <button
                    style={{
                      width: "100%",
                      maxWidth: "120px",

                      height: "30px",
                      borderRadius: "15px",
                      border: "1px solid #00B4BD ",
                      marginTop: "10px",
                    }}
                  >
                    <img src={request} alt="" />
                    <span
                      style={{
                        color: "#00B4BD ",
                        fontSize: "14px",
                        fontWeight: "bold",
                        marginLeft: "10px",
                      }}
                    >
                      Add Friend
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4">
              <div
                className="res-card d-flex flex-column align-items-center flex-lg-row"
                style={{
                  backgroundColor: "#F5F5F5",
                  borderRadius: "20px",
                  padding: "10px",
                }}
              >
                <img
                  src={profile}
                  alt="profile"
                  style={{
                    height: "80px",
                    width: "80px",
                    borderRadius: "10px",
                  }}
                />
                <div className="res-details w-100 d-flex flex-column align-items-center mt-2 mt-lg-0 align-items-lg-start ms-lg-4">
                  <p
                    style={{
                      color: "#21212C",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    Thomas Abraham
                  </p>
                  <p
                    style={{
                      color: "#707070",
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                  >
                    12 Mutual Friends
                  </p>
                  <button
                    style={{
                      width: "100%",
                      maxWidth: "120px",
                      backgroundColor: "#00B4BD",
                      height: "30px",
                      borderRadius: "15px",
                      border: "none",
                      marginTop: "10px",
                    }}
                  >
                    <img src={chat} alt="" />
                    <span
                      style={{
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "bold",
                        marginLeft: "10px",
                      }}
                    >
                      Contact
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4">
              <div
                className="res-card d-flex flex-column align-items-center flex-lg-row"
                style={{
                  backgroundColor: "#F5F5F5",
                  borderRadius: "20px",
                  padding: "10px",
                }}
              >
                <img
                  src={profile}
                  alt="profile"
                  style={{
                    height: "80px",
                    width: "80px",
                    borderRadius: "10px",
                  }}
                />
                <div className="res-details w-100 d-flex flex-column align-items-center mt-2 mt-lg-0 align-items-lg-start ms-lg-4">
                  <p
                    style={{
                      color: "#21212C",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    Thomas Abraham
                  </p>
                  <p
                    style={{
                      color: "#707070",
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                  >
                    12 Mutual Friends
                  </p>
                  <button
                    style={{
                      width: "100%",
                      maxWidth: "120px",
                      backgroundColor: "#00B4BD",
                      height: "30px",
                      borderRadius: "15px",
                      border: "none",
                      marginTop: "10px",
                    }}
                  >
                    <img src={chat} alt="" />
                    <span
                      style={{
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "bold",
                        marginLeft: "10px",
                      }}
                    >
                      Contact
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4">
              <div
                className="res-card d-flex flex-column align-items-center flex-lg-row"
                style={{
                  backgroundColor: "#F5F5F5",
                  borderRadius: "20px",
                  padding: "10px",
                }}
              >
                <img
                  src={profile}
                  alt="profile"
                  style={{
                    height: "80px",
                    width: "80px",
                    borderRadius: "10px",
                  }}
                />
                <div className="res-details w-100 d-flex flex-column align-items-center mt-2 mt-lg-0 align-items-lg-start ms-lg-4">
                  <p
                    style={{
                      color: "#21212C",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    Thomas Abraham
                  </p>
                  <p
                    style={{
                      color: "#707070",
                      fontSize: "14px",
                      fontWeight: "normal",
                    }}
                  >
                    12 Mutual Friends
                  </p>
                  <button
                    style={{
                      width: "100%",
                      maxWidth: "120px",

                      height: "30px",
                      borderRadius: "15px",
                      border: "1px solid #00B4BD ",
                      marginTop: "10px",
                    }}
                  >
                    <img src={request} alt="" />
                    <span
                      style={{
                        color: "#00B4BD ",
                        fontSize: "14px",
                        fontWeight: "bold",
                        marginLeft: "10px",
                      }}
                    >
                      Add Friend
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default SearchResults;

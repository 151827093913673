import React, { useContext, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Sidebar from "../../components/Sidebar";
import swal from "sweetalert";
import Header from "../../components/Header";
import profile_picture from "../../assets/images/profile_picture.jpg";
import userDemo from '../../assets/images/userDemo.png'
import ImageInput from "./file/ImageInput";
import createselectAvatar from "../../assets/images/createselectAvatar.png";
import createadd_avatar from "../../assets/images/createadd_avatar.png";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
import {
  multipartPostCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
  putMultipartWithAuthCall,
  putMultipartWithAuthCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import Barloader from "../../sharedComponent/BarLoader";
import Select from 'react-select'
import { data } from "jquery";

function CreateProfile() {
  const navigate = useNavigate();
  const params = useParams();
  let id = params.id;
  const { sidebar, setSidebar, customerData } = useContext(AppContext);
  const [interestData, setInterest] = useState([]);
  const [Data, setData] = useState("");

  console.log("Data", Data)
  const [loading, setLoading] = useState(true);
  const [addloading, setAddLoading] = useState(false);
  const [stateOption, setstateOption] = useState([])
  const [list, setList] = useState([])
  console.log(list)
  const [State, setState] = useState({
    selectedOption: [],
    selectedOptionList: Data ? Data : ""
  })
  const [image, setImage] = useState("")
  console.log(State)
  const [UserDetails, setUserDetails] = useState({
    profile_pic: "",
    full_name: "",
    profile_name: "",
    university: "",
    age: "",
    gender: "",
    about_me: "",
    interest: [],
  });
  console.log(UserDetails);

  const [errMsg, setErrMsg] = useState({
    profile_pic: "",
    full_name: "",
    profile_name: "",
    university: "",
    age: "",
    gender: "",
    about_me: "",
    interest: [],
  });

  const [showElement, setShowElement] = React.useState(true);
  useEffect(() => {
    setTimeout(function () {
      setShowElement(false);
    }, 5000);
  }, []);

  useEffect(() => {
    getuserinterest();
    getUnivarcityList()
  }, []);
  const getuserDetails = (id) => {
    setLoading(true);
    simpleGetCallWithErrorResponse(
      ApiConfig.UNIVERSR_USER_PROFILE,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        if (res.json.user_profile.full_name !== null) {
          parseUserProfile(res.json.user_profile);
        }
        setLoading(false);
        console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const parseUserProfile = (data) => {
    setData(data.university)
    setUserDetails({
      profile_pic: data.profile_pic == null ? null : data.profile_pic,
      full_name: data.full_name,
      profile_name: data.profile_name,
      university: data.university,
      age: data.age,
      gender: data.gender,
      about_me: data.about_me,
      interest: data.interest,
    });
  };
 

  useEffect(() => {
    let Interestlist = []
    if (UserDetails.interest.length > 0) {
      UserDetails.interest.map((dataType, index) => {
        Interestlist.push({
          label: dataType.interest.subject,
          value: dataType.interest.id,
        })
      })
      setInterest(Interestlist);
    }
  }, [UserDetails]);
  const getuserinterest = () => {
    simpleGetCallWithErrorResponse(ApiConfig.UNIVERSR_INSTITUTE_INTEREST).then(
      (data) => {
   

        parseUserData(data);
        getuserDetails();
      }
    );
  };
  const getUnivarcityList = () => {
    simpleGetCallWithErrorResponse(ApiConfig.UNIVERSR_LIST).then(
      (data) => {
console.log(data)
        parseUserDataList(data);
        getuserDetails();
      }
    );
  };

  useEffect(() => { }, [interestData]);
  const parseUserData = (data) => {
    let datas = [];
    let category = []
    data.json.data.map((item, index) => {
      datas.push({
        id: item.id,
        subject: item.subject,
        isSelected: false,
      });
    });
    data.json.data.map((dataType, index) => {
      category.push({
        label: dataType.subject,
        value: dataType.id,
      })
    })
    setstateOption(category)

  };
  const parseUserDataList = (data) => {

    let category = []
    data.json.data.map((item, index) => {
      category.push({
        value: item.id,
        label: item.institute_name,

      });
    });

    setList(category)

  };

  const Add = () => {
    if (UserDetails.full_name === "") {
      if (UserDetails.full_name === "") {
        setErrMsg({ ...errMsg, full_name: "Enter Your Name " });
        window.scrollTo(0, 0);
        return;
      }
    } else {
      setAddLoading(true);
      let intData = [];
      console.log(intData);
      interestData.map((data) => {
        // if (data.checked)
        intData.push(data.value);
      });

      let detail = JSON.stringify({
        full_name: UserDetails.full_name,
        profile_name: UserDetails.profile_name,
        gender: UserDetails.gender,
        age: UserDetails.age,
        university: Data,
        about_me: UserDetails.about_me,
        subjects: intData,
      });
      let formData = new FormData();
      formData.append("details", detail);

      UserDetails.profile_pic &&
        UserDetails.profile_pic.name &&
        formData.append("profile_pic", UserDetails.profile_pic);
      putMultipartWithAuthCallWithErrorResponse(
        ApiConfig.UNIVERSR_EDIT_PROFILE,
        formData
      )
        .then((res) => {
          console.log(res);
          setAddLoading(false);
          swal("Profile updated sucessfully.");
          navigate("/dashboard");
        })
        .catch((err) => {
          setAddLoading(false);
          console.log(err);
        });
      // }
    }
  };
  function ontypeChange(selectedOption) {
    setState({
      ...State,
      selectedOption: selectedOption,
      
    })
    setInterest([...interestData,selectedOption])


  }
  function ontypeChangeUniversity(selectedOption) {
    setState({
      ...State,
      selectedOptionList: selectedOption,
      // selectedGradeType: selectedOption,
    })
    setData(selectedOption.label)


  }
  return (
    <>
      <Sidebar />

      {/* <Header /> */}
      {loading ? (
        <Loader />
      ) : (
        <main
          id="cxMain"
          className={sidebar ? "cx-main cx-active" : "cx-main"}
          style={{
            height: "calc(100vh - 156px)",
          }}
        >
          {addloading && <Barloader />}

          <section
            className="content-main"
            style={{
              height: "calc(100vh - 156px)",
              backgroundColor: "white",
              borderRadius: "20px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <div className="row">
              <div className="col-md-12">
                <div
                  className="create_screenWrapper mywrapper"
                  style={{
                    padding: "20px",
                    height: "fit-content",
                  }}
                >
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Edit Profile
                    </h5>
                  </div>

                  <div className="profile_modal_contentWrapper">
                    <div className="row cProfile-wrapper">
                      <div className="col-12 col-xl-2">
                        <div className="uploadPPWrapper d-flex flex-column align-items-center align-items-lg-start">
                          <div className="uploadPP_imgHolder d-flex flex-column align-items-center align-items-lg-start">
                            {/* <img
                            src={profile_picture}
                            alt=""
                            style={{
                              height: "auto",
                              width: "129px",
                              margin: "0",
                            }}
                          /> */}
                            <label for="cx-pro-cam" className="cx-pro-cam ">
                              <img
                                style={{
                                  height: "129px",
                                  width: "129px",
                                  margin: "0",
                                  objectFit: "cover",
                                }}
                                class="cx-pdefault"
                                src={
                                  // profile_picture
                                  !UserDetails.profile_pic
                                    ? userDemo
                                    : UserDetails.profile_pic.length
                                      ? UserDetails.profile_pic
                                      : UserDetails.profile_pic &&
                                      URL.createObjectURL(
                                        UserDetails.profile_pic
                                      )
                                }
                              />
                            </label>
                          </div>
                          <div className="uploadPP_input02">
                            <div
                              className="form-group"
                              style={{ width: "135px" }}
                            >
                              {/* <input
                              type="file"
                              name="file"
                              id="file"
                              className="input-file"
                            /> */}
                              <input
                                type="file"
                                name="file"
                                class="form-control cx-adduser-input input-file  "
                                id="cx-pro-cam"
                                required
                                onChange={(e) => {

                                  setErrMsg({ ...errMsg, profile_pic: "" });
                                  setUserDetails({
                                    ...UserDetails,
                                    profile_pic: e.target.files[0],
                                  });
                                }}
                              />
                              {/* <div className="col image-input">
      <ImageInput
        imageData={image.photo?.src}
        defaultPic={Default_User_Pic}
        type="admin"
        name="photo"
        label="Add Photo"
        showPreview
        onChange={(files) => setImage(files, "admin")}
      />
    </div> */}
                              <label
                                style={{
                                  marginTop: "20px",
                                }}
                                for="cx-pro-cam"
                                className="btn btn-tertiary js-labelFile"
                              >
                                <span
                                  className="js-fileName"
                                  style={{
                                    marginRight: "10px",
                                  }}
                                >
                                  Update
                                </span>
                              </label>
                              {errMsg.profile_pic.length > 0 && (
                                <div className="text-center text-danger">
                                  <span> {errMsg.profile_pic}</span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div class="cx-form-group">
                          <label for="cx-pro-cam" className="cx-pro-cam ">

                          </label>

                        </div>
                      </div>

                      <div
                        className="col-md-10 profile_form"
                        style={{ marginTop: "-20px" }}
                      >
                        <div className="row">
                          <div
                            className="col-md-6"
                            style={{ marginTop: "20px" }}
                          >
                            <div className="profile-input-details">
                              <label className="form-label">Full Name</label>
                              <input
                                style={{ height: "40px" }}
                                type="text"
                                className="form-control place-fw"
                                placeholder="Catherin James"
                                value={UserDetails.profile_name}
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                onChange={(e) => {
                                  setErrMsg({ ...errMsg, profile_name: "" });
                                  setUserDetails({
                                    ...UserDetails,
                                    profile_name: e.target.value,
                                  });
                                }}
                              />
                              {errMsg.profile_name.length > 0 && (
                                <div className="text-center text-danger">
                                  <span> {errMsg.profile_name}</span>
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            className="col-md-6"
                            style={{ marginTop: "20px" }}
                          >
                            <div className="profile-input-details">
                              <label className="form-label">User Name</label>
                              <input
                                style={{ height: "40px" }}
                                type="text"
                                value={UserDetails.full_name}
                                className="form-control place-fw"
                                placeholder="cathy.james"
                                onChange={(e) => {
                                  setErrMsg({ ...errMsg, full_name: "" });

                                  setUserDetails({
                                    ...UserDetails,
                                    full_name: e.target.value,
                                  });
                                }}
                              />
                              {errMsg.full_name.length > 0 && (
                                <div className="text-center text-danger">
                                  <span> {errMsg.full_name}</span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div
                            className="col-md-4"
                            style={{ marginTop: "20px" }}
                          >
                            <div className="profile-input-details">
                              <label className="form-label">
                                University / Institution
                              </label>
                              {/* <input
                                style={{ height: "40px" }}
                                type="text"
                                className="form-control place-fw"
                                placeholder="IIT"
                                value={UserDetails.university}
                                onChange={(e) => {
                                  setErrMsg({ ...errMsg, university: "" });

                                  setUserDetails({
                                    ...UserDetails,
                                    university: e.target.value,
                                  });
                                }}
                              />
                              {errMsg.university.length > 0 && (
                                <div className="text-center text-danger">
                                  <span> {errMsg.university}</span>
                                </div>
                              )} */}
                              <Select
                                placeholder="IIT"
                                class="js-example-basic-single form-select"
                                value={State.selectedOptionList}
                                onChange={ontypeChangeUniversity}
                                options={list}


                              />
                              {/* {errMsg.university.length > 0 && (
                                <div className="text-center text-danger">
                                  <span> {errMsg.university}</span>
                                </div>
                              )} */}
                            </div>
                          </div>
                          <div
                            className="col-md-4"
                            style={{ marginTop: "20px" }}
                          >
                            <div className="profile-input-details">
                              <label className="form-label">Age</label>
                              <input
                                style={{ height: "40px", textAlign: "center" }}
                                type="text"
                                className="form-control place-fw  "
                                placeholder="Entere Your Age Here"
                                value={UserDetails.age}
                                onChange={(e) => {
                                  setErrMsg({ ...errMsg, age: "" });

                                  setUserDetails({
                                    ...UserDetails,
                                    age: e.target.value,
                                  });
                                }}
                              />
                              {errMsg.age.length > 0 && (
                                <div className="text-center text-danger">
                                  <span> {errMsg.age}</span>
                                </div>
                              )}
                            </div>
                          </div>

                          <div
                            className="col-md-4"
                            style={{ marginTop: "20px" }}
                          >
                            <div className="profile-input-details">
                              <label className="form-label">
                                I would like to be known as
                              </label>
                              <input
                                className="form-control"
                                id="inputGroupSelect01 place-fw"
                                style={{
                                  backgroundColor: "#FBF8F8",
                                  height: "40px",
                                  borderRadius: "10px",
                                }}
                                value={UserDetails.gender}
                                onChange={(e) => {
                                  setErrMsg({ ...errMsg, gender: "" });
                                  setUserDetails({
                                    ...UserDetails,
                                    gender: e.target.value,
                                  });
                                }}
                              />
                              {errMsg.gender.length > 0 && (
                                <div className="text-center text-danger">
                                  <span> {errMsg.gender}</span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div
                            className="col-md-12"
                            style={{ marginTop: "20px" }}
                          >
                            <div className="uploadPP_inputTextRow">
                              <label className="form-label">About Me</label>
                              <textarea
                                value={UserDetails.about_me}
                                onChange={(e) => {
                                  setErrMsg({ ...errMsg, about_me: "" });

                                  setUserDetails({
                                    ...UserDetails,
                                    about_me: e.target.value,
                                  });
                                }}
                                style={{
                                  height: "80px",
                                  outline: "none",
                                  padding: "10px 20px",
                                }}
                                placeholder="Describe your property"
                                className="place-fw"
                              ></textarea>
                              {errMsg.about_me.length > 0 && (
                                <div className="text-center text-danger">
                                  <span> {errMsg.about_me}</span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div
                            className="col-md-12"
                            style={{ marginTop: "0px" }}
                          >
                            <Select
                              class="js-example-basic-single form-select"
                              value={State.selectedOption}
                              onChange={ontypeChange}
                              options={stateOption}
                              isMulti={true}

                            />
                            <div style={{
                              marginTop: "10px"
                            }} className="profile_tagWrapper">
                              <h1>Interests</h1>
                              <div
                                className="scrolling"
                                style={{
                                  overflow: "auto",
                                }}
                              >
                                <div className="row pro-check">
                                  {interestData &&
                                    interestData.length &&
                                    interestData.map((item, Index) => {
                                      return (
                                        <div
                                          className="col-6 col-sm-3 col-lg-2 col-xl-1 mt-3"
                                          style={{
                                            minWidth: "110px",
                                          }}
                                        >
                                          <label className="intrest-item">
                                            <input
                                              class="icheck"
                                              type="checkbox"
                                            // value={item.subject}
                                            // checked={item.checked}
                                            // onChange={(e) => {
                                            //   let data = interestData.map(
                                            //     (data) => {
                                            //       if (data.id === item.id) {
                                            //         return {
                                            //           ...data,
                                            //           checked:
                                            //             e.target.checked,
                                            //         };
                                            //       } else return data;
                                            //     }
                                            //   );
                                            //   setInterest(data);
                                            // }}
                                            />
                                            <span class="i-label-text">
                                              {item.label}
                                            </span>
                                          </label>
                                        </div>
                                      );
                                    })}
                                </div>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="create_screenControls"
                        style={{ marginTop: "25px" }}
                      >
                        <Link
                          to="/dashboard"
                          style={{
                            padding: "13PX",
                            width: "140px",
                            border: "1px solid #00B4BD",
                            backgroundColor: "white",
                            color: "#00B4BD",
                          }}
                        >
                          Cancel
                        </Link>
                        <Link
                          className="primary"
                          to="#"
                          onClick={Add}
                          style={{ padding: "13PX", width: "140px" }}
                        >
                          Update Profile
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      )}
    </>
  );
}

export default CreateProfile;

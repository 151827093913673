import React, { useContext, useState, useEffect } from 'react'
import { Link,useNavigate, useParams } from 'react-router-dom'
import { AppContext } from '../../context/AppContext'
import Sidebar from '../../components/Sidebar'
import Header from '../../components/Header'
import profile_picture from '../../assets/images/profile_picture.jpg'
import createselectAvatar from '../../assets/images/createselectAvatar.png'
import createadd_avatar from '../../assets/images/createadd_avatar.png'
import down from '../../assets/images/drop_down.svg'
import './Group.scss'
import Select from 'react-select'
import {
  postMultipartWithAuthCallWithErrorResponseNodeCreate,
  putMultipartWithAuthCallWithErrorResponseNodeCreate,
  simpleGetCallWithErrorResponseNODE,
} from '../../api/ApiServices'
import ApiConfig from '../../api/ApiConfig'
import userDemo from '../../assets/images/userDemo.png'
import swal from 'sweetalert'

function CreateGroup() {
  const params = useParams();
  let id = params.id;
  const { sidebar, setSidebar,setActiveTab } = useContext(AppContext)
  const [interestData, setInterest] = useState([])
  const [UserDetails, setUserDetails] = useState({
    group_logo: '',
    group_name: '',
    group_type: 'work',
    MembersId: [],
  })
  console.log(UserDetails)
  const [showElement, setShowElement] = React.useState(true)
  const [stateOption, setstateOption] = useState([])
  const navigate = useNavigate();

  const [State, setState] = useState({
    selectedOption: [],
  })
  console.log(State);

  
  useEffect(() => {
    getGroupData()
    getGroupDetails()
  }, [])

  useEffect(() => {
    setTimeout(function () {
      setShowElement(false)
    }, 5000)
  }, [])

  const getGroupDetails = () => {
    // setLoading(true);
    simpleGetCallWithErrorResponseNODE(
      ApiConfig.GROUP_DETAILS + id,
      
    )
      .then((res) => {
        console.log(res)
        // if (res.json.user_profile.full_name !== null) {
          parseUserProfile(res.json.data);
        // }
        // setLoading(false);
        console.log(res);
      })
      .catch((err) => {
        // setLoading(false);
        console.log(err);
      });
  };

  const parseUserProfile = (data) => {
    console.log(data)
    // setData(data.university)
    setUserDetails({
      group_logo: data[0].group_image,
      group_name: data[0].group_name,
      
     
    })
    let category = []

    data[0].MembersArray.map((item, index) => {
      console.log(item)
      category.push({
        value: item.member_id.toString(),
        label: item.member_name,
        img:item.member_profile
        
      })
    })
    setInterest(category)
    setState({
      ...State,
      selectedOption: category,
    })

  };

  const HandalGroup = () => {
    debugger
if(id){
  UpdateGroup()
}
else {
  AddGroup()
}
  }

  const UpdateGroup = () => {
    let intData = []
    console.log(intData)
    interestData.map((data) => {
      // if (data.checked)
      intData.push(data.value)
    })

    let data = JSON.stringify({
      group_name: UserDetails.group_name,
      group_type: UserDetails.group_type,
      MembersId: intData,
      group_id:id
    })
    let formData = new FormData()
    formData.append('data', data)

    UserDetails.group_logo &&
      UserDetails.group_logo &&
      formData.append('group_logo', UserDetails.group_logo ? UserDetails.group_logo : '')
      postMultipartWithAuthCallWithErrorResponseNodeCreate(
      ApiConfig.GROUP_EDIT,
      formData,
    )
      .then((res) => {
        console.log(res)

        swal("Group Update Successfully.");
        navigate("/groups");
      })
      .catch((err) => {
        console.log(err)
      })
    // }
  }

  const AddGroup = () => {
    let intData = []
    console.log(intData)
    interestData.map((data) => {
      // if (data.checked)
      intData.push(data.value)
    })

    let data = JSON.stringify({
      group_name: UserDetails.group_name,
      group_type: UserDetails.group_type,
      MembersId: intData,
    })
    let formData = new FormData()
    formData.append('data', data)

    UserDetails.group_logo &&
      UserDetails.group_logo.name &&
      formData.append('group_logo', UserDetails.group_logo)
      postMultipartWithAuthCallWithErrorResponseNodeCreate(
      ApiConfig.CREATE_GROUP_HOUSING,
      formData,
    )
      .then((res) => {
        console.log(res)

        swal("Group Created sucessfully");
        navigate("/groups");
      })
      .catch((err) => {
        console.log(err)
      })
    // }
  }

  const getGroupData = () => {
    simpleGetCallWithErrorResponseNODE(ApiConfig.GROUP_FRIEND_LIST).then(
      (data) => {
        console.log(data)
        parseUserData(data)
      },
    )
  }
  const parseUserData = (data) => {
    let category = []
    data.json.data.map((item, index) => {
      console.log(item)
      category.push({
        value: item.id,
        label: item.profile_name,
        img:item.profile_pic
        
      })
    })

    setstateOption(category)
  }

  function ontypeChange(selectedOption) {
    console.log(selectedOption)
    setState({
      ...State,
      selectedOption: selectedOption,
    })
    setInterest(selectedOption)
  }
  return (
    <>
      <Sidebar />

      {/* <Header /> */}
      <main id="cxMain" className={sidebar ? 'cx-main cx-active' : 'cx-main'}>
        <section
          className="content-main"
          style={{
            borderRadius: '20px',
          }}
        >
          <div className="row">
            <div className="col-md-12">
              <div
                className="create_screenWrapper border-radius"
                style={{
                  padding: '20px',
                  borderRadius: '20px',
                }}
              >
                <div class="modal-header">
                  <h5
                    style={{
                      fontSize: '20px',
                      color: '#1D1D25',
                      fontWeight: 'bold',
                    }}
                    class="modal-title"
                    id="exampleModalLabel"
                  >
                    {id ? 'Update Group' :'Create New Group'}
                  </h5>

                  <Link to="/groups">
                    <i class="fa-solid fa-xmark"></i>
                  </Link>
                </div>

                <div className="profile_modal_contentWrapper mt-3">
                  <div className="row">
                    <div className="col-12 col-lg-2">
                      <div className="uploadPPWrapper d-flex flex-column align-items-center">
                        <div className="uploadPP_imgHolder d-flex justify-content-center">
                          <img
                            src={
                              // profile_picture
                              !UserDetails.group_logo
                                ? userDemo
                                : UserDetails.group_logo.length
                                ? UserDetails.group_logo
                                : UserDetails.group_logo &&
                                  URL.createObjectURL(UserDetails.group_logo)
                            }
                            alt=""
                            style={{
                              height: '129px',
                              width: '121px',
                              margin: '0',
                              borderRadius: '33px',
                              border: '1px solid #00B4BD',
                            }}
                          />
                        </div>
                        <div className="uploadPP_input02">
                          <div
                            class="form-group"
                            style={{ width: '121px', borderRadius: '20px' }}
                          >
                            <input
                              type="file"
                              name="file"
                              id="file"
                              class="input-file"
                              onChange={(e) => {
                                setUserDetails({
                                  ...UserDetails,
                                  group_logo: e.target.files[0],
                                })
                              }}
                            />
                            <label
                              for="file"
                              class="btn btn-tertiary js-labelFile mt-3"
                            >
                              {/* <i class="icon fa fa-check"></i> */}
                              <span class="js-fileName">Update</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        padding: '0px 20px 2px 20px',
                      }}
                      className="col-12 col-lg-10  profile_form"
                    >
                      <div style={{ marginTop: '-20px' }} className="row">
                        <div
                          className="col-12 col-sm-12"
                          style={{ marginTop: '20px' }}
                        >
                          <div className="profile-input-details">
                            <label
                              style={{
                                fontFamily: 'Source Sans Pro, sans-serif',
                                fontSize: '14px',
                                lineHeight: '19px',
                                fontStyle: 'normal',
                                color: '#707070',
                              }}
                              class="form-label"
                            >
                              Group Name
                            </label>
                            <input
                              style={{
                                color: '#1D1D25',
                                fontSize: '16px ',
                                fontWeight: '600',
                              }}
                              value={UserDetails.group_name}

                              onChange={(e) => {
                                setUserDetails({
                                  ...UserDetails,
                                  group_name: e.target.value,
                                })
                              }}
                              type="text"
                              class="form-control"
                              placeholder="Enter group name"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div
                          className="col-md-12"
                          style={{ marginTop: '10px' }}
                        >
                          <div className="c_selectUserWrapper">
                            <h1
                              style={{
                                color: '#1D1D25',
                                fontSize: '16px',
                                fontWeight: '600',
                              }}
                            >
                              Selected Friends{' '}
                              <span
                                style={{
                                  marginLeft: '20px',
                                  color: '#00B4BD',
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                }}
                              >
                                02
                              </span>{' '}
                            </h1>
                            <div className='Selected_Friends_style '>
                            {interestData &&
                              interestData.length &&
                              interestData.map((item, Index) => {
                                return (
                                  <ul>
                                    <li>
                                      <div className="cselect_item">
                                        <div className="ci_avatar">
                                          <img
                                            src={item.img}
                                            style={{
                                              borderRadius: '22px',
                                              border: '1px solid #40F5CD',
                                              height:"38px",
                                              width:"38px"
                                            }}
                                            alt=""
                                          />
                                          {/* <button
                                            type="button"
                                            aria-label="Close"
                                          >
                                            <i class="fa-solid fa-xmark"></i>
                                          </button> */}
                                        </div>
                                        <h1
                                          style={{
                                            // font: "normal normal normal 14px/16px SF Pro Text",
                                            fontFamily:
                                              'Source Sans Pro, sans-serif',
                                            fontSize: '14px',
                                            lineHeight: '16px',
                                            fontStyle: 'normal',
                                            letterSpacing: '0px',
                                            fontWeight: 'normal',

                                            color: '#21212C',
                                          }}
                                        >
                                          {item.label}
                                        </h1>
                                      </div>
                                    </li>
                                  </ul>
                                )
                              })}
                              </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div
                          className="col-md-12 c_addUserWrapper-model "
                          style={{ marginTop: 'px' }}
                        >
                          <div className="c_addUserWrapper">
                            <h1
                              style={{
                                // font: "normal normal 600 16px/22px Nunito",
                                color: '#1D1D25',
                                fontSize: '16px',
                                fontWeight: '600',
                              }}
                            >
                              Add Friends
                            </h1>
                            <div className="row"></div>
                            <Select
                              placeholder="Friends"
                              class="js-example-basic-single form-select"
                              value={State.selectedOption}
                              // value={{ value: 'revenue1', label: 'Revenue' }}
                              onChange={ontypeChange}
                              options={stateOption}
                              isMulti={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="create_screenControls"
                  style={{ marginBottom: '30px', textAlign: 'center' }}
                >
                  <Link
                    to="/groups"
                    style={{
                      width: '140px',
                      padding: '13px',
                      backgroundColor: 'white',
                    }}
                  >
                    Cancel
                  </Link>
                  <Link 
                  // onClick={AddGroup}
                  onClick={() => {HandalGroup(); setActiveTab('Groups') }}
                    className="primary"
                    to="#"
                    style={{ width: '140px', padding: '13px' }}
                  >
                    { id ? 'Update Group':'Create Group'}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default CreateGroup

import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Sidebar from "../../components/Sidebar";
import HousingTabsHeader from "./HousingTabsHeader";
import thumbImg from "../../assets/images/house01.png";
import gray_location_icon from "../../assets/images/gray_location_icon.svg";
import ApiConfig from "../../api/ApiConfig";
import { simpleGetCallWithErrorResponse } from "../../api/ApiServices";

function HouseManage() {
  const { sidebar, setSidebar } = useContext(AppContext);
  const currentRoute = useLocation().pathname;
  const [loading, setLoading] = useState(false);
  const [showElement, setShowElement] = React.useState(true);
  const { customerData } = useContext(AppContext);
  const [HouseingList, setHouseingList] = useState([]);


  useEffect(() => {
    setTimeout(function () {
      setShowElement(false);
    }, 5000);
  }, []);

  useEffect(() => {
    getHouselist();

    return () => {
      setHouseingList([]);
    };
  }, []);

  const getHouselist = () => {
    setLoading(true);

    simpleGetCallWithErrorResponse(
      ApiConfig.UNIVERSR_HOUSING_MANGE_LIST,
      JSON.stringify({ ...customerData })
    ).then((res) => {
      console.log(res);
      setLoading(false);
      setHouseingList(res.json.housing_properties);
    });
  };

  return (
    <div class="sitemainBGwrapper">
      <Sidebar />
      <main id="cxMain" className={sidebar ? "cx-main cx-active" : "cx-main"}>
        <section className="content-main">
          <HousingTabsHeader />
          <div className="row ">
            <div className="col-md-12">
              <div className="gblThumb_outerWrapper">
                <div className="row">
                  <div className="col-md-4 " style={{ overflow: "auto" }}>
                    <div className="gbl_thumbWrapper">
                      <div className="img_thumbWrapper">
                        <div
                          class="dropdown"
                          style={{
                            borderRadius: "8px",
                            height: "34px",
                            width: "34px",
                          }}
                        >
                          <button
                            class="btn"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i class="fa-solid fa-ellipsis-vertical"></i>
                          </button>
                          <ul
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li style={{ padding: "0 20px" }}>
                              <Link
                                to="/add_device"
                                class="dropdown-item"
                                href="#"
                                style={{ borderBottom: "1px solid #e1e1e1" }}
                              >
                                Edit
                              </Link>
                            </li>
                          </ul>

                          {HouseingList &&
                            HouseingList.length &&
                            HouseingList.map((HouseingListMange, index) => {
                    
                              return (
                                <div className="col-12 col-lg-6  col-xl-4 col-xxl-3">
                                  <div className="gbl_thumbWrapper">
                                    <div className="img_thumbWrapper">
                                      <div
                                        class="dropdown"
                                        style={{
                                          borderRadius: "8px",
                                          height: "34px",
                                          width: "34px",
                                        }}
                                      >
                                        <button
                                          class="btn"
                                          type="button"
                                          id="dropdownMenuButton1"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <i class="fa-solid fa-ellipsis-vertical"></i>
                                        </button>
                                        <ul
                                          style={{
                                            borderRadius: "10px",
                                            border: "1px solid #ECECEC",
                                          }}
                                          class="dropdown-menu house-manage-dropdown"
                                          aria-labelledby="dropdownMenuButton1"
                                        >
                                          <li style={{ padding: "0 20px" }}>
                                            <Link
                                              to="/add_device"
                                              class="dropdown-item "
                                              href="#"
                                              style={{
                                                borderBottom:
                                                  "1px solid #e1e1e1",
                                                fontSize: "14px",
                                                fontFamily: "Nunito",
                                                fontStyle: "normal",
                                              }}
                                            >
                                              Edit
                                            </Link>
                                          </li>

                                          <li style={{ padding: "0 20px" }}>
                                            <Link
                                              to="/device_details"
                                              class="dropdown-item"
                                              href="#"
                                              style={{
                                                borderBottom:
                                                  "1px solid #e1e1e1",
                                                fontSize: "14px",
                                                fontFamily: "Nunito",
                                                fontStyle: "normal",
                                              }}
                                            >
                                              Share
                                            </Link>
                                          </li>
                                          <li
                                            style={{
                                              padding: "0 20px",
                                              fontSize: "14px",
                                              fontFamily: "Nunito",
                                              fontStyle: "normal",
                                            }}
                                          >
                                            <Link
                                              to="/device_details"
                                              class="dropdown-item"
                                              href="#"
                                            >
                                              Delete
                                            </Link>
                                          </li>
                                        </ul>
                                      </div>
                                      {/* <img
                                src={HouseingListMange.property_image}
                                alt=""
                              /> */}
                                      {HouseingListMange.property_image ? (
                                        <img
                                          src={HouseingListMange.property_image}
                                          alt=""
                                          style={{
                                            height: "200px",
                                            width: "333px",
                                          }}
                                        />
                                      ) : (
                                        <img
                                          className="ofImage"
                                          src={thumbImg}
                                          alt="img"
                                          style={{
                                            height: "200px",
                                            width: "333px",
                                          }}
                                        />
                                      )}
                                    </div>

                                    <div className="gbl_thumbContent">
                                      <h1
                                        style={{
                                          color: "#1D1D25",
                                          fontSize: "16px",
                                          fontWeight: "600",
                                          fontFamily: "Nunito",
                                        }}
                                      >
                                        {HouseingListMange.title}
                                      </h1>
                                      <h2
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "bold",
                                          fontFamily: "Nunito",
                                          color: "#1D1D25",
                                        }}
                                      >
                                        {HouseingListMange.monthly_rent}
                                      </h2>
                                      <h4
                                        style={{
                                          fontSize: "13px",
                                          fontFamily: "Nunito",
                                          color: "#707070",
                                        }}
                                      >
                                        {HouseingListMange.property_description}
                                      </h4>
                                      <div className="gbl_thumbControlWrapper">
                                        <p
                                          style={{
                                            fontSize: "14px",
                                            fontFamily: "Nunito",
                                            color: "#707070",
                                            marginLeft: "0px",
                                          }}
                                        >
                                          <i>
                                            <img
                                              src={gray_location_icon}
                                              alt=""
                                            />
                                          </i>{" "}
                                          {HouseingListMange.address}
                                        </p>
                                        <Link
                                          style={{
                                            fontSize: "14px",
                                            fontFamily: "Nunito",
                                            color: "#FFFFFF",
                                            fontWeight: "bold",
                                          }}
                                          to="#"
                                          data-bs-toggle="offcanvas"
                                          data-bs-target="#offcanvasRight"
                                          aria-controls="offcanvasRight"
                                        >
                                          I'm Interested
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*  */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default HouseManage;

import React, { useContext, useState, useEffect } from 'react'
import fillter_icon from '../../assets/images/fillter_icon.svg'
import Header from '../../components/Header'
import Sidebar from '../../components/Sidebar'
import {
  Link,
  useLocation,
  NavLink,
  useParams,
  useNavigate,
} from 'react-router-dom'
import { AppContext } from '../../context/AppContext'
import OfferDetailsCard from './OfferDetailsCard'
import watch from '../../assets/images/watch.png'
import VoucherDetailsCard from './VoucherDetailsCard'
import ApiConfig from '../../api/ApiConfig'
import LoaderCenter from '../../sharedComponent/LoaderCenter'
import {
  simpleGetCallWithErrorResponse,
  simplePostCall,
} from '../../api/ApiServices'
import swal from 'sweetalert'
import JewelleryDetailsCard from './JewelleryDetailsCard'

const DigitalDetails = () => {
  const params = useParams()
  let id = params.id
  const { customerData, setIdDetails } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  // const [prouctssearch, setProuctsSearch] = useState([]);
  const [products, setProducts] = useState([])
  const [healthproducts, sethProductsDetails] = useState([])
  const navigate = useNavigate()

  const { sidebar, setSidebar } = useContext(AppContext)
  const [filter, setFilter] = useState(
    useLocation().pathname === '/electronics/products',
  )
  const [showElement, setShowElement] = React.useState(true)
  const [counter, setCounter] = useState(1)

  const increase = () => {
    setCounter((count) => count + 1)
  }

  //decrease counter
  const decrease = () => {
    setCounter((count) => count - 1)
  }

  useEffect(() => {
    setTimeout(function () {
      setShowElement(false)
    }, 5000)
  })

  useEffect(() => {
    geDetails()
  }, [])
  const geDetails = () => {
    setLoading(true)

    simpleGetCallWithErrorResponse(
      ApiConfig.DIGITAL_PRODUCT_DETAILS + id,
      JSON.stringify({ ...customerData }),
    ).then((res) => {
      console.log(res)
      setLoading(false)
      sethProductsDetails(res.json.details)
      let product_details = res.json.details

      setIdDetails({
        id: product_details.id,
        offer_subtype: res.json.subtype,
        quantity: counter,
        price: product_details.price,
      })
    })
  }

  const healthAddTocart = () => {
    simplePostCall(
      ApiConfig.ADD_TO_CART_HEAL,
      JSON.stringify({
        subtype: 'Digital',
        product_id: id,
        quantity: counter,
      }),
    )
      .then((res) => {
        console.log(res)
        // navigate('/jewellery/my-cart')
        if (healthproducts.add_to_cart.add_to_cart === true) {
          swal('Product is Already in Your Cart')
        } else {
          swal(res.detal)
        }
        geDetails()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <>
      <div>
        <Sidebar />

        {/* <Header /> */}
        <main id="cxMain" className={sidebar ? 'cx-main cx-active' : 'cx-main'}>
          {loading ? (
            <LoaderCenter />
          ) : (
            <section
              className="content-main"
              style={{
                backgroundColor: 'white',
                borderRadius: '20px',
                height: 'fit-content',
                padding: '20px',
              }}
            >
              <div
                className="globalPages_tabWrapper"
                style={{
                  marginBottom: '20px',
                  width: '100%',
                  marginRight: '0px',
                  marginLeft: '0px',
                }}
              >
                <div
                  className="gb_tabs"
                  style={{
                    minWidth: '200px',
                    overflow: 'auto',
                  }}
                >
                  <div className="tabs">
                    <div>
                      <NavLink
                        onClick={() => setFilter(false)}
                        to={`/digital/voucher`}
                        className={({ isActive }) =>
                          // currentRoute === `/health/voucher` ||
                          // currentRoute === "/fashion/voucher" ||
                          // currentRoute === "/electronics/voucher" ||
                          // currentRoute === "/office/voucher" ||
                          // currentRoute === "/sports/voucher" ||
                          // currentRoute === "/dining/voucher" ||
                          // currentRoute === "/jewellery/voucher" ||
                          // currentRoute === "/digital/voucher"
                          isActive ? 'nav-active' : 'nav-inactive'
                        }
                      >
                        Voucher
                      </NavLink>
                    </div>
                    <div>
                      <NavLink
                        onClick={() => setFilter(true)}
                        to={`/digital/products`}
                        className={({ isActive }) =>
                          // currentRoute === `/health/products` ||
                          // currentRoute === "/fashion/voucher" ||
                          // currentRoute === "/electronics/products" ||
                          // currentRoute === "/office/products" ||
                          // currentRoute === "/sports/products" ||
                          // currentRoute === "/dining/products" ||
                          // currentRoute === "/jewellery/products" ||
                          // currentRoute === "/digital/products"
                          isActive ? 'nav-active' : 'nav-inactive'
                        }
                      >
                        Product
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row  ">
                <div className="col-12 col-lg-6">
                  {/* <VoucherDetailsCard PassData={healthproducts} /> */}
                  {/* <JewelleryDetailsCard PassData={healthproducts} /> */}
                  <div
                    className="of-details-wrapper"
                    style={{
                      backgroundColor: '#F5F5F5',
                      borderRadius: '20px',
                      height: 'fit-content',
                    }}
                  >
                    <div
                      className="of-img"
                      style={{
                        borderRadis: '20px 20px 10px 10px',
                      }}
                    >
                      <img
                        src={healthproducts.image}
                        alt="shirt"
                        style={{
                          width: '100%',
                          height: '260px',
                          objectFit: 'cover',
                          borderRadius: '20px 20px 14px 14px',
                        }}
                      />
                    </div>
                    <div
                      className="detail-content"
                      style={{
                        padding: '20px',
                      }}
                    >
                      <div className="row">
                        <div className="col-6">
                          <span
                            style={{
                              color: '#1D1D25',
                              fontSize: '16px',
                              fontWeight: '600',
                            }}
                          >
                            {healthproducts.product_name}
                          </span>
                        </div>
                        <div className="col-6 d-flex justify-content-end">
                          <label
                            className="heart-check2"
                            style={{ position: 'static' }}
                          >
                            <input class="check" type="checkbox" />
                            <span class="label-text"></span>
                          </label>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-6">
                          <span
                            style={{
                              color: '#707070',
                              fontSize: '14px',
                              fontWeight: 'normal',
                            }}
                          >
                            {healthproducts.price}
                            /-
                          </span>
                        </div>
                        <div className="col-6  d-flex justify-content-end ">
                          <span
                            style={{
                              color: '#707070',
                              fontSize: '14px',
                              fontWeight: 'normal',
                            }}
                          >
                            Quantity
                          </span>
                          <div
                            className="counter ms-3 d-flex align-items-center"
                            style={{
                              columnGap: '10px',
                            }}
                          >
                            <button
                              onClick={decrease}
                              style={{
                                backgroundColor: 'white',
                                border: '1px solid #707070',
                                height: '14px',
                                width: '14px',
                                borderRadius: '7px',
                                color: '#707070',
                                fontSize: '14px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              -
                            </button>
                            <span
                              style={{
                                color: '#21212C',
                                fontSize: '14px',
                                fontWeight: 'bold',
                              }}
                            >
                              {/* {healthproducts.cart_quantity} */}
                              {counter}
                            </span>
                            <button
                              onClick={increase}
                              style={{
                                backgroundColor: 'white',
                                border: '1px solid #707070',
                                height: '14px',
                                width: '14px',
                                borderRadius: '7px',
                                color: '#707070',
                                fontSize: '12px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-12">
                          <h3
                            style={{
                              color: '#21212C',
                              fontSize: '16px',
                              fontWeight: '600',
                            }}
                          >
                            Product Description
                          </h3>
                          <p
                            style={{
                              color: '#707070',
                              fontSize: '12px',
                              fontWeight: '600',
                              marginTop: '10px',
                            }}
                          >
                            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit. Lorem ipsum dolor sit amet,
                consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                nostrud exercitation ullamco laboris nisi. */}
                            {healthproducts.product_description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6 mt-3  mt-lg-0 ">
                  <div
                    className="details last-no-border"
                    style={{
                      backgroundColor: ' #F5F5F5',
                      borderRadius: '20px',
                      padding: '20px',
                      minHeight: '450px',
                    }}
                  >
                    <div className="detail-item-20">
                      <label htmlFor="" className="primary-text">
                        Brands
                      </label>
                      <p
                        className="secondary-text"
                        style={{
                          marginTop: '10px',
                        }}
                      >
                        {healthproducts.brands}
                      </p>
                    </div>
                    <div className="detail-item-20">
                      <label htmlFor="" className="primary-text">
                        Department
                      </label>
                      <p
                        className="secondary-text"
                        style={{
                          marginTop: '10px',
                        }}
                      >
                        {healthproducts.type}
                      </p>
                    </div>
                  </div>

                  <div
                    className="row"
                    style={{
                      marginTop: '20px',
                    }}
                  >
                    <div className="col-12 col-sm-6">
                      <button
                        onClick={() => {
                          healthAddTocart(healthproducts.id)
                        }}
                        className="w-100"
                        style={{
                          backgroundColor: 'transparent',
                          width: '100%',
                          height: '50px',
                          borderRadius: '25px',
                          fontSize: '16px',
                          fontWeight: '600',
                          color: '#00B4BD',
                          border: '2px solid #00B4BD',
                        }}
                      >
                        {healthproducts.add_to_cart &&
                        healthproducts.add_to_cart.add_to_cart == true
                          ? 'Added to cart'
                          : 'Add To Cart'}
                      </button>
                    </div>
                    <div className="col-12 mt-3 mt-sm-0 col-sm-6">
                      <button
                        style={{
                          width: '100%',
                          height: '50px',
                          borderRadius: '25px',
                          fontSize: '16px',
                          fontWeight: '600',
                          backgroundColor: '#00B4BD',
                          border: 'none',
                          color: 'white',
                        }}
                      >
                        <Link
                          style={{
                            display: 'block',
                            height: '100%',
                            backgroundColor: '#00B4BD',
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                          to="/health/PlaceOrder"
                        >
                          {' '}
                          Buy Now
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </main>
      </div>
    </>
  )
}

export default DigitalDetails

import React from "react";
import login_logo from "../assets/images/login_logo.svg";
import { Link } from "react-router-dom";
import email_icon from "../assets/images/email_icon.svg";
import lock_icon from "../assets/images/lock_icon.svg";
import facebook_icon from "../assets/images/facebook_icon.svg";
import gmail_icon from "../assets/images/gmail_icon.svg";
import profile_inputIcon from "../assets/images/profile_inputIcon.svg";
import phone_icon from "../assets/images/phone_icon.svg";
import university_icon from "../assets/images/university_icon.svg";
import scan_icon from "../assets/images/scan_icon.svg"

function Verification1() {
  return (
    <div className="auth_mainWrapper">
      <div className="auth_innerWrapper">
        <div className="auth_twoPane">
          <div className="auth_banner">
            <div className="auth_logoWrapper">
              <div className="auth_logoHolder">
                <img src={login_logo} alt="login logo" />
                <h1>
                  We use tech to create,
                  <br /> connect, and grow
                </h1>
              </div>
            </div>
          </div>
          <div>
            <div className="auth_formWrapper verificationAdj01_height">
              <div className="auth_innerformWrapper verificationAdj01_height">
                {/* <div className="auth_wizardWrapper">
                  <ul>
                    <li>
                      <Link className="auth_completed" to="#">
                        01
                      </Link>
                    </li>

                    <li>
                      <Link className="auth_completed" to="#">
                        02
                      </Link>
                    </li>

                    <li>
                      <Link className="auth_completed" to="#">03</Link>
                    </li>

                    <li>
                      <Link to="#">04</Link>
                    </li>
                  </ul>
                </div> */}

                <div className="verification_wrapper">
                  <h1>
                 Show us 
                    <br /> you're real
                  </h1>

<div className="scannerWrapper">
  <img src={scan_icon} alt="" />
</div>

                  <p>
                    All the available courses under your
                    <br /> selected University
                  </p>
               
               <div className="vari_controlWrapper1">
                    {/* <button>Continue</button> */}
                    <Link to="/verification2">Continue</Link>
               </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Login
<Link to="/dashboard" >dashboard</Link> */}
    </div>
  );
}

export default Verification1;

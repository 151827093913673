import React, { useContext, useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { AppContext } from '../../context/AppContext'
import Sidebar from '../../components/Sidebar'
import Header from '../../components/Header'
import profile_picture from '../../assets/images/profile_picture.jpg'
import createselectAvatar from '../../assets/images/createselectAvatar.png'
import createadd_avatar from '../../assets/images/createadd_avatar.png'
import thumbImg from '../../assets/images/house01.png'
import upload from '../../assets/images/upload_icon.svg'
import swal from 'sweetalert'
import ApiConfig from '../../api/ApiConfig'
import {
  getWithAuthCall,
  getWithAuthCallWithErrorResponse,
  multipartPostCall,
  putMultipartWithAuthCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from '../../api/ApiServices'
import ImageUplodeSell from '../../sharedComponent/ImageUplodeSell'
import LoaderCenter from '../../sharedComponent/LoaderCenter'

function UpdateSell() {
  const params = useParams()
  let id = params.id
  const navigate = useNavigate()
  const { sidebar, setSidebar, customerData } = useContext(AppContext)

  const [loading, setLoading] = useState(false)
  const [sellType, setSellType] = useState([])
  const [detalisSell, setDetalisSell] = useState({
    type: '',
    category: '',
    title: '',
    description: '',
    price: '',
    lat: '',
    long: '',
    image: [],
  })


  const [errMsg, setErrMsg] = useState({
    title: '',
    type: '',
    category: '',
    // title: "",
    description: '',
    price: '',
    image: '',
  })

  const [showElement, setShowElement] = React.useState(true)
  useEffect(() => {
    setTimeout(function () {
      setShowElement(false)
    }, 5000)
  }, [])

  useEffect(() => {
    getSellTypes()
  }, [])

  const getSellTypes = () => {
    getWithAuthCall(
      ApiConfig.CATEGORY_LIST,
      // JSON.stringify({ ...customerData })
    ).then((res) => {
      console.log('res', res)
      setSellType(res.filter)
    })
  }

  const updatedSellData = (e) => {
    if (
      detalisSell.title === '' ||
      detalisSell.category === '' ||
      detalisSell.description === '' ||
      !detalisSell.image[0] ||
      detalisSell.type === '' ||
      detalisSell.price === ''
    ) {
      if (detalisSell.title === '') {
        setErrMsg({ ...errMsg, title: 'Please Enter title.' })
        window.scrollTo(0, 0)
        return
      }
      if (detalisSell.category === '') {
        setErrMsg({ ...errMsg, category: 'Please choose category.' })
        window.scrollTo(0, 0)
        return
      }
      if (detalisSell.description === '') {
        setErrMsg({ ...errMsg, description: 'Please enter description.' })
        window.scrollTo(0, 0)
        return
      }
      if (!detalisSell.image[0]) {
        setErrMsg({ ...errMsg, image: 'Please enter image.' })
        window.scrollTo(0, 0)
        return
      }

      if (detalisSell.type === '') {
        setErrMsg({ ...errMsg, type: 'Please enter type.' })
        window.scrollTo(0, 0)
        return
      }
      if (detalisSell.price === '') {
        setErrMsg({ ...errMsg, price: 'Please enter price.' })
        window.scrollTo(0, 0)
        return
      }
    } else {
      setLoading(true)
      let formData = new FormData()

      formData.append('type', detalisSell.type)
      formData.append('category', detalisSell.category)
      formData.append('title', detalisSell.title)
      formData.append('description', detalisSell.description)
      formData.append('price', detalisSell.price)
      formData.append('lat', detalisSell.lat)
      formData.append('long', detalisSell.long)
      // formData.append("image", detalisSell.image[0]);
      detalisSell.image &&
        detalisSell.image.length &&
        detalisSell.image.map((data) => {
          formData.append('image', data)
        })


      putMultipartWithAuthCallWithErrorResponse(
        ApiConfig.UPDATE_ITEM + id + '/',
        formData,
      )
        .then((res) => {
          setLoading(false)
          swal(' Update Property Successfully')

          navigate('/sell_list/all')
        })
        .catch((err) => console.log(err))
      setLoading(false)
    }
  }

  // const updatedSellData = (e) => {
  //   console.log(updatedSellData);
  //   setLoading(true);
  //   let details = JSON.stringify({
  //     //  id: customerData.id,
  //     type: detalisSell.type,
  //     category: detalisSell.category,
  //     title: detalisSell.title,
  //     description: detalisSell.description,
  //     price: detalisSell.price,
  //     //  image: detalisSell.image,
  //     lat: detalisSell.lat,
  //     long: detalisSell.long,
  //   });

  //   const formData = new FormData();
  //   formData.append("details", details);
  //   detalisSell.image &&
  //     detalisSell.image.length &&
  //     detalisSell.image.map((data) => {
  //       formData.append("image", data);
  //     });
  //   console.log(formData);

  //   putMultipartWithAuthCallWithErrorResponse(
  //     ApiConfig.UPDATE_ITEM + id + "/",
  //     formData
  //   )
  //     .then((res) => {
  //       console.log(res);
  //       swal(res.detail);
  //       setLoading(false);

  //       //  navigate("/sell_list/all");
  //     })
  //     .catch((err) => console.log(err));
  // };

  const removeImage = (index) => {
    let image = detalisSell.image

    image = image.filter((img) => image.indexOf(img) != index)

    setDetalisSell({ ...detalisSell, image: image })
  }

  useEffect(() => {
    getCreateDetails()
  }, [])

  const getCreateDetails = () => {

    setLoading(true)
    getWithAuthCallWithErrorResponse(
      ApiConfig.GET_ITEM_LIST + id + '/',
      // JSON.stringify({ ...customerData })
    )
      .then((res) => {
        setLoading(false)
        console.log(res)

        let user_profile = res.json

        setDetalisSell({
          type: user_profile.item_type,
          category: user_profile.category,
          title: user_profile.title,
          description: user_profile.about,
          price: user_profile.price,
          image: user_profile.image,
          lat: user_profile.lat,
          long: user_profile.long,
        })

      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }

  return (
    <div>
      <Sidebar />

      {/* <Header /> */}
      <main id="cxMain" className={sidebar ? 'cx-main cx-active' : 'cx-main'}>
        <section
          className="content-main"
          style={{
            backgroundColor: 'white',
            borderRadius: '24px',
            padding: '20px',
            height: 'fit-content',
          }}
        >
          <p className="fo-heading" style={{ marginBottom: '10px' }}>
            Create an item
          </p>
          <div className="row">
            {loading && <LoaderCenter />}
            <div className="col-6">
              <label htmlFor="">Title</label>
              <input
                value={detalisSell.title}
                type="text"
                name="text"
                id="text"
                className="ce-input"
                placeholder="Enter title here"
                onChange={(e) => {
                  setErrMsg({ ...errMsg, title: '' })

                  setDetalisSell({
                    ...detalisSell,
                    title: e.target.value,
                  })
                }}
              />
              <small
                style={{
                  color: 'red',
                }}
              >
                {errMsg.title}
              </small>
            </div>
            {/* {errMsg.title.length > 0 && (
              <div className="text-danger">
                <span> {errMsg.title}</span>
              </div>
            )} */}
            <div className="col-lg-6">
              <div className="uploadPP_inputTextRow usalabel">
                <label class="form-label">category</label>
                <div className="upp_selectWrapper ">
                  <select
                    onChange={(e) => {
                      setErrMsg({ ...errMsg, category: '' })

                      setDetalisSell({
                        ...detalisSell,
                        category: e.target.value,
                      })
                    }}
                    className="Property-Type-midal form-select  ce-input"
                    aria-label="Default select example"
                    style={{
                      height: '40px',
                      backgroundColor: '#FBF8F8',
                      margin: '0',
                    }}
                  >
                    {sellType &&
                      sellType.length &&
                      sellType.map((sellType, index) => {
                        if (sellType.category)
                          return (
                            <option
                              selected
                              key={'delivery' + index}
                              value={sellType.category}
                            >
                              {sellType.category.charAt(0).toUpperCase() +
                                sellType.category.slice(1)}
                            </option>
                          )
                      })}
                  </select>
                  <small
                    style={{
                      color: 'red',
                    }}
                  >
                    {errMsg.category}
                  </small>
                  {/* {errMsg.category.length > 0 && (
                    <div className="text-danger">
                      <span> {errMsg.category}</span>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <label htmlFor="">Description</label>
              <textarea
                name="text"
                value={detalisSell.description}
                id="text"
                cols="30"
                rows="10"
                className="ce-input"
                placeholder="Describe your product"
                onChange={(e) => {
                  setErrMsg({ ...errMsg, description: '' })

                  setDetalisSell({
                    ...detalisSell,
                    description: e.target.value,
                  })
                }}
                style={{
                  height: '100px',
                  padding: '10px 20px',
                }}
              />
              <small
                style={{
                  color: 'red',
                }}
              >
                {errMsg.description}
              </small>
            </div>
            {/* {errMsg.description.length > 0 && (
              <div className="text-danger">
                <span> {errMsg.description}</span>
              </div>
            )} */}
          </div>
          <div className="row  d-flex align-items-center">
            <div className="col-12 col-sm-6 col-lg-2 custom-resp">
              <div
                className=""
                style={{ height: '148px', margin: '0', padding: '0' }}
              >
                {/* <input
                  type="file"
                  name="name"
                  id="name"
                  className=""
                  placeholder="Enter event Name"
                />
                <div className="custom-icon-image d-flex flex-column align-items-center">
                  <img src={upload} alt="upload" className="upload-icon" />
                  <p className="uimage">Upload Image</p>
                </div> */}
                <ImageUplodeSell
                  setFile={(files) => {
                    setErrMsg({ ...errMsg, image: '' })

                    setDetalisSell({
                      ...detalisSell,
                      image: [...files],
                    })
                  }}
                />
                <small
                  style={{
                    color: 'red',
                  }}
                >
                  {errMsg.image}
                </small>
                {/* {errMsg.image.length > 0 && (
                  <div className="text-danger">
                    <span> {errMsg.image}</span>
                  </div>
                )} */}
              </div>
            </div>

            {detalisSell &&
              detalisSell.image.map((img, index) => {
                return (
                  <div className="col-12 col-sm-6 col-lg-2   mt-3 mt-sm-0 ">
                    <div
                      className="uploaded-image uploaded-reps"
                      key={'img' + index}
                      style={{
                        maxWidth: '148px',
                      }}
                    >
                      <img
                        width={148}
                        height="148px"
                        className="img"
                        src={img.name ? URL.createObjectURL(img) : img}
                        alt=""
                      />
                      <a
                        style={{
                          position: 'absolute',
                          top: '10px',
                          right: '10px',
                          width: '34px',
                          height: '34px',
                          backgroundColor: 'white',
                          borderRadius: '10px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        href="#"
                        className=""
                      >
                        <i
                          onClick={() => removeImage(index)}
                          class="fa-solid fa-xmark"
                        ></i>
                      </a>
                    </div>
                  </div>
                )
              })}

            <div className="col-12 col-sm-6 col-xl-3">
              <label htmlFor="name" style={{ marginTop: '20px' }}>
                Type
              </label>
              <select
                value={detalisSell.type}
                onChange={(e) => {
                  setErrMsg({ ...errMsg, type: '' })

                  setDetalisSell({
                    ...detalisSell,
                    type: e.target.value,
                  })
                }}
                class="form-select ce-input"
                aria-label="Default select example"
              >
                <option value="Sell">Sell</option>
                <option value="Swap">Swap</option>
              </select>
              <small
                style={{
                  color: 'red',
                }}
              >
                {errMsg.type}
              </small>
              {/* {errMsg.type.length > 0 && (
                <div className="text-danger">
                  <span> {errMsg.type}</span>
                </div>
              )} */}
            </div>
            <div className="col-12 col-sm-6 col-xl-3">
              <label htmlFor="name" style={{ marginTop: '20px' }}>
                Item Price
              </label>
              <input
                type="text"
                name="numb"
                id="text"
                value={detalisSell.price}
                className="ce-input"
                placeholder=" £ Price"
                onChange={(e) => {
                  setErrMsg({ ...errMsg, price: '' })

                  setDetalisSell({
                    ...detalisSell,
                    price: e.target.value,
                  })
                }}
                style={{
                  textAlign: 'center',
                }}
              />
            </div>
            <small
              style={{
                color: 'red',
              }}
            >
              {errMsg.price}
            </small>
            {/* {errMsg.price.length > 0 && (
              <div className="text-danger">
                <span> {errMsg.price}</span>
              </div>
            )} */}
          </div>
          <div
            className="row"
            style={{ marginTop: '60px', marginBottom: '20px' }}
          >
            <div className="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
              <button className="cancel">Cancel</button>
            </div>
            <div className="col-12 col-sm-6 d-flex align-items-center justify-content-center mt-3 mt-sm-0 justify-content-sm-start  ">
              <button className="create" onClick={updatedSellData}>
                {' '}
                Update Item
              </button>
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}

export default UpdateSell

import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import "./OffersCards.scss";
import stationary from "../../assets/images/stationary.svg";
import entertainment from "../../assets/images/entertainment.svg";
import pubs from "../../assets/images/pubs_clubs.svg";
import dining from "../../assets/images/dining.svg";
import sports from "../../assets/images/sports.svg";
import events from "../../assets/images/events.svg";
import health from "../../assets/images/health_beauty.svg";
import clothing from "../../assets/images/clothing.svg";
import electronic from "../../assets/images/electronics.svg";
import gift from "../../assets/images/gifts.svg";
function OffersCard() {
  const { sidebar, setSidebar } = useContext(AppContext);
  const currentRoute = useLocation().pathname;

  const [showElement, setShowElement] = React.useState(true);
  useEffect(() => {
    setTimeout(function () {
      setShowElement(false);
    }, 5000);
  }, []);
  return (
    <>
      <Sidebar />

      {/* <Header /> */}
      <main id="cxMain" className={sidebar ? "cx-main cx-active" : "cx-main"}>
        <section
          className="content-main"
          style={{
            backgroundColor: "white",
            borderRadius: "20px",
            height: "fit-content",
            padding: "10px 20px",
          }}
        >
          <div class="row">
            <div class="col-12 col-sm-6 col-lg-4 col-xl-3 col-xxl-2">
              <Link
                to="/health/voucher"
                className={
                  currentRoute === "/offers_listing" ? "ct_active" : ""
                }
              >
                <div class="card cardSize ">
                  <div class="card-body">
                    <div className="cardText">
                      <img
                        src={health}
                        class="img-responsive rounded mx-auto d-block"
                        alt=""
                      />
                      <p class="text-center ">Health & beauty</p>
                    </div>
                  </div>
                </div>
              </Link>{" "}
            </div>
            <div class="col-12 col-sm-6 col-lg-4 col-xl-3 col-xxl-2">
              <Link
                to="/fashion/voucher"
                className={
                  currentRoute === "/offers_listing" ? "ct_active" : ""
                }
              >
                <div class="card  cardSize">
                  <div class="card-body">
                    <div className="cardText">
                      <img
                        src={clothing}
                        class="img-responsive rounded mx-auto d-block"
                        alt=""
                      />
                      <p class="text-center ">Fashion</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div class="col-12 col-sm-6 col-lg-4 col-xl-3 col-xxl-2">
              <Link
                to="/electronics/voucher"
                className={
                  currentRoute === "/offers_listing" ? "ct_active" : ""
                }
              >
                <div class="card  cardSize">
                  <div class="card-body">
                    <div className="cardText">
                      <img
                        src={electronic}
                        class="img-responsive rounded mx-auto d-block"
                        alt=""
                      />
                      <p class="text-center ">Electronics</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div class="col-12 col-sm-6 col-lg-4 col-xl-3 col-xxl-2">
              <Link
                to="/office/voucher"
                className={
                  currentRoute === "/offers_listing" ? "ct_active" : ""
                }
              >
                <div class="card  cardSize">
                  <div class="card-body">
                    <div className="cardText">
                      <img
                        src={stationary}
                        class="img-responsive rounded mx-auto d-block"
                        alt=""
                      />
                      <p class="text-center ">Office, Books & Stationery</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div class="col-12 col-sm-6 col-lg-4 col-xl-3 col-xxl-2">
              <Link
                to="/sports/voucher"
                className={
                  currentRoute === "/offers_listing" ? "ct_active" : ""
                }
              >
                <div class="card  cardSize">
                  <div class="card-body">
                    <div className="cardText">
                      <img
                        src={sports}
                        class="img-responsive rounded mx-auto d-block"
                        alt=""
                      />
                      <p class="text-center ">Sports, Hobbies & Leisure</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div class="col-12 col-sm-6 col-lg-4 col-xl-3 col-xxl-2">
              <Link
                to="/dining/voucher"
                className={
                  currentRoute === "/offers_listing" ? "ct_active" : ""
                }
              >
                <div class="card cardSize">
                  <div class="card-body">
                    <div className="cardText">
                      <img
                        src={dining}
                        class="img-responsive rounded mx-auto d-block"
                        alt=""
                      />
                      <p class="text-center ">Dining</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div class="col-12 col-sm-6 col-lg-4 col-xl-3 col-xxl-2">
              <Link
                to="/jewellery/voucher"
                className={
                  currentRoute === "/offers_listing" ? "ct_active" : ""
                }
              >
                <div class="card cardSize">
                  <div class="card-body">
                    <div className="cardText">
                      <img
                        src={gift}
                        class="img-responsive rounded mx-auto d-block"
                        alt=""
                      />
                      <p class="text-center ">Jewellery & Gifts</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div class="col-12 col-sm-6 col-lg-4 col-xl-3 col-xxl-2">
              <Link
                to="/digital/voucher"
                className={
                  currentRoute === "/offers_listing" ? "ct_active" : ""
                }
              >
                <div class="card  cardSize">
                  <div class="card-body">
                    <div className="cardText">
                      <img
                        src={entertainment}
                        class="img-responsive rounded mx-auto d-block"
                        alt=""
                      />
                      <p class="text-center ">Digital</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default OffersCard;

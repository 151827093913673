import React, { useContext, useState, useEffect } from 'react'
import { NavLink, useLocation, Link } from 'react-router-dom'
import thumbImg from '../../assets/images/watch.png'
import back from '../../assets/images/back.svg'
import Tabs from './Tabs'
import ApiConfig from '../../api/ApiConfig'
import { AppContext } from '../../context/AppContext'
import LoaderCenter from '../../sharedComponent/LoaderCenter'
import { simpleGetCallWithErrorResponse } from '../../api/ApiServices'

const Accordion = ({ title, children }) => {
  const [isOpen, setOpen] = React.useState(false)

  return (
    <div className="accordion-wrapper">
      <div
        className={`accordion-title ${isOpen ? 'open' : ''}`}
        onClick={() => setOpen(!isOpen)}
      >
        {title}
      </div>
      <div className={`accordion-item ${!isOpen ? 'collapsed' : ''}`}>
        <div className="accordion-content">{children}</div>
      </div>
    </div>
  )
}

const Orders = () => {
  const [oderDetails, setOderDetails] = useState([])
  const { customerData } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    getOrder()
  }, [])

  const getOrder = () => {
    setLoading(true)
    simpleGetCallWithErrorResponse(
      ApiConfig.ORDER_LIST,

      JSON.stringify({ ...customerData }),
    ).then((res) => {
      console.log(res)
      setLoading(false)
      setOderDetails(res.json.orders)
    })
  }
  return (
    <>
      {loading ? (
        <LoaderCenter />
      ) : (
        <div className="row row-gap-20">
          {oderDetails && oderDetails.length > 0 ? (
            oderDetails.map((oderDetailsitem, index) => {
           
              return (
                <div className="col-12 col-sm-6  col-lg-4 col-xxl-3">
                  <div className="gbl_thumbWrapper">
                    <div className="img_thumbWrapper">
                      {oderDetailsitem.order_image ? (
                        <img
                          src={oderDetailsitem.order_image}
                          height="158px"
                          alt=""
                          style={{
                            objectFit: 'cover',
                          }}
                        />
                      ) : (
                        <img
                          src={thumbImg}
                          height="158px"
                          alt=""
                          style={{
                            objectFit: 'cover',
                          }}
                        />
                      )}
                      {/* <img
                        src={oderDetailsitem.order_image}
                        height="158px"
                        alt=""
                        style={{
                          objectFit: 'cover',
                        }}
                      /> */}
                    </div>

                    <div className="gbl_thumbContent">
                      <h1
                        style={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: 'hsla(240, 12%, 13%, 1)',
                          marginTop: '5px',
                          letterSpacing: '0',
                          font: 'normal normal 600 16px/22px Nunito',
                          opacity: '0px',
                        }}
                      >
                        {oderDetailsitem.order_title}
                      </h1>
                      <p
                        style={{
                          color: 'var(--unnamed-color-707070)',
                          font: 'normal normal normal 14px/19px Nunito',
                          letterSpacing: '0px',
                          color: 'hsla(0, 0%, 44%, 1)',
                          opacity: '1px',
                          marginTop: '12px',
                        }}
                      >
                        {oderDetailsitem.order_status}
                      </p>
                      <Link
                        to={`/health/order-details/${oderDetailsitem.id}`}
                        // to="/health/order-details"
                      >
                        <img
                          style={{
                            marginTop: '-25px',
                            float: 'right',
                          }}
                          src={back}
                          alt=""
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              )
            })
          ) : (
            <p
              style={{
                fontSize: '20px',
                marginTop: '200px',
              }}
              className=" text-center justify-content-center align-items-center"
            >
              Oops! It doesn't look like there are any Event in your area.
              <br /> Why don't you try expanding your search criteria?
            </p>
          )}
        </div>
      )}
    </>
  )
}

export default Orders

import React, { useContext, useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { AppContext } from '../../context/AppContext'
import { ATMCard } from 'atm-card-react'
import mastercard from '../../assets/images/mastercard.svg'
import graycard from '../../assets/images/graycard.svg'
import swal from 'sweetalert'
import LoaderCenter from '../../sharedComponent/LoaderCenter'
import logo from '../../assets/images/master-card.png'
import {
  simpleGetCallWithErrorResponse,
  multipartPostCall,
  postWithAuthCallWithErrorResponse,
} from '../../api/ApiServices'
import ApiConfig from '../../api/ApiConfig'
import Modal from 'react-bootstrap/Modal'

function PaymentPage() {
  const navigate = useNavigate()
  const {
    cartchecked,
    adreeschecked,
    customerData,
    idDetails,
    totalPrice,
    totalItem,
  } = useContext(AppContext)
  console.log(idDetails)
  const currentRoute = useLocation().pathname
  const [loading, setLoading] = useState(false)
  const [cardDetails, setCardDetails] = useState('')
  // const [selectedProduct, setSelectedProduct] = useState([])
  // console.log('selectedProduct', selectedProduct)
  const [selectedCard, setSelectedCard] = useState('')
  console.log(selectedCard);
  const [ordersCart, setOrderCart] = useState([])
  const [adressCart, setAdressCart] = useState('')
  const [atmCart, setAtmCart] = useState([])
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [addAtmCard, setAtmCard] = useState({
    card_name: '',
    mm: '',
    yy: '',
    cvv: '',
    card_label: '',
    card_number: '',
  })

  const [errMsg, setErrMsg] = useState({
    card_name: '',
    mm: '',
    yy: '',
    cvv: '',
    card_label: '',
    card_number: '',
  })

  const [showElement, setShowElement] = React.useState(true)
  useEffect(() => {
    setTimeout(function () {
      setShowElement(false)
    }, 5000)
  }, [])

  useEffect(() => {
    getaAtmAddress()
  }, [])

  const getaAtmAddress = () => {
    setLoading(true)
    simpleGetCallWithErrorResponse(
      ApiConfig.ATM_CARD_LISTING,

      JSON.stringify({ ...customerData }),
    ).then((res) => {
      console.log(res)
      setLoading(false)
      setCardDetails(res.json.card_list)
    })
  }
  useEffect(() => {
    let cartids = cartchecked.map((cartData) => {
      return cartData.cart_id
    })

    let addressids =
      adreeschecked &&
      adreeschecked.map((adreesitem) => {
        return adreesitem.id
      })

    let atmId =
      atmCart &&
      atmCart.map((atmitem) => {
      
        return atmitem.id
      })
    setSelectedCard(atmId)
    setOrderCart(cartids)
    setAdressCart(addressids[0])
  }, [cartchecked, atmCart, adreeschecked])

  const Orderplace = (id) => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.PLACE_ORDER,
      JSON.stringify({
        cart_items: ordersCart,
        address_id: adressCart.toString(),
        card_id: selectedCard,
      }),
    )
      .then((res) => {
        console.log(res)
        swal(res.json.message)
        if (res.json.result === true) {
          // navigate('/health/orders')
          handlePayment(res.order_id)
        } else {
          swal('Server Error')
        }

        // if (res.json == 500) {
        //   swal(
        //     // "Registration successfully done. Admin will verify account. Please login after 1 hour"
        //     'User Does not Exist! Please Registration ',
        //   )
        // }
      })
      .catch((err) => {
        setLoading(false)

        console.log(err)
      })
  }

  const buynow = (id) => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.BUY_NOW,
      JSON.stringify({
        product_id: idDetails.id.toString(),
        subtype: idDetails.offer_subtype,
        quantity: idDetails.quantity.toString(),
        address_id: adressCart.toString(),
        card_id: selectedCard.toString(),
      }),
    )
      .then((data) => {
        console.log(data)
        
        if (data.json.result === true) {
          // swal(data.json.message)
          handlePayment(idDetails.id.toString())
          setTimeout(() => {
            navigate('/health/orders')
          }, 1000);
        
        } else {
          swal(data.json.message)
        }
      })
      .catch((err) => {
        setLoading(false)

        console.log(err)
      })
  }

  const AddCreateCard = (e) => {
    if (
      addAtmCard.card_name === '' ||
      addAtmCard.mm === '' ||
      addAtmCard.yy === '' ||
      addAtmCard.cvv === ' ' ||
      addAtmCard.card_label === '' ||
      addAtmCard.card_number === ''
    ) {
      if (!addAtmCard.card_name) {
        setErrMsg({
          ...errMsg,
          card_name: 'Please Enter card name.',
        })
        window.scrollTo(0, 0)
        return
      }

      if (addAtmCard.mm === '') {
        setErrMsg({
          ...errMsg,
          mm: 'Please Enter Monthe ',
        })
        window.scrollTo(0, 0)
        return
      }
      if (addAtmCard.yy === '') {
        setErrMsg({
          ...errMsg,
          yy: 'Please Enter Pin Year  ',
        })
        window.scrollTo(0, 0)
        return
      }
      if (addAtmCard.card_number === '') {
        setErrMsg({
          ...errMsg,
          card_number: 'Please Enter Card Number  ',
        })
        window.scrollTo(0, 0)
        return
      }
      if (addAtmCard.cvv === '') {
        setErrMsg({
          ...errMsg,
          cvv: 'Please Enter Cvv  ',
        })
        window.scrollTo(0, 0)
        return
      }
      if (addAtmCard.card_label === '') {
        setErrMsg({
          ...errMsg,
          card_label: 'Please Enter card label  ',
        })
        window.scrollTo(0, 0)
        return
      }
    } else {
      setLoading(true)
      let formData = new FormData()
      formData.append('card_name', addAtmCard.card_name)
      formData.append('mm', addAtmCard.mm)
      formData.append('yy', addAtmCard.yy)
      formData.append('card_number', addAtmCard.card_number)
      formData.append('cvv', addAtmCard.cvv)
      formData.append('card_label', addAtmCard.card_label)

      multipartPostCall(ApiConfig.ADD_ATM, formData)
        .then((res) => {
          handleClose()
          getaAtmAddress()

          setLoading(false)
          swal('New Card Added Successfully')
        })
        .catch((err) => {
          setLoading(false)

          console.log(err)
        })
    }
  }

  const handleBuy = () => {
    if (idDetails.offer_subtype) {
      buynow()
    } else {
      Orderplace()
    }
  }



  // Payment page  / / / / / 

  const handlePayment = async (id) => {
  
    // setIndex(index + 1)
    let newRequestBody = JSON.stringify({
     
      order_id: id,
    })
    postWithAuthCallWithErrorResponse(ApiConfig.PAYMENT_API, newRequestBody)
      .then((data) => {
          console.log(data)
          window.open(data.json.url)
        
          
          
        
      })
      .catch((error) => {
        console.log('api response', error)
        swal('Please wait or try after sometime..')
      })
  }





  return (
    <>
      {loading ? (
        <LoaderCenter />
      ) : (
        <div
          className="row "
          style={{
            maxWidth: '685px',
            margin: '0 auto',
          }}
        >
          <h6
            style={{
              color: 'black',
              marginBottom: '10px',
              letterSpacing: '0px',
              font: 'normal normal bold 20px/27px Nunito',
            }}
          >
            Choose Your Credit / Debit Card
          </h6>

          {cardDetails && cardDetails.length > 0 ? (
            cardDetails.map((cardDetailsAll, index) => {
             
              return (
                <div className="col-12 col-sm-6 col-xl-4 p-2 ip-wrap">
                  <input
                    type="radio"
                    name="atm"
                    id={`${index}`}
                    className="atm-radio"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setAtmCart([...atmCart, cardDetailsAll])
                      } else {
                        setAtmCart(
                          atmCart.filter(
                            (data) => data.id !== cardDetailsAll.id,
                          ),
                        )
                      }
                    }}
                  />

                  <label
                    className={
                      'atm-card d-flex flex-column justify-content-between'
                    }
                    id="atm-card"
                    htmlFor={`${index}`}
                  >
                    <div className="namee-logo d-flex justify-content-between">
                      <span
                        style={{
                          color: 'white',

                          fontSize: '16px',

                          fontWeight: '600',
                        }}
                      >
                        {cardDetailsAll.card_label}
                      </span>

                      <img
                        alt=""
                        src={logo}
                        style={{
                          width: '36',

                          height: '32px',

                          objectFit: 'contain',
                        }}
                      />
                    </div>

                    <div className="person-number">
                      <p
                        style={{
                          color: 'white',

                          fontSize: '14px',

                          fontWeight: '600',
                        }}
                      >
                        {cardDetailsAll.card_name}
                      </p>

                      <p
                        style={{
                          color: 'white',

                          fontSize: '14px',

                          fontWeight: '600',
                        }}
                      >
                        {cardDetailsAll.card_number}
                      </p>
                    </div>
                  </label>
                </div>
              )
            })
          ) : (
            <p
              style={{
                fontSize: '20px',
                marginTop: '200px',
              }}
              className=" text-center justify-content-center align-items-center"
            >
              Oops! It doesn't look like there are any Event in your area.
              <br /> Why don't you try expanding your search criteria?
            </p>
          )}

          <div className="col-12 mt-3">
            <button
              // data-bs-toggle="modal"
              // data-bs-target="#exampleModal"
              onClick={handleShow}
              style={{
                height: '50px',
                border: '1px solid #00B4BD',
                borderRadius: '10px',
                backgroundColor: 'transparent',
                width: '100%',
                color: '#00B4BD',
                fontSize: '16px',
                fontWeight: '600',
              }}
            >
              + Add New Card
            </button>
            {/* <div
              class="modal fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div
                class="modal-dialog modal-dialog-centered"
                style={{
                  width: '80%',
                  maxWidth: '393px',
                }}
              > */}

            <Modal
              show={show}
              onHide={handleClose}
              contentClassName="myContent"
            >
              <Modal.Body
                // class="modal-content"
                style={{
                  // maxWidth: '393px',
                  padding: '20px',
                  backgroundColor: '#F5F5F5',
                  borderRadius: '20px',
                }}
              >
                <div>
                  <div
                    class="modal-header"
                    style={{ padding: '0', borderBottom: 'none' }}
                  >
                    <h5 class="modal-title" id="exampleModalLabel">
                      Enter Card Details
                    </h5>
                  </div>
                  <div className="add-input d-flex flex-column mt-3">
                    <label
                      htmlFor=""
                      className="add-label"
                      style={{
                        color: '#707070',
                        fontSize: '14px',
                        fontWeight: 'normal',
                      }}
                    >
                      Card Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name "
                      className="add-input"
                      placeholder="Enter Card Name"
                      style={{
                        height: '50px',
                        backgroundColor: 'white',
                        border: '1px solid #E1E1E1',
                        padding: '0 20px',
                        color: '#1D1D25',
                        fontSize: '16px',
                        fontWeight: '600',
                        borderRadius: '10px',
                      }}
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, card_name: '' })

                        setAtmCard({
                          ...addAtmCard,
                          card_name: e.target.value,
                        })
                      }}
                    />
                  </div>
                  <small
                    style={{
                      color: 'red',
                    }}
                  >
                    {errMsg.card_name}
                  </small>
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <div className="add-input d-flex flex-column mt-3">
                        <label
                          htmlFor=""
                          className="add-label"
                          style={{
                            color: '#707070',
                            fontSize: '14px',
                            fontWeight: 'normal',
                          }}
                        >
                          MM
                        </label>
                        <input
                          type="number"
                          name="name"
                          id="name "
                          className="add-input"
                          placeholder="Enter Card Name"
                          style={{
                            height: '50px',
                            backgroundColor: 'white',
                            border: '1px solid #E1E1E1',
                            padding: '0 20px',
                            color: '#1D1D25',
                            fontSize: '16px',
                            fontWeight: '600',
                            borderRadius: '10px',
                          }}
                          onChange={(e) => {
                            setErrMsg({ ...errMsg, mm: '' })

                            setAtmCard({
                              ...addAtmCard,
                              mm: e.target.value,
                            })
                          }}
                        />
                      </div>
                      <small
                        style={{
                          color: 'red',
                        }}
                      >
                        {errMsg.mm}
                      </small>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="add-input d-flex flex-column mt-3">
                        <label
                          htmlFor=""
                          className="add-label"
                          style={{
                            color: '#707070',
                            fontSize: '14px',
                            fontWeight: 'normal',
                          }}
                        >
                          YY
                        </label>
                        <input
                          type="number"
                          name="name"
                          id="name "
                          className="add-input"
                          placeholder="Enter Card Name"
                          style={{
                            height: '50px',
                            backgroundColor: 'white',
                            border: '1px solid #E1E1E1',
                            padding: '0 20px',
                            color: '#1D1D25',
                            fontSize: '16px',
                            fontWeight: '600',
                            borderRadius: '10px',
                          }}
                          onChange={(e) => {
                            setErrMsg({ ...errMsg, yy: '' })

                            setAtmCard({
                              ...addAtmCard,
                              yy: e.target.value,
                            })
                          }}
                        />
                      </div>
                      <small
                        style={{
                          color: 'red',
                        }}
                      >
                        {errMsg.yy}
                      </small>
                    </div>
                  </div>
                  <div className="add-input d-flex flex-column mt-3">
                    <label
                      htmlFor=""
                      className="add-label"
                      style={{
                        color: '#707070',
                        fontSize: '14px',
                        fontWeight: 'normal',
                      }}
                    >
                      Card Number
                    </label>
                    <input
                      type="phone "
                      name="name"
                      id="name "
                      className="add-input"
                      placeholder="Enter Card Number"
                      style={{
                        height: '50px',
                        backgroundColor: 'white',
                        border: '1px solid #E1E1E1',
                        padding: '0 20px',
                        color: '#1D1D25',
                        fontSize: '16px',
                        fontWeight: '600',
                        borderRadius: '10px',
                      }}
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, card_number: '' })

                        setAtmCard({
                          ...addAtmCard,
                          card_number: e.target.value,
                        })
                      }}
                    />
                  </div>
                  <small
                    style={{
                      color: 'red',
                    }}
                  >
                    {errMsg.card_number}
                  </small>
                  <div className="add-input d-flex flex-column mt-3">
                    <label
                      htmlFor=""
                      className="add-label"
                      style={{
                        color: '#707070',
                        fontSize: '14px',
                        fontWeight: 'normal',
                      }}
                    >
                      Card CVV
                    </label>
                    <input
                      type="phone "
                      name="name"
                      id="name "
                      className="add-input"
                      placeholder="Enter CVV"
                      style={{
                        height: '50px',
                        backgroundColor: 'white',
                        border: '1px solid #E1E1E1',
                        padding: '0 20px',
                        color: '#1D1D25',
                        fontSize: '16px',
                        fontWeight: '600',
                        borderRadius: '10px',
                      }}
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, cvv: '' })

                        setAtmCard({
                          ...addAtmCard,
                          cvv: e.target.value,
                        })
                      }}
                    />
                  </div>
                  <small
                    style={{
                      color: 'red',
                    }}
                  >
                    {errMsg.cvv}
                  </small>
                  <div className="add-input d-flex flex-column mt-3">
                    <label
                      htmlFor=""
                      className="add-label"
                      style={{
                        color: '#707070',
                        fontSize: '14px',
                        fontWeight: 'normal',
                      }}
                    >
                      Card Label(optional)
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name "
                      className="add-input"
                      placeholder="Enter card label"
                      style={{
                        height: '50px',
                        backgroundColor: 'white',
                        border: '1px solid #E1E1E1',
                        padding: '0 20px',
                        color: '#1D1D25',
                        fontSize: '16px',
                        fontWeight: '600',
                        borderRadius: '10px',
                      }}
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, card_label: '' })

                        setAtmCard({
                          ...addAtmCard,
                          card_label: e.target.value,
                        })
                      }}
                    />
                  </div>
                  <small
                    style={{
                      color: 'red',
                    }}
                  >
                    {errMsg.card_label}
                  </small>
                  <div className="col-12 mt-3 d-flex justify-content-center">
                    <button
                      onClick={AddCreateCard}
                      // data-bs-dismiss="modal"
                      // aria-label="Close"
                      style={{
                        width: '158px',
                        height: '50px',
                        backgroundColor: '#00B4BD',

                        border: 'none',
                        borderRadius: '25px',
                        color: '#FFFFFF',
                        fontSize: '14px',
                        fontWeight: '600',
                      }}
                    >
                      <Link
                        to="#"
                        style={{
                          display: 'block',
                          height: '100%',
                          color: 'white',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        Add Card
                      </Link>
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>

          {/* </div>
          </div> */}
          <div
            className="col-12"
            style={{
              marginTop: '20px',
            }}
          >
            <div
              className="gbl_thumbWrapper"
              style={{
                borderRadius: '10px',
              }}
            >
              <div className="gbl_thumbContent">
                <div
                  style={{
                    font: 'normal normal 600 16px/22px Nunito',
                    letterSpacing: '0px',
                    textAlign: 'left',
                    color: '#1D1D25',
                    // marginLeft: '-18px',
                    marginTop: '18px',
                  }}
                  class="card-header bg-transparent"
                >
                  Price Details
                </div>
                <div className="d-flex justify-content-between">
                  <p
                    style={{
                      color: 'var(--unnamed-color-707070)',
                      font: 'normal normal normal 14px/19px Nunito',
                      letterSpacing: '0px',
                      color: 'hsla(0, 0%, 44%, 1)',
                      opacity: '1px',
                      marginTop: '12px',
                    }}
                  >
                    Price ({totalItem.length} items)
                  </p>
                  <p
                    style={{
                      color: '#21212C',
                      font: 'normal normal bold 14px/19px Nunito',
                      letterSpacing: '0px',
                      opacity: '1px',
                      marginTop: '12px',
                    }}
                  >
                    {idDetails.price ? (
                      <> ${idDetails.price}</>
                    ) : (
                      <> ${totalPrice} </>
                    )}
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p
                    style={{
                      color: 'var(--unnamed-color-707070)',
                      font: 'normal normal normal 14px/19px Nunito',
                      letterSpacing: '0px',
                      color: 'hsla(0, 0%, 44%, 1)',
                      opacity: '1px',
                      marginTop: '12px',
                    }}
                  >
                    Discount
                  </p>
                  <p
                    style={{
                      color: '#21212C',
                      font: 'normal normal bold 14px/19px Nunito',
                      letterSpacing: '0px',
                      opacity: '1px',
                      marginTop: '12px',
                    }}
                  >
                    0/-
                  </p>
                </div>
                <div class="card-header bg-transparent"></div>
                <div className="d-flex justify-content-between">
                  <p
                    style={{
                      color: '#1D1D25',
                      font: 'normal normal 600 16px/22px Nunito',
                      letterSpacing: '0px',
                      opacity: '1px',
                      marginTop: '12px',
                    }}
                  >
                    Total Price
                  </p>
                  <p
                    style={{
                      color: '#1D1D25',
                      font: 'normal normal 600 16px/22px Nunito',
                      letterSpacing: '0px',
                      opacity: '1px',
                      marginTop: '12px',
                    }}
                  >
                    {idDetails.price ? (
                      <> ${idDetails.price}</>
                    ) : (
                      <> ${totalPrice} </>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-3 d-flex justify-content-center">
            <button
              style={{
                width: '158px',
                height: '50px',
                backgroundColor: '#00B4BD',

                border: 'none',
                borderRadius: '25px',
                color: '#FFFFFF',
                fontSize: '14px',
                fontWeight: '600',
              }}
            >
              <p
                // onClick={placeOrder}
                onClick={() => {
                  handleBuy()
                }}
                // to="#"
                style={{
                  display: 'block',
                  height: '100%',
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {idDetails.price ? (
                  <> ${idDetails.price}</>
                ) : (
                  <> ${totalPrice} </>
                )}
              </p>
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default PaymentPage

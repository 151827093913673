import React, { useContext, useState, useEffect } from "react";
import ApiConfig from "../../../../api/ApiConfig";
import { AppContext } from "../../../../context/AppContext";
import swal from "sweetalert";
import LoaderCenter from "../../../../sharedComponent/LoaderCenter";
import { useParams, Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faBuilding,
  faUsers,
  faBed,
  faBath,
  faMoneyBill,
  faDollarSign,
  faUser,
  faClock ,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

const ManageHealthOfferVoucher = () => {
  const { customerData } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [houseDetails, setHouseDetails] = useState(null);

  useEffect(() => {
    getHouseDetails();
  }, []);

  const { id } = useParams();

  const getHouseDetails = async () => {
    setLoading(true);

    console.log("check id", id);

    const response = await fetch(`${ApiConfig.BASE_NEW_URL}offer-health/${id}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setLoading(false);
        console.log("offer_properties", data);
        // if (keyword.length > 0) {
        //   getSearchedProducts(data.housing_properties);
        // } else {
        setHouseDetails(data);
        //console.log("res housing", HouseingList);
        // setHouseingAdsList(data.housing_properties.filter(e => e.featured_ads == true));
        //}
      })
      .catch(error => {
        setLoading(false);
        console.error('There was a problem fetching the data:', error);
      });

    // try {
    //   const response = await fetch(`http://localhost:3080/posts/view?type=housing/${id}`);
    //   if (!response.ok) {
    //     throw new Error('Network response was not ok');
    //   }
    //   const data = await response.json();
    //   const foundHouse = data.posts.find(house => house._id === '663c88c452cb377ab56c2e78');
    //   setHouseDetails(data.posts);
    //   setLoading(false);
    // } catch (error) {
    //   setLoading(false);
    //   console.error('There was a problem fetching the data:', error);
    // }


  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    };
    return date.toLocaleString('en-US', options);
  };

  const handleDelete = () => {
    // Handle delete functionality here
    swal("Delete functionality is not implemented yet.");
  };

  const handleInterested = () => {
    // Handle interested functionality here
    swal("Interested functionality is not implemented yet.");
  };



  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDeleteClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) => 
            prevIndex === 0 ? houseDetails.images.length - 1 : prevIndex - 1
        );
    };

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) => 
            prevIndex === houseDetails.images.length - 1 ? 0 : prevIndex + 1
        );
    };

    const handleThumbnailClick = (index) => {
        setCurrentIndex(index);
    };


  // return (
  //   <div style={{ display: 'flex', justifyContent: 'center' }}>
  //     <div style={{ maxWidth: '800px', display: 'flex', flexDirection: 'row', margin: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', borderRadius: '15px', overflow: 'hidden' }}>
  //       {loading ? (
  //         <LoaderCenter />
  //       ) : houseDetails ? (
  //         <>
  //           <div style={{ flex: 1, backgroundColor: '#kkk', padding: '20px' }}>
  //             <h2>{houseDetails.title}</h2>
  //             <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
  //               <img src={houseDetails.images[0]} alt="Main" style={{ width: '70%', maxWidth: '400px', height: 'auto', borderRadius: '15px',padding:'4px'}} />
  //               <div style={{ width: '30%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
  //                 {houseDetails.images.slice(1, 4).map((image, index) => (
  //                   <img key={index} src={image} alt={`Image ${index + 1}`} style={{ width: '100%', maxWidth: '200px', height: 'auto', borderRadius: '10px', marginBottom: '10px' }} />
  //                 ))}
  //               </div>
  //             </div>
  //             <section>
  //               <h5>Description</h5>
  //               <p>{houseDetails.description}</p>
  //             </section>

  //             <div style={{ backgroundColor: '#fff', padding: '20px', marginTop: '20px', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
  //             <div style={{ flex: 1 }}>
  //               <section>
  //                 <h5>Address Details</h5>
  //                 <p><strong>City:</strong> {houseDetails.housingDetails.city}</p>
  //                 <p><strong>Address:</strong> {houseDetails.housingDetails.address1}</p>
  //                 <p><strong>PostCode:</strong> {houseDetails.housingDetails.postCode}</p>
  //               </section>
  //             </div>
  //             <div style={{ flex: 1, marginLeft: '20px' }}>
  //             <img src={houseDetails.images[1]} alt="Main" style={{ width: '130%', maxWidth: '400px', height: '120%', borderRadius: '15px' }} />
  //             </div>
  //           </div>
  //           </div>
  //           <div style={{ flex: 1, backgroundColor: '#fff', padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '20px', marginLeft: '20px' }}>
  //             <section>
  //               <h5>Other Details</h5>
  //               <p><strong>Availability:</strong> {houseDetails.housingDetails.availability}</p>
  //               <p><strong>Property Type:</strong> {houseDetails.housingDetails.propertyType}</p>
  //               <p><strong>Sharing Type:</strong> {houseDetails.housingDetails.sharingType}</p>
  //               <p><strong>Rooms:</strong> {houseDetails.housingDetails.rooms}</p>
  //               <p><strong>Bathrooms:</strong> {houseDetails.housingDetails.bathrooms}</p>
  //               <p><strong>Deposit:</strong> {houseDetails.housingDetails.deposit}</p>
  //               <p><strong>Rent:</strong> {houseDetails.housingDetails.monthlyRent.value} {houseDetails.housingDetails.monthlyRent.currency}</p>
  //             </section>
  //           </div>

  //         </>
  //       ) : (
  //         <p className="error-message" style={{ textAlign: 'center', padding: '20px', backgroundColor: '#fff', borderRadius: '15px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
  //           Oops! No details found for this property.
  //         </p>
  //       )}
  //     </div>
  //   </div>
  // );


  return (
    <div>
    {/* <div style={{ display: 'flex', flexDirection: 'row', margin: '10px', borderRadius: '15px', overflow: 'hidden', position: 'relative' }}> */}
    <div>
      {loading ? (
        <LoaderCenter />
      ) : houseDetails ? (
        <div
          style={{
            display: "flex",
            position: "relative",
            width: "100%",
            padding: "20px",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "1px",
              right: "20px",
              display: "flex",
              gap: "10px",
            }}
          >
            <Link
              style={{ padding: "5px" }}
              to="#"
              // to={`/career/edit/${houseDetails._id}`}
            >
              Share
            </Link>
   {/* <button className="btns toolbar" onClick={handleDeleteClick} >
   <FontAwesomeIcon icon={faTrashAlt} style={{ color: "white",marginTop: "2px",width:"25px" }} />
            </button> */}
          </div>

          <div
            style={{
              marginTop: "10px",
              flex: 2,
              marginRight: "20px",
              borderRadius: "10px",
              padding: "20px",
              backgroundColor: "white",
            }}
          >
            <h3>{houseDetails.voucher?.voucherName}</h3>

{houseDetails.images.length > 0 && (
 <div className="slider-container" style={{ marginTop: '30px' }}>
 <div className="main-image">
   <button className="nav prev" onClick={handlePrevClick} >
     &#10094;
   </button>
   <img
 src={houseDetails.images[currentIndex]}
 alt="current slide"
 style={{width:"100%", borderRadius:"10px" }}
/>
   <button className="nav next" onClick={handleNextClick}>
     &#10095;
   </button>
 </div>
 <div className="thumbnail-container" style={{ display: 'flex', justifyContent: 'center'}}>
   {houseDetails.images.slice(0, 3).map((image, index) => (
     <img
       key={index}
       src={image}
       alt={`thumbnail ${index}`}
       className={`thumbnail ${index === currentIndex ? 'active' : ''}`}
       style={{
         width: '70px',
         height: '60px',
         objectFit: 'cover',
         borderRadius: '5px',
         margin: '0 2px',
         cursor: 'pointer',
         border: index === currentIndex ? '2px solid blue' : '2px solid transparent',
       }}
       onClick={() => handleThumbnailClick(index)}
     />
   ))}
 </div>
</div>
)}
           
            <h5 style={{ marginTop: "25px" }}>Description</h5>
            <p style={{ marginTop: "15px",minHeight:"70px" }}>{houseDetails.description}</p>
            {/* <h5 style={{ marginTop: "25px" }}>Contact Info</h5>
            <p style={{ marginTop: "10px" }}>{houseDetails.contactInfo}</p> */}
            {/* <h5 style={{ marginTop: "20px" }}>Terms</h5>
            <p style={{ marginTop: "10px" }}>
              {houseDetails.housingDetails.term}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "30px",
                marginBottom: "30px",
              }}
            >
              <div style={{ textAlign: "center", marginRight: "10px" }}>
                <h5>Bills Included</h5>
                {houseDetails.housingDetails.billsIncluded ? (
                  <div
                    style={{
                      border: "1px solid",
                      padding: "5px 10px",
                      marginTop: "5px",
                      borderRadius: "5px",
                      color: "green",
                    }}
                  >
                    Included
                  </div>
                ) : (
                  <div
                    style={{
                      border: "1px solid",
                      padding: "5px 10px",
                      marginTop: "5px",
                      borderRadius: "5px",
                      color: "red",
                    }}
                  >
                    Not Included
                  </div>
                )}
              </div>
              <div style={{ textAlign: "center", marginRight: "10px" }}>
                <h5>Smoke Allowed</h5>
                {houseDetails.housingDetails.smokedAllowed ? (
                  <div
                    style={{
                      border: "1px solid",
                      marginTop: "5px",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      color: "green",
                    }}
                  >
                    Allowed
                  </div>
                ) : (
                  <div
                    style={{
                      border: "1px solid",
                      padding: "5px 10px",
                      marginTop: "5px",
                      borderRadius: "5px",
                      color: "red",
                    }}
                  >
                    Not Allowed
                  </div>
                )}
              </div>
              <div style={{ textAlign: "center", marginRight: "0" }}>
                <h5>Pets Allowed</h5>
                {houseDetails.housingDetails.petsAllowed ? (
                  <div
                    style={{
                      border: "1px solid",
                      padding: "5px 10px",
                      marginTop: "5px",
                      borderRadius: "5px",
                      color: "green",
                    }}
                  >
                    Allowed
                  </div>
                ) : (
                  <div
                    style={{
                      border: "1px solid",
                      padding: "5px 10px",
                      marginTop: "5px",
                      borderRadius: "5px",
                      color: "red",
                    }}
                  >
                    Not Allowed
                  </div>
                )}
              </div>
            </div> */}
          </div>
          <div
            style={{
              flex: 1,
              marginTop: "30px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <div
              style={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                borderRadius: "10px",
                padding: "20px",
                backgroundColor: "white",
              }}
            >
              <div style={{ flex: 1, marginTop: "10px", marginLeft: "10px" }}>
                {/* <div style={{ display: "flex" }}>
                  <FontAwesomeIcon icon={faCalendarAlt} style={{ color: "black",marginTop: "2px"  }} />
                  <div style={{marginLeft:"10px"}}>
                    <p>Date Posted</p>
                    <h6 style={{ marginTop: "10px" }}>
                      {formatDate(houseDetails.postedDate)}
                    </h6>
                  </div>
                </div> */}

                <div style={{ display: "flex",marginTop: "25px"  }}>
                  <FontAwesomeIcon icon={faClock} style={{ color: "black",marginTop: "2px" }} />
                  <div style={{marginLeft:"10px"}}>
                  <p>Voucher ID</p>
                <h6 style={{ marginTop: "10px" }}>
                {houseDetails.voucher?.voucherID}
                </h6>
                  </div>
                </div>

                
                <div style={{ display: "flex",marginTop: "25px" }}>
                  <FontAwesomeIcon icon={faDollarSign} style={{ color: "black",marginTop: "2px" }} />
                  <div style={{marginLeft:"10px"}}>        
                  <p >Code</p>
                <h6 style={{ marginTop: "10px" }}>
               {houseDetails.voucher?.code}
                </h6>
                  </div>
                </div>

                
                <div style={{ display: "flex",marginTop: "25px" }}>
                  <FontAwesomeIcon icon={faDollarSign} style={{ color: "black",marginTop: "2px" }} />
                  <div style={{marginLeft:"10px"}}>        
                  <p >Link</p>
                <h6 style={{ marginTop: "10px" }}>
                {houseDetails.voucher?.link}
                </h6>
                  </div>
                </div>

                <div style={{ display: "flex" ,marginTop: "25px" }}>
                  <FontAwesomeIcon icon={faDollarSign} style={{ color: "black",marginTop: "2px" }} />
                  <div style={{marginLeft:"10px"}}>
                  <p >Currency</p>
                <h6 style={{ marginTop: "10px" }}>
                {houseDetails?.currency}
                </h6>
                  </div>
                </div>

                {/* <div style={{ display: "flex" ,marginTop: "25px" }}>
                  <FontAwesomeIcon icon={faDollarSign} style={{ color: "black",marginTop: "2px" }} />
                  <div style={{marginLeft:"10px"}}>
                  <p >Price</p>
                <h6 style={{ marginTop: "10px" }}>
                {houseDetails.product?.price}
                </h6>
                  </div>
                </div> */}
                
              </div>
            </div>
            {/* <div
              style={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                borderRadius: "10px",
                padding: "20px",
                backgroundColor: "white",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  marginTop: "10px",
                  marginLeft: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60px",
                    width: "60px",
                    backgroundColor: "#FFA51B",
                    borderRadius: "10px",
                    padding: "5px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faUser}
                    size="2x"
                    style={{ color: "black" }}
                  />
                </div>
                <div style={{ flex: 3, marginLeft: "15px" }}>
                  <h6>Longitude: {houseDetails.eventDetails.long}</h6>
                  <h6 style={{ marginTop: "15px" }}>
                    Latitude: {houseDetails.eventDetails.lat}
                  </h6>
                  <h6 style={{ marginTop: "15px" }}>
                    Contact: {houseDetails.eventDetails.contactInfo}
                  </h6>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      ) : (
        <p
          className="error-message"
          style={{
            textAlign: "center",
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "15px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          Oops! No details found for this property.
        </p>
      )}
    </div>

    {/* Modal */}
    {isModalOpen && (
      <div
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "10px",
            width: "350px",
            height: "150px",
            textAlign: "center",
          }}
        >
          <h4 style={{ marginBottom: "20px" }}>Delete this post?</h4>
          <button
            onClick={handleModalClose}
            style={{
              padding: "10px 20px",
              marginTop: "10px",
              marginRight: "10px",
              backgroundColor: "#ccc",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Contact
          </button>
          <button 
            // onClick={handleDeleteConfirm}
            style={{
              padding: "10px 20px",
              backgroundColor: "#f44336",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Share
          </button>
        </div>
      </div>
    )}
  </div>
    // <div style={{ display: 'flex', justifyContent: 'center' }}>
    //   <div style={{ maxWidth: '1300px', display: 'flex', flexDirection: 'row', margin: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', borderRadius: '15px', overflow: 'hidden' }}>
    //     {loading ? (
    //       <LoaderCenter />
    //     ) : houseDetails ? (
    //       <>
    //         <div style={{ flex: 1, backgroundColor: '#fff', padding: '20px' }}>
    //           <h2 style={{ fontWeight: 'bold', color: '#333', marginBottom: '20px' }}>Details</h2>
    //           <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
    //             {/* <img src={houseDetails.images[0]} alt="Main" style={{ width: '70%', maxWidth: '400px', height: 'auto', borderRadius: '15px', padding: '4px' }} /> */}
    //             {/* <div style={{ width: '30%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
    //               {houseDetails.images.slice(1, 4).map((image, index) => (
    //                 <img key={index} src={image} alt={`Image ${index + 1}`} style={{ width: '100%', maxWidth: '200px', height: 'auto', borderRadius: '10px', marginBottom: '10px' }} />
    //               ))}
    //             </div> */}
    //           </div>
    //           <section>
    //             <h5 style={{ fontWeight: 'bold', color: '#333', marginBottom: '10px' }}>Description</h5>
    //             <p>{houseDetails.description}</p>
    //             <h5 style={{ fontWeight: 'bold', color: '#333', marginBottom: '10px' }}>Offer Type</h5>
    //             <p>{houseDetails.offerType}</p>
    //             <h5 style={{ fontWeight: 'bold', color: '#333', marginBottom: '10px' }}>Category</h5>
    //             <p>{houseDetails.category}</p>
    //           </section>
    //         </div>
    //         <div style={{ flex: 1, backgroundColor: '#fff', padding: '20px', marginTop: '20px', marginLeft: '20px', borderRadius: '15px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
    //           <section>
    //             <h5 style={{ fontWeight: 'bold', color: '#333', marginBottom: '10px' }}>Voucher Details</h5>
    //             <p><strong>Voucher ID:</strong> {houseDetails.voucher?.voucherID}</p>
    //             <p><strong>Voucher Name:</strong> {houseDetails.voucher?.voucherName}</p>
    //             <p><strong>Code:</strong> {houseDetails.voucher?.code}</p>
    //             <p><strong>Link:</strong> {houseDetails.voucher?.link}</p>
    //             <p><strong>Subscription Only:</strong> {houseDetails.voucher?.subscriptionOnly}</p>
    //             {/* <p><strong>Address:</strong> {houseDetails.housingDetails.address1}</p>
    //             <p><strong>PostCode:</strong> {houseDetails.housingDetails.postCode}</p> */}
    //           </section>
    //           {/* <img src={houseDetails.images[1]} alt="Address" style={{ width: '100%', maxWidth: '400px', height: 'auto', borderRadius: '15px', marginTop: '20px' }} /> */}
    //         </div>
    //         <div style={{ flex: 1, backgroundColor: '#fff', padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '20px', marginLeft: '20px', borderRadius: '15px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
    //           {/* <section>
    //             <h5 style={{ fontWeight: 'bold', color: '#333', marginBottom: '10px' }}>Other Details</h5>
    //             <p><strong>Availability:</strong> {houseDetails.housingDetails.availability}</p>
    //             <p><strong>Property Type:</strong> {houseDetails.housingDetails.propertyType}</p>
    //             <p><strong>Sharing Type:</strong> {houseDetails.housingDetails.sharingType}</p>
    //             <p><strong>Rooms:</strong> {houseDetails.housingDetails.rooms}</p>
    //             <p><strong>Bathrooms:</strong> {houseDetails.housingDetails.bathrooms}</p>
    //             <p><strong>Deposit:</strong> {houseDetails.housingDetails.deposit}</p>
    //             <p><strong>Rent:</strong> {houseDetails.housingDetails.monthlyRent.value} {houseDetails.housingDetails.monthlyRent.currency}</p>
    //           </section> */}
    //         </div>
    //       </>
    //     ) : (
    //       <p className="error-message" style={{ textAlign: 'center', padding: '20px', backgroundColor: '#fff', borderRadius: '15px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
    //         Oops! No details found for this property.
    //       </p>
    //     )}
    //   </div>
    // </div>
  );


};

export default ManageHealthOfferVoucher;









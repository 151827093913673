import React, { useContext, useState, useEffect, useRef } from 'react'
import { Link, useParams } from 'react-router-dom'
import call_icon from '../../assets/images/call_icon.svg'
import vCall_icon from '../../assets/images/Chat_Video_Call.svg'
import chatpp from '../../assets/images/chatListAvatar01.png'
import send_icon from '../../assets/images/send_icon.svg'
import micrphone_icon from '../../assets/images/micrphone_icon.svg'
import addImage_file_icon from '../../assets/images/addImage_file_icon.svg'
import chat_bodyContentAvatar from '../../assets/images/chat_bodyContentAvatar.png'
import chatAudio_bubble from '../../assets/images/chatAudio_bubble.svg'
import chatImageBubble from '../../assets/images/chatImageBubble.svg'
import chatTextBubble from '../../assets/images/chatTextBubble.svg'
import gc_img from '../../assets/images/groupCallImg.png'
import speaker_notselected_icon from '../../assets/images/speaker_notselected_icon.svg'
import mute_icon from '../../assets/images/mute_icon.svg'
import endcall_icon from '../../assets/images/endcall_icon.svg'
import Draggable from 'react-draggable'
import Nav from "react-bootstrap/Nav";
import double_tick from '../../assets/images/double_tick.svg'
import hr_three_dot from "../../assets/images/Chating_images/hr_three_dot.svg";
import send_btn from "../../assets/images/Chating_images/send_btn.svg";
import Three_dot from "../../assets/images/Chating_images/Three_dot.svg";
import profile_img from "../../assets/images/Chating_images/profile_img.svg";
import ic_online from "../../assets/images/Chating_images/ic_online.svg";
import { Tab } from "react-bootstrap";
import { SpeedDial } from "primereact/speeddial";
import { Button, Dropdown } from "react-bootstrap";
// import ChatBox from "./ChatBox";
import {
  PostCallWithErrorResponse,
  postMultipartWithAuthCall,
  postWithAuthCall,
  simpleGetCallWithErrorResponse,
} from '../../api/ApiServices'
import ApiConfig from '../../api/ApiConfig'
import { AppContext } from '../../context/AppContext'
import openSocket from 'socket.io-client'

function GroupChatNew({ socket }) {
  const params = useParams()
  let id = params.id
  const [showMap, setShowMap] = useState(false)
  const [message, setMessage] = useState('')
  const [ProfilDetalis, setProfileDetalis] = useState([])
  const { customerData } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const [messages, setMessages] = useState([])

  const [activeChat, setActiveChat] = useState({})
  const [selectedChat, setSelectedChat] = useState({})

  useEffect(() => {
    // getprofile()
    // getChatHystory()
  }, [])

  // useEffect(() => {
  //   socket.on('connect', async () => {})
  // }, [])

  const getprofile = () => {
    setLoading(true)

    simpleGetCallWithErrorResponse(
      ApiConfig.UNIVERSR_CONNECTION_PROFILE + id,
      JSON.stringify({ ...customerData }),
    ).then((res) => {
      console.log(res)
      setLoading(false)
      setProfileDetalis(res.json.user_profile)
    })
  }

  const getChatHystory = () => {
    setLoading(true)
    let url = ApiConfig.GET_CHAT + '?recv_id=' + selectedChat.id

    simpleGetCallWithErrorResponse(url)
      .then((res) => {
        console.log(res)
        setLoading(false)
        setMessages(res.json.list.reverse())
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  const sendMessage = (data) => {
    setLoading(true)
    socket &&
      socket.emit('new message', {
        conversation_type: 'text',
        files: [],
        message: message,
        receiverId: selectedChat.id,
        room: activeChat.private_chat_room,
        senderID: localStorage.getItem('id'),
        type: 'private',
        username: selectedChat.unique_id,
        userpic: selectedChat.avatar,
      })
  }

  socket &&
    socket.on('message created', async (data) => {
      setMessage('')
      // getChatHystory()
    })

  const uploadFile = (data) => {
    let formData = new FormData()
    formData.append('files', data)
    postMultipartWithAuthCall(ApiConfig.SAVE_CHAT_FILE, formData)
      .then((data) => {
        setMessage(data.files[0].path_url)
      })
      .catch((error) => {
        console.log('register error', error)
        alert('Something went wrong please try again!')
      })
  }

  const toast = useRef(null);

  const items = [
    {
      label: "Add",
      icon: "pi pi-pencil",
      command: () => {
        toast.current.show({
          severity: "info",
          summary: "Add",
          detail: "Data Added",
        });
      },
    },
    {
      label: "Update",
      icon: "pi pi-refresh",
      command: () => {
        toast.current.show({
          severity: "success",
          summary: "Update",
          detail: "Data Updated",
        });
      },
    },
    {
      label: "Delete",
      icon: "pi pi-trash",
      command: () => {
        toast.current.show({
          severity: "error",
          summary: "Delete",
          detail: "Data Deleted",
        });
      },
    },
    {
      label: "Upload",
      icon: "pi pi-upload",
      command: () => {
        window.location.hash = "/fileupload";
      },
    },
    {
      label: "React Website",
      icon: "pi pi-external-link",
      command: () => {
        window.location.href = "https://facebook.github.io/react/";
      },
    },
  ];

  return (
  
    <div className="col-lg-8 right-chat-section right-chat-custom-height-1">
      <Tab.Content>
        <Tab.Pane eventKey="1">
          <div className="heading-chat-section">
            <div className="left">
              <div className="left-profile-pic">
                <Link to="">
                  <img src={profile_img} alt="" />
                </Link>

              </div>
              <div className="name">
                <label htmlFor="">John Doe12</label>
              </div>
            </div>
            <div className="right">
              <Link
                to=""
                onClick={() => {
                  setShowMap(true)
                }}
                className="me-4"
              >
                <img src={call_icon} alt="" />
              </Link>
              <Link
                to=""
                onClick={() => {
                  setShowMap(true)
                }}
                className="me-2"
              >
                <img src={vCall_icon} alt="" />
              </Link>
              <div className="leaveGropu">
                <Dropdown>
                  <Dropdown.Toggle variant="" id="dropdown-basic">
                    <img src={Three_dot} alt="" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#"><Link to="/EditGropu">Edit Group</Link></Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="chatting-section">
            <div className="message-main">
              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>
              </div>

              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                  <p className='Sender_name'>Sender-1</p>
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>


              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>

              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>

              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>

            </div>
          </div>
          <div className="send_section_btn_inputs">
            <div className="chat_bodyFooter">
              <div
                className="chat_footer_inputWrapper "
                style={{}}
              >
                <input
                  className="chat-body-footer"
                  type="text"
                  placeholder="Type here..."
                  // value={message}
                  // onChange={(e) => setMessage(e.target.value)}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyUp={(e) => {
                    if (e.key == 'Enter') sendMessage()
                  }}
                />
              </div>

              {/* here I dont know which bot will be used so I used buttons only, once confirmed I will make changes accordingly */}

              <div className="chat_footerControls">
                <form action="" style={{ position: 'relative' }}>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault()
                      document
                        .getElementById('uploadFile')
                        .click()
                    }}
                  >
                    <img
                      src={addImage_file_icon}
                      alt=""
                      className="image_img"
                    />
                  </a>

                  <input
                    type="file"
                    className="footer_input "
                    placeholder="Type a message"
                    // onChange={(e) => uploadFile(e.target.files[0])}
                    style={{ display: 'none' }}
                    id="uploadFile"
                  />

                  {/* <button className="chat_footerControls-2">
                    <img
                      className="chat_footerControls-2-mic"
                      src={micrphone_icon}
                      alt=""
                    />
                  </button> */}
                  <a>
                    <img
                      onClick={sendMessage}
                      onKeyUp={sendMessage}
                      src={send_icon}
                      alt=""
                      className="send-icon-1-send"
                    />
                  </a>
                </form>
              </div>
            </div>
            <Draggable>
              <div
                className="group_callingWrapper"
                style={{
                  boxShadow: '0px 3px 12px #0000000F',
                  display: `${showMap ? 'block' : 'none'}`,
                }}
              >
                <div className="gc_imgWrapper">
                  <img src={gc_img} alt="" />
                </div>
                <h1
                  style={{
                    font:
                      ' normal normal normal 20px/24px SF Pro Text',
                    color: '#21212C',
                    letterSpacing: '0px',
                  }}
                >
                  Work Friends
                </h1>
                <p style={{ color: 'lightgrey' }}>Calling...</p>
                <div className="gc_dailup">
                  <Link to="/">
                    <img
                      className="gc_dailup-1"
                      src={speaker_notselected_icon}
                      alt=""
                    />
                  </Link>
                  <Link to="/">
                    <img
                      className="gc_dailup-2"
                      src={mute_icon}
                      alt=""
                    />
                  </Link>
                  <Link to="/groups">
                    <img
                      className="gc_dailup-3"
                      src={endcall_icon}
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </Draggable>
          </div>
        </Tab.Pane>
        <Tab.Pane eventKey="2">
          <div className="heading-chat-section">
            <div className="left">
              <div className="left-profile-pic">
                <Link to="">
                  <img src={profile_img} alt="" />
                </Link>

              </div>
              <div className="name">
                <label htmlFor="">John Doe</label>
              </div>
            </div>
            <div className="right">

            </div>
          </div>
          <div className="chatting-section">
            <div className="message-main">
              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>


              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>

              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>

              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>

            </div>
          </div>
          <div className="send_section_btn_inputs">

            <div className="text-input">
              <input
                type="text"
                className="form-control"
                placeholder="Type a message here"
              />
            </div>
            <div className="send-btn">
              <Link to="#">
                <img src={send_btn} alt="" />
              </Link>
            </div>
          </div>
        </Tab.Pane>
        <Tab.Pane eventKey="3">
          <div className="heading-chat-section">
            <div className="left">
              <div className="left-profile-pic">
                <Link to="">
                  <img src={profile_img} alt="" />
                </Link>
              </div>
              <div className="name">
                <label htmlFor="">John Doe</label>
              </div>
            </div>
            <div className="right">


            </div>
          </div>
          <div className="chatting-section">
            <div className="message-main">
              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>


              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>

              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>

              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>

            </div>
          </div>
          <div className="send_section_btn_inputs">

            <div className="text-input">
              <input
                type="text"
                className="form-control"
                placeholder="Type a message here"
              />
            </div>
            <div className="send-btn">
              <Link to="#">
                <img src={send_btn} alt="" />
              </Link>
            </div>
          </div>
        </Tab.Pane>
        <Tab.Pane eventKey="4">
          <div className="heading-chat-section">
            <div className="left">
              <div className="left-profile-pic">
                <Link to="">
                  <img src={profile_img} alt="" />
                </Link>
              </div>
              <div className="name">
                <label htmlFor="">John Doe</label>
              </div>
            </div>
            <div className="right">


            </div>
          </div>
          <div className="chatting-section">
            <div className="message-main">
              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>


              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>

              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>

              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>

            </div>
          </div>
          <div className="send_section_btn_inputs">

            <div className="text-input">
              <input
                type="text"
                className="form-control"
                placeholder="Type a message here"
              />
            </div>
            <div className="send-btn">
              <Link to="#">
                <img src={send_btn} alt="" />
              </Link>
            </div>
          </div>
        </Tab.Pane>
        <Tab.Pane eventKey="5">
          <div className="heading-chat-section">
            <div className="left">
              <div className="left-profile-pic">
                <Link to="">
                  <img src={profile_img} alt="" />
                </Link>
              </div>
              <div className="name">
                <label htmlFor="">John Doe</label>
              </div>
            </div>
            <div className="right">


            </div>
          </div>
          <div className="chatting-section">
            <div className="message-main">
              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>


              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>

              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>

              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>

            </div>
          </div>
          <div className="send_section_btn_inputs">

            <div className="text-input">
              <input
                type="text"
                className="form-control"
                placeholder="Type a message here"
              />
            </div>
            <div className="send-btn">
              <Link to="#">
                <img src={send_btn} alt="" />
              </Link>
            </div>
          </div>
        </Tab.Pane>
        <Tab.Pane eventKey="6">
          <div className="heading-chat-section">
            <div className="left">
              <div className="left-profile-pic">
                <Link to="">
                  <img src={profile_img} alt="" />
                </Link>
              </div>
              <div className="name">
                <label htmlFor="">John Doe</label>
              </div>
            </div>
            <div className="right">


            </div>
          </div>
          <div className="chatting-section">
            <div className="message-main">
              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>


              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>

              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>

              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>

            </div>
          </div>
          <div className="send_section_btn_inputs">

            <div className="text-input">
              <input
                type="text"
                className="form-control"
                placeholder="Type a message here"
              />
            </div>
            <div className="send-btn">
              <Link to="#">
                <img src={send_btn} alt="" />
              </Link>
            </div>
          </div>
        </Tab.Pane>
        <Tab.Pane eventKey="7">
          <div className="heading-chat-section">
            <div className="left">
              <div className="left-profile-pic">
                <Link to="">
                  <img src={profile_img} alt="" />
                </Link>
              </div>
              <div className="name">
                <label htmlFor="">John Doe</label>
              </div>
            </div>

          </div>
          <div className="chatting-section">
            <div className="message-main">
              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>


              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>

              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>

              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>

            </div>
          </div>
          <div className="send_section_btn_inputs">

            <div className="text-input">
              <input
                type="text"
                className="form-control"
                placeholder="Type a message here"
              />
            </div>
            <div className="send-btn">
              <Link to="#">
                <img src={send_btn} alt="" />
              </Link>
            </div>
          </div>
        </Tab.Pane>
        <Tab.Pane eventKey="8">
          <div className="heading-chat-section">
            <div className="left">
              <div className="left-profile-pic">
                <Link to="">
                  <img src={profile_img} alt="" />
                </Link>
              </div>
              <div className="name">
                <label htmlFor="">John Doe</label>
              </div>
            </div>
            <div className="right">


            </div>
          </div>
          <div className="chatting-section">
            <div className="message-main">
              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>


              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>

              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>

              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>

            </div>
          </div>
          <div className="send_section_btn_inputs">

            <div className="text-input">
              <input
                type="text"
                className="form-control"
                placeholder="Type a message here"
              />
            </div>
            <div className="send-btn">
              <Link to="#">
                <img src={send_btn} alt="" />
              </Link>
            </div>
          </div>
        </Tab.Pane>
        <Tab.Pane eventKey="9">
          <div className="heading-chat-section">
            <div className="left">
              <div className="left-profile-pic">
                <Link to="">
                  <img src={profile_img} alt="" />
                </Link>
              </div>
              <div className="name">
                <label htmlFor="">John Doe</label>
              </div>
            </div>
            <div className="right">


            </div>
          </div>
          <div className="chatting-section">
            <div className="message-main">
              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>


              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>

              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>

              <div className="send-msg">
                <Link to="">
                  <img src={double_tick} alt="double_tick" className="ms-3" />
                </Link>
                <div className="send-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes here
                  </p>
                </div>

              </div>


              <div className="receive-msg">
                <div className="msg-with-img">
                  <img src={profile_img} alt="" />
                </div>
                <div className="right-receive-msg">
                  <div className="receive-msg-main">
                    <p className="msg-text">
                      Message goes here Message goes here Message goes Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Autem
                      aspernatur libero suscipit magnam tempore architecto,
                      omnis nihil? Cum dolorem nulla amet ullam, iusto, nam
                      culpa impedit odit sit aspernatur illo! here
                    </p>
                  </div>


                </div>
              </div>

            </div>
          </div>
          <div className="send_section_btn_inputs">

            <div className="text-input">
              <input
                type="text"
                className="form-control"
                placeholder="Type a message here"
              />
            </div>
            <div className="send-btn">
              <Link to="#">
                <img src={send_btn} alt="" />
              </Link>
            </div>
          </div>
        </Tab.Pane>
      </Tab.Content>
    </div>
  )
}

export default GroupChatNew

import React, { useEffect } from "react";
import upload from "../assets/images/upload_icon.svg";
export default function ImageUplode({ setFile }) {
  let id = "DropZone";
  useEffect(() => {
    initDragAndDrop();
  }, []);
  const initDragAndDrop = () => {
    var dropZone = document.getElementById(id);
    // Optional.   Show the copy icon when dragging over.  Seems to only work for chrome.
    dropZone &&
      dropZone.addEventListener("dragover", function (e) {
        e.stopPropagation();
        e.preventDefault();
        e.dataTransfer.dropEffect = "copy";
      });
    dropZone && dropZone.addEventListener("drop", drop);
  };
  function drop(e) {
    e.stopPropagation();
    e.preventDefault();
    let file = "";
    if (e.target && e.target.files) {
      file = e.target.files;
    } else {
      file = e.dataTransfer.files;
    }
    setFile(file);
  }

  return (
    <div className="border-block " id={id}>
      <div
        className="custom-file-1  ce-input-1 ce-upload-1  d-flex flex-column align-items-center justify-content-center "
        style={{
          backgroundColor: "white",
          width: "100%",
          position: "relative",
        }}
      >
        <input
          multiple
          onChange={drop}
          accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
          id={"uploadImages"}
          type="file"
          name="vehicle_images"
          style={{
            // display: "none",
            height: "100%",
            width: "100%",
            zIndex: "1000",
            position: "absolute",
          }}
        />
        <img style={{}} src={upload} alt="" className="upload-icon-1" />

        <a
          href="#"
          style={{ color: "#4D9DE7" }}
          onClick={(e) => {
            e.preventDefault();
            document.getElementById("uploadImages").click();
          }}
        >
          <p
            style={{
              marginTop: "20px",
              textAlign: "center",
            }}
            className="uimage-1"
          >
           Upload CV
          </p>
        </a>
      </div>
    </div>
  );
}

import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Sidebar from "../../components/Sidebar";
import thumbImg from "../../assets/images/house01.png";
import tooltip_icon from "../../assets/images/tooltip_icon.svg";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
function PropertyItem() {
  const { sidebar, setSidebar } = useContext(AppContext);
  const [showMap, setShowMap] = useState(false);
  const [showElement, setShowElement] = React.useState(true);
   const [windowDimensions, setWindowDimensions] = useState(
     getWindowDimensions()
   );
   const [toolOpen, setToolOpen] = useState(false);
   function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
  useEffect(() => {
    setTimeout(function () {
      setShowElement(false);
    }, 5000);
  }, []);
  return (
    <div class="sitemainBGwrapper">
      <Sidebar />

      {/* <Header /> */}
      <main id="cxMain" className={sidebar ? "cx-main cx-active" : "cx-main"}>
        <section className="content-main">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="globalPages_tabWrapper">
                    <div className="gb_tabs"></div>

                    <div className="gb_tabs_ControlsWrapper">
                      <ul className="gb-tabs-property" style={{ width: "150%", marginRight: "70px" }}>
                        <li>
                          <Link
                            style={{
                              color: "#21212C",
                              fontSize: "14px",
                              fontFamily: "Nunito",
                              fontWeight: "600",
                            }}
                            className="ct_active"
                            to="/create_property"
                          >
                            Create Property
                          </Link>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row" style={{ marginTop: "-40px" }}>
                <div className="col-md-8">
                  <div className="gbl_thumbWrapper gbl_thumbWrapper02">
                    <div className="img_thumbWrapper img_thumbWrapperAdj01">
                      <Link
                        style={{
                          marginLeft: "10px",
                          marginTop: "8px",
                        }}
                        to="/house_listing"
                      >
                        <i class="fa-solid fa-arrow-left"></i>
                      </Link>
                      <div
                        class="dropdown"
                        style={{
                          borderRadius: "8px",
                          height: "34px",
                          width: "34px",
                          marginLeft: "-110px",
                          marginTop: "46px",
                          position: "absolute",
                          margin: "29px",
                        }}
                      >
                        <button
                          class="btn"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i class="fa-solid fa-ellipsis-vertical"></i>
                        </button>
                        <ul
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <Link
                              to="/property_item"
                              class="dropdown-item"
                              href="#"
                              style={{ backgroundColor: "white" }}
                            >
                              View Details
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <img
                        style={{
                          padding: "20px",
                          borderRadius: "40px",
                        }}
                        src={thumbImg}
                        alt=""
                      />
                    </div>

                    <div
                      style={{
                        marginLeft: "12px",
                      }}
                      className="gbl_thumbContent"
                    >
                      <Link
                        className="tooltipdirection"
                        to=""
                        onClick={() => setToolOpen(!toolOpen)}
                      >
                        <img src={tooltip_icon} alt="" />
                      </Link>

                      <h1>2BHK Residential House</h1>
                      <h6>
                        by{" "}
                        <Link to="">
                          <u>Sarah Connors</u>
                        </Link>
                      </h6>
                      <h2>
                        <span>Monthly Rent</span> 7000 /-
                      </h2>
                      <h2>
                        <span>Deposit</span> 7000 /-
                      </h2>
                      <h2>
                        <span>Availability</span> Within 1 Week
                      </h2>
                      <h2>
                        <span>Number of Rooms</span> 3
                      </h2>
                      <h2>
                        <span>Gender</span> Flat
                      </h2>
                      <h2>
                        <span>Terms</span> Female Only
                      </h2>
                      <h2>
                        <span>Smokers Allowed</span> Yes
                      </h2>
                      <h2>
                        <span>Pets Allowed</span> Yes
                      </h2>

                      <div className="gbl_thumbControlWrapper gbl_thumbControlWrapper02">
                        <h2
                          style={{
                            fontSize: "16px",
                            fontFamily: "Nunito",
                            fontWeight: "600",
                            color: "1D1D25",
                          }}
                        >
                          About Property
                        </h2>
                        <p
                          style={{
                            fontSize: "14px",
                            fontFamily: "Nunito",
                            fontWeight: "600",
                            color: "#707070",
                          }}
                        >
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Lorem ipsum dolor sit amet,
                          consectetur.
                        </p>
                      </div>
                    </div>
                    <div
                      className={`mainLocWrapper ${
                        windowDimensions.width >= 1200
                          ? "right-popup"
                          : "left-popup"
                      }`}
                    >
                      {" "}
                      <div
                        className={`tooltipInfoWrapper ${
                          toolOpen ? "tool-open" : "tool-close"
                        }`}
                      >
                        <div
                          className={`triangle-left ${
                            windowDimensions.width >= 1200
                              ? "right-icon"
                              : "left-icon"
                          } `}
                        ></div>
                        <div className="ttInfoHeader">
                          <h1>Location & Address</h1>
                          <Link
                            to="#"
                            style={{ margin: "0" }}
                            onClick={() => setToolOpen(false)}
                          >
                            <i class="fa-solid fa-xmark"></i>
                          </Link>
                        </div>

                        <div className="ttMapWrapper">
                          <iframe
                            className="actAsDiv"
                            frameborder="0"
                            scrolling="no"
                            marginheight="0"
                            marginwidth="0"
                            src="https://maps.google.com/maps?f=q&amp;source=s_q&amp;q=Adobe%20Systems%20Inc%2C%20Park%20Avenue%2C%20San%20Jose%2C%20CA&amp;aq=0&amp;ie=UTF8&amp;t=m&amp;z=12&amp;iwloc=A&amp;output=embed"
                          >
                            {" "}
                          </iframe>
                        </div>

                        <div className="ttAddressWrapper">
                          <h1>Address</h1>
                          <p>4111 Milford Street Portsmouth 603-810-0381</p>
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default PropertyItem;


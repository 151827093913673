import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";

function Subscription() {
  const { sidebar, setSidebar } = useContext(AppContext);

  const [showElement, setShowElement] = React.useState(true);
  useEffect(() => {
    setTimeout(function () {
      setShowElement(false);
    }, 5000);
  }, []);
  return (
    <div class="sitemainBGwrapper">
      <Sidebar />

      {/* <Header /> */}
      <main id="cxMain" className={sidebar ? "cx-main cx-active" : "cx-main"}>
        <section className="content-main">
          <div className="row">
            <div className="col-md-12">
              <div className="create_screenWrapper baloonScreenWrapper">
                <div className="row">
                  <div className="col-md-3">
                      
                

                       <div className="baloonThumbWrapper baloonThumbWrapper01">
                       <div className="aWizardRow">
                        <div className="checkboxWrapper">
                          <div className="checkboxWrapper_holder">
                            <label>
                              <div></div>
                              <input type="checkbox" class="default__check" />
                              <span class="custom__check"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      
<h1><span>99/</span>Month</h1>
<h2>Basic</h2>
<p>Remove all advertisements</p>

<Link to="#">SUBSCRIBE NOW</Link>
                       </div>

                  </div>


                  <div className="col-md-3">
                      
                

                      <div className="baloonThumbWrapper baloonThumbWrapper02">
                      <div className="aWizardRow">
                       <div className="checkboxWrapper">
                         <div className="checkboxWrapper_holder">
                           <label>
                             <div></div>
                             <input type="checkbox" class="default__check" />
                             <span class="custom__check"></span>
                           </label>
                         </div>
                       </div>
                     </div>
                     
<h1><span>99/</span>Month</h1>
<h2>Basic</h2>
<p>Remove all advertisements</p>

<Link to="#">SUBSCRIBE NOW</Link>
                      </div>

                 </div>


                 <div className="col-md-3">
                      
                

                      <div className="baloonThumbWrapper baloonThumbWrapper03">
                      <div className="aWizardRow">
                       <div className="checkboxWrapper">
                         <div className="checkboxWrapper_holder">
                           <label>
                             <div></div>
                             <input type="checkbox" class="default__check" />
                             <span class="custom__check"></span>
                           </label>
                         </div>
                       </div>
                     </div>
                     
<h1><span>99/</span>Month</h1>
<h2>Basic</h2>
<p>Remove all advertisements</p>

<Link to="#">SUBSCRIBE NOW</Link>
                      </div>

                 </div>


                 <div className="col-md-3">
                      
                

                      <div className="baloonThumbWrapper baloonThumbWrapper04 active04">
                      <div className="aWizardRow">
                       <div className="checkboxWrapper">
                         <div className="checkboxWrapper_holder">
                           <label>
                             <div></div>
                             <input type="checkbox" class="default__check" />
                             <span class="custom__check"></span>
                           </label>
                         </div>
                       </div>
                     </div>
                     
<h1><span>99/</span>Month</h1>
<h2>Basic</h2>
<p>Remove all advertisements</p>

<Link to="#">SUBSCRIBE NOW</Link>
                      </div>

                 </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Subscription;

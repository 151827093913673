import React, { useState } from "react";
import login_logo from "../assets/images/login_logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { findUserById, saveUserDetails, userUpdate } from "../api/ApiServices";
import swal from "sweetalert";

const Onboarding = () => {
  // const [realName, setrealName] = useState("");
  // const [age, setAge] = useState("");
  // const [address, setAddress] = useState("");
  // const [contactNumber, setContactNumber] = useState("");
  // const
  const navigate = useNavigate();

  const [formState, setFormState] = useState({
    step: 1,
    realName: "",
    age: "",
    address: "",
    contactNumber: "",
    profileName: "",
    courses: "",
  });

  const handleContinue = (event) => {
    event.preventDefault();
    setFormState((prevState) => ({
      ...prevState,
      step: prevState.step + 1,
    }));
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      // Handle checkbox input
      setFormState((prevState) => ({
        ...prevState,
        courses: checked ? value : "",
      }));
    } else {
      // Handle text input
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const updatedFormState = {
      ...formState,
      age: Number(formState.age),
    };

    try {
      const userDetails = localStorage.getItem("user_details");

      const userObject = JSON.parse(userDetails);

      const accessToken = localStorage.getItem("access_token");
      const url = `http://localhost:3080/userFlutter/auth0/${userObject.sub}`;
      const searchUser = await findUserById(url, accessToken);

      if (!searchUser) {
        const response = await saveUserDetails(
          userObject.sub,
          updatedFormState,
          userObject.email
        );

        swal(
          "Registration successfully done. Admin will verify your account. Please login after 1 hour."
        );
        navigate("/pendingverification");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };
  return (
    <div className="auth_mainWrapper">
      <div className="auth_innerWrapper">
        <div className="auth_twoPane">
          <div className="auth_banner">
            <div className="auth_logoWrapper">
              <div className="auth_logoHolder">
                <img src={login_logo} alt="login logo" />
                <h1>
                  We use tech to create,
                  <br /> connect, and grow
                </h1>
              </div>
            </div>
          </div>
          <div>
            <div className="auth_formWrapper">
              <div className="auth_innerformWrapper">
                <div className="auth_wizardForm">
                  <form onSubmit={handleSubmit}>
                    {formState.step === 1 && (
                      <>
                        <h1>Full Name</h1>
                        <div className="auth_inner_wizardForm">
                          <div className="aWizardRow">
                            <div className="checkboxWrapper">
                              <div className="checkboxWrapper_holder">
                                <input
                                  style={{
                                    width: "100%",
                                    backgroundColor: "white",
                                    border: "1px solid #ccc",
                                    borderRadius: "20px",
                                    padding: "15px",
                                    boxSizing: "border-box",
                                  }}
                                  type="text"
                                  name="realName"
                                  placeholder="Please enter your name"
                                  value={formState.realName}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <h1>Your Age</h1>
                        <div className="auth_inner_wizardForm">
                          <div className="aWizardRow">
                            <div className="checkboxWrapper">
                              <div className="checkboxWrapper_holder">
                                <input
                                  style={{
                                    width: "100%",
                                    backgroundColor: "white",
                                    border: "1px solid #ccc",
                                    borderRadius: "20px",
                                    padding: "15px",
                                    boxSizing: "border-box",
                                  }}
                                  type="text"
                                  name="age"
                                  placeholder="Please enter your age"
                                  value={formState.age}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <h1>Address</h1>
                        <div className="auth_inner_wizardForm">
                          <div className="aWizardRow">
                            <div className="checkboxWrapper">
                              <div className="checkboxWrapper_holder">
                                <input
                                  style={{
                                    width: "100%",
                                    backgroundColor: "white",
                                    border: "1px solid #ccc",
                                    borderRadius: "20px",
                                    padding: "15px",
                                    boxSizing: "border-box",
                                  }}
                                  type="text"
                                  name="address"
                                  placeholder="Please enter your address"
                                  value={formState.address}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <h1>Contact Number</h1>
                        <div className="auth_inner_wizardForm">
                          <div className="aWizardRow">
                            <div className="checkboxWrapper">
                              <div className="checkboxWrapper_holder">
                                <input
                                  style={{
                                    width: "100%",
                                    backgroundColor: "white",
                                    border: "1px solid #ccc",
                                    borderRadius: "20px",
                                    padding: "15px",
                                    boxSizing: "border-box",
                                  }}
                                  type="text"
                                  name="contactNumber"
                                  placeholder="Please enter your Contact Number"
                                  value={formState.contactNumber}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="auth_inner_wizardForm">
                          <div className="auth_controlWrapper2">
                            <button onClick={handleContinue}>Continue</button>
                            {/* <Link to="/interests">Continue</Link> */}
                          </div>
                        </div>
                      </>
                    )}
                    {formState.step === 2 && (
                      <>
                        <h1>I would like to be called by</h1>
                        <div className="auth_inner_wizardForm">
                          <div className="aWizardRow">
                            <div className="checkboxWrapper">
                              <div className="checkboxWrapper_holder">
                                <input
                                  style={{
                                    width: "100%",
                                    backgroundColor: "white",
                                    border: "1px solid #ccc",
                                    borderRadius: "20px",
                                    padding: "15px",
                                    boxSizing: "border-box",
                                  }}
                                  type="text"
                                  name="profileName"
                                  placeholder="Please enter your profile name"
                                  value={formState.profileName}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <h1>
                          I'm studying /
                          <br /> would like to study
                        </h1>
                        <p>
                          All the available courses under your
                          <br /> selected University
                        </p>

                        <div className="auth_inner_wizardForm">
                          <div className="aWizardRow">
                            <div className="checkboxWrapper">
                              <div className="checkboxWrapper_holder">
                                <label>
                                  Arts
                                  <input
                                    type="checkbox"
                                    name="courses"
                                    value="Arts"
                                    class="default__check"
                                    checked={formState.courses === "Arts"}
                                    onChange={handleChange}
                                  />
                                  <span class="custom__check"></span>
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="aWizardRow">
                            <div className="checkboxWrapper">
                              <div className="checkboxWrapper_holder">
                                <label>
                                  Technology
                                  <input
                                    name="courses"
                                    type="checkbox"
                                    value="Technology"
                                    class="default__check"
                                    checked={formState.courses === "Technology"}
                                    onChange={handleChange}
                                  />
                                  <span class="custom__check"></span>
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="aWizardRow">
                            <div className="checkboxWrapper">
                              <div className="checkboxWrapper_holder">
                                <label>
                                  Science
                                  <input
                                    name="courses"
                                    type="checkbox"
                                    value="Science"
                                    class="default__check"
                                    checked={formState.courses === "Science"}
                                    onChange={handleChange}
                                  />
                                  <span class="custom__check"></span>
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="auth_controlWrapper2">
                            <button type="submit">Continue</button>
                            {/* <Link to="/verify-email">Continue</Link> */}
                          </div>
                        </div>
                      </>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Login
    <Link to="/dashboard" >dashboard</Link> */}
    </div>
  );
};

export default Onboarding;

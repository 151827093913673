import React, { useContext, useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { AppContext } from '../../context/AppContext'
import Sidebar from '../../components/Sidebar'
import Header from '../../components/Header'
import LoaderCenter from '../../sharedComponent/LoaderCenter'
import 'react-phone-number-input/style.css'
import saleThumb01 from '../../assets/images/saleThumb01.png'
import saleThumb02 from '../../assets/images/saleThumb02.png'
import saleThumb03 from '../../assets/images/saleThumb03.png'
import location_icon from '../../assets/images/location_icon.svg'
import jobIcn01 from '../../assets/images/jobIcn01.svg'
import fillter_icon from '../../assets/images/fillter_icon.svg'
import arrow from '../../assets/images/up_arrow.svg'
import left from '../../assets/images/left.svg'
import upload from '../../assets/images/upload_icon.svg'
import ApiConfig from '../../api/ApiConfig'
import ImageUPloadeJOb from '../../sharedComponent/ImageUplodeJob'
import PhoneInput from 'react-phone-number-input'
// import validator from "validator";
import { CopyToClipboard } from 'react-copy-to-clipboard'

import ApiServices, {
  multipartPostCall,
  simpleGetCallWithErrorResponse,
  simplePostCall,
} from '../../api/ApiServices'
import swal from 'sweetalert'
import { ShareSocial } from 'react-share-social'
import { Modal, Button } from 'react-bootstrap'

const style = {
  background: '#F5F5F5',
  borderRadius: 3,
  border: 0,
  color: 'white',
  padding: '0 0px',
  // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  height: '200px',
  marginTop: '20px',
  // width: '20px',
}

function CareerDetails() {
  const { customerData } = useContext(AppContext)
  // apply api starts
  const [ShareData, setShare] = useState('')

  const navigate = useNavigate()
  const [showModal, setShow] = useState(false)
  const [showModalApply, setApplyShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => {
    
    setShow(true)
    ShareHandal()
  }
  const handleCloseAll = () => setApplyShow(false)
  const handleShowAll = () => setApplyShow(true)
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [cover, setCover] = useState('')
  const [resume, setResume] = useState('')
  const [errorMsg, setErrorMsg] = useState({
    phone: '',
    email: '',
    cover: '',
    resume: '',
  })

  const isEmailValid = () => {
    if (email != '') {
      if (
        !/^[a-zA-Z0-9.! #$%&'*+/=? ^_`{|}~-]+@[a-zA-Z0-9-]+(?:\. [a-zA-Z0-9-]+)*$/.test(
          email,
        )
      ) {
        setErrorMsg({ ...errorMsg, email: '' })
        return true
      } else {
        setErrorMsg({ ...errorMsg, email: 'please enter valid email' })
        return false
      }
    } else {
      setErrorMsg({ ...errorMsg, email: 'please enter email' })
      return false
    }
  }
  const isPhoneValid = () => {
    if (phone != '') {
      if (phone.length >= 10) {
        setErrorMsg({ ...errorMsg, phone: '' })
        return true
      } else {
        setErrorMsg({ ...errorMsg, phone: 'please enter 10 digits' })
        return false
      }
    }
    //  else {
    //   setErrorMsg({ ...errorMsg, phone: "please enter phone number" });
    //   return false;
    // }
  }
  const isCoverValid = () => {
    if (cover == '') {
      setErrorMsg({ ...errorMsg, cover: 'please enter cover msg' })
      return false
    } else {
      return true
    }
  }
  const isResumeValid = () => {
    if (resume == '') {
      setErrorMsg({ ...errorMsg, resume: 'please upload your resume' })
      return false
    } else {
      return true
    }
  }
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setResume(URL.createObjectURL(event.target.files[0]))
    }
  }
  const applyJob = async () => {
    // const id = useParams().id;
    const formData = new FormData()
    formData.append('email', email)
    formData.append('phone_number', phone)
    formData.append('cover_letter', cover)
    formData.append('job_id', id)
    formData.append('resume', resume)
    const resp = await multipartPostCall(ApiConfig.JOB_APPLY, formData)
    swal(resp?.detail)
    navigate('/job_volunteer')
  }
  // const handleForm = () => {
  //   if (isPhoneValid() && isEmailValid() && isCoverValid() && isResumeValid()) {
  //     applyJob();
  //   }
  // };
  // apply api ends
  const { sidebar, setSidebar } = useContext(AppContext)
  const [showElement, setShowElement] = React.useState(true)
  const [details, setDetails] = useState({})
  const id = useParams().id
  const getJobDetails = async () => {
    const data = await simpleGetCallWithErrorResponse(
      ApiConfig.JOB_LISTING + `${id}/`,
    )
    setDetails(data?.json)
  }
  useEffect(() => {
    getJobDetails()

    // return setErrorMsg({ ...errorMsg, phone: null });
  }, [])

  const ShareHandal = async () => {
    let requestBody = JSON.stringify({
      dynamicLinkInfo: {
        domainUriPrefix: 'https://myuniverse.page.link',

        link:
          'https://my-univrs.app.redbytes.in/career-details/' +
          String(id) +
          '/?user=' +
          String(customerData.id),

        androidInfo: {
          androidPackageName: 'com.my_universe',
        },

        iosInfo: {
          iosBundleId: 'org.myUniverse',
        },
      },
    })

    await fetch(
      'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBmt92Aby2xlZWOCIHeTutj8ywpF-4reyw',

      {
        method: 'POST',

        headers: {
          Accept: 'application/json',

          'Content-Type': 'application/json',
        },

        body: requestBody,
      },
    )
      .then((response) => response.json())

      .then((data) => {
        console.log('response', data)
        setShare(data.shortLink)
      })

      .catch((err) => {
        // this.setState({isVisible:false})

        console.log('error', err)
      })
  }




  return (
    <>
      <Sidebar />

      {/* <Header /> */}
      <main id="cxMain" className={sidebar ? 'cx-main cx-active' : 'cx-main'}>
        <section
          className="content-main"
          style={{
            backgroundColor: 'white',
            borderRadius: '24px',
            height: 'fit-content',
            padding: '20px',
          }}
        >
          <Modal
              className="custom-dialog"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={showModal}
              onHide={handleClose}
            >
              <Modal.Header
                style={{ padding: '12px', width: '99%' }}
                closeButton
              >
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
              {/* Are you sure you want to cancel this Friend Request? */}
              <div class="modal-body" style={{ marginTop: '-37px' }}>
                <ShareSocial
                  style={style}
                  url={ShareData}
                  socialTypes={[
                    'facebook',
                    'twitter',
                    'reddit',
                    'linkedin',
                    'email',
                    'pinterest',
                  ]}
                />
                <CopyToClipboard
                    text={ShareData}
                    onCopy={() => swal('Copied to Clipboard')}
                  >
                    <Link to="#" className='copy-icon'>
                      <i class="fa-solid fa-copy"></i>
                    </Link>
                  </CopyToClipboard>
              </div>
            </Modal.Body>
            </Modal>
          {!Object.keys(details).length ? (
            <LoaderCenter />
          ) : (
            <>
              <div className="c-thumbnial">
                <div
                  className="dropdown dButton"
                  style={{ top: '20px', right: '20px' }}
                >
                  <button
                    class="btn dotsBtn"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="fa-solid fa-ellipsis-vertical"></i>
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <Link
                        onClick={handleShow}
                        to="#"
                        class="dropdown-item"
                        href="#"
                      >
                        Share
                      </Link>
                    </li>
                  </ul>
                </div>
                <button className="back">
                  <Link to="/job_volunteer">
                    <i class="fa-solid fa-arrow-left"></i>
                  </Link>
                </button>
                <img
                  src={details.image[0]}
                  alt="thumbnail"
                  style={{
                    width: '70px',
                    borderRadius: '10px',
                    objectFit: 'cover',
                  }}
                />
              </div>
              <p className="cd-heading">{details.title}</p>
              <p className="cd-by">
                by <span>{details.company}</span>
              </p>
              <div className="salary">
                <span className="key">Salary</span>
                <span className="value">{details.salary} /-</span>
              </div>
              <hr />
              <p className="cd-about-heading">About Property</p>
              <p className="cd-about-body">{details.about}</p>
              <div className="d-flex justify-content-center">
                <button
                  onClick={(e) => {
                    if (details.job_applied === 'True') {
                      swal('You have Already applied for this Job')
                    } else {
                      handleShowAll()
                    }
                  }}
                  className="ep-action"
                  // data-bs-toggle="modal"
                  // data-bs-target="#cm-modal"
                  //        data-bs-toggle="modal"
                  // data-bs-target="#exampleModal2"
                >
                  {details.job_applied === 'True' ? 'Applied' : 'Apply Now '}
                </button>
              </div>
            </>
          )}

          {/* ////////// Modal /// */}

          <Modal
            className="custom-dialog"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showModalApply}
            onHide={handleCloseAll}
          >
            <Modal.Header style={{ padding: '12px', width: '99%' }} closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                // class="modal-content"
                style={{
                  // background: "white",
                  borderRadius: '20px',
                  padding: '30px 20px ',
                  width: '100%',
                  marginTop: '50px',
                }}
              >
                <p className="cm-heading">Apply to Discord</p>
                <div className="cm-input">
                  <label htmlFor="number">Phone Number</label>
                  {/* <input
                    value={phone}
                    type="text"
                    placeholder="phone number"
                    // maxLength={10}
                    onChange={(e) => setPhone(e?.target?.value)}
                  /> */}
                  <PhoneInput
                    style={{
                      height: '40px',
                    }}
                    value={phone}
                    placeholder="Enter phone number"
                    onChange={(e) => setPhone(e?.target?.value)}
                  />
                  <p
                    style={{
                      fontSize: '16px',
                      color: 'red',
                    }}
                  >
                    {errorMsg.phone}
                  </p>
                </div>
                <div className="cm-input">
                  <label htmlFor="number">Email</label>
                  <input
                    value={email}
                    type="text"
                    placeholder="emailhere@gmail.com"
                    onChange={(e) => setEmail(e?.target?.value)}
                  />
                  <p
                    style={{
                      fontSize: '16px',
                      color: 'red',
                    }}
                  >
                    {errorMsg.email}
                  </p>
                </div>
                <div className="cm-input">
                  <label htmlFor="number">Cover Letter</label>
                  <textarea
                    value={cover}
                    type="text"
                    placeholder="Enter your cover letter here"
                    style={{
                      height: '142px',
                    }}
                    onChange={(e) => setCover(e?.target?.value)}
                  />
                  <p
                    style={{
                      fontSize: '16px',
                      color: 'red',
                    }}
                  >
                    {errorMsg.cover}
                  </p>
                </div>
                <ImageUPloadeJOb
                  setFile={(files) => {
                    setResume(files[0])
                  }}
                />
                <p
                  style={{
                    fontSize: '16px',
                    color: 'red',
                  }}
                >
                  {errorMsg.resume}
                </p>

                <div className="row">
                  <div className="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                    <button
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      className="cancel"
                      style={{
                        fontSize: '16px',
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col-12 col-sm-6 d-flex align-items-center justify-content-center mt-3 mt-sm-0 justify-content-sm-start  ">
                    <button
                      className="create"
                      style={{
                        fontSize: '16px',
                      }}
                      onClick={() => applyJob()}
                    >
                      Submit
                    </button>
                    <div
                      class="modal fade"
                      id="exampleModal"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div
                        class="modal-dialog modal-dialog-centered"
                        style={{
                          backgroundColor: '#FFFFFF',
                          borderRadius: '20px',
                          padding: '60px 30px',
                        }}
                      >
                        <div
                          class="modal-content"
                          style={{
                            border: 'none',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <p className="ep-body">
                            Tell your guests what your event is all about! Make
                            sure you tell them the dress code, if food/drinks
                            are included, entry costs and anything else they
                            need to know.
                          </p>
                          <button
                            className="ep-action"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            OKAY
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* modal starts */}
          <div
            class="modal fade"
            id="cm-modal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              class="modal-dialog modal-dialog-centered"
              style={{
                width: '80%',
                maxWidth: '683px',
              }}
            >
              <div
                class="modal-content"
                style={{
                  background: 'white',
                  borderRadius: '20px',
                  padding: '30px 20px ',
                  width: '100%',
                  marginTop: '50px',
                }}
              >
                <p className="cm-heading">Apply to Discord</p>
                <div className="cm-input">
                  <label htmlFor="number">Phone Number</label>
                  {/* <input
                    value={phone}
                    type="text"
                    placeholder="phone number"
                    // maxLength={10}
                    onChange={(e) => setPhone(e?.target?.value)}
                  /> */}
                  <PhoneInput
                    style={{
                      height: '40px',
                    }}
                    value={phone}
                    placeholder="Enter phone number"
                    onChange={(e) => setPhone(e?.target?.value)}
                  />
                  <p
                    style={{
                      fontSize: '16px',
                      color: 'red',
                    }}
                  >
                    {errorMsg.phone}
                  </p>
                </div>
                <div className="cm-input">
                  <label htmlFor="number">Email</label>
                  <input
                    value={email}
                    type="text"
                    placeholder="emailhere@gmail.com"
                    onChange={(e) => setEmail(e?.target?.value)}
                  />
                  <p
                    style={{
                      fontSize: '16px',
                      color: 'red',
                    }}
                  >
                    {errorMsg.email}
                  </p>
                </div>
                <div className="cm-input">
                  <label htmlFor="number">Cover Letter</label>
                  <textarea
                    value={cover}
                    type="text"
                    placeholder="Enter your cover letter here"
                    style={{
                      height: '142px',
                    }}
                    onChange={(e) => setCover(e?.target?.value)}
                  />
                  <p
                    style={{
                      fontSize: '16px',
                      color: 'red',
                    }}
                  >
                    {errorMsg.cover}
                  </p>
                </div>
                <ImageUPloadeJOb
                  setFile={(files) => {
                    setResume(files[0])
                  }}
                />
                <p
                  style={{
                    fontSize: '16px',
                    color: 'red',
                  }}
                >
                  {errorMsg.resume}
                </p>

                <div className="row">
                  <div className="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                    <button
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      className="cancel"
                      style={{
                        fontSize: '16px',
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col-12 col-sm-6 d-flex align-items-center justify-content-center mt-3 mt-sm-0 justify-content-sm-start  ">
                    <button
                      className="create"
                      style={{
                        fontSize: '16px',
                      }}
                      onClick={() => applyJob()}
                    >
                      Submit
                    </button>
                    <div
                      class="modal fade"
                      id="exampleModal"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div
                        class="modal-dialog modal-dialog-centered"
                        style={{
                          backgroundColor: '#FFFFFF',
                          borderRadius: '20px',
                          padding: '60px 30px',
                        }}
                      >
                        <div
                          class="modal-content"
                          style={{
                            border: 'none',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <p className="ep-body">
                            Tell your guests what your event is all about! Make
                            sure you tell them the dress code, if food/drinks
                            are included, entry costs and anything else they
                            need to know.
                          </p>
                          <button
                            className="ep-action"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            OKAY
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* modal ends */}

          {/* Secand Modal */}
          <div
            // show={false}
            // ref={filterModal}
            class="modal fade "
            id="exampleModal2"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              class="modal-dialog modal-dialog-centered  "
              style={{
                width: '80%',
                maxWidth: '656px',
              }}
            >
              <div
                class="modal-content"
                style={{
                  background: 'white',
                  borderRadius: '20px',
                  padding: '30px 20px ',
                  width: '100%',
                  marginTop: '50px',
                }}
              >
                <p className="cm-heading">Apply to Discord</p>
                <div className="cm-input">
                  <label htmlFor="number">Phone Number</label>
                  {/* <input
                    value={phone}
                    type="text"
                    placeholder="phone number"
                    // maxLength={10}
                    onChange={(e) => setPhone(e?.target?.value)}
                  /> */}
                  <PhoneInput
                    style={{
                      height: '40px',
                    }}
                    value={phone}
                    placeholder="Enter phone number"
                    onChange={(e) => setPhone(e?.target?.value)}
                  />
                  <p
                    style={{
                      fontSize: '16px',
                      color: 'red',
                    }}
                  >
                    {errorMsg.phone}
                  </p>
                </div>
                <div className="cm-input">
                  <label htmlFor="number">Email</label>
                  <input
                    value={email}
                    type="text"
                    placeholder="emailhere@gmail.com"
                    onChange={(e) => setEmail(e?.target?.value)}
                  />
                  <p
                    style={{
                      fontSize: '16px',
                      color: 'red',
                    }}
                  >
                    {errorMsg.email}
                  </p>
                </div>
                <div className="cm-input">
                  <label htmlFor="number">Cover Letter</label>
                  <textarea
                    value={cover}
                    type="text"
                    placeholder="Enter your cover letter here"
                    style={{
                      height: '142px',
                    }}
                    onChange={(e) => setCover(e?.target?.value)}
                  />
                  <p
                    style={{
                      fontSize: '16px',
                      color: 'red',
                    }}
                  >
                    {errorMsg.cover}
                  </p>
                </div>
                <ImageUPloadeJOb
                  setFile={(files) => {
                    setResume(files[0])
                  }}
                />
                <p
                  style={{
                    fontSize: '16px',
                    color: 'red',
                  }}
                >
                  {errorMsg.resume}
                </p>

                <div className="row">
                  <div className="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                    <button
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      className="cancel"
                      style={{
                        fontSize: '16px',
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col-12 col-sm-6 d-flex align-items-center justify-content-center mt-3 mt-sm-0 justify-content-sm-start  ">
                    <button
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      className="create"
                      style={{
                        fontSize: '16px',
                      }}
                      onClick={() => applyJob()}
                    >
                      Submit
                    </button>
                    <div
                      class="modal fade"
                      id="exampleModal"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div
                        class="modal-dialog modal-dialog-centered"
                        style={{
                          backgroundColor: '#FFFFFF',
                          borderRadius: '20px',
                          padding: '60px 30px',
                        }}
                      >
                        <div
                          class="modal-content"
                          style={{
                            border: 'none',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <p className="ep-body">
                            Tell your guests what your event is all about! Make
                            sure you tell them the dress code, if food/drinks
                            are included, entry costs and anything else they
                            need to know.
                          </p>
                          <button
                            className="ep-action"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            OKAY
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default CareerDetails

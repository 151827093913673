import React, { useContext, useState, useEffect, useRef , useCallback} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Sidebar from "../../components/Sidebar";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import { Icon } from "@iconify/react";
// import locationIcon from "@iconify/icons-mdi/map-marker";
import profile_picture from "../../assets/images/profile_picture.jpg";
import createselectAvatar from "../../assets/images/createselectAvatar.png";
import createadd_avatar from "../../assets/images/createadd_avatar.png";
import thumbImg from "../../assets/images/house01.png";
import upload from "../../assets/images/upload_icon.svg";
import GoogleMapReact from "google-map-react";
import ApiConfig from "../../api/ApiConfig";
import {
  multipartPostCall,
  multipartPostCallWithErrorResponse,
  postMultipartWithAuthCall,
  postMultipartWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import ImageUplode from "../../sharedComponent/ImageUplode";
import location from "../../assets/images/location.svg";
import Barloader from "../../sharedComponent/BarLoader";
import GoogleMap from "../../components/GoogleMap";
import GoogleMapNew from "../../components/GoogleMapNew";
import Payment from "../payment/Payment";

function CreateOrderManagementProperty() {
  const params = useParams();
  let id = params.id;
  const { sidebar, setSidebar, customerData } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [eventData, setEventData] = useState({});
  const [locationData, setLocationData] = useState({});
  const placeInputRef = useRef(null);
  const [place, setPlace] = useState({
    center: { lat: 19.8762, lng: 75.3433 },
  });

  let [marker, setMarker] = useState(null);
  const [circle, setCircle] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setPlace(place);
  }, [place]);

  useEffect(() => {
    if (locationData.maps && locationData.map) {
      const { map, maps } = locationData;
      // or else call that isApiLoaded function and pass-on these arguments
      renderMarkers(map, maps);
    }
  }, [locationData, place]);

  const getLocation = (lat, lng) => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBxqcBX2r8cybljkDigXBgyOByjeQOmqr0`,
      
      {
        method: "GET", // or 'PUT'
        headers: {
          // "Content-Type": "application/json",
          // 'Access-Control-Allow-Credentials': "*"
        },
        //body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setPlace({
          ...place,
          center: { lat, lng },
          address: data.results[1].formatted_address,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const renderMarkers = (map, maps) => {
    marker && marker.setMap(null);
    circle && circle.setMap(null);
    const cityCircle = new maps.Circle({
      strokeColor: "#00b4bd",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#00b4bd",
      fillOpacity: 0.35,
      map,
      center: { ...place.center },
      // radius: { ...place.center.radius },
      radius: place.radius ? Number(place.radius) : 1000,
    });
    marker = new maps.Marker({
      draggable: true,
      position: { ...place.center },
      map,
    });
    maps.event.addListener(marker, "dragend", async function (event) {
      getLocation(event.latLng.lat(), event.latLng.lng());
    });
    setMarker(marker);
    setCircle(cityCircle);

    return { marker, cityCircle };
  };

  useEffect(() => {
    initPlaceAPI();
  }, []);

  // initialize the google place autocomplete
  const initPlaceAPI = () => {
    let autocomplete = new window.google.maps.places.Autocomplete(
      placeInputRef.current
    );
    new window.google.maps.event.addListener(
      autocomplete,
      "place_changed",
      function () {
        let place = autocomplete.getPlace();

        setEventData({
          ...eventData,
          address: place.name,
          center: {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          },
        });

        setPlace({
          address: place.name,
          center: {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          },
        });
      }
    );
  };

  const [errorMsg, setErrMsg] = useState({
    
    title: "",
    description: "",
    contactInfo: "",
    postType: "",
    isFeatured: "",
    postedDate: "",
    keywords: "",
    images: "",

    companyName: "",
    Occurrence: "",
  });

  const [featureAdd, setFeatureAdd] = useState({
    featured_ads: false,
    featured_ads_duration: "select",
    featured_ads_cost: "£ 1.98",
  });

  const [formdatas, setformdatas] = useState("");
  const [isEnabled, setIsEnabled] = useState(false);
  const [openFeatureAddModel, setOpenFeatureAddModel] = useState(false);
  const [openPaymentAddModel, setOpenPaymentAddModel] = useState(false);

  const handleClose = () => {
    setIsEnabled(false);
    setOpenFeatureAddModel(false);
    setOpenPaymentAddModel(false);
    setFeatureAdd((prevState) => ({ ...prevState, featured_ads: false }));
  };

  const proceedPayment = () => {
    setOpenPaymentAddModel(true);
  };

  const toggleSwitch = () => {
    setOpenFeatureAddModel(true);
    setIsEnabled(!isEnabled);
    setFeatureAdd((prevState) => ({
      ...prevState,
      featured_ads: !prevState.featured_ads,
    }));
  };

  const removeImage = (index) => {
    let property_image = AddProperty.images;

    property_image = property_image.filter(
      (img) => property_image.indexOf(img) != index
    );

    setAddProperty({ ...AddProperty, property_image: property_image });
  };

  const [showElement, setShowElement] = React.useState(true);
  useEffect(() => {
    const timerId = setTimeout(function () {
      setShowElement(false);
    }, 5000);

    return () => clearTimeout(timerId);
  }, []);

  // const AddpropertyMange = async (e) => {
  //   setLoading(true);
  //   setOpenPaymentAddModel(true);

  //   const validationErrors = {
  //     title: AddProperty.title === "",
  //     property_description: AddProperty.property_description === "",
  //     property_image: AddProperty.property_image.length === 0,
  //     property_type: AddProperty.property_type === "select",
  //     bills_included: AddProperty.bills_included === "select",
  //     gender: AddProperty.gender === "select",
  //     term: AddProperty.term === "select",
  //     smokers_allowed: AddProperty.smokers_allowed === "select",
  //     pets_allowed: AddProperty.pets_allowed === "select",
  //     numbers_of_rooms: AddProperty.numbers_of_rooms === "",
  //     availability: AddProperty.availability === "select",
  //     type: AddProperty.type === "select",
  //     deposit: AddProperty.deposit === "select",
  //     monthly_rent: AddProperty.monthly_rent === "",
  //   };

  //   const errorMessages = {
  //     title: "Please enter a title",
  //     property_description: "Please enter a property description",
  //     property_image: "Please upload an image",
  //     property_type: "Please select a property type",
  //     bills_included: "Please select whether bills are included",
  //     gender: "Please select a gender",
  //     term: "Please select the terms",
  //     smokers_allowed: "Please select whether smokers are allowed",
  //     pets_allowed: "Please select whether pets are allowed",
  //     numbers_of_rooms: "Please enter the number of rooms",
  //     availability: "Please select availability",
  //     type: "Please select a type",
  //     deposit: "Please select a deposit",
  //     monthly_rent: "Please enter monthly rent",
  //   };

  //   const errorMessagesToShow = {};

  //   for (const field in validationErrors) {
  //     if (validationErrors[field]) {
  //       errorMessagesToShow[field] = errorMessages[field];
  //     }
  //   }

  //   setErrMsg(errorMessagesToShow);

  //   if (Object.values(validationErrors).every((error) => !error)) {
  //     const datails = JSON.stringify({
  //       // ... (rest of your code)
  //     });

  //     const formData = new FormData();
  //     formData.append("images", AddProperty.property_image[0]);
  //     formData.append("details", datails);
  //     setformdatas(formData);

  //     // ============> api call start <==============
  //     // postMultipartWithAuthCallWithErrorResponse(
  //     //   ApiConfig.UNIVERSR_HOUSING_CREATE_PROPERTY,
  //     //   formData
  //     // )
  //     // .then((res) => {
  //     //   setLoading(false);
  //     //   swal("Property Created Successfully");
  //     //   navigate("/house_listing/all");
  //     // })
  //     // .catch((err) => {
  //     //   setLoading(false);
  //     //   console.log(err);
  //     // });

  //     console.log(datails)
  //     await fetch(ApiConfig.UNIVERSR_HOUSING_CREATE_PROPERTY, {
  //       method: "POST",
  //       body: formData,
  //     }).then((res) => {
  //       setLoading(false);
  //       swal("Property Created Successfully");
  //       // navigate("/house_listing/all");
  //     }).catch((err) => {
  //       setLoading(false);
  //       console.log(err);
  //     });

  //     // ============> api call ends <================
  //   } else {
  //     setLoading(false);
  //   }
  // };

  const handleGenderChange = (e) => {
    const { value, checked } = e.target;
    setAddProperty({
      ...AddProperty,
      housingDetails: {
        ...AddProperty.housingDetails,
        gender: checked
          ? [...AddProperty.housingDetails.gender, value]
          : AddProperty.housingDetails.gender.filter((item) => item !== value),
      }
    });
  };


  

  const [AddProperty, setAddProperty] = useState({
  user: "", 
  orderDate: Date,
  orderStatus: "",
  paymentId: "",
  paymentStatus: "",
  totalProducts: 0,
  totalPrice: 0,
  totalDiscount: "",
  productId: "",
  ordermanegmentNumber: "",

  address: {
    fullName: "",
    mobile: "",
    address: "",
    city: "",
    pin: "",
  }
  });

  const handleDrop = useCallback((acceptedFiles) => {
    const files = AddProperty.images || [];
    const newFiles = acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) }));
    setAddProperty(prevState => ({ ...prevState, images: [...files, ...newFiles] }));

    
  }, [AddProperty]);

  const AddpropertyMange = async (e) => {
    setLoading(true);
    let details = JSON.stringify({
      user: AddProperty.user || "",
      orderDate: AddProperty?.orderDate ? new Date(AddProperty.orderDate) : new Date(),
      orderStatus: AddProperty.orderStatus || "",
      paymentId: AddProperty.paymentId || "",
      totalProducts: AddProperty.totalProducts || "",
      totalPrice: AddProperty.totalPrice || "",
      totalDiscount: AddProperty.totalDiscount || "",
      productId: AddProperty.productId || "",
      ordermanegmentNumber: AddProperty.ordermanegmentNumber || "",

      address:{
        fullName: AddProperty.address.fullName || "",
        mobile: AddProperty.address.mobile || "",
        address: AddProperty.address.address || "",
        city: AddProperty.address.city || "",
        pin: AddProperty.address.pin || "",     
      },
    }
    );

    const formData = new FormData();

    Object.keys(AddProperty).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(AddProperty, key)) {
        const value = AddProperty[key];
        if (value !== undefined) {
          if (key === "address") {
            const address = value;
            Object.keys(address).forEach((detailKey) => {
              if (Object.prototype.hasOwnProperty.call(address, detailKey)) {
                const detailValue = address[detailKey];
                if (detailValue !== undefined) {
                  if (typeof detailValue === "string" || typeof detailValue === "number" || typeof detailValue === "boolean") {
                    formData.append(`address.${detailKey}`, detailValue.toString());
                  } else if (Array.isArray(detailValue) || typeof detailValue === "object") {
                    formData.append(`address.${detailKey}`, JSON.stringify(detailValue));
                  }
                }
              }
            });
          }
          else if(key === "images"){
            const images = value;
            Array.from(images).forEach((file) => {
              formData.append("files", file);
            });
            

          } else {
            formData.append(key, value.toString());
          }
        }
      }
    });

    setformdatas(formData);
    console.log("details", details);

    postMultipartWithAuthCallWithErrorResponse(ApiConfig.UNIVERSR_AD_CREATE_PROPERTY, formData)
      .then((res) => {
        setLoading(false);
        swal("Property Created Successfully");
        navigate("/adCampaing/all");
      })
      .catch((err) => {
        setLoading(false);
        swal("Property Created Unsuccessful");
        console.log(err);
      });
  };


  
  return (
    <div style={{ overflowY: "hidden" }}>
      <Sidebar />

      {/* <Header /> */}
      <main id="cxMain" className={sidebar ? "cx-main cx-active" : "cx-main"}>
        <section
          className="content-main"
          style={{
            backgroundColor: "white",
            borderRadius: "20px",
            height: "calc(100vh - 156px)",
          }}
        >
          <div className="row">
            <div className="col-md-12 scroll-hidden">
              <div
                className="create_screenWrapper"
                style={{
                  overflow: "auto",
                  height: "calc(100vh - 156px)",
                }}
              >
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Create your Property
                  </h5>
                </div>

                <div className="profile_modal_contentWrapper">
                  {loading && <Barloader />}
                  <div className="row">
                    {/* left side */}
                    <div
                      className="col-md-6 profile_form"
                      style={{ marginTop: "0px" }}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className="uploadPP_inputTextRow"
                            // style={{ width: "100%" }}type
                          >
                            <label class="form-label">User</label>
                            <input
                              type="text"
                              class="form-control"
                              style={{ height: "40px" }}
                              value={AddProperty.user}
                              placeholder="Property User "
                              onChange={(e) => {
                                // setErrMsg({ ...errMsg, title: "" });

                                setAddProperty({
                                  ...AddProperty,
                                  user: e.target.value,
                                });
                              }}
                            />
                            <small
                              style={{
                                color: "red",
                              }}
                            >
                              {/* {errorMsg.title} */}
                            </small>
                            {/* {errMsg.title.length > 0 && (
                              <div className="text-center text-danger">
                                <span> {errMsg.title}</span>
                              </div>
                            )} */}
                          </div>
                        </div>

                        
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className="uploadPP_inputTextRow"
                            // style={{ width: "100%" }}type
                          >
                            <label
                              class="form-label"
                              style={{ marginTop: "20px" }}
                            >
                              Contact Info
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              style={{ height: "40px" }}
                              value={AddProperty.contactInfo}
                              placeholder="Contact info"
                              onChange={(e) => {
                                // setErrMsg({ ...errMsg, title: "" });

                                setAddProperty({
                                  ...AddProperty,
                                  contactInfo: e.target.value,
                                });
                              }}
                            />
                            <small
                              style={{
                                color: "red",
                              }}
                            >
                              {/* {errorMsg.contactInfo} */}
                            </small>
                            {/* {errMsg.title.length > 0 && (
                              <div className="text-center text-danger">
                                <span> {errMsg.title}</span>
                              </div>
                            )} */}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">

                        </div>
                      </div>

                     
<div className="col-lg-12">
                          <div className="uploadPP_inputTextRow usalabel">
                            <div className="upp_selectWrapper">
                              <div className="">
                                <div
                                  className="uploadPP_inputTextRow"
                                  style={{ width: "100%" }}
                                >
                                  <div
                                    className="ip"
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <label
                                      class="form-label"
                                      style={{ marginTop: "20px" }}
                                    >
                                      <input
          type="checkbox"
          checked={AddProperty.billsIncluded} // Bind switch to the state
          onChange={(e) => setAddProperty({ ...AddProperty, billsIncluded: e.target.checked })} // Handle switch change
        />{" "}
                                       is Featured
                                    </label>
                                  </div>
                                  <small
                                    style={{
                                      color: "red",
                                    }}
                                  >
                                    {errorMsg.isFeatured}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                     

                      <div className="row">
                        <div className="col-md-12">

                        </div>
                      </div>

                      {/* <div className="row">
                        <div className="col-md-12">
                           <div
                            className="uploadPP_inputTextRow"
                            // style={{ width: "100%" }}type
                          >
                            <label class="form-label">Keywords</label>
                            <input
                              type="text"
                              class="form-control"
                              style={{ height: "40px" }}
                              value={AddProperty.keywords}
                              placeholder="Keywords"
                              onChange={(e) => {
                                // setErrMsg({ ...errMsg, title: "" });

                                setAddProperty({
                                  ...AddProperty,
                                  keywords: e.target.value,
                                });
                              }}
                            />
                            <small
                              style={{
                                color: "red",
                              }}
                            >
                              {errorMsg.keywords}
                            </small>
                            {/* {errMsg.title.length > 0 && (
                              <div className="text-center text-danger">
                                <span> {errMsg.title}</span>
                              </div>
                            )}
                          </div> 
                        </div>
                      </div>  */}

                       <div className="row mb-4 align-items-center gy-3">
                        <div
                          style={
                            {
                              // marginTop: "70px",
                            }
                          }
                          className="col-12 col-sm-6 col-xl-4 custom-resp-1"
                        >
                          <div
                            style={{
                              height: "148px",
                              width: "148px",
                              margin: "0",
                              padding: "0",
                            }}
                          >
                          
                            {/* <ImageUplode
                                setFile={(files) => {
                                  setAddProperty(prevState => ({
                                    ...prevState,
                                    images: [...prevState.images, ...files],
                                  }));
                                }}
                              /> */}

                            {/* <ImageUplode
                              setFile={(files) => {
                                setAddProperty(prevState => ({
                                  ...prevState,
                                  images: [...prevState.images, ...files],
                                }));
                              }}
                            /> */}

                            <small
                              style={{
                                color: "red",
                              }}
                            >
                              {errorMsg.images}
                            </small>
                          </div>
                        </div>

                        {AddProperty.images.map((img, index) => {
                          return (
                            <div className="col-12 col-sm-6 col-xl-4 ">
                              <div
                                style={{
                                  width: "148px",
                                  // marginTop: "10px",
                                }}
                                className="uploaded-image-1 uploaded-reps-1"
                              >
                                <div className="item_list" key={"img" + index}>
                                  <img
                                    width={72}
                                    height="72"
                                    className="img"
                                    src={URL.createObjectURL(img)}
                                    alt=""
                                  />
                                  <a
                                    style={{
                                      position: "absolute",
                                      top: "10px",
                                      right: "0px",
                                      // marginTop: "10px",
                                      // marginLeft: "-0px",
                                    }}
                                    href="#"
                                    className=""
                                  >
                                    <i
                                      onClick={() => removeImage(index)}
                                      class="fa-solid fa-xmark"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div> 
                    </div>
                    <div className="col-lg-6 main-property-type">
                      <div className="row">
                        {/* PropertyType */}


                        <div className="col-md-12">
                          <label htmlFor="Occurrence" className="ce-label">
                          Occurrence
                          </label>
                          <input
                            value={AddProperty.adsDetails.Occurrence}
                            type="text"
                            name="Occurrence"
                            id="Occurrence"
                            className="ce-input"
                            placeholder=""
                            required
                            onChange={(e) => {
                              // setErrMsg({ ...errMsg, eventType: '' })
              
                              setAddProperty({
                                ...AddProperty,
                                adsDetails: {
                                  ...AddProperty.adsDetails,
                                  Occurrence: e.target.value,
                                },
                              })
                            }}
                          />
                          <small
                            style={{
                              color: 'red',
                            }}
                          >
                            {/* {errMsg.eventType} */}
                          </small>
                        </div>

                        <div className="col-md-12">
                          <label htmlFor="companyName" className="ce-label">
                          Company Name
                          </label>
                          <input
                            value={AddProperty.adsDetails.companyName}
                            type="text"
                            name="companyName"
                            id="companyName"
                            className="ce-input"
                            placeholder=""
                            required
                            onChange={(e) => {
                              // setErrMsg({ ...errMsg, eventType: '' })
              
                              setAddProperty({
                                ...AddProperty,
                                adsDetails: {
                                  ...AddProperty.adsDetails,
                                  companyName: e.target.value,
                                },
                              })
                            }}
                          />
                          <small
                            style={{
                              color: 'red',
                            }}
                          >
                            {/* {errMsg.eventType} */}
                          </small>
                        </div>



                       



                        
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                        }}
                      >
                        {/* <h5 class="modal-title">Feature-Add</h5> */}

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "5px",
                            paddingLeft: "2px",
                            paddingRight: "2px",
                          }}
                        >
                          {/* <div
                            style={{
                              border: "1px solid #ccc",
                              borderRadius: "8px",
                              marginTop: "5px",
                              width: "100%",
                              // marginLeft: '4px',
                              backgroundColor: "black",
                              padding: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                paddingLeft: "4px",
                                marginTop: "4px",
                                marginBottom: "3px",
                                alignItems: "center",
                              }}
                            >
                              <p
                                className="reporttext"
                                style={{ color: "white" }}
                              >
                                Make this a featured post?
                              </p>

                              <div
                                style={{
                                  position: "relative",
                                  display: "inline-block",
                                  transition: "all 200ms ease-in-out",
                                  marginRight: "15px",
                                }}
                              >
                                <label
                                  style={{
                                    position: "relative",
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    className="sr-only"
                                    checked={isEnabled}
                                    onChange={toggleSwitch}
                                  />

                                  <div
                                    style={{
                                      width: "50px",
                                      height: "28px",
                                      borderRadius: "50px",
                                      transition: "all 300ms",
                                      backgroundColor: isEnabled
                                        ? "#00B4BD"
                                        : "gray",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "28px",
                                        height: "28px",
                                        borderRadius: "50%",
                                        transition: "all 300ms",
                                        transform: isEnabled
                                          ? "translateX(28px)"
                                          : "",
                                        backgroundColor: "white",
                                        boxShadow:
                                          "0 4px 6px rgba(0, 0, 0, 0.1)",
                                      }}
                                    ></div>
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      {openFeatureAddModel && isEnabled ? (
                        <Modal
                          className="custom-dialog"
                          aria-labelledby="contained-modal-title-vcenter"
                          centered
                          show={openFeatureAddModel}
                          onHide={handleClose}
                        >
                          <Modal.Header
                            style={{ padding: "12px", width: "99%" }}
                            closeButton
                          >
                            <Modal.Title>About Featured Post</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            {/* Are you sure you want to cancel this Friend Request? */}
                            <div
                              class="modal-body"
                              style={{ marginTop: "-37px" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "1.25rem",
                                  marginTop: "1.25rem",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span>Duration</span>

                                  <span style={{ fontWeight: "bold" }}>
                                    Boost this post for
                                  </span>

                                  <select
                                    style={{
                                      width: "150px",
                                      color: "#707070",
                                      height: "50px",
                                      fontSize: "12px",
                                      background: "white",
                                      display: "flex",
                                      alignItems: "center",
                                      borderRadius: "10px",
                                      border: "1px solid #01B3BB",
                                      paddingTop: "14px",
                                      paddingRight: "30px",
                                      paddingBottom: "14px",
                                      paddingLeft: "20px",
                                      fontWeight: "bold",
                                    }}
                                    value={featureAdd.featured_ads_duration}
                                    onChange={(e) =>
                                      setFeatureAdd((prevState) => ({
                                        ...prevState,
                                        featured_ads_duration: e.target.value,
                                      }))
                                    }
                                    // onClick={handlePrice}
                                  >
                                    <option value="select">
                                      Select Duration
                                    </option>

                                    <option value="1 day">1 day</option>

                                    <option value="1 week">1 Week</option>

                                    <option value="2 week">2 Week</option>

                                    <option value="3 Week">3 Week</option>

                                    <option value="1 Month">1 Month</option>
                                  </select>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span>Price</span>

                                  <input
                                    style={{
                                      width: "150px",
                                      color: "#707070",
                                      height: "50px",
                                      fontSize: "12px",
                                      background: "white",
                                      display: "flex",
                                      alignItems: "center",
                                      borderRadius: "10px",
                                      border: "1px solid #01B3BB",
                                      paddingTop: "14px",
                                      paddingRight: "30px",
                                      paddingBottom: "14px",
                                      paddingLeft: "20px",
                                      fontWeight: "bold",
                                    }}
                                    // placeholder="Feature Add Cost"
                                    value={featureAdd.featured_ads_cost}
                                    readOnly={true}
                                    onChange={(e) =>
                                      setFeatureAdd((prevState) => ({
                                        ...prevState,
                                        featured_ads_cost: e.target.value,
                                      }))
                                    }
                                  />
                                </div>

                                <button
                                  style={{
                                    marginTop: "8px",
                                    width: "100%",
                                    height: "67.19px",
                                    flexShrink: 0,
                                    border: "10px solid transparent",
                                    borderRadius: "10px",
                                    backgroundColor: "#01B3BB",
                                    color: "#FEFCFE",
                                    fontFamily: "montserrat",
                                    fontWeight: "bold",
                                    fontSize: "18px",
                                    textTransform: "uppercase",
                                  }}
                                  onClick={() => {
                                    setOpenPaymentAddModel(true);
                                  }}
                                >
                                  Proceed Payment
                                </button>
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                      ) : (
                        <></>
                      )}

                      {openPaymentAddModel && (
                        <Modal
                          className="custom-dialog"
                          aria-labelledby="contained-modal-title-vcenter"
                          centered
                          show={openFeatureAddModel}
                          onHide={handleClose}
                        >
                          <Modal.Header
                            style={{ padding: "12px", width: "99%" }}
                            closeButton
                          >
                            <Modal.Title>About Payment</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            {/* Are you sure you want to cancel this Friend Request? */}
                            <div
                              class="modal-body"
                              style={{ marginTop: "-37px" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "1.25rem",
                                  marginTop: "1.25rem",
                                }}
                              >
                                {/* <Payment
                                  onSuccess={onPaymentSuccess}
                                  setOpenPaymentAddModel={
                                    setOpenPaymentAddModel
                                  }
                                /> */}
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                      )}
                    </div>
                  </div>

                  {/* tright side`` */}

                  <div
                    className="create_screenControls"
                    style={{
                      marginTop: "-20px",
                      borderColor: "#00B4BD",
                      color: "#00B4BD",
                    }}
                  >
                    <Link to="/adCampaing/all" className="dis">
                      Cancel
                    </Link>
                    <Link onClick={AddpropertyMange} className="primary" to="#">
                      Create Property
                    </Link>
                    {/* <button
                      className="primary"
                      to="#"
                      onClick={Addproperty}
                      style={{ padding: "13PX", width: "140px" }}
                    >
                      Update Profile
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default CreateOrderManagementProperty;

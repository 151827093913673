import React, { useEffect, useState } from 'react'
import { AppContext } from './AppContext'
import openSocket, { io } from 'socket.io-client'

export const AppState = (props) => {
  const [sidebar, setSidebar] = React.useState(false)
  const [locationData, setLocationData] = useState({})
  let [marker, setMarker] = useState(null)
  const [circle, setCircle] = useState(null)
  let option = {
    timeout: 20000,
    query: {
      UID: localStorage.getItem('id'),
    },
  }

  const [customerData, setCustomerData] = useState({
    id: localStorage.getItem('id') ? localStorage.getItem('id') : null,
    lat: localStorage.getItem('lat') ? localStorage.getItem('lat') : null,
    long: localStorage.getItem('long') ? localStorage.getItem('long') : null,
    radius: localStorage.getItem('radius')
      ? localStorage.getItem('radius')
      : null,
    location: localStorage.getItem('location')
      ? localStorage.getItem('location')
      : null,

    auth_token: localStorage.getItem('auth_token')
      ? localStorage.getItem('auth_token')
      : null,
  })
  const [socket,] = useState(io('https://api.myunivrs.com:3000?UID='+customerData.id, option))
  

  const [place, setPlace] = useState({
    radius: '10.00',
    center: {
      lat:
        localStorage.getItem('lat') && localStorage.getItem('lat').length > 0
          ? Number(localStorage.getItem('lat'))
          : 19.8762,
      lng:
        localStorage.getItem('long') && localStorage.getItem('long').length > 0
          ? Number(localStorage.getItem('long'))
          : 75.3433,
      radius:
        localStorage.getItem('radius') &&
        localStorage.getItem('radius').length > 0
          ? Number(localStorage.getItem('radius'))
          : 1,
    },
    address: customerData.location == null ? '' : customerData.location,
  })
  const [keyword, setKeyword] = useState({
    keywordData: localStorage.getItem('keywordData')
      ? localStorage.getItem('keywordData')
      : null,
  })
 

  const [cartchecked, setchecked] = useState([])
  const [adreeschecked, setAdreeschecked] = useState('')
  const [ActiveTab, setActiveTab] = useState('Friends')
  const [InterstedItem, setInterstedItem] = useState('')
  console.log(InterstedItem)
  const [idDetails, setIdDetails] = useState({
    productId: '',
    productName: '',
    quantity: '',
    buyPrice: '',
  })
  const [totalPrice, setTotalPrice] = useState([])
  const [totalItem, setTotalItem] = useState([])
  const [notificationTotal, setNotificationTotal] = useState(0)
  const [image, setImage] = useState('')
  console.log("image",image.name)

  return (
    <AppContext.Provider
      value={{
        image, setImage,
        InterstedItem, setInterstedItem,
        sidebar,
        setSidebar,
        customerData,
        ActiveTab, 
        setActiveTab,
        setCustomerData,
        keyword,
        setKeyword,
        locationData,
        setLocationData,
        place,
        setPlace,
        marker,
        setMarker,
        circle,
        setCircle,
        setchecked,
        cartchecked,
        setAdreeschecked,
        adreeschecked,
        setIdDetails,
        idDetails,
        setTotalPrice,
        totalPrice,
        setTotalItem,
        totalItem,
        setNotificationTotal,
        notificationTotal,
        socket,
        baseUrl:  "http://localhost:5000/api"
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}

import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, useLocation, NavLink, Outlet } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Sidebar from "../../components/Sidebar";
import thumbImg from "../../assets/images/watch.png";
import back from "../../assets/images/back.svg";
import search_icon from "../../assets/images/search_icon.svg";
import fillter_icon from "../../assets/images/fillter_icon.svg";
import VoucherHealth from "./VoucherHealth";
import MyCart from "./MyCart";
import Orders from "./Orders";
import ApiConfig from "../../api/ApiConfig";
import LoaderCenter from "../../sharedComponent/LoaderCenter";
import eventthumbImg01 from "../../assets/images/house01.png";

import {
  deleteWithAuthCall,
  simpleGetCallWithErrorResponse,
  simplePostCall,
} from "../../api/ApiServices";
import swal from "sweetalert";
import { Slider } from "../../components/Slider";
function Health() {
  const [keyword, setKeyword] = useState("");
  // const [refresh, setRefresh] = useState(false);
  // wishlist start
  const { customerData } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [healthwishlist, setHealthWishlist] = useState([]);
  console.log(healthwishlist);

  // useEffect(() => {
  //   getHealthWish();
  // }, [refresh]);

  const getHealthWish = () => {
    setLoading(true);

    simpleGetCallWithErrorResponse(
      ApiConfig.GET_HEALTH_MYWISHLIST,
      JSON.stringify({ ...customerData })
    ).then((res) => {
      console.log(res);
      setLoading(false);
      setHealthWishlist(res.json.details);
    });
  };

  const healthUnSave = (id) => {
    simplePostCall(
      ApiConfig.LIKE_DISLIKE_HEALTH_PRODUCT,
      JSON.stringify({ ...customerData, product_id: id })
    )
      .then((res) => {
        console.log(res);
        swal(res.detail);
        getHealthWish();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DeleteHealt = (id) => {
    deleteWithAuthCall(ApiConfig.HEALTHPRODUCT_DELETE + id)
      .then((res) => {
        console.log(res);
        swal(res.detail);
        getHealthWish();
      })
      .catch((err) => {
        setLoading(false);

        console.log(err);
      });
  };

  const [healthproducts, setHealthProducts] = useState([]);
  const getSearchProduct = async () => {
    const data = await simpleGetCallWithErrorResponse(
      ApiConfig.HEALTH_SEARCH + keyword
    );

    if (data?.json?.search) {
      setHealthProducts(data?.json?.search);
    }
  };
  useEffect(() => {
    if (keyword.length > 0) {
      getSearchProduct();
    } else {
      getHealthProduct();
    }
  }, [keyword]);

  const getHealthProduct = () => {
    setLoading(true);

    simpleGetCallWithErrorResponse(
      ApiConfig.HEALTHPRODUCT_LIST,
      JSON.stringify({ ...customerData })
    ).then((res) => {
      console.log(res);
      setLoading(false);
      setHealthProducts(res.json.helth);
    });
  };

  const healthSave = (id) => {
    simplePostCall(
      ApiConfig.LIKE_DISLIKE_HEALTH_PRODUCT,
      JSON.stringify({ like_dislike_status: "like", product_id: id })
    )
      .then((res) => {
        console.log(res);
        // setRefresh(!refresh);
        // getHealthWish();
        swal(res.detail);
        getHealthWish();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // product ends
  const [status, setStatus] = useState(0);

  const ipRef = useRef(0);
  function handleSearch() {
    setKeyword(ipRef.current.value);
  }
  const { sidebar, setSidebar } = useContext(AppContext);
  const [filter, setFilter] = useState(
    useLocation().pathname === "/electronics/products"
  );
  const [showElement, setShowElement] = React.useState(true);

  function isUpdate() {
    setStatus(Math.random());
  }

  // api starts

  const Accordion = ({ title, children }) => {
    const [isOpen, setOpen] = React.useState(false);
    return (
      <div className="accordion-wrapper">
        <div
          className={`accordion-title ${isOpen ? "open" : ""}`}
          onClick={() => setOpen(!isOpen)}
        >
          {title}
        </div>
        <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
          <div className="accordion-content">{children}</div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div>
        <Sidebar />

        {/* <Header /> */}
        <main id="cxMain" className={sidebar ? "cx-main cx-active" : "cx-main"}>
          <section
            className="content-main"
            style={{
              backgroundColor: "white",
              borderRadius: "20px",
              height: "fit-content",
              minHeight: "calc(100vh - 156px)",
              padding: "20px",
            }}
          >
            <ul class="nav nav-pills mb-3 " id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link tab-btn active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Voucher
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link tab-btn"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Restaurent
                </button>
              </li>
              <li
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                style={{
                  cursor: "pointer",
                }}
              >
                <img src={fillter_icon} alt="" />
              </li>
              <li>
                <div
                  className="search-input"
                  style={{
                    width: "224px",
                  }}
                >
                  <input
                    ref={ipRef}
                    type="text"
                    name="text"
                    id="text"
                    placeholder="Search health"
                    style={{
                      width: "100%",
                    }}
                  />
                  <img
                    src={search_icon}
                    alt="search"
                    onClick={() => handleSearch()}
                  />
                </div>
              </li>

              <li class="nav-item" role="presentation">
                <button
                  class="nav-link tab-btn "
                  id="pills-cart-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-cart"
                  type="button"
                  role="tab"
                  aria-controls="pills-cart"
                  aria-selected="false"
                >
                  My Cart
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link tab-btn "
                  id="pills-order-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-order"
                  type="button"
                  role="tab"
                  aria-controls="pills-order"
                  aria-selected="false"
                >
                  My Orders
                </button>
              </li>
              <li
                class="nav-item"
                role="presentation"
                style={{
                  marginLeft: "auto",
                }}
              >
                <button
                  class="nav-link tab-btn"
                  id="pills-contact-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-contact"
                  type="button"
                  role="tab"
                  aria-controls="pills-contact"
                  aria-selected="false"
                >
                  My Wishlist
                </button>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
                tabindex="0"
              >
                <VoucherHealth />
              </div>
              <div
                class="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
                tabindex="0"
              >
                <>
                  {loading ? (
                    <LoaderCenter />
                  ) : (
                    <>

                      {
                        healthproducts.length > 0 && <div className=''>
                          <Slider sliderImages={
                            healthproducts.map((item) => <Link key={item.id} to={'#'}>
                              <div className="slider-content">
                                <img src={item.image ? `${ApiConfig.BASE_NEW_FOR_IMAGES + item.image}` : eventthumbImg01} className="slider-content-image" />
                              </div>
                            </Link>)
                          } />
                        </div>
                      }
                      <div className="row row-gap-20">
                        {healthproducts && healthproducts.length > 0 ? (
                          healthproducts.map((healthproductsAll, index) => {

                            return (
                              <div className="col-12 col-sm-6  col-lg-4 col-xxl-3">
                                <div className="gbl_thumbWrapper">
                                  <label className="heart-check">
                                    <input
                                      onClick={() => {
                                        healthSave(healthproductsAll.id);
                                      }}
                                      class="check"
                                      type="checkbox"
                                    />
                                    <span class="label-text"></span>
                                  </label>

                                  <div className="img_thumbWrapper">
                                    <img
                                      src={healthproductsAll.image}
                                      height="158px"
                                      alt=""
                                      style={{
                                        objectFit: "cover",
                                      }}
                                    />
                                  </div>

                                  <div className="gbl_thumbContent">
                                    <h1
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        color: "hsla(240, 12%, 13%, 1)",
                                        marginTop: "5px",
                                        letterSpacing: "0",
                                        font: "normal normal 600 16px/22px Nunito",
                                        opacity: "0px",
                                      }}
                                    >
                                      {healthproductsAll.brand}
                                    </h1>
                                    <p
                                      style={{
                                        color: "var(--unnamed-color-707070)",
                                        font: "normal normal normal 14px/19px Nunito",
                                        letterSpacing: "0px",
                                        color: "hsla(0, 0%, 44%, 1)",
                                        opacity: "1px",
                                        marginTop: "12px",
                                      }}
                                    >
                                      {healthproductsAll.price}/-
                                    </p>
                                    <Link
                                      to={`/health-details/${healthproductsAll.id}`}
                                    // to={"/health-details"}
                                    // state={healthproductsAll}
                                    // to={"/health-details/" + healthproductsAll.id}
                                    >
                                      <img
                                        style={{
                                          marginTop: "-25px",
                                          float: "right",
                                        }}
                                        src={back}
                                        alt=""
                                      />
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <p
                            style={{
                              fontSize: "25px",
                              margin: "auto",
                            }}
                            className=" text-center justify-content-center"
                          >
                            Data not found
                          </p>
                        )}
                      </div>
                    </>)}
                  <div
                    class="modal fade"
                    id="exampleModal"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div
                      class="modal-dialog modal-dialog-centered"
                      style={{
                        margin: "auto",
                        width: "80%",
                        maxWidth: "377px",
                      }}
                    >
                      <div
                        class="modal-content"
                        style={{
                          borderRadius: "20px",
                          backgroundColor: "#F5F5F5",
                          padding: "20px",
                        }}
                      >
                        <p className="pf-heading">Filter Health & Beauty</p>
                        <div className="pf-body">
                          <p className="pf-label">Choose Product Type</p>
                          <Accordion title="Brand">
                            <div className="pf-option">
                              <input type="radio" id="check1" name="same" />
                              <label htmlFor="check1">All Brands</label>
                            </div>
                            <div className="pf-option">
                              <input type="radio" id="check2" name="same" />
                              <label htmlFor="check2">111Skin</label>
                            </div>
                            <div className="pf-option">
                              <input type="radio" id="check3" name="same" />
                              <label htmlFor="check3">Aesop</label>
                            </div>
                            <div className="pf-option">
                              <input type="radio" id="check4" name="same" />
                              <label htmlFor="check4">Allies of Skin</label>
                            </div>
                          </Accordion>
                          <Accordion title="Skincare Product Type">
                            <div className="pf-option">
                              <input type="radio" id="size1" name="size" />
                              <label htmlFor="size1">100 $ - 200 $</label>
                            </div>
                            <div className="pf-option">
                              <input type="radio" id="size2" name="size" />
                              <label htmlFor="size2">100 $ - 200 $</label>
                            </div>
                            <div className="pf-option">
                              <input type="radio" id="size3" name="size" />
                              <label htmlFor="size3">100 $ - 200 $</label>
                            </div>
                            <div className="pf-option">
                              <input type="radio" id="size4" name="size" />
                              <label htmlFor="size4">100 $ - 200 $</label>
                            </div>
                          </Accordion>
                        </div>

                        <div
                          className="text-center"
                          style={{
                            marginTop: "20px",
                          }}
                        >
                          <button
                            className="ep-action"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            style={{
                              marginTop: "0px",
                            }}
                          >
                            Apply Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  ;
                </>
              </div>
              <div
                class="tab-pane fade"
                id="pills-cart"
                role="tabpanel"
                aria-labelledby="pills-cart-tab"
                tabindex="0"
              >
                <MyCart />
              </div>
              <div
                class="tab-pane fade"
                id="pills-order"
                role="tabpanel"
                aria-labelledby="pills-order-tab"
                tabindex="0"
              >
                <Orders />
              </div>
              <div
                class="tab-pane fade"
                id="pills-contact"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
                tabindex="0"
              >
                <>
                  {loading ? (
                    <LoaderCenter />
                  ) : (
                    <div className="row row-gap-20">
                      {healthwishlist && healthwishlist.length > 0 ? (
                        healthwishlist.map((healthwishlistAll, index) => {

                          return (
                            <div className="col-12 col-sm-6  col-lg-4 col-xxl-3">
                              <div
                                className="gbl_thumbWrapper"
                                style={{
                                  position: "relative",
                                }}
                              >
                                {/* <label style={{
                      
                    }} className="heart-check">
                      <input
                        // onClick={() => {
                        //   healthSave(healthproductsAll.id);
                        // }}
                        class="check"
                        type="checkbox"
                      />
                      <span class="label-text"></span>
                    </label> */}
                                <button
                                  class="eventMenu"
                                  type="button"
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  style={{
                                    position: "absolute",
                                    top: "10px",
                                    right: "10px",
                                    zIndex: "1000",
                                  }}
                                >
                                  <i class="fa-solid fa-ellipsis-vertical"></i>
                                </button>
                                <ul
                                  class="dropdown-menu career-drop"
                                  aria-labelledby="dropdownMenuButton1"
                                  style={{
                                    backgroundColor: "#F5F5F5",
                                    border: "1px solid #ECECEC",
                                    position: "absolute",
                                    top: "10px",
                                    right: "10px",
                                  }}
                                >
                                  <li>
                                    <Link
                                      to="#"
                                      onClick={() => {
                                        healthUnSave(healthwishlistAll.id);
                                      }}
                                      class="dropdown-item"
                                      href="#"
                                    >
                                      UnSave
                                    </Link>
                                  </li>

                                  <li>
                                    <Link
                                      onClick={() => {
                                        DeleteHealt(healthwishlistAll.id);
                                      }}
                                      to="#"
                                      class="dropdown-item"
                                      href="#"
                                    >
                                      Delete
                                    </Link>
                                  </li>
                                </ul>

                                <div className="img_thumbWrapper">
                                  {/* <img
                        src={healthwishlistAll.product_detail.image}
                        height="158px"
                        alt=""
                        style={{
                          objectFit: "cover",
                        }}
                      /> */}
                                  {healthwishlistAll.product_detail.image ? (
                                    <img
                                      src={
                                        healthwishlistAll.product_detail.image
                                      }
                                      alt=""
                                      height="158px"
                                      style={{
                                        objectFit: "cover",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={thumbImg}
                                      alt=""
                                      height="158px"
                                      style={{
                                        objectFit: "cover",
                                      }}
                                    />
                                  )}
                                </div>

                                <div className="gbl_thumbContent">
                                  <h1
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "500",
                                      color: "hsla(240, 12%, 13%, 1)",
                                      marginTop: "5px",
                                      letterSpacing: "0",
                                      font: "normal normal 600 16px/22px Nunito",
                                      opacity: "0px",
                                    }}
                                  >
                                    {
                                      healthwishlistAll.product_detail
                                        .product_name
                                    }
                                  </h1>
                                  <p
                                    style={{
                                      color: "var(--unnamed-color-707070)",
                                      font: "normal normal normal 14px/19px Nunito",
                                      letterSpacing: "0px",
                                      color: "hsla(0, 0%, 44%, 1)",
                                      opacity: "1px",
                                      marginTop: "12px",
                                    }}
                                  >
                                    Starting from{" "}
                                    {healthwishlistAll.product_detail.price}
                                    /-
                                  </p>
                                  <Link to="#">
                                    <img
                                      style={{
                                        marginTop: "-25px",
                                        float: "right",
                                      }}
                                      src={back}
                                      alt=""
                                    />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p
                          style={{
                            fontSize: "25px",
                            margin: "auto",
                          }}
                          className=" text-center justify-content-center"
                        >
                          Data not found
                        </p>
                      )}
                    </div>
                  )}
                </>
              </div>
            </div>
          </section>
        </main>
      </div>

      {/* modal starts */}
      {/* <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          style={{
            margin: "auto",
            width: "80%",
            maxWidth: "377px",
          }}
        >
          <div
            class="modal-content"
            style={{
              borderRadius: "20px",
              backgroundColor: "#F5F5F5",
              padding: "20px",
            }}
          >
            <p className="pf-heading">Filter Dining</p>
            <div className="pf-body">
              {" "}
              <Accordion title="Price">
                <div className="pf-option">
                  <input type="checkbox" id="check1" />
                  <label htmlFor="check1">100 $ - 200 $</label>
                </div>
              </Accordion>
              <div className="input-cover">
                <input
                  type="text"
                  name="text"
                  id="text"
                  className="pf-search"
                  placeholder="Search Dining"
                />
                <img src={search_icon} alt="search" />
              </div>
              <div className="search-results">
                <div className="search-result d-flex flex-column  flex-sm-row align-items-center ">
                  <img src={result_icon} alt="icon" />
                  <span className="mt-2 mt-sm-0">The Kings Restaurant</span>
                </div>
                <div className="search-result d-flex flex-column  flex-sm-row align-items-center ">
                  <img src={result_icon} alt="icon" />
                  <span className="mt-2 mt-sm-0">The Kings Restaurant</span>
                </div>
              </div>
            </div>

            <div
              className="text-center"
              style={{
                marginTop: "20px",
              }}
            >
              <button
                className="ep-action"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{
                  marginTop: "0px",
                }}
              >
                OKAY
              </button>
            </div>
          </div>
        </div>
      </div> */}
      {/* modal ends */}
    </>
  );
}

export default Health;
{
  /* <div
className="globalPages_tabWrapper"
style={{
  marginBottom: "20px",
  width: "100%",
  marginRight: "0px",
  marginLeft: "0px",
}}
>
<div
  className="gb_tabs"
  style={{
    minWidth: "852px",
    overflow: "auto",
  }}
>
  <div
    className="tabs"
    style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      minWidth: "1024px",
      overflowX: "auto",
    }}
  >
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        columnGap: "20px",
      }}
    >
      <div
        id="pills-home-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-home"
        type="button"
        role="tab"
        aria-controls="pills-home"
        aria-selected="true"
        onClick={() => setFilter(false)}
        to={`/dining/voucher`}
        className={({ isActive }) =>
          isActive ? "nav-active" : "nav-inactive"
        }
        style={{
          width: "130px",
        }}
      >
        Voucher
      </div>
      <div
        id="pills-profile-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-profile"
        type="button"
        role="tab"
        aria-controls="pills-profile"
        aria-selected="false"
        onClick={() => setFilter(true)}
        // to={`/dining/products`}
        className={({ isActive }) =>
          isActive ? "nav-active" : "nav-inactive"
        }
        style={{
          width: "130px",
        }}
      >
        Product
      </div>
    </div>

    {filter ? (
      <button className="filter-ic-btn border-0">
        <Link
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          to="#"
          style={{
            border: "none",
            margin: "0",
            padding: "0px 0px",
            zIndex: "1061",
            marginRight:
              "                                                                                                                                                                                                                                                               m,mm,mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,llllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllll,l,l,,,ll                             ,l  mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,l,llllllllllllllllllllllllllllllllllmmmmmmm,lllllllllllllllllllllllllllllll                                                                                                                                                                                                                                                                                                                                                                                                                                                  0px",
          }}
        >
          <img src={fillter_icon} alt="" />
        </Link>
      </button>
    ) : (
      <></>
    )}
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        columnGap: "20px",
      }}
    >
      <div
        className="search-input"
        style={{
          width: "224px",
        }}
      >
        <input
          type="text"
          name="text"
          id="text"
          placeholder="Search property"
          style={{
            width: "100%",
          }}
        />
        <img src={search_icon} alt="search" />
      </div>
      <div>
        <NavLink
          onClick={() => setFilter(false)}
          to="/dining/my-cart"
          className={({ isActive }) =>
            isActive ? "nav-active" : "nav-inactive"
          }
          style={{
            width: "130px",
          }}
        >
          My Cart
        </NavLink>
      </div>
      <div>
        <NavLink
          onClick={() => setFilter(false)}
          to="/dining/orders"
          className={({ isActive }) =>
            isActive ? "nav-active" : "nav-inactive"
          }
          style={{
            width: "130px",
          }}
        >
          My Orders
        </NavLink>
      </div>
      <div>
        <NavLink
          onClick={() => setFilter(false)}
          to="/dining/wishlist"
          className={({ isActive }) =>
            isActive ? "nav-active" : "nav-inactive"
          }
          style={{
            width: "130px",
          }}
        >
          My Wishlist
        </NavLink>
      </div>
    </div>
  </div>
</div>
</div>
<div class="tab-content" id="pills-tabContent">
<div
  class="tab-pane fade show active"
  id="pills-home"
  role="tabpanel"
  aria-labelledby="pills-home-tab"
  tabindex="0"
>
  Voucher Tab
</div>
<div
  class="tab-pane fade"
  id="pills-profile"
  role="tabpanel"
  aria-labelledby="pills-profile-tab"
  tabindex="0"
>
  Product Tab
</div>
<div
  class="tab-pane fade"
  id="pills-contact"
  role="tabpanel"
  aria-labelledby="pills-contact-tab"
  tabindex="0"
>
  My Cart
</div>
</div> */
}

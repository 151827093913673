import React, { useContext, useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { AppContext } from '../../context/AppContext'
import Sidebar from '../../components/Sidebar'
import Header from '../../components/Header'
import thumbImg from '../../assets/images/house01.png'
import location_icon from '../../assets/images/location_icon.svg'
import back from '../../assets/images/back.svg'
import gray_location_icon from '../../assets/images/gray_location_icon.svg'
import chatListAvatar01 from '../../assets/images/chatListAvatar01.png'
import chat_icon_sidebar from '../../assets/images/chat_icon_sidebar.svg'

function MyOder() {
  const { sidebar, setSidebar } = useContext(AppContext)
  const currentRoute = useLocation().pathname

  const [showElement, setShowElement] = React.useState(true)
  useEffect(() => {
    setTimeout(function () {
      setShowElement(false)
    }, 5000)
  }, [])
  return (
    <div class="sitemainBGwrapper">
      <Sidebar />

      {/* <Header /> */}
      <main id="cxMain" className={sidebar ? 'cx-main cx-active' : 'cx-main'}>
        <section className="content-main">
          <div className="row">
            <div className="col-md-12">
              <div className="gblThumb_outerWrapper">
                <div className="row">
                  <div className="globalPages_tabWrapper">
                    <div className="gb_tabs">
                      <div style={{ display: 'flex' }}>
                        <div
                          className="offers-gb-tabs"
                          style={{ marginRight: '20px', marginBottom: '20px' }}
                        >
                          <Link
                            to="/offers_listing"
                            className={
                              currentRoute === '/offers_listing'
                                ? 'ct_active'
                                : ''
                            }
                          >
                            Voucher
                          </Link>
                        </div>
                        <div className="offers-gb-tabs">
                          <Link
                            to="/Product-Offer"
                            className={
                              currentRoute === '/Offers-Product'
                                ? 'ct_active'
                                : ''
                            }
                          >
                            Product
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="gb_tabs-btn ">
                      <div style={{ display: 'flex' }}>
                        <div
                          style={{ marginRight: '20px', marginBottom: '20px' }}
                        >
                          <Link
                            style={{
                              font: 'normal normal 600 14px/19px Nunito',
                              letterSpacing: '0px',
                            }}
                            to="/MyCart"
                            className={
                              currentRoute === '/MyCart' ? 'ct_active' : ''
                            }
                          >
                            My Cart
                          </Link>
                        </div>
                        <div
                          style={{ marginRight: '20px', marginBottom: '20px' }}
                        >
                          <Link
                            to="/Order-Details"
                            className={
                              currentRoute === '/MyOder' ? 'ct_active' : ''
                            }
                          >
                            My Orders
                          </Link>
                        </div>
                        <div>
                          <Link
                            to="/My-Wishlist"
                            className={
                              currentRoute === '/My-Wishlist' ? 'ct_active' : ''
                            }
                          >
                            My Wishlist
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="gbl_thumbWrapper">
                      <div className="img_thumbWrapper">
                        <img src={thumbImg} height="158px" alt="" />
                      </div>
                      <Link
                        to="/Order-Details"
                        className={
                          currentRoute === '/Order-Details' ? 'ct_active' : ''
                        }
                      >
                        <div className="gbl_thumbContent">
                          <h1
                            style={{
                              fontSize: '16px',
                              fontWeight: '500',
                              color: 'hsla(240, 12%, 13%, 1)',
                              marginTop: '5px',
                              letterSpacing: '0',
                              font: 'normal normal 600 16px/22px Nunito',
                              opacity: '0px',
                            }}
                          >
                            Wrist Watch
                          </h1>
                          <p
                            style={{
                              color: 'var(--unnamed-color-707070)',
                              font: 'normal normal normal 14px/19px Nunito',
                              letterSpacing: '0px',
                              color: 'hsla(0, 0%, 44%, 1)',
                              opacity: '1px',
                              marginTop: '12px',
                            }}
                          >
                            Arriving today
                          </p>
                          <img
                            style={{
                              marginTop: '-25px',
                              float: 'right',
                            }}
                            src={back}
                            alt=""
                          />
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <div className="gray_modalWrapper">
        <div
          class="modal fade"
          id="exampleModal2"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Interested Users
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div className="grayfriendsListWrapper">
                  <ul>
                    <li>
                      <div className="fl_itemInner_Wrapper">
                        <div className="fli_leftWrapper">
                          <img src={chatListAvatar01} alt="" />
                          <h1>george96</h1>
                        </div>

                        <Link to="#">
                          <img src={chat_icon_sidebar} alt="" />
                        </Link>
                      </div>
                    </li>

                    <li>
                      <div className="fl_itemInner_Wrapper">
                        <div className="fli_leftWrapper">
                          <img src={chatListAvatar01} alt="" />
                          <h1>george96</h1>
                        </div>

                        <Link to="#">
                          <img src={chat_icon_sidebar} alt="" />
                        </Link>
                      </div>
                    </li>

                    <li>
                      <div className="fl_itemInner_Wrapper">
                        <div className="fli_leftWrapper">
                          <img src={chatListAvatar01} alt="" />
                          <h1>george96</h1>
                        </div>

                        <Link to="#">
                          <img src={chat_icon_sidebar} alt="" />
                        </Link>
                      </div>
                    </li>

                    <li>
                      <div className="fl_itemInner_Wrapper">
                        <div className="fli_leftWrapper">
                          <img src={chatListAvatar01} alt="" />
                          <h1>george96</h1>
                        </div>

                        <Link to="#">
                          <img src={chat_icon_sidebar} alt="" />
                        </Link>
                      </div>
                    </li>

                    <li>
                      <div className="fl_itemInner_Wrapper">
                        <div className="fli_leftWrapper">
                          <img src={chatListAvatar01} alt="" />
                          <h1>george96</h1>
                        </div>

                        <Link to="#">
                          <img src={chat_icon_sidebar} alt="" />
                        </Link>
                      </div>
                    </li>

                    <li>
                      <div className="fl_itemInner_Wrapper">
                        <div className="fli_leftWrapper">
                          <img src={chatListAvatar01} alt="" />
                          <h1>george96</h1>
                        </div>

                        <Link to="#">
                          <img src={chat_icon_sidebar} alt="" />
                        </Link>
                      </div>
                    </li>

                    <li>
                      <div className="fl_itemInner_Wrapper">
                        <div className="fli_leftWrapper">
                          <img src={chatListAvatar01} alt="" />
                          <h1>george96</h1>
                        </div>

                        <Link to="#">
                          <img src={chat_icon_sidebar} alt="" />
                        </Link>
                      </div>
                    </li>

                    <li>
                      <div className="fl_itemInner_Wrapper">
                        <div className="fli_leftWrapper">
                          <img src={chatListAvatar01} alt="" />
                          <h1>george96dd</h1>
                        </div>

                        <Link to="#">
                          <img src={chat_icon_sidebar} alt="" />
                        </Link>
                      </div>
                    </li>

                    <li>
                      <div className="fl_itemInner_Wrapper">
                        <div className="fli_leftWrapper">
                          <img src={chatListAvatar01} alt="" />
                          <h1>george96dd</h1>
                        </div>

                        <Link to="#">
                          <img src={chat_icon_sidebar} alt="" />
                        </Link>
                      </div>
                    </li>

                    <li>
                      <div className="fl_itemInner_Wrapper">
                        <div className="fli_leftWrapper">
                          <img src={chatListAvatar01} alt="" />
                          <h1>george96dd</h1>
                        </div>

                        <Link to="#">
                          <img src={chat_icon_sidebar} alt="" />
                        </Link>
                      </div>
                    </li>

                    <li>
                      <div className="fl_itemInner_Wrapper">
                        <div className="fli_leftWrapper">
                          <img src={chatListAvatar01} alt="" />
                          <h1>george96dd</h1>
                        </div>

                        <Link to="#">
                          <img src={chat_icon_sidebar} alt="" />
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyOder

import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import Profile_header from "../dashboard/dashboard_profile/Profile_header";
import Profile_aboutSection from "../dashboard/dashboard_about/Profile_aboutSection";
import Friend_requestSection from "../dashboard/dashboard_friend_request/Friend_requestSection";
import profile_picture from "../../assets/images/profile_picture.jpg";
import chatpp from "../../assets/images/chatListAvatar01.png";
import ChatBody from "./ChatBody";
import ChatSidebar from "./ChatSidebar";
import TabButton from "../groups/TabButton";
import Button from "../groups/Button";
import "./GroupCall.scss";

function GroupCall() {
  const { sidebar, setSidebar } = useContext(AppContext);

  const [showElement, setShowElement] = React.useState(true);
  useEffect(() => {
    setTimeout(function () {
      setShowElement(false);
    }, 5000);
  }, []);
  return (
    <div class="sitemainBGwrapper">
      <Sidebar />

      {/* <Header /> */}
      <main id="cxMain" className={sidebar ? "cx-main cx-active" : "cx-main"}>
        <section className="content-main">
          <div className="row">
            {/* <div className="col-md-4 connection-card-wrapper">
              <ChatSidebar />
            </div> */}
            <div className="col-md-4   ">
              <div>
                <TabButton />
              </div>
              <div className="connection-card-wrapper">
                <ChatSidebar />
              </div>

              <div>
                <Button />
              </div>
            </div>

            <div className="col-md-8">
              <ChatBody />
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default GroupCall;

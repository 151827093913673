import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import thumbImg from "../../assets/images/watch.png";
import back from "../../assets/images/back.svg";

const MyWishlist = () => {
  return (
    <>
      <div className="row row-gap-20">
        <div className="col-12 col-sm-6  col-lg-4 col-xxl-3">
          <div
            className="gbl_thumbWrapper"
            style={{
              position: "relative",
            }}
          >
            <button
              class="eventMenu"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                zIndex: "1000",
              }}
            >
              <i class="fa-solid fa-ellipsis-vertical"></i>
            </button>
            <ul
              class="dropdown-menu career-drop"
              aria-labelledby="dropdownMenuButton1"
              style={{
                backgroundColor: "#F5F5F5",
                border: "1px solid #ECECEC",
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
            >
              <li>
                <Link to="#" class="dropdown-item" href="#">
                  Share
                </Link>
              </li>

              <li>
                <Link to="#" class="dropdown-item" href="#">
                  Delete
                </Link>
              </li>
            </ul>

            <div className="img_thumbWrapper">
              <img
                src={thumbImg}
                height="158px"
                alt=""
                style={{
                  objectFit: "cover",
                }}
              />
            </div>

            <div className="gbl_thumbContent">
              <h1
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "hsla(240, 12%, 13%, 1)",
                  marginTop: "5px",
                  letterSpacing: "0",
                  font: "normal normal 600 16px/22px Nunito",
                  opacity: "0px",
                }}
              >
                Wrist Watch
              </h1>
              <p
                style={{
                  color: "var(--unnamed-color-707070)",
                  font: "normal normal normal 14px/19px Nunito",
                  letterSpacing: "0px",
                  color: "hsla(0, 0%, 44%, 1)",
                  opacity: "1px",
                  marginTop: "12px",
                }}
              >
                Starting from 1,999/-
              </p>
              <Link to="#">
                <img
                  style={{
                    marginTop: "-25px",
                    float: "right",
                  }}
                  src={back}
                  alt=""
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6  col-lg-4 col-xxl-3">
          <div
            className="gbl_thumbWrapper"
            style={{
              position: "relative",
            }}
          >
            <button
              class="eventMenu"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                zIndex: "1000",
              }}
            >
              <i class="fa-solid fa-ellipsis-vertical"></i>
            </button>
            <ul
              class="dropdown-menu career-drop"
              aria-labelledby="dropdownMenuButton1"
              style={{
                backgroundColor: "#F5F5F5",
                border: "1px solid #ECECEC",
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
            >
              <li>
                <Link to="#" class="dropdown-item" href="#">
                  Share
                </Link>
              </li>

              <li>
                <Link to="#" class="dropdown-item" href="#">
                  Delete
                </Link>
              </li>
            </ul>

            <div className="img_thumbWrapper">
              <img
                src={thumbImg}
                height="158px"
                alt=""
                style={{
                  objectFit: "cover",
                }}
              />
            </div>

            <div className="gbl_thumbContent">
              <h1
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "hsla(240, 12%, 13%, 1)",
                  marginTop: "5px",
                  letterSpacing: "0",
                  font: "normal normal 600 16px/22px Nunito",
                  opacity: "0px",
                }}
              >
                Wrist Watch
              </h1>
              <p
                style={{
                  color: "var(--unnamed-color-707070)",
                  font: "normal normal normal 14px/19px Nunito",
                  letterSpacing: "0px",
                  color: "hsla(0, 0%, 44%, 1)",
                  opacity: "1px",
                  marginTop: "12px",
                }}
              >
                Starting from 1,999/-
              </p>
              <Link to="#">
                <img
                  style={{
                    marginTop: "-25px",
                    float: "right",
                  }}
                  src={back}
                  alt=""
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyWishlist;

import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import profile_picture from "../../assets/images/profile_picture.jpg";
import createselectAvatar from "../../assets/images/createselectAvatar.png";
import createadd_avatar from "../../assets/images/createadd_avatar.png";
import ImageUplode from "../../sharedComponent/ImageUplode";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
import swal from "sweetalert";
import {
  multipartPostCallWithErrorResponse,
  postMultipartWithAuthCall,
  postMultipartWithAuthCallWithErrorResponse,
  postMultipartWithAuthCallWithErrorResponseNodeCreate,
  postWithAuthCallWithErrorResponse,
  putMultipartWithAuthCallWithErrorResponseNodeCreate,
  putWithAuthCall,
  simpleGetCallWithErrorResponseNode,
  simpleGetCallWithErrorResponseNodeCreate,
} from "../../api/ApiServices";
import { getByDisplayValue } from "@testing-library/react";
import { cleanData } from "jquery";

function EditForum() {
  const navigate = useNavigate();
  const params = useParams();
  let id = params.id;
  console.log("ID", id);
  // const location = useLocation();
  // const dataForum = location.state();
  // console.log(dataForum);
  // const { from } = location.state;
  const { sidebar, setSidebar } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [showElement, setShowElement] = React.useState(true);
  const [forumData, setForumData] = useState([]);
  console.log("forumData", forumData);
  const [addForum, setaddForum] = useState(
    // id:from.id,
    // title: from.text==null?'':from.text,
    // property_image:[],
    // property_image: from.forum_image,
    {
      image: [],
      title: "",
      type: 0,             // type will contain type_id 
      forum_text: "",
      forum_id: id || 0,
      other_type: ""
    }
  );
  console.log("addForum", addForum);
  const [forumTypeList, setForumTypeList] = useState([]);

  const forumtypeId = forumTypeList.map((forumType) => {
    return forumType.type_id == 4;
  })

  const getForumType = () => {
    simpleGetCallWithErrorResponseNodeCreate(ApiConfig.UNIVERSR_ALL_FORUM_TYPE)
      .then((res) => {
        console.log("Response Hai ", res.json.data)
        setForumTypeList(res.json.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }


  useEffect(() => {
    getForumType();
    setTimeout(function () {
      setShowElement(false);
    }, 5000);
  }, []);


  useEffect(() => {
    if (id) {
      getForumData();
    }
  }, []);

  const getForumData = () => {
    setLoading(true)
    simpleGetCallWithErrorResponseNode(ApiConfig.UNIVERSR_GET_FORUM_EDIT_DATA + id)
      .then((res) => {
        console.log(res);
        if (res.json.result) {
          let data = res.json.data.map(data => {
            console.log("data", data);
            setaddForum({
              title: data.title,
              forum_text: data.forum_text,
              image: data.forum_image,
              type : data.type_id
            })
          })
          setForumData(res.json.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err)
      })
  }

  // FORUM CREATION API CALL
  const createForum = () => {

    if (addForum.title == '') {
      swal('Please enter title');
      return
    }
    if (addForum.type == 0) {
      swal('Please select type');
      return
    }
    if (addForum.forum_text == '') {
      swal('Please enter text');
      return
    }
    if (addForum.image.length == 0) {
      swal("Please select image");
      return;
    }
    setLoading(true);
    const formData = new FormData();
    // formData.append("forum_text", addForum.title);
    // addForum.image.map(img => {
    //   formData.append("image", img);
    // })
    formData.append('title', addForum.title)
    formData.append('image', addForum.image[0])
    formData.append('type', addForum.type);
    formData.append('forum_id', id);
    formData.append('forum_text', addForum.forum_text);
    formData.append('other_type', addForum.other_type);


    putMultipartWithAuthCallWithErrorResponseNodeCreate(
      ApiConfig.UNIVERSR_UPDATE_FORUM_DATA,
      formData,
    )
      .then((res) => {
        setLoading(false)
        swal('Forum updated successfully!')
        navigate('/manage_forum')
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })

  };

  // IMAGE REMOVE FUNCTION
  const removeImage = (index) => {
    let property_image = addForum.image;

    property_image = property_image.filter(
      (img) => property_image.indexOf(img) != index
    );

    setaddForum({ ...addForum, image: property_image });
  };

  return (
    <>
      <Sidebar />

      {/* <Header /> */}
      <main id="cxMain" className={sidebar ? "cx-main cx-active" : "cx-main"}>
        {loading ? (
          <Loader />
        ) : (
          <section
            className="content-main"
            style={{
              backgroundColor: "white",
              borderRadius: "24px",
              padding: "20px",
              minHeight: "calc(100vh - 156px)",
            }}
          >
            <p className="fo-heading" style={{ marginBottom: "10px" }}>
              Edit Forum
            </p>
            <div className="row">
              {/*  */}
              <div className='col-md-6'>
                <label class="form-label">Title</label>
                <input
                  type="text"
                  class="form-control"
                  style={{ height: '40px' }}
                  value={addForum.title}
                  placeholder="Enter your title here"
                  onChange={(e) => {
                    setaddForum({
                      ...addForum,
                      title: e.target.value
                    })
                    // setErrMsg({ ...errMsg, title: "" });

                    // setAddProperty({
                    //   ...addForum,
                    //   title: e.target.value,
                    // })
                  }}
                />
              </div>
              <div className="col-md-6">
                <label class="form-label">Type</label>
                <div className="upp_selectWrapper ">
                  <select
                    onChange={(e) => {
                      setaddForum({
                        ...addForum,
                        type: Number(e.target.value)
                      })
                    }}
                    value={addForum.type}
                    className="Property-Type-midal form-select  ce-input"
                    aria-label="Default select example"
                    style={{
                      height: '40px',
                      backgroundColor: '#FBF8F8',
                      margin: '0',
                    }}
                  >
                    <option value="">
                      Select forum Type
                    </option>
                    {forumTypeList.map((forumType) => {
                      return <option key={forumType.type_id} value={forumType.type_id}>{forumType.name}</option>;
                    })}
                  </select>
                </div>
              </div>
              {
                forumtypeId && addForum.type == 4 ?
                  <>
                    <div className="col-lg-6"></div>
                    <div className="col-md-6 mt-3 text-end ">
                      <input type="text" onChange={(e) => {
                        setaddForum({
                          ...addForum,
                          other_type : e.target.value
                        })
                      }} placeholder="Enter Type" class="form-control" />
                    </div> 
                  </> : null
              }

              {/*  */}
              <div className="col mt-4">
                <label>Text</label>
                <textarea
                  value={addForum.forum_text}
                  onChange={(e) => {
                    setaddForum({
                      ...addForum,
                      forum_text: e.target.value,
                    });
                  }}
                  type="text"
                  name="desc"
                  id="desc"
                  className="ce-input"
                  placeholder="Enter location here"
                  style={{ height: "150px", paddingTop: "20px" }}
                />
              </div>
            </div>
            <div className="row mb-4 d-flex align-items-center gy-3">
              <div className="col-12 col-sm-6 col-lg-4 col-xl-auto custom-resp-1">
                <div className="up-icon">
                  {" "}
                  <ImageUplode
                    setFile={(files) => {
                      setaddForum({
                        ...addForum,
                        image: [...files],
                      });
                    }}
                  />
                </div>
              </div>

              {addForum.image.map((img, index) => {

                return (
                  <div className="col-12 col-sm-6 col-lg-4 col-xl-auto">
                    <div className="uploaded-image-1 uploaded-reps-1 up-img">
                      <div className="item_list" key={"img" + index}>
                        <img
                          width={500}
                          height="500"
                          className="img"
                          src={
                            img.name
                              ? URL.createObjectURL(img)
                              : img
                          }
                          alt=""
                        />
                        <button
                          style={{
                            position: "absolute",
                            width: "34px",
                            height: "34px",
                            backgroundColor: "white",
                            borderRadius: "10px",
                            border: "none",
                            top: "10px",
                            right: "10px",
                          }}
                          href="#"
                          className=""
                          onClick={() => removeImage(index)}
                        >
                          <i class="fa-solid fa-xmark"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              className="row"
              style={{
                marginTop: "60px",
              }}
            >
              <div className="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                <button className="cancel">Cancel</button>
              </div>
              <div className="col-12 col-sm-6 d-flex align-items-center justify-content-center mt-3 mt-sm-0 justify-content-sm-start  ">
                <button className="create" onClick={createForum}>
                  {" "}
                  Post
                </button>
              </div>
            </div>
          </section>
        )}
      </main>
    </>
  );
}

export default EditForum;
// import { useState } from "react";
// import { AppContext } from '../context/user/AppContext';
export async function PostCallWithErrorResponse(url, requestBody) {
  var resp;
  // const [progress, setProgress] = useState(0);
  // const { customerData } = useContext(AppContext);

  return await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: "Token " + localStorage.getItem("auth_token"),
    },
    // body: JSON.stringify({...customerData,...requestBody})
    body: JSON.stringify(requestBody),
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return {
        response: resp,
        json: json,
        error: !resp.ok,
      };
    });
}
// signup user with auth 0
export async function createUser(requestBody) {
  const url = "https://dev-3uriluzll4q5ecyt.us.auth0.com/dbconnections/signup";
  const bodyData = {
    email: requestBody.email,
    password: requestBody.password,
    client_id: "zKXVGpHbs33QQe2Mm35y4XrcAYn023oe",
    client_secret:
      "ROMfq01XbkgyxKlZzFGl0Liqh9rmjBoEk2klG-zlL0PAOtFTAh7w7yQW94UY2lCr",
    connection: "universe",
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyData),
    });

    if (!response.ok) {
      const errorText = `HTTP error! status: ${response.status}`;
      console.error(errorText);
      const errorJson = await response.json();
      console.error("Error details:", errorJson);
      throw new Error(errorText);
    }

    const json = await response.json();
    return {
      response: json,
      error: false,
    };
  } catch (error) {
    console.error("Error during fetch:", error);
    return {
      response: null,
      error: true,
      message: error.message,
    };
  }
}
// get token auth0
export async function getAuth0Token(requestBody) {
  const url = "https://dev-3uriluzll4q5ecyt.us.auth0.com/oauth/token";
  const data = {
    client_id: "zKXVGpHbs33QQe2Mm35y4XrcAYn023oe",
    client_secret:
      "ROMfq01XbkgyxKlZzFGl0Liqh9rmjBoEk2klG-zlL0PAOtFTAh7w7yQW94UY2lCr",
    audience: "this is layout index identifier",
    grant_type: "password",
    username: requestBody.email,
    password: requestBody.password,
    scope: "openid",
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      console.error(`Failed to obtain token! Status: ${response.status}`);
      const errorJson = await response.json();
      console.error("Error details:", errorJson);
      throw new Error(`Failed to obtain token! Status: ${response.status}`);
    }

    const json = await response.json();
    console.log("details:", json);
    return json;
  } catch (error) {
    console.error("Error during token fetch:", error);
    return null;
  }
}

//get userInformation

export async function getAuth0UserDetails(accessToken) {
  const url = "https://dev-3uriluzll4q5ecyt.us.auth0.com/userinfo";

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.error(`Failed to get user details! Status: ${response.status}`);
      const errorJson = await response.json();
      console.error("Error details:", errorJson);
      throw new Error(`Failed to get user details! Status: ${response.status}`);
    }

    const userDetails = await response.json();
    console.log("User details obtained:", userDetails);
    return userDetails; // Return user details
  } catch (error) {
    console.error("Error during user details fetch:", error);
    return null;
  }
}

// save user Details
export async function saveUserDetails(auth0Id, userData, email) {
  const url = "http://localhost:3080/userFlutter/create";

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        auth0Id: auth0Id,
        email: email,
        ...userData,
      }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const responseData = await response.json();
    console.log("Success:", responseData);
    return responseData;
  } catch (error) {
    console.error("Error saving user details:", error);
    throw error;
  }
}

// find Auth0 id
export async function findUserById(url, accessToken) {
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("not find auth0id");
    }
    const responseData = await response.json();
    console.log("success", responseData);
    return responseData;
  } catch (error) {
    console.error("Error find user id:", error);
    return "user not found";
  }
}

//update user base on auth0Id
export async function userUpdate(auth0Id, accessToken, updateUserDetails) {
  console.log(updateUserDetails);
  const url = `http://localhost:3080/userFlutter/update/${auth0Id}`;
  try {
    const response = await fetch(url, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updateUserDetails),
    });
    if (!response.ok) {
      throw new Error("not find auth0id");
    }
    const responseData = await response.json();
    console.log("success", responseData);
    return responseData;
  } catch (error) {
    console.error("Error find user id:", error);
    return "user not found";
  }
}

export async function multipartPostCallWithErrorResponse(url, requestBody) {
  var resp;
  return await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      //"Content-Type": "multipart/form-data",
      Authorization: "Token " + localStorage.getItem("auth_token"),
    },
    body: requestBody,
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return {
        response: resp,
        json: json,
        error: !resp.ok,
      };
    });
}

export async function getWithAuthCallWithErrorResponse(url) {
  var resp;
  return await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + localStorage.getItem("auth_token"),
    },
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return {
        response: resp,
        json: json,
        error: !resp.ok,
      };
    });
}

export async function postWithAuthCallWithErrorResponse(url, requestBody) {
  var resp;
  return await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // "Content-Type": "multipart/form-data",
      Authorization: "Token " + localStorage.getItem("auth_token"),
      // Origin: window.location.origin,
    },
    body: requestBody,
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return {
        response: resp,
        json: json,
        error: !resp.ok,
      };
    });
}
export async function getWithAuthCallWithtext(url) {
  var resp;
  return await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: 'Token ' + localStorage.getItem("USER_AUTH_TOKEN"),    },
    },
  })
    .then((response) => {
      resp = response;
      return response.text();
    })
    .then((text) => {
      return { response: resp, text: text, error: !resp.ok };
    });
}

export async function patchMultipartWithAuthCallWithErrorResponse(
  url,
  requestBody
) {
  var resp;
  return await fetch(url, {
    method: "PATCH",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "multipart/form-data",
      Authorization: "Token " + localStorage.getItem("auth_token"),
    },
    body: requestBody,
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return {
        response: resp,
        json: json,
        error: !resp.ok,
      };
    });
}

export async function putMultipartWithAuthCallWithErrorResponse(
  url,
  requestBody
) {
  var resp;
  return await fetch(url, {
    method: "PUT",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "multipart/form-data",
      Authorization: "Token " + localStorage.getItem("auth_token"),
    },
    body: requestBody,
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return {
        response: resp,
        json: json,
        error: !resp.ok,
      };
    });
}

export async function postMultipartWithAuthCallWithErrorResponse(
  url,
  requestBody
) {
  var resp;
  return await fetch(url, {
    method: "POST",

    headers: {
      Accept: "application/json",
      Authorization: "Token " + localStorage.getItem("auth_token"),
    },
    body: requestBody,
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return {
        response: resp,
        json: json,
        error: !resp.ok,
      };
    });
}
export async function postMultipartWithAuthCallWithErrorResponseNode(
  url,
  requestBody
) {
  var resp;
  return await fetch(url, {
    method: "POST",

    headers: {
      Accept: "application/json",
      Authorization: localStorage.getItem("auth_token"),
    },
    body: requestBody,
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return {
        response: resp,
        json: json,
        error: !resp.ok,
      };
    });
}
export async function simpleGetCallWithErrorResponse(url) {
  var resp;
  return await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + localStorage.getItem("auth_token"),
    },
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return {
        response: resp,
        json: json,
        error: !resp.ok,
      };
    });
}
export async function simpleGetCallWithErrorResponseNODE(url) {
  var resp;
  return await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("auth_token"),
    },
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return {
        response: resp,
        json: json,
        error: !resp.ok,
      };
    });
}
export async function simpleGetCallWithErrorResponseNode(url) {
  var resp;
  return await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("auth_token"),
    },
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return {
        response: resp,
        json: json,
        error: !resp.ok,
      };
    });
}
export async function simpleGetCall(url) {
  return await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.text())
    .then((result) => getResult(result));
}

export async function simplePostCall(url, requestBody) {
  return await fetch(url, {
    method: "POST",
    // mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      //'Access-Control-Allow-Credentials': "*"
      Authorization: "Token " + localStorage.getItem("auth_token"),
    },
    withCredentials: true,
    body: requestBody,
  })
    .then((response) => response.text())
    .then((result) => getResult(result));
  //.then(data=>data.json());
}
export async function simplePostCallNode(url, requestBody) {
  return await fetch(url, {
    method: "POST",
    // mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      //'Access-Control-Allow-Credentials': "*"
      Authorization: localStorage.getItem("auth_token"),
    },
    withCredentials: true,
    body: requestBody,
  })
    .then((response) => response.text())
    .then((result) => getResult(result));
  //.then(data=>data.json());
}
export async function simplePUTCall(url, requestBody) {
  return await fetch(url, {
    method: "PUT",
    // mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      //'Access-Control-Allow-Credentials': "*"
      Authorization: "Token " + localStorage.getItem("auth_token"),
    },
    withCredentials: true,
    body: requestBody,
  })
    .then((response) => response.text())
    .then((result) => getResult(result));
  //.then(data=>data.json());
}
export async function simplePostCallAll(url, requestBody) {
  return await fetch(url, {
    method: "POST",
    // mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      //'Access-Control-Allow-Credentials': "*"
    },
    withCredentials: true,
    body: requestBody,
  })
    .then((response) => response.text())
    .then((result) => getResult(result));
  //.then(data=>data.json());
}

export async function multipartPostCall(url, requestBody) {
  return await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      //'Content-Type': 'multipart/form-data',
      Authorization: "Token " + localStorage.getItem("auth_token"),
    },
    body: requestBody,
  })
    .then((response) => response.text())
    .then((result) => getResult(result));
}

export async function getWithAuthCall(url) {
  return await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + localStorage.getItem("auth_token"),
    },
  })
    .then((response) => response.text())
    .then((result) => getResult(result));
}

export async function postWithAuthCall(url, requestBody) {
  return await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + localStorage.getItem("auth_token"),
    },
    body: requestBody,
  })
    .then((response) => response.text())
    .then((result) => getResult(result));
}

export async function putWithAuthCall(url, requestBody) {
  return await fetch(url, {
    method: "PUT",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: "Token " + localStorage.getItem("auth_token"),
    },
    body: requestBody,
  })
    .then((response) => response.text())
    .then((result) => getResult(result));
}

export async function postMultipartWithAuthCall(url, requestBody) {
  return await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      Accept: "application/json",
      // "Content-Type": "multipart/form-data",
      // "Access-Control-Allow-Origin": "*",
      Authorization: "Token " + localStorage.getItem("auth_token"),
    },
    withCredentials: true,
    body: requestBody,
  })
    .then((response) => response.text())
    .then((result) => getResult(result));
}

export async function putMultipartWithAuthCall(url, requestBody) {
  return await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      //Authorization: 'Token ' + (await AsyncStorage.getItem(AppStrings.TOKEN)),
      Authorization: "Token " + localStorage.getItem("auth_token"),
    },
    body: requestBody,
  })
    .then((response) => response.json())
    .then((result) => getResult(result));
}

export async function deleteWithAuthCall(url, requestBody) {
  return await fetch(url, {
    method: "DELETE",

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + localStorage.getItem("auth_token"),
    },

    body: requestBody,
  }).then((response) => response.json());
}

export async function deleteWithAuthCallNode(url, requestBody) {
  return await fetch(url, {
    method: "DELETE",

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("auth_token"),
    },

    body: requestBody,
  }).then((response) => response.json());
}
export async function simpleGetCallWithErrorResponseNodeCreate(url) {
  var resp;
  return await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("auth_token"),
    },
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return {
        response: resp,
        json: json,
        error: !resp.ok,
      };
    });
}

export async function postMultipartWithAuthCallWithErrorResponseNodeCreate(
  url,
  requestBody
) {
  var resp;
  return await fetch(url, {
    method: "POST",

    headers: {
      Accept: "application/json",
      Authorization: localStorage.getItem("auth_token"),
    },
    body: requestBody,
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return {
        response: resp,
        json: json,
        error: !resp.ok,
      };
    });
}

export async function putMultipartWithAuthCallWithErrorResponseNodeCreate(
  url,
  requestBody
) {
  var resp;
  return await fetch(url, {
    method: "PUT",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "multipart/form-data",
      Authorization: localStorage.getItem("auth_token"),
    },
    body: requestBody,
  })
    .then((response) => {
      resp = response;
      return response.json();
    })
    .then((json) => {
      return {
        response: resp,
        json: json,
        error: !resp.ok,
      };
    });
}

//-------------------------------------
export async function getResult(data) {
  //console.log('--------------', data);
  return JSON.parse(data.trim());
}
//-------------------------------------

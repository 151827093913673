import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from '../../context/AppContext'
import Sidebar from '../../components/Sidebar'
import Header from '../../components/Header'
import thumbImg from '../../assets/images/offashion.png'
import location_icon from '../../assets/images/location_icon.svg'
import back from '../../assets/images/back.svg'
import gray_location_icon from '../../assets/images/gray_location_icon.svg'
import chatListAvatar01 from '../../assets/images/chatListAvatar01.png'
import chat_icon_sidebar from '../../assets/images/chat_icon_sidebar.svg'
import search_icon from '../../assets/images/search_icon.svg'
import result_icon from '../../assets/images/saleThumb02.png'
import ApiConfig from '../../api/ApiConfig'
import LoaderCenter from '../../sharedComponent/LoaderCenter'
import {
  getWithAuthCall,
  multipartPostCall,
  simpleGetCallWithErrorResponse,
  simplePostCall,
} from '../../api/ApiServices'
import swal from 'sweetalert'

const Accordion = ({ title, children }) => {
  const [isOpen, setOpen] = React.useState(false)
  return (
    <div className="accordion-wrapper">
      <div
        className={`accordion-title ${isOpen ? 'open' : ''}`}
        onClick={() => setOpen(!isOpen)}
      >
        {title}
      </div>
      <div className={`accordion-item ${!isOpen ? 'collapsed' : ''}`}>
        <div className="accordion-content">{children}</div>
      </div>
    </div>
  )
}
const FashionCard = () => {
  const { customerData, keyword } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const [prouctsGender, setProuctsGender] = useState({
    gender: 'Men',
  })
  const [products, setProducts] = useState([])
  const [categoriesFilter, setCategoriesFilter] = useState([])
  const [BrandsFilter, setBrandsFilter] = useState([])
  const [colourFilter, setColourFilter] = useState([])
  const [storeFilter, setStoreFilter] = useState([])

  const [filter, setFilter] = useState({
    min: 0,
    max: 0,
  })
  const [categoriesType, setCategoriesType] = useState([])
  const [brandsType, setBrandsType] = useState([])
  const [colourType, setColourType] = useState([])
  const [storeType, setStoreType] = useState([])

  useEffect(() => {
  
  }, [filter])
  const handleFilter = (e, type) => {
   
    if (type == 'min') setFilter({ ...filter, min: e.target.value })
    if (type == 'max') setFilter({ ...filter, max: e.target.value })
  }

  const getSearchedProducts = (data) => {
    
    let matches = []
    matches = data.filter((product, index) => {
      if (
        product &&
        product.product_name.toLowerCase().includes(keyword.toLowerCase())
      )
        return product
    })

    setProducts(matches)
  }
  useEffect( () => {
     getProduct()
  }, [])
  useEffect(() => {
    getProduct()
  }, [keyword])

  const getProduct = () => {
    setLoading(true)

    simpleGetCallWithErrorResponse(
      ApiConfig.FASHIN_PRODUCT_LIST,
      JSON.stringify({ ...customerData }),
    ).then((res) => {
      console.log(res)
      setLoading(false)
      // setProducts(res.json.Fashion);
      if (keyword.length > 0) {
        getSearchedProducts(res.json.Fashion)
      } else {
        setProducts(res.json.Fashion)
      }
    })
  }

  const productSave = async (status, id) => {
    

    if (status) {
      const data = await simplePostCall(
        ApiConfig.LIKE_FASHION,
        JSON.stringify({
          product_id: id,
          like_dislike_status: 'like',
        }),
      )
      swal(data.detail)
      refreshPage()
    } else {
      const data = await simplePostCall(
        ApiConfig.LIKE_FASHION,
        JSON.stringify({
          product_id: id,
          like_dislike_status: 'dislike',
        }),
      )
      swal(data.detail)
      refreshPage()
    }
  }

  //// Filter ////

  const FilterFashionItem = (e) => {
    setLoading(true)
    let formData = new FormData()
    formData.append('gender', prouctsGender.gender)
    formData.append('category', categoriesFilter)
    formData.append('brand', BrandsFilter)
    formData.append('colour', colourFilter)
    formData.append('store', storeFilter)
    formData.append('min', filter.min)
    formData.append('max', filter.max)

    multipartPostCall(ApiConfig.FILTER_FASHION, formData)
      .then((res) => {
        setLoading(false)
        swal(res.message)
        setProducts(res?.Fashion)

        // getProduct()
      })
      .catch((err) => console.log(err))
    setLoading(false)
  }

  useEffect(() => {
    getCategories()
    getBrands()
    getColoures()
    getStore()
  }, [])

  const getCategories = () => {
    getWithAuthCall(ApiConfig.FASHION_CATEGORY_DROPDOWNLIST).then((res) => {
      console.log('res', res)
      setCategoriesType(res.categories)
    })
  }

  const getBrands = () => {
    getWithAuthCall(ApiConfig.FASHION_BRANDS_DROPDOWNLIST).then((res) => {
      console.log('res', res)
      setBrandsType(res.brands)
    })
  }
  const getColoures = () => {
    getWithAuthCall(ApiConfig.FASHION_COLOURS_DROPDOWNLIST).then((res) => {
      console.log('res', res)
      setColourType(res.colours)
    })
  }

  const getStore = () => {
    getWithAuthCall(ApiConfig.FASHION_STORE_DROPDOWNLIST).then((res) => {
      console.log('res', res)
      setStoreType(res.stores)
    })
  }

  function refreshPage() {
    setTimeout(() => {
      window.location.reload(false)
    }, 0)
   
  }

  return (
    <>
      {loading ? (
        <LoaderCenter />
      ) : (
        <div className="row row-gap-20">
          {products && products.length > 0 ? (
            products.map((productsAll, index) => {
              console.log(productsAll)
              return (
                <div className="col-12 col-sm-6  col-lg-4 col-xxl-3">
                  <div className="gbl_thumbWrapper">
                    <label className="heart-check">
                      <input
                        checked={productsAll.is_like}
                        onChange={(e) =>
                          productSave(e.target.checked, productsAll.id)
                        }
                        class="check"
                        type="checkbox"
                        // checked={healthproductsAll.quantity.like}
                      />
                      <span class="label-text"></span>
                    </label>

                    <div className="img_thumbWrapper">
                      <img
                        src={productsAll.image}
                        height="158px"
                        alt=""
                        style={{
                          objectFit: 'cover',
                        }}
                      />
                    </div>

                    <div className="gbl_thumbContent">
                      <h1
                        style={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: 'hsla(240, 12%, 13%, 1)',
                          marginTop: '5px',
                          letterSpacing: '0',
                          font: 'normal normal 600 16px/22px Nunito',
                          opacity: '0px',
                        }}
                      >
                        {productsAll.product_name}
                      </h1>
                      <p
                        style={{
                          color: 'var(--unnamed-color-707070)',
                          font: 'normal normal normal 14px/19px Nunito',
                          letterSpacing: '0px',
                          color: 'hsla(0, 0%, 44%, 1)',
                          opacity: '1px',
                          marginTop: '12px',
                        }}
                      >
                        {productsAll.price}/-
                      </p>

                      <Link to={`/fashion-details/${productsAll.id}`}>
                        <img
                          style={{
                            marginTop: '-25px',
                            float: 'right',
                          }}
                          src={back}
                          alt=""
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              )
            })
          ) : (
            <p
              style={{
                fontSize: '25px',
                margin: 'auto',
              }}
              className=" text-center justify-content-center"
            >
              Data not found
            </p>
          )}
        </div>
      )}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          style={{
            margin: 'auto',
            width: '80%',
            maxWidth: '377px',
          }}
        >
          <div
            class="modal-content"
            style={{
              borderRadius: '20px',
              backgroundColor: '#F5F5F5',
              padding: '20px',
            }}
          >
            <p className="pf-heading">Filter Fashion</p>
            <div className="pf-body">
              <p className="pf-label">Choose Product Type</p>
              <Accordion title="Categories">
                {categoriesType &&
                  categoriesType.length &&
                  categoriesType.map((categoriesTypeItem, index) => {
                    return (
                      <div className="pf-option">
                        <input
                          type="checkbox"
                          id={'check1' + index}
                          name="same"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setCategoriesFilter([
                                ...categoriesFilter,
                                categoriesTypeItem,
                              ])
                            } else {
                              setCategoriesFilter(
                                categoriesFilter.filter(
                                  (data) => data.id !== categoriesTypeItem.id,
                                ),
                              )
                            }
                          }}
                        />

                        <label
                          value={categoriesTypeItem.id}
                          key={index}
                          htmlFor={'check1' + index}
                        >
                          {categoriesTypeItem}
                        </label>
                      </div>
                    )
                  })}
              </Accordion>
              <Accordion title="Brand">
                {brandsType &&
                  brandsType.length &&
                  brandsType.map((brandsTypeItem, index) => {
                    return (
                      <div className="pf-option">
                        <input
                          type="checkbox"
                          id={'check2' + index}
                          name="same"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setBrandsFilter([...BrandsFilter, brandsTypeItem])
                            } else {
                              setBrandsFilter(
                                BrandsFilter.filter(
                                  (data) => data.id !== brandsTypeItem.id,
                                ),
                              )
                            }
                          }}
                        />

                        <label
                          value={brandsTypeItem.id}
                          key={index}
                          htmlFor={'check2' + index}
                        >
                          {brandsTypeItem}
                        </label>
                      </div>
                    )
                  })}
              </Accordion>
              <Accordion title="Colours">
                {colourType &&
                  colourType.length &&
                  colourType.map((colourTypeItem, index) => {
                    return (
                      <div className="pf-option">
                        <input
                          type="checkbox"
                          id={'check3' + index}
                          name="same"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setColourFilter([...colourFilter, colourTypeItem])
                            } else {
                              setColourFilter(
                                colourFilter.filter(
                                  (data) => data.id !== colourTypeItem.id,
                                ),
                              )
                            }
                          }}
                        />

                        <label
                          value={colourTypeItem.id}
                          key={index}
                          htmlFor={'check3' + index}
                        >
                          {colourTypeItem}
                        </label>
                      </div>
                    )
                  })}
              </Accordion>
              <Accordion title="Stores">
                {storeType &&
                  storeType.length &&
                  storeType.map((storeTypeItem, index) => {
                    return (
                      <div className="pf-option">
                        <input
                          type="checkbox"
                          id={'check4' + index}
                          name="same"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setStoreFilter([...storeFilter, storeTypeItem])
                            } else {
                              setStoreFilter(
                                storeFilter.filter(
                                  (data) => data.id !== storeTypeItem.id,
                                ),
                              )
                            }
                          }}
                        />

                        <label
                          value={storeTypeItem.id}
                          key={index}
                          htmlFor={'check4' + index}
                        >
                          {storeTypeItem}
                        </label>
                      </div>
                    )
                  })}
              </Accordion>
              <Accordion title="Minum Price">
                <div className="pf-option">
                  <input
                    value={'100'}
                    type="radio"
                    id="min1"
                    name="min"
                    onChange={(e) => {
                      handleFilter(e, 'min')
                    }}
                  />
                  <label htmlFor="min1">100</label>
                </div>
                <div className="pf-option">
                  <input
                    value={'400'}
                    type="radio"
                    id="min2"
                    name="min"
                    onChange={(e) => {
                      handleFilter(e, 'min')
                    }}
                  />
                  <label htmlFor="min2">400</label>
                </div>
                <div className="pf-option">
                  <input
                    value={'600'}
                    type="radio"
                    id="min3"
                    name="min"
                    onChange={(e) => {
                      handleFilter(e, 'min')
                    }}
                  />
                  <label htmlFor="min3">600</label>
                </div>
                <div className="pf-option">
                  <input
                    value={'1000'}
                    type="radio"
                    id="min4"
                    name="min"
                    onChange={(e) => {
                      handleFilter(e, 'min')
                    }}
                  />
                  <label htmlFor="min4">1000</label>
                </div>
              </Accordion>
              <Accordion title="Maximum Price">
                <div className="pf-option">
                  <input
                    value={'200'}
                    type="radio"
                    id="max1"
                    name="max"
                    onChange={(e) => {
                      handleFilter(e, 'max')
                    }}
                  />
                  <label htmlFor="max1">200</label>
                </div>
                <div className="pf-option">
                  <input
                    value={'500'}
                    type="radio"
                    id="max2"
                    name="max"
                    onChange={(e) => {
                      handleFilter(e, 'max')
                    }}
                  />
                  <label htmlFor="max2">500</label>
                </div>
                <div className="pf-option">
                  <input
                    value={'900'}
                    type="radio"
                    id="max3"
                    name="max"
                    onChange={(e) => {
                      handleFilter(e, 'max')
                    }}
                  />
                  <label htmlFor="max3">900</label>
                </div>
                <div className="pf-option">
                  <input
                    value={'1500'}
                    type="radio"
                    id="max4"
                    name="max"
                    onChange={(e) => {
                      handleFilter(e?.target?.checked, 'max')
                    }}
                  />
                  <label htmlFor="max4">1500</label>
                </div>
              </Accordion>
            </div>

            <div
              className="text-center"
              style={{
                marginTop: '20px',
              }}
            >
              <button
                onClick={FilterFashionItem}
                className="ep-action"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{
                  marginTop: '0px',
                }}
              >
                Apply Filter
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FashionCard

import React, { useContext, useState, useEffect } from 'react'
import { AppContext } from '../../context/AppContext'
import { Link } from 'react-router-dom'
import swal from 'sweetalert'
import Sidebar from '../../components/Sidebar'
import chatusa from '../../assets/images/chat_icon_sidebar.svg'
import down from '../../assets/images/123.svg'
import edit_icn from '../../assets/images/edit_icn.svg'
import users_icn from '../../assets/images/users_icn.svg'
import userDemo from '../../assets/images/userDemo.png'
import LoaderCenter from '../../sharedComponent/LoaderCenter'
import ApiConfig from '../../api/ApiConfig'
import {
  getWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
  simplePostCall,
} from '../../api/ApiServices'
function Dashboard(id) {
  const { sidebar, setSidebar } = useContext(AppContext)

  const [showElement, setShowElement] = React.useState(true)
  // hello
  useEffect(() => {
    setTimeout(function () {
      setShowElement(false)
    }, 5000)
  }, [])

  const [loading, setLoading] = useState(false)
  const [Profilelistfriend, setProfileListFriend] = useState([])

  const { customerData } = useContext(AppContext)

  const [Profilelistpending, setProfileListPending] = useState({
    request_status: 'Rejected',
    request_id: id,
  })

  const [Profilelist, setProfileList] = useState([])
console.log(Profilelist)
  const [ProfileListTotal, setProfileListTotal] = useState('')

  const [ProfilePendingTotal, setProfilePendingTotal] = useState('')


  useEffect(() => {
    getprofile()
    getprofilefriend()
    getprofilefriendpending()
  }, [])

  const getprofile = () => {
    setLoading(true)

    simpleGetCallWithErrorResponse(
      ApiConfig.UNIVERSR_USER_PROFILE,
      JSON.stringify({ ...customerData }),
    ).then((res) => {
      setLoading(false)

      setProfileList(res.json.user_profile)
    })
  }
  const getprofilefriend = () => {
    setLoading(true)

    simpleGetCallWithErrorResponse(
      ApiConfig.UNIVERSR_FRIEND_LIST_ACCEPTED,
      JSON.stringify({ ...customerData }),
    ).then((res) => {
      console.log(res)
      setLoading(false)

      setProfileListFriend(res.json.friends)
      setProfileListTotal(res.json.friends.length ? res.json.friends.length : 0)
    })
  }
  const getprofilefriendpending = () => {
    setLoading(true)

    getWithAuthCallWithErrorResponse(
      ApiConfig.UNIVERSR_FRIEND_LIST_PENDDING,
      JSON.stringify({
        ...customerData,

        id: Profilelistpending.request_id,
      }),
    ).then((res) => {
      console.log(res)
      setLoading(false)
      setProfileListPending(res.json.pending_friends_request)
      setProfilePendingTotal(
        res.json.pending_friends_request.length
          ? res.json.pending_friends_request.length
          : 0,
      )
    })
  }

  const reject = (id) => {
    setLoading(true)

    simplePostCall(
      ApiConfig.UNIVERSR_FRIEND_REJECT,
      JSON.stringify({
        request_id: id,
        request_status: 'Rejected',
      }),
    )
      .then((res) => {
        swal('Friend request deleted successfully.')
        getprofilefriendpending()
        getprofilefriend()
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }
  const friendrqtaccaccepted = (id) => {
    simplePostCall(
      ApiConfig.UNIVERSR_FRIEND_ACCEPTED,
      JSON.stringify({
        request_id: id,
        request_status: 'Accepted',
      }),
    )
      .then((res) => {
        swal('Friend Request Accepted')
        getprofilefriendpending()
        getprofilefriend()
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }

  return (
    <>
      {/* <Header /> */}
      <Sidebar />
      <main
        id="cxMain"
        className={sidebar ? 'cx-main cx-active' : 'cx-main'}
        style={{
          height: 'calc(100vh - 156px)',
          overflow: 'unset',
        }}
      >
        {loading ? (
          <LoaderCenter />
        ) : (
          <section
            className="content-main"
            style={{
              height: 'calc(100vh - 156px)',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            <div className="row">
              <div className="col-md-12">
                <div
                  className="profile_header d-flex flex-column flex-md-row"
                  style={{
                    position: 'relative',
                  }}
                >
                  <div className="ph_section01 ">
                    <div className="ph_profilePhoto d-flex justify-content-center ">
                      {Profilelist.profile_pic ? (
                        <img
                          src={Profilelist.profile_pic}
                          alt="profile header"
                          style={{
                            width: '111px',
                            height: '111px',
                            objectFit: 'cover',
                          }}
                        />
                      ) : (
                        <img
                          className="profile header"
                          src={userDemo}
                          style={{
                            width: '111px',
                            height: '111px',
                          }}
                          alt="img"
                        />
                      )}
                    </div>

                    <div className="ph_profilePhoto_details ">
                      <ul>
                        <li
                          className="d-flex flex-column align-items-center mt-2 me-0 flex-sm-row "
                          style={{
                            columnGap: '20px',
                          }}
                        >
                          <span
                            style={{
                              color: '#00B4BD',
                              fontSize: '20px',
                              fontWeight: '600',
                            }}
                          >
                            {Profilelist.profile_name}/{Profilelist.full_name}
                          </span>
                          <label
                            htmlFor=""
                            style={{
                              width: '86px',
                              height: '32px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              border: '1px solid #00B4BD',
                              borderRadius: '24px',
                              color: 'white',
                              fontSize: '14px',
                              fontWeight: '600',
                            }}
                          >
                            {Profilelist.gender}
                          </label>
                        </li>

                        <li className="me-0">
                          <ul
                            style={{ marginTop: '16px' }}
                            className="d-flex flex-column align-items-center flex-sm-row"
                          >
                            <li className="me-0">
                              <p> {Profilelist.age} years old </p>
                            </li>
                            <li className="d-flex align-items-center me-0">
                              <div
                                className="dot my-2"
                                style={{
                                  height: '4px',
                                  width: '4px',
                                  backgroundColor: 'white',
                                  borderRadius: '2px',
                                }}
                              ></div>
                            </li>
                            <li className="me-0">
                              <p>{Profilelist.university}</p>
                            </li>

                            <li></li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="ph_section02 d-flex justify-content-end">
                    <Link
                      state={Profilelist}
                      to={'/create_profile/' + Profilelist.id}
                      className="pencil-link pen"
                      style={{
                        position: 'absolute',
                        right: '10px',
                        top: '10px',
                        background: 'white',
                      }}
                    >
                      <img src={edit_icn} alt="" />
                    </Link>

                    <Link
                      className="f-count m-0 me-md-3"
                      to="#"
                      style={{ background: 'none' }}
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight"
                      aria-controls="offcanvasRight"
                    >
                      <div className="ph_userSection">
                        <img src={users_icn} alt="" />
                        <p>Friends</p>
                        <h3>{ProfileListTotal}</h3>
                      </div>
                    </Link>
                    <div>
                      <div
                        class="offcanvas offcanvas-end"
                        tabindex="-1"
                        id="offcanvasRight"
                        aria-labelledby="offcanvasRightLabel"
                        style={{
                          margin: ' 30px 0 30px 0',
                          borderRadius: '30px 0 0 30px ',
                          padding: '30px !important',
                          width: '360px !important',
                        }}
                      >
                        <div className="of-head d-flex justify-content-between align-items-center mt-3 mb-2">
                          <span
                            style={{
                              font: 'normal normal bold 16px/22px Nunito',
                              fontSize: '16px',
                            }}
                          >
                            Friends List
                          </span>
                          <span
                            style={{
                              font: 'normal normal bold 16px/22px Nunito',
                              fontSize: '16px',
                            }}
                          >
                            {ProfileListTotal}
                          </span>
                        </div>
                        <hr />
                        <div
                          class="offcanvas-body"
                          style={{
                            marginTop: '-10px',
                            paddingTop: '0px',

                            padding: '0',
                          }}
                        >
                          {Profilelistfriend && Profilelistfriend.length > 0 ? (
                            Profilelistfriend.map(
                              (ProfilelistfriendAcc, index) => {
                                return (
                                  <>
                                    <div className="frnd-botm">
                                      <div className="fir-frnd">
                                        <div>
                                          {ProfilelistfriendAcc.profile_pic ? (
                                            <img
                                              className="ofImage"
                                              src={
                                                ProfilelistfriendAcc.profile_pic
                                              }
                                              alt="img"
                                            />
                                          ) : (
                                            <img
                                              className="ofImage"
                                              src={userDemo}
                                              alt="img"
                                            />
                                          )}
                                        </div>
                                        <div>
                                          <Link
                                            style={{
                                              background: 'transparent ',
                                            }}
                                            to='#'
                                                
                                                
                                              
                                            className="fName"
                                            // state={ProfilelistfriendAcc}
                                            // to={
                                            //   '/ConnectionAbout/' +
                                            //   ProfilelistfriendAcc.id
                                            // }
                                          >
                                            {ProfilelistfriendAcc.full_name}
                                          </Link>
                                        </div>
                                      </div>
                                      {/* <div className="chaticon">
                                        <img src={chatusa} />
                                      </div> */}
                                    </div>
                                  </>
                                )
                              },
                            )
                          ) : (
                            <p
                              style={{
                                fontSize: '20px',
                                marginTop: '100px',
                              }}
                              className=" text-center justify-content-center align-items-center"
                            >
                              Oops! No Friend List
                              
                            </p>
                          )}
                        </div>
                        <div
                          style={{
                            paddingTop: '20px',
                            width: '100%',
                            textAlign: 'center',
                          }}
                        >
                          <img src={down} style={{ color: 'gray' }} />
                        </div>
                      </div>
                      {/* //////////////// */}

                      {/* ////////////////// */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col">
                <div className="gbl_col_70 aboutSection">
                  <h1 className="profile-headings">About Me</h1>
                  <div className="abt_paraSection">
                    <p style={{ color: '#707070' }}>{Profilelist.about_me}</p>
                  </div>
                  <div className="abt_contentSection">
                    <h1 className="profile-headings">
                      University / Institution
                    </h1>
                    <p style={{ color: '#707070', marginTop: '10px' }}></p>
                  </div>

                  <div className="abt_labelSection">
                    <h1 className="profile-headings">Interests</h1>
                    <ul>
                      {Profilelist.interest &&
                      Profilelist.interest.length > 0 ? (
                        Profilelist.interest.map((item) => {
                          return (
                            <li>
                              <Link to="#">{item.interest.subject}</Link>
                            </li>
                          )
                        })
                      ) : (
                        <p
                          style={{
                            fontSize: '16px',
                            margin: 'auto',
                          }}
                          className=" text-center justify-content-center"
                        >
                          No Interests!!
                        </p>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className=" col-12 col-xl-5 custom-width">
                <div className="friendSectionWrapper w-100">
                  <div className="fs_header">
                    <h1 className="freq">Friend Requests</h1>

                    <button
                      className="freq"
                      // data-bs-toggle="modal"
                      // data-bs-target="#exampleModal1"
                      type="button"
                      role="tab"
                      // aria-controls="v-pills-home"
                      // aria-selected="true"
                    >
                      {ProfilePendingTotal}
                    </button>
                  </div>

                  <div className="friendSectionScroll scrollbar thin-scroll">
                    {Profilelistpending && Profilelistpending.length > 0 ? (
                      Profilelistpending.map(
                        (Profilelistpendingitem, index) => {
                          return (
                            <div className="friendSectionItem d-flex flex-column flex-sm-row">
                              <div className="fs_avatar d-flex align-items-start">
                                {Profilelistpendingitem.user_info
                                  .profile_pic ? (
                                  <img
                                    className="ofImage"
                                    src={
                                      Profilelistpendingitem.user_info
                                        .profile_pic
                                    }
                                    alt="img"
                                  />
                                ) : (
                                  <img
                                    className="ofImage"
                                    src={userDemo}
                                    alt="img"
                                  />
                                )}
                              </div>
                              <div
                                className="fs_details d-flex flex-column align-items-center align-items-sm-start mx-0 mt-2 mt-sm-0 ms-sm-3 d-lg-flex  flex-lg-row flex-xl-column align-items-lg-center align-items-xl-start"
                                style={{ columnGap: '20px' }}
                              >
                                <h2></h2>
                                <Link
                                  state={Profilelistpendingitem}
                                  to={
                                    '/ConnectionAbout/' +
                                    Profilelistpendingitem.request_by
                                  }
                                  className={'fName'}
                                  style={{
                                    backgroundColor: 'transparent',
                                  }}
                                >
                                  {Profilelistpendingitem.user_info.full_name}
                                </Link>

                                <div className="fs_control">
                                  <ul className="d-flex flex-column flex-sm-row">
                                    <li>
                                      <Link
                                        onClick={() => {
                                          friendrqtaccaccepted(
                                            Profilelistpendingitem.id,
                                          )
                                        }}
                                        className="primary"
                                        to="#"
                                      >
                                        Confirm
                                      </Link>
                                    </li>

                                    <li>
                                      <Link
                                        onClick={() => {
                                          reject(Profilelistpendingitem.id)
                                        }}
                                        className="deleteButton mt-2 mt-sm-0 ms-sm-2"
                                        to="#"
                                      >
                                        Delete
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          )
                        },
                      )
                    ) : (
                      <p
                        style={{
                          fontSize: '16px',
                          margin: 'auto',
                        }}
                        className=" text-center justify-content-center"
                      >
                        No Friend Request.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>

      {/* <!-- Sidebar Right --> */}
    </>
  )
}

export default Dashboard

import React, { useContext, useState, useEffect, useRef } from 'react'
import { AppContext } from '../../context/AppContext'
import { useNavigate, useParams } from 'react-router-dom'
import Sidebar from '../../components/Sidebar'
import { Link } from 'react-router-dom'
import dot from '../../assets/images/dot.png'
import src from '../../assets/images/search.svg'
import ApiConfig from '../../api/ApiConfig'
import swal from 'sweetalert'
import userDemo from '../../assets/images/userDemo.png'
import user_icon from '../../assets/images/default.jpg'
import Card from 'react-bootstrap/Card';
import {
  postWithAuthCall,
  simpleGetCallWithErrorResponse,
  simplePostCall,
} from '../../api/ApiServices'
import Loader from '../../sharedComponent/Loader'
import { Modal, Button } from 'react-bootstrap'

function ConnectionsSwip({ setLoggedIn }) {
  const params = useParams()
  let id = params.id
  const {
    sidebar,
    setSidebar,
    customerData,
    setKeyword,
    keyword,
    keywordData,
  } = useContext(AppContext)
  // console.log(keyword);

  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false)
  const [Profilelist, setProfileList] = useState([])
  const [requestsend, setRequestSend] = useState([])
  const [selectedFriend, setSelectedFriend] = useState('')
  const [frirendsearch, setFrirendSearch] = useState([])
  const [showElement, setShowElement] = React.useState(true)
  const [refresh, setRefresh] = useState(true)
  const [showModal, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  // useEffect(() => {
  // const list = frirendsearch.map((item) => {
  //   console.log(item)
  //   return item
  // })
  // setFrirendSearchItem(list)
  // }, [])

  const navigate = useNavigate()
  useEffect(() => {
    setKeyword('')
  }, [])
  const ipRef = useRef(null)
  const handleSearch = () => {
    setKeyword(ipRef.current.value)
  }
  // const handler = useCallback(debounce(setKeyword, 2000), [])
  useEffect(() => {
    getprofile()
  }, [requestsend, keyword])

  useEffect(() => {
    setTimeout(function () {
      setShowElement(false)
      setRefresh(!refresh)
    }, 5000)
  }, [frirendsearch, refresh])

  const logout = (e) => {
    e.preventDefault()
    localStorage.clear()
    setLoggedIn(false)
  }

  const getprofile = () => {
    setLoading(true)

    simpleGetCallWithErrorResponse(
      ApiConfig.UNIVERSR_USER_PROFILE,
      JSON.stringify({ ...customerData }),
    ).then((res) => {
      console.log(res)
      setLoading(false)
      setProfileList(res.json.user_profile)
      if (keyword.length > 0) {
        ipRef.current.value = keyword
        search(keyword)
      }
    })
  }

  const search = (key) => {
    setLoading(true)
    simpleGetCallWithErrorResponse(
      ApiConfig.UNIVERSR_SEARCH_FRIEND + key,
      JSON.stringify({ ...customerData }),
    ).then((res) => {
      console.log(res)
      setLoading(false)
      setFrirendSearch(res.json.search)
      // setFrirendSearchList(res.json.search)
    })
  }

  const friendrequest = (id, e) => {
    e.preventDefault()
    simplePostCall(
      ApiConfig.UNIVERSR_FRIEND_REQUEST,
      JSON.stringify({ request_for: id }),
    )
      .then((res) => {
        console.log(res)
        setRequestSend(res)
        swal(res.detail)
        getprofile()
      })
      .catch((err) => {
        console.log(err)
      })
    setRefresh(false)
  }

  const friendRemove = (e) => {
    e.preventDefault()

    console.log(id)

    postWithAuthCall(
      ApiConfig.UNIVERSR_FRIEND_REMOVE,
      JSON.stringify({ request_for: selectedFriend }),
    )
      .then((res) => {
        swal(res.detail)
        setShow(false)
        getprofile()
        //  navigate('/settings')
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
    setRefresh(false)
  }

  const handleClickReq = (event) => {
    // data-bs-toggle"modal"
    //   data-bs-target="#exampleModalreq"
  }

  return (
    <>
      <Sidebar />
      <div
        style={{
          marginTop: '-100px',
          position: 'fixed',
          width: '99%',
          paddingRight: '3%',
          zIndex: '1',
          backgroundColor: '#F4F4F4',
          paddingBottom: '0px',
        }}
      ></div>
      {/* <Header /> */}
      <>
        <main
          style={{
            paddingTop: '0px',
            // paddingLeft: "6px",
            // paddingRight: "1px",
          }}
          class="main-header"
        >
          <header
            className={sidebar ? 'cx-header cx-active' : 'cx-header'}
            style={{
              padding: '0',
            }}
          >
            <nav class="navbar navbar-expand-lg ">
              <div class="container-fluid header-container">
                {/* <a class="navbar-brand" href="#">Navbsar</a> */}
                <button
                  class="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon">
                    <i class="fa-solid fa-bars"></i>
                  </span>
                </button>
                <div
                  class="collapse navbar-collapse col-lg-12"
                  id="navbarSupportedContent"
                >
                  <ul
                    class="navbar-nav  profile-menu row flex-column flex-sm-row align-items-center"
                    className="mainnaveresponsive"
                    style={{
                      margin: '0',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'start',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      class="col p-0"
                      style={{
                        padding: '0',
                      }}
                    >
                      <div>
                        <li
                          value={name}
                          class="nav-item navmedianinpute"
                          style={{
                            position: 'relative',
                            maxWidth: '800px',
                          }}
                        >
                          <input
                            value={keyword}
                            type="text"
                            onChange={(e) => setKeyword(e.target.value)}
                            ref={ipRef}
                            placeholder="Search my univrs"
                            style={{
                              width: '100%',
                              fontSize: '16px',
                              fontWeight: '500',
                              height: '40px',
                              backgroundColor: '#313131',
                              padding: '0px 0px 0px 20px',
                              color: 'white',
                              borderRadius: '10px',
                              border: 'none',
                            }}
                          />
                          <img
                            onClick={() => handleSearch()}
                            src={src}
                            style={{
                              float: 'right',
                              position: 'absolute',
                              top: '10px',
                              right: '20px',
                            }}
                          />
                        </li>
                      </div>
                      {/* <li
                      // value={searchlist}
                      >
                       
                      </li> */}
                    </div>

                    <div
                      class="col-lg-3 d-flex align-items-center p-0"
                      style={{ width: 'fit-content', height: '44px' }}
                    ></div>
                    <div
                      class="col-lg-3 p-0"
                      style={{
                        width: 'fit-content',

                        marginRight: '10px',
                      }}
                    >
                      <li
                        class="nav-item dropdown "
                        style={{
                          // position: "relative",
                          float: 'left',
                          justifySelf: 'center',
                          marginLeft: '40px',
                          width: 'fit-content',
                          // bottom: "15px",
                        }}
                      >
                        <a
                          class="nav-link dropdown-toggle navimglogo d-flex justify-content-between align-items-center"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{
                            display: 'block',

                            width: 'fit-content',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <img
                            src={
                              Profilelist.profile_pic
                                ? Profilelist.profile_pic
                                : user_icon
                            }
                            // src={Profilelist.profile_pic}
                            style={{
                              justifySelf: 'center',
                              height: '48px',
                              width: '48px',
                              borderRadius: '24px',
                              objectFit: 'cover',
                              outline: '2px solid #00B4BD',
                              outlineOffset: '2px',
                            }}
                          />
                        </a>
                        <ul
                          class="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <li>
                            <Link class="dropdown-item" to="/settings">
                              <i class="fas fa-sliders-h fa-fw"></i> Account
                            </Link>
                          </li>
                          <li>
                            <Link class="dropdown-item" to="/settings">
                              <i class="fas fa-cog fa-fw"></i> Settings
                            </Link>
                          </li>
                          <li>
                            <hr class="dropdown-divider" />
                          </li>
                          <li>
                            <a class="dropdown-item" onClick={logout}>
                              <i class="fas fa-sign-out-alt fa-fw"></i> Log Out
                            </a>
                          </li>
                        </ul>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>

              <div
                class="modal fade"
                id="staticBackdrop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabindex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog">
                  <div
                    class="modal-content "
                    style={{
                      borderRadius: '20px',
                      padding: '30px',
                      backgroundColor: '#F5F5F5',
                      width: '75%',
                      margin: 'auto',
                    }}
                  >
                    <div class="modal-header" style={{ borderBottom: 'none' }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <h5
                          class="modal-title"
                          id="staticBackdropLabel"
                          style={{ padding: '0', marginLeft: '-15px' }}
                        >
                          Change Location
                        </h5>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                    </div>
                    <div>
                      <p
                        style={{
                          color: '#707070',
                          fontSize: '14px',
                          marginBottom: '20px',
                          marginTop: '-10px',
                        }}
                      >
                        Search by town, city, neighbourhood or postal code.
                      </p>
                    </div>
                    <div style={{ marginTop: '20px' }}>
                      <p
                        style={{
                          color: '#707070',
                          fontSize: '14px',
                          marginBottom: '10px',
                        }}
                      >
                        Location
                      </p>
                      <div
                        className="upp_selectWrapper"
                        style={{ marginBottom: '30px' }}
                      >
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          style={{
                            height: '50px',
                            fontSize: '18px',
                            fontWeight: '600',
                            color: 'black',
                          }}
                        >
                          <option value="1">Mumbai , Maharashtra</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                    </div>
                    <div>
                      <p
                        style={{
                          color: '#707070',
                          fontSize: '14px',
                          marginBottom: '10px',
                        }}
                      >
                        Radius
                      </p>
                      <div
                        className="upp_selectWrapper"
                        style={{ marginBottom: '40px' }}
                      >
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          style={{
                            height: '50px',
                            fontSize: '18px',
                            fontWeight: '600',
                            color: 'black',
                          }}
                        >
                          <option value="1">60 Kilometer</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                      <div>
                        <iframe
                          class="actAsDiv"
                          frameborder="0"
                          scrolling="no"
                          marginheight="0"
                          marginwidth="0"
                          src="https://maps.google.com/maps?f=q&amp;source=s_q&amp;q=Adobe%20Systems%20Inc%2C%20Park%20Avenue%2C%20San%20Jose%2C%20CA&amp;aq=0&amp;ie=UTF8&amp;t=m&amp;z=12&amp;iwloc=A&amp;output=embed"
                          style={{
                            width: '100%',
                            height: '25vh',
                            borderRadius: '20px',
                          }}
                        >
                          {' '}
                        </iframe>
                      </div>
                    </div>
                  </div>

                  <div
                    class="modal fade"
                    id="staticBackdrop"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog">
                      <div
                        class="modal-content"
                        style={{ borderRadius: '20px', padding: '30px' }}
                      >
                        <div
                          class="modal-header"
                          style={{ borderBottom: 'none' }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: '100%',
                            }}
                          >
                            <h5
                              class="modal-title"
                              id="staticBackdropLabel"
                              style={{ padding: '0' }}
                            >
                              Change Location
                            </h5>
                            <button
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                        </div>
                        <div>
                          <p
                            style={{
                              color: '#707070',
                              fontSize: '14px',
                              marginBottom: '20px',
                              marginTop: '-10px',
                            }}
                          >
                            Search by town, city, neighbourhood or postal code.
                          </p>
                        </div>
                        <div style={{ marginTop: '20px' }}>
                          <p
                            style={{
                              color: '#707070',
                              fontSize: '14px',
                              marginBottom: '10px',
                            }}
                          >
                            Location
                          </p>
                          <div
                            className="upp_selectWrapper"
                            style={{ marginBottom: '30px' }}
                          >
                            <select
                              class="form-select"
                              aria-label="Default select example"
                              style={{
                                height: '50px',
                                fontSize: '18px',
                                fontWeight: '600',
                                color: 'black',
                              }}
                            >
                              <option value="1">Mumbai , Maharashtra</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                          </div>
                        </div>
                        <div>
                          <p
                            style={{
                              color: '#707070',
                              fontSize: '14px',
                              marginBottom: '10px',
                            }}
                          >
                            Radius
                          </p>
                          <div
                            className="upp_selectWrapper"
                            style={{ marginBottom: '40px' }}
                          >
                            <select
                              class="form-select"
                              aria-label="Default select example"
                              style={{
                                height: '50px',
                                fontSize: '18px',
                                fontWeight: '600',
                                color: 'black',
                              }}
                            >
                              <option value="1">60 Kilometer</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                          </div>
                          <div>
                            <iframe
                              class="actAsDiv"
                              frameborder="0"
                              scrolling="no"
                              marginheight="0"
                              marginwidth="0"
                              src="https://maps.google.com/maps?f=q&amp;source=s_q&amp;q=Adobe%20Systems%20Inc%2C%20Park%20Avenue%2C%20San%20Jose%2C%20CA&amp;aq=0&amp;ie=UTF8&amp;t=m&amp;z=12&amp;iwloc=A&amp;output=embed"
                              style={{
                                width: '100%',
                                height: '25vh',
                                borderRadius: '20px',
                              }}
                            >
                              {' '}
                            </iframe>
                          </div>
                        </div>

                        <div class="modal-footer ">
                          <div
                            className="usa-btn"
                            style={{ width: '100%', alignItems: 'center' }}
                          >
                            <Link
                              className="primary"
                              to="#"
                              style={{
                                padding: '13px',
                                width: '130px',
                                backgroundColor: '#00B4BD',
                              }}
                            >
                              Apply
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </header>
        </main>
      </>
      {/* end */}
      <main id="cxMain" className={sidebar ? 'cx-main cx-active' : 'cx-main'}>
        <section
          className="content-main"
          style={{
            backgroundColor: 'white',
            borderRadius: '20px',
            padding: '10px',
            height: 'calc(100vh - 156px)',
          }}
        >
          <div
            className="no-scroll"
            style={{
              height: '100%',
              overflow: 'auto',
            }}
          >
            {loading ? (
              <Loader />
            ) : (
              <div
                style={{
                  margin: '0',
                }}
                className="row  w-100  h-100"
              >
                {frirendsearch && frirendsearch.length > 0 ? (
                  frirendsearch.map((frirendsearchList, index) => {
            

                    return (
                      <div className="col-12 col-md-6 col-xl-4 col-xxl-3  mt-1 py-1 px-2">
                        <div className="connectionSwip-card ">
                          <div className="connection-top-section ">
                            <div className="image-name-con d-flex flex-column flex-lg-row">
                              <div>
                                {frirendsearchList.profile_pic ? (
                                  <img
                                    src={frirendsearchList.profile_pic}
                                    style={{
                                      borderRadius: '10px',
                                      objectFit: 'cover',
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={userDemo}
                                    style={{
                                      borderRadius: '10px',
                                      objectFit: 'cover',
                                    }}
                                  />
                                )}
                              </div>
                              <div className="d-flex  flex-column align-items-center align-items-lg-start ms-lg-2">
                                <p
                                  className="p-name mt-2"
                                  style={{
                                    // font: "normal normal bold 20px/27px Nunito",
                                    fontFamily: 'Nunito',
                                    fontSize: '20px',
                                    lineHeight: '27px',
                                    fontStyle: 'normal',
                                    letterSpacing: '0px',
                                    color: '#21212C',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  <Link
                                    state={frirendsearchList}
                                    to={
                                      (frirendsearchList.connect_status[0] &&
                                        frirendsearchList.connect_status[0]
                                          .request_status === 'Pending') ||
                                      (frirendsearchList.connect_status &&
                                        frirendsearchList.connect_status
                                          .request_status === 'Add Friend')
                                        ? '#'
                                        : '/ConnectionAbout/' +
                                          frirendsearchList.id
                                    }
                                  >
                                    {frirendsearchList.full_name}
                                  </Link>
                                </p>
                                <p
                                  style={{
                                    paddingTop: '10px',

                                    fontFamily: 'Nunito',
                                    fontSize: '15x',
                                    lineHeight: '20px',
                                    fontStyle: 'normal',
                                    letterSpacing: '0px',
                                    color: '#707070',
                                  }}
                                >
                                  {/* University of Delhi */}
                                </p>
                              </div>
                            </div>
                            <hr style={{ margin: '0' }} />
                            <div className="connection-matual">
                              {frirendsearchList.mutual_friends ? (
                                <p
                                  style={{
                                    font:
                                      'normal normal normal 14px/19px Nunito',
                                    fontFamily: 'Nunito',
                                    fontSize: '14x',
                                    lineHeight: '19px',
                                    fontStyle: 'normal',
                                    letterSpacing: '0px',
                                    color: '#707070',
                                  }}
                                >
                                  <img
                                    src={dot}
                                    alt="img"
                                    style={{
                                      alignItems: 'center',
                                      margin: '2px 10px',
                                      font:
                                        'normal normal normal 14px/19px Nunito',
                                      color: '#707070',
                                      letterSpacing: '0px',
                                    }}
                                  />{' '}
                                  {frirendsearchList.mutual_friends} Mutual
                                  Friends
                                </p>
                              ) : (
                                <p
                                  style={{
                                    font:
                                      'normal normal normal 14px/19px Nunito',
                                    fontFamily: 'Nunito',
                                    fontSize: '14x',
                                    lineHeight: '19px',
                                    fontStyle: 'normal',
                                    letterSpacing: '0px',
                                    color: '#707070',
                                  }}
                                >
                                  <img
                                    src={dot}
                                    alt="img"
                                    style={{
                                      alignItems: 'center',
                                      margin: '2px 10px',
                                      font:
                                        'normal normal normal 14px/19px Nunito',
                                      color: '#707070',
                                      letterSpacing: '0px',
                                    }}
                                  />{' '}
                                  No Mutual Friends
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="connection-botton">
                            <h6
                              style={{
                                font: 'normal bold 15px/20px Nunito',
                                color: '#FFFFFF',
                                letterSpacing: '0px',
                              }}
                            >
                              <a
                                href="#"
                                onClick={(e) => {
                                  frirendsearchList.connect_status
                                    .request_status === 'Add Friend' &&
                                    friendrequest(frirendsearchList.id, e)
                                  if (
                                    Array.isArray(
                                      frirendsearchList.connect_status,
                                    )
                                  ) {
                                    setSelectedFriend(frirendsearchList.id)
                                    if (
                                      frirendsearchList.connect_status[0]
                                        .request_status === 'Accepted'
                                    ) {
                                      frirendsearchList.connect_status[0]
                                        .request_status === 'Accepted' &&
                                        navigate('/groups')
                                    } else {
                                      frirendsearchList.connect_status[0]
                                        .request_status === 'Pending' &&
                                        handleShow()
                                    }
                                  }
                                }}
                                className="grp-btn"
                              >
                                {/* Add Friend */}
                                {frirendsearchList.connect_status
                                  .request_status === 'Add Friend'
                                  ? 'Add Friend'
                                  : frirendsearchList.connect_status[0]
                                      .request_status === 'Accepted'
                                  ? 'Connect'
                                  : frirendsearchList.connect_status
                                      .request_status === 'Pending'
                                  ? 'Request sent'
                                  : 'Request sent'}
                              </a>
                            </h6>
                          </div>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <div className="h-100 d-flex justify-content-center align-items-center">
                    <p
                      style={{
                        fontSize: '20px',
                        marginTop: '80px',
                      }}
                      className=" text-center justify-content-center align-items-center"
                    >
                      Oops! It doesn't look like there are any Friend in your
                      area.
                      <br /> Why don't you try expanding your search criteria?
                    </p>
                  </div>
                )}
              </div>
            )}
            {/* modal  */}
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: '100vh' }}
            >
              <Modal
                className="custom-dialog"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showModal}
                onHide={handleClose}
              >
                <Modal.Header
                  style={{ padding: '12px', width: '99%' }}
                  closeButton
                >
                  <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div
                    class="modal-body"
                    style={{ marginTop: '-37px', padding: '20px' }}
                  >
                    <p
                      style={{
                        margin: '20px 10px',
                        fontWeight: '600',
                        fontSize: '19px',
                        fontFamily: 'Nunito',
                        color: '#1D1D25',
                      }}
                    >
                      Are you sure you want to cancel this Friend Request?
                    </p>
                  </div>
                </Modal.Body>

                <Modal.Footer>
                  <div
                    className="usa-btn"
                    style={{
                      marginTop: '-30px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginRight: '83px',
                    }}
                  >
                    <Link
                      onClick={handleClose}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      to="/connection_swip"
                      className="fir"
                      style={{ padding: '13px', width: '130px' }}
                    >
                      Cancel
                    </Link>

                    <Link
                      onClick={(e) => {
                        friendRemove(e)
                      }}
                      className="primary"
                      to="#"
                      style={{ padding: '13px', width: '130px' }}
                    >
                      Confirm
                    </Link>

                    {/* )
              })} */}
                  </div>
                </Modal.Footer>
              </Modal>
            </div>
            {/* Close */}
          </div>
        </section>
      </main>
    </>
  )
}

export default ConnectionsSwip

import React, { useEffect } from "react";
import upload from "../assets/images/upload_icon.svg";
export default function ImageUplode({ setFile }) {
  let id = "DropZone";
  useEffect(() => {
    initDragAndDrop();
  }, []);
  const initDragAndDrop = () => {
    var dropZone = document.getElementById(id);
    // Optional.   Show the copy icon when dragging over.  Seems to only work for chrome.
    dropZone &&
      dropZone.addEventListener("dragover", function (e) {
        e.stopPropagation();
        e.preventDefault();
        e.dataTransfer.dropEffect = "copy";
      });
    dropZone && dropZone.addEventListener("drop", drop);
  };
  function drop(e) {
    e.stopPropagation();
    e.preventDefault();
    let file = "";
    if (e.target && e.target.files) {
      file = e.target.files;
    } else {
      file = e.dataTransfer.files;
    }
    setFile(file);
  }

  return (
    <div className="border-block " id={id}>
      <div
        className="custom-file-1  ce-input-1 ce-upload-1 custom-resp-1 "
        style={{
          position: "relative",
          height: "148px",
          width: "148px",
          padding: "0",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <input
          multiple
          onChange={drop}
          accept="image/png, image/gif, image/jpeg"
          id={"uploadImages"}
          type="file"
          name="vehicle_images"
          style={{
            display: "none",
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: "1",
            padding: "0",
            margin: "0",
          }}
        />
        <img
          style={
            {
              // marginLeft: "40px",
              // marginTop: "30px",
            }
          }
          src={upload}
          alt=""
          className="upload-icon-1"
        />

        <a
          href="#"
          style={{ color: "#4D9DE7" }}
          onClick={(e) => {
            e.preventDefault();
            document.getElementById("uploadImages").click();
          }}
        >
          <p
            style={{
              marginTop: "20px",
            }}
            className="uimage-1"
          >
            Upload Image
          </p>
        </a>
      </div>
    </div>
  );
}

import React, { useContext, useState, useEffect } from 'react'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import userDemo from '../assets/images/userDemo.png'

import { AppContext } from '../context/AppContext'
import profile from '../assets/images/profile_picture.jpg'
import chat from '../assets/images/white_chat.svg'
import request from '../assets/images/requested.png'
import ApiConfig from '../api/ApiConfig'
import { simpleGetCallWithErrorResponse } from '../api/ApiServices'
import LoaderCenter from '../sharedComponent/LoaderCenter'
import Moment from 'react-moment'

function Notification() {
  const {
    sidebar,
    customerData,
    setNotificationTotal,
    notificationTotal,
  } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const [showElement, setShowElement] = React.useState(true)
  const [notification, setNotification] = useState([])

  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    setTimeout(function () {
      setShowElement(false)
    }, 5000)
  }, [])

  const handleClick = (id) => {
    // console.log("This One is Getting Hit!", JSON.stringify(id));
    //  toggle
    setIsActive((current) => !current)

    setIsActive(true)
  }

  useEffect(() => {
    getNotification();
    getNotificationCount()
  }, [])

  const getNotification = () => {
    setLoading(true)

    simpleGetCallWithErrorResponse(
      ApiConfig.NOTIFICATION,
      JSON.stringify({ ...customerData }),
    ).then((res) => {
      console.log("Haina",res)
      setLoading(false)
      setNotification(res.json.notifications)
      //  setNotificationTotal(
      //   res.json.notifications.length
      //     ? res.json.notifications.length
      //     : 0,
      // )
    })
  }

  const getNotificationCount = () => {
    setLoading(true)

    simpleGetCallWithErrorResponse(
      ApiConfig.NOTIFICATION_COUNT,
      JSON.stringify({ ...customerData }),
    ).then((res) => {
      console.log(res)
      setLoading(false)
      setNotificationTotal(res.json.notification_count)
    })
  }

  return (
    <div class="">
      <Sidebar />

      {/* <Header /> */}
      <main id="cxMain" className={sidebar ? 'cx-main cx-active' : 'cx-main'}>
        {loading ? (
          <LoaderCenter />
        ) : (
          <section
            className="content-main"
            style={{
              backgroundColor: 'white',
              borderRadius: '20px',
              padding: '20px',
              height: 'fit-content',
              minHeight: 'calc(100vh - 156px)',
            }}
          >
            <p
              style={{
                color: '#1D1D25',
                fontSize: '20px',
                fontWeight: 'bold',
                paddingBottom: '20px',

                borderBottom: '1px solid #E8E8E8',
              }}
            >
              Notifications
              <span
                style={{
                  color: '#00B4BD',
                  fontSize: '20px',
                  fontWeight: 'bold',
                  marginLeft: '20px',
                }}
              >
                {notificationTotal}
              </span>
             
            </p>
            <div className="row">
              {notification && notification.length > 0 ? (
                notification.map((notificationItem, index) => {
                  console.log('notificationItem', notificationItem.text)
                  return (
                    <Link
                      to={
                        notificationItem.text ===
                        'You have recived like on your forum.'
                          ? '/forum_list'
                          : notificationItem.text ===
                            'You have recived comment on your forum.'
                          ? '/forum_list'
                          : notificationItem.text ===
                            'You have received new friend request.'
                          ? '/dashboard'
                          : notificationItem.text ===
                            'You have received comment on your forum.'
                          ? '/forum_list'
                          : notificationItem.text ===
                            'You have recived like on your forum.'
                          ? '/forum_list'
                          : notificationItem.text ===
                            'You have received like on your forum.'
                          ? '/forum_list'
                          : notificationItem.text ===
                            'Someone is interested in your housing property.'
                          ? '/house_listing/manage'
                          : notificationItem.title === 'Housing property.'
                          ? '/house_listing/all'
                          : notificationItem.title ===
                            'Someone is Interested in your product.'
                          ? '/events/all'
                          : '/notification'
                      }
                    >
                      <div
                        id={`${index}`}
                        onClick={handleClick}
                        className="col-12"
                      >
                        <div
                          className="notification-card d-flex align-items-start"
                          style={{
                            padding: '20px 0',
                            borderBottom: '1px solid #E8E8E8',
                          }}
                        >
                          {/* <img
                            src={notificationItem.profile_pic}
                            alt=""
                            style={{
                              height: '40px',
                              width: '40px',
                              borderRadius: '10px',
                              objectFit: 'cover',
                            }}
                          /> */}
                            {notificationItem.profile_pic ? (
                                 <img
                                 src={notificationItem.profile_pic}
                                 alt=""
                                 style={{
                                   height: '40px',
                                   width: '40px',
                                   borderRadius: '10px',
                                   objectFit: 'cover',
                                 }}
                               />
                                ) : (
                                  <img
                                  src={userDemo}
                                  alt=""
                                  style={{
                                    height: '40px',
                                    width: '40px',
                                    borderRadius: '10px',
                                    objectFit: 'cover',
                                  }}
                                />
                                )}
                          <div className="noti-details ms-3 w-100">
                            <div className="name-time d-flex justify-content-between align-items-start p-0">
                              <span
                                style={{
                                  // color: '#00B4BD',
                                  color: isActive ? '#989898' : '#00B4BD',

                                  fontSize: '14px',
                                  fontWeight: '600',
                                }}
                              >
                                {notificationItem.profile_name}
                              </span>
                              <span
                                style={{
                                  // color: '#BBBBBB',
                                  color: isActive ? '#BBBBBB' : '#989898',
                                  fontSize: '14px',
                                  fontWeight: 'normal',
                                }}
                              >
                                <Moment format="MMMM Do YYYY, h:mm:ss a">
                                  {notificationItem.created_at}
                                </Moment>
                              </span>
                            </div>
                            <p
                              style={{
                                // color: '#21212C',
                                color: isActive ? '#989898' : '#21212C',
                                fontSize: '14px',
                                fontWeight: 'medium',
                              }}
                            >
                              {notificationItem.text}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  )
                })
              ) : (
                <p
                  style={{
                    fontSize: '25px',
                    margin: 'auto',
                  }}
                  className=" text-center justify-content-center"
                >
                  Data not found
                </p>
              )}
            </div>
          </section>
        )}
      </main>
    </div>
  )
}

export default Notification

import React, { useContext, useState, useEffect } from 'react'
import Sidebar from '../../components/Sidebar'
import Header from '../../components/Header'
import { AppContext } from '../../context/AppContext'
import back from '../../assets/images/back.svg'
import profile_picture from '../../assets/images/profile_picture.jpg'
import chatusa from '../../assets/images/chat_icon_sidebar.svg'
import { Link, useNavigate } from 'react-router-dom'
import userDemo from '../../assets/images/userDemo.png'

import {
  deleteWithAuthCall,
  simpleGetCallWithErrorResponse,
  simplePostCall,
} from '../../api/ApiServices'
import ApiConfig from '../../api/ApiConfig'
import swal from 'sweetalert'
import { Button } from 'react-bootstrap'

const Settinglisting = ({ setLoggedIn }) => {
  const { sidebar, setSidebar, customerData } = useContext(AppContext)
  const navigate = useNavigate()
  const [blocklist, setBlockList] = useState([])
  const [UpdateDetails, setUpdateDetails] = useState([])
  // console.log('UpdateDetails', UpdateDetails)
  const [loading, setLoading] = useState(false)
  const [errMsg, setErrMsg] = useState({ message: '' })
  const [ProfileListTotal, setProfileListTotal] = useState('')
  const [errMsgal, setErrMsgAll] = useState({ message: '' })
  const [errMsgSug, setErrMsgSug] = useState({ message: '' })
  const [userprives, setUserprives] = useState({
    privacy_real_name: '',
    privacy_age: '',
    privacy_visibility: '',
  })
  console.log('userprives', userprives)
  const [userreport, setUserreport] = useState({
    type: 'Report Bug',
    message: '',
  })
  const [userreportsuggestion, setUserreportsuggestion] = useState({
    type: 'Suggestion',
    message: '',
  })
  
  useEffect(() => {
    getuserblock()
    getPrivacy()
  }, [])

  const logout = (e) => {
    e.preventDefault()
    localStorage.clear()
    setLoggedIn(false)
  }

  const DeleteAccount = () => {
    deleteWithAuthCall(ApiConfig.UNIVERSR_ACCOUNT_DELETE)
      .then((res) => {
        console.log(res)
        navigate('/')
        swal(res.message)
      })
      .catch((err) => {
        setLoading(false)

        console.log(err)
      })
  }

  const getuserblock = () => {
    setLoading(true)

    simpleGetCallWithErrorResponse(
      ApiConfig.UNIVERSR_USER_BLOCK,
      JSON.stringify({ ...customerData }),
    ).then((res) => {
      console.log(res)
      setLoading(false)
      setBlockList(res.json.block_status)
      setProfileListTotal(
        res.json.detail === 'No block list found.'
          ? 0
          : res.json.block_status.length,
      )
    })
  }
  const getPrivacy = () => {
    setLoading(true)

    simpleGetCallWithErrorResponse(
      ApiConfig.GET_PRIVACY_SETING,
      JSON.stringify({ ...customerData }),
    ).then((res) => {
      console.log(res)
      let profileDetails = res.json

      setUserprives({
        privacy_age: profileDetails.privacy_age,
        privacy_real_name: profileDetails.privacy_real_name,
        privacy_visibility: profileDetails.privacy_visibility,
      })
    })
  }

  const unblockuser = (id) => {
    simplePostCall(
      ApiConfig.UNIVERSR_UNBLOCK,
      JSON.stringify({
        blocked_to: id,
      }),
    )
      .then((res) => {
        swal(res.detail)
        //  navigate('/settings')
        getuserblock()
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }
  const reportuser = (id) => {
    if (userreport.message === '') {
      // setErrMsgAll({ ...errMsgal, message: 'Please enter message! ' })
      swal("Please enter message!")
      return
    } else {
      simplePostCall(
        ApiConfig.UNIVERSR_CONTACTUS,
        JSON.stringify({
          ...userreport,
        }),
      )
        .then((res) => {
          // setUserreportList(res)
          swal(res.detail)
          navigate('/settings')
          
        })
        .catch((err) => {
          setLoading(false)
          console.log(err)
        })
    }
  }
  const reportuserSuggestion = (id) => {
    if (userreportsuggestion.message === '') {
      // setErrMsgSug({ ...errMsgSug, message: 'Please enter message! ' })
      swal("Please enter message!")

      return
    } else {
      simplePostCall(
        ApiConfig.UNIVERSR_CONTACTUS,
        JSON.stringify({
          ...userreportsuggestion,
        }),
      )
        .then((res) => {
          // setUserreportList(res)
          // setUserreportsuggestion("")
          swal(res.detail)
          navigate('/settings')
        })
        .catch((err) => {
          setLoading(false)
          console.log(err)
        })
    }
  }
  
  const PrivacySave = (id) => {
    simplePostCall(
      ApiConfig.UNIVERSR_PRIVACY_SETING,
      JSON.stringify({ ...userprives }),
    )
      .then((res) => {
        console.log(res)
        swal(res)

        // if (res.data) {
        //   swal("Update");
        // }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div>
      {/* <Header /> */}
      <Sidebar />

      <main id="cxMain" className={sidebar ? 'cx-main cx-active' : 'cx-main'}>
        <section
          className="content-main"
          style={{ backgroundColorcolor: '#F5F5F5' }}
        >
          <div class="SettingMainListing">
            <div class="d-flex align-items-start mainmainmain">
              <div
                class="nav flex-column nav-pills me-3 mainTab"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <button
                  class="nav-link active settingtabs"
                  id="v-pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-home"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-home"
                  aria-selected="true"
                >
                  Profile
                </button>
                <button
                  class="nav-link settingtabs"
                  id="v-pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-profile"
                  aria-selected="false"
                >
                  Feedback
                </button>
                <button
                  class="nav-link settingtabs"
                  id="v-pills-messages-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-messages"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-messages"
                  aria-selected="false"
                >
                  More Information
                </button>
                <button
                  class="nav-link settingtabs"
                  id="v-pills-settings-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-settings"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-settings"
                  aria-selected="false"
                >
                  Account
                </button>
              </div>
              <div class="tab-content settingdataviwe" id="v-pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="v-pills-home"
                  role="tabpanel"
                  aria-labelledby="v-pills-home-tab"
                >
                  <div className="row mb-3 d-xl-flex align-items-xl-center">
                    <div className="col-12 mb-2 mb-xl-0 col-xl-6">
                      <label
                        style={{
                          color: '#21212C',
                          fontSize: '16px',
                          fontWeight: '600',
                        }}
                      >
                        Real Name
                      </label>
                    </div>
                    <div className="col-12 col-xl-6">
                      <select
                        value={userprives.privacy_real_name}
                        onChange={(e) => {
                          setUserprives({
                            ...userprives,
                            privacy_real_name: e.target.value,
                          })
                        }}
                        style={{
                          backgroundColor: '#ffffff',
                          height: '50px',
                          borderRadius: '10px',
                          fontSize: '16px',
                          fontWeight: '600',
                        }}
                        class="form-select upp_selectWrapper-form-select setting-form"
                        aria-label="Default select example"
                      >
                        <option value="public">Public</option>
                        <option value="friends">Friends</option>
                        <option value="no_one">No One</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mb-3 d-xl-flex align-items-xl-center">
                    <div className="col-12 mb-2 mb-xl-0 col-xl-6">
                      <label
                        style={{
                          color: '#21212C',
                          fontSize: '16px',
                          fontWeight: '600',
                        }}
                      >
                        Age
                      </label>
                    </div>
                    <div className="col-12 col-xl-6">
                      <select
                        value={userprives.privacy_age}
                        onChange={(e) => {
                          setUserprives({
                            ...userprives,
                            privacy_age: e.target.value,
                          })
                        }}
                        style={{
                          backgroundColor: '#ffffff',
                          height: '50px',
                          borderRadius: '10px',
                          fontSize: '16px',
                          fontWeight: '600',
                        }}
                        class="form-select upp_selectWrapper-form-select setting-form"
                        aria-label="Default select example"
                      >
                        <option value="public">Public</option>
                        <option value="friends">Friends</option>
                        <option value="no_one">No One</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mb-3 d-xl-flex align-items-xl-center">
                    <div className="col-12 mb-2 mb-xl-0 col-xl-6">
                      <label
                        style={{
                          color: '#21212C',
                          fontSize: '16px',
                          fontWeight: '600',
                        }}
                      >
                         My Pronouns
                      </label>
                    </div>
                    <div className="col-12 col-xl-6">
                      <select
                        value={userprives.privacy_visibility}
                        onChange={(e) => {
                          setUserprives({
                            ...userprives,
                            privacy_visibility: e.target.value,
                          })
                        }}
                        style={{
                          backgroundColor: '#ffffff',
                          height: '50px',
                          borderRadius: '10px',
                          fontSize: '16px',
                          fontWeight: '600',
                        }}
                        class="form-select upp_selectWrapper-form-select setting-form"
                        aria-label="Default select example"
                      >
                        {/* hello */}
                        <option value="public">Public</option>
                        <option value="friends">Friends</option>
                        <option value="no_one">No One</option>
                      </select>
                      <Button
                        style={{
                          float: 'right',
                          marginTop: '20px',
                          backgroundColor: ' #00868E',
                          color: 'white',
                          border: 'none',
                          width: '110px',
                        }}
                        // Onclick={PrivacySave}
                        onClick={() => {
                          PrivacySave()
                        }}
                      >
                        {' '}
                        Save{' '}
                      </Button>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade settingdataviwe"
                  id="v-pills-profile"
                  role="tabpanel"
                  aria-labelledby="v-pills-profile-tab"
                  style={{ height: '70vh' }}
                >
                  <div class="FBheading">
                    <p>Report a bug</p>
                  </div>
                  <div class="FBmessage1 ">
                    <label>Message</label>
                    <br />
                    <div className="setting-ip-wrapper setting-text">
                      <textarea
                        // value={userprives.message}
                        onChange={(e) => {
                          setErrMsgAll({
                            ...errMsgal,
                            message: '',
                          })

                          setUserreport({
                            ...userreport,
                            message: e.target.value,
                          })
                        }}
                        placeholder="Enter Your Message Here..."
                      ></textarea>
                      <button onClick={reportuser} className="setting-send">
                        Send
                      </button>
                    </div>
                    {/* {errMsgal.message.length > 0 && (
                      <span className="errmsgshow">{errMsgal.message}</span>
                    )} */}
                  </div>
                  <div class="FBheading">
                    <p
                      style={{
                        marginTop: '30px',
                      }}
                    >
                      I want to make a suggestion
                    </p>
                  </div>
                  <div class="FBmessage1">
                    <label>Message</label>
                    <br />
                    <div className="setting-ip-wrapper setting-text">
                      <textarea
                        // value={userprives.message}
                        onChange={(e) => {
                          setErrMsgSug({
                            ...errMsgSug,
                            message: '',
                          })


                          setUserreportsuggestion({
                            ...userreportsuggestion,
                            message: e.target.value,
                          })
                        }}
                        placeholder="Enter Your Message Here..."
                      ></textarea>
                      <button
                        onClick={reportuserSuggestion}
                        className="setting-send"
                      >
                        Send
                      </button>
                    </div>
                    {/* {errMsgSug.message.length > 0 && (
                      <span className="errmsgshow">{errMsgSug.message}</span>
                    )} */}
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="v-pills-messages"
                  role="tabpanel"
                  aria-labelledby="v-pills-messages-tab"
                >
                  <div>
                    <div class="FBheading">
                      {/* <p
                        style={{
                          borderBottom: '2px solid #E9E9E9',
                        }}
                      >
                        Report a bug
                      </p> */}
                    </div>
                    <Link class="MIbtn" to="/about">
                      <div>
                        <p>About us</p>
                      </div>
                      <div>
                        <img src={back} />
                      </div>
                    </Link>
                    <Link class="MIbtn" to="/privacy_policy">
                      <div>
                        <p>Privacy Policy</p>
                      </div>
                      <div>
                        <img src={back} />
                      </div>
                    </Link>
                    <Link class="MIbtn" to="/TermsConditions">
                      <div>
                        <p>Terms and Conditions</p>
                      </div>
                      <div>
                        <img src={back} />
                      </div>
                    </Link>
                    <Link class="MIbtn" to="/contactus">
                      <div>
                        <p>Contact Us</p>
                      </div>
                      <div>
                        <img src={back} />
                      </div>
                    </Link>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="v-pills-settings"
                  role="tabpanel"
                  aria-labelledby="v-pills-settings-tab"
                >
                  {' '}
                  <div>
                    <div class="FBheading">
                      {/* <p
                        style={{
                          borderBottom: '2px solid #E9E9E9',
                        }}
                      >
                        Report a bug
                      </p> */}
                    </div>
                    <button
                      class="MIbtn"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalblock"
                    >
                      <div>
                        <p>Blocked Contacts</p>
                      </div>
                      <div>
                        <img src={back} />
                      </div>
                    </button>
                    <button class="MIbtn">
                      <div>
                        <p
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModaldelelte"
                        >
                          Delete Account
                        </p>
                      </div>
                      <div>
                        <img src={back} />
                      </div>
                    </button>
                    <button class="MIbtn">
                      <div>
                        <p
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModalLOg"
                        >
                          Log Out
                        </p>
                      </div>
                      <div>
                        <img src={back} />
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* modal log out */}
      <div
        class="modal fade main-model-of-block"
        id="exampleModalLOg"
        tabindex="-1"
        aria-labelledby="exampleModalLabelLOg"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered "
          style={{ width: '393px' }}
        >
          <div
            class="modal-content"
            style={{
              borderRadius: '30px',
              padding: '10px',
              backgroundColor: '#F5F5F5',
            }}
          >
            <div
              style={{
                padding: '13px 14px 20px 1px',
                fontSize: '20px',
                color: '#21212C',
                fontFamily: 'Nunito',
                fontWeight: 'bold',
                letterSpacing: '0px',
                textAlign: 'center',
              }}
              class="modal-header"
            >
              {/* <img src={block} style={{ marginLeft: "8px" }} /> */}
              <h5
                class="modal-title"
                id="exampleModalLabel"
                style={{ marginLeft: '20px' }}
              >
                {/* Block {connectionProfilelist.full_name} */}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{
                  backgroundColor: 'white',
                  border: '1px solid #ECECEC',
                  borderRadius: '5px',
                }}
              ></button>
            </div>
            <div class="modal-body" style={{ marginTop: '-20px' }}>
              <p
                style={{
                  margin: '20px 10px',
                  fontWeight: '600',
                  fontSize: '20px',
                  fontFamily: 'Nunito',
                  color: '#1D1D25',
                }}
              >
                Are you sure you want to log out?
              </p>
            </div>
            <div class="modal-footer ">
              <div
                className="usa-btn"
                style={{
                  marginTop: '-30px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '25px',
                }}
              >
                <Link
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  to="/ConnectionAbout"
                  className="fir"
                  style={{ padding: '13px', width: '130px' }}
                >
                  Cancel
                </Link>
                <Link
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="primary"
                  to="#"
                  onClick={logout}
                  style={{ padding: '13px', width: '130px' }}
                >
                  Confirm
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Close */}

      {/* modal Dellte */}
      <div
        class="modal fade main-model-of-block"
        id="exampleModaldelelte"
        tabindex="-1"
        aria-labelledby="exampleModalLabelLOg"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered "
          style={{ width: '393px' }}
        >
          <div
            class="modal-content"
            style={{
              borderRadius: '30px',
              padding: '10px',
              backgroundColor: '#F5F5F5',
            }}
          >
            <div
              style={{
                padding: '13px 14px 20px 1px',
                fontSize: '20px',
                color: '#21212C',
                fontFamily: 'Nunito',
                fontWeight: 'bold',
                letterSpacing: '0px',
                textAlign: 'center',
              }}
              class="modal-header"
            >
              <h5
                class="modal-title"
                id="exampleModalLabel"
                style={{ marginLeft: '20px' }}
              ></h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{
                  backgroundColor: 'white',
                  border: '1px solid #ECECEC',
                  borderRadius: '5px',
                }}
              ></button>
            </div>
            <div class="modal-body" style={{ marginTop: '-20px' }}>
              <p
                style={{
                  margin: '20px 10px',
                  fontWeight: '600',
                  fontSize: '20px',
                  fontFamily: 'Nunito',
                  color: '#1D1D25',
                }}
              >
                Are you sure you want to Delete ?
              </p>
            </div>
            <div class="modal-footer ">
              <div
                className="usa-btn"
                style={{
                  marginTop: '-30px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '25px',
                }}
              >
                <Link
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  to="/ConnectionAbout"
                  className="fir"
                  style={{ padding: '13px', width: '130px' }}
                >
                  Cancel
                </Link>
                <Link
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="primary"
                  to="#"
                  onClick={DeleteAccount}
                  style={{ padding: '13px', width: '130px' }}
                >
                  Confirm
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Close */}

      {/* modal starts */}
      <div
        class="modal  fade"
        id="exampleModalblock"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          style={{
            width: '80%',
            maxHeight: 'calc(100vh - 40px)',
            maxWidth: '338px',
            borderRadius: '20px',
          }}
        >
          <div
            class="modal-content modal-centered "
            style={{
              borderRadius: '20px',
              maxHeight: '84vh',
              overflow: 'auto',
              margin: '0px auto 0',
              height: 'fit-content',
              minHeight: 'calc(100vh - 40px)',
            }}
          >
            {' '}
            <div
              class="offcanvas-header"
              style={{
                marginTop: '0px',
                padding: '0',
                marginTop: '40px',
                width: '100%',
                padding: '0 30px',
              }}
            >
              <span className="b-contacts">Blocked Contacts</span>
              <span className="b-contacts-counts">{ProfileListTotal}</span>
            </div>
            <div
              class="offcanvas-body"
              style={{ marginTop: '-5px', margin: '14px' }}
            >
              {blocklist &&
               blocklist.length > 0 ? (
                blocklist.map((blocklistAll, index) => {
                  return (
                    <div className="frnd-botm">
                      <div className="fir-frnd">
                        <div>
                          
                          {blocklistAll.profile_pic ? (
                            <img
                              src={blocklistAll.profile_pic}
                              alt="img"
                              style={{
                                height: '40px',
                                width: '40px',
                              }}
                            />
                          ) : (
                            <img
                              src={userDemo}
                              alt="img"
                              style={{
                                height: '40px',
                                width: '40px',
                              }}
                            />
                          )}
                        </div>
                        <div>
                          <p>{blocklistAll.full_name}</p>
                        </div>
                      </div>
                      <div className="chaticon">
                        <button
                          onClick={() => {
                            unblockuser(blocklistAll.id)
                          }}
                          style={{
                            border: '1px solid #00ffdc',
                            padding: '5px 20px 5px 20px',
                            borderRadius: '20px',
                            color: '#1D1D25',
                            fontSize: '14px',
                          }}
                        >
                          Unblock
                        </button>
                      </div>
                    </div>
                  )
                })
                ) : (
                  <p
                    style={{
                      fontSize: '18px',
                      marginTop: '200px',
                    }}
                    className=" text-center justify-content-center align-items-center"
                  >
                 You haven't blocked any one. 
                  </p>
                )}
            </div>
          </div>
        </div>
      </div>
      {/* modal ends */}
    </div>
  )
}

export default Settinglisting

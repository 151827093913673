import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import swal from "sweetalert";
import { Icon } from "@iconify/react";
// import locationIcon from "@iconify/icons-mdi/map-marker";
import profile_picture from "../../assets/images/profile_picture.jpg";
import createselectAvatar from "../../assets/images/createselectAvatar.png";
import createadd_avatar from "../../assets/images/createadd_avatar.png";
import thumbImg from "../../assets/images/house01.png";
import upload from "../../assets/images/upload_icon.svg";
import GoogleMapReact from "google-map-react";
import ApiConfig from "../../api/ApiConfig";
import ImageUplode from "../../sharedComponent/ImageUplode";
import location from "../../assets/images/location.svg";

import {
  multipartPostCallWithErrorResponse,
  postMultipartWithAuthCall,
  postMultipartWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
  putMultipartWithAuthCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
  simplePUTCall,
} from "../../api/ApiServices";
import {Modal} from "react-bootstrap";
import Payment from "../payment/Payment";

function UpdateProperty() {
  const [errorMsg, setErrMsg] = useState({
    title: "",
    property_description: "",
    numbers_of_rooms: "",
    address: "",
    monthly_rent: "",
    deposit: "",
    property_image: "",

    // selects
    property_type: "",
    bills_included: "",
    gender: "",
    term: "",
    smokers_allowed: "",
    pets_allowed: "",
    availability: "",
    type: "",
    property_image: "",
  });

  const [UpdatePropertyMange, setUpdateProperty] = useState({
    title: "",
    property_description: "",
    type: "",
    property_type: "",
    bills_included: "",
    gender: "",
    term: "",
    smokers_allowed: "",
    pets_allowed: "",
    numbers_of_rooms: "",
    availability: "",
    address: "",
    monthly_rent: "",
    deposit: "",
    property_image: [],
    lat: "",
    long: "",
    featured_ads: false,
    featured_ads_duration: null,
    featured_ads_cost: null,
  });

  const [featureAdd, setFeatureAdd] = useState({
    featured_ads: false,
    featured_ads_duration: "select",
    featured_ads_cost: "£ 1.98",
  });

  let [marker, setMarker] = useState(null)
  const [circle, setCircle] = useState(null)
  const [locationData, setLocationData] = useState({})

  const placeInputRef = useRef(null);
  const [place, setPlace] = useState({
    center: { lat: 19.8762, lng: 75.3433 },
  });

  const params = useParams();
  let id = params.id;
  const { sidebar, setSidebar, customerData } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [eventData, setEventData] = useState({})
  const [isEnabled, setIsEnabled] = useState(false);
  const [openFeatureAddModel, setOpenFeatureAddModel] = useState(false);
  const [openPaymentAddModel, setOpenPaymentAddModel] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {

  }, [place]);




  // useEffect(() => {

  //   setPlace({
  //     center: { lat: UpdatePropertyMange.lat, lng: UpdatePropertyMange.long },
  //   });
  // }, [UpdatePropertyMange]);

  useEffect( () => {
    initPlaceAPI();
  }, []);
  const initPlaceAPI = async () => {
    if (
      window.google &&
      window.google.maps &&
      window.google.maps.places &&
      window.google.maps.places.Autocomplete
    ) {
      let autocomplete = new window.google.maps.places.Autocomplete(
        placeInputRef.current
      );
      new window.google.maps.event.addListener(
        autocomplete,
        "place_changed",
        function () {
          let place = autocomplete.getPlace();

          setEventData({
            ...eventData,
            address: place.name,
            center: {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            },
          });

          setPlace({
            center: {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            },
            address: place.formatted_address,
          });
        }
      );
    }
  };

  const removeImage = (index) => {
    let property_image = UpdatePropertyMange.property_image;

    property_image = property_image.filter(
      (property_image) => property_image.indexOf(property_image) != index
    );

    setUpdateProperty({
      ...UpdatePropertyMange,
      property_image: property_image,
    });
  };

  const [showElement, setShowElement] = React.useState(true);

  useEffect(() => {
    setTimeout(function () {
      setShowElement(false);
    }, 5000);
  }, []);

  useEffect(() => {
    geCreateDetails();
  }, []);
  const geCreateDetails = () => {

    setLoading(true);
    simpleGetCallWithErrorResponse(
      ApiConfig.UNIVERSR_HOUSING_MANGE_GET + id,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        setLoading(false);
        let user_profile = res.json.data[0];

        console.log({res,user_profile});

        setUpdateProperty({
          title: user_profile.title,
          type: user_profile.type,
          property_type: user_profile.property_type,
          bills_included: user_profile.bills_included,
          property_description: user_profile.property_description,
          gender: user_profile.gender,
          term: user_profile.term,
          smokers_allowed: user_profile.smokers_allowed,
          pets_allowed: user_profile.pets_allowed,
          numbers_of_rooms: user_profile.numbers_of_rooms,
          availability: user_profile.availability,
          address: user_profile.address,
          monthly_rent: user_profile.monthly_rent,
          deposit: user_profile.deposit,
          property_image: user_profile.property_image,
          lat: user_profile.lat,
          long: user_profile.long,
          featured_ads: user_profile.featured_ads,
          featured_ads_duration: user_profile.featured_ads_duration,
          featured_ads_cost: user_profile.featured_ads_cost,
        });

        setFeatureAdd(prevState => ({
          featured_ads: user_profile.featured_ads,
          featured_ads_duration: user_profile.featured_ads ? user_profile.featured_ads_duration: prevState.featured_ads_duration,
          featured_ads_cost: user_profile.featured_ads ? user_profile.featured_ads_cost: prevState.featured_ads_cost
        }))

        setIsEnabled(user_profile.featured_ads);


        setPlace({
          address: user_profile.address,
          center: { lat: Number(user_profile.lat), lng: Number(user_profile.long) },
        });

      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const UpdatepropertyAll = (e) => {
    setLoading(true);
    let datails = JSON.stringify({
      id: customerData.id,
      pets_allowed: UpdatePropertyMange.pets_allowed,
      bills_included: UpdatePropertyMange.bills_included,
      type: UpdatePropertyMange.type,
      property_description: UpdatePropertyMange.property_description,
      property_type: UpdatePropertyMange.property_type,
      gender: UpdatePropertyMange.gender,
      term: UpdatePropertyMange.term,
      smokers_allowed: UpdatePropertyMange.smokers_allowed,
      title: UpdatePropertyMange.title,
      numbers_of_rooms: UpdatePropertyMange.numbers_of_rooms,
      availability: UpdatePropertyMange.availability,
      monthly_rent: UpdatePropertyMange.monthly_rent,
      deposit: UpdatePropertyMange.deposit,
      address: place.address,
      lat: place.center.lat,
      long: place.center.lng,
      featured_ads: featureAdd.featured_ads,
      featured_ads_duration: !featureAdd.featured_ads ? null : featureAdd.featured_ads_duration,
      featured_ads_cost: !featureAdd.featured_ads ? null : featureAdd.featured_ads_cost,
    });
    console.log({datails: JSON.parse(datails)})
    const formData = new FormData();
    formData.append("details", datails);
    // UpdatePropertyMange.property_image &&
    //   UpdatePropertyMange.property_image.length &&
    //   UpdatePropertyMange.property_image.map((data) => {
    //     formData.append("property_image", data);
    //   });

   /* Join tables and fix here
   UpdatePropertyMange.property_image &&
      UpdatePropertyMange.property_image.length &&
      formData.append("property_image", UpdatePropertyMange.property_image[0]);
*/

    if (!(UpdatePropertyMange.title === "")) {
      setErrMsg({ title: "" });
      if (!(UpdatePropertyMange.property_description === "")) {
        setErrMsg({ property_description: "" });
        // if (!(UpdatePropertyMange.address === "")) {
        //   setErrMsg({ address: "" });
        if (!(UpdatePropertyMange?.property_image?.length === 0)) {
          setErrMsg({ property_image: "" });
          if (!(UpdatePropertyMange.property_type === "select")) {
            setErrMsg({ property_type: "" });
            if (!(UpdatePropertyMange.bills_included === "select")) {
              setErrMsg({ bills_included: "" });
              if (!(UpdatePropertyMange.gender === "select")) {
                setErrMsg({ gender: "" });
                if (!(UpdatePropertyMange.term === "select")) {
                  setErrMsg({
                    term: "",
                  });
                  if (!(UpdatePropertyMange.smokers_allowed === "select")) {
                    setErrMsg({ smokers_allowed: "" });
                    if (!(UpdatePropertyMange.pets_allowed === "select")) {
                      setErrMsg({ pets_allowed: "" });
                      if (!(UpdatePropertyMange.numbers_of_rooms == "")) {
                        setErrMsg({
                          numbers_of_rooms: "",
                        });
                        if (
                          !(UpdatePropertyMange.availability === "select")
                        ) {
                          setErrMsg({
                            availability: "",
                          });
                          if (!(UpdatePropertyMange.type === "select")) {
                            setErrMsg({
                              type: "",
                            });
                            if (!(UpdatePropertyMange.deposit === "select")) {
                              setErrMsg({
                                deposit: "",
                              });
                              if (
                                !(UpdatePropertyMange.monthly_rent === "")
                              ) {
                                setErrMsg({ monthly_rent: "" });
                                // ============> api call start <==============
                                putMultipartWithAuthCallWithErrorResponse(
                                  ApiConfig.UNIVERSR_HOUSING_MANGE_UPDATE +
                                  id,
                                  formData
                                )
                                  .then((res) => {
                                    setLoading(false);
                                    swal(" Update Property Successfully");

                                    navigate("/house_listing/all");
                                  })
                                  .catch((err) => console.log(err));
                                // ============> api call ends <================
                              } else {
                                setErrMsg({
                                  monthly_rent: "please Enter monthly rent",
                                });
                              }
                            } else {
                              setErrMsg({
                                deposit: "please enter deposit",
                              });
                            }
                          } else {
                            setErrMsg({
                              type: "please select type",
                            });
                          }
                        } else {
                          setErrMsg({
                            availability: "please select availability",
                          });
                        }
                      } else {
                        setErrMsg({
                          numbers_of_rooms: "Please enter numbers of rooms",
                        });
                      }
                    } else {
                      setErrMsg({
                        pets_allowed: "please select pets allowed",
                      });
                    }
                  } else {
                    setErrMsg({
                      smokers_allowed: "Please select smokers allowed",
                    });
                  }
                } else {
                  setErrMsg({
                    term: "Please select terms",
                  });
                }
              } else {
                setErrMsg({ gender: "Please select gender" });
              }
            } else {
              setErrMsg({
                bills_included: "Please select bills included",
              });
            }
          } else {
            setErrMsg({
              property_type: "Please select property type",
            });
          }
        } else {
          setErrMsg({
            property_image: "Please upload image",
          });
        }
        // } else {
        //   setErrMsg({
        //     address: "please Enter address",
        //   });
        // }
      } else {
        setErrMsg({
          property_description: "please Enter property description",
        });
      }
    } else {
      setErrMsg({ title: "please Enter title" });
    }
  };


  useEffect(() => {

    if (locationData.maps && locationData.map) {
      const { map, maps } = locationData;
      // or else call that isApiLoaded function and pass-on these arguments
      renderMarkers(map, maps);
    }
  }, [locationData, place]);
  const getLocation = (lat, lng) => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBxqcBX2r8cybljkDigXBgyOByjeQOmqr0`,
      {
        method: "GET", // or 'PUT'
        headers: {
          // "Content-Type": "application/json",
          // 'Access-Control-Allow-Credentials': "*"
        },
        //body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((data) => {

        setPlace({
          ...place,
          center: { lat, lng },
          address: data.results[1].formatted_address,
        });

      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const renderMarkers = (map, maps) => {

    marker && marker.setMap(null);
    circle && circle.setMap(null);
    const cityCircle = new maps.Circle({
      strokeColor: "#00b4bd",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#00b4bd",
      fillOpacity: 0.35,
      map,
      center: { ...place.center },
      // radius: { ...place.center.radius },
      radius: place.radius ? Number(place.radius) : 1000,
    });
    marker = new maps.Marker({
      draggable: true,
      position: { ...place.center },
      map,
    });
    maps.event.addListener(marker, "dragend", async function (event) {
      getLocation(event.latLng.lat(), event.latLng.lng());
    });
    setMarker(marker);
    setCircle(cityCircle);

    return { marker, cityCircle };
  };

  const toggleSwitch = () => {
    setOpenFeatureAddModel(true);
    setIsEnabled(!isEnabled);
    setFeatureAdd(prevState => ({...prevState, featured_ads: !prevState.featured_ads}))
  };

  const handleClose = () => {
    setIsEnabled(false);
    setOpenFeatureAddModel(false);
    setOpenPaymentAddModel(false);
    setFeatureAdd(prevState => ({...prevState, featured_ads: false}))
  };

  const onPaymentSuccess = () => {
    setUpdateProperty(prevState => ({...prevState, ...featureAdd}))
    setOpenFeatureAddModel(false)
    swal("Payment Successfully");
  }

  console.log("render-update-housing", {featureAdd, UpdatePropertyMange})

  return (
    <div style={{ overflowY: "hidden" }}>
      <Sidebar />

      {/* <Header /> */}
      <main
        id="cxMain"
        className={sidebar ? "cx-main cx-active" : "cx-main"}
        style={{
          height: "calc(100vh - 156px)",
        }}
      >
        <section
          className="content-main"
          style={{
            backgroundColor: "white",
            borderRadius: "20px",
            height: "calc(100vh - 156px)",
          }}
        >
          <div
            className="row"
            style={{
              height: "calc(100vh - 156px)",
            }}
          >
            <div className="col-md-12 scroll-hidden">
              <div className="create_screenWrapper">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Create your Property
                  </h5>
                </div>
                <div className="profile_modal_contentWrapper">
                  <div className="row">
                    {/* left side */}
                    <div
                      className="col-md-6 profile_form"
                      style={{ marginTop: "0px" }}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className="uploadPP_inputTextRow"
                          // style={{ width: "100%" }}type
                          >
                            <label class="form-label">Title</label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Property Title "
                              style={{ height: "40px" }}
                              value={UpdatePropertyMange.title}
                              onChange={(e) => {
                                setUpdateProperty({
                                  ...UpdatePropertyMange,
                                  title: e.target.value,
                                });
                              }}
                            />
                            <small
                              style={{
                                color: "red",
                              }}
                            >
                              {errorMsg.title}
                            </small>
                            {/* {errMsg.title.length > 0 && (
                              <div className="text-center text-danger">
                                <span> {errMsg.title}</span>
                              </div>
                            )} */}
                          </div>
                        </div>

                        <div
                          style={{
                            margin: "-21px -5px -4px 7px",
                            paddingLeft: "0px",
                          }}
                          className="row"
                        >
                          <div
                            style={{
                              paddingLeft: "6px",
                            }}
                            className="col-md-12"
                          >
                            <div class="form-group ">
                              <label
                                class="form-label form-label-box-1"
                                style={{ marginTop: "20px" }}
                              >
                                Property Description
                              </label>
                              <textarea
                                value={UpdatePropertyMange.property_description}
                                onChange={(e) => {
                                  // setErrMsg({ ...errMsg, title: "" });

                                  setUpdateProperty({
                                    ...UpdatePropertyMange,
                                    property_description: e.target.value,
                                  });
                                }}
                                style={{
                                  border: "1px solid #E1E1E1",
                                  borderRadius: "10px",
                                  backgroundColor: "#FBF8F8 ",
                                }}
                                class="form-control "
                                placeholder="Describe your property"
                                id="exampleFormControlTextarea1"
                                rows="3"
                              ></textarea>
                              <small
                                style={{
                                  color: "red",
                                }}
                              >
                                {errorMsg.property_description}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div
                            // style={{ width: "100%" }}
                            className="uploadPP_inputTextRow"
                          >
                            <label
                              class="form-label"
                              style={{ marginTop: "20px" }}
                            >
                              Address
                            </label>

                            <textarea
                              value={place.address}
                              ref={placeInputRef}
                              onChange={(e) => {

                                setPlace({
                                  address: e.target.value,
                                })
                                setUpdateProperty({
                                  ...UpdatePropertyMange,
                                  address: e.target.value,
                                });
                              }}
                              className="textareaAdj01-1"
                              placeholder="Enter location here"
                              style={{ marginRight: "20px", height: "50px" }}
                            ></textarea>
                            <small
                              style={{
                                color: "red",
                              }}
                            >
                              {errorMsg.address}
                            </small>
                          </div>
                        </div>

                        <div
                          className="col-md-6"
                          style={{
                            height: "129px",
                            marginTop: "50px",
                            borderRadius: "20px",
                          }}
                        >
                          <div
                            className="mapWrapper"
                            style={{
                              height: "129px",
                              border: "1px solid #e1e1e1",
                              borderRadius: "10px",
                              overflow: "hidden",
                            }}
                          >

                            <GoogleMapReact
                              bootstrapURLKeys={{
                                key: 'AIzaSyBxqcBX2r8cybljkDigXBgyOByjeQOmqr0',
                              }}
                              center={place.center}
                              defaultZoom={11}
                              onGoogleApiLoaded={({ map, maps }) => {
                                // renderMarkers({ map, maps });
                                setLocationData({ map, maps })
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row mb-4 d-flex align-items-center">
                        <div className="col-12 col-sm-6 col-lg-4 custom-resp-1">
                          <div
                            style={{
                              height: "148px",
                              width: "148px",
                              margin: "0",
                              padding: "0",
                            }}
                          >
                            <ImageUplode
                              setFile={(files) => {

                                setUpdateProperty({
                                  ...UpdatePropertyMange,
                                  property_image: [...files],
                                });
                              }}
                            />
                            <small
                              style={{
                                color: "red",
                              }}
                            >
                              {errorMsg.property_image}
                            </small>
                          </div>
                        </div>

                        {/* {UpdatePropertyMange.property_image.map(
                          (img, index) => {
                            return (
                              <div
                                className="col-12 col-sm-6 col-lg-4   mt-sm-0"
                                style={{
                                  marginTop: "40px",
                                }}
                              >
                                <div
                                  style={{
                                    width: "148px",
                                    // marginTop: "40px",
                                  }}
                                  className="uploaded-image-1 uploaded-reps-1"
                                >
                                   {UpdatePropertyMange.property_image.map(
                              (img, index) => {
                                console.log(img);
                                return (
                                  <div
                                    className="item_list"
                                  // key={"vehicleImages" + index}
                                  >
                                    <img
                                      width={72}
                                      height="72"
                                      className="vehicle_img"
                                      src={
                                        img.name
                                          ? URL.createObjectURL(img)
                                          : img
                                      }
                                      alt=""
                                    />
                                    <a
                                      style={{
                                        position: "absolute",
                                        top: "10px",
                                        right: "0px",
                                      }}
                                      href="#"
                                      onClick={() => removeImage(index)}
                                      className=""
                                    >
                                      <i class="fa-solid fa-xmark"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )} */}
                      </div>
                    </div>
                    <div className="col-lg-6 main-property-type">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="uploadPP_inputTextRow usalabel">
                            <label class="form-label">Property Type</label>
                            <div className="upp_selectWrapper ">
                              <select
                                value={UpdatePropertyMange.property_type}
                                onChange={(e) => {
                                  // setErrMsg({ ...errMsg, title: "" });

                                  setUpdateProperty({
                                    ...UpdatePropertyMange,
                                    property_type: e.target.value,
                                  });
                                }}
                                className="Property-Type-midal form-select  ce-input"
                                aria-label="Default select example"
                                style={{
                                  height: "40px",
                                  backgroundColor: "#FBF8F8",
                                  margin: "0",
                                }}
                              >
                                <option value="select">
                                  Select Property Type
                                </option>
                                <option value="flat">Flat</option>
                                <option value="house">House</option>
                                <option value="studio">Studio</option>
                              </select>
                              <small
                                style={{
                                  color: "red",
                                }}
                              >
                                {errorMsg.property_type}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="uploadPP_inputTextRow usalabel upp_selectWrapper-type-1">
                            <label class="form-label">Bill Included</label>
                            <div className="upp_selectWrapper">
                              <select
                                value={UpdatePropertyMange.bills_included}
                                onChange={(e) => {
                                  setUpdateProperty({
                                    ...UpdatePropertyMange,
                                    bills_included: e.target.value,
                                  });
                                }}
                                style={{
                                  height: "40px",
                                  margin: "0",
                                  backgroundColor: "#FBF8F8",
                                }}
                                class=" Property-Type-midal  form-select ce-input "
                                aria-label="Default select example"
                              >
                                <option value="select">
                                  Select Bill Included
                                </option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </select>
                              <small
                                style={{
                                  color: "red",
                                }}
                              >
                                {errorMsg.bills_included}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="uploadPP_inputTextRow usalabel">
                            <label
                              class="form-label"
                              style={{ marginTop: "20px" }}
                            >
                              Gender
                            </label>
                            <div className="upp_selectWrapper">
                              <select
                                value={UpdatePropertyMange.gender}
                                onChange={(e) => {
                                  setUpdateProperty({
                                    ...UpdatePropertyMange,
                                    gender: e.target.value,
                                  });
                                }}
                                style={{
                                  height: "40px",
                                  margin: "0",
                                  backgroundColor: "#FBF8F8",
                                }}
                                class=" Property-Type-midal form-select ce-input "
                                aria-label="Default select example"
                              >
                                <option value="select">Select Gender</option>
                                <option value="male only">Male only</option>
                                <option value="Female only">Female only</option>
                                <option value="LGBTQ+">LGBTQ+</option>
                              </select>
                              <small
                                style={{
                                  color: "red",
                                }}
                              >
                                {errorMsg.gender}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="uploadPP_inputTextRow usalabel">
                            <label
                              class="form-label"
                              style={{ marginTop: "20px" }}
                            >
                              {" "}
                              Terms
                            </label>
                            <div className="upp_selectWrapper">
                              <select
                                value={UpdatePropertyMange.term}
                                onChange={(e) => {
                                  setUpdateProperty({
                                    ...UpdatePropertyMange,
                                    term: e.target.value,
                                  });
                                }}
                                style={{
                                  height: "40px",
                                  margin: "0",
                                  backgroundColor: "#FBF8F8",
                                }}
                                class=" Property-Type-midal form-select ce-input "
                                aria-label="Default select example"
                              >
                                <option value="select">Select Term</option>
                                <option value="Short-term (6 months or less)">
                                  Short-term (6 months or less)
                                </option>
                                <option value="Long-term (over 6 months)">
                                  Long-term (over 6 months)
                                </option>
                              </select>
                              <small
                                style={{
                                  color: "red",
                                }}
                              >
                                {errorMsg.term}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="uploadPP_inputTextRow usalabel">
                            <label
                              class="form-label"
                              style={{ marginTop: "20px" }}
                            >
                              Smokers Allowed
                            </label>
                            <div className="upp_selectWrapper">
                              <select
                                value={UpdatePropertyMange.smokers_allowed}
                                onChange={(e) => {
                                  setUpdateProperty({
                                    ...UpdatePropertyMange,
                                    smokers_allowed: e.target.value,
                                  });
                                }}
                                style={{
                                  height: "40px",
                                  margin: "0",
                                  backgroundColor: "#FBF8F8",
                                }}
                                class=" Property-Type-midal form-select ce-input "
                                aria-label="Default select example"
                              >
                                <option value="select">
                                  Select Smokers Allowed
                                </option>
                                <option value="No">No</option>
                                <option value="Yes">Yes</option>
                              </select>
                              <small
                                style={{
                                  color: "red",
                                }}
                              >
                                {errorMsg.smokers_allowed}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="uploadPP_inputTextRow usalabel">
                            <label
                              class="form-label"
                              style={{ marginTop: "20px" }}
                            >
                              Pets Allowed
                            </label>
                            <div className="upp_selectWrapper">
                              <select
                                value={UpdatePropertyMange.pets_allowed}
                                onChange={(e) => {
                                  setUpdateProperty({
                                    ...UpdatePropertyMange,
                                    pets_allowed: e.target.value,
                                  });
                                }}
                                style={{
                                  height: "40px",
                                  margin: "0",
                                  backgroundColor: "#FBF8F8",
                                }}
                                class="Property-Type-midal form-select ce-input "
                                aria-label="Default select example"
                              >
                                <option value="select">
                                  Select Pets Allowed
                                </option>
                                <option value="No">No</option>
                                <option value="Yes">Yes</option>
                              </select>
                              <small
                                style={{
                                  color: "red",
                                }}
                              >
                                {errorMsg.pets_allowed}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="uploadPP_inputTextRow usalabel">
                            <div className="upp_selectWrapper">
                              <div className="">
                                <div
                                  className="uploadPP_inputTextRow"
                                  style={{ width: "100%" }}
                                >
                                  <label
                                    class=" Property-Type-midal form-label"
                                    style={{ marginTop: "20px" }}
                                  >
                                    Number of Rooms
                                  </label>
                                  <input
                                    value={UpdatePropertyMange.numbers_of_rooms}
                                    onChange={(e) => {
                                      setUpdateProperty({
                                        ...UpdatePropertyMange,
                                        numbers_of_rooms: e.target.value,
                                      });
                                    }}
                                    style={{
                                      height: "40px",
                                      backgroundColor: "#FBF8F8 !important",
                                    }}
                                    type="text"
                                    class=" Property-Type-midal form-control fcs text-start"
                                    placeholder="Enter Number Of Rooms"
                                  />
                                  <small
                                    style={{
                                      color: "red",
                                    }}
                                  >
                                    {errorMsg.numbers_of_rooms}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="uploadPP_inputTextRow usalabel">
                            <label
                              class="form-label"
                              style={{ marginTop: "20px" }}
                            >
                              Availability
                            </label>
                            <div className="upp_selectWrapper">
                              <select
                                value={UpdatePropertyMange.availability}
                                onChange={(e) => {
                                  setUpdateProperty({
                                    ...UpdatePropertyMange,
                                    availability: e.target.value,
                                  });
                                }}
                                style={{
                                  height: "40px",
                                  margin: "0",
                                  backgroundColor: "#FBF8F8",
                                }}
                                class="Property-Type-midal form-select ce-input "
                                aria-label="Default select example"
                              >
                                <option value="select">
                                  Select Availability
                                </option>

                                <option value="immediately">Immediately</option>
                                <option value="Within 1 week">
                                  Within 1 week
                                </option>
                                <option value="Within 1 month">
                                  Within 1 month
                                </option>
                                <option value="Within 3 months">
                                  Within 3 months
                                </option>
                              </select>
                              <small
                                style={{
                                  color: "red",
                                }}
                              >
                                {errorMsg.availability}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="uploadPP_inputTextRow usalabel">
                            <label
                              class="form-label"
                              style={{ marginTop: "20px" }}
                            >
                              Type
                            </label>
                            <div className="upp_selectWrapper">
                              <select
                                value={UpdatePropertyMange.type}
                                onChange={(e) => {
                                  setUpdateProperty({
                                    ...UpdatePropertyMange,
                                    type: e.target.value,
                                  });
                                }}
                                style={{
                                  height: "40px",
                                  margin: "0",
                                  backgroundColor: "#FBF8F8",
                                }}
                                class="Property-Type-midal-1 form-select ce-input "
                                aria-label="Default select example"
                              >
                                <option value="select">Select Type</option>
                                <option value="individual">Individual</option>
                                <option value="room share">Room share</option>
                              </select>
                              <small
                                style={{
                                  color: "red",
                                }}
                              >
                                {errorMsg.type}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="uploadPP_inputTextRow usalabel">
                            <div className="upp_selectWrapper">
                              <div className="">
                                <div
                                  className="uploadPP_inputTextRow"
                                  style={{ width: "100%" }}
                                >
                                  <label
                                    class="form-label"
                                    style={{ marginTop: "20px" }}
                                  >
                                    Deposit
                                  </label>
                                  <input
                                    value={UpdatePropertyMange.deposit}
                                    onChange={(e) => {
                                      // setErrMsg({ ...errMsg, title: "" });

                                      setUpdateProperty({
                                        ...UpdatePropertyMange,
                                        deposit: e.target.value,
                                      });
                                    }}
                                    style={{
                                      height: "40px",
                                      backgroundColor: "black",
                                      backround: "black !important",
                                    }}
                                    type="text"
                                    class="form-control text-start"
                                    placeholder=" £ Price"
                                  />
                                  <small
                                    style={{
                                      color: "red",
                                    }}
                                  >
                                    {errorMsg.deposit}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="uploadPP_inputTextRow usalabel">
                            <div className="upp_selectWrapper">
                              <div className="">
                                <div
                                  className="uploadPP_inputTextRow"
                                  style={{ width: "100%" }}
                                >
                                  <label
                                    class="form-label"
                                    style={{ marginTop: "20px" }}
                                  >
                                    Monthly Rent
                                  </label>
                                  <input
                                    value={UpdatePropertyMange.monthly_rent}
                                    onChange={(e) => {
                                      // setErrMsg({ ...errMsg, title: "" });

                                      setUpdateProperty({
                                        ...UpdatePropertyMange,
                                        monthly_rent: e.target.value,
                                      });
                                    }}
                                    style={{ height: "40px" }}
                                    type="text"
                                    class="form-control text-start"
                                    placeholder=" £ Price"
                                  />
                                  <small
                                    style={{
                                      color: "red",
                                    }}
                                  >
                                    {errorMsg.monthly_rent}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                          style={{
                            marginTop: "10px",
                          }}
                      >
                        <h5 class="modal-title">Feature-Add</h5>

                        <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              gap: "5px",
                              paddingLeft: "2px",
                              paddingRight: "2px",
                            }}
                        >
                          <div
                              style={{
                                border: "1px solid #ccc",
                                borderRadius: "8px",
                                marginTop: "5px",
                                width: "100%",
                                // marginLeft: '4px',
                                backgroundColor: "black",
                                padding: "10px",
                              }}
                          >
                            <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  paddingLeft: "4px",
                                  marginTop: "4px",
                                  marginBottom: "3px",
                                  alignItems: "center",
                                }}
                            >
                              <p
                                  className="reporttext"
                                  style={{ color: "white" }}
                              >
                                Make this a featured post?
                              </p>

                              <div
                                  style={{
                                    position: "relative",
                                    display: "inline-block",
                                    transition: "all 200ms ease-in-out",
                                    marginRight: "15px",
                                  }}
                              >
                                <label
                                    style={{
                                      position: "relative",
                                    }}
                                >
                                  <input
                                      type="checkbox"
                                      className="sr-only"
                                      checked={isEnabled}
                                      onChange={toggleSwitch}
                                  />

                                  <div
                                      style={{
                                        width: "50px",
                                        height: "28px",
                                        borderRadius: "50px",
                                        transition: "all 300ms",
                                        backgroundColor: isEnabled
                                            ? "#00B4BD"
                                            : "gray",
                                      }}
                                  >
                                    <div
                                        style={{
                                          width: "28px",
                                          height: "28px",
                                          borderRadius: "50%",
                                          transition: "all 300ms",
                                          transform: isEnabled
                                              ? "translateX(28px)"
                                              : "",
                                          backgroundColor: "white",
                                          boxShadow:
                                              "0 4px 6px rgba(0, 0, 0, 0.1)",
                                        }}
                                    ></div>
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {openFeatureAddModel && isEnabled ? (
                          <Modal
                              className="custom-dialog"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              show={openFeatureAddModel}
                              onHide={handleClose}
                          >
                            <Modal.Header
                                style={{ padding: "12px", width: "99%" }}
                                closeButton
                            >
                              <Modal.Title>About Featured Post</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              {/* Are you sure you want to cancel this Friend Request? */}
                              <div
                                  class="modal-body"
                                  style={{ marginTop: "-37px" }}
                              >
                                <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "1.25rem",
                                      marginTop: "1.25rem",
                                    }}
                                >
                                  <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                  >
                                    <span>Duration</span>

                                    <span style={{ fontWeight: "bold" }}>
                                    Boost this post for
                                  </span>

                                    <select
                                        style={{
                                          width: "150px",
                                          color: "#707070",
                                          height: "50px",
                                          fontSize: "12px",
                                          background: "white",
                                          display: "flex",
                                          alignItems: "center",
                                          borderRadius: "10px",
                                          border: "1px solid #01B3BB",
                                          paddingTop: "14px",
                                          paddingRight: "30px",
                                          paddingBottom: "14px",
                                          paddingLeft: "20px",
                                          fontWeight: "bold",
                                        }}
                                        value={featureAdd.featured_ads_duration}
                                        onChange={(e) =>
                                            setFeatureAdd(prevState => ({...prevState,
                                              featured_ads_duration: e.target.value,}))

                                        }
                                        // onClick={handlePrice}
                                    >
                                      <option value="select">
                                        Select Duration
                                      </option>

                                      <option value="1 day">1 day</option>

                                      <option value="1 week">1 Week</option>

                                      <option value="2 week">2 Week</option>

                                      <option value="3 Week">3 Week</option>

                                      <option value="1 Month">1 Month</option>
                                    </select>
                                  </div>

                                  <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                  >
                                    <span>Price</span>

                                    <input
                                        style={{
                                          width: "150px",
                                          color: "#707070",
                                          height: "50px",
                                          fontSize: "12px",
                                          background: "white",
                                          display: "flex",
                                          alignItems: "center",
                                          borderRadius: "10px",
                                          border: "1px solid #01B3BB",
                                          paddingTop: "14px",
                                          paddingRight: "30px",
                                          paddingBottom: "14px",
                                          paddingLeft: "20px",
                                          fontWeight: "bold",
                                        }}
                                        // placeholder="Feature Add Cost"
                                        value={featureAdd.featured_ads_cost}
                                        readOnly={true}
                                        onChange={(e) =>
                                            setFeatureAdd(prevState => ({...prevState,
                                              featured_ads_cost: e.target.value,}))
                                        }
                                    />
                                  </div>

                                  <button
                                      style={{
                                        marginTop: "8px",
                                        width: "100%",
                                        height: "67.19px",
                                        flexShrink: 0,
                                        border: "10px solid transparent",
                                        borderRadius: "10px",
                                        backgroundColor: "#01B3BB",
                                        color: "#FEFCFE",
                                        fontFamily: "montserrat",
                                        fontWeight: "bold",
                                        fontSize: "18px",
                                        textTransform: "uppercase",
                                      }}
                                      onClick={() => {
                                        setOpenPaymentAddModel(true)
                                      }}
                                  >
                                    Proceed Payment
                                  </button>
                                </div>
                              </div>
                            </Modal.Body>
                          </Modal>
                      ) : (
                          <></>
                      )}

                      {openPaymentAddModel && (
                          <Modal
                              className="custom-dialog"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              show={openFeatureAddModel}
                              onHide={handleClose}
                          >
                            <Modal.Header
                                style={{ padding: "12px", width: "99%" }}
                                closeButton
                            >
                              <Modal.Title>About Payment</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              {/* Are you sure you want to cancel this Friend Request? */}
                              <div
                                  class="modal-body"
                                  style={{ marginTop: "-37px" }}
                              >
                                <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "1.25rem",
                                      marginTop: "1.25rem",
                                    }}
                                >

                                  <Payment onSuccess={onPaymentSuccess} setOpenPaymentAddModel={setOpenPaymentAddModel} />

                                </div>
                              </div>
                            </Modal.Body>
                          </Modal>
                      )}

                    </div>
                  </div>


                  {/* tright side`` */}

                  <div
                    className="create_screenControls"
                    style={{
                      marginTop: "-20px",
                      borderColor: "#00B4BD",
                      color: "#00B4BD",
                    }}
                  >
                    <Link to="/house_listing/all" className="dis">
                      Cancel
                    </Link>
                    <Link
                      onClick={UpdatepropertyAll}
                      className="primary"
                      // to="#"
                    >
                      Update Property
                    </Link>
                     {/*<button
                      className="primary"
                      onClick={UpdatepropertyAll}
                      style={{ padding: "13PX", width: "140px" }}
                    >
                      Update Property
                    </button>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default UpdateProperty;

import React, { useContext, useState, useEffect } from "react";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/AppContext";
import swal from "sweetalert";
import LoaderCenter from "../../sharedComponent/LoaderCenter";
import './AllProperty.css';
import { useParams,useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faBuilding,
  faUsers,
  faBed,
  faBath,
  faMoneyBill,
  faDollarSign,
  faUser,
  faClock ,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faMapMarkerAlt, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
const AdManage = () => {
  const { customerData } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [houseDetails, setHouseDetails] = useState(null);

  useEffect(() => {
    getHouseDetails();
  }, []);

  const { id } = useParams();

  const getHouseDetails = async () => {
    setLoading(true);

    console.log("check id", id);

    await fetch(`${ApiConfig.BASE_NEW_URL}posts/view/${id}`)


      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setLoading(false);
        console.log("housing_properties", data);
        
        setHouseDetails(data);
        
      })
      .catch(error => {
        setLoading(false);
        console.error('There was a problem fetching the data:', error);
      });

  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      // hour: 'numeric',
      // minute: 'numeric',
      // second: 'numeric',
      // hour12: true,
    };
    return date.toLocaleString('en-US', options);
  };

  const handleDelete = () => {
    // Handle delete functionality here
    swal("Delete functionality is not implemented yet.");
  };

  const handleInterested = () => {
    // Handle interested functionality here
    swal("Interested functionality is not implemented yet.");
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDeleteClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const navigate = useNavigate();

  const handleDeleteConfirm = async () => {
    try {
      const response = await fetch(`${ApiConfig.BASE_NEW_URL}posts/delete/${id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
           swal({
        title: "Deletion unsuccesfull",
        icon: "error",
      });
      }

      await response.json();
      setIsModalOpen(false); 
      swal({ title:"Deleted successfully",icon: "success",}); 
      navigate("/events/all"); 

    } catch (error) {
      console.error('Failed to delete the post', error);
      swal({
        title: "Error Occurred",
        icon: "error",
      });
    } 
  };
  const newYorkLat = 40.7128;
  const newYorkLong = -74.0060;
  const mapSrc = `https://maps.google.com/maps?q=${newYorkLat},${newYorkLong}&hl=es;z=14&output=embed`;


  // const mapSrc = `https://maps.google.com/maps?q=${houseDetails.housingDetails.lat},${houseDetails.housingDetails.long}&hl=es;z=14&output=embed`;


  const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) => 
            prevIndex === 0 ? houseDetails.images.length - 1 : prevIndex - 1
        );
    };

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) => 
            prevIndex === houseDetails.images.length - 1 ? 0 : prevIndex + 1
        );
    };

    const handleThumbnailClick = (index) => {
        setCurrentIndex(index);
    };


  return (
    <div>
    {/* <div style={{ display: 'flex', flexDirection: 'row', margin: '10px', borderRadius: '15px', overflow: 'hidden', position: 'relative' }}> */}
    <div>
      {loading ? (
        <LoaderCenter />
      ) : houseDetails ? (
        <div
          style={{
            display: "flex",
            position: "relative",
            width: "100%",
            padding: "20px",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "1px",
              right: "20px",
              display: "flex",
              gap: "10px",
            }}
          >
            <Link
              style={{ padding: "5px" }}
              to={`/adCampaing/edit/${houseDetails._id}`}
            >
              Edit
            </Link>
   <button className="btns toolbar" onClick={handleDeleteClick} >
   <FontAwesomeIcon icon={faTrashAlt} style={{ color: "white",marginTop: "2px",width:"25px" }} />
            </button>
          </div>

          <div
            style={{
              marginTop: "10px",
              flex: 2,
              marginRight: "20px",
              borderRadius: "10px",
              padding: "20px",
              backgroundColor: "white",
            }}
          >
            <h3>{houseDetails.title}</h3>

{houseDetails.images.length > 0 && (
 <div className="slider-container" style={{ marginTop: '30px' }}>
 <div className="main-image">
   <button className="nav prev" onClick={handlePrevClick} >
     &#10094;
   </button>
   <img
 src={houseDetails.images[currentIndex]}
 alt="current slide"
 style={{width:"100%", borderRadius:"10px" }}
/>
   <button className="nav next" onClick={handleNextClick}>
     &#10095;
   </button>
 </div>
 <div className="thumbnail-container" style={{ display: 'flex', justifyContent: 'center'}}>
   {houseDetails.images.slice(0, 3).map((image, index) => (
     <img
       key={index}
       src={image}
       alt={`thumbnail ${index}`}
       className={`thumbnail ${index === currentIndex ? 'active' : ''}`}
       style={{
         width: '70px',
         height: '60px',
         objectFit: 'cover',
         borderRadius: '5px',
         margin: '0 2px',
         cursor: 'pointer',
         border: index === currentIndex ? '2px solid blue' : '2px solid transparent',
       }}
       onClick={() => handleThumbnailClick(index)}
     />
   ))}
 </div>
</div>
)}
           
            <h5 style={{ marginTop: "25px" }}>Description</h5>
            <p style={{ marginTop: "15px",minHeight:"70px" }}>{houseDetails.description}</p>
            <h5 style={{ marginTop: "25px" }}>Contact Info</h5>
            <p style={{ marginTop: "10px" }}>{houseDetails.contactInfo}</p>
            {/* <h5 style={{ marginTop: "20px" }}>Terms</h5>
            <p style={{ marginTop: "10px" }}>
              {houseDetails.housingDetails.term}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "30px",
                marginBottom: "30px",
              }}
            >
              <div style={{ textAlign: "center", marginRight: "10px" }}>
                <h5>Bills Included</h5>
                {houseDetails.housingDetails.billsIncluded ? (
                  <div
                    style={{
                      border: "1px solid",
                      padding: "5px 10px",
                      marginTop: "5px",
                      borderRadius: "5px",
                      color: "green",
                    }}
                  >
                    Included
                  </div>
                ) : (
                  <div
                    style={{
                      border: "1px solid",
                      padding: "5px 10px",
                      marginTop: "5px",
                      borderRadius: "5px",
                      color: "red",
                    }}
                  >
                    Not Included
                  </div>
                )}
              </div>
              <div style={{ textAlign: "center", marginRight: "10px" }}>
                <h5>Smoke Allowed</h5>
                {houseDetails.housingDetails.smokedAllowed ? (
                  <div
                    style={{
                      border: "1px solid",
                      marginTop: "5px",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      color: "green",
                    }}
                  >
                    Allowed
                  </div>
                ) : (
                  <div
                    style={{
                      border: "1px solid",
                      padding: "5px 10px",
                      marginTop: "5px",
                      borderRadius: "5px",
                      color: "red",
                    }}
                  >
                    Not Allowed
                  </div>
                )}
              </div>
              <div style={{ textAlign: "center", marginRight: "0" }}>
                <h5>Pets Allowed</h5>
                {houseDetails.housingDetails.petsAllowed ? (
                  <div
                    style={{
                      border: "1px solid",
                      padding: "5px 10px",
                      marginTop: "5px",
                      borderRadius: "5px",
                      color: "green",
                    }}
                  >
                    Allowed
                  </div>
                ) : (
                  <div
                    style={{
                      border: "1px solid",
                      padding: "5px 10px",
                      marginTop: "5px",
                      borderRadius: "5px",
                      color: "red",
                    }}
                  >
                    Not Allowed
                  </div>
                )}
              </div>
            </div> */}
          </div>
          <div
            style={{
              flex: 1,
              marginTop: "30px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <div
              style={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                borderRadius: "10px",
                padding: "20px",
                backgroundColor: "white",
              }}
            >
              <div
                // className="mapWrapper"
                style={{
                  height: "50vh",
                  border: "1px solid #e1e1e1",
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
              >          
                            <iframe
    className="actAsDiv"
    frameBorder="0"
    scrolling="no"
    marginHeight="0"
    marginWidth="0"
    src={mapSrc}
    style={{ border: 0 , width: '100%', height: '100%' }}
    allowFullScreen=""
    aria-hidden="false"
    tabIndex="0"
  ></iframe>
              </div>
            </div>
            <div
              style={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                borderRadius: "10px",
                padding: "20px",
                backgroundColor: "white",
              }}
            >
              <div style={{ flex: 1, marginTop: "10px", marginLeft: "10px" }}>
                <div style={{ display: "flex" }}>
                  <FontAwesomeIcon icon={faCalendarAlt} style={{ color: "black",marginTop: "2px"  }} />
                  <div style={{marginLeft:"10px"}}>
                    <p>Date Posted</p>
                    <h6 style={{ marginTop: "10px" }}>
                      {formatDate(houseDetails.postedDate)}
                    </h6>
                  </div>
                </div>

                <div style={{ display: "flex",marginTop: "25px"  }}>
                  <FontAwesomeIcon icon={faClock} style={{ color: "black",marginTop: "2px" }} />
                  <div style={{marginLeft:"10px"}}>
                  <p>Company Name</p>
                <h6 style={{ marginTop: "10px" }}>
                {houseDetails.adsDetails.companyName}
                </h6>
                  </div>
                </div>

                
                <div style={{ display: "flex",marginTop: "25px" }}>
                  <FontAwesomeIcon icon={faDollarSign} style={{ color: "black",marginTop: "2px" }} />
                  <div style={{marginLeft:"10px"}}>        
                  <p >Occurrence</p>
                <h6 style={{ marginTop: "10px" }}>
               {houseDetails.adsDetails.Occurrence}
                </h6>
                  </div>
                </div>

                <div style={{ display: "flex",marginTop: "25px" }}>
                  <FontAwesomeIcon icon={faDollarSign} style={{ color: "black",marginTop: "2px" }} />
                  <div style={{marginLeft:"10px"}}>        
                  <p >Start Date</p>
                <h6 style={{ marginTop: "10px" }}>
                {formatDate(houseDetails.adsDetails.startDate)}
                </h6>
                  </div>
                </div><div style={{ display: "flex",marginTop: "25px" }}>
                  <FontAwesomeIcon icon={faDollarSign} style={{ color: "black",marginTop: "2px" }} />
                  <div style={{marginLeft:"10px"}}>        
                  <p >End Date</p>
                <h6 style={{ marginTop: "10px" }}>
                {formatDate(houseDetails.adsDetails.endDate)}
                </h6>
                  </div>
                </div>

           
                
              </div>
            </div>
            {/* <div
              style={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                borderRadius: "10px",
                padding: "20px",
                backgroundColor: "white",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  marginTop: "10px",
                  marginLeft: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60px",
                    width: "60px",
                    backgroundColor: "#FFA51B",
                    borderRadius: "10px",
                    padding: "5px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faUser}
                    size="2x"
                    style={{ color: "black" }}
                  />
                </div>
                <div style={{ flex: 3, marginLeft: "15px" }}>
                  <h6>Longitude: {houseDetails.eventDetails.long}</h6>
                  <h6 style={{ marginTop: "15px" }}>
                    Latitude: {houseDetails.eventDetails.lat}
                  </h6>
                  <h6 style={{ marginTop: "15px" }}>
                    Contact: {houseDetails.eventDetails.contactInfo}
                  </h6>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      ) : (
        <p
          className="error-message"
          style={{
            textAlign: "center",
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "15px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          Oops! No details found for this property.
        </p>
      )}
    </div>

    {/* Modal */}
    {isModalOpen && (
      <div
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "10px",
            width: "350px",
            height: "150px",
            textAlign: "center",
          }}
        >
          <h4 style={{ marginBottom: "20px" }}>Delete this post?</h4>
          <button
            onClick={handleModalClose}
            style={{
              padding: "10px 20px",
              marginTop: "10px",
              marginRight: "10px",
              backgroundColor: "#ccc",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Cancel
          </button>
          <button 
            onClick={handleDeleteConfirm}
            style={{
              padding: "10px 20px",
              backgroundColor: "#f44336",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Delete
          </button>
        </div>
      </div>
    )}
  </div>
  );


};

export default AdManage;









import React, { useContext, useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { AppContext } from '../../context/AppContext'
import eventthumbImg01 from '../../assets/images/house01.png'
import users_icn from '../../assets/images/users_icn.svg'
import profile_picture from '../../assets/images/profile_picture.jpg'
import chatusa from '../../assets/images/chat_icon_sidebar.svg'
import down from '../../assets/images/123.svg'
import swal from 'sweetalert'
import ApiConfig from '../../api/ApiConfig'
import {
  deleteWithAuthCall,
  postWithAuthCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
  simplePostCall,
} from '../../api/ApiServices'
import LoaderCenter from '../../sharedComponent/LoaderCenter'
import userDemo from '../../assets/images/userDemo.png'

const PropertyInterested = () => {
  const { customerData } = useContext(AppContext)
  const [showElement, setShowElement] = React.useState(true)
  const currentRoute = useLocation().pathname
  const [loading, setLoading] = useState(false)
  const [HouseingList, setHouseingList] = useState([])
  const [listTotal, setListTotal] = useState([])
  const [HouseingIntersted, setHouseingIntersted] = useState([{}])

  useEffect(() => {
    setTimeout(function () {
      setShowElement(false)
    }, 5000)
  }, [])

  useEffect(() => {
    getHouselist()
  }, [])

  // const getHouseintersted = (id) => {
  //   setLoading(true)

  //   simpleGetCallWithErrorResponse(
  //     ApiConfig.UNIVERSR_USER_INTERSTED + id,
  //     JSON.stringify({ ...customerData }),
  //   ).then((res) => {
  //     setListTotal(res.json.length ? res.json.length : 0)
  //     console.log(res)
  //     setLoading(false)
  //     setHouseingIntersted(res.json)
  //   })
  // }

  const getHouselist = () => {
    setLoading(true)

    simpleGetCallWithErrorResponse(
      ApiConfig.UNIVERSR_HOUSING_MANGE_INTERESTED,
      JSON.stringify({ ...customerData }),
    ).then((res) => {
      console.log(res)
      setLoading(false)
      setHouseingList(res.json.interested_housing_properties)
    })
  }

  // const Delete = (id) => {
  //   deleteWithAuthCall(ApiConfig.UNIVERSR_HOUSING_MANGE_DELET + id)
  //     .then((res) => {
  //       console.log(res)
  //       swal(res.detail)
  //       getHouselist()
  //     })
  //     .catch((err) => {
  //       setLoading(false)

  //       console.log(err)
  //     })
  // }

  const RemoveIntersted = (id) => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.UNIVERSR_ADD_USER_INTERSTED,
      JSON.stringify({ housing_property_id: id }),
    )
      .then((res) => {
        console.log(res)
        swal(res?.json)
        getHouselist()
      })

      .catch((err) => {
        setLoading(false)

        console.log(err)
      })
  }

  return (
    <>
      {loading ? (
        <LoaderCenter />
      ) : (
        <div className="row no-scroll row-gap-20">
          {HouseingList && HouseingList.length > 0 ? (
            HouseingList.map((HouseingListMange, index) => {
              return (
                <div className="col-12 col-lg-6  col-xl-4 col-xxl-3">
                  <div className="gbl_thumbWrapper">
                    <div className="img_thumbWrapper">
                      {HouseingListMange.property_image[0] ? (
                        <img
                          src={HouseingListMange.property_image[0]}
                          alt=""
                          className="event-thumbnail"
                        />
                      ) : (
                        <img
                          src={eventthumbImg01}
                          alt=""
                          className="event-thumbnail"
                        />
                      )}
                    </div>

                    <div className="event-details">
                      <h1 className="event-title">
                        {' '}
                        {HouseingListMange.property_info.title}
                      </h1>
                      <h2 className="event-price">
                        £ {HouseingListMange.property_info.monthly_rent}
                      </h2>
                      <p
                        className="content"
                        style={{
                          color: '#707070',
                          fontSize: '14px',
                          fontWeight: 'normal',
                          marginTop: '10px',
                          marginLeft: '0',
                        }}
                      >
                        {HouseingListMange.property_info
                          .property_description ? (
                          HouseingListMange.property_info.property_description
                        ) : (
                          <>&nbsp;</>
                        )}
                      </p>
                      <div className="event-action-wrapper d-flex flex-column align-items-start align-items-sm-end flex-sm-row">
                        <h4
                          className="event-address content d-flex align-items-center mb-2"
                          style={{
                            width: '150px',
                            margin: '0',
                          }}
                        >
                          {HouseingListMange.property_info.address}
                        </h4>
                        <Link
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          to="#"
                          className="mt-2  mt-sm-0"
                          style={{ fontSize: '11px' }}
                          onClick={() => {
                            RemoveIntersted(HouseingListMange.property_info.id)
                          }}
                        >
                          Remove Interested
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          ) : (
            <p
              style={{
                fontSize: '20px',
                marginTop: '200px',
              }}
              className=" text-center justify-content-center align-items-center"
            >
              Oops! It doesn't look like there are any properties in your area.
              <br /> Why don't you try expanding your search criteria?
            </p>
          )}
        </div>
      )}

      {/* <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog  modal-dialog-centered"
          style={{
            width: "80%",
            maxWidth: "338px",
            margin: "0 auto",
          }}
        >
          <div
            class="modal-content"
            style={{
              padding: "20px",
              backgroundColor: "white",
              borderRadius: "20px",
              height: "calc(100vh - 40px)",
            }}
          >
            <div className="of-head d-flex justify-content-between align-items-center mt-3 mb-2">
              <span
                style={{
                  font: "normal normal bold 16px/22px Nunito",
                  fontSize: "16px",
                }}
              >
                Interested Users
              </span>
              <span
                style={{
                  font: "normal normal bold 16px/22px Nunito",
                  fontSize: "16px",
                }}
              >
                {listTotal}
              </span>
            </div>
            <hr />
            <div
              class="offcanvas-body"
              style={{
                marginTop: "-10px",
                paddingTop: "0px",

                padding: "0",
              }}
            >
              {HouseingIntersted && HouseingIntersted.length > 0 ? (
                HouseingIntersted.map((EventIntersteditem, index) => {
            
                  return (
                    <div className="frnd-botm">
                      <div className="fir-frnd">
                        <div>
                         
                          {EventIntersteditem.profile_pic ? (
                            <img
                              src={EventIntersteditem.profile_pic}
                              style={{
                                borderRadius: "10px",
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            <img
                              src={userDemo}
                              style={{
                                borderRadius: "10px",
                                objectFit: "cover",
                              }}
                            />
                          )}
                        </div>
                        <div>
                          <p className="fName">
                            {EventIntersteditem.full_name}
                          </p>
                        </div>
                      </div>
                      <div className="chaticon">
                       
                      </div>
                    </div>
                  );
                })
              ) : (
                <p
                  style={{
                    fontSize: "20px",
                    margin: "auto",
                    marginTop: "60px",
                  }}
                  className=" text-center justify-content-center"
                >
                  No Interested Users !!!
                </p>
              )}
            </div>
            <div
              style={{
                paddingTop: "20px",
                width: "100%",
                textAlign: "center",
              }}
            >
            
            </div>
          </div>
        </div>
      </div> */}
    </>
  )
}

export default PropertyInterested

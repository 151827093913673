import React, { useState } from "react";
import login_logo from "../assets/images/login_logo.svg";
import { Link, useNavigate } from "react-router-dom";
import email_icon from "../assets/images/email_icon.svg";
import lock_icon from "../assets/images/lock_icon.svg";
import facebook_icon from "../assets/images/facebook_icon.svg";
import gmail_icon from "../assets/images/gmail_icon.svg";
import profile_inputIcon from "../assets/images/profile_inputIcon.svg";
import phone_icon from "../assets/images/phone_icon.svg";
import university_icon from "../assets/images/university_icon.svg";
import { userUpdate } from "../api/ApiServices";

function Interests() {
  const navigate = useNavigate();
  const [profileName, setProfileName] = useState("");
  const [courses, setCourses] = useState([]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const userData = {
      profileName,
      courses,
    };

    try {
      const userDetails = localStorage.getItem("user_details");
      const userObject = JSON.parse(userDetails);
      const accessToken = localStorage.getItem("access_token");

      const response = await userUpdate(userObject.sub, accessToken, userData);
      console.log("User data updated successfully:", response);

      navigate("/verify-email"); // Navigate to the next page after successful update
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  // const handleCourseSelection = (course) => {
  //   // Toggle course selection
  //   if (courses.includes(course)) {
  //     setCourses(courses.filter((item) => item !== course));
  //   } else {
  //     setCourses([...courses, course]);
  //   }
  // };
  return (
    <div className="auth_mainWrapper">
      <div className="auth_innerWrapper">
        <div className="auth_twoPane">
          <div className="auth_banner">
            <div className="auth_logoWrapper">
              <div className="auth_logoHolder">
                <img src={login_logo} alt="login logo" />
                <h1>
                  We use tech to create,
                  <br /> connect, and grow
                </h1>
              </div>
            </div>
          </div>
          <div>
            <div className="auth_formWrapper">
              <div className="auth_innerformWrapper">
                {/* <div className="auth_wizardWrapper">
                  <ul>
                    <li>
                      <Link className="auth_completed" to="#">
                        01
                      </Link>
                    </li>

                    <li>
                      <Link to="#">
                        02
                      </Link>
                    </li>

                    <li>
                      <Link to="#">03</Link>
                    </li>

                    <li>
                      <Link to="#">04</Link>
                    </li>
                  </ul>
                </div> */}

                <div className="auth_wizardForm">
                  <form onSubmit={handleSubmit}>
                    <h1>I would like to be called by</h1>
                    <div className="auth_inner_wizardForm">
                      <div className="aWizardRow">
                        <div className="checkboxWrapper">
                          <div className="checkboxWrapper_holder">
                            <input
                              style={{
                                width: "100%",
                                backgroundColor: "white",
                                border: "1px solid #ccc",
                                borderRadius: "20px",
                                padding: "15px",
                                boxSizing: "border-box",
                              }}
                              type="text"
                              placeholder="Please enter your profile name"
                              value={profileName}
                              onChange={(e) => setProfileName(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <h1>
                      I'm studying /
                      <br /> would like to study
                    </h1>
                    <p>
                      All the available courses under your
                      <br /> selected University
                    </p>

                    <div className="auth_inner_wizardForm">
                      <div className="aWizardRow">
                        <div className="checkboxWrapper">
                          <div className="checkboxWrapper_holder">
                            <label>
                              Arts
                              <input
                                type="checkbox"
                                class="default__check"
                                checked={courses === "Arts"}
                                onChange={() => setCourses("Arts")}
                              />
                              <span class="custom__check"></span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="aWizardRow">
                        <div className="checkboxWrapper">
                          <div className="checkboxWrapper_holder">
                            <label>
                              Technology
                              <input
                                type="checkbox"
                                class="default__check"
                                checked={courses === "Technology"}
                                onChange={() => setCourses("Technology")}
                              />
                              <span class="custom__check"></span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="aWizardRow">
                        <div className="checkboxWrapper">
                          <div className="checkboxWrapper_holder">
                            <label>
                              Science
                              <input
                                type="checkbox"
                                class="default__check"
                                checked={courses === "Science"}
                                onChange={() => setCourses("Science")}
                              />
                              <span class="custom__check"></span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="auth_controlWrapper2">
                        <button type="submit">Continue</button>
                        {/* <Link to="/verify-email">Continue</Link> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Login
<Link to="/dashboard" >dashboard</Link> */}
    </div>
  );
}

export default Interests;

import React from "react";
import { Link } from "react-router-dom";

const TabButton = () => {
  return (
    <div
      style={{
        overflow: "auto",
      }}
      className="chat_sidebarWrapper"
    >
      <div className="chat_tabs_header">
        <ul>
          <li className="chat_sidebarWrapper-btn-Tab">
            <Link
              to="#"
              className="ct_active"
              style={{
                boxShadow: "0px 3px 12px #017C8266",
                width: "90%",
                margin: "16px 16px 16px 0px",
              }}
            >
              Friends
            </Link>{" "}
          </li>
          <li className="chat_sidebarWrapper-btn-Tab">
            <Link to="#" style={{ width: "90%" }}>
              Work
            </Link>{" "}
          </li>
          <li className="chat_sidebarWrapper-btn-Tab">
            <Link to="#" style={{ width: "90%" }}>
              Study
            </Link>{" "}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TabButton;

import React, {
    useContext,
    useState,
    useEffect,
    useRef,
    useCallback,
  } from "react";
  import { Link, useNavigate, useParams } from "react-router-dom";
  import { AppContext } from "../../context/AppContext";
  import Sidebar from "../../components/Sidebar";
  import { Modal } from "react-bootstrap";
  import swal from "sweetalert";
  import Switch from "react-switch";
  import { Icon } from "@iconify/react";
  // import locationIcon from "@iconify/icons-mdi/map-marker";
  import profile_picture from "../../assets/images/profile_picture.jpg";
  import createselectAvatar from "../../assets/images/createselectAvatar.png";
  import createadd_avatar from "../../assets/images/createadd_avatar.png";
  import thumbImg from "../../assets/images/house01.png";
  import upload from "../../assets/images/upload_icon.svg";
  import GoogleMapReact from "google-map-react";
  import ApiConfig from "../../api/ApiConfig";
  import {
    multipartPostCall,
    multipartPostCallWithErrorResponse,
    postMultipartWithAuthCall,
    postMultipartWithAuthCallWithErrorResponse,
    postWithAuthCallWithErrorResponse,
    simpleGetCallWithErrorResponse,
    putMultipartWithAuthCallWithErrorResponse,
  } from "../../api/ApiServices";
  import ImageUplode from "../../sharedComponent/ImageUplode";
  import location from "../../assets/images/location.svg";
  import Barloader from "../../sharedComponent/BarLoader";
  import GoogleMap from "../../components/GoogleMap";
  import GoogleMapNew from "../../components/GoogleMapNew";
  import Payment from "../payment/Payment";
  
  const CareerEdit =()=> {
    const params = useParams();
    let id = params.id;
    const { sidebar, setSidebar, customerData } = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [eventData, setEventData] = useState({});
    const [locationData, setLocationData] = useState({});
    const placeInputRef = useRef(null);
    const [place, setPlace] = useState({
      center: { lat: 19.8762, lng: 75.3433 },
    });
  
    let [marker, setMarker] = useState(null);
    const [circle, setCircle] = useState(null);
    const navigate = useNavigate();
  
    // useEffect(() => {
    //   setPlace(place);
    // }, [place]);
  
    // useEffect(() => {
    //   if (locationData.maps && locationData.map) {
    //     const { map, maps } = locationData;
    //     // or else call that isApiLoaded function and pass-on these arguments
    //     renderMarkers(map, maps);
    //   }
    // }, [locationData, place]);
  
    const getLocation = (lat, lng) => {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBxqcBX2r8cybljkDigXBgyOByjeQOmqr0`,
  
        {
          method: "GET", // or 'PUT'
          headers: {
            // "Content-Type": "application/json",
            // 'Access-Control-Allow-Credentials': "*"
          },
          //body: JSON.stringify(data),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setPlace({
            ...place,
            center: { lat, lng },
            address: data.results[1].formatted_address,
          });
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };
  
    const renderMarkers = (map, maps) => {
      marker && marker.setMap(null);
      circle && circle.setMap(null);
      const cityCircle = new maps.Circle({
        strokeColor: "#00b4bd",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#00b4bd",
        fillOpacity: 0.35,
        map,
        center: { ...place.center },
        // radius: { ...place.center.radius },
        radius: place.radius ? Number(place.radius) : 1000,
      });
      marker = new maps.Marker({
        draggable: true,
        position: { ...place.center },
        map,
      });
      maps.event.addListener(marker, "dragend", async function (event) {
        getLocation(event.latLng.lat(), event.latLng.lng());
      });
      setMarker(marker);
      setCircle(cityCircle);
  
      return { marker, cityCircle };
    };
  
    // useEffect(() => {
    //   initPlaceAPI();
    // }, []);
  
    // initialize the google place autocomplete
    const initPlaceAPI = () => {
      let autocomplete = new window.google.maps.places.Autocomplete(
        placeInputRef.current
      );
      new window.google.maps.event.addListener(
        autocomplete,
        "place_changed",
        function () {
          let place = autocomplete.getPlace();
  
          setEventData({
            ...eventData,
            address: place.name,
            center: {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            },
          });
  
          setPlace({
            address: place.name,
            center: {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            },
          });
        }
      );
    };
  
    const [errorMsg, setErrMsg] = useState({
      title: "",
      description: "",
      contactInfo: "",
      postType: "",
      isFeatured: "",
      postedDate: "",
      keywords: "",
      images: "",
  
      propertyType: "",
      availability: "",
      sharingType: "",
      term: "",
      gender: "",
      rooms: "",
      bathroom: "",
      billsIncluded: "",
      smokedAllowed: "",
      petsAllowed: "",
      monthlyRent: {
        currency: "",
        value: "",
      },
      deposit: "",
      address: "",
      long: "",
      lat: "",
      address1: "",
      address2: "",
      city: "",
      county: "",
      country: "",
      postCode: "",
    });
  
    const [featureAdd, setFeatureAdd] = useState({
      featured_ads: false,
      featured_ads_duration: "select",
      featured_ads_cost: "£ 1.98",
    });

    const [formdatas, setformdatas] = useState("");
    const [isEnabled, setIsEnabled] = useState(false);
    const [openFeatureAddModel, setOpenFeatureAddModel] = useState(false);
    const [openPaymentAddModel, setOpenPaymentAddModel] = useState(false);
  
    const handleClose = () => {
      setIsEnabled(false);
      setOpenFeatureAddModel(false);
      setOpenPaymentAddModel(false);
      setFeatureAdd((prevState) => ({ ...prevState, featured_ads: false }));
    };
  
    const proceedPayment = () => {
      setOpenPaymentAddModel(true);
    };
  
    const toggleSwitch = () => {
      setOpenFeatureAddModel(true);
      setIsEnabled(!isEnabled);
      setFeatureAdd((prevState) => ({
        ...prevState,
        featured_ads: !prevState.featured_ads,
      }));
    };
  
    const removeImage = (index) => {
      let images = AddProperty.images;
  
      images = images.filter(
        (img) => images.indexOf(img) != index
      );
  
      setAddProperty({ ...AddProperty, images: images});
    };

    
    const [inputValue, setInputValue] = useState('');

    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && inputValue.trim()) {
        if (!AddProperty.keywords.includes(inputValue.trim())) {
          setAddProperty({
            ...AddProperty,
            keywords: [...AddProperty.keywords, inputValue.trim()]
          });
          setInputValue('');
          // setErrorMsg('');
        } else {
          // setErrorMsg('Keyword already exists');
        }
      }
    };
  
    
    const handleDelete = (keywordToDelete) => {
      setAddProperty({
        ...AddProperty,
        keywords: AddProperty.keywords.filter((keyword) => keyword !== keywordToDelete)
      });
    };
  

    
    
    // const removeImage = (index) => {
    //   const newImages = AddProperty.images.filter((_, i) => i !== index);
    //   setAddProperty({ ...AddProperty, images: newImages });
    // };
  
    const [showElement, setShowElement] = React.useState(true);
    useEffect(() => {
      const timerId = setTimeout(function () {
        setShowElement(false);
      }, 5000);
  
      return () => clearTimeout(timerId);
    }, []);
  
    // const AddpropertyMange = async (e) => {
    //   setLoading(true);
    //   setOpenPaymentAddModel(true);
  
    //   const validationErrors = {
    //     title: AddProperty.title === "",
    //     property_description: AddProperty.property_description === "",
    //     property_image: AddProperty.property_image.length === 0,
    //     property_type: AddProperty.property_type === "select",
    //     bills_included: AddProperty.bills_included === "select",
    //     gender: AddProperty.gender === "select",
    //     term: AddProperty.term === "select",
    //     smokers_allowed: AddProperty.smokers_allowed === "select",
    //     pets_allowed: AddProperty.pets_allowed === "select",
    //     numbers_of_rooms: AddProperty.numbers_of_rooms === "",
    //     availability: AddProperty.availability === "select",
    //     type: AddProperty.type === "select",
    //     deposit: AddProperty.deposit === "select",
    //     monthly_rent: AddProperty.monthly_rent === "",
    //   };
  
    //   const errorMessages = {
    //     title: "Please enter a title",
    //     property_description: "Please enter a property description",
    //     property_image: "Please upload an image",
    //     property_type: "Please select a property type",
    //     bills_included: "Please select whether bills are included",
    //     gender: "Please select a gender",
    //     term: "Please select the terms",
    //     smokers_allowed: "Please select whether smokers are allowed",
    //     pets_allowed: "Please select whether pets are allowed",
    //     numbers_of_rooms: "Please enter the number of rooms",
    //     availability: "Please select availability",
    //     type: "Please select a type",
    //     deposit: "Please select a deposit",
    //     monthly_rent: "Please enter monthly rent",
    //   };
  
    //   const errorMessagesToShow = {};
  
    //   for (const field in validationErrors) {
    //     if (validationErrors[field]) {
    //       errorMessagesToShow[field] = errorMessages[field];
    //     }
    //   }
  
    //   setErrMsg(errorMessagesToShow);
  
    //   if (Object.values(validationErrors).every((error) => !error)) {
    //     const datails = JSON.stringify({
    //       // ... (rest of your code)
    //     });
  
    //     const formData = new FormData();
    //     formData.append("images", AddProperty.property_image[0]);
    //     formData.append("details", datails);
    //     setformdatas(formData);
  
    //     // ============> api call start <==============
    //     // postMultipartWithAuthCallWithErrorResponse(
    //     //   ApiConfig.UNIVERSR_HOUSING_CREATE_PROPERTY,
    //     //   formData
    //     // )
    //     // .then((res) => {
    //     //   setLoading(false);
    //     //   swal("Property Created Successfully");
    //     //   navigate("/house_listing/all");
    //     // })
    //     // .catch((err) => {
    //     //   setLoading(false);
    //     //   console.log(err);
    //     // });
  
    //     console.log(datails)
    //     await fetch(ApiConfig.UNIVERSR_HOUSING_CREATE_PROPERTY, {
    //       method: "POST",
    //       body: formData,
    //     }).then((res) => {
    //       setLoading(false);
    //       swal("Property Created Successfully");
    //       // navigate("/house_listing/all");
    //     }).catch((err) => {
    //       setLoading(false);
    //       console.log(err);
    //     });
  
    //     // ============> api call ends <================
    //   } else {
    //     setLoading(false);
    //   }
    // };
  
    const handleGenderChange = (e) => {
      const { value, checked } = e.target;
      setAddProperty({
        ...AddProperty,
        housingDetails: {
          ...AddProperty.housingDetails,
          gender: checked
            ? [...AddProperty.housingDetails.gender, value]
            : AddProperty.housingDetails.gender.filter((item) => item !== value),
        },
      });
    };
  
    // const [AddProperty, setAddProperty,setUplodeFile] = useState({
    //   title: "Webapp test",
    //   description: "xxx",
    //   contactInfo: "xxx",
    //   postType: "housing",
    //   isFeatured: false,
    //   keywords: [],
    //   images: [],
  
    //   housingDetails: {
    //     propertyType: "",
    //     availability: "immediately",
    //     sharingType: "",
    //     term: "xxx",
    //     gender: [],
    //     rooms: 1,
    //     bathrooms: 1,
    //     billsIncluded: false,
    //     smokedAllowed: false,
    //     petsAllowed: false,
    //     monthlyRent: {
    //       currency: "USD",
    //       value: 20,
    //     },
    //     deposit: 100,
    //     long: "xx",
    //     lat: "xx",
    //     address1: "no-1, Brohaim",
    //     address2: "xxxxx",
    //     city: "xx",
    //     county: "xx",
    //     country: "xx",
    //     postCode: "xx",
    //   },
  
    // });
  
    // const handleDrop = useCallback(
    //   (acceptedFiles) => {
    //     const files = values.images || [];
  
    //     console.log("files", files);
    //     console.log("acceptedFiles", acceptedFiles);
  
    //     const newFiles = acceptedFiles.map((file) =>
    //       Object.assign(file, {
    //         preview: URL.createObjectURL(file),
    //       })
    //     );
    //   })
    //     setAddProperty('images', [...files, ...newFiles], { shouldValidate: true });
    //     setUplodeFile([...files, ...newFiles]);
  
    //     [setAddProperty,setUplodeFile,values.images]
  
    // const AddpropertyMange = async (e) => {
    //   setLoading(true);
    //   let datails = JSON.stringify({
  
    //     title: AddProperty?.title || "",
    //     description: AddProperty?.description || "",
    //     postType: AddProperty?.postType || "housing",
    //     contactInfo: AddProperty?.contactInfo || "",
    //     isFeatured: AddProperty?.isFeatured || false,
    //     keywords: AddProperty?.keywords || [],
    //     images: AddProperty?.images || [],
    //     housingDetails: {
    //       propertyType: AddProperty?.housingDetails?.propertyType || "",
    //       availability: AddProperty?.housingDetails?.availability || "",
    //       sharingType: AddProperty?.housingDetails?.sharingType || "",
    //       term: AddProperty?.housingDetails?.term || "",
    //       gender: AddProperty?.housingDetails?.gender || [],
    //       rooms: AddProperty?.housingDetails?.rooms || "",
    //       bathrooms: AddProperty?.housingDetails?.bathrooms || 1,
    //       billsIncluded: AddProperty?.housingDetails?.billsIncluded || false,
    //       smokedAllowed: AddProperty?.housingDetails.smokedAllowed || false,
    //       petsAllowed: AddProperty?.housingDetails?.petsAllowed || false,
    //       monthlyRent: {
    //         currency: AddProperty?.housingDetails?.monthlyRent?.currency || "",
    //         value: AddProperty?.housingDetails?.monthlyRent?.value || 1,
    //       },
    //       deposit: AddProperty?.housingDetails?.deposit || 1,
    //       long: AddProperty?.housingDetails?.long || "",
    //       lat: AddProperty?.housingDetails?.lat || "",
    //       address1: AddProperty?.housingDetails?.address1 || "",
    //       address2: AddProperty?.housingDetails?.address2 || "",
    //       city: AddProperty?.housingDetails?.city || "",
    //       county: AddProperty?.housingDetails?.county || "",
    //       country: AddProperty?.housingDetails?.country || "",
    //       postCode: AddProperty?.housingDetails?.postCode || "",
    //     },
    //   });
  
    //   const formData = new FormData();
  
    //   Object.keys(AddProperty).forEach((key) => {
    //     if (Object.prototype.hasOwnProperty.call(AddProperty, key)) {
    //       const value = AddProperty[key];
    //       // Check if value is defined before using it
    //       if (value !== undefined) {
    //         if (key === "housingDetails") {
    //           // Append each property of housingDetails individually
    //           const housingDetails = value;
    //           Object.keys(housingDetails).forEach((detailKey) => {
    //             if (
    //               Object.prototype.hasOwnProperty.call(housingDetails, detailKey)
    //             ) {
    //               const detailValue = housingDetails[detailKey];
    //               // Check if the detailValue is defined before appending
    //               if (detailValue !== undefined) {
    //                 if (
    //                   typeof detailValue === "string" ||
    //                   typeof detailValue === "number" ||
    //                   typeof detailValue === "boolean"
    //                 ) {
    //                   formData.append(
    //                     `housingDetails.${detailKey}`,
    //                     detailValue.toString()
    //                   );
    //                 } else if (
    //                   Array.isArray(detailValue) ||
    //                   typeof detailValue === "object"
    //                 ) {
    //                   formData.append(
    //                     `housingDetails.${detailKey}`,
    //                     JSON.stringify(detailValue)
    //                   );
    //                 }
    //               }
    //             }
    //           });
    //         } else {
    //           formData.append(key, value.toString());
    //         }
    //       }
    //     }
    //   });
  
    //   setformdatas(formData);
    //   console.log("details", datails);
  
    //   postMultipartWithAuthCallWithErrorResponse(
    //     ApiConfig.UNIVERSR_HOUSING_CREATE_PROPERTY,
    //     formData
    //   )
    //     .then((res) => {
    //       setLoading(false);
    //       swal("Property Created Successfully");
    //       navigate("/house_listing/all");
    //     })
    //     .catch((err) => {
    //       setLoading(false);
    //       swal("Property Created Unsuccesfull");
    //       console.log(err);
    //     });
  
    // };
  
    // const onPaymentSuccess = () => {
    //   setAddProperty((prevState) => ({ ...prevState, ...featureAdd }));
    //   setOpenFeatureAddModel(false);
    //   swal("Payment Successfully");
    // };
  
    // console.log("render-create-housing", { featureAdd, AddProperty });
  
    const [AddProperty, setAddProperty] = useState({
      title: "",
      description: "",
      contactInfo: "",
      postType: "career",
      isFeatured: false,
      keywords: [],
      images: [],
      careerDetails: {
        jobTitle: "",
        companyName: "",
        jobDescription: "",
        requiredSkills: [],
        salaryRange: "",
        jobType: "",
        isCvRequired: false,
        },
    });
  
  
    const handleDrop = useCallback((acceptedFiles) => {
      const files = AddProperty.images || [];
      const newFiles = acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) }));
      setAddProperty(prevState => ({ ...prevState, images: [...files, ...newFiles] }));
  
      
    }, [AddProperty]);
  
    useEffect(() => {
      getHouseDetails();
    }, [id]);

    useEffect(() => {
      console.log('AddProperty',AddProperty);
    }, [AddProperty]);

    
  
    const getHouseDetails = async () => {
      setLoading(true);
  
      console.log("check id", id);
  
      await fetch(`${ApiConfig.BASE_NEW_URL}posts/view/${id}`)
  
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setLoading(false);
          console.log("career_properties", data);
          
          // setHouseDetails(data);
          setAddProperty({
            title: data?.title || '',
            description: data?.description || '',
            postType: data?.postType || 'career',
            contactInfo: data?.contactInfo || '',
            isFeatured: data?.isFeatured || false,
            keywords: data?.keywords || [],
            images: data?.images || [],
            careerDetails: {
              jobTitle: data?.careerDetails?.jobTitle || '',
              companyName: data?.careerDetails?.jobTitle || '',
              jobDescription: data?.careerDetails?.jobTitle || '',
              requiredSkills: data?.careerDetails?.jobTitle || [],
              salaryRange: data?.careerDetails?.jobTitle || '',
              jobType: data?.careerDetails?.jobTitle || '',
              isCvRequired: data?.careerDetails?.jobTitle || false,
              },
          });
          
        })
        .catch(error => {
          setLoading(false);
          console.error('There was a problem fetching the data:', error);
        });
  
    };
  
  
    const AddpropertyMange = async (e) => {
      setLoading(true);
      let details = JSON.stringify({
        title: AddProperty.title || "",
        description: AddProperty.description || "",
        postType: AddProperty.postType || "career",
        contactInfo: AddProperty.contactInfo || "",
        isFeatured: AddProperty.isFeatured || false,
        keywords: AddProperty.keywords || [],
        images: AddProperty.images || [],
        careerDetails: {
          jobTitle: AddProperty.careerDetails.jobTitle || "",
          companyName: AddProperty.careerDetails.companyName || "",
          jobDescription: AddProperty.careerDetails.jobDescription || "",
          requiredSkills: AddProperty.careerDetails.requiredSkills || [],
          salaryRange: AddProperty.careerDetails.salaryRange || "",
          jobType: AddProperty.careerDetails.jobType || "",
          isCvRequired: AddProperty.careerDetails.isCvRequired || false,
                
        },
      });
  
      const formData = new FormData();

    //   Object.keys(AddProperty).forEach((key) => {
    //     if (Object.prototype.hasOwnProperty.call(AddProperty, key)) {
    //       const value = AddProperty[key];
    //         // Check if value is defined before using it
    //         if (value !== undefined) {
    //             if (key === 'housingDetails') {
    //                 // Append each property of housingDetails individually
    //                 const housingDetails = value;
    //                 Object.keys(housingDetails).forEach((detailKey) => {
    //                     if (Object.prototype.hasOwnProperty.call(housingDetails, detailKey)) {
    //                         const detailValue = housingDetails[detailKey];
    //                         // Check if the detailValue is defined before appending
    //                         if (detailValue !== undefined) {
    //                             if (
    //                                 typeof detailValue === 'string' ||
    //                                 typeof detailValue === 'number' ||
    //                                 typeof detailValue === 'boolean'
    //                             ) {
    //                                 formData.append(`housingDetails.${detailKey}`, detailValue.toString());
    //                             } else if (Array.isArray(detailValue) || typeof detailValue === 'object') {
    //                                 formData.append(`housingDetails.${detailKey}`, JSON.stringify(detailValue));
    //                             }
    //                         }
    //                     }
    //                 });
    //             } else {
    //                 formData.append(key, value.toString());
    //             }
    //         }
    //     }
    // });
    
    
      
  
    Object.keys(AddProperty).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(AddProperty, key)) {
        const value = AddProperty[key];
        if (value !== undefined) {
          if (key === "careerDetails") {
            const careerDetails = value;
            Object.keys(careerDetails).forEach((detailKey) => {
              if (Object.prototype.hasOwnProperty.call(careerDetails, detailKey)) {
                const detailValue = careerDetails[detailKey];
                if (detailValue !== undefined) {
                  if (typeof detailValue === "string" || typeof detailValue === "number" || typeof detailValue === "boolean") {
                    formData.append(`careerDetails.${detailKey}`, detailValue.toString());
                  } else if (Array.isArray(detailValue) || typeof detailValue === "object") {
                    formData.append(`careerDetails.${detailKey}`, JSON.stringify(detailValue));
                  }
                }
              }
            });
          }
          else if(key === "images"){
            const images = value;
            Array.from(images).forEach((file) => {
              formData.append("files", file);
            });
            
          }
          else {
            formData.append(key, value.toString());
          }
        }
      }
    });
    
      // if (uploadFile) {
      //   Array.from(uploadFile).forEach((file) => {
      //     formData.append("files", file);
      //   });
      // }
  
      setformdatas(formData);
      console.log("details", details);

    putMultipartWithAuthCallWithErrorResponse(
        ApiConfig.UNIVERSR_HOUSING_MANGE_UPDATE + id,
        formData
      )
        .then((res) => {
          setLoading(false);
          swal(" Updated Property Successfully");
  
          navigate("/events/all");
        })
        .catch((err) => console.log(err));
    };
   

  //   try {
  //     const response = await fetch(`${ApiConfig.UNIVERSR_HOUSING_MANGE_UPDATE}${id}`, {
  //       method: 'PATCH',
  //       body: formData
  //     });
  
  //     if (!response.ok) {
  //       // Handle the error response
  //       const errorData = await response.json();
  //       throw new Error(errorData.message || 'Failed to update property');
  //     }
  
  //     // Assuming the response is in JSON format
  //     const data = await response.json();
      
  //     // After successful update
  //     setLoading(false);
  //     swal("Updated Property Successfully");
  
  //     // Uncomment the next line if you want to navigate after success
  //     navigate("/house_listing/all");
  
  //   } catch (err) {
  //     console.error(err);
  //     swal("Update error");
  //     // Handle errors here, e.g., display an error message to the user
  //   }
  // };

    return (
    //   <div style={{ overflowY: "hidden" }}>
    //     {/* <Sidebar /> */}
  
    //     {/* <Header /> */}
    //     <main id="cxMain" className="cx-main cx-active">
    //       <section
    //         className="content-main"
    //         style={{
    //           backgroundColor: "white",
    //           borderRadius: "20px",
    //           height: "calc(100vh - 156px)",
    //         }}
    //       >
    <div style={{ display: 'flex', justifyContent: 'center' }}>
    <div style={{ maxWidth: '1300px', display: 'flex', flexDirection: 'row', margin: '10px', borderRadius: '15px', overflow: 'hidden' }}>
          
          <div className="row">
            <div className="col-md-12 scroll-hidden">
              <div
                className="create_screenWrapper"
                style={{
                  overflow: "auto",
                  height: "calc(100vh - 156px)",
                }}
              >
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Create your Property
                  </h5>
                </div>

                <div className="profile_modal_contentWrapper">
                  {loading && <Barloader />}
                  <div className="row">
                    {/* left side */}
                    <div
                      className="col-md-6 profile_form"
                      style={{ marginTop: "0px" }}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className="uploadPP_inputTextRow"
                            // style={{ width: "100%" }}type
                          >
                            <label class="form-label">Title</label>
                            <input
                              type="text"
                              class="form-control"
                              style={{ height: "40px" }}
                              value={AddProperty.title}
                              placeholder="Property Title "
                              onChange={(e) => {
                                // setErrMsg({ ...errMsg, title: "" });

                                setAddProperty({
                                  ...AddProperty,
                                  title: e.target.value,
                                });
                              }}
                            />
                            <small
                              style={{
                                color: "red",
                              }}
                            >
                              {errorMsg.title}
                            </small>
                            {/* {errMsg.title.length > 0 && (
                              <div className="text-center text-danger">
                                <span> {errMsg.title}</span>
                              </div>
                            )} */}
                          </div>
                        </div>

                        <div
                          style={{
                            margin: "-21px -5px -4px 7px",
                            paddingLeft: "0px",
                          }}
                          className="row"
                        >
                          <div
                            style={{
                              paddingLeft: "6px",
                            }}
                            className="col-md-12"
                          >
                            <div class="form-group ">
                              <label
                                class="form-label form-label-box-1"
                                style={{ marginTop: "20px" }}
                              >
                                Description
                              </label>
                              <textarea
                                value={AddProperty.description}
                                onChange={(e) => {
                                  // setErrMsg({ ...errMsg, title: "" })
                                  setAddProperty({
                                    ...AddProperty,
                                    description: e.target.value,
                                  });
                                }}
                                style={{
                                  border: "1px solid #E1E1E1",
                                  borderRadius: "10px",
                                  backgroundColor: "#FBF8F8 ",
                                }}
                                class="form-control "
                                placeholder="Describe your property"
                                id="exampleFormControlTextarea1"
                                rows="3"
                              ></textarea>
                              <small
                                style={{
                                  color: "red",
                                }}
                              >
                                {errorMsg.description}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className="uploadPP_inputTextRow"
                            // style={{ width: "100%" }}type
                          >
                            <label
                              class="form-label"
                              style={{ marginTop: "20px" }}
                            >
                              Contact Info
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              style={{ height: "40px" }}
                              value={AddProperty.contactInfo}
                              placeholder="Contact info"
                              onChange={(e) => {
                                // setErrMsg({ ...errMsg, title: "" });

                                setAddProperty({
                                  ...AddProperty,
                                  contactInfo: e.target.value,
                                });
                              }}
                            />
                            <small
                              style={{
                                color: "red",
                              }}
                            >
                              {errorMsg.contactInfo}
                            </small>
                            {/* {errMsg.title.length > 0 && (
                              <div className="text-center text-danger">
                                <span> {errMsg.title}</span>
                              </div>
                            )} */}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className="uploadPP_inputTextRow"
                            // style={{ width: "100%" }}type
                          >
                            <label
                              class="form-label"
                              style={{ marginTop: "20px" }}
                            >
                              Post Type
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              style={{ height: "40px" }}
                              value={AddProperty.postType}
                              placeholder="Post Type"
                              onChange={(e) => {
                                // setErrMsg({ ...errMsg, title: "" });

                                setAddProperty({
                                  ...AddProperty,
                                  postType: e.target.value,
                                });
                              }}
                            />
                            <small
                              style={{
                                color: "red",
                              }}
                            >
                              {errorMsg.postType}
                            </small>
                            {/* {errMsg.title.length > 0 && (
                              <div className="text-center text-danger">
                                <span> {errMsg.title}</span>
                              </div>
                            )} */}
                          </div>
                        </div>
                      </div>

                     
                      <div className="col-lg-12">
                        <div className="uploadPP_inputTextRow usalabel">
                          <div className="upp_selectWrapper">
                            <div className="">
                              <div className="uploadPP_inputTextRow" style={{ width: "100%" }}>
                                <div className="ip" style={{ position: "relative" }}>
                                  <label className="form-label" style={{ marginTop: "20px" }}>
                                    <Switch
                                      onChange={(checked) => setAddProperty({ ...AddProperty, isFeatured: checked })}
                                      checked={AddProperty.isFeatured}
                                      offColor="#888"
                                      onColor="#0b9"
                                      uncheckedIcon={false}
                                      checkedIcon={false}
                                    />
                                    {' '}
                                    is Featured
                                  </label>
                                  <span style={{ marginLeft: '10px' }}>
                                    {AddProperty.isFeatured ? 'Yes' : 'No'}
                                  </span>
                                </div>
                                <small style={{ color: "red" }}>
                                  {errorMsg.isFeatured}
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                     

                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className="uploadPP_inputTextRow"
                            // style={{ width: "100%" }}type
                          >
                            <label class="form-label">Keywords</label>
                            <input
                              type="text"
                              class="form-control"
                              style={{ height: "40px" }}
                              value={AddProperty.keywords}
                              placeholder="Keywords"
                              onChange={(e) => {
                                // setErrMsg({ ...errMsg, title: "" });

                                setAddProperty({
                                  ...AddProperty,
                                  keywords: e.target.value,
                                });
                              }}
                            />
                            <small
                              style={{
                                color: "red",
                              }}
                            >
                              {errorMsg.keywords}
                            </small>
                            {/* {errMsg.title.length > 0 && (
                              <div className="text-center text-danger">
                                <span> {errMsg.title}</span>
                              </div>
                            )} */}
                          </div>
                        </div>
                      </div>

                      {/* <div className="row">
                        <div className="col-md-12">
                           <div
                            className="uploadPP_inputTextRow"
                            // style={{ width: "100%" }}type
                          >
                            <label class="form-label">Keywords</label>
                            <input
                              type="text"
                              class="form-control"
                              style={{ height: "40px" }}
                              value={AddProperty.keywords}
                              placeholder="Keywords"
                              onChange={(e) => {
                                // setErrMsg({ ...errMsg, title: "" });

                                setAddProperty({
                                  ...AddProperty,
                                  keywords: e.target.value,
                                });
                              }}
                            />
                            <small
                              style={{
                                color: "red",
                              }}
                            >
                              {errorMsg.keywords}
                            </small>
                            {/* {errMsg.title.length > 0 && (
                              <div className="text-center text-danger">
                                <span> {errMsg.title}</span>
                              </div>
                            )}
                          </div> 
                        </div>
                      </div>  */}

                       <div className="row mb-4 align-items-center gy-3">
                        <div
                          style={
                            {
                              // marginTop: "70px",
                            }
                          }
                          className="col-12 col-sm-6 col-xl-4 custom-resp-1"
                        >
                          <div
                            style={{
                              height: "148px",
                              width: "148px",
                              margin: "0",
                              padding: "0",
                            }}
                          >
                          
                            {/* <ImageUplode
                                setFile={(files) => {
                                  setAddProperty(prevState => ({
                                    ...prevState,
                                    images: [...prevState.images, ...files],
                                  }));
                                }}
                              /> */}

                            <ImageUplode
                              setFile={(files) => {
                                setAddProperty(prevState => ({
                                  ...prevState,
                                  images: [...prevState.images, ...files],
                                }));
                              }}
                            />

                            <small
                              style={{
                                color: "red",
                              }}
                            >
                              {errorMsg.images}
                            </small>
                          </div>
                        </div>

                        {AddProperty.images.map((img, index) => {
                          return (
                            <div className="col-12 col-sm-6 col-xl-4 ">
                              <div
                                style={{
                                  width: "148px",
                                  // marginTop: "10px",
                                }}
                                className="uploaded-image-1 uploaded-reps-1"
                              >
                                <div className="item_list" key={"img" + index}>
                                  <img
                                    width={72}
                                    height="72"
                                    className="img"
                                    src={img}
                                    alt=""
                                  />
                                  <a
                                    style={{
                                      position: "absolute",
                                      top: "10px",
                                      right: "0px",
                                      // marginTop: "10px",
                                      // marginLeft: "-0px",
                                    }}
                                    href="#"
                                    className=""
                                  >
                                    <i
                                      onClick={() => removeImage(index)}
                                      class="fa-solid fa-xmark"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div> 
                    </div>
                    <div className="col-lg-6 main-property-type">
                      <div className="row">
                        {/* PropertyType */}

                        <div>
      <div className="col-md-12">
        <div className="uploadPP_inputTextRow">
          <label className="form-label" style={{ marginTop: '20px' }}>
            Job Title
          </label>
          <input
            type="text"
            className="form-control"
            style={{ height: '40px' }}
            value={AddProperty.careerDetails.jobTitle}
            placeholder=" "
            onChange={(e) => {
              setAddProperty({
                ...AddProperty,
                careerDetails: {
                  ...AddProperty.careerDetails,
                  jobTitle: e.target.value
                }
              });
            }}
          />
          <small style={{ color: 'red' }}>
            {errorMsg.jobTitle}
          </small>
        </div>
      </div>

      <div className="col-md-12">
        <div className="uploadPP_inputTextRow">
          <label className="form-label" style={{ marginTop: '20px' }}>
          Job Type
          </label>
          <input
            type="text"
            className="form-control"
            style={{ height: '40px' }}
            value={AddProperty.careerDetails.jobType}
            placeholder=" "
            onChange={(e) => {
              setAddProperty({
                ...AddProperty,
                careerDetails: {
                  ...AddProperty.careerDetails,
                  jobType: e.target.value
                }
              });
            }}
          />
          <small style={{ color: 'red' }}>
            {errorMsg.jobType}
          </small>
        </div>
      </div>

      <div className="col-md-12">
        <div className="uploadPP_inputTextRow">
          <label className="form-label" style={{ marginTop: '20px' }}>
            Company Name
          </label>
          <input
            type="text"
            className="form-control"
            style={{ height: '40px' }}
            value={AddProperty.careerDetails.companyName}
            placeholder=" "
            onChange={(e) => {
              setAddProperty({
                ...AddProperty,
                careerDetails: {
                  ...AddProperty.careerDetails,
                  companyName: e.target.value
                }
              });
            }}
          />
          <small style={{ color: 'red' }}>
            {errorMsg.companyName}
          </small>
        </div>
      </div>

      <div className="col-md-12">
        <div className="uploadPP_inputTextRow">
          <label className="form-label" style={{ marginTop: '20px' }}>
            Job Description
          </label>
          <textarea
            value={AddProperty.careerDetails.jobDescription}
            onChange={(e) => {
              setAddProperty({
                ...AddProperty,
                careerDetails: {
                  ...AddProperty.careerDetails,
                  jobDescription: e.target.value
                }
              });
            }}
            style={{
              border: '1px solid #E1E1E1',
              borderRadius: '10px',
              backgroundColor: '#FBF8F8'
            }}
            className="form-control"
            placeholder="Describe your property"
            rows="3"
          ></textarea>
          <small style={{ color: 'red' }}>
            {errorMsg.jobDescription}
          </small>
        </div>
      </div>

      <div className="col-md-12">
        <div className="uploadPP_inputTextRow">
          <label className="form-label" style={{ marginTop: '20px' }}>
            Required Skills
          </label>
          <input
            type="text"
            className="form-control"
            style={{ height: '40px' }}
            value={AddProperty.careerDetails.requiredSkills}
            placeholder=" "
            onChange={(e) => {
              setAddProperty({
                ...AddProperty,
                careerDetails: {
                  ...AddProperty.careerDetails,
                  requiredSkills: e.target.value
                }
              });
            }}
          />
          <small style={{ color: 'red' }}>
            {errorMsg.requiredSkills}
          </small>
        </div>
      </div>

      <div className="col-md-12">
        <div className="uploadPP_inputTextRow">
          <label className="form-label" style={{ marginTop: '20px' }}>
            <Switch
              onChange={(checked) => setAddProperty({
                ...AddProperty,
                careerDetails: {
                  ...AddProperty.careerDetails,
                  isCvRequired: checked
                }
              })}
              checked={AddProperty.careerDetails.isCvRequired}
              offColor="#888"
              onColor="#0b9"
              uncheckedIcon={false}
              checkedIcon={false}
            />
            {' '}
            Is CV Required
          </label>
          <span style={{ marginLeft: '10px' }}>
            {AddProperty.careerDetails.isCvRequired ? 'Yes' : 'No'}
          </span>
          <small style={{ color: 'red' }}>
            {errorMsg.isCvRequired}
          </small>
        </div>
      </div>


      <div className="col-md-12">
        <div className="uploadPP_inputTextRow">
          <label className="form-label" style={{ marginTop: '20px' }}>
            Salary Range
          </label>
          <input
            type="text"
            className="form-control"
            style={{ height: '40px' }}
            value={AddProperty.careerDetails.salaryRange}
            placeholder=" "
            onChange={(e) => {
              setAddProperty({
                ...AddProperty,
                careerDetails: {
                  ...AddProperty.careerDetails,
                  salaryRange: e.target.value
                }
              });
            }}
          />
          <small style={{ color: 'red' }}>
            {errorMsg.salaryRange}
          </small>
        </div>
      </div>
    </div>



                        
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                        }}
                      >
                        

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "5px",
                            paddingLeft: "2px",
                            paddingRight: "2px",
                          }}
                        >
                          
                        </div>
                      </div>
                      {openFeatureAddModel && isEnabled ? (
                        <Modal
                          className="custom-dialog"
                          aria-labelledby="contained-modal-title-vcenter"
                          centered
                          show={openFeatureAddModel}
                          onHide={handleClose}
                        >
                          <Modal.Header
                            style={{ padding: "12px", width: "99%" }}
                            closeButton
                          >
                            <Modal.Title>About Featured Post</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                           
                            <div
                              class="modal-body"
                              style={{ marginTop: "-37px" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "1.25rem",
                                  marginTop: "1.25rem",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span>Duration</span>

                                  <span style={{ fontWeight: "bold" }}>
                                    Boost this post for
                                  </span>

                                  <select
                                    style={{
                                      width: "150px",
                                      color: "#707070",
                                      height: "50px",
                                      fontSize: "12px",
                                      background: "white",
                                      display: "flex",
                                      alignItems: "center",
                                      borderRadius: "10px",
                                      border: "1px solid #01B3BB",
                                      paddingTop: "14px",
                                      paddingRight: "30px",
                                      paddingBottom: "14px",
                                      paddingLeft: "20px",
                                      fontWeight: "bold",
                                    }}
                                    value={featureAdd.featured_ads_duration}
                                    onChange={(e) =>
                                      setFeatureAdd((prevState) => ({
                                        ...prevState,
                                        featured_ads_duration: e.target.value,
                                      }))
                                    }
                                    // onClick={handlePrice}
                                  >
                                    <option value="select">
                                      Select Duration
                                    </option>

                                    <option value="1 day">1 day</option>

                                    <option value="1 week">1 Week</option>

                                    <option value="2 week">2 Week</option>

                                    <option value="3 Week">3 Week</option>

                                    <option value="1 Month">1 Month</option>
                                  </select>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span>Price</span>

                                  <input
                                    style={{
                                      width: "150px",
                                      color: "#707070",
                                      height: "50px",
                                      fontSize: "12px",
                                      background: "white",
                                      display: "flex",
                                      alignItems: "center",
                                      borderRadius: "10px",
                                      border: "1px solid #01B3BB",
                                      paddingTop: "14px",
                                      paddingRight: "30px",
                                      paddingBottom: "14px",
                                      paddingLeft: "20px",
                                      fontWeight: "bold",
                                    }}
                                    // placeholder="Feature Add Cost"
                                    value={featureAdd.featured_ads_cost}
                                    readOnly={true}
                                    onChange={(e) =>
                                      setFeatureAdd((prevState) => ({
                                        ...prevState,
                                        featured_ads_cost: e.target.value,
                                      }))
                                    }
                                  />
                                </div>

                                <button
                                  style={{
                                    marginTop: "8px",
                                    width: "100%",
                                    height: "67.19px",
                                    flexShrink: 0,
                                    border: "10px solid transparent",
                                    borderRadius: "10px",
                                    backgroundColor: "#01B3BB",
                                    color: "#FEFCFE",
                                    fontFamily: "montserrat",
                                    fontWeight: "bold",
                                    fontSize: "18px",
                                    textTransform: "uppercase",
                                  }}
                                  onClick={() => {
                                    setOpenPaymentAddModel(true);
                                  }}
                                >
                                  Proceed Payment
                                </button>
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                      ) : (
                        <></>
                      )}

                      {openPaymentAddModel && (
                        <Modal
                          className="custom-dialog"
                          aria-labelledby="contained-modal-title-vcenter"
                          centered
                          show={openFeatureAddModel}
                          onHide={handleClose}
                        >
                          <Modal.Header
                            style={{ padding: "12px", width: "99%" }}
                            closeButton
                          >
                            <Modal.Title>About Payment</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            {/* Are you sure you want to cancel this Friend Request? */}
                            <div
                              class="modal-body"
                              style={{ marginTop: "-37px" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "1.25rem",
                                  marginTop: "1.25rem",
                                }}
                              >
                                {/* <Payment
                                  onSuccess={onPaymentSuccess}
                                  setOpenPaymentAddModel={
                                    setOpenPaymentAddModel
                                  }
                                /> */}
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                      )}
                    </div>
                  </div>

                  {/* tright side`` */}

                  <div
                    className="create_screenControls"
                    style={{
                      marginTop: "-20px",
                      borderColor: "#00B4BD",
                      color: "#00B4BD",
                    }}
                  >
                    <Link to="/career/all" className="dis">
                      Cancel
                    </Link>
                    <Link onClick={AddpropertyMange} className="primary" to="#">
                      Create Property
                    </Link>
                    {/* <button
                      className="primary"
                      to="#"
                      onClick={Addproperty}
                      style={{ padding: "13PX", width: "140px" }}
                    >
                      Update Profile
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        {/* </section>
      </main>
    </div> */}
</div>
</div>
    );
  }
  
export default CareerEdit;
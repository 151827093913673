// import React, { useContext, useState, useEffect } from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import ApiConfig from "../../api/ApiConfig";
// import {
//   simpleGetCallWithErrorResponse,
//   simplePostCall,
// } from "../../api/ApiServices";
// import eventthumbImg01 from "../../assets/images/eventThumb01.png";
// import LoaderCenter from "../../sharedComponent/LoaderCenter";
// import { AppContext } from "../../context/AppContext";
// import swal from "sweetalert";
// import { Modal, Button } from 'react-bootstrap'
// import { CopyToClipboard } from 'react-copy-to-clipboard'
// import { ShareSocial } from 'react-share-social'
// import { Slider } from "../../components/Slider";

// const style = {
//   background: '#F5F5F5',
//   borderRadius: 3,
//   border: 0,
//   color: 'white',
//   padding: '0 0px',
//   // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
//   height: '200px',
//   marginTop: '20px',
//   // width: '20px',
// }
// const AllEvent = () => {
//   const { customerData } = useContext(AppContext);
//   const [showModal, setShow] = useState(false)
//   const [ShareData, setShare] = useState('')
//   const [selectedID, setselectedID] = useState("") 

//   const [loading, setLoading] = useState(false);
//   const [EventList, setEventList] = useState([]);
//   const [EventDetalis, setEventDetalis] = useState([]);
//   const handleClose = () => setShow(false)
//   const handleShow = () => {
    
//     setShow(true)
//     ShareHandal()
//   }
//   useEffect(() => {
//     getEventlist();
//   }, [EventDetalis]);

//   const getEventlist = () => {
//     setLoading(true);

//     simpleGetCallWithErrorResponse(
//       ApiConfig.UNIVERSR_EVENT_ALL_LIST,
//       JSON.stringify({ ...customerData })
//     ).then((res) => {
//       console.log(res);
//       setLoading(false);
//       setEventList(res.json.list);
//     });
//   };

//   const buyEvent = (id) => {
//     simplePostCall(
//       ApiConfig.EVENTS_IM_INTERESTED,
//       JSON.stringify({ event_id: id })
//     )
//       .then((res) => {
//         console.log(res);
//         setEventDetalis(res);
//         swal(res.message);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };

//   const EventSave = (id) => {
//     simplePostCall(
//       ApiConfig.UNIVERSR_EVENT_ALL_LIST_SAVE,
//       JSON.stringify({ event_id: id })
//     )
//       .then((res) => {
//         console.log(res);

//         swal(res.message);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };



//   const handalId = (id) => {
//     setselectedID(id)
//   }

//   const ShareHandal = async () => {
//     let requestBody = JSON.stringify({
//       dynamicLinkInfo: {
//         domainUriPrefix: 'https://myuniverse.page.link',

//         link:
//         'https://my-univrs.app.redbytes.in/event_details/' +
//         String(selectedID) +
//         '/?user=' +
//         String(customerData.id),

//         androidInfo: {
//           androidPackageName: 'com.my_universe',
//         },

//         iosInfo: {
//           iosBundleId: 'org.myUniverse',
//         },
//       },
//     })

//     await fetch(
//       'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBmt92Aby2xlZWOCIHeTutj8ywpF-4reyw',

//       {
//         method: 'POST',

//         headers: {
//           Accept: 'application/json',

//           'Content-Type': 'application/json',
//         },

//         body: requestBody,
//       },
//     )
//       .then((response) => response.json())

//       .then((data) => {
//         console.log('response', data)
//         setShare(data.shortLink)
//       })

//       .catch((err) => {
//         // this.setState({isVisible:false})

//         console.log('error', err)
//       })
//   }



//   return (
//     <>
//       {loading ? (
//         <LoaderCenter />
//       ) : (
//         <>
//          <Modal
//               className="custom-dialog"
//               aria-labelledby="contained-modal-title-vcenter"
//               centered
//               show={showModal}
//               onHide={handleClose}
//             >
//               <Modal.Header
//                 style={{ padding: '12px', width: '99%' }}
//                 closeButton
//               >
//                 <Modal.Title></Modal.Title>
//               </Modal.Header>
//               <Modal.Body>
//               {/* Are you sure you want to cancel this Friend Request? */}
//               <div class="modal-body" style={{ marginTop: '-37px' }}>
//                 <ShareSocial
//                   style={style}
//                   url={ShareData}
//                   socialTypes={[
//                     'facebook',
//                     'twitter',
//                     'reddit',
//                     'linkedin',
//                     'email',
//                     'pinterest',
//                   ]}
//                 />
//                 <CopyToClipboard
//                     text={ShareData}
//                     onCopy={() => swal('Copied to Clipboard')}
//                   >
//                     <Link to="#" className='copy-icon'>
//                       <i class="fa-solid fa-copy"></i>
//                     </Link>
//                   </CopyToClipboard>
//               </div>
//             </Modal.Body>
//             </Modal>

           

//         <div className="row no-scroll row-gap-20">
//           {EventList && EventList.length > 0 ? (
//             EventList.map((EventList, index) => {
     
//               return (
//                 <div className="col-12 col-lg-6  col-xl-4 col-xxl-3">
//                   <div className="gbl_thumbWrapper">
//                     <div className="img_thumbWrapper">
//                       <div class="dropdown">
//                         <button
//                           onClick={() => {
//                             handalId(EventList.id)
//                           }}
//                           class="eventMenu"
//                           type="button"
//                           id="dropdownMenuButton1"
//                           data-bs-toggle="dropdown"
//                           aria-expanded="false"
//                         >
//                           <i class="fa-solid fa-ellipsis-vertical"></i>
//                         </button>
//                         <ul
//                           class="dropdown-menu career-drop"
//                           aria-labelledby="dropdownMenuButton1"
//                           style={{
//                             backgroundColor: "#F5F5F5",
//                             border: "1px solid #ECECEC",
//                             position: "absolute",
//                             top: "10px",
//                             right: "10px",
//                           }}
//                         >
//                           <li>
//                             <a
//                               onClick={() => {
//                                 EventSave(EventList.id);
//                               }}
//                               class="dropdown-item"
//                               href="#"
//                             >
//                               Save
//                             </a>
//                           </li>
//                           <li>
//                           <Link
//                             onClick={handleShow}
//                             to="#"
//                             class="dropdown-item"
//                             href="#"
//                           >
//                             Share
//                           </Link>
//                         </li>
//                           {/* <li>
//                             <Link
//                               to={`/event_details/${EventList.id}`}
//                               class="dropdown-item"
//                               href="#"
//                             >
//                               Details
//                             </Link>
//                           </li> */}
//                         </ul>
//                       </div>

//                       <img
//                         src={EventList.image}
//                         alt=""
//                         className="event-thumbnail"
//                       />
//                     </div>
//                     <Link to={`/event_details/${EventList.id}`}>
//                       <div className="event-details">
//                         <h1 className="event-title">{EventList.name}</h1>
//                         <h2 className="event-price">
//                           {
//                             EventList.ticket_price==0?'Free':` $ ${EventList.ticket_price}`
//                           }
                       
//                         </h2>
//                         <h4 className="event-address content mx-0">
//                           {EventList.address}
//                         </h4>
//                         <div className="event-action-wrapper d-flex flex-column align-items-start align-items-sm-end flex-sm-row">
//                           <p className="event-time">
//                             {EventList.start_time} - {EventList.end_time}
//                           </p>
//                           <Link
//                             onClick={() => {
//                               buyEvent(EventList.id);
//                             }}
//                             to="#"
//                             className="mt-2  mt-sm-0"
//                           >
//                             {/* I`m Interested */}
//                             {EventList.i_am_interested === "not applied"
//                               ? "I'm Interested"
//                               : "Interested"}
//                           </Link>
//                         </div>
//                       </div>
//                     </Link>
//                   </div>
//                 </div>
//               );
//             })
//           ) : (
//            <p
//             style={{
//               fontSize: "20px",
//               marginTop: "200px",
              
//             }}
//             className=" text-center justify-content-center align-items-center"
//           >
//           Oops! It doesn't look like there are any Event in your area.<br/> Why don't you try expanding your search criteria?
//           </p>
//           )}
//         </div>
//         </>
//       )}
//     </>
//   );
// };

// export default AllEvent;

import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/AppContext";
import { ShareSocial } from "react-share-social";
import { Modal, Button } from "react-bootstrap";
import {
  deleteWithAuthCall,
  postMultipartWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
  simplePostCall,
  simpleGetCall,
} from "../../api/ApiServices";
import swal from "sweetalert";

import eventthumbImg01 from "../../assets/images/house01.png";
import LoaderCenter from "../../sharedComponent/LoaderCenter";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Slider } from "../../components/Slider";
import './AllPropertyEvent.css'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faBuilding,
  faUsers,
  faBed,
  faBath,
  faMoneyBill,
  faDollarSign,
  faUser,
  faClock ,
  faTrashAlt,
  faHeart
} from "@fortawesome/free-solid-svg-icons";

import CardView from "./CardView";


const InterestedEvents = () => {
  const { keyword } = useContext(AppContext);
  const [dismiss, setDismiss] = useState(false);
  const [ShareData, setShare] = useState("");

  const filterModal = useRef(null);

  // filter api starts
  const [errorMsg, setErrorMsg] = useState({
    title: "",
    property_type: "",
    gender: "",
    term: "",
    availability: "",
    minimum: "",
    maximum: "",
  });
  const { customerData } = useContext(AppContext);
  console.log("customerData", customerData.id);
  const [propertyfilterType, setPropertyFilterType] = useState({
    title: "",
    propertyType: "select",
    gender: "select",
    term: "select",
    availability: "select",
    billsIncluded: "No",
    smokedAllowed: "No",
    minimum: "",
    maximum: "",
  });
  const style = {
    background: "#F5F5F5",
    borderRadius: 3,
    border: 0,
    color: "white",
    padding: "0 0px",
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    height: "200px",
    marginTop: "20px",
    // width: '20px',
  };
  const [showElement, setShowElement] = React.useState(true);
  const currentRoute = useLocation().pathname;
  const [loading, setLoading] = useState(false);
  //const [HouseingList, setHouseingList] = useState([]);
  const [HouseingList, setHouseingList] = useState([]);
  const [HouseingAdsList, setHouseingAdsList] = useState([])
  const [houseingListadd, setHouseingAdd] = useState([]);
  const [HouseingMange, setHouseingMange] = useState([]);
  const [selectedID, setselectedID] = useState("");

  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    ShareHandal();
  };
  const navigate = useNavigate();

  useEffect(() => {
    getHouselist();
  }, []);
  useEffect(() => {
    getHouselist();
  }, [keyword]);
  

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    // Options for formatting the date as you wish
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true, // Whether to use 12-hour time (true) or 24-hour time (false)
    };
    return date.toLocaleString('en-US', options);
  };

//   const getHouselist =async() => {
//     setLoading(true);

//    await fetch(ApiConfig.UNIVERSR_EVENT_ALL_LIST)
//       .then(response => {
//         if (!response.ok) {
//           throw new Error('Network response was not ok');
//         }
//         return response.json();
//       })
//       .then(data => {
//         setLoading(false);
//        console.log("housing_properties", data.posts);
//         // if (keyword.length > 0) {
//         //   getSearchedProducts(data.housing_properties);
//         // } else {
//           setHouseingList(data.posts);
//           console.log("res housing", HouseingList);
//          // setHouseingAdsList(data.housing_properties.filter(e => e.featured_ads == true));
//          //}
//       })
//       .catch(error => {
//         setLoading(false);
//         console.error('There was a problem fetching the data:', error);
//       });
// };

const getHouselist = async () => {
  setLoading(true);

  await fetch(ApiConfig.UNIVERSR_EVENT_ALL_LIST)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      setLoading(false);
      if (keyword.length > 0) {
        getSearchedProducts(data.posts);
      } else {
        setHouseingList(data.posts);
        // setHouseingAdsList(data.posts.filter(e => e.featured_ads === true));
      }
    })
    .catch(error => {
      setLoading(false);
      console.error('There was a problem fetching the data:', error);
    });
};

const getSearchedProducts = (data) => {
  let matches = [];
  matches = data.filter((product) => {
    return product && product.title.toLowerCase().includes(keyword.toLowerCase());
  });
  setHouseingList(matches);
};


  const Delete = (id) => {
    deleteWithAuthCall(ApiConfig.UNIVERSR_HOUSING_MANGE_DELET + id)
      .then((res) => {
        console.log(res);
        swal(res.detail);
        getHouselist();
        navigate("/house_listing/all");
      })
      .catch((err) => {
        setLoading(false);

        console.log(err);
      });
  };

  // const getSearchedProducts = (data) => {
  //   let matches = [];
  //   matches = data.filter((product, index) => {
  //     if (
  //       product &&
  //       product.title.toLowerCase().includes(keyword.toLowerCase())
  //     )
  //       return product;
  //   });

  //   setHouseingList(matches);
  // };

  const AddIntersted = (id) => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.UNIVERSR_ADD_USER_INTERSTED,
      JSON.stringify({ housing_property_id: id })
    )
      .then((res) => {
        setHouseingAdd(res);

        console.log(res);
        swal(res?.json);
        getHouselist();
      })
      .then((data) => {
        swal(data?.json);
        setHouseingAdd(data);
      })
      .catch((err) => {
        setLoading(false);

        console.log(err);
      });
  };
  // ===========> search start =================

  // ================= end ================
  const propertyFilter = (e) => {
    setDismiss(false);

    let formData = new FormData();
    formData.append("id", customerData.id);
    formData.append("type", propertyfilterType.title);
    formData.append("propertyType", propertyfilterType.propertyType);
    formData.append("gender", propertyfilterType.gender);
    formData.append("term", propertyfilterType.term);
    formData.append("availability", propertyfilterType.availability);
    formData.append("bills_included", propertyfilterType.billsIncluded);
    formData.append("smokers_allowed", propertyfilterType.smokedAllowed);
    formData.append("minimum", propertyfilterType.minimum);
    formData.append("maximum", propertyfilterType.maximum);

    if (!(propertyfilterType.type === "")) {
      setErrorMsg({ title: "" });

      if (!(propertyfilterType.property_type === "select")) {
        setErrorMsg({ propertyType: "" });
        if (!(propertyfilterType.gender === "select")) {
          setErrorMsg({ gender: "" });
          if (!(propertyfilterType.term === "select")) {
            setErrorMsg({ term: "" });
            if (!(propertyfilterType.availability === "select")) {
              setErrorMsg({ availability: "" });
              if (!(propertyfilterType.minimum === "")) {
                setErrorMsg({ minimum: "" });
                if (!(propertyfilterType.maximum === "")) {
                  setErrorMsg({ maximum: "" });
                  // =======> api call starts <==========
                  setLoading(true);
                  // filterModal.current.modal("hide");
                  postMultipartWithAuthCallWithErrorResponse(
                    ApiConfig.UNIVERSR_HOUSING_ALL_PROPTIE_FILTER,
                    formData
                  )
                    .then((res) => {
                      setHouseingList(res?.json?.housing_property);
                      setHouseingMange([]);
                      setLoading(false);
                    })
                    .catch((err) => console.log(err));
                  // =======> api call end <==============
                } else {
                  setErrorMsg({
                    maximum: "Please enter  maximum price",
                  });
                }
              } else {
                setErrorMsg({
                  minimum: "Please enter  mininum price",
                });
              }
            } else {
              setErrorMsg({
                availability: "Please enter  availability",
              });
            }
          } else {
            setErrorMsg({
              term: "Please enter term",
            });
          }
        } else {
          setErrorMsg({
            gender: "Please enter  gender",
          });
        }
      } else {
        setErrorMsg({
          propertyType: "Please enter  property type",
        });
      }
    } else {
      setErrorMsg({ type: "Please enter type" });
    }
  };
  const HousingId = (id) => {
    setselectedID(id);
  };
  const ShareHandal = async () => {
    let requestBody = JSON.stringify({
      dynamicLinkInfo: {
        domainUriPrefix: "https://myuniverse.page.link",

        link:
          "https://my-univrs.app.redbytes.in/housing-details/" +
          String(selectedID) +
          "/?user=" +
          String(customerData.id),

        androidInfo: {
          androidPackageName: "com.my_universe",
        },

        iosInfo: {
          iosBundleId: "org.myUniverse",
        },
      },
    });

    await fetch(
      "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBmt92Aby2xlZWOCIHeTutj8ywpF-4reyw",

      {
        method: "POST",

        headers: {
          Accept: "application/json",

          "Content-Type": "application/json",
        },

        body: requestBody,
      }
    )
      .then((response) => response.json())

      .then((data) => {
        console.log("response", data);
        setShare(data.shortLink);
      })

      .catch((err) => {
        // this.setState({isVisible:false})

        console.log("error", err);
      });
  };

  const [showPopup, setShowPopup] = useState(false);

  // Function to toggle popup visibility
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const [clicked,setClicked]=useState(false);

  const handleHeartClick = (event) => {
    setClicked(!clicked)
    event.preventDefault(); // Prevent default action
    event.stopPropagation(); // Stop event propagation
    // Add your logic for what happens when the heart icon is clicked
    console.log('Heart icon clicked');
  };



  return (
    <>
      <div className="row  g-4">
        {loading ? (
          <LoaderCenter/>
        ) : 
        HouseingList && HouseingList.length > 0 ?
         (  
          <div style={{ width: "1120px", padding: "20px", margin: "0 auto" }}>
          <div style={{ display: "flex", flexWrap: "wrap", gap:"25px" }}>
            {HouseingList.map((house, index) => (
              <CardView key={index} house={house} index={index} />
            ))}
          </div>
        </div>
        ) 
        : (
          <p
            style={{
              fontSize: "20px",
              marginTop: "200px",
            }}
            className=" text-center justify-content-center align-items-center"
          >
            Oops! It doesn't look like there are any properties in your area.
            <br /> Why don't you try expanding your search criteria?
          </p>
        )
        }
      </div>

      {/* modal start */}
      <div
        // show={false}
        ref={filterModal}
        class="modal fade "
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered  "
          style={{
            width: "80%",
            maxWidth: "656px",
          }}
        >
          <div
            class="modal-content "
            style={{
              padding: "20px",
              borderRadius: "20px",
            }}
          >
            <div
              class="modal-header p-0"
              style={{ borderBottom: "none", marginBottom: "-25px" }}
            >
              <p
                class="modal-title"
                id="exampleModalLabel"
                style={{
                  color: "black",
                  fontSize: "20px",
                  fontFamily: "Nunito",
                  color: "#1D1D25",
                  fontWeight: "bold",
                }}
              >
                Sort Housing by
              </p>
            </div>
            <div class="modal-body p-0 mt-4">
              <div class="col-md-12 ">
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <div className="col-lg-12" style={{ marginTop: "15px" }}>
                        <div className="uploadPP_inputTextRow usalabel">
                          <label
                            style={{
                              fontSize: "14px",
                              fontFamily: "Nunito",
                              color: "#707070",
                              // fontWeight: "bold",
                            }}
                            class="form-label"
                          >
                            {" "}
                            Post Type
                          </label>
                          <div className="upp_selectWrapper">
                            <select
                              value={propertyfilterType.postType}
                              onChange={(e) => {
                                setPropertyFilterType({
                                  ...propertyfilterType,
                                  postType: e.target.value,
                                });
                              }}
                              style={{
                                backgroundColor: "#ffffff",
                                width: "100%",
                                height: "50px",
                                color: "#1D1D25",
                                fontSize: "16px",
                                fontWeight: "600",
                              }}
                              class="form-select upp_selectWrapper-form-select"
                              aria-label="Default select example"
                            >
                              <option value="select">Select Type</option>
                              <option value="individual">Individual</option>
                              <option value="room share">Room share</option>
                            </select>
                            <small
                              style={{
                                color: "red",
                              }}
                            >
                              {errorMsg.type}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12" style={{ marginTop: "15px" }}>
                        <div className="uploadPP_inputTextRow usalabel">
                          <label
                            style={{
                              fontSize: "14px",
                              fontFamily: "Nunito",
                              color: "#707070",
                              // fontWeight: "bold",
                            }}
                            class="form-label"
                          >
                            Gender
                          </label>
                          <div className="upp_selectWrapper">
                            <select
                              onChange={(e) => {
                                setPropertyFilterType({
                                  ...propertyfilterType,
                                  gender: e.target.value,
                                });
                              }}
                              style={{
                                backgroundColor: "#ffffff",
                                height: "50px",
                                color: "#1D1D25",
                                fontSize: "16px",
                                fontWeight: "600",
                              }}
                              class="form-select upp_selectWrapper-form-select"
                              aria-label="Default select example"
                            >
                              <option value="select">Select Gender</option>
                              <option value="male only">Male only</option>
                              <option value="Female only">Female only</option>
                              <option value="Mixed">Mixed</option>
                              <option value="LGBTQ+">LGBTQ+</option>
                            </select>
                            <small
                              style={{
                                color: "red",
                              }}
                            >
                              {errorMsg.gender}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12" style={{ marginTop: "15px" }}>
                        <div className="uploadPP_inputTextRow usalabel">
                          <label
                            style={{
                              fontSize: "14px",
                              fontFamily: "Nunito",
                              color: "#707070",
                              // fontWeight: "bold",
                            }}
                            class="form-label"
                          >
                            Availabilty
                          </label>
                          <div className="upp_selectWrapper">
                            <select
                              onChange={(e) => {
                                setPropertyFilterType({
                                  ...propertyfilterType,
                                  availability: e.target.value,
                                });
                              }}
                              style={{
                                backgroundColor: "#ffffff",
                                height: "50px",
                                color: "#1D1D25",
                                fontSize: "16px",
                                fontWeight: "600",
                              }}
                              class="form-select upp_selectWrapper-form-select"
                              aria-label="Default select example"
                            >
                              <option value="select">Select Availabilty</option>
                              <option value="Immediately">Immediately</option>

                              <option value="Within 1 week">
                                Within 1 week
                              </option>
                              <option value="Within 1 months">
                                Within 1 months
                              </option>
                              <option value="Within 3 months">
                                Within 3 months
                              </option>
                            </select>
                            <small
                              style={{
                                color: "red",
                              }}
                            >
                              {errorMsg.availability}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div className="col-lg-12" style={{ marginTop: "15px" }}>
                        <div className="uploadPP_inputTextRow usalabel">
                          <label
                            style={{
                              fontSize: "14px",
                              fontFamily: "Nunito",
                              color: "#707070",
                              // fontWeight: "bold",
                            }}
                            class="form-label"
                          >
                            Property Type
                          </label>
                          <div className="upp_selectWrapper">
                            <select
                              onChange={(e) => {
                                setPropertyFilterType({
                                  ...propertyfilterType,
                                  propertyType: e.target.value,
                                });
                              }}
                              style={{
                                backgroundColor: "#ffffff",
                                height: "50px",
                                color: "#1D1D25",
                                fontSize: "16px",
                                fontWeight: "600",
                              }}
                              class="form-select upp_selectWrapper-form-select"
                              aria-label="Default select example"
                            >
                              <option value="select">
                                Select Property Type
                              </option>
                              <option value="flat">Flat</option>
                              <option value="house">House</option>
                              <option value="studio">Studio</option>
                            </select>
                            <small
                              style={{
                                color: "red",
                              }}
                            >
                              {errorMsg.propertyType}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12" style={{ marginTop: "15px" }}>
                        <div className="uploadPP_inputTextRow usalabel">
                          <label
                            style={{
                              fontSize: "14px",
                              fontFamily: "Nunito",
                              color: "#707070",
                              // fontWeight: "bold",
                            }}
                            class="form-label"
                          >
                            Term
                          </label>
                          <div className="upp_selectWrapper">
                            <select
                              onChange={(e) => {
                                setPropertyFilterType({
                                  ...propertyfilterType,
                                  term: e.target.value,
                                });
                              }}
                              style={{
                                backgroundColor: "#ffffff",
                                height: "50px",
                                color: "#1D1D25",
                                fontSize: "16px",
                                fontWeight: "600",
                              }}
                              class="form-select upp_selectWrapper-form-select"
                              aria-label="Default select example"
                            >
                              <option value="select">Select Term</option>
                              <option value="Short-term (6 months or less)">
                                Short-term (6 months or less)
                              </option>
                              <option value="Long-term (over 6 months)">
                                Long-term (over 6 months)
                              </option>
                            </select>
                            <small
                              style={{
                                color: "red",
                              }}
                            >
                              {errorMsg.term}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div
                          className="uploadPP_inputTextRow usalabel"
                          style={{ marginTop: "15px" }}
                        >
                          <label
                            style={{
                              fontSize: "14px",
                              fontFamily: "Nunito",
                              color: "#707070",
                              // fontWeight: "bold",
                            }}
                            class="form-label"
                          >
                            Budget
                          </label>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <div>
                              <input
                                type="text"
                                placeholder="Minimum"
                                class="col-md-12"
                                onChange={(e) => {
                                  setPropertyFilterType({
                                    ...propertyfilterType,
                                    minimum: e.target.value,
                                  });
                                }}
                                style={{
                                  padding: "10px",
                                  fontSize: "12px",
                                  backgroundColor: "#ffffff",
                                  border: "none",
                                  borderRadius: "8px",
                                  border: "1px solid #E1E1E1",
                                  width: "86px",
                                  textAlign: "center",
                                  height: "50px",
                                  color: "#1D1D25",
                                  fontWeight: "600",
                                }}
                              />
                            </div>
                            <br />

                            <div>
                              <p
                                style={{
                                  alignItems: "center",
                                  marginTop: "7px",
                                  padding: "0 10px",
                                }}
                              >
                                to
                              </p>
                            </div>
                            <div
                              // className="upp_selectWrapper"
                              style={{ backgroundColor: "white" }}
                            >
                              <input
                                type="text"
                                onChange={(e) => {
                                  setPropertyFilterType({
                                    ...propertyfilterType,
                                    maximum: e.target.value,
                                  });
                                }}
                                placeholder="Maximum"
                                class="col-md-12"
                                style={{
                                  padding: "10px",
                                  fontSize: "12px",
                                  backgroundColor: "#ffffff",
                                  border: "none",
                                  borderRadius: "8px",
                                  border: "1px solid #E1E1E1",
                                  width: "86px",
                                  textAlign: "center",
                                  height: "50px",
                                  color: "#1D1D25",
                                  fontWeight: "600",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <small
                          style={{
                            color: "red",
                          }}
                        >
                          {errorMsg.minimum}
                        </small>
                        <small
                          style={{
                            color: "red",
                          }}
                        >
                          {errorMsg.maximum}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <label
                style={{
                  fontSize: "14px",
                  fontFamily: "Nunito",
                  color: "#707070",
                  // fontWeight: "bold",
                  marginTop: "20px",
                }}
                className="form-label"
              >
                Term
              </label>
              <div className="row ">
                <div className="col-12 col-md-4">
                  <div className="house-radio">
                    <input
                      // onChange={(e) => {
                      //   setPropertyFilterType({
                      //     ...propertyfilterType,
                      //     bills_included: e.target.value,
                      //   });
                      // }}
                      type="checkbox"
                      name="l1"
                      id="l1"
                      onChange={(e) => {
                        e?.target?.checked
                          ? setPropertyFilterType({
                            ...propertyfilterType,
                            bills_included: "Yes",
                          })
                          : setPropertyFilterType({
                            ...propertyfilterType,
                            bills_included: "No",
                          });
                      }}
                    />
                    <label htmlFor="l1">Bills Included</label>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="house-radio">
                    <input
                      // onChange={(e) => {
                      //   setPropertyFilterType({
                      //     ...propertyfilterType,
                      //     smokers_allowed: e.target.value,
                      //   });
                      // }}
                      type="checkbox"
                      name="l2"
                      id="l2"
                      onChange={(e) => {
                        e?.target?.checked
                          ? setPropertyFilterType({
                            ...propertyfilterType,
                            smokers_allowed: "Yes",
                          })
                          : setPropertyFilterType({
                            ...propertyfilterType,
                            smokers_allowed: "No",
                          });
                      }}
                    />
                    <label htmlFor="l2">Smokers Allowed</label>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="house-radio">
                    <input
                      type="checkbox"
                      name="l3"
                      id="l3"
                    // onChange={(e) => {
                    //   e?.target?.checked
                    //     ? setPropertyFilterType({
                    //         ...propertyfilterType,
                    //         smokers_allowed: "Yes",
                    //       })
                    //     : setPropertyFilterType({
                    //         ...propertyfilterType,
                    //         smokers_allowed: "No",
                    //       });
                    // }}
                    />
                    <label htmlFor="l3">Pets Allowed</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer ">
              <div
                className="usa-btn"
                style={{
                  marginTop: "0px",
                  marginBottom: "-10px",
                  width: "100%",
                }}
              >
                <Link
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="primary"
                  to="#"
                  onClick={propertyFilter}
                  style={{
                    padding: "13px",
                    width: "130px",
                    backgroundColor: "#00B4BD",
                  }}
                >
                  Apply
                </Link>
              </div>
            </div>
          </div>
          <Modal
            className="custom-dialog"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showModal}
            onHide={handleClose}
          >
            <Modal.Header style={{ padding: "12px", width: "99%" }} closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* Are you sure you want to cancel this Friend Request? */}
              <div class="modal-body" style={{ marginTop: "-37px" }}>
                <ShareSocial
                  style={style}
                  url={ShareData}
                  socialTypes={[
                    "facebook",
                    "twitter",
                    "reddit",
                    "linkedin",
                    "email",
                    "pinterest",
                  ]}
                />
                <CopyToClipboard
                  text={ShareData}
                  onCopy={() => swal("Copied to Clipboard")}
                >
                  <Link to="#" className="copy-icon">
                    <i class="fa-solid fa-copy"></i>
                  </Link>
                </CopyToClipboard>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default InterestedEvents;


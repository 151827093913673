// //
// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import {
//   getAuth,
//   GoogleAuthProvider,
//   signInWithPopup,
//   FacebookAuthProvider,
// } from "firebase/auth";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyAf--ugsYW-ad9-0SHaMoiwt-VWbEv-_dg",
//   authDomain: "my-universe-web.firebaseapp.com",
//   projectId: "my-universe-web",
//   storageBucket: "my-universe-web.appspot.com",
//   messagingSenderId: "610244703809",
//   appId: "1:610244703809:web:da3316b0f33aef69b278c0",
//   measurementId: "G-8FJQVJ670Z",
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);

// export const auth = getAuth(app);
// auth.languageCode = "it";
// const provider = new GoogleAuthProvider();
// const facebook = new FacebookAuthProvider();
// export const signInWithGoogle = () => signInWithPopup(auth, provider);
// export const signInWithFaceBook = () => signInWithPopup(auth, facebook);

// Import the functions you need from the SDKs you need

import { initializeApp } from 'firebase/app'
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,

} from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyC6bdDl5OcxFYY7dZzgrGVRqKib--zWMx4',
  authDomain: 'my-universe-web-a18d1.firebaseapp.com',
  projectId: 'my-universe-web-a18d1',
  storageBucket: 'my-universe-web-a18d1.appspot.com',
  messagingSenderId: '957209965984',
  appId: '1:957209965984:web:488dffc5ad04f18cb0a0c5',
  measurementId: 'G-T7LTQYKZST',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
console.log('app', app)
export const auth = getAuth(app)
auth.languageCode = 'it'
const provider = new GoogleAuthProvider()
const facebook = new FacebookAuthProvider()
export const signInWithGoogle = () => signInWithPopup(auth, provider)
export const signInWithFaceBook = () => signInWithPopup(auth, facebook)

import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import thumbImg from "../../assets/images/watch.png";
import back from "../../assets/images/back.svg";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/AppContext";
import LoaderCenter from "../../sharedComponent/LoaderCenter";
import {
  deleteWithAuthCall,
  simpleGetCallWithErrorResponse,
  simplePostCall,
} from "../../api/ApiServices";
import swal from "sweetalert";

const MyWishlistFashion = () => {
  const { customerData } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [prouctssearch, setProuctsSearch] = useState([]);
  const [healthwishlist, setHealthWishlist] = useState([]);
  

  useEffect(() => {
    getFashinWish();
  }, []);

  const getFashinWish = () => {
    setLoading(true);

    simpleGetCallWithErrorResponse(
      ApiConfig.FASHIN_MYWISHILIST,
      JSON.stringify({ ...customerData })
    ).then((res) => {
      console.log(res);
      setLoading(false);
      setHealthWishlist(res.json.details);
    });
  };

  // const healthUnSave = (id) => {
  //   simplePostCall(
  //     ApiConfig.LIKE_DISLIKE_HEALTH_PRODUCT,
  //     JSON.stringify({ ...customerData, product_id: id })
  //   )
  //     .then((res) => {
  //       console.log(res);

  //       swal(res.detail);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

    const DeleteProduct = (id) => {
      deleteWithAuthCall(ApiConfig.DELET_FASHIN + id)
        .then((res) => {
          console.log(res);
          swal(res.detail);
          getFashinWish();
        })
        .catch((err) => {
          setLoading(false);

          console.log(err);
        });
    };
  return (
    <>
      {loading ? (
        <LoaderCenter />
      ) : (
        <div className="row row-gap-20">
          {healthwishlist && healthwishlist.length > 0 ? (
            healthwishlist.map((healthwishlistAll, index) => {
              
              return (
                <div className="col-12 col-sm-6  col-lg-4 col-xxl-3">
                  <div
                    className="gbl_thumbWrapper"
                    style={{
                      position: "relative",
                    }}
                  >
                    {/* <label style={{
                      
                    }} className="heart-check">
                      <input
                        // onClick={() => {
                        //   healthSave(healthproductsAll.id);
                        // }}
                        class="check"
                        type="checkbox"
                      />
                      <span class="label-text"></span>
                    </label> */}
                    <button
                      class="eventMenu"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        zIndex: "1000",
                      }}
                    >
                      <i class="fa-solid fa-ellipsis-vertical"></i>
                    </button>
                    <ul
                      class="dropdown-menu career-drop"
                      aria-labelledby="dropdownMenuButton1"
                      style={{
                        backgroundColor: "#F5F5F5",
                        border: "1px solid #ECECEC",
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                      }}
                    >
                      {/* <li>
                        <Link
                          to="#"
                          onClick={() => {
                            healthUnSave(healthwishlistAll.id);
                          }}
                          class="dropdown-item"
                          href="#"
                        >
                          UnSave
                        </Link>
                      </li> */}

                      <li>
                        <Link
                          onClick={() => {
                            DeleteProduct(healthwishlistAll.id);
                          }}
                          to="#"
                          class="dropdown-item"
                          href="#"
                        >
                          Remove
                        </Link>
                      </li>
                    </ul>

                    <div className="img_thumbWrapper">
                      {/* <img
                        src={healthwishlistAll.product_detail.image}
                        height="158px"
                        alt=""
                        style={{
                          objectFit: "cover",
                        }}
                      /> */}
                      {healthwishlistAll.product_detail.image ? (
                        <img
                          src={healthwishlistAll.product_detail.image}
                          alt=""
                          height="158px"
                          style={{
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <img
                          src={thumbImg}
                          alt=""
                          height="158px"
                          style={{
                            objectFit: "cover",
                          }}
                        />
                      )}
                    </div>

                    <div className="gbl_thumbContent">
                      <h1
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "hsla(240, 12%, 13%, 1)",
                          marginTop: "5px",
                          letterSpacing: "0",
                          font: "normal normal 600 16px/22px Nunito",
                          opacity: "0px",
                        }}
                      >
                        {healthwishlistAll.product_detail.product_name}
                      </h1>
                      <p
                        style={{
                          color: "var(--unnamed-color-707070)",
                          font: "normal normal normal 14px/19px Nunito",
                          letterSpacing: "0px",
                          color: "hsla(0, 0%, 44%, 1)",
                          opacity: "1px",
                          marginTop: "12px",
                        }}
                      >
                        Starting from {healthwishlistAll.product_detail.price}
                        /-
                      </p>
                      <Link  to={`/fashion-details/${healthwishlistAll.product_id}`}>
                        <img
                          style={{
                            marginTop: "-25px",
                            float: "right",
                          }}
                          src={back}
                          alt=""
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <p
              style={{
                fontSize: "25px",
                margin: "auto",
              }}
              className=" text-center justify-content-center"
            >
              Data not found
            </p>
          )}
        </div>
      )}
    </>
  );
};

export default MyWishlistFashion;

import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Sidebar from "../../components/Sidebar";
import GoogleMapReact from "google-map-react";
import swal from "sweetalert";
import {
  getWithAuthCallWithErrorResponse,
  putMultipartWithAuthCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import ImageUplodeEvent from "../../sharedComponent/ImageUplodeEvent";
import Barloader from '../../sharedComponent/BarLoader'


function UpdateEvent() {
  const params = useParams();
  const { sidebar, setSidebar, customerData } = useContext(AppContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [eventData, setEventData] = useState({})
  const placeInputRef = useRef(null);
    let [marker, setMarker] = useState(null)
  const [circle, setCircle] = useState(null)
  const [locationData, setLocationData] = useState({})
  const [place, setPlace] = useState({
    center: { lat: 19.8762, lng: 75.3433 },
  });

  let id = params.id;
  const [updateEventdata, setUpdateevent] = useState({
    name: "",
    event_type: "",
    address: "",
    ticket_price: "",
    no_of_tickets: "",
    description: "",
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
    lat: "",
    long: "",
    image: [],
  });
  const [errMsg, setErrMsg] = useState({
    name: "",
    event_type: "",
    address: "",
    ticket_price: "",
    no_of_tickets: "",
    description: "",
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
    image: "",
  });

  

  useEffect( () => {
    initPlaceAPI();
  }, []);
  const initPlaceAPI = async () => {
    if (
      window.google &&
      window.google.maps &&
      window.google.maps.places &&
      window.google.maps.places.Autocomplete
    ) {
      let autocomplete = new window.google.maps.places.Autocomplete(
        placeInputRef.current
      );
      new window.google.maps.event.addListener(
        autocomplete,
        "place_changed",
        function () {
          let place = autocomplete.getPlace();

           setEventData({
            ...eventData,
            address: place.name,
            center: {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            },
          });

          setPlace({
            center: {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            },
            address: place.formatted_address,
          });
        }
      );
    }
  };

  useEffect(() => {
   
  }, [place])
  
   useEffect(() => {
    if (locationData.maps && locationData.map) {
      const { map, maps } = locationData;
      // or else call that isApiLoaded function and pass-on these arguments
      renderMarkers(map, maps);
    }
  }, [locationData, place]);
    const getLocation = (lat, lng) => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBxqcBX2r8cybljkDigXBgyOByjeQOmqr0`,
      {
        method: "GET", // or 'PUT'
        headers: {
          // "Content-Type": "application/json",
          // 'Access-Control-Allow-Credentials': "*"
        },
        //body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data.results[1].formatted_address);
        setPlace({
          center: { lat, lng },
          address: data.results[1].formatted_address,
        });
        
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const renderMarkers = (map, maps) => {

    marker && marker.setMap(null);
    circle && circle.setMap(null);
    const cityCircle = new maps.Circle({
      strokeColor: "#00b4bd",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#00b4bd",
      fillOpacity: 0.35,
      map,
      center: place.center,
      // radius: { ...place.center.radius },
      radius: place.radius ? Number(place.radius) : 1000,
    });
    marker = new maps.Marker({
      draggable: true,
      position: { ...place.center },
      map,
    });
    maps.event.addListener(marker, "dragend", async function (event) {
      getLocation(event.latLng.lat(), event.latLng.lng());
    });
    setMarker(marker);
    setCircle(cityCircle);

    return { marker, cityCircle };
  };

  useEffect(() => {
    geCreateDetails();
  }, []);
  const geCreateDetails = () => {

    setLoading(true);
    getWithAuthCallWithErrorResponse(
      ApiConfig.EVENT_GET + id + "/",
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        setLoading(false);
        console.log(res);

        let user_profile = res.json.event;

        setUpdateevent({
          name: user_profile.name,
          description: user_profile.description,
          address: user_profile.address,
          end_date: user_profile.end_date,
          end_time: user_profile.end_time,
          event_type: user_profile.event_type,
          no_of_tickets: user_profile.no_of_tickets,
          start_date: user_profile.start_date,
          start_time: user_profile.start_time,
          ticket_price: user_profile.ticket_price,
          image: user_profile.image,
        });
          setPlace({
          address:user_profile.address,
          center: { lat: Number(user_profile.lat), lng: Number(user_profile.long) },
        });
  
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const UpdateEvent = (e) => {
    if (
      updateEventdata.name === "" ||
      updateEventdata.description === "" ||
      updateEventdata.address === "" ||
      !updateEventdata.image === "" ||
      updateEventdata.event_type === "" ||
      updateEventdata.ticket_price === "" ||
      updateEventdata.no_of_tickets === ""
    ) {
      if (!updateEventdata.name) {
        setErrMsg({
          ...errMsg,
          name: "Please Enter Event Name.",
        });
        window.scrollTo(0, 0);
        return;
      }
      if (updateEventdata.description === "") {
        setErrMsg({ ...errMsg, description: "Please Enter Description." });
        window.scrollTo(0, 0);
        return;
      }
      if (updateEventdata.address === "") {
        setErrMsg({ ...errMsg, address: "Please Enter Address." });
        window.scrollTo(0, 0);
        return;
      }

      if (!updateEventdata.image === "") {
        setErrMsg({
          ...errMsg,
          image: "Please Enter Event Image",
        });
        window.scrollTo(0, 0);
        return;
      }
      if (updateEventdata.event_type === "") {
        setErrMsg({
          ...errMsg,
          event_type: "Please Enter Event Type",
        });
        window.scrollTo(0, 0);
        return;
      }
      if (updateEventdata.ticket_price === "") {
        setErrMsg({
          ...errMsg,
          ticket_price: "Please Enter Event Ticket  Price",
        });
        window.scrollTo(0, 0);
        return;
      }
      if (updateEventdata.ticket_price === "") {
        setErrMsg({
          ...errMsg,
          ticket_price: "Please Enter Event Ticket  Price",
        });
        window.scrollTo(0, 0);
        return;
      }
      if (updateEventdata.no_of_tickets === "") {
        setErrMsg({
          ...errMsg,
          no_of_tickets: "Please Enter Event  Number Ticket ",
        });
        window.scrollTo(0, 0);
        return;
      }
    } else {
    
      setLoading(true);
      let formData = new FormData();
 
      formData.append("event_name", updateEventdata.name);
      formData.append("event_type", updateEventdata.event_type);
      formData.append("event_description", updateEventdata.description);
      formData.append("address", place.address);
      formData.append("start_date", updateEventdata.start_date);
      formData.append("start_time", updateEventdata.start_time);
      formData.append("end_date", updateEventdata.end_date);
      formData.append("end_time", updateEventdata.end_time);
      formData.append("ticket_price", updateEventdata.ticket_price);
      formData.append("no_of_tickets", updateEventdata.no_of_tickets);
      formData.append("lat", place.center.lat);
      formData.append("long",place.center.lng);

      formData.append(
        "image",
        updateEventdata.image &&
          updateEventdata.image.length &&
          updateEventdata.image.map((data) => {
            formData.append("image", data);
          })
      );
      putMultipartWithAuthCallWithErrorResponse(
        ApiConfig.UNIVERSR_UPDATE_EVENT + id + "/",
        formData
      )
        .then((res) => {
          setLoading(false);
          swal("Update Event Successfully");
          navigate("/events/all");
        })
        .catch((err) => {
          setLoading(false)

          console.log(err)
        })
    }
  };

  const removeImage = (index) => {
    let image = updateEventdata.image;
   
    image = image.filter((img) => image.indexOf(img) != index);

    setUpdateevent({ ...updateEventdata, image: image });
  };

  return (
    <>
      <Sidebar />

      {/* <Header /> */}
      <main id="cxMain" className={sidebar ? "cx-main cx-active" : "cx-main"}>
        <section
          className="content-main"
          style={{
            borderRadius: "24px",
            background: "#fff",
            padding: "20px",
            height: "fit-content",
          }}
        >
           {loading && <Barloader />}
          <p className="ce-heading">Update an Event</p>
          <div className="row">
            <div className="col-12">
              <label htmlFor="name" className="ce-label">
                Event Name
              </label>
              <input
                value={updateEventdata.name}
                type="text"
                name="name"
                id="name"
                className="ce-input"
                placeholder="Enter event Name"
                onChange={(e) => {
                  setErrMsg({ ...errMsg, name: "" });

                  setUpdateevent({
                    ...updateEventdata,
                    name: e.target.value,
                  });
                }}
              />
            </div>
            {errMsg.name.length > 0 && (
              <div className="text-danger">
                <span> {errMsg.name}</span>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <label htmlFor="desc" className="ce-label">
                Description
              </label>
              <textarea
                value={updateEventdata.description}
                type="text"
                name="desc"
                id="desc"
                className="ce-input ce-lg-input "
                placeholder="Describe your event"
                onChange={(e) => {
                  setErrMsg({ ...errMsg, description: "" });

                  setUpdateevent({
                    ...updateEventdata,
                    description: e.target.value,
                  });
                }}
              />
            </div>
            {errMsg.description.length > 0 && (
              <div className="text-danger">
                <span> {errMsg.description}</span>
              </div>
            )}
            <div className="col-12 col-md-6 col-lg-3">
              <label htmlFor="desc" className="ce-label">
                Address
              </label>
              <textarea
                type="text"
                ref={placeInputRef}
                value={place.address}
                name="desc"
                id="desc"
                className="ce-input ce-lg-input "
                placeholder="Enter location here"
                onChange={(e) => {
                  setErrMsg({ ...errMsg, address: "" });
                  setPlace({
                    address: e.target.value,
                  })
                  setEventData({
                    ...updateEventdata,
                    address: e.target.value,
                  });
                }}
              />
            </div>
            {errMsg.address.length > 0 && (
              <div className="text-danger">
                <span> {errMsg.address}</span>
              </div>
            )}
            <div className="col-12 col-lg-3">
              <label htmlFor="desc" className="ce-label">
                {""}
              </label>      
              <GoogleMapReact
                              bootstrapURLKeys={{
                                key: 'AIzaSyBxqcBX2r8cybljkDigXBgyOByjeQOmqr0',
                              }}
                              center={place.center}
                              defaultZoom={11}
                              onGoogleApiLoaded={({ map, maps }) => {
                                // renderMarkers({ map, maps });
                                setLocationData({ map, maps })
                              }}
                            />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 col-xl-3">
              <label htmlFor="name" className="ce-label">
                Event Start Date
              </label>
              <input
                value={updateEventdata.start_date}
                type="date"
                name="name"
                id="name"
                className="ce-input ce-center"
                placeholder="DD/MM/YYYY"
                onChange={(e) => {
                  // setErrMsg({ ...errMsg, start_date: "" });

                  setUpdateevent({
                    ...updateEventdata,
                    start_date: e.target.value,
                  });
                }}
              />
            </div>
            {/* {errMsg.start_date.length > 0 && (
              <div className="text-danger">
                <span> {errMsg.start_date}</span>
              </div>
            )} */}
            <div className="col-12 col-md-6 col-xl-3">
              <label htmlFor="name" className="ce-label">
                Event Start Time
              </label>
              <input
                value={updateEventdata.start_time}
                type="time"
                name="name"
                id="name"
                className="ce-input ce-center"
                placeholder="HH:MM"
                onChange={(e) => {
                  // setErrMsg({ ...errMsg, start_time: "" });

                  setUpdateevent({
                    ...updateEventdata,
                    start_time: e.target.value,
                  });
                }}
              />
            </div>
            {/* {errMsg.start_time.length > 0 && (
              <div className="text-danger">
                <span> {errMsg.start_time}</span>
              </div>
            )} */}
            <div className="col-12 col-md-6 col-xl-3">
              <label htmlFor="name" className="ce-label">
                Event End Date
              </label>
              <input
                value={updateEventdata.end_date}
                type="date"
                name="name"
                id="name"
                className="ce-input ce-center"
                placeholder="DD/MM/YYYY"
                onChange={(e) => {
                  // setErrMsg({ ...errMsg, end_date: "" });

                  setUpdateevent({
                    ...updateEventdata,
                    end_date: e.target.value,
                  });
                }}
              />
            </div>
            {/* {errMsg.end_date.length > 0 && (
              <div className="text-danger">
                <span> {errMsg.end_date}</span>
              </div>
            )} */}
            <div className="col-12 col-md-6 col-xl-3">
              <label htmlFor="name" className="ce-label">
                Event End Time
              </label>
              <input
                value={updateEventdata.end_time}
                type="time"
                name="name"
                id="name"
                className="ce-input ce-center"
                placeholder="HH:MM"
                onChange={(e) => {
                  // setErrMsg({ ...errMsg, end_time: "" });

                  setUpdateevent({
                    ...updateEventdata,
                    end_time: e.target.value,
                  });
                }}
              />
            </div>
            {/* {errMsg.end_time.length > 0 && (
              <div className="text-danger">
                <span> {errMsg.end_time}</span>
              </div>
            )} */}
          </div>
          <div className="row">
            <div className="col-12 col-xl-2">
              <ImageUplodeEvent
                setFile={(files) => {
                  console.log(files);
                  setErrMsg({ ...errMsg, image: "" });

                  setUpdateevent({
                    ...updateEventdata,
                    image: [...files],
                  });
                }}
              />
              {errMsg.image.length > 0 && (
                <div className="text-danger">
                  <span> {errMsg.image}</span>
                </div>
              )}
            </div>
            {updateEventdata.image.map((img, index) => {
              return (
                <div
                  className="col-12 col-sm-6 col-xl-2"
                  style={{
                    position: "relative",
                  }}
                >
                  <img
                    width={148}
                    height="148"
                    className="vehicle_img"
                    src={
                      updateEventdata.image[0].name
                        ? URL.createObjectURL(updateEventdata.image[0])
                        : img
                    }
                    alt=""
                    style={{
                      marginTop: "10px",
                      borderRadius: "10px",
                    }}
                  />
                  <a
                    style={{
                      position: "absolute",
                      top: "20px",
                      right: "24px",
                      padding: "10px",
                      backgroundColor: "white",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "34px",
                      height: "34px",
                    }}
                    className=""
                  >
                    <i
                      onClick={() => removeImage(index)}
                      class="fa-solid fa-xmark"
                    ></i>
                  </a>
                </div>
              );
            })}
            <div className="col-12 col-xl-2">
              <label htmlFor="name" className="ce-label">
                Is event paid or free?
              </label>
              <select
                value={updateEventdata.event_type}
                onChange={(e) => {
                  setErrMsg({ ...errMsg, event_type: "" });

                  setUpdateevent({
                    ...updateEventdata,
                    event_type: e.target.value,
                  });
                }}
                class="form-select ce-input"
                aria-label="Default select example"
              >
                <option value="Paid">Paid</option>
                <option value="free">Free</option>
              </select>
            </div>
            {errMsg.event_type.length > 0 && (
              <div className="text-danger">
                <span> {errMsg.event_type}</span>
              </div>
            )}
            <div className="col-12 col-xl-2">
              <label htmlFor="name" className="ce-label">
                Event Ticket Price
              </label>
              <input
                value={updateEventdata.ticket_price}
                type="text"
                name="name"
                id="name"
                className="ce-input ce-center"
                placeholder="£ AMOUNT"
                onChange={(e) => {
                  setErrMsg({ ...errMsg, ticket_price: "" });

                  setUpdateevent({
                    ...updateEventdata,
                    ticket_price: e.target.value,
                  });
                }}
              />
            </div>
            {errMsg.ticket_price.length > 0 && (
              <div className="text-danger">
                <span> {errMsg.ticket_price}</span>
              </div>
            )}
            <div className="col-12 col-xl-2">
              <label htmlFor="name" className="ce-label">
                No. of Tickets
              </label>
              <input
                value={updateEventdata.no_of_tickets}
                type="text"
                name="name"
                id="name"
                className="ce-input ce-center"
                placeholder="NO. OF TICKETS"
                onChange={(e) => {
                  setErrMsg({ ...errMsg, no_of_tickets: "" });

                  setUpdateevent({
                    ...updateEventdata,
                    no_of_tickets: e.target.value,
                  });
                }}
              />
            </div>
            {errMsg.no_of_tickets.length > 0 && (
              <div className="text-danger">
                <span> {errMsg.no_of_tickets}</span>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
              <button className="cancel">Cancel</button>
            </div>
            <div className="col-12 col-sm-6 d-flex align-items-center justify-content-center mt-3 mt-sm-0 justify-content-sm-start  ">
              <button className="create" onClick={UpdateEvent}>
                Update Event
              </button>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default UpdateEvent;

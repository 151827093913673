import React, { useContext, useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ApiConfig from '../../api/ApiConfig'
import { AppContext } from '../../context/AppContext'
import LoaderCenter from '../../sharedComponent/LoaderCenter'
import close from '../../assets/images/close.svg'
import swal from 'sweetalert'
import Modal from 'react-bootstrap/Modal'

import {
  simpleGetCallWithErrorResponse,
  multipartPostCall,
} from '../../api/ApiServices'

function PlaceOrder() {
  // ==========react strap modal starts
  // ==========================================
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  // ==========react strap modal ends
  // ==========================================

  const { setAdreeschecked, adreeschecked } = useContext(AppContext)
  

  const currentRoute = useLocation().pathname
  const [adressDetails, setAdreesDetails] = useState([])
  const navigate = useNavigate()
  const { customerData } = useContext(AppContext)
  const [showElement, setShowElement] = React.useState(true)
  const [loading, setLoading] = useState(false)
  const [addAdrees, setAdrees] = useState({
    full_name: '',
    mobile_number: '',
    pincode: '',
    address: '',
    city: '',
    card_number: '',
  })

  const [errMsg, setErrMsg] = useState({
    full_name: '',
    mobile_number: '',
    pincode: '',
    address: '',
    city: '',
    card_number: '',
  })

  useEffect(() => {
    getAddress()
  }, [])

  // useEffect(() => {
  //   let cartids = adreeschecked.map((cartData) => {
  //     return cartData.id
  //   })
  //   console.log('cartids', cartids)
  // }, [adreeschecked])

  const getAddress = () => {
    setLoading(true)
    simpleGetCallWithErrorResponse(
      ApiConfig.OFFER_ADDRES,

      JSON.stringify({ ...customerData }),
    ).then((res) => {
      console.log(res)
      setLoading(false)
      setAdreesDetails(res.json.address_list)
    })
  }

  useEffect(() => {
    setTimeout(function () {
      setShowElement(false)
    }, 5000)
  }, [])

  const AddCreateAdrees = (e) => {
    // if (
    //   addAdrees.full_name === '' ||
    //   addAdrees.mobile_number === '' ||
    //   addAdrees.pincode === '' ||
    //   addAdrees.address === ' ' ||
    //   addAdrees.city === '' ||
    //   addAdrees.card_number === ''
    // ) {
    //   if (!addAdrees.full_name) {
    //     setErrMsg({
    //       ...errMsg,
    //       full_name: 'Please Enter Eventname.',
    //     })
    //     window.scrollTo(0, 0)
    //     return
    //   }

    //   if (addAdrees.mobile_number === '') {
    //     setErrMsg({
    //       ...errMsg,
    //       mobile_number: 'Please Enter   Number  ',
    //     })
    //     window.scrollTo(0, 0)
    //     return
    //   }
    //   if (addAdrees.pincode === '') {
    //     setErrMsg({
    //       ...errMsg,
    //       pincode: 'Please Enter Pin Code  Number  ',
    //     })
    //     window.scrollTo(0, 0)
    //     return
    //   }
    //   if (addAdrees.address === '') {
    //     setErrMsg({
    //       ...errMsg,
    //       address: 'Please Enter Address  ',
    //     })
    //     window.scrollTo(0, 0)
    //     return
    //   }
    //   if (addAdrees.city === '') {
    //     setErrMsg({
    //       ...errMsg,
    //       city: 'Please Enter city  ',
    //     })
    //     window.scrollTo(0, 0)
    //     return
    //   }
    // } else {
    setLoading(true)
    let formData = new FormData()
    formData.append('full_name', addAdrees.full_name)
    formData.append('mobile_number', addAdrees.mobile_number)
    formData.append('pincode', addAdrees.pincode)
    formData.append('address', addAdrees.address)
    formData.append('city', addAdrees.city)

    multipartPostCall(ApiConfig.ADD_ADDRES, formData)
      .then((res) => {
        handleClose()
        setLoading(false)
        swal('Address Added Successfully')
        getAddress()
        navigate('/health/PlaceOrder')
      })
      .catch((err) => {
        setLoading(false)

        console.log(err)
      })
    // }
  }

  const handleCart = () => {
    if (adreeschecked.length === 0) {
      swal('Please Select the Address')
    } else {
      navigate('/health/Payment-Page')
    }
  }


  //  Payment Method  ////

  return (
    <>
      {loading ? (
        <LoaderCenter />
      ) : (
        <div
          className=" row"
          style={{
            maxWidth: '685px',
            margin: '0 auto',
          }}
        >
          <h6
            style={{
              color: 'black',
              marginBottom: '10px',
              letterSpacing: '0px',
              font: 'normal normal bold 20px/27px Nunito',
            }}
          >
            Delivery Address
          </h6>

          {adressDetails && adressDetails.length > 0 ? (
            adressDetails.map((adressDetailsAll, index) => {
              return (
                <div className="col-12 col-lg-6">
                  <div
                    className="gbl_thumbWrapper-card-oder"
                    style={{
                      borderRadius: '10px',
                    }}
                  >
                    <div className="gbl_thumbContentoder">
                      <h1
                        style={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: 'hsla(240, 12%, 13%, 1)',
                          marginTop: '5px',
                          letterSpacing: '0',
                          font: 'normal normal 600 16px/22px Nunito',
                          opacity: '0px',
                        }}
                      >
                        {adressDetailsAll.full_name}
                      </h1>
                      <div style={{ float: 'right', marginTop: '10px' }}>
                        <label
                          key={'odercartlist' + index}
                          value={adressDetailsAll.id}
                        >
                          <input
                            type="checkbox"
                            required
                            class="radio-custom"
                            // checked={
                            //   cartchecked(odercartlist.id)
                            //     ? true
                            //     : false
                            // }
                            onChange={(e) => {
                              if (e.target.checked) {
                                setAdreeschecked([
                                  ...adreeschecked,
                                  adressDetailsAll,
                                ])
                              } else {
                                setAdreeschecked(
                                  adreeschecked.filter(
                                    (data) => data.id !== adressDetailsAll.id,
                                  ),
                                )
                              }
                            }}
                          />
                          <span class="radio-custom-label"></span>
                        </label>
                      </div>
                      <p
                        style={{
                          color: 'var(--unnamed-color-707070)',
                          font: 'normal normal normal 14px/19px Nunito',
                          letterSpacing: '0px',
                          color: 'hsla(0, 0%, 44%, 1)',
                          opacity: '1px',
                          marginTop: '12px',
                        }}
                      >
                        {adressDetailsAll.address}
                        <br /> {adressDetailsAll.city},{' '}
                        {adressDetailsAll.pincode}
                        <br /> Phone Number :- {adressDetailsAll.mobile_number}
                      </p>
                    </div>
                  </div>
                </div>
              )
            })
          ) : (
            <p
              style={{
                fontSize: '20px',
                marginTop: '200px',
              }}
              className=" text-center justify-content-center align-items-center"
            >
              Oops! It doesn't look like there are any Event in your area.
              <br /> Why don't you try expanding your search criteria?
            </p>
          )}

          <div className="col-12">
            <button
              // data-bs-toggle="modal"
              // data-bs-target="#exampleModal"
              onClick={handleShow}
              style={{
                height: '50px',
                border: '1px solid #00B4BD',
                borderRadius: '10px',
                backgroundColor: 'transparent',
                width: '100%',
                color: '#00B4BD',
                fontSize: '16px',
                fontWeight: '600',
              }}
            >
              + Add New Address
            </button>
          </div>

          <div className="col-12 mt-3 d-flex justify-content-center">
            <button
              style={{
                width: '158px',
                height: '50px',
                backgroundColor: '#00B4BD',

                border: 'none',
                borderRadius: '25px',
                color: '#FFFFFF',
                fontSize: '14px',
                fontWeight: '600',
              }}
            >
              <div
                onClick={() => handleCart()}
                style={{
                  display: 'block',
                  height: '100%',
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                Place Order
              </div>
            </button>
          </div>
          {/* /// Modal // */}
          {/* =====>react strap modal starts */}
          {/* ============================== */}

          <Modal show={show} onHide={handleClose} contentClassName="myContent">
            <Modal.Body style={{}}>
              <div class="modal-header" style={{ padding: '0' }}>
                <h5 class="modal-title" id="exampleModalLabel">
                  Add New Address
                </h5>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{
                    height: '34px',
                    width: '34px',
                    backgroundColor: 'white',
                    border: '1px solid #ECECEC',
                    borderRadius: '10px',
                  }}
                >
                  <img src={close} alt="close" />
                </button>
              </div>
              <div className="add-input d-flex flex-column mt-3">
                <label
                  htmlFor=""
                  className="add-label"
                  style={{
                    color: '#707070',
                    fontSize: '14px',
                    fontWeight: 'normal',
                  }}
                >
                  Full Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name "
                  // value={addAdrees.full_name}
                  className="add-input"
                  placeholder="Enter Your Name"
                  style={{
                    height: '50px',
                    backgroundColor: 'white',
                    border: '1px solid #E1E1E1',
                    padding: '0 20px',
                    color: '#1D1D25',
                    fontSize: '16px',
                    fontWeight: '600',
                    borderRadius: '10px',
                  }}
                  onChange={(e) => {
                    setErrMsg({ ...errMsg, full_name: '' })

                    setAdrees({
                      ...addAdrees,
                      full_name: e.target.value,
                    })
                  }}
                />
                <small
                  style={{
                    color: 'red',
                  }}
                >
                  {errMsg.full_name}
                </small>
              </div>
              <div className="add-input d-flex flex-column mt-3">
                <label
                  htmlFor=""
                  className="add-label"
                  style={{
                    color: '#707070',
                    fontSize: '14px',
                    fontWeight: 'normal',
                  }}
                >
                  Mobile Number
                </label>
                <input
                  type="phone "
                  name="name"
                  id="name "
                  // value={addAdrees.mobile_number}
                  className="add-input"
                  placeholder="Enter Your phone"
                  style={{
                    height: '50px',
                    backgroundColor: 'white',
                    border: '1px solid #E1E1E1',
                    padding: '0 20px',
                    color: '#1D1D25',
                    fontSize: '16px',
                    fontWeight: '600',
                    borderRadius: '10px',
                  }}
                  onChange={(e) => {
                    setErrMsg({ ...errMsg, mobile_number: '' })

                    setAdrees({
                      ...addAdrees,
                      mobile_number: e.target.value,
                    })
                  }}
                />
              </div>
              <small
                style={{
                  color: 'red',
                }}
              >
                {errMsg.mobile_number}
              </small>
              <div className="add-input d-flex flex-column mt-3">
                <label
                  htmlFor=""
                  className="add-label"
                  style={{
                    color: '#707070',
                    fontSize: '14px',
                    fontWeight: 'normal',
                  }}
                >
                  Pincode
                </label>
                <input
                  type="text"
                  name="name"
                  id="name "
                  className="add-input"
                  placeholder="Enter Your pincode"
                  style={{
                    height: '50px',
                    backgroundColor: 'white',
                    border: '1px solid #E1E1E1',
                    padding: '0 20px',
                    color: '#1D1D25',
                    fontSize: '16px',
                    fontWeight: '600',
                    borderRadius: '10px',
                  }}
                  onChange={(e) => {
                    setErrMsg({ ...errMsg, pincode: '' })

                    setAdrees({
                      ...addAdrees,
                      pincode: e.target.value,
                    })
                  }}
                />
              </div>
              <small
                style={{
                  color: 'red',
                }}
              >
                {errMsg.pincode}
              </small>
              <div className="add-input d-flex flex-column mt-3">
                <label
                  htmlFor=""
                  className="add-label"
                  style={{
                    color: '#707070',
                    fontSize: '14px',
                    fontWeight: 'normal',
                  }}
                >
                  Address
                </label>
                <textarea
                  type="text"
                  name="name"
                  id="name "
                  className="add-input"
                  placeholder="Enter Your city"
                  style={{
                    height: '84px',
                    backgroundColor: 'white',
                    border: '1px solid #E1E1E1',
                    padding: '12px 20px',
                    color: '#1D1D25',
                    fontSize: '16px',
                    fontWeight: '600',
                    borderRadius: '10px',
                  }}
                  onChange={(e) => {
                    setErrMsg({ ...errMsg, address: '' })

                    setAdrees({
                      ...addAdrees,
                      address: e.target.value,
                    })
                  }}
                />
              </div>
              <small
                style={{
                  color: 'red',
                }}
              >
                {errMsg.address}
              </small>
              <div className="add-input d-flex flex-column mt-3">
                <label
                  htmlFor=""
                  className="add-label"
                  style={{
                    color: '#707070',
                    fontSize: '14px',
                    fontWeight: 'normal',
                  }}
                >
                  City
                </label>
                <input
                  type="text"
                  name="name"
                  id="name "
                  className="add-input"
                  placeholder="Enter Your city"
                  style={{
                    height: '50px',
                    backgroundColor: 'white',
                    border: '1px solid #E1E1E1',
                    padding: '0 20px',
                    color: '#1D1D25',
                    fontSize: '16px',
                    fontWeight: '600',
                    borderRadius: '10px',
                  }}
                  onChange={(e) => {
                    setErrMsg({ ...errMsg, city: '' })

                    setAdrees({
                      ...addAdrees,
                      city: e.target.value,
                    })
                  }}
                />
              </div>
              <small
                style={{
                  color: 'red',
                }}
              >
                {errMsg.city}
              </small>
              <div className="col-12 mt-3 d-flex justify-content-center">
                <button
                  // data-bs-dismiss="modal"
                  // aria-label="Close"
                  style={{
                    width: '158px',
                    height: '50px',
                    backgroundColor: '#00B4BD',

                    border: 'none',
                    borderRadius: '25px',
                    color: '#FFFFFF',
                    fontSize: '14px',
                    fontWeight: '600',
                  }}
                >
                  <Link
                    onClick={AddCreateAdrees}
                    to="#"
                    style={{
                      display: 'block',
                      height: '100%',
                      color: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    Add Address
                  </Link>
                </button>
              </div>
            </Modal.Body>
          </Modal>
          {/* =====>react strap modal ends */}
          {/* ============================== */}
          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              class="modal-dialog modal-dialog-centered"
              style={{
                width: '80%',
                maxWidth: '393px',
              }}
            >
              <div
                class="modal-content"
                style={{
                  padding: '20px',
                  backgroundColor: '#F5F5F5',
                  borderRadius: '20px',
                }}
              >
                <div class="modal-header" style={{ padding: '0' }}>
                  <h5 class="modal-title" id="exampleModalLabel">
                    Add New Address
                  </h5>
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    style={{
                      height: '34px',
                      width: '34px',
                      backgroundColor: 'white',
                      border: '1px solid #ECECEC',
                      borderRadius: '10px',
                    }}
                  >
                    <img src={close} alt="close" />
                  </button>
                </div>
                <div className="add-input d-flex flex-column mt-3">
                  <label
                    htmlFor=""
                    className="add-label"
                    style={{
                      color: '#707070',
                      fontSize: '14px',
                      fontWeight: 'normal',
                    }}
                  >
                    Full Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name "
                    value={addAdrees.full_name}
                    className="add-input"
                    placeholder="Enter Your Name"
                    style={{
                      height: '50px',
                      backgroundColor: 'white',
                      border: '1px solid #E1E1E1',
                      padding: '0 20px',
                      color: '#1D1D25',
                      fontSize: '16px',
                      fontWeight: '600',
                      borderRadius: '10px',
                    }}
                    onChange={(e) => {
                      setErrMsg({ ...errMsg, full_name: '' })

                      setAdrees({
                        ...addAdrees,
                        full_name: e.target.value,
                      })
                    }}
                  />
                  <small
                    style={{
                      color: 'red',
                    }}
                  >
                    {errMsg.full_name}
                  </small>
                </div>
                <div className="add-input d-flex flex-column mt-3">
                  <label
                    htmlFor=""
                    className="add-label"
                    style={{
                      color: '#707070',
                      fontSize: '14px',
                      fontWeight: 'normal',
                    }}
                  >
                    Mobile Number
                  </label>
                  <input
                    type="phone "
                    name="name"
                    id="name "
                    value={addAdrees.mobile_number}
                    className="add-input"
                    placeholder="Enter Your phone"
                    style={{
                      height: '50px',
                      backgroundColor: 'white',
                      border: '1px solid #E1E1E1',
                      padding: '0 20px',
                      color: '#1D1D25',
                      fontSize: '16px',
                      fontWeight: '600',
                      borderRadius: '10px',
                    }}
                    onChange={(e) => {
                      setErrMsg({ ...errMsg, mobile_number: '' })

                      setAdrees({
                        ...addAdrees,
                        mobile_number: e.target.value,
                      })
                    }}
                  />
                </div>
                <small
                  style={{
                    color: 'red',
                  }}
                >
                  {errMsg.mobile_number}
                </small>
                <div className="add-input d-flex flex-column mt-3">
                  <label
                    htmlFor=""
                    className="add-label"
                    style={{
                      color: '#707070',
                      fontSize: '14px',
                      fontWeight: 'normal',
                    }}
                  >
                    Pincode
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name "
                    className="add-input"
                    placeholder="Enter Your pincode"
                    style={{
                      height: '50px',
                      backgroundColor: 'white',
                      border: '1px solid #E1E1E1',
                      padding: '0 20px',
                      color: '#1D1D25',
                      fontSize: '16px',
                      fontWeight: '600',
                      borderRadius: '10px',
                    }}
                    onChange={(e) => {
                      setErrMsg({ ...errMsg, pincode: '' })

                      setAdrees({
                        ...addAdrees,
                        pincode: e.target.value,
                      })
                    }}
                  />
                </div>
                <small
                  style={{
                    color: 'red',
                  }}
                >
                  {errMsg.pincode}
                </small>
                <div className="add-input d-flex flex-column mt-3">
                  <label
                    htmlFor=""
                    className="add-label"
                    style={{
                      color: '#707070',
                      fontSize: '14px',
                      fontWeight: 'normal',
                    }}
                  >
                    Address
                  </label>
                  <textarea
                    type="text"
                    name="name"
                    id="name "
                    className="add-input"
                    placeholder="Enter Your city"
                    style={{
                      height: '84px',
                      backgroundColor: 'white',
                      border: '1px solid #E1E1E1',
                      padding: '12px 20px',
                      color: '#1D1D25',
                      fontSize: '16px',
                      fontWeight: '600',
                      borderRadius: '10px',
                    }}
                    onChange={(e) => {
                      setErrMsg({ ...errMsg, address: '' })

                      setAdrees({
                        ...addAdrees,
                        address: e.target.value,
                      })
                    }}
                  />
                </div>
                <small
                  style={{
                    color: 'red',
                  }}
                >
                  {errMsg.address}
                </small>
                <div className="add-input d-flex flex-column mt-3">
                  <label
                    htmlFor=""
                    className="add-label"
                    style={{
                      color: '#707070',
                      fontSize: '14px',
                      fontWeight: 'normal',
                    }}
                  >
                    City
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name "
                    className="add-input"
                    placeholder="Enter Your city"
                    style={{
                      height: '50px',
                      backgroundColor: 'white',
                      border: '1px solid #E1E1E1',
                      padding: '0 20px',
                      color: '#1D1D25',
                      fontSize: '16px',
                      fontWeight: '600',
                      borderRadius: '10px',
                    }}
                    onChange={(e) => {
                      setErrMsg({ ...errMsg, city: '' })

                      setAdrees({
                        ...addAdrees,
                        city: e.target.value,
                      })
                    }}
                  />
                </div>
                <small
                  style={{
                    color: 'red',
                  }}
                >
                  {errMsg.city}
                </small>
                <div className="col-12 mt-3 d-flex justify-content-center">
                  <button
                    // data-bs-dismiss="modal"
                    // aria-label="Close"
                    style={{
                      width: '158px',
                      height: '50px',
                      backgroundColor: '#00B4BD',

                      border: 'none',
                      borderRadius: '25px',
                      color: '#FFFFFF',
                      fontSize: '14px',
                      fontWeight: '600',
                    }}
                  >
                    <Link
                      onClick={AddCreateAdrees}
                      to="#"
                      style={{
                        display: 'block',
                        height: '100%',
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      Add Address
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PlaceOrder

import React, { useState } from 'react';
import './AllCareerRegister.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTimes } from '@fortawesome/free-solid-svg-icons';

const AllCareerRegister = ({ show, onClose }) => {
  const [formData, setFormData] = useState({
    email: '',
    cv: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      cv: e.target.files[0],
    }));
  };

  const handleFileUploadClick = () => {
    document.getElementById('cv').click();
  };

  const removeSelectedFile = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      cv: null,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Data:', formData);
    // You can handle form submission here (e.g., send data to an API)
  };

  if (!show) {
    return null;
  }

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h5>Add Up</h5>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email" style={{ marginTop: '15px' }}>Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <label htmlFor="text">CV:</label>
          <div className="form-group file-upload" onClick={handleFileUploadClick}>
            <input
              type="file"
              id="cv"
              name="cv"
              accept=".pdf,.doc,.docx"
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
            <FontAwesomeIcon icon={faUpload} size="2x" />
            <p>Click to upload</p>
            {formData.cv && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{display:'flex'}}>Selected file: <h6 style={{ marginTop: '3px', marginLeft: '6px' }}>{formData.cv.name}</h6></p>
                <FontAwesomeIcon
                  icon={faTimes}
                  size="1x"
                  style={{ marginLeft: '10px', cursor: 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    removeSelectedFile();
                  }}
                />
              </div>
            )}
          </div>
          <button type="button" onClick={onClose}>
            Close
          </button>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default AllCareerRegister;

import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import about_us_tron from "../assets/images/about_us_tron.svg";
import { getWithAuthCallWithtext, simpleGetCall } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import LoaderCenter from "./LoaderCenter";

function About() {
  const { sidebar, setSidebar } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [AboutOffers, setAboutOffers] = useState([]);
  const [aboutUs, setAboutUs] = useState("");

  useEffect(() => {
    getAbout();
  }, []);

  const getAbout = () => {
    setLoading(true);

    getWithAuthCallWithtext(ApiConfig.UNIVERSR_ABOUT_US)
      .then((data) => {
        setLoading(false);

        setAboutUs(data.text);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Sidebar />

      {/* <Header /> */}

      <main id="cxMain" className={sidebar ? "cx-main cx-active" : "cx-main"}>
        {loading ? (
          <LoaderCenter />
        ) : (
          <section className="content-main">
            <div className="row">
              <div className="col-md-12">
                <div className="pages_screenWrapper">
                  <div className="gbl_jumbotron">
                    <h1>About Us</h1>
                    <img src={about_us_tron} alt="" />
                  </div>

                  <div className="gbl_paraWrapper">
                    {/* <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.Lorem ipsum dolor
                          sit amet, consectetur adipiscing eli
                        </p>

                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.Lorem ipsum dolor
                          sit amet, consectetur adipiscing elit.Lorem ipsum
                          dolor sit amet, consectetur adipiscing elit, sed do
                          eiusmod tempor incididunt ut labore et dolore magna
                          aliqua. Ut enim ad minim veniam, quis nostrud
                          exercitation ullamco laboris nisi ut aliquip ex ea
                          commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.Lorem ipsum dolor
                          sit amet, consectetur adipiscing elit.
                        </p>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip.
                        </p> */}
                    <p dangerouslySetInnerHTML={{ __html: aboutUs }}></p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
    </div>
  );
}

export default About;

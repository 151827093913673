import React from 'react'
import shirt from '../../assets/images/shirt.png'

const OfferDetailsCard = ({
  PassData,
  counter,
  increase,
  decrease,
  addCart,
  setAddCart,
}) => {

  return (
    <>
      <div
        className="of-details-wrapper"
        style={{
          backgroundColor: '#F5F5F5',
          borderRadius: '20px',
          height: 'fit-content',
        }}
      >
        <div
          className="of-img"
          style={{
            borderRadis: '20px 20px 10px 10px',
          }}
        >
          <img
            src={PassData.image}
            alt="shirt"
            style={{
              width: '100%',
              height: '260px',
              objectFit: 'cover',
              borderRadius: '20px 20px 14px 14px',
            }}
          />
        </div>
        <div
          className="detail-content"
          style={{
            padding: '20px',
          }}
        >
          <div className="row">
            <div className="col-6">
              <span
                style={{
                  color: '#1D1D25',
                  fontSize: '16px',
                  fontWeight: '600',
                }}
              >
                {PassData.product_name}
              </span>
            </div>
            <div className="col-6 d-flex justify-content-end">
              <label className="heart-check2" style={{ position: 'static' }}>
                <input class="check" type="checkbox" />
                <span class="label-text"></span>
              </label>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-6">
              <span
                style={{
                  color: '#707070',
                  fontSize: '14px',
                  fontWeight: 'normal',
                }}
              >
                {PassData.price}
                /-
              </span>
            </div>
            <div className="col-6  d-flex justify-content-end ">
              <span
                style={{
                  color: '#707070',
                  fontSize: '14px',
                  fontWeight: 'normal',
                }}
              >
                Quantity
              </span>
              <div
                className="counter ms-3 d-flex align-items-center"
                style={{
                  columnGap: '10px',
                }}
              >
                <button
                  onChange={(e) => {
                    setAddCart({
                      ...addCart,
                      quantity: e.target.value,
                    })
                  }}
                  onClick={decrease}
                  style={{
                    backgroundColor: 'white',
                    border: '1px solid #707070',
                    height: '14px',
                    width: '14px',
                    borderRadius: '7px',
                    color: '#707070',
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  -
                </button>
                <span
                  style={{
                    color: '#21212C',
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                >
                  {counter}
                </span>
                <button
                  onChange={(e) => {
                    setAddCart({
                      ...addCart,
                      quantity: e.target.value,
                    })
                  }}
                  onClick={increase}
                  style={{
                    backgroundColor: 'white',
                    border: '1px solid #707070',
                    height: '14px',
                    width: '14px',
                    borderRadius: '7px',
                    color: '#707070',
                    fontSize: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  +
                </button>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-12">
              <h3
                style={{
                  color: '#21212C',
                  fontSize: '16px',
                  fontWeight: '600',
                }}
              >
                Product Description
              </h3>
              <p
                style={{
                  color: '#707070',
                  fontSize: '12px',
                  fontWeight: '600',
                  marginTop: '10px',
                }}
              >
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit. Lorem ipsum dolor sit amet,
                consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                nostrud exercitation ullamco laboris nisi. */}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OfferDetailsCard

import React, { useContext, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AppContext } from '../context/AppContext'
import Sidebar from '../components/Sidebar'
import Header from '../components/Header'

import contact_us_tron from '../assets/images/contact_us_tron.svg'
import { simplePostCall } from '../api/ApiServices'
import swal from 'sweetalert'
import ApiConfig from '../api/ApiConfig'

function ContactUs() {
  const [price, setPrice] = useState(false)
  const { sidebar, setSidebar } = useContext(AppContext)
  const [userprives, setUserprives] = useState({
    type: '',
    message: '',
  })
  const navigate = useNavigate()
  console.log(userprives)
  const [showElement, setShowElement] = React.useState(true)
  useEffect(() => {
    setTimeout(function () {
      setShowElement(false)
    }, 5000)
  }, [])

  const reportuser = () => {
    // if (userreport.message === '') {
    //   setErrMsgAll({ ...errMsgal, message: 'Enter message ' })
    //   return
    // } else {
    simplePostCall(
      ApiConfig.CONTACT_API,
      JSON.stringify({
        ...userprives,
      }),
    )
      .then((res) => {
        // setUserreportList(res)
        swal(res.detail)
        navigate('/settings')
      })
      .catch((err) => {
        console.log(err)
      })
    // }
  }

  return (
    <div>
      <Sidebar />

      <main id="cxMain" className={sidebar ? 'cx-main cx-active' : 'cx-main'}>
        <section className="content-main">
          <div className="row">
            <div className="col-md-12">
              <div className="create_screenWrapper">
                <div className="row">
                  <div className="col-md-12">
                    <div className="contactusTronWrapper">
                      <img src={contact_us_tron} alt="" />
                    </div>
                  </div>
                </div>

                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Get in Touch
                  </h5>
                  {/* <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button> */}
                  {/* <Link to="/house_listing">
                  <i class="fa-solid fa-xmark"></i>
                </Link> */}
                </div>

                <div className="profile_modal_contentWrapper">
                  <div className="row">
                    <div className="col-md-12 profile_form">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="uploadPP_inputTextRow">
                            {/* <label class="form-label">Title</label>
                            <div className="upp_selectWrapper">
                              <select
                                value={userprives.type}
                                onChange={(e) => {
                                  setUserprives({
                                    ...userprives,
                                    type: e.target.value,
                                  })
                                  e.target.value == 'Other'
                                    ? setPrice(true)
                                    : setPrice(false)
                                }}
                                class="form-select"
                                aria-label="Default select example"
                              >
                                <option value="Other">Other</option>
                                <option value="Connection">Connection</option>
                                <option value="Groups">Groups</option>
                                <option value="Housing">Housing</option>
                                <option value="Forum">Forum</option>
                                <option value="Events">Events</option>
                                <option value="Offers">Offers</option>
                                <option value="Sell/Swap">Sell/Swap</option>
                                <option value="Career">Career</option>
                              </select>
                            </div> */}
                          </div>

                          {price ? (
                            <>
                              <div className="col-md-12">
                                <div className="uploadPP_inputTextRow">
                                  <input
                                    type="text"
                                    class="form-control"
                                    style={{ height: '40px' }}
                                    // value={AddProperty.title}
                                    placeholder="Other"
                                    // value={userprives.type}
                                    onChange={(e) => {
                                      setUserprives({
                                        ...userprives,
                                        type: e.target.value,
                                      })
                                    }}
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>

                        <div className="col-md-12">
                          <div className="uploadPP_inputTextRow">
                            <label class="form-label">Message</label>
                            <textarea
                              onChange={(e) => {
                                setUserprives({
                                  ...userprives,
                                  message: e.target.value,
                                })
                              }}
                              placeholder="Enter your message here"
                            ></textarea>
                          </div>
                        </div>
                      </div>

                      <div className="create_screenControls">
                        <Link onClick={reportuser} className="primary" to="#">
                          Post
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}

export default ContactUs

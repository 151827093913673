import React, { useContext, useState, useEffect, useRef } from 'react'
import { Link, useLocation, NavLink, Outlet } from 'react-router-dom'
import { AppContext } from '../../context/AppContext'
import Sidebar from '../../components/Sidebar'
import Header from '../../components/Header'
import thumbImg from '../../assets/images/watch.png'
import location_icon from '../../assets/images/location_icon.svg'
import back from '../../assets/images/back.svg'
import gray_location_icon from '../../assets/images/gray_location_icon.svg'
import chatListAvatar01 from '../../assets/images/chatListAvatar01.png'
import chat_icon_sidebar from '../../assets/images/chat_icon_sidebar.svg'
import search_icon from '../../assets/images/search_icon.svg'
import result_icon from '../../assets/images/saleThumb02.png'
import fillter_icon from '../../assets/images/fillter_icon.svg'
function OfficeProducts() {
  const { sidebar, setSidebar, setKeyword } = useContext(AppContext)
  const [filter, setFilter] = useState(
    useLocation().pathname === '/electronics/products',
  )
  const [showElement, setShowElement] = React.useState(true)
  const ipRef = useRef(null)

  useEffect(() => {
    setKeyword('')
  }, [])

  useEffect(() => {
    setTimeout(function () {
      setShowElement(false)
    }, 5000)
  })

  const handleSearch = () => {
    setKeyword(ipRef.current.value)
  }

  function refreshPage() {
    setTimeout(() => {
      window.location.reload(false)
    }, 0)
    
  }
  return (
    <>
      <div>
        <Sidebar />

        {/* <Header /> */}
        <main id="cxMain" className={sidebar ? 'cx-main cx-active' : 'cx-main'}>
          <section
            className="content-main"
            style={{
              backgroundColor: 'white',
              borderRadius: '20px',
              height: 'fit-content',
              minHeight: 'calc(100vh - 156px)',
              padding: '20px',
            }}
          >
            <div
              className="globalPages_tabWrapper"
              style={{
                marginBottom: '20px',
                width: '100%',
                marginRight: '0px',
                marginLeft: '0px',
              }}
            >
              <div
                className="gb_tabs"
                style={{
                  minWidth: '852px',
                  overflow: 'auto',
                }}
              >
                <div
                  className="tabs"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    minWidth: '1024px',
                    overflowX: 'auto',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      columnGap: '20px',
                    }}
                  >
                    <div>
                      <NavLink
                        onClick={() => setFilter(false)}
                        to={`/office/voucher`}
                        className={({ isActive }) =>
                          isActive ? 'nav-active' : 'nav-inactive'
                        }
                        style={{
                          width: '130px',
                        }}
                      >
                        Voucher
                      </NavLink>
                    </div>
                    <div>
                      <NavLink
                        onClick={() => setFilter(true)}
                        to={`/office/products`}
                        className={({ isActive }) =>
                          isActive ? 'nav-active' : 'nav-inactive'
                        }
                        style={{
                          width: '130px',
                        }}
                      >
                        Product
                      </NavLink>
                    </div>
                  </div>

                  {filter ? (
                    <button className="filter-ic-btn border-0">
                      <Link
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        to="#"
                        style={{
                          border: 'none',
                          margin: '0',
                          padding: '0px 0px',
                          zIndex: '1061',
                          marginRight:
                            '                                                                                                                                                                                                                                                               m,mm,mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,llllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllll,l,l,,,ll                             ,l  mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,l,llllllllllllllllllllllllllllllllllmmmmmmm,lllllllllllllllllllllllllllllll                                                                                                                                                                                                                                                                                                                                                                                                                                                  0px',
                        }}
                      >
                        <img src={fillter_icon} alt="" />
                      </Link>
                    </button>
                  ) : (
                    <></>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      columnGap: '20px',
                    }}
                  >
                    <div
                      className="search-input"
                      style={{
                        width: '224px',
                      }}
                    >
                      <input
                        ref={ipRef}
                        type="text"
                        name="text"
                        id="text"
                        placeholder="Search Office Product"
                        style={{
                          width: '100%',
                        }}
                      />
                      <img
                        onClick={() => handleSearch()}
                        src={search_icon}
                        alt="search"
                      />
                    </div>
                    <div>
                      <NavLink
                        onClick={() => {
                          setFilter(false)
                          refreshPage()
                        }}
                        to="/office/my-cart"
                        className={({ isActive }) =>
                          isActive ? 'nav-active' : 'nav-inactive'
                        }
                        style={{
                          width: '130px',
                        }}
                      >
                        My Cart
                      </NavLink>
                    </div>
                    <div>
                      <NavLink
                        onClick={() => setFilter(false)}
                        to="/office/orders"
                        className={({ isActive }) =>
                          isActive ? 'nav-active' : 'nav-inactive'
                        }
                        style={{
                          width: '130px',
                        }}
                      >
                        My Orders
                      </NavLink>
                    </div>
                    <div>
                      <NavLink
                        onClick={() => setFilter(false)}
                        to="/office/wishlist"
                        className={({ isActive }) =>
                          isActive ? 'nav-active' : 'nav-inactive'
                        }
                        style={{
                          width: '130px',
                        }}
                      >
                        My Wishlist
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Outlet />
          </section>
        </main>
      </div>
    </>
  )
}

export default OfficeProducts

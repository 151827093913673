import React, { useContext, useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import Header from '../../../components/Header'
import sidebar from '../../../components/Sidebar'
import Profile_header from '../../dashboard/dashboard_profile/Profile_header'
import profile_picture from '../../../assets/images/profile_picture.jpg'
import userDemo from '../../../assets/images/userDemo.png'
import rpt from '../../../assets/images/report.svg'
import block from '../../../assets/images/block.svg'
import eye_icn from '../../../assets/images/eye_icn.svg'
import global_icn from '../../../assets/images/dot.svg'
import users_icn from '../../../assets/images/users_icn.svg'
import Sidebar from '../../../components/Sidebar'
import { AppContext } from '../../../context/AppContext'
import ApiConfig from '../../../api/ApiConfig'
import swal from 'sweetalert'
import profilelogo from '../../../assets/images/headerProfilePic.png'
import src from '../../../assets/images/search.svg'

import {
  simpleGetCallWithErrorResponse,
  simplePostCall,
} from '../../../api/ApiServices'
import Loader from '../../../sharedComponent/Loader'

function ConnectionAbout({ setLoggedIn }) {
  const logout = (e) => {
    e.preventDefault()
    localStorage.clear()
    setLoggedIn(false)
  }
  const params = useParams()
  let id = params.id
  const navigate = useNavigate()
  const [userreport, setUserReport] = useState({
    type: '',
    message: '',
  })
  const { sidebar, setSidebar, customerData } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const [connectionProfilelist, setConnectionProfileList] = useState([])
  
  useEffect(() => {
    connectiongetprofile()

    return () => {
      setConnectionProfileList([])
    }
  }, [])

  const connectiongetprofile = () => {
    setLoading(true)

    simpleGetCallWithErrorResponse(
      ApiConfig.UNIVERSR_CONNECTION_PROFILE + id,
      JSON.stringify({ ...customerData }),
    ).then((res) => {
      console.log(res)
      setLoading(false)
      setConnectionProfileList(res.json.user_profile)
    })
  }
  const blockuser = (id) => {
    simplePostCall(
      ApiConfig.UNIVERSR_BLOCK_USER,
      JSON.stringify({
        blocked_to: id,
        blocked_status: 'block',
      }),
    )
      .then((res) => {
        swal('User Blocked Successfully.')

        navigate('/connection_swip')
        connectiongetprofile()
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }

  const reportuser = (id) => {
    simplePostCall(
      ApiConfig.UNIVERSR_REPORT_USER,
      JSON.stringify({ ...userreport, reported_to: id }),
    )
      .then((res) => {
        swal(res.detail)

        navigate('/connection_swip')
        connectiongetprofile()
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }

  const friendrequest = (id) => {
    simplePostCall(
      ApiConfig.UNIVERSR_FRIEND_REQUEST,
      JSON.stringify({ request_for: id }),
    )
      .then((res) => {
        console.log(res)
        // setRequestSend(res);
        swal(res.detail)
        navigate('/connection_swip')
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <>
      <Sidebar />

      {loading ? (
        <Loader />
      ) : (
        <main
          id="cxMain"
          className={sidebar ? 'cx-main cx-active' : 'cx-main'}
          style={{
            height: 'calc(100vh - 156px)',
          }}
        >
          <section className="content-main">
            <div className="profile_header">
              <div className="ph_section01">
                <div className="ph_profilePhoto">
                  {connectionProfilelist.profile_pic ? (
                    <>
                      {' '}
                      <img
                        src={connectionProfilelist.profile_pic}
                        alt="profile header"
                        style={{
                          outline: '2px solid #00B4BD',
                          padding: '5px',
                          height: '119px',
                          width: '111px',
                          objectFit: 'cover',
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      />
                      {/* =====> modal start <===== */}
                      <div
                        class="modal fade"
                        id="exampleModal"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div
                          class="modal-dialog modal-dialog-centered"
                          style={{
                            width: '80%',
                          }}
                        >
                          <div
                            class="modal-content"
                            style={{
                              padding: '20px',
                              borderRadius: '20px',
                              width: '100%',
                              maxWidth: '500px',
                            }}
                          >
                            <div class="modal-header">
                              <h3>{connectionProfilelist.full_name}</h3>
                              <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <img
                              src={connectionProfilelist.profile_pic}
                              alt=""
                              style={{
                                width: '100%',
                                objectFit: 'cover',
                                maxHeight: '400px',
                                border: '1px solid black',
                                marginTop: '20px',
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {/* =====> modal start <===== */}
                    </>
                  ) : (
                    <img
                      src={userDemo}
                      alt="profile header"
                      style={{
                        border: '1px solid #00B4BD',
                        padding: '5px',
                        height: '119px',
                        width: '111px',
                      }}
                    />
                  )}
                </div>

                <div className="ph_profilePhoto_details">
                  <ul>
                    <li>
                      <h1>{connectionProfilelist.full_name}</h1>
                    </li>
                    <li style={{ marginTop: '0px' }}>
                      <h2>{connectionProfilelist.privacy_real_name}</h2>
                    </li>
                    <li>
                      <ul style={{ marginTop: '10px' }}>
                        <li>
                          {connectionProfilelist.age ? (
                            <p>
                              {connectionProfilelist.age} Years Old{' '}
                              <img
                                src={global_icn}
                                alt="eye icon"
                                style={{
                                  alignItems: 'center',
                                  paddingLeft: '10px',
                                }}
                              />
                            </p>
                          ) : (
                            <></>
                          )}
                        </li>

                        <li>
                          {/* <p>
                              {connectionProfilelist.university}{" "}
                              <img
                                src={global_icn}
                                alt="eye icon"
                                style={{ paddingLeft: "10px" }}
                              />
                            </p> */}
                          {connectionProfilelist.university ? (
                            <p>
                              {connectionProfilelist.university}{' '}
                              <img
                                src={global_icn}
                                alt="eye icon"
                                style={{ paddingLeft: '10px' }}
                              />
                            </p>
                          ) : (
                            <></>
                          )}
                        </li>

                        <li>
                          {connectionProfilelist.gender ? (
                            <p
                              className="she-he-body"
                              style={{
                                border: '1px solid #00B4BD',
                                padding: '5px 10px',
                                borderRadius: '20px',
                                position: 'relative',
                                bottom: '5px',
                              }}
                            >
                              {connectionProfilelist.gender}
                            </p>
                          ) : (
                            <></>
                          )}
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="ph_section02 d-flex justify-content-end">
                <button
                  // class="btn"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    backgroundColor: 'transparent',
                    color: 'white',
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                  }}
                >
                  <i class="fa-solid fa-ellipsis-vertical"></i>
                </button>
                <ul
                  class="dropdown-menu custom-ul-con"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li style={{ paddingLeft: '0px' }}>
                    <Link
                      to="/property_item"
                      class="dropdown-item"
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal2"
                      style={{ backgroundColor: 'transparent' }}
                    >
                      Report
                    </Link>
                  </li>
                  <Link
                    to="/property_item"
                    class="dropdown-item "
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalBlock"
                    style={{ backgroundColor: 'transparent' }}
                  >
                    Block
                  </Link>
                </ul>
              </div>
            </div>
            <div className="gbl_col_70 aboutSection">
              <h1>About Me</h1>
              <div
                className="abt_paraSection"
                style={{ marginTop: '5px', marginBottom: '35px' }}
              >
                <p style={{ color: '#707070' }}>
                  {connectionProfilelist.about_me}
                </p>
              </div>
              <div
                className="abt_contentSection"
                style={{ marginTop: '-20px' }}
              >
                <h1 style={{ marginBottom: '10px' }}>
                  University / Institution
                </h1>
                <p style={{ color: '#707070' }}>
                  {connectionProfilelist.university}
                </p>
              </div>

              <div className="abt_labelSection">
                <h1 style={{ marginTop: '20px' }}>Interests</h1>
                <ul style={{ marginBottom: '20px' }}>
                  {connectionProfilelist.interest &&
                  connectionProfilelist.interest.length ? (
                    connectionProfilelist.interest.map((item) => {
                      return (
                        <li>
                          <Link to="#">{item.interest.subject}</Link>
                        </li>
                      )
                    })
                  ) : (
                    <>
                      {connectionProfilelist.full_name +
                        ` do not have any interest`}
                    </>
                  )}
                </ul>
              </div>
              <hr />
              <div
                className="usa-btn"
                style={{ marginTop: '-10px', marginBottom: '-15px' }}
              >
                <Link
                  to="/connection_swip"
                  className="fir connection_swip "
                  style={{
                    padding: '15px',
                    width: '170px',
                    border: '1px solid #00B4BD',
                    font: '16px',
                    fontFamily: 'Nunito',
                    fontWeight: 'bold',
                    color: '#00B4BD',
                    borderRadius: '30px',
                  }}
                >
                  Cancel
                </Link>
                <Link
                  onClick={() => {
                    friendrequest(connectionProfilelist.id)
                  }}
                  className="primary connection_swip"
                  to="#"
                  style={{
                    padding: '15px',
                    width: '170px',
                    backgroundColor: '#00B4BD',
                    font: '16px',
                    fontFamily: 'Nunito',
                    fontWeight: 'bold',
                    color: '#FFFFFF',
                    borderRadius: '30px',
                  }}
                >
                  {/* {connectionProfilelist.is_friend === "Add Friend"
                    ? "Add Friend"
                    : "Request sent"} */}

                  {connectionProfilelist.is_friend === 'Add Friend'
                    ? 'Add Friend'
                    : connectionProfilelist.is_friend === 'Accepted'
                    ? 'Connect'
                    : connectionProfilelist.is_friend === 'Pending'
                    ? 'Request sent'
                    : 'Request sent'}
                  {/* Add Friend */}
                </Link>
              </div>
            </div>
            <div
              class="modal fade main-model-of-block"
              id="exampleModalBlock"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div
                class="modal-dialog modal-dialog-centered "
                style={{ width: '393px' }}
              >
                <div
                  class="modal-content"
                  style={{
                    borderRadius: '30px',
                    padding: '10px',
                    backgroundColor: '#F5F5F5',
                  }}
                >
                  <div
                    style={{
                      padding: '13px 14px 20px 1px',
                      fontSize: '20px',
                      color: '#21212C',
                      fontFamily: 'Nunito',
                      fontWeight: 'bold',
                      letterSpacing: '0px',
                      textAlign: 'center',
                    }}
                    class="modal-header"
                  >
                    <img src={block} style={{ marginLeft: '8px' }} />
                    <h5
                      class="modal-title"
                      id="exampleModalLabel"
                      style={{ marginLeft: '20px' }}
                    >
                      Block {connectionProfilelist.full_name}
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      style={{
                        backgroundColor: 'white',
                        border: '1px solid #ECECEC',
                        borderRadius: '5px',
                      }}
                    ></button>
                  </div>
                  <div class="modal-body" style={{ marginTop: '-20px' }}>
                    <p
                      style={{
                        margin: '20px 10px',
                        fontSize: '14px',
                        color: '#707070',
                        fontFamily: 'Nunito',
                      }}
                    >
                      {connectionProfilelist.full_name} will no longer be able to :
                    </p>
                    <p
                      style={{
                        // font: "normal normal 600 16px/ 22px Nunito",
                        fontWeight: '600',
                        fontSize: '16px',
                        color: '#1D1D25',
                        margin: '20px 10px 20px 10px ',
                        fontFamily: 'Nunito',
                      }}
                    >
                      See your posts Invite you to events or groups Message you
                      Add you as a friend
                    </p>
                    <p
                      style={{
                        margin: '20px 10px',
                        fontWeight: '600',
                        fontSize: '16px',
                        fontFamily: 'Nunito',
                        color: '#1D1D25',
                      }}
                    >
                      If you're friends, blocking {connectionProfilelist.full_name} will also unfriend her.
                    </p>
                  </div>
                  <div class="modal-footer ">
                    <div
                      className="usa-btn"
                      style={{
                        marginTop: '-30px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: '25px',
                      }}
                    >
                      <Link
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        to="/ConnectionAbout"
                        className="fir"
                        style={{ padding: '13px', width: '130px' }}
                      >
                        Cancel
                      </Link>
                      <Link
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        className="primary"
                        to="#"
                        onClick={() => {
                          blockuser(connectionProfilelist.id)
                        }}
                        style={{ padding: '13px', width: '130px' }}
                      >
                        Confirm
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal fade modal-backdrp "
              id="exampleModal2"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              modal-backdrp="rgba(0, 0, 0, .5)"
            >
              <div class="modal-dialog modal-dialog-centered modal-dialog-centered-profile ">
                <div
                  class="modal-content"
                  style={{
                    borderRadius: '30px',
                    padding: '15px',
                    backgroundColor: '#F5F5F5',
                    width: '393px',
                  }}
                >
                  <div class="modal-header ">
                    <img src={rpt} />
                    <div className="modal-title-block">
                      {' '}
                      <h5 class="modal-title ms-3" id="exampleModalLabel">
                        Report {connectionProfilelist.full_name}
                      </h5>
                    </div>

                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      style={{
                        marginRight: '4px',
                        border: '1px solid #ECECEC',
                        backgroundColor: 'white',
                        borderRadius: '10px',
                      }}
                    ></button>
                  </div>
                  <div class="modal-body">
                    <p
                      className="modal-body-user-1"
                      style={{ marginBottom: '10px' }}
                    >
                      Why are you reporting this user ?
                    </p>
                    <div
                      className="upp_selectWrapper-block-1"
                      style={{ marginBottom: '40px' }}
                    >
                      <select
                        value={userreport.type}
                        onChange={(e) => {
                          setUserReport({
                            ...userreport,
                            type: e.target.value,
                          })
                        }}
                        class="form-select"
                        aria-label="Default select example"
                        style={{
                          height: '50px',
                          fontSize: '18px',
                          fontWeight: '600px',
                          color: '#1D1D25',
                        }}
                      >
                        <option value="1">Inappropriate Behaviour</option>
                        <option value="2">Abusive Behaviour</option>
                      </select>
                    </div>
                    <div className="connection-p-model">
                      <p style={{ marginBottom: '10px', marginTop: '-20px' }}>
                        Please describe your reason for reporting.
                      </p>
                    </div>
                    <div className="col-md-6">
                      <div
                        className="uploadPP_inputTextRow"
                        style={{ height: '150px' }}
                      >
                        <textarea
                          value={userreport.message}
                          onChange={(e) => {
                            setUserReport({
                              ...userreport,
                              message: e.target.value,
                            })
                          }}
                          className="textareaAdj01"
                          cols="45"
                          style={{
                            height: '150px',
                            width: '198%',
                            borderRadius: '10px',
                            padding: '20px',
                            color: '#BBBBBB',
                            backgroundColor: '#FFFFFF',
                            border: '1px solid #E1E1E1',
                            fontSize: '14px',
                            fontStyle: 'normal',
                          }}
                          placeholder="Type Here..."
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer ">
                    <div
                      className="usa-btn-model"
                      style={{ width: '100%', alignItems: 'baseline' }}
                    >
                      <Link
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        to="/ConnectionAbout"
                        className="fir"
                        style={{
                          width: '129px',
                          height: '50px',
                          color: '#707070',
                          fontSize: '16px',
                          fontWeight: 'bold',
                          letterSpacing: '0px',
                          textAlign: 'center',
                        }}
                      >
                        Cancel
                      </Link>
                      <Link
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        className="primary"
                        to="#"
                        onClick={() => {
                          reportuser(connectionProfilelist.id)
                        }}
                        style={{
                          width: '129px',
                          height: '50px',
                          color: '#FFFFFF',
                          fontSize: '16px',
                          fontWeight: 'bold',
                          letterSpacing: '0px',
                          textAlign: 'center',
                        }}
                      >
                        Confirm
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      )}
    </>
  )
}

export default ConnectionAbout

import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

import { useEffect, useState, useContext } from "react";
// import "antd/dist/antd.css";
// import "ant-design-draggable-modal/dist/index.css";
import Dashboard from "./pages/dashboard/Dashboard";
import Login from "./sharedComponent/Login";
import "../src/assets/css/main.scss";
import "../src/assets/css/main_responsive.scss";
import SignUp from "./sharedComponent/SignUp";
import Interests from "./sharedComponent/Interests";
import Verification1 from "./sharedComponent/Verification1";
import Verification2 from "./sharedComponent/Verification2";
import Verification3 from "./sharedComponent/Verification3";
import Verification4 from "./sharedComponent/Verification4";
import Groups from "../src/pages/groups/Groups";
import GroupCall from "./pages/groupCall/GroupCall";
import CreateGroup from "./pages/groups/CreateGroup";
import ConnectionsSwip from "./pages/connections/ConnectionsSwip";
import ConnectionProfile from "./pages/connections/connection_profile/ConnectionProfile";
import HouseListing from "./pages/housing/HouseListing";
import AdListing from "./pages/adCampaing/AdListing.js";
import AdEdit from "./pages/adCampaing/AdEdit.js";
import CareerEdit from "./pages/career/CareerEdit.js";
import EventEdit from "./pages/events/EventEdit.js";
import CareerListing from "./pages/career/AdListing.js";
import orderManagementListing from "./pages/orderManagement/AdListing.js";

import HousingEdit from "./pages/housing/HousingEdit.js";
import HouseManage from "./pages/housing/HouseManage";
import CreateProperty from "./pages/housing/CreateProperty";
import CreateAdProperty from "./pages/adCampaing/CreateProperty.js";
import CreateCareerProperty from "./pages/career/CreateProperty.js";
import CreateOrderManagementProperty from "./pages/orderManagement/CreateProperty.js";
import PropertyItem from "./pages/housing/PropertyItem";
import ForumList from "./pages/forum/ForumList";
import CreateForum from "./pages/forum/CreateForum";
import ManageForum from "./pages/forum/ManageForum";
import EditForum from "./pages/forum/EditForum";
import ManageEventAll from "./pages/events/ManageAll.jsx";
import ManageEventDetails from "./pages/events/ManageEventDetails.jsx";
import EventsList from "./pages/events/EventsList";
import CreateEvent from "./pages/events/CreateEvent";
import ManageEvent from "./pages/events/ManageEvent";
import EventPurchased from "./pages/events/EventPurchased";
import SavedEvents from "./pages/events/SavedEvents";
import EventDetails from "./pages/events/EventDetails.js";
import AllSellSwapProperty from "./pages/sellSwap/post/AllProperty.jsx";
import CreateSellSwapProperty from "./pages/sellSwap/post/CreateProperty.js";
import EditSellSwapProperty from "./pages/sellSwap/post/EditProperty.js";
import SellDetails from "./pages/sellSwap/post/AllSellDetails.jsx";
import ManageSellSwapDetails from "./pages/sellSwap/post/ManageSellSwapDetails.jsx";
import SellList from "./pages/sellSwap/SellList";
import SellManage from "./pages/sellSwap/SellManage";
import AllManageSellSwapProperty from "./pages/sellSwap/post/ManageSellSwapAll.jsx";
import SavedSell from "./pages/sellSwap/SavedSell";
import CreateSell from "./pages/sellSwap/CreateSell";
import JobVolunteer from "./pages/job_volunteer/JobVolunteer";
import OffersListing from "./pages/offers/OffersListing";
import SetLocation from "./sharedComponent/SetLocation";
import PrivacyPolicy from "./sharedComponent/PrivacyPolicy";
import About from "./sharedComponent/About";
import TnC from "./sharedComponent/TnC";
import ContactUs from "./sharedComponent/ContactUs";
import Subscription from "./sharedComponent/Subscription";
import CreateProfile from "./pages/dashboard/CreateProfile";
import ConnectionAbout from "./pages/connections/connection_profile/ConnectionAbout";
import Friend_requestSection from "./pages/dashboard/dashboard_friend_request/Friend_requestSection";
import settinglisting from "./pages/Setting/SettingListing";
import Settinglisting from "./pages/Setting/SettingListing";
import SettingAbout from "./pages/Setting/SettingAbout";
import SettingPrivecy from "./pages/Setting/SettingPrivecy";
import TermsConditions from "./pages/Setting/TermsConditions";
// import Product from "./pages/offers/Product";
import OffersCard from "./pages/offers/OffersCard";
import Product from "./pages/offers/HealthProducts";
import OffersProduct from "./pages/offers/OffersProduct";
import MyCart from "./pages/offers/MyCart";
import MyOder from "./pages/offers/MyOder";
import PlaceOrder from "./pages/offers/PlaceOrderPage";
import PaymentPage from "./pages/offers/PaymentPage";
import OrderDetails from "./pages/offers/OrderDetails";
import MyWishlist from "./pages/offers/MyWishlist";
import AllEvent from "./pages/events/AllEvent";
import EventDetailsView from "./pages/events/EventDetailsView.jsx";
import MyEvent from "./pages/events/MyEvent";
import CareerDetails from "./pages/job_volunteer/CareerDetails";
import AllItems from "./pages/sellSwap/AllItems";
import Voucher from "./pages/offers/Voucher";
import VoucherHealth from "./pages/offers/VoucherHealth";
import HealthProducts from "./pages/offers/HealthProducts";
import DigitalProducts from "./pages/offers/DigitalProducts";
import FashionProducts from "./pages/offers/FashionProducts";
import ElectronicProducts from "./pages/offers/ElectronicProducts";
import OfficeProducts from "./pages/offers/OfficeProducts";
import SportsProducts from "./pages/offers/SportsProducts";

import DiningProducts from "./pages/offers/DiningProducts";
import JewelleryProducts from "./pages/offers/JewelleryProducts";
import HealthCard from "./pages/offers/HealthCard";
import FashionCard from "./pages/offers/FashionCard";
import ElectronicCard from "./pages/offers/ElectronicCard";
import OfficeCard from "./pages/offers/OfficeCard";
import ProductCard from "./pages/offers/sport/product/OfferCardList.js";
import VoucherCard from "./pages/offers/sport/voucher/OfferCardList.js";

import DiningCard from "./pages/offers/DiningCard";
import JewelleryCard from "./pages/offers/jewelleryCard";
import DigitalCard from "./pages/offers/DigitalCard";
import OfferDetailsCard from "./pages/offers/OfferDetailsCard";
import ElectronicDetails from "./pages/offers/ElectronicDetails";
import FashionDetails from "./pages/offers/FashionDetails";
import HealthDetails from "./pages/offers/HealthDetails";
import OfficeDetails from "./pages/offers/OfficeDetails";
import DiningDetails from "./pages/offers/DiningDetails";
import Orders from "./pages/offers/Orders";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import ForumComments from "./pages/forum/ForumComments";
import ConfirmMail from "./sharedComponent/ConfirmMail";
import AllProperty from "./pages/housing/AllProperty";
import AllAdProperty from "./pages/adCampaing/AllProperty.js";
import AllCareerProperty from "./pages/career/AllProperty.jsx";
import AllOrderProperty from "./pages/orderManagement/AllProperty.js";

import Manage from "./pages/housing/Manage";
import ManageAll from "./pages/housing/ManageAll.jsx";
import ManageHouseDetails from "./pages/housing/ManageHouseDetails.jsx";
import HouseDetails from "./pages/housing/HouseDetails.jsx";
import InterestedHousing from "./pages/housing/InterestedHousing.jsx";
import InterestedCareer from "./pages/career/InterestedCareer.jsx";
import InterestedEvents from "./pages/events/InterestedEvents.jsx";
import AdManage from "./pages/adCampaing/Manage.js";
import CareerManage from "./pages/career/Manage.js";
import CareerManageDetails from "./pages/career/ManageDetailsView.js";
import ManageCareerProperty from "./pages/career/ManageAllProperty.js";
import OrderManage from "./pages/orderManagement/Manage.js";
import HousingDetails from "./pages/housing/HousingDetails.js";
import SearchResults from "./sharedComponent/SearchResults";
import Notification from "./sharedComponent/Notification";
import NewPassword from "./sharedComponent/NewPassword";
import UpdateProperty from "./pages/housing/UpdateProperty";
import UpdateEvent from "./pages/events/UpdateEvent";
import UpdateSell from "./pages/sellSwap/UpdateSell";
import DetailsSell from "./pages/sellSwap/DetailsSell";
import MyWishlistHealth from "./pages/offers/MyWishlistHealth";
import DiningVoucher from "./pages/offers/DiningVoucher";
import JewelleryVoucher from "./pages/offers/JewelleryVoucher";
import DigitalVoucher from "./pages/offers/DigitalVoucher";
import VoucherFasion from "./pages/offers/VoucherFasion";
import MyWishlistFashion from "./pages/offers/MyWishlistFashion";
import Fashion from "./pages/offers/Fashion";
import Dining from "./pages/offers/Dining";
import MyWishlistJewellery from "./pages/offers/MyWishlistJewellery";
import VoucherElectronic from "./pages/offers/VoucherElectronic";
import MyWishlistElectronic from "./pages/offers/MyWishlistElectronic";
import Health from "./pages/offers/Health";
import VoucherOffer from "./pages/offers/VoucherOffer";
import MyWishlistOffer from "./pages/offers/MyWishlistOffer";
import VoucherSport from "./pages/offers/VoucherSport";
import MyWishlistSport from "./pages/offers/MyWishlistSport";
import OfficeDetailsSport from "./pages/offers/OfficeDetailsSport";
import MyWishlistDining from "./pages/offers/MyWishlistDining";
import JewelleryDetails from "./pages/offers/JewelleryDetails";
import MyWishlistDigital from "./pages/offers/MyWishlistDigital";
import DigitalDetails from "./pages/offers/DigitalDetails";
import PropertyInterested from "./pages/housing/PropertyInterested";
import ApiConfig from "./api/ApiConfig";
import { simpleGetCallWithErrorResponse } from "./api/ApiServices";
import { AppContext } from "./context/AppContext";
import "react-image-crop/dist/ReactCrop.css";
import EditGropu from "./pages/groups/EditGropu";
import ChatConnection from "./pages/connections/connection_profile/ChatConnection";
import ForumProfile from "./pages/connections/connection_profile/ForumProfile";
import Payment from "./pages/payment/Payment";
import Completion from "./pages/payment/Completion";
// import ChatBox from './pages/ChatNew/ChatBox'
// or scss:
import ManageOfferProduct from "./pages/offers/sport/product/OfferProductDetails.js";
import ManageOfferVoucher from "./pages/offers/sport/voucher/OfferVoucherDetails.js";

import ManageDigitalOfferProduct from "./pages/offers/digital/product/OfferProductDetails.js";
import ManageDigitalOfferVoucher from "./pages/offers/digital/voucher/OfferVoucherDetails.js";
import DigitalProductCard from "./pages/offers/digital/product/OfferCardList.js";
import DigitalVoucherCard from "./pages/offers/digital/voucher/OfferCardList.js";

import ManageElectronicsOfferProduct from "./pages/offers/electronics/product/OfferProductDetails.js";
import ManageElectronicsOfferVoucher from "./pages/offers/electronics/voucher/OfferVoucherDetails.js";
import ElectronicsProductCard from "./pages/offers/electronics/product/OfferCardList.js";
import ElectronicsVoucherCard from "./pages/offers/electronics/voucher/OfferCardList.js";

import ManageDiningOfferProduct from "./pages/offers/dining/product/OfferProductDetails.js";
import ManageDiningOfferVoucher from "./pages/offers/dining/voucher/OfferVoucherDetails.js";
import DiningProductCard from "./pages/offers/dining/product/OfferCardList.js";
import DiningVoucherCard from "./pages/offers/dining/voucher/OfferCardList.js";

import ManageJewelleryOfferProduct from "./pages/offers/jewellery/product/OfferProductDetails.js";
import ManageJewelleryOfferVoucher from "./pages/offers/jewellery/voucher/OfferVoucherDetails.js";
import JewelleryProductCard from "./pages/offers/jewellery/product/OfferCardList.js";
import JewelleryVoucherCard from "./pages/offers/jewellery/voucher/OfferCardList.js";

import ManageFashionOfferProduct from "./pages/offers/fashion/product/OfferProductDetails.js";
import ManageFashionOfferVoucher from "./pages/offers/fashion/voucher/OfferVoucherDetails.js";
import FashionProductCard from "./pages/offers/fashion/product/OfferCardList.js";
import FashionVoucherCard from "./pages/offers/fashion/voucher/OfferCardList.js";

import ManageHealthOfferProduct from "./pages/offers/health/product/OfferProductDetails.js";
import ManageHealthOfferVoucher from "./pages/offers/health/voucher/OfferVoucherDetails.js";
import HealthProductCard from "./pages/offers/health/product/OfferCardList.js";
import HealthVoucherCard from "./pages/offers/health/voucher/OfferCardList.js";

import ManageOfficeOfferProduct from "./pages/offers/office/product/OfferProductDetails.js";
import ManageOfficeOfferVoucher from "./pages/offers/office/voucher/OfferVoucherDetails.js";
import OfficeProductCard from "./pages/offers/office/product/OfferCardList.js";
import OfficeVoucherCard from "./pages/offers/office/voucher/OfferCardList.js";
import Onboarding from "./sharedComponent/Onboarding.js";
import NotVerified from "./sharedComponent/NotVerified.js";
import PendingVerification from "./sharedComponent/PendingVerification.js";

function App() {
  const [loading, setLoading] = useState(false);
  const {
    sidebar,
    customerData,
    setNotificationTotal,
    // notificationTotal,
  } = useContext(AppContext);
  const location = useLocation().pathname;
  const navigate = useNavigate();
  const [logedIn, setLoggedIn] = useState(
    localStorage.getItem("logedIn") ? localStorage.getItem("logedIn") : false
  );

  console.log(logedIn);

  useEffect(() => {
    if (false) {
      //!logedIn) {
      navigate("/");
    } else navigate("dashboard");
  }, [logedIn]);

  useEffect(() => {
    if (!logedIn) {
      navigate("/");
    } else navigate("/dashboard");
  }, []);

  /// Notifation ///

  useEffect(() => {
    getNotification();
    // getNotificationCount()
  }, []);

  const getNotification = () => {
    setLoading(true);

    simpleGetCallWithErrorResponse(
      ApiConfig.NOTIFICATION,
      JSON.stringify({ ...customerData })
    ).then((res) => {
      console.log(res);
      setLoading(false);

      // setNotificationTotal(
      //   res.json.notifications.length ? res.json.notifications.length : 0,
      // )
    });
  };

  return (
    <div className="App">
      <div
        class="sitemainBGwrapper"
        style={{
          overflow: "unset",
        }}
      >
        {location !== "/" &&
          location !== "Login" &&
          location !== "/Signup" &&
          location !== "/signup" &&
          location !== "/verify-email" &&
          location !== "/onboarding" &&
          location !== "/notverified" &&
          location !== "/pendingverification" &&
          location !== "/interests" &&
          location !== "/verification1" &&
          location !== "/verification2" &&
          location !== "/verification3" &&
          location !== "/verification4" &&
          location !== "/new" &&
          location !== "/tnc" && (
            <>
              {!(
                (
                  window.location.pathname === "/connection_swip" ||
                  window.location.pathname === "/connectionAbout/:id" ||
                  window.location.pathname === "/confirm"
                )
                // window.location.pathname === "/new"
                // window.location.pathname === '/comment'
              ) ? (
                <Header setLoggedIn={setLoggedIn} />
              ) : (
                <></>
              )}

              <Routes>
                <Route
                  path="/settings"
                  element={<Settinglisting setLoggedIn={setLoggedIn} />}
                />

                {/* <ConnectionsSwip /> */}
              </Routes>
            </>
          )}
        <Routes>
          <Route
            path={"/" || "/Login"}
            element={<Login setLoggedIn={setLoggedIn} />}
          />
          <Route path="/fashion" element={<Fashion />} />
          <Route path="/health" element={<Health />} />
          <Route path="/dining" element={<Dining />} />
          <Route
            path="/signup"
            element={<SignUp setLoggedIn={setLoggedIn} />}
          />

          <Route path="/verify-email" element={<ConfirmMail />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/interests" element={<Interests />} />
          <Route path="/onboarding" element={<Onboarding />} />
          <Route path="/notverified" element={<NotVerified />} />
          <Route
            path="/pendingverification"
            element={<PendingVerification />}
          />
          <Route path="/verification1" element={<Verification1 />} />
          <Route path="/verification2" element={<Verification2 />} />
          <Route path="/verification3" element={<Verification3 />} />
          <Route path="/verification4" element={<Verification4 />} />
          <Route path="/groups" element={<Groups />} />
          <Route path="/groups/:id" element={<Groups />} />
          <Route path="/UpdateProperty/:id" element={<UpdateProperty />} />
          <Route path="/groups_call" element={<GroupCall />} />
          <Route path="/create_group" element={<CreateGroup />} />
          <Route path="/create_group/:id" element={<CreateGroup />} />
          <Route path="/EditGropu" element={<EditGropu />} />
          <Route path="/EditGropu/:id" element={<EditGropu />} />
          <Route path="/connection_profile" element={<ConnectionProfile />} />
          <Route path="/connectionAbout/:id" element={<ConnectionAbout />} />
          <Route path="/house_listing" element={<HouseListing />} />
          <Route path="/adCampaing" element={<AdListing />} />
          <Route path="/career" element={<CareerListing />} />
          <Route path="/orderManagement" element={<orderManagementListing />} />
          <Route path="/connectionAbout" element={<ConnectionAbout />} />
          <Route path="/connectionGroup/:id" element={<ChatConnection />} />
          <Route path="/connectionGroup" element={<ChatConnection />} />
          <Route path="/ForunGroup/:id" element={<ForumProfile />} />
          <Route path="/completion" element={<Completion />} />
          {/* Chat  */}
          {/* <Route path="/ChatBox" element={<ChatBox />} /> */}

          <Route path="/house_listing" element={<HouseListing />}>
            <Route path="all" element={<AllProperty />} />
            <Route path="edit/:id" element={<HousingEdit />} />
            <Route path="details/:id" element={<HouseDetails />} />
            <Route path="manage/details/:id" element={<ManageHouseDetails />} />
            <Route path="manage" element={<ManageAll />} />
            <Route path="interested" element={<InterestedHousing />} />

            {/* <Route path="interested" element={<PropertyInterested />} /> */}
          </Route>

          <Route path="/adCampaing" element={<AdListing />}>
            <Route path="all" element={<AllAdProperty />} />
            <Route path="manage/:id" element={<AdManage />} />
            <Route path="edit/:id" element={<AdEdit />} />
            <Route path="interested" element={<PropertyInterested />} />
            
          </Route>

          <Route path="/career" element={<CareerListing />}>
            <Route path="all" element={<AllCareerProperty />} />
            <Route path="details/:id" element={<CareerManage />} />
            <Route
              path="manage/details/:id"
              element={<CareerManageDetails />}
            />
            <Route path="manage" element={<ManageCareerProperty />} />
            <Route path="edit/:id" element={<CareerEdit />} />
            <Route path="interested" element={<InterestedCareer />} />
            
            {/* <Route path="interested" element={<PropertyInterested />} /> */}
          </Route>

          <Route path="/orderManagement" element={<orderManagementListing />}>
            <Route path="all" element={<AllOrderProperty />} />
            <Route path="manage/:id" element={<OrderManage />} />
            <Route path="interested" element={<PropertyInterested />} />
          </Route>

          <Route path="/house_manage" element={<HouseManage />} />
          {!(window.location.pathname === "/create_property") ? (
            <Route path="/create_property/:id" element={<CreateProperty />} />
          ) : (
            <Route path="/create_property" element={<CreateProperty />} />
          )}

          <Route path="/adCampaing" element={<AdListing />}>
            <Route path="all" element={<AllProperty />} />
            <Route path="manage/:id" element={<Manage />} />
            <Route path="interested" element={<PropertyInterested />} />
          </Route>

          <Route path="/house_manage" element={<HouseManage />} />
          {!(window.location.pathname === "/create_property") ? (
            <Route path="/create_property/:id" element={<CreateProperty />} />
          ) : (
            <Route path="/create_property" element={<CreateProperty />} />
          )}

          <Route path="/adCampaing" element={<HouseManage />} />
          {!(window.location.pathname === "/create_ad") ? (
            <Route path="/create_ad/:id" element={<CreateAdProperty />} />
          ) : (
            <Route path="/create_ad" element={<CreateAdProperty />} />
          )}

          <Route path="/career" element={<HouseManage />} />
          {!(window.location.pathname === "/create_career") ? (
            <Route
              path="/create_career/:id"
              element={<CreateCareerProperty />}
            />
          ) : (
            <Route path="/create_career" element={<CreateCareerProperty />} />
          )}

          <Route path="/orderManagement" element={<HouseManage />} />
          {!(window.location.pathname === "/create_order") ? (
            <Route
              path="/create_order/:id"
              element={<CreateOrderManagementProperty />}
            />
          ) : (
            <Route
              path="/create_order"
              element={<CreateOrderManagementProperty />}
            />
          )}

          <Route path="/property_item" element={<PropertyItem />} />
          <Route path="/forum_list" element={<ForumList />} />
          <Route path="/create_forum" element={<CreateForum />} />
          <Route path="/edit_forum/:id" element={<EditForum />} />
          <Route path="/events" element={<EventsList />}>
            <Route path="all" element={<AllEvent />} />
            <Route path="details/:id" element={<EventDetailsView />} />
            <Route path="edit/:id" element={<EventEdit />} />
            <Route path="myevent" element={<ManageEventAll />} />
            <Route
              path="myevent/details/:id"
              element={<ManageEventDetails />}
            />
            {/* <Route path="myevent" element={<MyEvent />} /> */}
            <Route path="saved" element={<InterestedEvents />} />
            {/* <Route path="saved" element={<SavedEvents />} /> */}

          </Route>
          <Route path="/create_event" element={<CreateEvent />} />
          <Route path="/manage_event/:id" element={<ManageEvent />} />
          <Route path="/event_purchased" element={<EventPurchased />} />
          <Route path="/saved_events" element={<SavedEvents />} />
          <Route path="/event_details/:id" element={<EventDetails />} />
          {/* <Route path="/sell_list" element={<SellList />}>
            <Route path="all" element={<AllItems />} />
            <Route path="manage" element={<SellManage />} />
            <Route path="saved" element={<SavedSell />} />
          </Route> */}
          <Route path="/sell_manage" element={<SellManage />} />
          <Route path="/saved_sell" element={<SavedSell />} />
          <Route path="/create_sell" element={<CreateSell />} />
          <Route path="/update-sell" element={<UpdateSell />} />

          <Route path="/job_volunteer" element={<JobVolunteer />} />

          <Route path="/health" element={<HealthProducts />}>
            <Route path="Voucher" element={<HealthVoucherCard />} />
            <Route path="products" element={<HealthProductCard />} />
            <Route path="product/:id" element={<ManageHealthOfferProduct />} />
            <Route path="voucher/:id" element={<ManageHealthOfferVoucher />} />
            <Route path="my-cart" element={<MyCart />} />
            <Route path="PlaceOrder" element={<PlaceOrder />} />
            <Route path="Payment-Page" element={<PaymentPage />} />
            <Route path="orders" element={<Orders />} />
            <Route path="order-details/:id" element={<OrderDetails />} />
            <Route path="wishlist" element={<MyWishlistHealth />} />
          </Route>

          <Route path="/fashion" element={<FashionProducts />}>
            <Route path="voucher" element={<FashionVoucherCard />} />
            <Route path="products" element={<FashionProductCard />} />
            <Route path="product/:id" element={<ManageFashionOfferProduct />} />
            <Route path="voucher/:id" element={<ManageFashionOfferVoucher />} />
            <Route path="my-cart" element={<MyCart />} />
            <Route path="PlaceOrder" element={<PlaceOrder />} />
            <Route path="Payment-Page" element={<PaymentPage />} />
            <Route path="orders" element={<Orders />} />
            <Route path="order-details" element={<OrderDetails />} />
            <Route path="wishlist" element={<MyWishlistFashion />} />
          </Route>
          <Route path="/electronics" element={<ElectronicProducts />}>
            <Route path="voucher" element={<ElectronicsVoucherCard />} />
            <Route path="products" element={<ElectronicsProductCard />} />
            <Route
              path="product/:id"
              element={<ManageElectronicsOfferProduct />}
            />
            <Route
              path="voucher/:id"
              element={<ManageElectronicsOfferVoucher />}
            />
            <Route path="my-cart" element={<MyCart />} />
            <Route path="PlaceOrder" element={<PlaceOrder />} />
            <Route path="Payment-Page" element={<PaymentPage />} />
            <Route path="orders" element={<Orders />} />
            <Route path="order-details" element={<OrderDetails />} />
            <Route path="wishlist" element={<MyWishlistElectronic />} />
          </Route>
          <Route path="/office" element={<OfficeProducts />}>
            <Route path="voucher" element={<OfficeVoucherCard />} />
            <Route path="products" element={<OfficeProductCard />} />
            <Route path="product/:id" element={<ManageOfficeOfferProduct />} />
            <Route path="voucher/:id" element={<ManageOfficeOfferVoucher />} />
            <Route path="my-cart" element={<MyCart />} />
            <Route path="PlaceOrder" element={<PlaceOrder />} />
            <Route path="Payment-Page" element={<PaymentPage />} />
            <Route path="orders" element={<Orders />} />
            <Route path="order-details" element={<OrderDetails />} />
            <Route path="wishlist" element={<MyWishlistOffer />} />
          </Route>
          <Route path="/sports" element={<SportsProducts />}>
            <Route path="voucher" element={<VoucherCard />} />
            <Route path="product/:id" element={<ManageOfferProduct />} />
            <Route path="voucher/:id" element={<ManageOfferVoucher />} />
            <Route path="products" element={<ProductCard />} />
            <Route path="my-cart" element={<MyCart />} />
            <Route path="PlaceOrder" element={<PlaceOrder />} />
            <Route path="Payment-Page" element={<PaymentPage />} />
            <Route path="orders" element={<Orders />} />
            <Route path="order-details" element={<OrderDetails />} />
            <Route path="wishlist" element={<MyWishlistSport />} />
          </Route>
          <Route path="/dining" element={<DiningProducts />}>
            <Route path="voucher" element={<DiningVoucherCard />} />
            <Route path="products" element={<DiningProductCard />} />
            <Route path="product/:id" element={<ManageDiningOfferProduct />} />
            <Route path="voucher/:id" element={<ManageDiningOfferVoucher />} />
            <Route path="my-cart" element={<MyCart />} />
            <Route path="PlaceOrder" element={<PlaceOrder />} />
            <Route path="Payment-Page" element={<PaymentPage />} />
            <Route path="orders" element={<Orders />} />
            <Route path="order-details" element={<OrderDetails />} />
            <Route path="wishlist" element={<MyWishlistDining />} />
          </Route>
          <Route path="/jewellery" element={<JewelleryProducts />}>
            <Route path="voucher" element={<JewelleryVoucherCard />} />
            <Route path="products" element={<JewelleryProductCard />} />
            <Route
              path="product/:id"
              element={<ManageJewelleryOfferProduct />}
            />
            <Route
              path="voucher/:id"
              element={<ManageJewelleryOfferVoucher />}
            />
            <Route path="my-cart" element={<MyCart />} />
            <Route path="PlaceOrder" element={<PlaceOrder />} />
            <Route path="Payment-Page" element={<PaymentPage />} />
            <Route path="orders" element={<Orders />} />
            <Route path="order-details" element={<OrderDetails />} />
            <Route path="wishlist" element={<MyWishlistJewellery />} />
          </Route>
          <Route path="/digital" element={<DigitalProducts />}>
            <Route path="voucher" element={<DigitalVoucherCard />} />
            <Route path="products" element={<DigitalProductCard />} />
            <Route path="product/:id" element={<ManageDigitalOfferProduct />} />
            <Route path="voucher/:id" element={<ManageDigitalOfferVoucher />} />
            <Route path="my-cart" element={<MyCart />} />
            <Route path="PlaceOrder" element={<PlaceOrder />} />
            <Route path="Payment-Page" element={<PaymentPage />} />
            <Route path="orders" element={<Orders />} />
            <Route path="order-details" element={<OrderDetails />} />
            <Route path="wishlist" element={<MyWishlistDigital />} />
          </Route>

          <Route path="/Offers-Card" element={<OffersCard />} />
          <Route path="/Offers-Product" element={<OffersProduct />} />

          <Route path="/Order-Details" element={<OrderDetails />} />
          <Route path="/My-Wishlist" element={<MyWishlist />} />
          <Route path="/Product-Offer" element={<Product />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/tnc" element={<TnC />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/set_location" element={<SetLocation />} />
          <Route path="/create_profile/:id" element={<CreateProfile />} />
          {/* <Route path="/settings" element={<Settinglisting />} /> */}
          <Route path="/property_item" element={<PropertyItem />} />
          <Route path="/forum_list" element={<ForumList />} />
          <Route path="/create_forum" element={<CreateForum />} />
          <Route path="/manage_forum" element={<ManageForum />} />
          {/* <Route path="/events" element={<EventsList />}>
            <Route path="all" element={<AllEvent />} />
            <Route path="details/:id" element={<EventDetailsView />} />
            <Route path="myevent" element={<MyEvent />} />
            <Route path="saved" element={<SavedEvents />} />
          </Route> */}
          <Route path="/create_event" element={<CreateEvent />} />
          <Route path="/manage_event/:id" element={<ManageEvent />} />
          <Route path="/event_purchased" element={<EventPurchased />} />
          <Route path="/saved_events" element={<SavedEvents />} />
          <Route path="/event_details" element={<EventDetails />} />
          {/* <Route path="/sell_list" element={<SellList />}>
            <Route path="all" element={<AllItems />} />
            <Route path="manage" element={<SellManage />} />
            <Route path="saved" element={<SavedSell />} />
          </Route> */}
          <Route path="/sell_list" element={<SellList />}>
            <Route path="create" element={<CreateSellSwapProperty />} />
            <Route path="edit/:id" element={<EditSellSwapProperty />} />
            <Route path="all" element={<AllSellSwapProperty />} />
            <Route path="details/:id" element={<SellDetails />} />
            <Route path="manage" element={<AllManageSellSwapProperty />} />
            <Route
              path="manage/details/:id"
              element={<ManageSellSwapDetails />}
            />
            <Route path="saved" element={<SavedSell />} />
          </Route>
          <Route path="/sell_manage" element={<SellManage />} />
          <Route path="/saved_sell" element={<SavedSell />} />
          <Route path="/create_sell" element={<CreateSell />} />
          <Route path="/job_volunteer" element={<JobVolunteer />} />

          <Route path="/MyOder" element={<MyOder />} />

          <Route path="/settings_About" element={<SettingAbout />} />
          <Route path="/settings_Privecy" element={<SettingPrivecy />} />
          <Route path="/TermsConditions" element={<TermsConditions />} />
          <Route path="/career-details/:id" element={<CareerDetails />} />
          <Route path="/of-details" element={<OfferDetailsCard />} />
          <Route path="/elec-details/:id" element={<ElectronicDetails />} />
          <Route path="/fashion-details/:id" element={<FashionDetails />} />
          <Route path="/jewellery-details/:id" element={<JewelleryDetails />} />
          <Route path="/digital-Details/:id" element={<DigitalDetails />} />
          <Route path="/health-details/:id" element={<HealthDetails />} />
          <Route path="/office-details/:id" element={<OfficeDetails />} />
          <Route path="/office-Sport/:id" element={<OfficeDetailsSport />} />
          <Route path="/dining-details/:id" element={<DiningDetails />} />
          <Route path="/housing-details/:id" element={<HousingDetails />} />
          <Route path="/confirm" element={<ConfirmMail />} />
          <Route path="/comment" element={<ForumComments />} />
          <Route path="/comment/:id" element={<ForumComments />} />
        </Routes>
        <Routes>
          <Route
            path="/connection_swip"
            element={<ConnectionsSwip setLoggedIn={setLoggedIn} />}
          />
          <Route path="/search" element={<SearchResults />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/new" element={<NewPassword />} />
          <Route path="/update-Event/:id" element={<UpdateEvent />} />
          <Route path="/update-sell/:id" element={<UpdateSell />} />
          <Route path="/Detail-sell/:id" element={<DetailsSell />} />
        </Routes>

        {/* dining products */}
      </div>
    </div>
  );
}

export default App;

import React, { useContext, useState, useEffect } from 'react'
import { AppContext } from '../context/AppContext'
import { Link, useLocation, useParams } from 'react-router-dom'
import logo from '../../src/assets/images/logo.png'
import profile_sbm_icon from '../../src/assets/images/profile_sbm_icon.svg'
import profile_sbm_icon02 from '../../src/assets/images/profile_sbm_icon02.svg'
import profile_sbm_icon03 from '../../src/assets/images/profile_sbm_icon03.svg'
import profile_sbm_icon04 from '../../src/assets/images/profile_sbm_icon04.svg'
import profile_sbm_icon05 from '../../src/assets/images/profile_sbm_icon05.svg'
import profile_sbm_icon06 from '../../src/assets/images/profile_sbm_icon06.svg'
import profile_sbm_icon07 from '../../src/assets/images/profile_sbm_icon07.svg'
import profile_sbm_icon08 from '../../src/assets/images/profile_sbm_icon08.svg'
import profile_sbm_icon09 from '../../src/assets/images/profile_sbm_icon09.svg'
import settings from '../../src/assets/images/settings.svg'
import chat from '../../src/assets/images/chat.svg'
import { NavLink } from 'react-router-dom'
import login_logo from '../assets/images/login_logo.svg'

function Sidebar(props) {
  /* State to store width of the screen start*/
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  /* Use effect to handle resizing of the screen so that horizontal bar start and end points can be calculated correctly
based on changed width of the screen */
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  /* State to store width of the screen end*/

  const { sidebar, setSidebar, notificationTotal } = useContext(AppContext)
  const showSidebar = () => {
    setSidebar(!sidebar)
  }
  const [subMenuOpen, setSubMenuOpen] = useState(false)
  const [subMenuOpen2, setSubMenuOpen2] = useState(false)
  const [subMenuOpen3, setSubMenuOpen3] = useState(false)
  const [subMenuOpen4, setSubMenuOpen4] = useState(false)
  const [subMenuOpen5, setSubMenuOpen5] = useState(false)
  const toggleMenu = (x) => {
    setSubMenuOpen(!subMenuOpen)
    setSubMenuOpen2(false)
    setSubMenuOpen3(false)
    setSubMenuOpen4(false)
    setSubMenuOpen5(false)
  }
  const toggleMenu2 = (x) => {
    setSubMenuOpen2(!subMenuOpen2)
    setSubMenuOpen(false)
    setSubMenuOpen3(false)
    setSubMenuOpen4(false)
    setSubMenuOpen5(false)
  }

  const toggleMenu3 = (x) => {
    setSubMenuOpen3(!subMenuOpen3)
    setSubMenuOpen(false)
    setSubMenuOpen2(false)
    setSubMenuOpen4(false)
    setSubMenuOpen5(false)
  }

  const toggleMenu4 = (x) => {
    setSubMenuOpen4(!subMenuOpen4)
    setSubMenuOpen(false)
    setSubMenuOpen2(false)
    setSubMenuOpen3(false)
    setSubMenuOpen5(false)
  }

  const toggleMenu5 = (x) => {
    setSubMenuOpen5(!subMenuOpen5)
    setSubMenuOpen(false)
    setSubMenuOpen2(false)
    setSubMenuOpen3(false)
    setSubMenuOpen4(false)
  }

  const id = useParams().id

  const currentRoute = useLocation().pathname

  return (
    <aside
      id="cx-sidebar"
      className={sidebar ? 'cx-active' : null}
      style={{ top: '20px' }}
    >
      <div className={`cx-sidebar-brand ${sidebar ? 'cx-active' : null} `}>
        <div className="icon-chats ">
          <div class=" position-relative icon-div  ">
            <Link to="/notification">
              <img
                src={login_logo}
                alt="login logo"
                style={{
                  height: '40px',
                  width: '40px',
                  border: '1px solid #262727',
                  padding: '5px',
                  borderRadius: '50px',
                  backgroundColor: '#262727',
                }}
                className="logo-ic "
              />
              <span
                class="position-absolute red-c top-0 start-100 translate-middle badge rounded-pill bg-danger pb-2"
                style={{
                  borderRadius: '50px',
                  width: '25px',
                  height: '25px',
                  fontSize: '12px',
                  padding: '0',
                  margin: '0',
                  paddingTop: '7px',
                }}
              >
                {notificationTotal}
                <span class="visually-hidden">unread messages</span>
              </span>
            </Link>
          </div>
          
        </div>
      </div>
      
      <ul className={`cx-list ${sidebar ? 'cx-active' : null} `}>
        <div class="force-overflow"></div>
        <li
          onClick={() => {
            if (screenWidth < 768) {
              setSidebar(false)
            }
          }}
        >
          <NavLink
            exact
            className={currentRoute === `/create_profile/${id}` ? 'active' : ''}
            activeClassName="active"
            to="/dashboard"
          >
            <img
              src={profile_sbm_icon}
              alt=""
              style={{ height: '16px', width: '16px' }}
            />
            <span>Profile</span>
          </NavLink>
          {/* <div className="cx-tooltip">Dashboard</div> */}
        </li>

        <li
          onClick={() => {
            if (screenWidth < 768) {
              setSidebar(false)
            }
          }}
        >
          <NavLink
            exact
            className={
              currentRoute === '/create_group' ||
              currentRoute === '/groups_call'
                ? 'active'
                : ''
            }
            activeClassName="active"
            to="/groups"
          >
            <img
              src={profile_sbm_icon02}
              alt=""
              style={{ height: '16px', width: '16px' }}
            />
            <span>Chats</span>
          </NavLink>
        </li>

        <li
          onClick={() => {
            if (screenWidth < 768) {
              setSidebar(false)
            }
          }}
        >
          <NavLink
            exact
            className={
              currentRoute === `/ConnectionAbout/${id}` ? 'active' : ''
            }
            activeClassName="active"
            to="/connection_swip"
          >
            <img
              src={profile_sbm_icon03}
              alt=""
              style={{ height: '16px', width: '16px' }}
            />
            <span>Connections</span>
          </NavLink>
        </li>

        <li
          onClick={() => {
            if (screenWidth < 768) {
              setSidebar(false)
            }
          }}
        >
          <NavLink
            exact
            className={
              currentRoute === '/house_listing/all' ||
              // currentRoute === `/house_listing/manage/${id}` ||
              currentRoute === `/house_listing/details/${id}` ||            
              currentRoute === `/house_listing/edit/${id}` ||
              currentRoute === `/housing-details/${id}` ||
              currentRoute === `/UpdateProperty/${id}` ||
              currentRoute === '/house_listing/manage' ||
              currentRoute === `/house_listing/manage/details/${id}` ||
              currentRoute === '/create_property'
                ? 'active'
                : ''
            }
            activeClassName="active"
            to="/house_listing/all"
          >
            <img
              src={profile_sbm_icon04}
              alt=""
              style={{ height: '16px', width: '16px' }}
            />
            <span>Housing</span>
          </NavLink>
        </li>

        <li
          onClick={() => {
            if (screenWidth < 768) {
              setSidebar(false)
            }
          }}
        >
          <NavLink
            exact
            className={
              currentRoute === '/events/all' ||
              currentRoute === `/events/details/${id}` ||
              currentRoute === `/events/myevent/details/${id}` ||
              currentRoute === `/events/hosting/${id}` ||
              currentRoute === `/events/edit/${id}` ||
              currentRoute === '/events/hosting' ||
              currentRoute === `/event_details/${id}` ||
              currentRoute === `/update-Event/${id}` ||
              currentRoute === '/events/myevent' ||
              currentRoute === '/events/saved' ||
              currentRoute === '/create_event'
                ? 'active'
                : ''
            }
            activeClassName="active"
            to="/events/all"
          >
            <img
              src={profile_sbm_icon06}
              alt=""
              style={{ height: '16px', width: '16px' }}
            />
            <span>Events</span>
          </NavLink>
        </li>
{/* 
        <li
          onClick={() => {
            if (screenWidth < 768) {
              setSidebar(false)
            }
          }}
        >
          <NavLink
            exact
            className={
              currentRoute === '/adCampaing/all' ||
              currentRoute === `/adCampaing/manage/${id}` ||
              currentRoute === `/adCampaing/edit/${id}` ||
              currentRoute === `/housing-details/${id}` ||
              currentRoute === `/UpdateProperty/${id}` ||
              currentRoute === '/adCampaing/manage' ||
              currentRoute === '/create_ad'
                ? 'active'
                : ''
            }
            activeClassName="active"
            to="/adCampaing/all"
          >
            <img
              src={profile_sbm_icon04}
              alt=""
              style={{ height: '16px', width: '16px' }}
            />
            <span>Ad Campaing</span>
          </NavLink>
        </li> */}

        <li
          onClick={() => {
            if (screenWidth < 768) {
              setSidebar(false)
            }
          }}
        >

          <NavLink
            exact
            className={
              currentRoute === '/career/all' ||
              currentRoute === `/career/details/${id}` ||
              currentRoute === `/career/edit/${id}` ||
              currentRoute === `/housing-details/${id}` ||
              currentRoute === `/UpdateProperty/${id}` ||
              currentRoute === '/career/manage' ||
              currentRoute === `/career/manage/details/${id}` ||

              currentRoute === '/create_career'
                ? 'active'
                : ''
            }
            activeClassName="active"
            to="/career/all"
          >
            <img
              src={profile_sbm_icon04}
              alt=""
              style={{ height: '16px', width: '16px' }}
            />
            <span>Job/Volunteer</span>
          </NavLink>
        </li>

        
        <li
          onClick={() => {
            if (screenWidth < 768) {
              setSidebar(false)
            }
          }}
        >

          <NavLink
            exact
            className={
              currentRoute === '/orderManagement/all' ||
              currentRoute === `/housing-details/${id}` ||
              currentRoute === `/UpdateProperty/${id}` ||
              currentRoute === '/orderManagement/manage' ||
              currentRoute === '/create_order'
                ? 'active'
                : ''
            }
            activeClassName="active"
            to="/orderManagement/all"
          >
            <img
              src={profile_sbm_icon04}
              alt=""
              style={{ height: '16px', width: '16px' }}
            />
            <span>Order Management</span>
          </NavLink>
        </li>


        <li
          onClick={() => {
            if (screenWidth < 768) {
              setSidebar(false)
            }
          }}
        >
          <NavLink
            exact
            className={currentRoute === '/create_forum' ? 'active' : ''}
            activeClassName="active"
            to="/forum_list"
          >
            <img
              src={profile_sbm_icon05}
              alt=""
              style={{ height: '16px', width: '16px' }}
            />
            <span>Form</span>
          </NavLink>
        </li>

        

        

        <li
          onClick={() => {
            if (screenWidth < 768) {
              setSidebar(false)
            }
          }}
        >
          <NavLink
            exact
            activeClassName="active"
            to="/Offers-Card"
            className={
              currentRoute === '/offers_listing' ||
              currentRoute === '/Offers-Product' ||
              currentRoute === '/Offers-Card' ||
              currentRoute === '/health/voucher' ||
              currentRoute === '/health/products' ||
              currentRoute === '/health/my-cart' ||
              currentRoute === '/health/orders' ||
              currentRoute === '/health/wishlist' ||
              currentRoute === '/health/order-details' ||
              currentRoute === '/health/PlaceOrder' ||
              currentRoute === '/health/Payment-Page' ||
              // fashion
              currentRoute === '/fashion/voucher' ||
              currentRoute === '/fashion/products' ||
              currentRoute === '/fashion/my-cart' ||
              currentRoute === '/fashion/orders' ||
              currentRoute === '/fashion/wishlist' ||
              currentRoute === '/fashion/order-details' ||
              currentRoute === '/fashion/PlaceOrder' ||
              currentRoute === '/fashion/Payment-Page' ||
              // electronics
              currentRoute === '/electronics/voucher' ||
              currentRoute === '/electronics/products' ||
              currentRoute === '/electronics/my-cart' ||
              currentRoute === '/electronics/orders' ||
              currentRoute === '/electronics/wishlist' ||
              currentRoute === '/electronics/order-details' ||
              currentRoute === '/electronics/PlaceOrder' ||
              currentRoute === '/electronics/Payment-Page' ||
              //office
              currentRoute === '/office/voucher' ||
              currentRoute === '/office/products' ||
              currentRoute === '/office/my-cart' ||
              currentRoute === '/office/orders' ||
              currentRoute === '/office/wishlist' ||
              currentRoute === '/office/order-details' ||
              currentRoute === '/office/PlaceOrder' ||
              currentRoute === '/office/Payment-Page' ||
              //sports
              currentRoute === '/sports/voucher' ||
              currentRoute === '/sports/products' ||
              currentRoute === '/sports/my-cart' ||
              currentRoute === '/sports/orders' ||
              currentRoute === '/sports/wishlist' ||
              currentRoute === '/sports/order-details' ||
              currentRoute === '/sports/PlaceOrder' ||
              currentRoute === '/sports/Payment-Page' ||
              //dining
              currentRoute === '/dining/voucher' ||
              currentRoute === '/dining/products' ||
              currentRoute === '/dining/my-cart' ||
              currentRoute === '/dining/orders' ||
              currentRoute === '/dining/wishlist' ||
              currentRoute === '/dining/order-details' ||
              currentRoute === '/dining/PlaceOrder' ||
              currentRoute === '/dining/Payment-Page' ||
              //jewellery
              currentRoute === '/jewellery/voucher' ||
              currentRoute === '/jewellery/products' ||
              currentRoute === '/jewellery/my-cart' ||
              currentRoute === '/jewellery/orders' ||
              currentRoute === '/jewellery/wishlist' ||
              currentRoute === '/jewellery/order-details' ||
              currentRoute === '/jewellery/PlaceOrder' ||
              currentRoute === '/jewellery/Payment-Page' ||
              //digital
              currentRoute === '/digital/voucher' ||
              currentRoute === '/digital/products' ||
              currentRoute === '/digital/my-cart' ||
              currentRoute === '/digital/orders' ||
              currentRoute === '/digital/wishlist' ||
              currentRoute === '/digital/order-details' ||
              currentRoute === '/digital/PlaceOrder' ||
              currentRoute === '/digital/Payment-Page'
                ? 'active'
                : ' '
            }
          >
            <img
              src={profile_sbm_icon07}
              alt=""
              style={{ height: '16px', width: '16px' }}
            />
            <span>Offers</span>
          </NavLink>
        </li>

        <li
          onClick={() => {
            if (screenWidth < 768) {
              setSidebar(false)
            }
          }}
        >
          <NavLink
            exact
            className={
              currentRoute === '/sell_list/all' ||
              currentRoute === '/sell_list/manage' ||
              currentRoute === `/Detail-sell/${id}` ||
              currentRoute === `/update-sell/${id}` ||
              currentRoute === '/sell_list/saved' ||
              currentRoute === '/create_sell'
                ? 'active'
                : ''
            }
            activeClassName="active"
            to="/sell_list/all"
          >
            <img
              src={profile_sbm_icon08}
              alt=""
              style={{ height: '16px', width: '16px' }}
            />
            <span>Sell / Swap</span>
          </NavLink>
        </li>

        <li
          onClick={() => {
            if (screenWidth < 768) {
              setSidebar(false)
            }
          }}
        >
          {/* <NavLink
            exact
            className={
              currentRoute === '/job_volunteer' ||
              currentRoute === '/career-details'
                ? 'active'
                : ''
            }
            activeClassName="active"
            to="/job_volunteer"
          >
            <img
              src={profile_sbm_icon09}
              alt=""
              style={{ height: '16px', width: '16px' }}
            />
            <span>Job / Volunteer</span>
          </NavLink> */}
        </li>

        <li
          onClick={() => {
            if (screenWidth < 768) {
              setSidebar(false)
            }
          }}
        >
          <NavLink
            exact
            className={
              currentRoute === '/settings_About' ||
              currentRoute === '/settings_Privecy' ||
              currentRoute === '/about' ||
              currentRoute === '/privacy_policy' ||
              currentRoute === '/TermsConditions' ||
              currentRoute === '/TermsConditions'
                ? 'active'
                : ''
            }
            activeClassName="active"
            to="/settings"
          >
            <img
              src={settings}
              alt=""
              style={{ height: '16px', width: '16px' }}
            />
            <span>Settings</span>
          </NavLink>
          {/* <div className="cx-tooltip">Dashboard</div> */}
        </li>
      </ul>
    </aside>
  )
}

export default Sidebar

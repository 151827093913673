import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, useLocation, NavLink, Outlet } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import thumbImg from "../../assets/images/watch.png";
import location_icon from "../../assets/images/location_icon.svg";
import back from "../../assets/images/back.svg";
import gray_location_icon from "../../assets/images/gray_location_icon.svg";
import chatListAvatar01 from "../../assets/images/chatListAvatar01.png";
import chat_icon_sidebar from "../../assets/images/chat_icon_sidebar.svg";
import search_icon from "../../assets/images/search_icon.svg";
import result_icon from "../../assets/images/saleThumb02.png";
import fillter_icon from "../../assets/images/fillter_icon.svg";
import DiningVoucher from "./DiningVoucher";
import DiningCard from "./DiningCard";
import MyWishlist from "./MyWishlist";
import DiningWishList from "./DiningWishList";
import VoucherHealth from "./VoucherHealth";
import HealthCard from "./HealthCard";
import MyCart from "./MyCart";
import Orders from "./Orders";
import MyWishlistFashion from "./MyWishlistFashion";
import VoucherFasion from "./VoucherFasion";
import FashionCard from "./FashionCard";

function Dining() {
  const [keyword, setKeyword] = useState("");
  const ipRef = useRef(0);
  function handleSearch() {
    setKeyword(ipRef.current.value);
  }
  const { sidebar, setSidebar } = useContext(AppContext);
  const [filter, setFilter] = useState(
    useLocation().pathname === "/electronics/products"
  );
  const [showElement, setShowElement] = React.useState(true);

  useEffect(() => {
    setTimeout(function () {
      setShowElement(false);
    }, 5000);
  });

  // api starts

  const Accordion = ({ title, children }) => {
    const [isOpen, setOpen] = React.useState(false);
    return (
      <div className="accordion-wrapper">
        <div
          className={`accordion-title ${isOpen ? "open" : ""}`}
          onClick={() => setOpen(!isOpen)}
        >
          {title}
        </div>
        <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
          <div className="accordion-content">{children}</div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div>
        <Sidebar />

        {/* <Header /> */}
        <main id="cxMain" className={sidebar ? "cx-main cx-active" : "cx-main"}>
          <section
            className="content-main"
            style={{
              backgroundColor: "white",
              borderRadius: "20px",
              height: "fit-content",
              minHeight: "calc(100vh - 156px)",
              padding: "20px",
            }}
          >
            <ul class="nav nav-pills mb-3 " id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link tab-btn active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Voucher
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link tab-btn"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Restaurent
                </button>
              </li>
              <li
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                style={{
                  cursor: "pointer",
                }}
              >
                <img src={fillter_icon} alt="" />
              </li>
              <li>
                <div
                  className="search-input"
                  style={{
                    width: "224px",
                  }}
                >
                  <input
                    ref={ipRef}
                    type="text"
                    name="text"
                    id="text"
                    placeholder="Search property"
                    style={{
                      width: "100%",
                    }}
                  />
                  <img
                    src={search_icon}
                    alt="search"
                    onClick={() => handleSearch()}
                  />
                </div>
              </li>

              <li class="nav-item" role="presentation">
                <button
                  class="nav-link tab-btn "
                  id="pills-cart-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-cart"
                  type="button"
                  role="tab"
                  aria-controls="pills-cart"
                  aria-selected="false"
                >
                  My Cart
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link tab-btn "
                  id="pills-order-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-order"
                  type="button"
                  role="tab"
                  aria-controls="pills-order"
                  aria-selected="false"
                >
                  My Orders
                </button>
              </li>
              <li
                class="nav-item"
                role="presentation"
                style={{
                  marginLeft: "auto",
                }}
              >
                <Link to="/fashion/wishlist">
                  <button
                    class="nav-link tab-btn"
                    id="pills-contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                    My Wishlist
                  </button>
                </Link>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
                tabindex="0"
              >
                <VoucherFasion />
              </div>
              <div
                class="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
                tabindex="0"
              >
                <FashionCard keyword={keyword} />
              </div>
              <div
                class="tab-pane fade"
                id="pills-cart"
                role="tabpanel"
                aria-labelledby="pills-cart-tab"
                tabindex="0"
              >
                <MyCart />
              </div>
              <div
                class="tab-pane fade"
                id="pills-order"
                role="tabpanel"
                aria-labelledby="pills-order-tab"
                tabindex="0"
              >
                <Orders />
              </div>
              <div
                class="tab-pane fade"
                id="pills-contact"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
                tabindex="0"
              >
                <MyWishlistFashion />
              </div>
            </div>
          </section>
        </main>
      </div>

      {/* modal starts */}
      {/* <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          style={{
            margin: "auto",
            width: "80%",
            maxWidth: "377px",
          }}
        >
          <div
            class="modal-content"
            style={{
              borderRadius: "20px",
              backgroundColor: "#F5F5F5",
              padding: "20px",
            }}
          >
            <p className="pf-heading">Filter Dining</p>
            <div className="pf-body">
              {" "}
              <Accordion title="Price">
                <div className="pf-option">
                  <input type="checkbox" id="check1" />
                  <label htmlFor="check1">100 $ - 200 $</label>
                </div>
              </Accordion>
              <div className="input-cover">
                <input
                  type="text"
                  name="text"
                  id="text"
                  className="pf-search"
                  placeholder="Search Dining"
                />
                <img src={search_icon} alt="search" />
              </div>
              <div className="search-results">
                <div className="search-result d-flex flex-column  flex-sm-row align-items-center ">
                  <img src={result_icon} alt="icon" />
                  <span className="mt-2 mt-sm-0">The Kings Restaurant</span>
                </div>
                <div className="search-result d-flex flex-column  flex-sm-row align-items-center ">
                  <img src={result_icon} alt="icon" />
                  <span className="mt-2 mt-sm-0">The Kings Restaurant</span>
                </div>
              </div>
            </div>

            <div
              className="text-center"
              style={{
                marginTop: "20px",
              }}
            >
              <button
                className="ep-action"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{
                  marginTop: "0px",
                }}
              >
                OKAY
              </button>
            </div>
          </div>
        </div>
      </div> */}
      {/* modal ends */}
    </>
  );
}

export default Dining;
{
  /* <div
className="globalPages_tabWrapper"
style={{
  marginBottom: "20px",
  width: "100%",
  marginRight: "0px",
  marginLeft: "0px",
}}
>
<div
  className="gb_tabs"
  style={{
    minWidth: "852px",
    overflow: "auto",
  }}
>
  <div
    className="tabs"
    style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      minWidth: "1024px",
      overflowX: "auto",
    }}
  >
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        columnGap: "20px",
      }}
    >
      <div
        id="pills-home-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-home"
        type="button"
        role="tab"
        aria-controls="pills-home"
        aria-selected="true"
        onClick={() => setFilter(false)}
        to={`/dining/voucher`}
        className={({ isActive }) =>
          isActive ? "nav-active" : "nav-inactive"
        }
        style={{
          width: "130px",
        }}
      >
        Voucher
      </div>
      <div
        id="pills-profile-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-profile"
        type="button"
        role="tab"
        aria-controls="pills-profile"
        aria-selected="false"
        onClick={() => setFilter(true)}
        // to={`/dining/products`}
        className={({ isActive }) =>
          isActive ? "nav-active" : "nav-inactive"
        }
        style={{
          width: "130px",
        }}
      >
        Product
      </div>
    </div>

    {filter ? (
      <button className="filter-ic-btn border-0">
        <Link
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          to="#"
          style={{
            border: "none",
            margin: "0",
            padding: "0px 0px",
            zIndex: "1061",
            marginRight:
              "                                                                                                                                                                                                                                                               m,mm,mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,llllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllll,l,l,,,ll                             ,l  mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,l,llllllllllllllllllllllllllllllllllmmmmmmm,lllllllllllllllllllllllllllllll                                                                                                                                                                                                                                                                                                                                                                                                                                                  0px",
          }}
        >
          <img src={fillter_icon} alt="" />
        </Link>
      </button>
    ) : (
      <></>
    )}
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        columnGap: "20px",
      }}
    >
      <div
        className="search-input"
        style={{
          width: "224px",
        }}
      >
        <input
          type="text"
          name="text"
          id="text"
          placeholder="Search property"
          style={{
            width: "100%",
          }}
        />
        <img src={search_icon} alt="search" />
      </div>
      <div>
        <NavLink
          onClick={() => setFilter(false)}
          to="/dining/my-cart"
          className={({ isActive }) =>
            isActive ? "nav-active" : "nav-inactive"
          }
          style={{
            width: "130px",
          }}
        >
          My Cart
        </NavLink>
      </div>
      <div>
        <NavLink
          onClick={() => setFilter(false)}
          to="/dining/orders"
          className={({ isActive }) =>
            isActive ? "nav-active" : "nav-inactive"
          }
          style={{
            width: "130px",
          }}
        >
          My Orders
        </NavLink>
      </div>
      <div>
        <NavLink
          onClick={() => setFilter(false)}
          to="/dining/wishlist"
          className={({ isActive }) =>
            isActive ? "nav-active" : "nav-inactive"
          }
          style={{
            width: "130px",
          }}
        >
          My Wishlist
        </NavLink>
      </div>
    </div>
  </div>
</div>
</div>
<div class="tab-content" id="pills-tabContent">
<div
  class="tab-pane fade show active"
  id="pills-home"
  role="tabpanel"
  aria-labelledby="pills-home-tab"
  tabindex="0"
>
  Voucher Tab
</div>
<div
  class="tab-pane fade"
  id="pills-profile"
  role="tabpanel"
  aria-labelledby="pills-profile-tab"
  tabindex="0"
>
  Product Tab
</div>
<div
  class="tab-pane fade"
  id="pills-contact"
  role="tabpanel"
  aria-labelledby="pills-contact-tab"
  tabindex="0"
>
  My Cart
</div>
</div> */
}

import React, { useState, useContext, useEffect } from "react";
// import login_logo from "../assets/images/login_logo.svg";
import { Link, useNavigate } from "react-router-dom";
import email_icon from "../assets/images/email_icon.svg";
import lock_icon from "../assets/images/lock_icon.svg";
import facebook_icon from "../assets/images/facebook_icon.svg";
import gmail_icon from "../assets/images/gmail_icon.svg";
import login_logo_lottie from "../assets/images/login_logo_lottie";
// import Lottie from 'react-lottie'
import swal from "sweetalert";
import { signInWithFaceBook, signInWithGoogle } from "./Firebase";

import {
  PostCallWithErrorResponse,
  findUser,
  findUserById,
  getAuth0Token,
  getAuth0UserDetails,
  saveUserDetails,
  simplePostCall,
} from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import Barloader from "./BarLoader";
import { AppContext } from "../context/AppContext";

const Login = ({ setLoggedIn }) => {
  const { setCustomerData } = useContext(AppContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const [socialLogin, setSocialLogin] = useState(false);
  const [facebookLogin, setFacebookLogin] = useState(false);
  const [userDetails, setUserDetails] = useState({
    email: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState({ email: "", password: "" });
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: login_logo_lottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // const Userlogin = (e) => {
  //   e.preventDefault();
  //   if (userDetails.email === "" || userDetails.password === "") {
  //     if (userDetails.email === "") {
  //       setErrMsg({ ...errMsg, email: "Enter Email ID " });
  //       return;
  //     }
  //     if (userDetails.password === "") {
  //       setErrMsg({ ...errMsg, password: "Enter Password " });
  //       return;
  //     }
  //   } else {
  //     setLoading(true);
  //     PostCallWithErrorResponse(ApiConfig.UNIVERSR_LOGIN_API, {
  //       ...userDetails,
  //     })
  //       .then((res) => {
  //         setLoading(false);
  //         console.log(res);
  //         if (res.json.user_details.verify == false) {
  //           swal(
  //             "Registration successfully done. Admin will verify account. Please login after 1 hour"
  //           );
  //         } else {
  //           localStorage.setItem(
  //             "auth_token",
  //             res.json.user_details.auth_token
  //           );
  //           localStorage.setItem("location", res.json.user_details.location);
  //           localStorage.setItem("lat", res.json.user_details.lat);
  //           localStorage.setItem("long", res.json.user_details.long);
  //           localStorage.setItem("radius", res.json.user_details.radius);

  //           localStorage.setItem("id", res.json.user_details.id);

  //           localStorage.setItem(
  //             "userDetails",
  //             JSON.stringify(res.json.user_details)
  //           );
  //           localStorage.setItem("logedIn", true);
  //           setCustomerData({
  //             id: res.json.user_details.id,
  //             auth_token: res.json.user_details.auth_token,
  //             lat: res.json.user_details.lat,
  //             long: res.json.user_details.long,
  //             location: res.json.user_details.location,
  //             radius: res.json.user_details.radius,
  //           });
  //           setLoggedIn(true);
  //         }
  //       })

  //       .catch((err) => {
  //         setLoading(false);
  //         swal("User Does not Exist! or Please Check Credentials");
  //         // swal({
  //         //   title: "warning",
  //         //   text: "User Does not Exist! or Please Check Credentials",
  //         //   icon: "success",
  //         //   button: true,
  //         // });
  //         console.log(err);
  //       });
  //   }
  // };

  //////////////// Start ///

  useEffect(() => {
    if (
      (socialLogin || facebookLogin) &&
      userDetails.email.length > 0 &&
      userDetails.displayName
    )
      Userlogin();
  }, [socialLogin, userDetails, facebookLogin]);

  // const loginIn = (requestBody) => {
  //   setLoading(true)

  //   let url
  //   let data
  //   if (socialLogin || facebookLogin) {
  //     url = ApiConfig.SOCIAL_LOGIN
  //     data = requestBody
  //   } else {
  //     url = ApiConfig.UNIVERSR_LOGIN_API
  //     data = userDetails
  //   }
  //   PostCallWithErrorResponse(url, data)
  //     .then((res) => {
  //       setLoading(false)
  //       console.log(res)
  //       if (res.json.user_details.verify == false) {
  //         swal(
  //           // "Registration successfully done. Admin will verify account. Please login after 1 hour"
  //           'User Does not Exist! Please Registration ',
  //         )
  //       }
  //       if (res.json.user_details.verify == false) {
  //         swal(
  //           'Registration successfully done. Admin will verify account. Please login after 1 hour',
  //         )
  //       }
  //       if (res.json.user_details.reject == true) {
  //         swal(
  //           'Verification rejected, please check your email for further Details',
  //         )
  //       } else {
  //         localStorage.setItem('auth_token', res.json.user_details.auth_token)
  //         localStorage.setItem('location', res.json.user_details.location)
  //         localStorage.setItem('lat', res.json.user_details.lat)
  //         localStorage.setItem('long', res.json.user_details.long)
  //         localStorage.setItem('radius', res.json.user_details.radius)

  //         localStorage.setItem('id', res.json.user_details.id)

  //         localStorage.setItem(
  //           'userDetails',
  //           JSON.stringify(res.json.user_details),
  //         )
  //         localStorage.setItem('logedIn', true)
  //         setCustomerData({
  //           id: res.json.user_details.id,
  //           auth_token: res.json.user_details.auth_token,
  //           lat: res.json.user_details.lat,
  //           long: res.json.user_details.long,
  //           location: res.json.user_details.location,
  //           radius: res.json.user_details.radius,
  //         })
  //         setLoggedIn(true)
  //       }
  //     })
  //     .catch((err) => {
  //       setLoading(false)

  //       swal('User Does not Exist! or Please Check Credentials')
  //       // swal({
  //       //   title: "warning",
  //       //   text: "User Does not Exist! or Please Check Credentials",
  //       //   icon: "success",
  //       //   button: true,
  //       // });
  //       console.log(err)
  //     })
  // }

  const loginIn = async (requestBody) => {
    setLoading(true);

    let url;
    let data;
    if (socialLogin || facebookLogin) {
      // url = ApiConfig.SOCIAL_LOGIN
      data = requestBody;
    } else {
      // url = ApiConfig.UNIVERSR_LOGIN_API
      data = userDetails;
    }

    try {
      const response = await getAuth0Token(data);
      if (response) {
        // Destructure the tokens and other information from the response
        const { access_token, id_token, expires_in, token_type, scope } =
          response;
        console.log(response);
        const token = access_token;

        // Save tokens and other relevant information to local storage
        localStorage.setItem("access_token", access_token);
        localStorage.setItem("logedIn", true);
        localStorage.setItem("id_token", id_token);
        localStorage.setItem("expires_in", expires_in.toString());
        localStorage.setItem("token_type", token_type);
        localStorage.setItem("scope", scope);

        // localStorage.setItem("auth_response", JSON.stringify(response));

        const userDetails = await getAuth0UserDetails(token);
        console.log("Additional user details:", userDetails.email_verified);

        if (userDetails.email_verified === false) {
          swal("Please verify your email");
          setLoading(false);
          return;
        }

        localStorage.setItem("user_details", JSON.stringify(userDetails));
        setLoading(false);

        const url = `http://localhost:3080/userFlutter/auth0/${userDetails.sub}`;
        const searchUser = await findUserById(url, token);
        console.log("searchUser", searchUser);

        if (searchUser === "user not found") {
          // await saveUserDetails(userDetails.email, userDetails.sub);
          navigate("/onboarding");
        } else {
          if (searchUser.status === "notverified") {
            swal("Verification failed, please try again.");
            navigate("/notverified");
          } else if (searchUser.status === "submit") {
            swal(
              "Registration successfully done. Admin will verify your account. Please login after 1 hour."
            );
            navigate("/pendingverification");
          } else if (searchUser.status === "verified") {
            navigate("/dashboard");
          } else {
            swal("plz fill user detail ");
            navigate("/onboarding");
          }
        }
      }
    } catch (error) {
      console.error("Error during authentication:", error);
      // Optionally, handle the error and set loading to false
      setLoading(false);
    }
  };

  const Userlogin = (e) => {
    e && e.preventDefault();
    const regex = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
    if (socialLogin || facebookLogin) {
      let requestBody = {
        social_id: userDetails.uid,
        login_type: facebookLogin ? "facebook" : "gmail",
        email: userDetails.email.toLocaleLowerCase(),
        full_name: userDetails.displayName,
        username: userDetails.uid,
        log_in_status: true,
      };
      loginIn(requestBody);
    } else {
      if (userDetails.email === "" || userDetails.password === "") {
        if (userDetails.email === "") {
          // setErrMsg({ ...errMsg, email: 'Enter Email ID ' })
          swal("Please enter Email!");
          return;
        }
        if (!regex.test(userDetails.email)) {
          // setErrMsg({ ...errMsg, email: 'Please Enter Valid Email ID ' })
          swal("Please Enter Valid Email ID");
          return;
        }
        if (userDetails.password === "") {
          // setErrMsg({ ...errMsg, password: 'Please enter Password!' })
          swal("Please enter Password!");
          return;
        }
      } else {
        setLoading(true);
        loginIn();
      }
    }
  };

  return (
    <div className="auth_mainWrapper">
      {loading && <Barloader />}
      <div
        className="auth_innerWrapper"
        style={{
          padding: "0",
          height: "calc(100vh - 40px)",
        }}
      >
        <div className="auth_twoPane">
          <div
            className="auth_banner"
            style={{
              height: "calc(100vh - 40px)",
            }}
          >
            <div className="auth_logoWrapper">
              <div className="auth_logoHolder">
                <div className="animatedLogo">
                  {/* <Lottie options={defaultOptions} /> */}
                </div>
                <h1>
                  We use tech to create,
                  <br /> connect, and grow
                </h1>
              </div>
            </div>
          </div>

          <div className="auth_formWrapper">
            <div className="auth_innerformWrapper">
              <div className="auth_header_control auth_formbg1">
                <Link className="authLink" to="#">
                  <h1>Login</h1>
                </Link>
                <Link to="/signup">
                  <h2>Sign Up</h2>
                </Link>
              </div>

              <div className="auth_formHolder auth_formHolderBG1">
                <form>
                  <div
                    className="auth_inputWrapper auth_Gapper1"
                    style={{
                      padding: "30px",
                      height: "280px",
                    }}
                  >
                    <div
                      className="auth_iHolder"
                      style={{
                        padding: "0",
                        margin: "0",
                      }}
                    >
                      <img
                        src={email_icon}
                        alt="user name"
                        style={{ height: "13px" }}
                      />
                      <input
                        autoComplete="off"
                        type="email"
                        placeholder="Email Address"
                        class="form-control "
                        id="inputEmail"
                        // required
                        value={userDetails.email}
                        onChange={(e) => {
                          setErrMsg({ ...errMsg, email: "" });
                          setUserDetails({
                            ...userDetails,
                            email: e.target.value,
                          });
                        }}
                      />
                    </div>

                    {/* {
                      errMsg.email &&
                      swal(errMsg.email)
                      // <span className="text-danger">{errMsg.email}</span>
                    } */}

                    <div
                      className="auth_iHolder"
                      style={{
                        padding: "0",
                        margin: "0",
                        marginTop: "10px",
                      }}
                    >
                      <img
                        src={lock_icon}
                        alt="user name"
                        style={{ marginTop: "-7px", height: "17px" }}
                      />
                      <input
                        type="password"
                        placeholder="Password"
                        class="form-control"
                        id="inputPassword"
                        required
                        value={userDetails.password}
                        onChange={(e) => {
                          setErrMsg({ ...errMsg, password: "" });
                          setUserDetails({
                            ...userDetails,
                            password: e.target.value,
                          });
                        }}
                      />
                    </div>
                    {/* <small
                      style={{
                        color: 'red',
                      }}
                    >
                      {errMsg.password ? 'Please Enter Password' : <>&nbsp;</>}
                    </small> */}
                    {/* {
                      errMsg.password &&
                      <span className="text-danger">{errMsg.password}</span>
                    } */}

                    <div
                      className="auth_iHolder authLink"
                      style={{
                        margin: "0",
                        padding: "0",
                      }}
                    >
                      <Link to="/confirm">Forgot password?</Link>
                    </div>
                  </div>
                  <div className="auth_controlWrapper">
                    {/* <button>Login</button> */}

                    <button type="submit" onClick={Userlogin}>
                      Login
                    </button>
                  </div>
                </form>
              </div>

              <div className="oAuthWrapper">
                <h1>or login with</h1>
                <ul>
                  <li>
                    <Link to="#" className="google">
                      <img
                        src={gmail_icon}
                        alt=""
                        onClick={() => {
                          signInWithGoogle().then((res) => {
                            console.log(res);

                            if (res.user) {
                              setUserDetails({ ...res.user.providerData[0] });
                              setSocialLogin(true);
                            }
                          });
                        }}
                      />
                    </Link>
                  </li>

                  <li>
                    <Link to="#" className="google">
                      <img
                        src={facebook_icon}
                        alt=""
                        onClick={() => {
                          signInWithFaceBook().then((res) => {
                            console.log(res);
                            if (res.user) {
                              setUserDetails({ ...res.user.providerData[0] });
                              setFacebookLogin(true);
                            }
                          });
                        }}
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Login
               <Link to="/dashboard" >dashboard</Link> */}
    </div>
  );
};

export default Login;

import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../../context/AppContext";
import Sidebar from "../../../components/Sidebar";
import Header from "../../../components/Header";
import ConnectionHeader from "../../connections/connection_profile/ConnectionHeader";
import ConnectionAbout from "../../connections/connection_profile/ConnectionAbout";
// import Friend_requestSection from "./dashboard_friend_request/Friend_requestSection";
import report_icon from "../../../assets/images/report_icon.svg";

function ConnectionProfile() {
  const { sidebar, setSidebar } = useContext(AppContext);

  const [showElement, setShowElement] = React.useState(true);
  useEffect(() => {
    setTimeout(function () {
      setShowElement(false);
    }, 5000);
  }, []);
  return (
    <>
      <Sidebar />

      {/* <Header /> */}
      <main id="cxMain" className={sidebar ? "cx-main cx-active" : "cx-main"}>
        <section className="content-main">
          <div className="row">
            <div className="col-md-12">
              <ConnectionHeader />
            </div>
          </div>

          <div className="row ">
            <div className="col-md-12">
              <ConnectionAbout />
            </div>
          </div>
        </section>
      </main>

      <div className="gray_modalWrapper">
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  <img src={report_icon} alt="" />
                  Report Mary Fernandez
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div className="profile_modal_contentWrapper">
                  <div className="profile_form">
                    <div className="row">
                      <div className="uploadPP_inputTextRow">
                        <label class="form-label">
                          Why are you reporting this user?
                        </label>
                        <div className="upp_selectWrapper">
                          <select
                            class="form-select"
                            aria-label="Default select example"
                          >
                            <option selected>Inappropriate Behaviour</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="uploadPP_inputTextRow">
                        <label class="form-label">
                          Please describe your reason for reporting.
                        </label>

                        <textarea placeholder="Type here"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary">
                  Cancel
                </button>
                <button type="button" class="btn btn-primary">
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="gray_modalWrapper">
        <div
          class="modal fade"
          id="exampleModal2"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  <img src={report_icon} alt="" />
                  Block Mary Fernandez
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div className="profile_modal_contentWrapper">
                  <div className="profile_form">
                    <div className="row">
                      <div className="uploadPP_inputTextRow">
                        <label class="form-label">
                          Why are you reporting this user?
                        </label>
                        <p>
                          See your posts Invite you to events or groups Message
                          you Add you as a friend
                        </p>
                        <p>
                          If you're friends, blocking Mary will also unfriend
                          her.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary">
                  Cancel
                </button>
                <button type="button" class="btn btn-primary">
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConnectionProfile;

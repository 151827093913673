import React, { useContext, useState, useEffect } from "react";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
// import HousingTabsHeader from "./HousingTabsHeader";
import thumbImg from "../../assets/images/house01.png";
import location_icon from "../../assets/images/location_icon.svg";
import gray_location_icon from "../../assets/images/gray_location_icon.svg";
import tooltip_icon from "../../assets/images/tooltip_icon.svg";
import dining from "../../assets/images/diningd.png";
import { simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import LoaderCenter from '../../sharedComponent/LoaderCenter'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function DiningDetails() {
  // api stats
   const params = useParams();
   let id = params.id;
     const { customerData } = useContext(AppContext);
     const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({});
  const [healthproducts, sethProductsDetails] = useState([]);
 


  useEffect(() => {
    getDetails();
  }, []);
 
  const getDetails = async () => {
    const data = await simpleGetCallWithErrorResponse(
      ApiConfig.DINING_DETAILS + id
    );
   
    setDetails(data?.json?.details);
  };
 

  const { sidebar, setSidebar } = useContext(AppContext);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showElement, setShowElement] = React.useState(true);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [toolOpen, setToolOpen] = useState(false);

  const [filter, setFilter] = useState(
    useLocation().pathname === "/electronics/products"
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <Sidebar />

      {/* <Header /> */}
      <main
        id="cxMain"
        className={sidebar ? "cx-main cx-active" : "cx-main"}
        style={{
          height: "calc(100vh - 156px)",
        }}
      >
           {loading ? (
            <LoaderCenter />
          ) : (
           
        <section className="content-main">
       
          <div className="row">
            <div className="col-xl-5 detail-section col-12 order-2 order-xl-1">
              <div
                className="gbl_thumbWrapper gbl_thumbWrapper02"
                style={{ marginTop: "0 ", padding: "20px", minHeight: "617px" }}
              >
                <div className="tab-container">
                  <div
                    className="globalPages_tabWrapper"
                    style={{
                      width: "100%",
                      marginRight: "0px",
                      marginLeft: "0px",
                    }}
                  >
                    <div
                      className="gb_tabs"
                      style={{
                        minWidth: "200px",
                        overflow: "auto",
                      }}
                    >
                      <div className="tabs">
                        <div>
                          <NavLink
                            onClick={() => setFilter(false)}
                            to={`/dining/voucher`}
                            className={({ isActive }) =>
                              isActive ? "nav-active" : "nav-inactive"
                            }
                          >
                            Voucher
                          </NavLink>
                        </div>
                        <div>
                          <NavLink
                            onClick={() => setFilter(true)}
                            to={`/dining/products`}
                            className={({ isActive }) =>
                              isActive ? "nav-active" : "nav-inactive"
                            }
                          >
                            Product
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="img_thumbWrapper img_thumbWrapperAdj01"
                  style={{ padding: "20px 0", position: "relative" }}
                >
                  <Link to="/dining" id="dining-back">
                    <i class="fa-solid fa-arrow-left"></i>
                  </Link>

                  <div class="dropdown dot-button dining-ver    ">
                    <button
                      class="btn"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{
                        width: "34px",
                        height: "34px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <i class="fa-solid fa-ellipsis-vertical"></i>
                    </button>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <Link to="#" class="dropdown-item" href="#">
                          Edit
                        </Link>
                      </li>

                      <li>
                        <Link to="#" class="dropdown-item" href="#">
                          View Details
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <img
                    className="thumbImage"
                    src={details.image}
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                </div>

                <div
                  className="gbl_thumbContent"
                  style={{
                    padding: "0",
                  }}
                >
                  <div className="detail-heading">
                    <h1
                      style={{
                        color: "#1D1D25",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      {details.product_name}
                    </h1>
                    <div className="details-actions">
                      <Link
                        className=""
                        to=""
                        onClick={() => setToolOpen(!toolOpen)}
                      >
                        <img src={tooltip_icon} alt="" />
                      </Link>
                    </div>
                  </div>

                  <h6
                    style={{
                      color: "#1D1D25",
                      fontSize: "16px ",
                      fontWeight: "600",
                    }}
                  >
                    by{" "}
                    <Link to="" style={{ textDecoration: "underline" }}>
                      {details.link}
                    </Link>
                  </h6>

                  <div
                    className="starting-price d-flex align-items-center"
                    style={{
                      columnGap: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <span
                      className="label"
                      style={{
                        color: "#707070",
                        fontSize: "16px",
                        fontWeight: "normal",
                      }}
                    >
                      Starting from
                    </span>
                    <span
                      className="price"
                      style={{
                        color: "#1D1D25",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      {details.price + "/-"}
                    </span>
                  </div>

                  <div className="gbl_thumbControlWrapper gbl_thumbControlWrapper02">
                    <h1
                      style={{
                        color: "#1D1D25",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                     Description
                    </h1>
                    <p
                      style={{
                        color: "#707070",
                        fontSize: "14px",
                        fontWeight: "600",
                        marginTop: "10px",
                      }}
                    >
                      {details.product_description}
                    </p>
                  </div>
                </div>
                <div
                  className={`mainLocWrapper ${
                    windowDimensions.width >= 1200
                      ? "right-popup"
                      : "left-popup"
                  }`}
                >
                  {" "}
                  <div
                    className={`tooltipInfoWrapper ${
                      toolOpen ? "tool-open" : "tool-close"
                    }`}
                  >
                    <div
                      className={`triangle-left ${
                        windowDimensions.width >= 1200
                          ? "right-icon"
                          : "left-icon"
                      } `}
                    ></div>
                    <div className="ttInfoHeader">
                      <h1>Location & Address</h1>
                      <Link
                        to="#"
                        style={{ margin: "0" }}
                        onClick={() => setToolOpen(false)}
                      >
                        <i class="fa-solid fa-xmark"></i>
                      </Link>
                    </div>

                    <div className="ttMapWrapper">
                      <iframe
                        className="actAsDiv"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                        src="https://maps.google.com/maps?f=q&amp;source=s_q&amp;q=Adobe%20Systems%20Inc%2C%20Park%20Avenue%2C%20San%20Jose%2C%20CA&amp;aq=0&amp;ie=UTF8&amp;t=m&amp;z=12&amp;iwloc=A&amp;output=embed"
                      >
                        {" "}
                      </iframe>
                    </div>

                    <div className="ttAddressWrapper">
                      <h1>Address</h1>
                      <p>{details.address}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
           
        </section>
         )}

      </main>
    </div>
  );
}

export default DiningDetails;

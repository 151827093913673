import React, { useContext, useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { AppContext } from '../../context/AppContext'
import Sidebar from '../../components/Sidebar'
import Header from '../../components/Header'
import thumbImg from '../../assets/images/watch.png'
import location_icon from '../../assets/images/location_icon.svg'
import close_sIcon from '../../assets/images/close_sIcon.svg'
import gray_location_icon from '../../assets/images/gray_location_icon.svg'
import chatListAvatar01 from '../../assets/images/chatListAvatar01.png'
import chat_icon_sidebar from '../../assets/images/chat_icon_sidebar.svg'
import ApiConfig from '../../api/ApiConfig'
import LoaderCenter from '../../sharedComponent/LoaderCenter'
import {
  deleteWithAuthCall,
  simpleGetCallWithErrorResponse,
  simplePostCall,
} from '../../api/ApiServices'
import swal from 'sweetalert'

function MyCart() {
  const {
    sidebar,
    setSidebar,
    setchecked,
    cartchecked,
    setTotalPrice,
    setTotalItem,
  } = useContext(AppContext)
  const navigate = useNavigate()
  const currentRoute = useLocation().pathname
  const [showElement, setShowElement] = React.useState(true)
  const [ordersCart, setOrderCart] = useState([])

  const [totalCount, setTotalCount] = useState(0)
  const [refresh, setRefresh] = useState(true)
  const { customerData } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const [price, setPrice] = useState(0)
  // console.log('cartStatus', cartStatus)
  useEffect(() => {
    getOrder()
  }, [])

  useEffect(() => {
    let total_price = 0
    cartchecked.map((cartData) => {
      total_price =
        total_price +
        cartData.price *
          (!isNaN(cartData.cart_quantity) ? cartData.cart_quantity : 1)
    })
    setPrice(total_price)
    setTotalPrice(total_price)

    let cartids = cartchecked.map((cartData) => {
      return cartData.cart_id
    })
    setTotalCount(cartids)
    setTotalItem(cartids)
  }, [cartchecked])

  const getOrder = () => {
    setLoading(true)
    simpleGetCallWithErrorResponse(
      ApiConfig.ADD_TO_CART,

      JSON.stringify({ ...customerData }),
    ).then((res) => {
      console.log(res)

      setLoading(false)
      setOrderCart(res.json.detail)
      setTotalCount(
        res.json.detail[0].id.length ? res.json.details[0].id.length : 0,
      )
    })
  }

  const handleCart = () => {
    if (totalCount === 0) {
      swal('Please Select the product')
    } else {
      navigate('/health/PlaceOrder')
    }
  }

  const handleShop = () => {
    navigate('/health/PlaceOrder');
  }

  useEffect(() => {
    setTimeout(function () {
      setShowElement(false)
    }, 5000)
  }, [])

  const removeImage = (index) =>
    setOrderCart((cartchecked) => cartchecked.filter((_, i) => i !== index))

  const DeleteHealt = (id) => {
    deleteWithAuthCall(
      ApiConfig.DELETE_CART_ITEM + id + '&sub_type=' + ordersCart[0].subtype,
    )
      .then((res) => {
        console.log(res)
        swal(res.detail)
        // getHealthWish()
        getOrder()
      })
      .catch((err) => {
        setLoading(false)

        console.log(err)
      })
  }

  return (
    <>
      {loading ? (
        <LoaderCenter />
      ) : (
        <div
          className="row"
          style={{
            maxWidth: '685px',
            margin: '0 auto',
          }}
        >
          {ordersCart && ordersCart.length > 0 ? (
            ordersCart.map((odercartlist, index) => {
              console.log(odercartlist)
              return (
                <>
                  <div className="col-12 col-lg-6">
                    <div className="gbl_thumbWrapper-card">
                      <div className="img_thumbWrapper">
                        <div class="dropdown">
                          <button
                            class="btn"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i
                              onClick={() => DeleteHealt(odercartlist.id)}
                              class="fa-solid fa-xmark"
                            ></i>
                          </button>
                        </div>
                        <img
                          src={odercartlist.image}
                          height="158px"
                          alt=""
                          style={{
                            objectFit: 'cover',
                          }}
                        />
                      </div>

                      <div className="gbl_thumbContent">
                        <div
                          style={{
                            marginLeft: '-20px',
                            marginTop: '20px',
                            position: 'absolute',
                          }}
                        >
                          <label
                            key={'odercartlist' + index}
                            value={odercartlist.cart_id}
                          >
                            <input
                              type="checkbox"
                              required
                              class="radio-custom"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setchecked([...cartchecked, odercartlist])
                                } else {
                                  setchecked(
                                    cartchecked.filter(
                                      (data) =>
                                        data.cart_id !== odercartlist.cart_id,
                                    ),
                                  )
                                }
                              }}
                            />
                            <span class="radio-custom-label"></span>
                          </label>
                        </div>
                        <h1
                          style={{
                            fontSize: '16px',
                            fontWeight: '500',
                            color: 'hsla(240, 12%, 13%, 1)',
                            marginTop: '5px',
                            letterSpacing: '0',
                            font: 'normal normal 600 16px/22px Nunito',
                            opacity: '0px',
                          }}
                        >
                          {odercartlist.product_name}
                        </h1>
                        <p
                          style={{
                            color: 'var(--unnamed-color-707070)',
                            font: 'normal normal normal 14px/19px Nunito',
                            letterSpacing: '0px',
                            color: 'hsla(0, 0%, 44%, 1)',
                            opacity: '1px',
                            marginTop: '12px',
                          }}
                        >
                          Quantity {odercartlist.cart_quantity}
                        </p>

                        <p
                          style={{
                            color: '#21212C',
                            font: 'normal normal bold 16px/22px Nunito',
                            letterSpacing: '0px',
                            opacity: '1px',
                            marginTop: '-35px',
                            float: 'right',
                          }}
                        >
                          {odercartlist.price}/-
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )
            })
          ) : (
            <p
              style={{
                fontSize: '25px',
                margin: 'auto',
              }}
              className=" text-center justify-content-center"
            >
              Your cart is empty! 
            </p>
          )}
         {
          ordersCart.length > 0 ? 
          <div className="col-12 ">
          <div className="gbl_thumbWrapper">
            <div className="gbl_thumbContent">
              <div
                style={{
                  font: 'normal normal 600 16px/22px Nunito',
                  letterSpacing: '0px',
                  textAlign: 'left',
                  color: '#1D1D25',
                  // marginLeft: '-18px',
                  marginTop: '18px',
                }}
                class="card-header bg-transparent"
              >
                Price Details
              </div>
              <div className="d-flex justify-content-between">
                <p
                  style={{
                    color: 'var(--unnamed-color-707070)',
                    font: 'normal normal normal 14px/19px Nunito',
                    letterSpacing: '0px',
                    color: 'hsla(0, 0%, 44%, 1)',
                    opacity: '1px',
                    marginTop: '12px',
                  }}
                >
                  Price ({totalCount.length} items)
                </p>
                <p
                  style={{
                    color: '#21212C',
                    font: 'normal normal bold 14px/19px Nunito',
                    letterSpacing: '0px',
                    opacity: '1px',
                    marginTop: '12px',
                  }}
                >
                  {/* {ordersCart.reduce(
                    (total, item) => total + item.price * item.quantity,
                    0,
                  )} */}
                  ${price}
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p
                  style={{
                    color: 'var(--unnamed-color-707070)',
                    font: 'normal normal normal 14px/19px Nunito',
                    letterSpacing: '0px',
                    color: 'hsla(0, 0%, 44%, 1)',
                    opacity: '1px',
                    marginTop: '12px',
                  }}
                >
                  Discount
                </p>
                <p
                  style={{
                    color: '#21212C',
                    font: 'normal normal bold 14px/19px Nunito',
                    letterSpacing: '0px',
                    opacity: '1px',
                    marginTop: '12px',
                  }}
                >
                  0/-
                </p>
              </div>
              <div class="card-header bg-transparent"></div>
              <div className="d-flex justify-content-between">
                <p
                  style={{
                    color: '#1D1D25',
                    font: 'normal normal 600 16px/22px Nunito',
                    letterSpacing: '0px',
                    opacity: '1px',
                    marginTop: '12px',
                  }}
                >
                  Total Price
                </p>
                <p
                  style={{
                    color: '#1D1D25',
                    font: 'normal normal 600 16px/22px Nunito',
                    letterSpacing: '0px',
                    opacity: '1px',
                    marginTop: '12px',
                  }}
                >
                  ${price}
                </p>
              </div>
            </div>
          </div>
        </div> : ""
         }
          <div className="col-12 mt-3 d-flex justify-content-center">
           {
            ordersCart.length > 0 ?
            <button
            style={{
              width: '158px',
              height: '50px',
              backgroundColor: '#00B4BD',

              border: 'none',
              borderRadius: '25px',
              color: '#FFFFFF',
              fontSize: '14px',
              fontWeight: '600',
            }}
          >
            <div
              onClick={() => handleCart()}
              style={{
                display: 'block',
                height: '100%',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Place Order
            </div>
          </button> : 
           <button
           style={{
             width: '158px',
             height: '50px',
             backgroundColor: '#00B4BD',

             border: 'none',
             borderRadius: '25px',
             color: '#FFFFFF',
             fontSize: '14px',
             fontWeight: '600',
           }}
         >
           <div
             onClick={() => navigate('/health/products')}
             style={{
               display: 'block',
               height: '100%',
               color: 'white',
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
             }}
           >
             Shop Now
           </div>
         </button>
           }
          </div>
        </div>
      )}
    </>
  )
}

export default MyCart

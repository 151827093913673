import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import privacy_policy_tron from "../assets/images/privacy_policy_tron.svg";
import { getWithAuthCallWithtext } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import LoaderCenter from "./LoaderCenter";

function PrivacyPolicy() {
  const { sidebar, setSidebar } = useContext(AppContext);
  const [Privacypoli, setPrivacyPoli] = useState("");
  const [loading, setLoading] = useState(false);

  const [showElement, setShowElement] = React.useState(true);

  useEffect(() => {
    Privacy();
  }, []);
  const Privacy = () => {
    setLoading(true);

    getWithAuthCallWithtext(ApiConfig.UNIVERSR_PRIVACY_POLICY)
      .then((data) => {
        setLoading(false);

        setPrivacyPoli(data.text);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      <Sidebar />

      {/* <Header /> */}

      <main id="cxMain" className={sidebar ? "cx-main cx-active" : "cx-main"}>
        {loading ? (
          <LoaderCenter />
        ) : (
          <section className="content-main">
            <div className="row">
              <div className="col-md-12">
                <div className="pages_screenWrapper">
                  <div className="gbl_jumbotron">
                    <h1>Privacy Policy</h1>
                    <img src={privacy_policy_tron} alt="" />
                  </div>

                  <div className="gbl_paraWrapper">
                    {/* <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.Lorem ipsum dolor sit amet,
                consectetur adipiscing eli
              </p>

              <p>Lorem ipsum dolor sit amet,
                consectetur adipiscing elit, sed do eiusmod tempor incididunt
                ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                ea commodo consequat. Duis aute irure dolor in reprehenderit
                in voluptate velit esse cillum dolore eu fugiat nulla
                pariatur. Excepteur sint occaecat cupidatat non proident, sunt
                in culpa qui officia deserunt mollit anim id est laborum.Lorem
                ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum
                dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                minim veniam, quis nostrud exercitation ullamco laboris nisi
                ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non
                proident, sunt in culpa qui officia deserunt mollit anim id
                est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing
                elit.
              </p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                ullamco laboris nisi ut aliquip.</p> */}
                    <p dangerouslySetInnerHTML={{ __html: Privacypoli }}></p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
    </div>
  );
}

export default PrivacyPolicy;

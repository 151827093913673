import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { AppContext } from "../../context/AppContext";
import abtimg from "../../assets/images/terms&conditons.svg";
import { getWithAuthCallWithtext } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import LoaderCenter from "./../../sharedComponent/LoaderCenter";

const TermsConditions = () => {
  const { sidebar, setSidebar } = useContext(AppContext);
  const [termscondition, setTermsCondition] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    TermsCondition();
  }, []);
  const TermsCondition = () => {
    setLoading(true);

    getWithAuthCallWithtext(ApiConfig.UNIVERSR_TERMSANDCONDITION_API)
      .then((data) => {
        setLoading(false);

        setTermsCondition(data.text);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      {/* <Header /> */}
      <Sidebar />

      <main id="cxMain" className={sidebar ? "cx-main cx-active" : "cx-main"}>
        {loading ? (
          <LoaderCenter />
        ) : (
          <section
            className="content-main"
            style={{ backgroundColorcolor: "#F5F5F5" }}
          >
            <div class="setabtmain">
              <div class="mainabt1">
                <div>
                  <p>Terms and Conditions </p>
                </div>
                <div>
                  <img src={abtimg} alt="Img" />
                </div>
              </div>
              <div class="mainabt2">
                {/* <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est
                laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                <br />
                <br />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est
                laborum.Lorem ipsum dolor sit amet, consectetur adipiscing
                elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                aute irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.Lorem ipsum dolor sit amet,
                consectetur adipiscing elit.
                <br />
                <br />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est
                laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p> */}
                <p dangerouslySetInnerHTML={{ __html: termscondition }}></p>
              </div>
            </div>
          </section>
        )}
      </main>
    </div>
  );
};

export default TermsConditions;

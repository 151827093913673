import React, { useState } from 'react'
import login_logo from '../assets/images/login_logo.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import image from '../assets/images/login_banner.png'
import lock from '../assets/images/lock_icon.svg'
import ApiConfig from '../api/ApiConfig'
import { simplePostCallAll } from '../api/ApiServices'
import swal from 'sweetalert'

function NewPassword() {
  const [data, setData] = useState({})
  const navigate = useNavigate()
  const previousPageData = useLocation().state
  const [errMsg, setErrMsg] = useState({ password: null })
  const [Confirm_password, setConfirm_password] = useState('')
  const [state, setState] = useState({
    otpDigit1: '',
    otpDigit2: '',
    otpDigit3: '',
    otpDigit4: '',
    password: '',
    email:'',
    //  email:previousPageData.email,
  })


  const handleSubmitSet = (e) => {
    e.preventDefault()
    if (state.otpDigit1 === '') {
      // setErrMsg({ ...errMsg, otpDigit1: 'Please enter otpDigit1! ' })
      swal('OTP Required')
      return
    }
    if (state.password === '') {
      setErrMsg({ ...errMsg, password: 'Please enter password! ' })
      return
    }
    // else if (state.password.length < 8) {
    //   setErrMsg({
    //     ...errMsg,
    //     password: 'Password must be at least 8 characters',
    //   })
    //   return
    // }
    else {
      const num = /[0-9]/
      const smallAlpha = /[a-z]/
      const capAlpha = /[A-Z]/
      const mix = /^\w+$/
      if (!smallAlpha.test(state.password)) {
        //alert("Password should be a mixture of at least one uppercase, lowercase, numeric & special character.");
        setErrMsg({
          ...errMsg,
          password:
            ' Password must be at least 8 characters, Password should be a mixture of at least one uppercase, lowercase, numeric & special character.',
        })
        return false
      }
      if (!capAlpha.test(state.password)) {
        //alert("Password should be a mixture of at least one uppercase, lowercase, numeric & special character.");
        setErrMsg({
          ...errMsg,
          password:
            'Password should be a mixture of at least one uppercase, lowercase, numeric & special character.',
        })
        return false
      }
      if (!num.test(state.password)) {
        //alert("Password should be a mixture of at least one uppercase, lowercase, numeric & special character.");
        setErrMsg({
          ...errMsg,
          password:
            'Password should be a mixture of at least one uppercase, lowercase, numeric & special character.',
        })
        return false
      }
      if (mix.test(state.password)) {
        //alert("Password should be a mixture of at least one uppercase, lowercase, numeric & special character.");
        setErrMsg({
          ...errMsg,
          password:
            'Password should be a mixture of at least one uppercase, lowercase, numeric & special character.',
        })
        return false
      }
      if (Confirm_password == '') {
        setErrMsg({
          ...errMsg,
          Confirm_password: 'Please enter confirm password!',
        })
        return
      }
     
      if (Confirm_password != state.password) {
        setErrMsg({
          ...errMsg,
          Confirm_password: '"Password and Confirm Password do not match"',
        })
        return
      }

      var otp =
        state.otpDigit1 + state.otpDigit2 + state.otpDigit3 + state.otpDigit4
      var password = state.password
      var email = previousPageData.state.email;
      var email = state.email

      simplePostCallAll(
        ApiConfig.UNIVERSR_SET_PASSWORD,
        JSON.stringify({
          ...previousPageData,
          email: email,
          otp: otp,
          password: Confirm_password,
        }),
      )
        .then((data) => {
        
          if (data.detail === 'Password saved') {
            swal('Password reset successfully')
            swal(data.detail)
            navigate('/')
          } else {
            swal(data.detail)
            // swal('OTP Required')
          }
        })
        .catch((err) => console.log(err))
    }
  }

  const handleEnter = (event) => {
    var validkeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
    if (validkeys.includes(event.key)) {
      event.target.value = event.key
      const form = event.target.form
      const index = [...form].indexOf(event.target)

      form.elements[index + 1] && form.elements[index + 1].focus()
      event.preventDefault()
    }
  }
  return (
    <>
      <div
        className="row m-0"
        style={{
          padding: '0px',
        }}
      >
        <div
          className="col-12 col-xl-8 banner-img p-0"
          style={{
            position: 'relative',
          }}
        >
          <img
            src={image}
            alt=""
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'cover',
              borderRadius: '20px',
            }}
          />
          <div className="tag_line d-flex flex-column align-items-center align-items-xl-start flex-xl-row w-100">
            <img
              src={login_logo}
              alt=""
              style={{
                height: '80px',
                width: '40px',
                objectFit: 'cover',
              }}
            />
            <span
              style={{
                color: '#FFFFFF',
                fontSize: '30px',
                fontWeight: 'normal',
              }}
            >
              We use tech to create,
              <br />
              connect, and grow
            </span>
          </div>
        </div>
        <div className="col-12 col-xl-4">
          <div
            className=" no-scroll d-flex flex-column align-items-center align-items-xl-start"
            style={{
              padding: ' 0px 30px 0',
              height: 'fit-content',
              maxHeight: 'calc(100vh - 40px)',
              overflow: 'auto',
            }}
          >
            <h3
              style={{
                color: '#1D1D25',
                fontSize: '30px',
                fontWeight: 'bold',
              }}
            >
              Verification Code
            </h3>

            <p
              style={{
                color: '#707070',
                fontSize: '14px',
                fontWeight: 'normal',
              }}
            >
              Please enter the code we sent to
               {/* {previousPageData.email} */}
            </p>

            <form
              className="otp d-flex justify-content-evenly w-100"
              style={{
                padding: '30px 0',

                maxWidth: '320px',
              }}
            >
              <input
                className=" box"
                type="text"
                id="first"
                maxLength="1"
                value={state.otpDigit1}
                onChange={(e) => {
                  setState({
                    ...state,
                    otpDigit1: e.target.value,
                  })
                }}
                onKeyDown={(e) => {
                  setErrMsg({ ...errMsg, otpDigit1: '' })
                  if (String(e.key).match('^[0-9]+$')) {
                 
                    setState({ ...state, otpDigit1: e.key })
                    handleEnter(e)
                  }
                }}
              />
              {/* <small
                style={{
                  color: 'red',
                }}
              >
                {errMsg.otpDigit1 ? errMsg.otpDigit1 : <>&nbsp;</>}
              </small> */}

              <input
                className=" box"
                type="text"
                id="first"
                maxLength="1"
                value={state.otpDigit2}
                  onChange={(e) => {
                  setState({
                    ...state,
                    otpDigit2: e.target.value,
                  })
                }}
                onKeyDown={(e) => {
                  if (String(e.key).match('^[0-9]+$')) {
                    setState({ ...state, otpDigit2: e.key })
                    handleEnter(e)
                  }
                }}
              />
              <input
                className="box"
                type="text"
                id="first"
                maxLength="1"
                value={state.otpDigit3}
                    onChange={(e) => {
                  setState({
                    ...state,
                    otpDigit3: e.target.value,
                  })
                }}
                onKeyDown={(e) => {
                  if (String(e.key).match('^[0-9]+$')) {
                    setState({ ...state, otpDigit3: e.key })
                    handleEnter(e)
                  }
                }}
              />
              <input
                className="box"
                type="text"
                id="first"
                maxLength="1"
                value={state.otpDigit4}
                     onChange={(e) => {
                  setState({
                    ...state,
                    otpDigit4: e.target.value,
                  })
                }}
                onKeyDown={(e) => {
                  if (String(e.key).match('^[0-9]+$')) {
                    setState({ ...state, otpDigit4: e.key })
                    handleEnter(e)
                  }
                }}
              />
            </form>
            <hr
              style={{
                border: '1px solid #707070',
                width: '100%',
              }}
            />
            <h3
              style={{
                color: '#1D1D25',
                fontSize: '30px',
                fontWeight: 'bold',
                marginTop: '30px',
              }}
            >
              Create new password
            </h3>
            <p
              style={{
                color: '#707070',
                fontSize: '14px',
                fontWeight: 'normal',
              }}
            >
              Your new password must be different from previous used passwords.
            </p>

            <form className="w-100">
              <div
                className="conf-div"
                style={{
                  marginTop: '30px',
                  width: '100%',
                  maxWidth: '393px',
                }}
              >
                <input
                  className="confirm-input"
                  type="text"
                  name="text"
                  id="e"
                  placeholder="Password"
                  onChange={(e) => {
                    setErrMsg({ ...errMsg, password: '' })
                    setState({ ...state, password: e.target.value })
                  }}
                  style={{
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #E1E1E1',
                    borderRadius: '25px',
                    height: '50px',
                    padding: '0 50px 0 20px',
                    width: '100%',
                  }}
                />

                {/* <p className="must_8cha mt-2">Must be at least 8 characters.</p> */}

                <img src={lock} alt="" />
              </div>
              <small
                style={{
                  color: 'red',
                }}
              >
                {errMsg.password ? errMsg.password : <>&nbsp;</>}
              </small>
              <p
                className=" w-100 "
                style={{
                  color: '#B8B8B8',
                  fontSize: '14px',
                  fontWeight: 'normal',
                  textAlign: 'center',
                }}
              >
                Must be at least 8 charachters
              </p>
              <div
                className="conf-div"
                style={{
                  marginTop: '30px',
                  width: '100%',
                  maxWidth: '393px',
                }}
              >
                <input
                  className="confirm-input"
                  type="text"
                  name="text"
                  id="e"
                  placeholder="Confirm Password"
                  onChange={(e) => {
                    setErrMsg({ ...errMsg, Confirm_password: '' })
                    setConfirm_password(e.target.value)
                  }}
                  style={{
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #E1E1E1',
                    borderRadius: '25px',
                    height: '50px',
                    padding: '0 50px 0 20px',
                    width: '100%',
                  }}
                />

                {/* <p for="exampleFormControlInput1" className="must_8cha mt-2">
                Both passwords must match.
              </p> */}
                <img src={lock} alt="" />
              </div>
              <small
                style={{
                  color: 'red',
                }}
              >
                {errMsg.Confirm_password ? (
                  errMsg.Confirm_password
                ) : (
                  <>&nbsp;</>
                )}
              </small>
              <p
                className=" w-100 "
                style={{
                  color: '#B8B8B8',
                  fontSize: '14px',
                  fontWeight: 'normal',
                  textAlign: 'center',
                }}
              >
                Must be at least 8 charachters
              </p>
              <div
                className="d-flex justify-content-center w-100"
                style={{
                  marginTop: '30px',
                }}
              >
                <button
                  style={{
                    backgroundColor: '#00B4BD',
                    width: '228px',
                    height: '50px',
                    borderRadius: '25px',

                    border: 'none',
                    color: '#FFFFFF',
                    fontSize: '16px',
                    fontWeight: 'bold',
                  }}
                >
                  <Link
                    // to="#"
                    to="/verification1"
                    style={{
                      color: 'white',
                      display: 'inline-block',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    // onClick={handleSubmitSet}
                  >
                    Reset Password
                  </Link>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewPassword

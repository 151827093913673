import React, { useContext, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AppContext } from '../../context/AppContext'
import Sidebar from '../../components/Sidebar'
import Header from '../../components/Header'
import fillter_icon from '../../assets/images/fillter_icon.svg'
import arrow from '../../assets/images/up_arrow.svg'
import left from '../../assets/images/left.svg'
import LoaderCenter from '../../sharedComponent/LoaderCenter'
import ApiServices, {
  simpleGetCallWithErrorResponse,
  simplePostCall,
} from '../../api/ApiServices'
import ApiConfig from '../../api/ApiConfig'
import swal from 'sweetalert'
import { ShareSocial } from 'react-share-social'
import { Modal, Button } from 'react-bootstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard'


const style = {
  background: '#F5F5F5',
  borderRadius: 3,
  border: 0,
  color: 'white',
  padding: '0 0px',
  // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  height: '200px',
  marginTop: '20px',
  // width: '20px',
}

function JobVolunteer() {
  const [loader, setLoader] = useState(true)
  const [showModal, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => {
    
    setShow(true)
    ShareHandal()
  }
  const navigate = useNavigate()

  // API STARTS

  const [filter, setFilter] = useState([])
  const [filterStatus, setFilterStatus] = useState({
    'Full Time': false,
    'Part Time': false,
    Volunteer: false,
    Remote: false,
    Internship: false,
    'Work-experience': false,
  })

  useEffect(() => {
    if (filter.length) {
      setLoader(true)
      getFilterJobListing()
      setLoader(false)
    } else {
      setLoader(true)
      getJobListing()
      setLoader(false)
    }
  }, [filter])
  function handleFilter(e) {
    const filterList = []
    for (const property in filterStatus) {
      if (filterStatus[property]) {
        filterList.push(property)
      }
    }

    setFilter(filterList)
  }
  const [jobs, setJobs] = useState([])
  //Get Job List

  const getJobListing = async () => {
    const data = await simpleGetCallWithErrorResponse(ApiConfig.JOB_LISTING)

    setJobs(data?.json?.list)
  }
  const getFilterJobListing = async () => {
    const data = await simplePostCall(
      ApiConfig.JOB_LISTING,
      JSON.stringify({
        job_types: filter,
      }),
    )

    setJobs(data?.list)
  }

  // API ENDS
  const { sidebar, setSidebar, customerData } = useContext(AppContext)
  const [showElement, setShowElement] = React.useState(true)
  const [selectedID, setselectedID] = useState("") 
  const [ShareData, setShare] = useState('')

  const HandalId = (id) => {
    setselectedID(id)
  }
  const ShareHandal = async () => {
    let requestBody = JSON.stringify({
      dynamicLinkInfo: {
        domainUriPrefix: 'https://myuniverse.page.link',

        link:
          'https://my-univrs.app.redbytes.in/career-details/' +
          String(selectedID) +
          '/?user=' +
          String(customerData.id),

        androidInfo: {
          androidPackageName: 'com.my_universe',
        },

        iosInfo: {
          iosBundleId: 'org.myUniverse',
        },
      },
    })

    await fetch(
      'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBmt92Aby2xlZWOCIHeTutj8ywpF-4reyw',

      {
        method: 'POST',

        headers: {
          Accept: 'application/json',

          'Content-Type': 'application/json',
        },

        body: requestBody,
      },
    )
      .then((response) => response.json())

      .then((data) => {
        console.log('response', data)
        setShare(data.shortLink)
      })

      .catch((err) => {
        // this.setState({isVisible:false})

        console.log('error', err)
      })
  }



  return (
    <>
      <Sidebar />

      {/* <Header /> */}
      <main id="cxMain" className={sidebar ? 'cx-main cx-active' : 'cx-main'}>
        <section className="content-main">
        <Modal
              className="custom-dialog"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={showModal}
              onHide={handleClose}
            >
              <Modal.Header
                style={{ padding: '12px', width: '99%' }}
                closeButton
              >
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
              {/* Are you sure you want to cancel this Friend Request? */}
              <div class="modal-body" style={{ marginTop: '-37px' }}>
                <ShareSocial
                  style={style}
                  url={ShareData}
                  socialTypes={[
                    'facebook',
                    'twitter',
                    'reddit',
                    'linkedin',
                    'email',
                    'pinterest',
                  ]}
                />
                <CopyToClipboard
                    text={ShareData}
                    onCopy={() => swal('Copied to Clipboard')}
                  >
                    <Link to="#" className='copy-icon'>
                      <i class="fa-solid fa-copy"></i>
                    </Link>
                  </CopyToClipboard>
              </div>
            </Modal.Body>
            </Modal>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="globalPages_tabWrapper d-flex justify-content-between m-0 p-0">
                    <button className="filter-ic-btn border-0">
                      <Link
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal2"
                        style={{
                          border: 'none',
                          margin: '0',
                          padding: '0px 0px',
                          marginRight:
                            '17nm,mn,l                                                                                                                                                                                                                                                                 m,mm,mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,llllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllll,l,l,,,ll                             ,l  mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,l,llllllllllllllllllllllllllllllllllmmmmmmm,lllllllllllllllllllllllllllllll                                                                                                                                                                                                                                                                                                                                                                                                                                                  0px',
                        }}
                      >
                        <img src={fillter_icon} alt="" />
                      </Link>
                    </button>

                    <div className="gb_tabs-2" style={{ width: 'fit-content' }}>
                      <div
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalset"
                      >
                        <Link
                          to="#"
                          className="location-button d-flex align-items-center justify-content-center"
                        >
                          <p>
                            <span
                              className="content"
                              style={{
                                marginLeft: '8px',
                                width: 'fit-content',
                                maxWidth: '100px',
                                textAlign: 'start',
                              }}
                            >
                              {customerData.location}{' '}
                            </span>
                            <span className="dot mx-1"></span>
                            <span>{customerData.radius} Miles </span>
                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="gblThumb_outerWrapper-2"
                style={{
                  height: 'fit-content',
                  minHeight: 'calc(100vh - 216px)',
                  maxHeight: 'calc(100vh - 216px )',
                }}
              >
                {loader ? (
                  <LoaderCenter />
                ) : jobs.length ? (
                  <>
                    <div className="row row-gap-20">
                      {jobs.map((job, index) => {
                      
                        return (
                          <div
                            className="col-12 col-sm-6 col-xl-4 col-xxl-3"
                            key={index}
                          >
                            <div className="gbl_thumbWrapper">
                              <div
                                className="gbl_thumbContent"
                                style={{
                                  padding: '20px',
                                  position: 'relative',
                                }}
                              >
                                <div className="dropdown dButton">
                                  <button
                                   onClick={() => {
                                    HandalId(job.id)
                                  }}
                                    class="dotsBtn border-0 d-flex justify-content-center align-items-center"
                                    type="button"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i class="fa-solid fa-ellipsis-vertical"></i>
                                  </button>
                                  <ul
                                    class="dropdown-menu career-drop"
                                    aria-labelledby="dropdownMenuButton1"
                                  >
                                    <li>
                                      <a
                                        onClick={handleShow}
                                        class="dropdown-item"
                                        href="#"
                                      >
                                        Share
                                      </a>
                                    </li>
                                  </ul>
                                </div>

                                <div className="jobImgThumbWrapper d-flex justify-content-center justify-content-lg-start">
                                  <div className="jiInnerWrapper">
                                    <div className="jii_wrapper w-100 d-flex flex-column align-items-center flex-lg-row ">
                                      <div className="jii_InnerWrapper">
                                        <img
                                          src={job.image[0]}
                                          alt=""
                                          style={{
                                            height: '70px',
                                            width: '70px',
                                            objectFit: 'cover',
                                            borderRadius: '10px',
                                          }}
                                        />
                                      </div>
                                      <Link to={`/career-details/${job.id}`}>
                                        <div className="jii_TextContent ps-0 ps-lg-4 d-flex flex-column align-items-center align-items-lg-start">
                                          <h1>{job.title}</h1>
                                          <p className="career-desc">
                                            {job.company}
                                          </p>
                                          <p className="career-desc">
                                            {job.job_type}
                                          </p>
                                        </div>
                                      </Link>
                                    </div>
                                  </div>
                                </div>

                                <div className="gbl_thumbControlWrapper d-flex flex-column align-items-center flex-lg-row">
                                  <p className="jobPara d-flex justify-content-center justify-content-lg-start">
                                    {job.salary + ` / Month`}
                                  </p>
                                  <a
                                    style={{
                                      cursor: 'pointer',
                                    }}
                                    className="jobbtn"
                                    // to={`/career-details/${job.id}`}
                                    onClick={(e) => {
                                      if (job.job_applied === 'True') {
                                        swal(
                                          'You have Already applied for this Job',
                                        )
                                      } else {
                                        navigate(`/career-details/${job.id}`)
                                      }
                                    }}
                                  >
                                    {/* Apply Now */}
                                    {job.job_applied === 'True'
                                      ? 'Applied'
                                      : 'Apply Now '}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </>
                ) : (
                  <p
                    style={{
                      fontSize: '20px',
                      marginTop: '200px',
                    }}
                    className=" text-center justify-content-center align-items-center"
                  >
                    Oops! It doesn't look like there are any jobs in your area.
                    <br /> Why don't you try expanding your search criteria?
                  </p>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
      <div
        class="modal fade "
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered "
          style={{ width: 'fit-content', margin: 'auto' }}
        >
          <div
            class="modal-content cmo-content"
            style={{
              borderRadius: '20px',
              padding: '20px',
              width: '378px',
              maxWidth: '378px',
              position: 'relative',
              top: '-12px',
            }}
          >
            <div
              style={{
                position: 'absolute',
                marginTop: '28px',
                right: '370px',
              }}
            >
              <img src={left} alt="" />
            </div>
            <p
              style={{
                color: '#1D1D25',
                fontSize: '20px',
                fontWeight: 'bold',
              }}
            >
              Filter Jobs
            </p>
            <p
              className="job-type"
              style={{
                color: '#707070',
                fontSize: '14px',
                fontWeight: 'normal',
                marginTop: '20px',
                marginBottom: '10px',
              }}
            >
              Choose Job Type
            </p>
            <div className="cr-check-list">
              <div className="ip-label">
                <input
                  type="checkbox"
                  id="check1"
                  checked={filterStatus['Full Time']}
                  onChange={(e) =>
                    setFilterStatus({
                      ...filterStatus,
                      'Full Time': e.target.checked,
                    })
                  }
                />
                <label htmlFor="check1">Full Time</label>
              </div>
              <div className="ip-label">
                <input
                  type="checkbox"
                  id="check2"
                  checked={filterStatus['Part Time']}
                  onChange={(e) =>
                    setFilterStatus({
                      ...filterStatus,
                      'Part Time': e.target.checked,
                    })
                  }
                />
                <label htmlFor="check2">Part Time</label>
              </div>
              <div className="ip-label">
                <input
                  type="checkbox"
                  id="check3"
                  checked={filterStatus['Volunteer']}
                  onChange={(e) =>
                    setFilterStatus({
                      ...filterStatus,
                      Volunteer: e.target.checked,
                    })
                  }
                />
                <label htmlFor="check3">Volunteer</label>
              </div>
              <div className="ip-label">
                <input
                  type="checkbox"
                  id="check4"
                  checked={filterStatus['Remote']}
                  onChange={(e) =>
                    setFilterStatus({
                      ...filterStatus,
                      Remote: e.target.checked,
                    })
                  }
                />
                <label htmlFor="check4">Remote</label>
              </div>
              <div className="ip-label">
                <input
                  type="checkbox"
                  id="check5"
                  checked={filterStatus['Internship']}
                  onChange={(e) =>
                    setFilterStatus({
                      ...filterStatus,
                      Internship: e.target.checked,
                    })
                  }
                />
                <label htmlFor="check5">Internship</label>
              </div>
              <div className="ip-label">
                <input
                  type="checkbox"
                  id="check6"
                  checked={filterStatus['Work-experience']}
                  onChange={(e) =>
                    setFilterStatus({
                      ...filterStatus,
                      'Work-experience': e.target.checked,
                    })
                  }
                />
                <label htmlFor="check6">Work-experience</label>
              </div>
            </div>
            <div
              className="text-center"
              style={{
                marginTop: '30px',
              }}
            >
              <button
                className="ep-action"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{
                  margin: '0px',
                  color: '#FFFFFF',
                  fontSize: '14px',
                  fontWeight: '600',
                  padding: '16px 40px',
                }}
                onClick={(e) => handleFilter(e)}
              >
                Apply Filter
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default JobVolunteer

import React, {
    useContext,
    useState,
    useEffect,
    useRef,
    useCallback,
  } from "react";
  import { Link, useNavigate, useParams } from "react-router-dom";
  import { AppContext } from "../../context/AppContext";
  import Sidebar from "../../components/Sidebar";
  import { Modal } from "react-bootstrap";
  import swal from "sweetalert";
  import Switch from "react-switch";
  import { Icon } from "@iconify/react";
  // import locationIcon from "@iconify/icons-mdi/map-marker";
  import profile_picture from "../../assets/images/profile_picture.jpg";
  import createselectAvatar from "../../assets/images/createselectAvatar.png";
  import createadd_avatar from "../../assets/images/createadd_avatar.png";
  import thumbImg from "../../assets/images/house01.png";
  import upload from "../../assets/images/upload_icon.svg";
  import GoogleMapReact from "google-map-react";
  import ApiConfig from "../../api/ApiConfig";
  import {
    multipartPostCall,
    multipartPostCallWithErrorResponse,
    postMultipartWithAuthCall,
    postMultipartWithAuthCallWithErrorResponse,
    postWithAuthCallWithErrorResponse,
    simpleGetCallWithErrorResponse,
    putMultipartWithAuthCallWithErrorResponse,
  } from "../../api/ApiServices";
  import ImageUplode from "../../sharedComponent/ImageUplode";
  import location from "../../assets/images/location.svg";
  import Barloader from "../../sharedComponent/BarLoader";
  import GoogleMap from "../../components/GoogleMap";
  import GoogleMapNew from "../../components/GoogleMapNew";
  import Payment from "../payment/Payment";
  
  const HousingEdit =()=> {
    const params = useParams();
    let id = params.id;
    const { sidebar, setSidebar, customerData } = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [eventData, setEventData] = useState({});
    const [locationData, setLocationData] = useState({});
    const placeInputRef = useRef(null);
    const [place, setPlace] = useState({
      center: { lat: 19.8762, lng: 75.3433 },
    });
  
    let [marker, setMarker] = useState(null);
    const [circle, setCircle] = useState(null);
    const navigate = useNavigate();
  
    // useEffect(() => {
    //   setPlace(place);
    // }, [place]);
  
    // useEffect(() => {
    //   if (locationData.maps && locationData.map) {
    //     const { map, maps } = locationData;
    //     // or else call that isApiLoaded function and pass-on these arguments
    //     renderMarkers(map, maps);
    //   }
    // }, [locationData, place]);
  
    const getLocation = (lat, lng) => {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBxqcBX2r8cybljkDigXBgyOByjeQOmqr0`,
  
        {
          method: "GET", // or 'PUT'
          headers: {
            // "Content-Type": "application/json",
            // 'Access-Control-Allow-Credentials': "*"
          },
          //body: JSON.stringify(data),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setPlace({
            ...place,
            center: { lat, lng },
            address: data.results[1].formatted_address,
          });
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };
  
    const renderMarkers = (map, maps) => {
      marker && marker.setMap(null);
      circle && circle.setMap(null);
      const cityCircle = new maps.Circle({
        strokeColor: "#00b4bd",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#00b4bd",
        fillOpacity: 0.35,
        map,
        center: { ...place.center },
        // radius: { ...place.center.radius },
        radius: place.radius ? Number(place.radius) : 1000,
      });
      marker = new maps.Marker({
        draggable: true,
        position: { ...place.center },
        map,
      });
      maps.event.addListener(marker, "dragend", async function (event) {
        getLocation(event.latLng.lat(), event.latLng.lng());
      });
      setMarker(marker);
      setCircle(cityCircle);
  
      return { marker, cityCircle };
    };
  
    // useEffect(() => {
    //   initPlaceAPI();
    // }, []);
  
    // initialize the google place autocomplete
    const initPlaceAPI = () => {
      let autocomplete = new window.google.maps.places.Autocomplete(
        placeInputRef.current
      );
      new window.google.maps.event.addListener(
        autocomplete,
        "place_changed",
        function () {
          let place = autocomplete.getPlace();
  
          setEventData({
            ...eventData,
            address: place.name,
            center: {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            },
          });
  
          setPlace({
            address: place.name,
            center: {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            },
          });
        }
      );
    };
  
    const [errorMsg, setErrMsg] = useState({
      title: "",
      description: "",
      contactInfo: "",
      postType: "",
      isFeatured: "",
      postedDate: "",
      keywords: "",
      images: "",
  
      propertyType: "",
      availability: "",
      sharingType: "",
      term: "",
      gender: "",
      rooms: "",
      // bathroom: "",
      billsIncluded: "",
      smokedAllowed: "",
      petsAllowed: "",
      monthlyRent: {
        currency: "",
        value: "",
      },
      deposit: "",
      address: "",
      long: "",
      lat: "",
      address1: "",
      address2: "",
      city: "",
      county: "",
      country: "",
      postCode: "",
    });
  
    const [featureAdd, setFeatureAdd] = useState({
      featured_ads: false,
      featured_ads_duration: "select",
      featured_ads_cost: "£ 1.98",
    });

    const [formdatas, setformdatas] = useState("");
    const [isEnabled, setIsEnabled] = useState(false);
    const [openFeatureAddModel, setOpenFeatureAddModel] = useState(false);
    const [openPaymentAddModel, setOpenPaymentAddModel] = useState(false);
  
    const handleClose = () => {
      setIsEnabled(false);
      setOpenFeatureAddModel(false);
      setOpenPaymentAddModel(false);
      setFeatureAdd((prevState) => ({ ...prevState, featured_ads: false }));
    };
  
    const proceedPayment = () => {
      setOpenPaymentAddModel(true);
    };
  
    const toggleSwitch = () => {
      setOpenFeatureAddModel(true);
      setIsEnabled(!isEnabled);
      setFeatureAdd((prevState) => ({
        ...prevState,
        featured_ads: !prevState.featured_ads,
      }));
    };
  
    const removeImage = (index) => {
      let images = AddProperty.images;
  
      images = images.filter(
        (img) => images.indexOf(img) != index
      );
  
      setAddProperty({ ...AddProperty, images: images});
    };

    const [inputValue, setInputValue] = useState('');

    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && inputValue.trim()) {
        if (!AddProperty.keywords.includes(inputValue.trim())) {
          setAddProperty({
            ...AddProperty,
            keywords: [...AddProperty.keywords, inputValue.trim()]
          });
          setInputValue('');
          // setErrorMsg('');
        } else {
          // setErrorMsg('Keyword already exists');
        }
      }
    };
  
    
    const handleDelete = (keywordToDelete) => {
      setAddProperty({
        ...AddProperty,
        keywords: AddProperty.keywords.filter((keyword) => keyword !== keywordToDelete)
      });
    };
  

    
    // const removeImage = (index) => {
    //   const newImages = AddProperty.images.filter((_, i) => i !== index);
    //   setAddProperty({ ...AddProperty, images: newImages });
    // };
  
    const [showElement, setShowElement] = React.useState(true);
    useEffect(() => {
      const timerId = setTimeout(function () {
        setShowElement(false);
      }, 5000);
  
      return () => clearTimeout(timerId);
    }, []);
  
    // const AddpropertyMange = async (e) => {
    //   setLoading(true);
    //   setOpenPaymentAddModel(true);
  
    //   const validationErrors = {
    //     title: AddProperty.title === "",
    //     property_description: AddProperty.property_description === "",
    //     property_image: AddProperty.property_image.length === 0,
    //     property_type: AddProperty.property_type === "select",
    //     bills_included: AddProperty.bills_included === "select",
    //     gender: AddProperty.gender === "select",
    //     term: AddProperty.term === "select",
    //     smokers_allowed: AddProperty.smokers_allowed === "select",
    //     pets_allowed: AddProperty.pets_allowed === "select",
    //     numbers_of_rooms: AddProperty.numbers_of_rooms === "",
    //     availability: AddProperty.availability === "select",
    //     type: AddProperty.type === "select",
    //     deposit: AddProperty.deposit === "select",
    //     monthly_rent: AddProperty.monthly_rent === "",
    //   };
  
    //   const errorMessages = {
    //     title: "Please enter a title",
    //     property_description: "Please enter a property description",
    //     property_image: "Please upload an image",
    //     property_type: "Please select a property type",
    //     bills_included: "Please select whether bills are included",
    //     gender: "Please select a gender",
    //     term: "Please select the terms",
    //     smokers_allowed: "Please select whether smokers are allowed",
    //     pets_allowed: "Please select whether pets are allowed",
    //     numbers_of_rooms: "Please enter the number of rooms",
    //     availability: "Please select availability",
    //     type: "Please select a type",
    //     deposit: "Please select a deposit",
    //     monthly_rent: "Please enter monthly rent",
    //   };
  
    //   const errorMessagesToShow = {};
  
    //   for (const field in validationErrors) {
    //     if (validationErrors[field]) {
    //       errorMessagesToShow[field] = errorMessages[field];
    //     }
    //   }
  
    //   setErrMsg(errorMessagesToShow);
  
    //   if (Object.values(validationErrors).every((error) => !error)) {
    //     const datails = JSON.stringify({
    //       // ... (rest of your code)
    //     });
  
    //     const formData = new FormData();
    //     formData.append("images", AddProperty.property_image[0]);
    //     formData.append("details", datails);
    //     setformdatas(formData);
  
    //     // ============> api call start <==============
    //     // postMultipartWithAuthCallWithErrorResponse(
    //     //   ApiConfig.UNIVERSR_HOUSING_CREATE_PROPERTY,
    //     //   formData
    //     // )
    //     // .then((res) => {
    //     //   setLoading(false);
    //     //   swal("Property Created Successfully");
    //     //   navigate("/house_listing/all");
    //     // })
    //     // .catch((err) => {
    //     //   setLoading(false);
    //     //   console.log(err);
    //     // });
  
    //     console.log(datails)
    //     await fetch(ApiConfig.UNIVERSR_HOUSING_CREATE_PROPERTY, {
    //       method: "POST",
    //       body: formData,
    //     }).then((res) => {
    //       setLoading(false);
    //       swal("Property Created Successfully");
    //       // navigate("/house_listing/all");
    //     }).catch((err) => {
    //       setLoading(false);
    //       console.log(err);
    //     });
  
    //     // ============> api call ends <================
    //   } else {
    //     setLoading(false);
    //   }
    // };
  
    const handleGenderChange = (e) => {
      const { value, checked } = e.target;
      setAddProperty({
        ...AddProperty,
        housingDetails: {
          ...AddProperty.housingDetails,
          gender: checked
            ? [...AddProperty.housingDetails.gender, value]
            : AddProperty.housingDetails.gender.filter((item) => item !== value),
        },
      });
    };
  
    // const [AddProperty, setAddProperty,setUplodeFile] = useState({
    //   title: "Webapp test",
    //   description: "xxx",
    //   contactInfo: "xxx",
    //   postType: "housing",
    //   isFeatured: false,
    //   keywords: [],
    //   images: [],
  
    //   housingDetails: {
    //     propertyType: "",
    //     availability: "immediately",
    //     sharingType: "",
    //     term: "xxx",
    //     gender: [],
    //     rooms: 1,
    //     bathrooms: 1,
    //     billsIncluded: false,
    //     smokedAllowed: false,
    //     petsAllowed: false,
    //     monthlyRent: {
    //       currency: "USD",
    //       value: 20,
    //     },
    //     deposit: 100,
    //     long: "xx",
    //     lat: "xx",
    //     address1: "no-1, Brohaim",
    //     address2: "xxxxx",
    //     city: "xx",
    //     county: "xx",
    //     country: "xx",
    //     postCode: "xx",
    //   },
  
    // });
  
    // const handleDrop = useCallback(
    //   (acceptedFiles) => {
    //     const files = values.images || [];
  
    //     console.log("files", files);
    //     console.log("acceptedFiles", acceptedFiles);
  
    //     const newFiles = acceptedFiles.map((file) =>
    //       Object.assign(file, {
    //         preview: URL.createObjectURL(file),
    //       })
    //     );
    //   })
    //     setAddProperty('images', [...files, ...newFiles], { shouldValidate: true });
    //     setUplodeFile([...files, ...newFiles]);
  
    //     [setAddProperty,setUplodeFile,values.images]
  
    // const AddpropertyMange = async (e) => {
    //   setLoading(true);
    //   let datails = JSON.stringify({
  
    //     title: AddProperty?.title || "",
    //     description: AddProperty?.description || "",
    //     postType: AddProperty?.postType || "housing",
    //     contactInfo: AddProperty?.contactInfo || "",
    //     isFeatured: AddProperty?.isFeatured || false,
    //     keywords: AddProperty?.keywords || [],
    //     images: AddProperty?.images || [],
    //     housingDetails: {
    //       propertyType: AddProperty?.housingDetails?.propertyType || "",
    //       availability: AddProperty?.housingDetails?.availability || "",
    //       sharingType: AddProperty?.housingDetails?.sharingType || "",
    //       term: AddProperty?.housingDetails?.term || "",
    //       gender: AddProperty?.housingDetails?.gender || [],
    //       rooms: AddProperty?.housingDetails?.rooms || "",
    //       bathrooms: AddProperty?.housingDetails?.bathrooms || 1,
    //       billsIncluded: AddProperty?.housingDetails?.billsIncluded || false,
    //       smokedAllowed: AddProperty?.housingDetails.smokedAllowed || false,
    //       petsAllowed: AddProperty?.housingDetails?.petsAllowed || false,
    //       monthlyRent: {
    //         currency: AddProperty?.housingDetails?.monthlyRent?.currency || "",
    //         value: AddProperty?.housingDetails?.monthlyRent?.value || 1,
    //       },
    //       deposit: AddProperty?.housingDetails?.deposit || 1,
    //       long: AddProperty?.housingDetails?.long || "",
    //       lat: AddProperty?.housingDetails?.lat || "",
    //       address1: AddProperty?.housingDetails?.address1 || "",
    //       address2: AddProperty?.housingDetails?.address2 || "",
    //       city: AddProperty?.housingDetails?.city || "",
    //       county: AddProperty?.housingDetails?.county || "",
    //       country: AddProperty?.housingDetails?.country || "",
    //       postCode: AddProperty?.housingDetails?.postCode || "",
    //     },
    //   });
  
    //   const formData = new FormData();
  
    //   Object.keys(AddProperty).forEach((key) => {
    //     if (Object.prototype.hasOwnProperty.call(AddProperty, key)) {
    //       const value = AddProperty[key];
    //       // Check if value is defined before using it
    //       if (value !== undefined) {
    //         if (key === "housingDetails") {
    //           // Append each property of housingDetails individually
    //           const housingDetails = value;
    //           Object.keys(housingDetails).forEach((detailKey) => {
    //             if (
    //               Object.prototype.hasOwnProperty.call(housingDetails, detailKey)
    //             ) {
    //               const detailValue = housingDetails[detailKey];
    //               // Check if the detailValue is defined before appending
    //               if (detailValue !== undefined) {
    //                 if (
    //                   typeof detailValue === "string" ||
    //                   typeof detailValue === "number" ||
    //                   typeof detailValue === "boolean"
    //                 ) {
    //                   formData.append(
    //                     `housingDetails.${detailKey}`,
    //                     detailValue.toString()
    //                   );
    //                 } else if (
    //                   Array.isArray(detailValue) ||
    //                   typeof detailValue === "object"
    //                 ) {
    //                   formData.append(
    //                     `housingDetails.${detailKey}`,
    //                     JSON.stringify(detailValue)
    //                   );
    //                 }
    //               }
    //             }
    //           });
    //         } else {
    //           formData.append(key, value.toString());
    //         }
    //       }
    //     }
    //   });
  
    //   setformdatas(formData);
    //   console.log("details", datails);
  
    //   postMultipartWithAuthCallWithErrorResponse(
    //     ApiConfig.UNIVERSR_HOUSING_CREATE_PROPERTY,
    //     formData
    //   )
    //     .then((res) => {
    //       setLoading(false);
    //       swal("Property Created Successfully");
    //       navigate("/house_listing/all");
    //     })
    //     .catch((err) => {
    //       setLoading(false);
    //       swal("Property Created Unsuccesfull");
    //       console.log(err);
    //     });
  
    // };
  
    // const onPaymentSuccess = () => {
    //   setAddProperty((prevState) => ({ ...prevState, ...featureAdd }));
    //   setOpenFeatureAddModel(false);
    //   swal("Payment Successfully");
    // };
  
    // console.log("render-create-housing", { featureAdd, AddProperty });
  
    const [AddProperty, setAddProperty] = useState({
      title: "",
      description: "",
      contactInfo: "",
      postType: "housing",
      isFeatured: false,
      keywords: [],
      images: [],
      housingDetails: {
        propertyType: "",
        availability: "",
        sharingType: "",
        term: "",
        gender: [],
        rooms: 1,
        // bathrooms: 1,
        billsIncluded: false,
        smokedAllowed: false,
        petsAllowed: false,
        monthlyRent: {
          currency: "",
          value: 0,
        },
        deposit: 0,
        long: "",
        lat: "",
        address1: "",
        address2: "",
        city: "",
        county: "",
        country: "",
        postCode: "",
      },
    });
  
  
    const handleDrop = useCallback((acceptedFiles) => {
      const files = AddProperty.images || [];
      const newFiles = acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) }));
      setAddProperty(prevState => ({ ...prevState, images: [...files, ...newFiles] }));
  
      
    }, [AddProperty]);
  
    useEffect(() => {
      getHouseDetails();
    }, [id]);

    useEffect(() => {
      console.log('AddProperty',AddProperty);
    }, [AddProperty]);


    // const getHouseDetails = async () => {
    //   setLoading(true);
    
    //   console.log("check id", id);
    
    //   try {
    //     const response = await fetch(`${ApiConfig.BASE_NEW_URL}posts/view/${id}`);
    
    //     if (!response.ok) {
    //       throw new Error('Network response was not ok');
    //     }
    
    //     const data = await response.json();
    //     setLoading(false);
    //     console.log("housing_properties", data);
    
    //     // Destructure the data object
    //     const {
    //       title = "",
    //       description = "",
    //       contactInfo = "",
    //       postType = "housing",
    //       isFeatured = false,
    //       keywords = [],
    //       images = [],
    //       housingDetails = {},
    //     } = data;
    
    //     // Destructure the housingDetails object
    //     const {
    //       propertyType = "",
    //       availability = "",
    //       sharingType = "",
    //       term = "",
    //       gender = [],
    //       rooms = 1,
    //       bathrooms = 1,
    //       billsIncluded = false,
    //       smokedAllowed = false,
    //       petsAllowed = false,
    //       monthlyRent = {},
    //       deposit = 0,
    //       long = "",
    //       lat = "",
    //       address1 = "",
    //       address2 = "",
    //       city = "",
    //       county = "",
    //       country = "",
    //       postCode = "",
    //     } = housingDetails;
    
    //     // Destructure the monthlyRent object
    //     const {
    //       currency = "",
    //       value = 0,
    //     } = monthlyRent;
    
    //     // Update state with destructured values
    //     setAddProperty({
    //       title,
    //       description,
    //       contactInfo,
    //       postType,
    //       isFeatured,
    //       keywords,
    //       images,
    //       housingDetails: {
    //         propertyType,
    //         availability,
    //         sharingType,
    //         term,
    //         gender,
    //         rooms,
    //         bathrooms,
    //         billsIncluded,
    //         smokedAllowed,
    //         petsAllowed,
    //         monthlyRent: {
    //           currency,
    //           value,
    //         },
    //         deposit,
    //         long,
    //         lat,
    //         address1,
    //         address2,
    //         city,
    //         county,
    //         country,
    //         postCode,
    //       },
    //     });
    
    //   } catch (error) {
    //     setLoading(false);
    //     console.error('There was a problem fetching the data:', error);
    //   }
    // };
    
  
    const getHouseDetails = async () => {
      setLoading(true);
  
      console.log("check id", id);
  
      await fetch(`${ApiConfig.BASE_NEW_URL}posts/view/${id}`)
  
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setLoading(false);
          console.log("housing_properties", data);
          
          // setHouseDetails(data);
          setAddProperty({
            title: data?.title || '',
            description: data?.description || '',
            postType: data?.postType || 'housing',
            contactInfo: data?.contactInfo || '',
            isFeatured: data?.isFeatured || false,
            keywords: data?.keywords || [],
            images: data?.images || [],
            housingDetails: {
              propertyType: data?.housingDetails?.propertyType || '',
              availability: data?.housingDetails?.availability || '',
              sharingType: data?.housingDetails?.sharingType || '',
              term: data?.housingDetails?.term || '',
              gender: data?.housingDetails?.gender || [],
              rooms: data?.housingDetails?.rooms || 1,
              // bathrooms: data?.housingDetails?.bathrooms || 1,
              billsIncluded: data?.housingDetails?.billsIncluded || false,
              smokedAllowed: data?.housingDetails?.smokedAllowed || false,
              petsAllowed: data?.housingDetails?.petsAllowed || false,
              monthlyRent: {
                currency: data?.housingDetails?.monthlyRent?.currency || 'USD',
                value: data?.housingDetails?.monthlyRent?.value || 0,
              },
              deposit: data?.housingDetails?.deposit || 0,
              // address: currentPost?.housingDetails?.address || '',
              long: data?.housingDetails?.long || '',
              lat: data?.housingDetails?.lat || '',
              // address
              address1: data?.housingDetails?.address1 || '',
              address2: data?.housingDetails?.address2 || '',
              city: data?.housingDetails?.city || '',
              county: data?.housingDetails?.county || '',
              country: data?.housingDetails?.country || '',
              postCode: data?.housingDetails?.postCode || '',
            },
            // title: data.title,
            // description: data.description,
            // contactInfo: data.contactInfo,
            // postType: data.postType || "housing",
            // isFeatured: data.isFeatured,
            // keywords: data.keywords,
            // images: data.images,
            // housingDetails: {
            //   propertyType: data.housingDetails.propertyType,
            //   availability: data.housingDetails.availability,
            //   sharingType: data.housingDetails.sharingType,
            //   term: data.housingDetails.term,
            //   gender: data.housingDetails.gender,
            //   rooms: data.housingDetails.rooms,
            //   bathrooms: data.housingDetails.bathrooms,
            //   billsIncluded: data.housingDetails.billsIncluded,
            //   smokedAllowed: data.housingDetails.smokedAllowed,
            //   petsAllowed: data.housingDetails.petsAllowed,
            //   monthlyRent: {
            //     currency: data.housingDetails.monthlyRent.currency,
            //     value: data.housingDetails.monthlyRent.value,
            //   },
            //   deposit: data.housingDetails.deposit,
            //   long: data.housingDetails.long,
            //   lat: data.housingDetails.lat,
            //   address1: data.housingDetails.address1,
            //   address2: data.housingDetails.address2,
            //   city: data.housingDetails.city,
            //   county: data.housingDetails.county,
            //   country: data.housingDetails.country,
            //   postCode: data.housingDetails.postCode,
            // },
          });
          
        })
        .catch(error => {
          setLoading(false);
          console.error('There was a problem fetching the data:', error);
        });
  
    };
  
  
    const AddpropertyMange = async (e) => {
      setLoading(true);
      let details = JSON.stringify({
        title: AddProperty.title || "",
        description: AddProperty.description || "",
        postType: AddProperty.postType || "housing",
        contactInfo: AddProperty.contactInfo || "",
        isFeatured: AddProperty.isFeatured || false,
        keywords: AddProperty.keywords || [],
        images: AddProperty.images || [],
        housingDetails: {
          propertyType: AddProperty.housingDetails.propertyType || "",
          availability: AddProperty.housingDetails.availability || "",
          sharingType: AddProperty.housingDetails.sharingType || "",
          term: AddProperty.housingDetails.term || "",
          gender: AddProperty.housingDetails.gender || [],
          rooms: AddProperty.housingDetails.rooms || "",
          // bathrooms: AddProperty.housingDetails.bathrooms || 1,
          billsIncluded: AddProperty.housingDetails.billsIncluded || false,
          smokedAllowed: AddProperty.housingDetails.smokedAllowed || false,
          petsAllowed: AddProperty.housingDetails.petsAllowed || false,
          monthlyRent: {
            currency: AddProperty.housingDetails.monthlyRent.currency || "",
            value: AddProperty.housingDetails.monthlyRent.value || 1,
          },
          deposit: AddProperty.housingDetails.deposit || 1,
          long: AddProperty.housingDetails.long || "",
          lat: AddProperty.housingDetails.lat || "",
          address1: AddProperty.housingDetails.address1 || "",
          address2: AddProperty.housingDetails.address2 || "",
          city: AddProperty.housingDetails.city || "",
          county: AddProperty.housingDetails.county || "",
          country: AddProperty.housingDetails.country || "",
          postCode: AddProperty.housingDetails.postCode || "",
        },
      });
  
      const formData = new FormData();

    //   Object.keys(AddProperty).forEach((key) => {
    //     if (Object.prototype.hasOwnProperty.call(AddProperty, key)) {
    //       const value = AddProperty[key];
    //         // Check if value is defined before using it
    //         if (value !== undefined) {
    //             if (key === 'housingDetails') {
    //                 // Append each property of housingDetails individually
    //                 const housingDetails = value;
    //                 Object.keys(housingDetails).forEach((detailKey) => {
    //                     if (Object.prototype.hasOwnProperty.call(housingDetails, detailKey)) {
    //                         const detailValue = housingDetails[detailKey];
    //                         // Check if the detailValue is defined before appending
    //                         if (detailValue !== undefined) {
    //                             if (
    //                                 typeof detailValue === 'string' ||
    //                                 typeof detailValue === 'number' ||
    //                                 typeof detailValue === 'boolean'
    //                             ) {
    //                                 formData.append(`housingDetails.${detailKey}`, detailValue.toString());
    //                             } else if (Array.isArray(detailValue) || typeof detailValue === 'object') {
    //                                 formData.append(`housingDetails.${detailKey}`, JSON.stringify(detailValue));
    //                             }
    //                         }
    //                     }
    //                 });
    //             } else {
    //                 formData.append(key, value.toString());
    //             }
    //         }
    //     }
    // });
    
    
      
  
    Object.keys(AddProperty).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(AddProperty, key)) {
        const value = AddProperty[key];
        if (value !== undefined) {
          if (key === "housingDetails") {
            const housingDetails = value;
            Object.keys(housingDetails).forEach((detailKey) => {
              if (
                Object.prototype.hasOwnProperty.call(housingDetails, detailKey)
              ) {
                const detailValue = housingDetails[detailKey];
                if (detailValue !== undefined) {
                  if (
                    typeof detailValue === "string" ||
                    typeof detailValue === "number" ||
                    typeof detailValue === "boolean"
                  ) {
                    formData.append(
                      `housingDetails.${detailKey}`,
                      detailValue.toString()
                    );
                  } else if (
                    Array.isArray(detailValue) ||
                    typeof detailValue === "object"
                  ) {
                    formData.append(
                      `housingDetails.${detailKey}`,
                      JSON.stringify(detailValue)
                    );
                  }
                }
              }
            });
          } else if (key === "images") {
            const images = value;
            Array.from(images).forEach((file) => {
              formData.append("files", file);
            });
          } else {
            formData.append(key, value.toString());
          }
        }
      }
    });
    
    
      // if (uploadFile) {
      //   Array.from(uploadFile).forEach((file) => {
      //     formData.append("files", file);
      //   });
      // }
  
      setformdatas(formData);
      console.log("details", details);

    putMultipartWithAuthCallWithErrorResponse(
        ApiConfig.UNIVERSR_HOUSING_MANGE_UPDATE + id,
        formData
      )
        .then((res) => {
          setLoading(false);
          swal(" Updated Property Successfully");
  
          navigate("/house_listing/all");
        })
        .catch((err) => console.log(err));
    };
   

  //   try {
  //     const response = await fetch(`${ApiConfig.UNIVERSR_HOUSING_MANGE_UPDATE}${id}`, {
  //       method: 'PATCH',
  //       body: formData
  //     });
  
  //     if (!response.ok) {
  //       // Handle the error response
  //       const errorData = await response.json();
  //       throw new Error(errorData.message || 'Failed to update property');
  //     }
  
  //     // Assuming the response is in JSON format
  //     const data = await response.json();
      
  //     // After successful update
  //     setLoading(false);
  //     swal("Updated Property Successfully");
  
  //     // Uncomment the next line if you want to navigate after success
  //     navigate("/house_listing/all");
  
  //   } catch (err) {
  //     console.error(err);
  //     swal("Update error");
  //     // Handle errors here, e.g., display an error message to the user
  //   }
  // };

    return (
    //   <div style={{ overflowY: "hidden" }}>
    //     {/* <Sidebar /> */}
  
    //     {/* <Header /> */}
    //     <main id="cxMain" className="cx-main cx-active">
    //       <section
    //         className="content-main"
    //         style={{
    //           backgroundColor: "white",
    //           borderRadius: "20px",
    //           height: "calc(100vh - 156px)",
    //         }}
    //       >
    <div style={{ display: 'flex', justifyContent: 'center' }}>
    <div style={{ maxWidth: '1300px', display: 'flex', flexDirection: 'row', margin: '10px', borderRadius: '15px', overflow: 'hidden' }}>
            <div className="row">
              <div className="col-md-12 scroll-hidden">
                <div
                  className="create_screenWrapper"
                  style={{
                    overflow: "auto",
                    height: "calc(100vh - 156px)",
                  }}
                >
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Edit your Property
                    </h5>
                  </div>
  
                  <div className="profile_modal_contentWrapper">
                    {loading && <Barloader />}
                    <div className="row">
                      {/* left side */}
                      <div
                        className="col-md-6 profile_form"
                        style={{ marginTop: "0px" }}
                      >
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className="uploadPP_inputTextRow"
                              // style={{ width: "100%" }}type
                            >
                              <label class="form-label">Title</label>
                              <input
                                type="text"
                                class="form-control"
                                style={{ height: "40px" }}
                                value={AddProperty.title}
                                placeholder="Property Title "
                                onChange={(e) => {
                                  // setErrMsg({ ...errMsg, title: "" });
  
                                  setAddProperty({
                                    ...AddProperty,
                                    title: e.target.value,
                                  });
                                }}
                              />
                              <small
                                style={{
                                  color: "red",
                                }}
                              >
                                {errorMsg.title}
                              </small>
                              {/* {errMsg.title.length > 0 && (
                                <div className="text-center text-danger">
                                  <span> {errMsg.title}</span>
                                </div>
                              )} */}
                            </div>
                          </div>
  
                          <div
                            style={{
                              margin: "-21px -5px -4px 7px",
                              paddingLeft: "0px",
                            }}
                            className="row"
                          >
                            <div
                              style={{
                                paddingLeft: "6px",
                              }}
                              className="col-md-12"
                            >
                              <div class="form-group ">
                                <label
                                  class="form-label form-label-box-1"
                                  style={{ marginTop: "20px" }}
                                >
                                  Description
                                </label>
                                <textarea
                                  value={AddProperty.description}
                                  onChange={(e) => {
                                    // setErrMsg({ ...errMsg, title: "" })
                                    setAddProperty({
                                      ...AddProperty,
                                      description: e.target.value,
                                    });
                                  }}
                                  style={{
                                    border: "1px solid #E1E1E1",
                                    borderRadius: "10px",
                                    backgroundColor: "#FBF8F8 ",
                                  }}
                                  class="form-control "
                                  placeholder="Describe your property"
                                  id="exampleFormControlTextarea1"
                                  rows="3"
                                ></textarea>
                                <small
                                  style={{
                                    color: "red",
                                  }}
                                >
                                  {errorMsg.description}
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
  
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className="uploadPP_inputTextRow"
                              // style={{ width: "100%" }}type
                            >
                              <label
                                class="form-label"
                                style={{ marginTop: "20px" }}
                              >
                                Contact Info
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                style={{ height: "40px" }}
                                value={AddProperty.contactInfo}
                                placeholder="Contact info"
                                onChange={(e) => {
                                  // setErrMsg({ ...errMsg, title: "" });
  
                                  setAddProperty({
                                    ...AddProperty,
                                    contactInfo: e.target.value,
                                  });
                                }}
                              />
                              <small
                                style={{
                                  color: "red",
                                }}
                              >
                                {errorMsg.contactInfo}
                              </small>
                              {/* {errMsg.title.length > 0 && (
                                <div className="text-center text-danger">
                                  <span> {errMsg.title}</span>
                                </div>
                              )} */}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className="uploadPP_inputTextRow"
                              // style={{ width: "100%" }}type
                            >
                              <label
                                class="form-label"
                                style={{ marginTop: "20px" }}
                              >
                                Post Type
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                style={{ height: "40px" }}
                                value={AddProperty.postType}
                                placeholder="Post Type"
                                onChange={(e) => {
                                  // setErrMsg({ ...errMsg, title: "" });
  
                                  setAddProperty({
                                    ...AddProperty,
                                    postType: e.target.value,
                                  });
                                }}
                              />
                              <small
                                style={{
                                  color: "red",
                                }}
                              >
                                {errorMsg.postType}
                              </small>
                              {/* {errMsg.title.length > 0 && (
                                <div className="text-center text-danger">
                                  <span> {errMsg.title}</span>
                                </div>
                              )} */}
                            </div>
                          </div>
                        </div>
  
                        <div className="col-lg-12">
                          <div className="uploadPP_inputTextRow usalabel">
                            <div className="upp_selectWrapper">
                              <div className="">
                                <div
                                  className="uploadPP_inputTextRow"
                                  style={{ width: "100%" }}
                                >
                                  <div
                                    className="ip"
                                    style={{ position: "relative" }}
                                  >
                                    <label
                                      className="form-label"
                                      style={{ marginTop: "20px" }}
                                    >
                                      <Switch
                                        onChange={(checked) =>
                                          setAddProperty({
                                            ...AddProperty,
                                            isFeatured: checked,
                                          })
                                        }
                                        checked={AddProperty.isFeatured}
                                        offColor="#888"
                                        onColor="#0b9"
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                      />{" "}
                                      is Featured
                                    </label>
                                    <span style={{ marginLeft: "10px" }}>
                                      {AddProperty.isFeatured ? "Yes" : "No"}
                                    </span>
                                  </div>
                                  <small style={{ color: "red" }}>
                                    {errorMsg.isFeatured}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
  
                        <div className="row">
                          {/* <div className="col-md-12">
                            <div
                              className="uploadPP_inputTextRow"
                              // style={{ width: "100%" }}type
                            >
                              <label class="form-label">Keywords</label>
                              <input
                                type="text"
                                class="form-control"
                                style={{ height: "40px" }}
                                value={AddProperty.keywords}
                                placeholder="Keywords"
                                onChange={(e) => {
                                  // setErrMsg({ ...errMsg, title: "" });
  
                                  setAddProperty({
                                    ...AddProperty,
                                    keywords: e.target.value,
                                  });
                                }}
                              />
                              <small
                                style={{
                                  color: "red",
                                }}
                              >
                                {errorMsg.keywords}
                              </small>
                             
                            </div>
                          </div> */}

<div className="col-md-12">
      <div className="uploadPP_inputTextRow">
        <label className="form-label">Keywords</label>
        <input
          type="text"
          className="form-control"
          style={{ height: '40px' }}
          value={inputValue}
          placeholder="Enter keywords"
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={handleKeyPress}
        />

        <div className="chips-container">
          {AddProperty.keywords.map((keyword, index) => (
            <div key={index} className="chip">
              {keyword}
              <span className="closebtn" onClick={() => handleDelete(keyword)}>
                &times;
              </span>
            </div>
          ))}
        </div>
      </div>
    </div> 
                        </div>
  
                        {/* <div className="row">
                          <div className="col-md-12">
                             <div
                              className="uploadPP_inputTextRow"
                              // style={{ width: "100%" }}type
                            >
                              <label class="form-label">Keywords</label>
                              <input
                                type="text"
                                class="form-control"
                                style={{ height: "40px" }}
                                value={AddProperty.keywords}
                                placeholder="Keywords"
                                onChange={(e) => {
                                  // setErrMsg({ ...errMsg, title: "" });
  
                                  setAddProperty({
                                    ...AddProperty,
                                    keywords: e.target.value,
                                  });
                                }}
                              />
                              <small
                                style={{
                                  color: "red",
                                }}
                              >
                                {errorMsg.keywords}
                              </small>
                              {/* {errMsg.title.length > 0 && (
                                <div className="text-center text-danger">
                                  <span> {errMsg.title}</span>
                                </div>
                              )}
                            </div> 
                          </div>
                        </div>  */}
  
                        
  <div className="row mb-4 align-items-center gy-3">
  <div className="col-12 col-sm-6 col-xl-4 custom-resp-1">
    <div style={{ height: "148px", width: "148px", margin: "0", padding: "0" }}>
      {/* Your image upload component */}
      <ImageUplode

        setFile={(files) => {
          setAddProperty((prevState) => ({
            ...prevState,
            images: [...prevState.images, ...files],
          }));
        }}
      />
      <small style={{ color: "red" }}>{errorMsg.images}</small>
    </div>
  </div>

  {AddProperty.images.map((img, index) => (
    <div className="col-12 col-sm-6 col-xl-4" key={"img" + index}>
      <div style={{ width: "148px" }} className="uploaded-image-1 uploaded-reps-1">
        <div className="item_list">
          <img width={72} height="72" className="img" 
          src={img} alt={`Image ${index}`} />
          <a
            style={{ position: "absolute", top: "10px", right: "0px" }}
            href="#"
            className=""
          >
            <i onClick={() => removeImage(index)} className="fa-solid fa-xmark"></i>
          </a>
        </div>
      </div>
    </div>
  ))}
</div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="uploadPP_inputTextRow">
                              <label
                                className="form-label"
                                style={{ marginTop: "20px" }}
                              >
                                First Address
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                style={{ height: "40px" }}
                                value={AddProperty.housingDetails.address1}
                                placeholder=""
                                onChange={(e) => {
                                  setAddProperty({
                                    ...AddProperty,
                                    housingDetails: {
                                      ...AddProperty.housingDetails,
                                      address1: e.target.value,
                                    },
                                  });
                                }}
                              />
                              <small style={{ color: "red" }}>
                                {errorMsg.address1}
                              </small>
                            </div>
                          </div>
                        </div>
  
                        <div className="row">
                          <div className="col-md-12">
                            <div className="uploadPP_inputTextRow">
                              <label
                                className="form-label"
                                style={{ marginTop: "20px" }}
                              >
                                Second Address
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                style={{ height: "40px" }}
                                value={AddProperty.housingDetails.address2}
                                placeholder=""
                                onChange={(e) => {
                                  setAddProperty({
                                    ...AddProperty,
                                    housingDetails: {
                                      ...AddProperty.housingDetails,
                                      address2: e.target.value,
                                    },
                                  });
                                }}
                              />
                              <small style={{ color: "red" }}>
                                {errorMsg.address2}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 main-property-type">
                        <div className="row">
                          {/* PropertyType */}
                          <div className="col-lg-12">
                            <div className="uploadPP_inputTextRow usalabel">
                              <label class="form-label">Property Type</label>
                              <div className="upp_selectWrapper ">
                                <select
                                value={AddProperty.housingDetails.propertyType}
                                  onChange={(e) => {
                                    
                                    // setErrMsg({ ...errMsg, title: "" });
  
                                    setAddProperty({
                                      ...AddProperty,
                                      housingDetails: {
                                        ...AddProperty.housingDetails,
                                        propertyType: e.target.value,
                                      },
                                    });
                                  }}
                                  className="Property-Type-midal form-select  ce-input"
                                  aria-label="Default select example"
                                  style={{
                                    height: "40px",
                                    backgroundColor: "#FBF8F8",
                                    margin: "0",
                                  }}
                                >
                                  <option value="select">
                                    Select Property Type
                                  </option>
                                  <option value="house">House</option>
                                  <option value="studio">Studio</option>
                                  <option value="flat">Flat</option>
                                </select>
                                <small
                                  style={{
                                    color: "red",
                                  }}
                                >
                                  {errorMsg.propertyType}
                                </small>
                              </div>
                            </div>
                          </div>
  
                          {/* <div className="col-lg-6">
                            <div className="uploadPP_inputTextRow usalabel upp_selectWrapper-type-1">
                              <label class="form-label">Bill Included</label>
                              <div className="upp_selectWrapper">
                                <select
                                  onChange={(e) => {
                                    setAddProperty({
                                      ...AddProperty,
                                      billsIncluded: e.target.value,
                                    });
                                  }}
                                  style={{
                                    height: "40px",
                                    margin: "0",
                                    backgroundColor: "#FBF8F8",
                                  }}
                                  class=" Property-Type-midal  form-select ce-input "
                                  aria-label="Default select example"
                                >
                                  <option value="select">
                                    Select Bill Included
                                  </option>
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </select>
                                <small
                                  style={{
                                    color: "red",
                                  }}
                                >
                                  {errorMsg.billsIncluded}
                                </small>
                              </div>
                            </div>
                          </div> */}
  
                          <div className="row">
                            {/* availability */}
                            <div className="col-12 col-md-6">
                              <div className="uploadPP_inputTextRow usalabel">
                                <div className="upp_selectWrapper">
                                  <div className="">
                                    <div
                                      className="uploadPP_inputTextRow"
                                      style={{ width: "100%" }}
                                    >
                                      <label
                                        class="form-label"
                                        style={{ marginTop: "20px" }}
                                      >
                                        Availability
                                      </label>
                                      <div
                                        className="ip"
                                        style={{
                                          position: "relative",
                                        }}
                                      >
                                        <input
                                          value={
                                            AddProperty.housingDetails
                                              .availability
                                          }
                                          onChange={(e) => {
                                            // setErrMsg({ ...errMsg, title: "" });
  
                                            setAddProperty({
                                              ...AddProperty,
                                              housingDetails: {
                                                ...AddProperty.housingDetails,
                                                availability: e.target.value,
                                              },
                                            });
                                          }}
                                          style={{
                                            height: "40px",
                                            backgroundColor: "black",
                                            backround: "black !important",
                                            paddingLeft: "20px",
                                          }}
                                          type="text"
                                          class="form-control text-start"
                                          placeholder="Availability"
                                        />
                                      </div>
                                      <small
                                        style={{
                                          color: "red",
                                        }}
                                      >
                                        {errorMsg.availability}
                                      </small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
  
                            {/* sharingType */}
                            <div className="col-12 col-md-6">
                              <div className="uploadPP_inputTextRow usalabel">
                                <div className="upp_selectWrapper">
                                  <div className=" ">
                                    <div
                                      className="uploadPP_inputTextRow"
                                      style={{ width: "100%" }}
                                    >
                                      <label
                                        class="form-label"
                                        style={{ marginTop: "20px" }}
                                      >
                                        Sharing Type
                                      </label>
  
                                      <select
                                      value={AddProperty.housingDetails.sharingType}
                                        onChange={(e) => {
                                          // setErrMsg({ ...errMsg, title: "" });
  
                                          setAddProperty({
                                            ...AddProperty,
                                            housingDetails: {
                                              ...AddProperty.housingDetails,
                                              sharingType: e.target.value,
                                            },
                                          });
                                        }}
                                        className="Property-Type-midal form-select  ce-input"
                                        aria-label="Default select example"
                                        style={{
                                          height: "40px",
                                          backgroundColor: "#FBF8F8",
                                          margin: "0",
                                        }}
                                      >
                                        <option value="select">
                                          Select Sharing Type
                                        </option>
                                        <option value="house">House</option>
                                        <option value="studio">Studio</option>
                                        <option value="flat">Flat</option>
                                      </select>
                                      <small
                                        style={{
                                          color: "red",
                                        }}
                                      >
                                        {errorMsg.sharingType}
                                      </small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
  
                          <div className="row">
                            {/* country */}
                            <div className="col-12 col-md-6">
                              <div className="uploadPP_inputTextRow usalabel">
                                <div className="upp_selectWrapper">
                                  <div className="">
                                    <div
                                      className="uploadPP_inputTextRow"
                                      style={{ width: "100%" }}
                                    >
                                      <label
                                        class="form-label"
                                        style={{ marginTop: "20px" }}
                                      >
                                        Country
                                      </label>
                                      <div
                                        className="ip"
                                        style={{
                                          position: "relative",
                                        }}
                                      >
                                        <input
                                          value={
                                            AddProperty.housingDetails.country
                                          }
                                          onChange={(e) => {
                                            // setErrMsg({ ...errMsg, title: "" });
  
                                            setAddProperty({
                                              ...AddProperty,
                                              housingDetails: {
                                                ...AddProperty.housingDetails,
                                                country: e.target.value,
                                              },
                                            });
                                          }}
                                          style={{
                                            height: "40px",
                                            backgroundColor: "black",
                                            backround: "black !important",
                                            paddingLeft: "20px",
                                          }}
                                          type="text"
                                          class="form-control text-start"
                                          placeholder=" "
                                        />
                                      </div>
                                      <small
                                        style={{
                                          color: "red",
                                        }}
                                      >
                                        {errorMsg.country}
                                      </small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
  
                            {/* city */}
                            <div className="col-12 col-md-6">
                              <div className="uploadPP_inputTextRow usalabel">
                                <div className="upp_selectWrapper">
                                  <div className="">
                                    <div
                                      className="uploadPP_inputTextRow"
                                      style={{ width: "100%" }}
                                    >
                                      <label
                                        class="form-label"
                                        style={{ marginTop: "20px" }}
                                      >
                                        City
                                      </label>
                                      <div
                                        className="ip"
                                        style={{
                                          position: "relative",
                                        }}
                                      >
                                        <input
                                          value={AddProperty.housingDetails.city}
                                          onChange={(e) => {
                                            // setErrMsg({ ...errMsg, title: "" });
  
                                            setAddProperty({
                                              ...AddProperty,
                                              housingDetails: {
                                                ...AddProperty.housingDetails,
                                                city: e.target.value,
                                              },
                                            });
                                          }}
                                          style={{
                                            height: "40px",
                                            backgroundColor: "black",
                                            backround: "black !important",
                                            paddingLeft: "20px",
                                          }}
                                          type="text"
                                          class="form-control text-start"
                                          placeholder=" "
                                        />
                                      </div>
                                      <small
                                        style={{
                                          color: "red",
                                        }}
                                      >
                                        {errorMsg.city}
                                      </small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
  
                          <div className="row">
                            {/* postCode */}
                            <div className="col-12 col-md-6">
                              <div className="uploadPP_inputTextRow usalabel">
                                <div className="upp_selectWrapper">
                                  <div className="">
                                    <div
                                      className="uploadPP_inputTextRow"
                                      style={{ width: "100%" }}
                                    >
                                      <label
                                        class="form-label"
                                        style={{ marginTop: "20px" }}
                                      >
                                        Post Code
                                      </label>
                                      <div
                                        className="ip"
                                        style={{
                                          position: "relative",
                                        }}
                                      >
                                        <input
                                          value={
                                            AddProperty.housingDetails.postCode
                                          }
                                          onChange={(e) => {
                                            // setErrMsg({ ...errMsg, title: "" });
  
                                            setAddProperty({
                                              ...AddProperty,
                                              housingDetails: {
                                                ...AddProperty.housingDetails,
                                                postCode: e.target.value,
                                              },
                                            });
                                          }}
                                          style={{
                                            height: "40px",
                                            backgroundColor: "black",
                                            backround: "black !important",
                                            paddingLeft: "20px",
                                          }}
                                          type="text"
                                          class="form-control text-start"
                                          placeholder=" "
                                        />
                                      </div>
                                      <small
                                        style={{
                                          color: "red",
                                        }}
                                      >
                                        {errorMsg.postCode}
                                      </small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
  
                            {/* county */}
                            <div className="col-12 col-md-6">
                              <div className="uploadPP_inputTextRow usalabel">
                                <div className="upp_selectWrapper">
                                  <div className="">
                                    <div
                                      className="uploadPP_inputTextRow"
                                      style={{ width: "100%" }}
                                    >
                                      <label
                                        class="form-label"
                                        style={{ marginTop: "20px" }}
                                      >
                                        County
                                      </label>
                                      <div
                                        className="ip"
                                        style={{
                                          position: "relative",
                                        }}
                                      >
                                        <input
                                          value={
                                            AddProperty.housingDetails.county
                                          }
                                          onChange={(e) => {
                                            // setErrMsg({ ...errMsg, title: "" });
  
                                            setAddProperty({
                                              ...AddProperty,
                                              housingDetails: {
                                                ...AddProperty.housingDetails,
                                                county: e.target.value,
                                              },
                                            });
                                          }}
                                          style={{
                                            height: "40px",
                                            backgroundColor: "black",
                                            backround: "black !important",
                                            paddingLeft: "20px",
                                          }}
                                          type="text"
                                          class="form-control text-start"
                                          placeholder=" "
                                        />
                                      </div>
                                      <small
                                        style={{
                                          color: "red",
                                        }}
                                      >
                                        {errorMsg.county}
                                      </small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
  
                          <div className="row">
                            {/* rooms */}
                            <div className="col-12 col-md-6">
                              <div className="uploadPP_inputTextRow usalabel">
                                <div className="upp_selectWrapper">
                                  <div className="">
                                    <div
                                      className="uploadPP_inputTextRow"
                                      style={{ width: "100%" }}
                                    >
                                      <label
                                        class="form-label"
                                        style={{ marginTop: "20px" }}
                                      >
                                        Rooms
                                      </label>
                                      <div
                                        className="ip"
                                        style={{
                                          position: "relative",
                                        }}
                                      >
                                        <input
                                          value={AddProperty.housingDetails.rooms}
                                          onChange={(e) => {
                                            // setErrMsg({ ...errMsg, title: "" });
  
                                            setAddProperty({
                                              ...AddProperty,
                                              housingDetails: {
                                                ...AddProperty.housingDetails,
                                                rooms: e.target.value,
                                              },
                                            });
                                          }}
                                          style={{
                                            height: "40px",
                                            backgroundColor: "black",
                                            backround: "black !important",
                                            paddingLeft: "20px",
                                          }}
                                          type="text"
                                          class="form-control text-start"
                                          placeholder="rooms"
                                        />
                                      </div>
                                      <small
                                        style={{
                                          color: "red",
                                        }}
                                      >
                                        {errorMsg.rooms}
                                      </small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
  
                            {/* bathrooms */}
                            {/* <div className="col-12 col-md-6">
                              <div className="uploadPP_inputTextRow usalabel">
                                <div className="upp_selectWrapper">
                                  <div className="">
                                    <div
                                      className="uploadPP_inputTextRow"
                                      style={{ width: "100%" }}
                                    >
                                      <label
                                        class="form-label"
                                        style={{ marginTop: "20px" }}
                                      >
                                        Bathrooms
                                      </label>
                                      <div
                                        className="ip"
                                        style={{
                                          position: "relative",
                                        }}
                                      >
                                        <input
                                          value={
                                            AddProperty.housingDetails.bathrooms
                                          }
                                          onChange={(e) => {
                                            // setErrMsg({ ...errMsg, title: "" });
  
                                            setAddProperty({
                                              ...AddProperty,
                                              housingDetails: {
                                                ...AddProperty.housingDetails,
                                                bathrooms: e.target.value,
                                              },
                                            });
                                          }}
                                          style={{
                                            height: "40px",
                                            backgroundColor: "black",
                                            backround: "black !important",
                                            paddingLeft: "20px",
                                          }}
                                          type="text"
                                          class="form-control text-start"
                                          placeholder="bathrooms"
                                        />
                                      </div>
                                      <small
                                        style={{
                                          color: "red",
                                        }}
                                      >
                                        {errorMsg.bathrooms}
                                      </small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                            
                          </div>
  
                          {/* gender */}
                          <div className="col-lg-12">
                            <div className="uploadPP_inputTextRow usalabel">
                              <div className="upp_selectWrapper">
                                <div className="">
                                  <div
                                    className="uploadPP_inputTextRow"
                                    style={{ width: "100%" }}
                                  >
                                    <label
                                      className="form-label"
                                      style={{ marginTop: "20px" }}
                                    >
                                      Gender
                                    </label>
                                    <div
                                      className="ip"
                                      style={{ position: "relative" }}
                                    >
                                      <label>
                                        Men
                                        <input
                                          style={{ marginLeft: "10px" }}
                                          type="checkbox"
                                          value="men"
                                          checked={AddProperty.housingDetails.gender.includes(
                                            "men"
                                          )}
                                          onChange={handleGenderChange}
                                        />
                                      </label>
                                      <label style={{ marginLeft: "20px" }}>
                                        Women
                                        <input
                                          style={{ marginLeft: "10px" }}
                                          type="checkbox"
                                          value="women"
                                          checked={AddProperty.housingDetails.gender.includes(
                                            "women"
                                          )}
                                          onChange={handleGenderChange}
                                        />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
  
                          <div className="col-lg-12">
                            <div className="uploadPP_inputTextRow usalabel">
                              <div className="upp_selectWrapper">
                                <div className="">
                                  <div
                                    className="uploadPP_inputTextRow"
                                    style={{ width: "100%" }}
                                  >
                                    <div
                                      className="ip"
                                      style={{ position: "relative" }}
                                    >
                                      <label
                                        className="form-label"
                                        style={{ marginTop: "20px" }}
                                      >
                                        <Switch
                                          onChange={(checked) =>
                                            setAddProperty({
                                              ...AddProperty,
                                              housingDetails: {
                                                ...AddProperty.housingDetails,
                                                billsIncluded: checked,
                                              },
                                            })
                                          }
                                          checked={
                                            AddProperty.housingDetails
                                              .billsIncluded
                                          }
                                          offColor="#888"
                                          onColor="#0b9"
                                          uncheckedIcon={false}
                                          checkedIcon={false}
                                        />{" "}
                                        Bills Included
                                      </label>
                                      <span style={{ marginLeft: "10px" }}>
                                        {AddProperty.housingDetails.billsIncluded
                                          ? "Allowed"
                                          : "Not Allowed"}
                                      </span>
                                    </div>
                                    <small style={{ color: "red" }}>
                                      {errorMsg.billsIncluded}
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
  
                          {/* Smoked Allowed */}
                          <div className="col-lg-12">
                            <div className="uploadPP_inputTextRow usalabel">
                              <div className="upp_selectWrapper">
                                <div className="">
                                  <div
                                    className="uploadPP_inputTextRow"
                                    style={{ width: "100%" }}
                                  >
                                    <div
                                      className="ip"
                                      style={{ position: "relative" }}
                                    >
                                      <label
                                        className="form-label"
                                        style={{ marginTop: "20px" }}
                                      >
                                        <Switch
                                          onChange={(checked) =>
                                            setAddProperty({
                                              ...AddProperty,
                                              housingDetails: {
                                                ...AddProperty.housingDetails,
                                                smokedAllowed: checked,
                                              },
                                            })
                                          }
                                          checked={
                                            AddProperty.housingDetails
                                              .smokedAllowed
                                          }
                                          offColor="#888"
                                          onColor="#0b9"
                                          uncheckedIcon={false}
                                          checkedIcon={false}
                                        />{" "}
                                        Smoked Allowed
                                      </label>
                                      <span style={{ marginLeft: "10px" }}>
                                        {AddProperty.housingDetails.smokedAllowed
                                          ? "Allowed"
                                          : "Not Allowed"}
                                      </span>
                                    </div>
                                    <small style={{ color: "red" }}>
                                      {errorMsg.smokedAllowed}
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
  
                          {/* Pets Allowed */}
                          <div className="col-lg-12">
                            <div className="uploadPP_inputTextRow usalabel">
                              <div className="upp_selectWrapper">
                                <div className="">
                                  <div
                                    className="uploadPP_inputTextRow"
                                    style={{ width: "100%" }}
                                  >
                                    <div
                                      className="ip"
                                      style={{ position: "relative" }}
                                    >
                                      <label
                                        className="form-label"
                                        style={{ marginTop: "20px" }}
                                      >
                                        <Switch
                                          onChange={(checked) =>
                                            setAddProperty({
                                              ...AddProperty,
                                              housingDetails: {
                                                ...AddProperty.housingDetails,
                                                petsAllowed: checked,
                                              },
                                            })
                                          }
                                          checked={
                                            AddProperty.housingDetails.petsAllowed
                                          }
                                          offColor="#888"
                                          onColor="#0b9"
                                          uncheckedIcon={false}
                                          checkedIcon={false}
                                        />{" "}
                                        Pets Allowed
                                      </label>
                                      <span style={{ marginLeft: "10px" }}>
                                        {AddProperty.housingDetails.petsAllowed
                                          ? "Allowed"
                                          : "Not Allowed"}
                                      </span>
                                    </div>
                                    <small style={{ color: "red" }}>
                                      {errorMsg.petsAllowed}
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
  
                          {/* deposit */}
                          <div className="col-lg-12">
                            <div className="uploadPP_inputTextRow usalabel">
                              <div className="upp_selectWrapper">
                                <div className="">
                                  <div
                                    className="uploadPP_inputTextRow"
                                    style={{ width: "100%" }}
                                  >
                                    <label
                                      class="form-label"
                                      style={{ marginTop: "20px" }}
                                    >
                                      Deposit
                                    </label>
                                    <div
                                      className="ip"
                                      style={{
                                        position: "relative",
                                      }}
                                    >
                                      <input
                                        value={AddProperty.housingDetails.deposit}
                                        onChange={(e) => {
                                          // setErrMsg({ ...errMsg, title: "" });
  
                                          setAddProperty({
                                            ...AddProperty,
                                            housingDetails: {
                                              ...AddProperty.housingDetails,
                                              deposit: e.target.value,
                                            },
                                          });
                                        }}
                                        style={{
                                          height: "40px",
                                          backgroundColor: "black",
                                          backround: "black !important",
                                          paddingLeft: "20px",
                                        }}
                                        type="text"
                                        class="form-control text-start"
                                        placeholder=" Price"
                                      />
                                      <span
                                        style={{
                                          position: "absolute",
                                          top: "50%",
                                          transform: "translateY(-50%)",
                                          left: "10px",
                                        }}
                                      >
                                        £{""}
                                      </span>
                                    </div>
                                    <small
                                      style={{
                                        color: "red",
                                      }}
                                    >
                                      {errorMsg.deposit}
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* monthlyRent currency */}
                          <div className="col-lg-6">
                            <div className="uploadPP_inputTextRow usalabel">
                              <div className="upp_selectWrapper">
                                <div className="">
                                  <div
                                    className="uploadPP_inputTextRow"
                                    style={{ width: "100%" }}
                                  >
                                    <label
                                      class="form-label"
                                      style={{ marginTop: "20px" }}
                                    >
                                      Monthly Rent
                                    </label>
                                    <div
                                      className="ips"
                                      style={{
                                        position: "relative",
                                      }}
                                    >
                                      {/* <input
                                        className="text-start"
                                        onChange={(e) => {
                                          // setErrMsg({ ...errMsg, title: "" });
  
                                          setAddProperty({
                                            ...AddProperty,
                                            monthlyRent: e.target.value,
                                          });
                                        }}
                                        style={{
                                          height: "40px",
                                          paddingLeft: "20px",
                                        }}
                                        type="text"
                                        class="form-control text-start"
                                        placeholder=" Price"
                                      /> */}
                                      <label class="form-label">Currency</label>
                                      <input
                                        className="text-start"
                                        value={
                                          AddProperty.housingDetails.monthlyRent
                                            .currency
                                        }
                                        onChange={(e) => {
                                          setAddProperty({
                                            ...AddProperty,
                                            housingDetails: {
                                              ...AddProperty.housingDetails,
                                              monthlyRent: {
                                                ...AddProperty.housingDetails
                                                  .monthlyRent,
                                                currency: e.target.value,
                                              },
                                            },
                                          });
                                        }}
                                        style={{
                                          height: "40px",
                                          paddingLeft: "20px",
                                        }}
                                        type="text"
                                        class="form-control text-start"
                                        placeholder="Currency"
                                      />
                                      {/* <span
                                        style={{
                                          position: "absolute",
                                          top: "50%",
                                          transform: "translateY(-50%)",
                                          left: "10px",
                                        }}
                                      >
                                        £
                                      </span> */}
                                    </div>
                                    <small
                                      style={{
                                        color: "red",
                                      }}
                                    >
                                      {errorMsg.monthlyRent.currency}
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
  
                          {/* monthlyRent value */}
  
                          <div className="col-lg-6">
                            <div className="uploadPP_inputTextRow usalabel">
                              <div className="upp_selectWrapper">
                                <div className="">
                                  <div
                                    className="uploadPP_inputTextRow"
                                    style={{ width: "100%" }}
                                  >
                                    <label
                                      class="form-label"
                                      style={{ marginTop: "37px" }}
                                    >
                                      {" "}
                                    </label>
                                    <div
                                      className="ips"
                                      style={{
                                        position: "relative",
                                      }}
                                    >
                                      <label class="form-label">Value</label>
                                      <input
                                        className="text-start"
                                        value={
                                          AddProperty.housingDetails.monthlyRent
                                            .value
                                        }
                                        onChange={(e) => {
                                          setAddProperty({
                                            ...AddProperty,
                                            housingDetails: {
                                              ...AddProperty.housingDetails,
                                              monthlyRent: {
                                                ...AddProperty.housingDetails
                                                  .monthlyRent,
                                                value: e.target.value,
                                              },
                                            },
                                          });
                                        }}
                                        style={{
                                          height: "40px",
                                          paddingLeft: "20px",
                                        }}
                                        type="text"
                                        class="form-control text-start"
                                        placeholder="Value"
                                      />
  
                                      <span
                                        style={{
                                          position: "absolute",
                                          top: "72%",
                                          transform: "translateY(-50%)",
                                          left: "10px",
                                        }}
                                      >
                                        £
                                      </span>
                                    </div>
                                    <small
                                      style={{
                                        color: "red",
                                      }}
                                    >
                                      {errorMsg.monthlyRent.value}
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            marginTop: "10px",
                          }}
                        >
                          <h5 class="modal-title">Feature-Add</h5>
  
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              gap: "5px",
                              paddingLeft: "2px",
                              paddingRight: "2px",
                            }}
                          >
                            <div
                              style={{
                                border: "1px solid #ccc",
                                borderRadius: "8px",
                                marginTop: "5px",
                                width: "100%",
                                // marginLeft: '4px',
                                backgroundColor: "black",
                                padding: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  paddingLeft: "4px",
                                  marginTop: "4px",
                                  marginBottom: "3px",
                                  alignItems: "center",
                                }}
                              >
                                <p
                                  className="reporttext"
                                  style={{ color: "white" }}
                                >
                                  Make this a featured post?
                                </p>
  
                                <div
                                  style={{
                                    position: "relative",
                                    display: "inline-block",
                                    transition: "all 200ms ease-in-out",
                                    marginRight: "15px",
                                  }}
                                >
                                  <label
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      className="sr-only"
                                      checked={isEnabled}
                                      onChange={toggleSwitch}
                                    />
  
                                    <div
                                      style={{
                                        width: "50px",
                                        height: "28px",
                                        borderRadius: "50px",
                                        transition: "all 300ms",
                                        backgroundColor: isEnabled
                                          ? "#00B4BD"
                                          : "gray",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "28px",
                                          height: "28px",
                                          borderRadius: "50%",
                                          transition: "all 300ms",
                                          transform: isEnabled
                                            ? "translateX(28px)"
                                            : "",
                                          backgroundColor: "white",
                                          boxShadow:
                                            "0 4px 6px rgba(0, 0, 0, 0.1)",
                                        }}
                                      ></div>
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {openFeatureAddModel && isEnabled ? (
                          <Modal
                            className="custom-dialog"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={openFeatureAddModel}
                            onHide={handleClose}
                          >
                            <Modal.Header
                              style={{ padding: "12px", width: "99%" }}
                              closeButton
                            >
                              <Modal.Title>About Featured Post</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              {/* Are you sure you want to cancel this Friend Request? */}
                              <div
                                class="modal-body"
                                style={{ marginTop: "-37px" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "1.25rem",
                                    marginTop: "1.25rem",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <span>Duration</span>
  
                                    <span style={{ fontWeight: "bold" }}>
                                      Boost this post for
                                    </span>
  
                                    <select
                                      style={{
                                        width: "150px",
                                        color: "#707070",
                                        height: "50px",
                                        fontSize: "12px",
                                        background: "white",
                                        display: "flex",
                                        alignItems: "center",
                                        borderRadius: "10px",
                                        border: "1px solid #01B3BB",
                                        paddingTop: "14px",
                                        paddingRight: "30px",
                                        paddingBottom: "14px",
                                        paddingLeft: "20px",
                                        fontWeight: "bold",
                                      }}
                                      value={featureAdd.featured_ads_duration}
                                      onChange={(e) =>
                                        setFeatureAdd((prevState) => ({
                                          ...prevState,
                                          featured_ads_duration: e.target.value,
                                        }))
                                      }
                                      // onClick={handlePrice}
                                    >
                                      <option value="select">
                                        Select Duration
                                      </option>
  
                                      <option value="1 day">1 day</option>
  
                                      <option value="1 week">1 Week</option>
  
                                      <option value="2 week">2 Week</option>
  
                                      <option value="3 Week">3 Week</option>
  
                                      <option value="1 Month">1 Month</option>
                                    </select>
                                  </div>
  
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <span>Price</span>
  
                                    <input
                                      style={{
                                        width: "150px",
                                        color: "#707070",
                                        height: "50px",
                                        fontSize: "12px",
                                        background: "white",
                                        display: "flex",
                                        alignItems: "center",
                                        borderRadius: "10px",
                                        border: "1px solid #01B3BB",
                                        paddingTop: "14px",
                                        paddingRight: "30px",
                                        paddingBottom: "14px",
                                        paddingLeft: "20px",
                                        fontWeight: "bold",
                                      }}
                                      // placeholder="Feature Add Cost"
                                      value={featureAdd.featured_ads_cost}
                                      readOnly={true}
                                      onChange={(e) =>
                                        setFeatureAdd((prevState) => ({
                                          ...prevState,
                                          featured_ads_cost: e.target.value,
                                        }))
                                      }
                                    />
                                  </div>
  
                                  <button
                                    style={{
                                      marginTop: "8px",
                                      width: "100%",
                                      height: "67.19px",
                                      flexShrink: 0,
                                      border: "10px solid transparent",
                                      borderRadius: "10px",
                                      backgroundColor: "#01B3BB",
                                      color: "#FEFCFE",
                                      fontFamily: "montserrat",
                                      fontWeight: "bold",
                                      fontSize: "18px",
                                      textTransform: "uppercase",
                                    }}
                                    onClick={() => {
                                      setOpenPaymentAddModel(true);
                                    }}
                                  >
                                    Proceed Payment
                                  </button>
                                </div>
                              </div>
                            </Modal.Body>
                          </Modal>
                        ) : (
                          <></>
                        )}
  
                        {openPaymentAddModel && (
                          <Modal
                            className="custom-dialog"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={openFeatureAddModel}
                            onHide={handleClose}
                          >
                            <Modal.Header
                              style={{ padding: "12px", width: "99%" }}
                              closeButton
                            >
                              <Modal.Title>About Payment</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              {/* Are you sure you want to cancel this Friend Request? */}
                              <div
                                class="modal-body"
                                style={{ marginTop: "-37px" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "1.25rem",
                                    marginTop: "1.25rem",
                                  }}
                                >
                                  {/* <Payment
                                    onSuccess={onPaymentSuccess}
                                    setOpenPaymentAddModel={
                                      setOpenPaymentAddModel
                                    }
                                  /> */}
                                </div>
                              </div>
                            </Modal.Body>
                          </Modal>
                        )}
                      </div>
                    </div>
  
                    {/* tright side`` */}
  
                    <div
                      className="create_screenControls"
                      style={{
                        marginTop: "-20px",
                        borderColor: "#00B4BD",
                        color: "#00B4BD",
                      }}
                    >
                      <Link to="/house_listing/all" className="dis">
                        Cancel
                      </Link>
                      <Link onClick={AddpropertyMange} className="primary" to="#">
                        Save Changes
                      </Link>
                      {/* <button
                        className="primary"
                        to="#"
                        onClick={Addproperty}
                        style={{ padding: "13PX", width: "140px" }}
                      >
                        Update Profile
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          {/* </section>
        </main>
      </div> */}
       </div> 
       </div> 

    );
  }
  
export default HousingEdit;
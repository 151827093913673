import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/AppContext";
import { ShareSocial } from "react-share-social";
import Switch from 'react-switch';
import { Modal, Button } from "react-bootstrap";
import {
  deleteWithAuthCall,
  postMultipartWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
  simplePostCall,
  simpleGetCall,
} from "../../api/ApiServices";
import swal from "sweetalert";

import eventthumbImg01 from "../../assets/images/house01.png";
import LoaderCenter from "../../sharedComponent/LoaderCenter";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Slider } from "../../components/Slider";
import './AllPropertyHousing.css'
import CardView from './CardView'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faBuilding,
  faUsers,
  faBed,
  faBath,
  faMoneyBill,
  faDollarSign,
  faUser,
  faClock ,
  faTrashAlt,
  faHeart,
  faArrowAltCircleUp,
  faArrowAltCircleDown
} from "@fortawesome/free-solid-svg-icons";


const AllProperty = () => {
  const baseUrl = window.location.origin;
  const { keyword } = useContext(AppContext);
  const [dismiss, setDismiss] = useState(false);
  const [ShareData, setShare] = useState("");

  const filterModal = useRef(null);

  // filter api starts
  const { customerData } = useContext(AppContext);
  console.log("customerData", customerData.id);
  const [propertyfilterType, setPropertyFilterType] = useState({
    propertyType: "",
    gender: "",
    term: "",
    availability: "",
    gender:[],
    billsIncluded: null,
    smokedAllowed: null,
    minimum:0,
    maximum: 0,
  });
  const style = {
    background: "#F5F5F5",
    borderRadius: 3,
    border: 0,
    color: "white",
    padding: "0 0px",
    height: "200px",
    marginTop: "20px",
  };
  const [showElement, setShowElement] = useState(true);
  const currentRoute = useLocation().pathname;
  const [loading, setLoading] = useState(false);
  const [HouseingList, setHouseingList] = useState([]);
  const [HouseingAdsList, setHouseingAdsList] = useState([])
  const [houseingListadd, setHouseingAdd] = useState([]);
  const [HouseingMange, setHouseingMange] = useState([]);
  const [selectedID, setselectedID] = useState("");

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const navigate = useNavigate();

  useEffect(() => {
    getHouselist();
    console.log("check booolean",propertyfilterType);
  }, []);

  useEffect(() => {
    console.log("Filter Housing",propertyfilterType);
  }, [propertyfilterType]);


  useEffect(() => {
    if (keyword.length > 0) {
      getSearchedProducts(HouseingList);
    } else {
      getHouselist();
    }
  }, [keyword]);

  

  // const getHouselist = async () => {
  //   setLoading(true);

  //   await fetch(ApiConfig.UNIVERSR_HOUSING_ALL_PROPTIE_LIST)
  //     .then(response => {
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       return response.json();
  //     })
  //     .then(data => { 
  //       setLoading(false);
  //       if (keyword.length > 0) {
  //         getSearchedProducts(data.posts);
  //       } else {
  //         setHouseingList(data.posts);
  //       }
  //     })
  //     .catch(error => {
  //       setLoading(false);
  //       console.error('There was a problem fetching the data:', error);
  //     });
  // };

  const getHouselist = async () => {
    setLoading(true);
    try {
      const response = await fetch(ApiConfig.UNIVERSR_HOUSING_ALL_PROPTIE_LIST);
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      setLoading(false);
      if (keyword.length > 0) {
        getSearchedProducts(data.posts);
      } else {
        setHouseingList(data.posts);
      }
    } catch (error) {
      setLoading(false);
      console.error('There was a problem fetching the data:', error);
    }
  };

  const getFilteredlist = async () => {
    setLoading(true);

    await fetch(ApiConfig.UNIVERSR_HOUSING_ALL_PROPTIE_LIST)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => { 
        handleCloseModal();
        setLoading(false);
       
          getFilteredProducts(data.posts);

        //   setHouseingList(data.posts);
        // }
      })
      .catch(error => {
        setLoading(false);
        console.error('There was a problem fetching the data:', error);
      });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    };
    return date.toLocaleString('en-US', options);
  };

  const Delete = (id) => {
    deleteWithAuthCall(ApiConfig.UNIVERSR_HOUSING_MANGE_DELET + id)
      .then((res) => {
        console.log(res);
        swal(res.detail);
        getHouselist();
        navigate("/house_listing/all");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getSearchedProducts = (data) => {
    let matches = [];
    matches = data.filter((product) => {
      return product && product.title.toLowerCase().includes(keyword.toLowerCase());
    });
    setHouseingList(matches);
  };

  const filters = {
    propertyType: propertyfilterType.propertyType,
    availability:propertyfilterType.availability,
    smokedAllowed:propertyfilterType.smokedAllowed,
    billsIncluded:propertyfilterType.billsIncluded,
    term: propertyfilterType.term ,
    minimum:propertyfilterType.minimum,
    maximum:propertyfilterType.maximum,
    gender:'',
    

    // Add more filters as needed
  };

  const getFilteredProducts = (data) => {
    let matches = [...data]; // Copying the original data array

    if (filters.propertyType && !filters.propertyType=='') {
      matches = matches.filter(product => {
        return product && product.housingDetails && product.housingDetails.propertyType === filters.propertyType;
      });
    }
  
    if (filters.availability && !filters.availability=='') {
      matches = matches.filter(product => {
        return product && product.housingDetails && product.housingDetails.availability === filters.availability;
      });
    }

    // if (typeof filters.smokedAllowed === 'boolean' ) {
    //   matches = matches.filter(product => {
    //     return product && product.housingDetails && product.housingDetails.smokedAllowed === filters.smokedAllowed;
    //   });
    // }
    
    // if (typeof filters.billsIncluded === 'boolean'  ) {
    //   matches = matches.filter(product => {
    //     return product && product.housingDetails && product.housingDetails.billsIncluded === filters.billsIncluded;
    //   });
    // }

    if (filters.smokedAllowed !== null) {
      matches = matches.filter(product => {
        return product && product.housingDetails && product.housingDetails.smokedAllowed === filters.smokedAllowed;
      });
    }
    
    if (filters.billsIncluded !== null) {
      matches = matches.filter(product => {
        return product && product.housingDetails && product.housingDetails.billsIncluded === filters.billsIncluded;
      });
    }


    if (filters.minimum > 0 && filters.maximum === 0) {
  matches = matches.filter(product => {
    return product && product.housingDetails && product.housingDetails.monthlyRent && product.housingDetails.monthlyRent.value >= filters.minimum;
  });
}

// Check if only maximum is specified and it is greater than 0
if (filters.minimum === 0 && filters.maximum > 0) {
  matches = matches.filter(product => {
    return product && product.housingDetails && product.housingDetails.monthlyRent && product.housingDetails.monthlyRent.value <= filters.maximum;
  });
}

// Check if both minimum and maximum are specified and within a range
if (filters.minimum > 0 && filters.maximum > 0) {
  matches = matches.filter(product => {
    return product && product.housingDetails && product.housingDetails.monthlyRent && product.housingDetails.monthlyRent.value >= filters.minimum && product.housingDetails.monthlyRent.value <= filters.maximum;
  });
}

    
    setHouseingList(matches);
  };
  

  const AddIntersted = (id) => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.UNIVERSR_ADD_USER_INTERSTED,
      JSON.stringify({ housing_property_id: id })
    )
      .then((res) => {
        setHouseingAdd(res);
        console.log(res);
        swal(res?.json);
        getHouselist();
      })
      .then((data) => {
        swal(data?.json);
        setHouseingAdd(data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  // const propertyFilter = (e) => {
  //   setDismiss(false);

  //   let formData = new FormData();
  //   // formData.append("id", customerData.id);
  //   // formData.append("title", propertyfilterType.title);

  //   formData.append("propertyType", propertyfilterType.propertyType);
  //   // formData.append("gender", propertyfilterType.gender);
  //   // formData.append("term", propertyfilterType.term);
  //   // formData.append("availability", propertyfilterType.availability);
  //   // formData.append("billsIncluded", propertyfilterType.billsIncluded);
  //   // formData.append("smokedAllowed", propertyfilterType.smokedAllowed);
  //   // formData.append("minimum", propertyfilterType.minimum);
  //   // formData.append("maximum", propertyfilterType.maximum);

  //   setLoading(true);
  //   postMultipartWithAuthCallWithErrorResponse(
  //     ApiConfig.UNIVERSR_HOUSING_ALL_PROPTIE_LIST,
  //     formData
  //   )
  //     .then((res) => {
  //       console.log("Filter data housing",res);
  //       setHouseingList(res?.json?.housing_property);
  //       setHouseingMange([]);
  //       setLoading(false);
  //     })
  //     .catch((err) => console.log(err));
  // };

  // const propertyFilter = async (e) => {
  //   setDismiss(false);
  
  //   const queryParams = new URLSearchParams({
  //     // propertyType: propertyfilterType.propertyType,
  //     postType:'housing',
  //     title: propertyfilterType.propertyType,
  //     // Add other parameters as needed
  //   });
  
  //   setLoading(true);
  
  //   try {
  //     const response = await fetch(`${ApiConfig.UNIVERSR_FILTER_ALL_PROPTIE_LIST}?${queryParams}`);
  
  //     if (!response.ok) {
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     }
  
  //     const result = await response.json();
  //     console.log("Filter data housing", result);
      
  //     // setHouseingList(result.posts);
  //     setHouseingMange([]);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     setLoading(false);
  //   }
  // };
  

  const HousingId = (id) => {
    setselectedID(id);
  };

  const ShareHandal = async () => {
    let requestBody = JSON.stringify({
      dynamicLinkInfo: {
        domainUriPrefix: "https://myuniverse.page.link",

        link:
          "https://my-univrs.app.redbytes.in/housing-details/" +
          String(selectedID) +
          "/?user=" +
          String(customerData.id),

        androidInfo: {
          androidPackageName: "com.my_universe",
        },

        iosInfo: {
          iosBundleId: "org.myUniverse",
        },
      },
    });

    await fetch(
      "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBmt92Aby2xlZWOCIHeTutj8ywpF-4reyw",

      {
        method: "POST",

        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },

        body: requestBody,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("response", data);
        setShare(data.shortLink);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const [clicked, setClicked] = useState(false);

  const handleHeartClick = (event) => {
    setClicked(!clicked)
    event.preventDefault();
    event.stopPropagation();
    console.log('Heart icon clicked');
  };

  return (
    <>
      <div className="row  g-4">
      <div style={{ width: "100%", textAlign: 'right' }}>
  <button
    style={{
      height:"35px",
      marginRight:"20px",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '150px',
      backgroundColor: 'rgba(211, 211, 211, 0.5)',  // Light transparent grey background
      color: '#333333',            // Black text color
      border: '1px solid #333333', // Grey border
      borderRadius: '20px',        // Rounded corners
      cursor: 'pointer',           // Pointer cursor on hover
      fontSize: '15px',            // Font size
      fontWeight: '600',            // Font weight
      transition: 'background-color 0.3s',  // Smooth background color transition
      float: 'right',              // Align button to right
      padding: '10px',             // Padding inside the button
    }}
    onClick={handleShowModal}
  >
    {showModal ? (  <span style={{ marginRight: '10px' }}>
      <FontAwesomeIcon icon={faArrowAltCircleUp}  color="#808080"/>
    </span>):(
        <span style={{ marginRight: '10px' }}>
        <FontAwesomeIcon icon={faArrowAltCircleDown} color="#808080"/>
      </span>
    )}
  
  
    filter
  </button>
  </div>

        {loading ? (
          <LoaderCenter/>
        ) : 
        HouseingList && HouseingList.length > 0 ?
         (  
          <div style={{ width: "1120px", padding: "20px", margin: "0 auto" }}>
          <div style={{ display: "flex", flexWrap: "wrap", gap:'25px' }}>
            {HouseingList.map((house, index) => (
              <CardView key={index} house={house} index={index} />
            ))}
          </div>
        </div>
        ) 
        : (
          <p
            style={{
              fontSize: "20px",
              marginTop: "200px",
            }}
            className=" text-center justify-content-center align-items-center"
          >
            Oops! It doesn't look like there are any properties in your area.
            <br /> Why don't you add some?
          </p>
        )}
        {/* filter modal */}
        <Modal
          // ref={filterModal}
          show={showModal}
          onHide={handleCloseModal}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          // centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Filter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>

              <div className="mb-3">
                <label htmlFor="propertyType" className="form-label">
                  Property Type
                </label>
                <select
                  className="form-control"
                  id="propertyType"
                  value={propertyfilterType.propertyType}
                  onChange={(e) =>
                    setPropertyFilterType({
                      ...propertyfilterType,
                      propertyType: e.target.value,
                    })
                  }
                >
                  <option value="">Select</option>
                  <option value="house">House</option>
                                <option value="studio">Studio</option>
                                <option value="flat">Flat</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="gender" className="form-label">
                  Gender
                </label>
                <select
                  className="form-control"
                  id="gender"
                  value={propertyfilterType.gender}
                  onChange={(e) =>
                    setPropertyFilterType({
                      ...propertyfilterType,
                      gender: e.target.value,
                    })
                  }
                >
                  <option value="">Select</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="any">Any</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="term" className="form-label">
                  Term
                </label>
                <select
                  className="form-control"
                  id="term"
                  value={propertyfilterType.term}
                  onChange={(e) =>
                    setPropertyFilterType({
                      ...propertyfilterType,
                      term: e.target.value,
                    })
                  }
                >
                  <option value="">Select</option>
                  <option value="short">Short</option>
                  <option value="long">Long</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="availability" className="form-label">
                Availability
                </label>
                <select
                  className="form-control"
                  id="availability"
                  value={propertyfilterType.availability}
                  onChange={(e) =>
                    setPropertyFilterType({
                      ...propertyfilterType,
                      availability: e.target.value,
                    })
                  }
                >
                    <option value="">
                                    Select
                                    </option>
                                    <option value="Immediately">Immediately</option>
                                    <option value="Within 1 week">Within 1 week</option>
                                    <option value="Within 2 weeks">Within 2 weeks</option>
                </select>
              </div>

{/* <div className="mb-3">
  <label htmlFor="billsIncluded" className="form-label">
    Bills Included
  </label>
  <select
    className="form-control"
    id="billsIncluded"
    value={propertyfilterType.billsIncluded}
    onChange={(e) =>
      setPropertyFilterType({
        ...propertyfilterType,
        billsIncluded: e.target.value,
      })
    }
  > <option value={null} >Select</option>
  <option value={true}>Yes</option>
  <option value={false}>No</option>
  </select>
</div>

<div className="mb-3">
  <label htmlFor="smokedAllowed" className="form-label">
    Smoke Allowed
  </label>
  <select
    className="form-control"
    id="smokedAllowed"
    value={propertyfilterType.smokedAllowed}
    onChange={(e) =>
      setPropertyFilterType({
        ...propertyfilterType,
        smokedAllowed: e.target.value,
      })
    }
  >
    <option value={null}>Select</option>
    <option value={true}>Yes</option>
    <option value={false}>No</option>
  </select>
</div> */}

<div className="mb-3">
  <label htmlFor="billsIncluded" className="form-label">Bills Included</label>
  <select
    className="form-control"
    id="billsIncluded"
    value={propertyfilterType.billsIncluded}
    onChange={(e) => setPropertyFilterType({
      ...propertyfilterType,
      billsIncluded: e.target.value === 'true' ? true : e.target.value === 'false' ? false : null,
    })}
  >
    <option value="null">Select</option>
    <option value="true">Yes</option>
    <option value="false">No</option>
  </select>
</div>

<div className="mb-3">
  <label htmlFor="smokedAllowed" className="form-label">Smoke Allowed</label>
  <select
    className="form-control"
    id="smokedAllowed"
    value={propertyfilterType.smokedAllowed}
    onChange={(e) => setPropertyFilterType({
      ...propertyfilterType,
      smokedAllowed: e.target.value === 'true' ? true : e.target.value === 'false' ? false : null,
    })}
  >
    <option value="null">Select</option>
    <option value="true">Yes</option>
    <option value="false">No</option>
  </select>
</div>



               {/* <div className="mb-3" >
                  <label className="form-label" style={{ marginTop: "20px" }}>
                    <Switch
                    onChange={(checked) =>
                      setPropertyFilterType({
                        ...propertyfilterType,
                        smokedAllowed: checked,
                      })
                    }
                    checked={propertyfilterType.smokedAllowed}      
                                    offColor="#888"
                      onColor="#0b9"
                      uncheckedIcon={false}
                      checkedIcon={false}
                    />
                    {' '}
                    Smoke Allowed
                  </label>
                  <span style={{ marginLeft: '10px' }}>
                    {propertyfilterType.smokedAllowed ? 'Allowed' : 'Not Allowed'}
                  </span>
                </div> */}

              <div className="mb-3">
                <label htmlFor="minimum" className="form-label">
                  Minimum Price
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="minimum"
                  value={propertyfilterType.minimum}
                  onChange={(e) =>
                    setPropertyFilterType({
                      ...propertyfilterType,
                      minimum: e.target.value,
                    })
                  }
                />
              </div>
              <div className="mb-3">
                <label htmlFor="maximum" className="form-label">
                  Maximum Price
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="maximum"
                  value={propertyfilterType.maximum}
                  onChange={(e) =>
                    setPropertyFilterType({
                      ...propertyfilterType,
                      maximum: e.target.value,
                    })
                  }
                />
              </div>
              {/* <Button variant="primary" onClick={propertyFilter}>
                Apply Filters
              </Button> */}
            </form>
          </Modal.Body>
          <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={getFilteredlist}>
            Apply Filters
          </Button>
        </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default AllProperty;

import React, { useState,useRef  ,useEffect} from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faEllipsisV, faShareAlt, faPhone } from "@fortawesome/free-solid-svg-icons";
import './OfferCardList.css';

const CardView = ({ house, index }) => {
  const [miniCardVisible, setMiniCardVisible] = useState(false);
  
  const miniCardRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (miniCardRef.current && !miniCardRef.current.contains(event.target)) {
        setMiniCardVisible(false);
      }
    };
    document.body.addEventListener('click', handleOutsideClick);
    return () => {
      document.body.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    };
    return date.toLocaleString('en-US', options);
  };

  const toggleMiniCard = (event) => {
    setMiniCardVisible(!miniCardVisible);
    event.preventDefault(); // Prevent default action
    event.stopPropagation(); // Stop event propagation
    // Add your logic for what happens when the heart icon is clicked
    console.log('Heart icon clicked');

  };

  return (
    <div key={index} className="card" style={{ position: 'relative' }}>
      <Link to={`//${window.location.host}/fashion/product/${house._id}`}>
        <div style={{ width: "100%", height: "40%", display: "flex" }}>
          {house.images[0] ? (
            <img
              src={house.images[0]}
              alt="pic"
              style={{
                width: "85%",
                objectFit: 'cover',
                height: "135px",
                padding: "5px",
                borderRadius: "10px"
              }}
            />
          ) : (
            <div
              style={{
                backgroundColor: "#E8E9E8",
                width: "85%",
                height: "125px",
                margin: "5px",
                padding: "15px",
                borderRadius: "10px"
              }}
            ></div>
          )}

          <div style={{ width: "40%", paddingRight: "5px" }}>
            {house.images[1] ? (
              <img
                src={house.images[1]}
                alt="pic"
                style={{
                  width: "100%",
                  objectFit: 'cover',
                  height: "60px",
                  marginTop: "5px",
                  borderRadius: "5px"
                }}
              />
            ) : (
              <div
                style={{
                  backgroundColor: "#E8E9E8",
                  width: "100%",
                  height: "60px",
                  margin: '5px 5px 5px 0',
                  paddingTop: "5px",
                  borderRadius: "5px"
                }}
              ></div>
            )}

            {house.images[2] ? (
              <img
                src={house.images[2]}
                alt="pic"
                style={{
                  width: "100%",
                  objectFit: 'cover',
                  height: "60px",
                  marginTop: "5px",
                  borderRadius: "5px"
                }}
              />
            ) : (
              <div
                style={{
                  backgroundColor: "#E8E9E8",
                  width: "100%",
                  height: "60px",
                  margin: '5px 5px 5px 0',
                  paddingTop: "5px",
                  borderRadius: "5px"
                }}
              ></div>
            )}
          </div>
        </div>

        <div style={{ padding: "20px", marginTop: "15px" }}>
          {/* <p style={{ fontSize: '12px', marginTop: "5px" }}>
            posted date: {formatDate(house.postedDate)}
          </p> */}
          <h6 style={{ marginTop: "10px" }}>{house.product?.productName}</h6>
          <div style={{ display: "flex", marginTop: "8px", width: "100%" }}>
            <div style={{ display: "flex" }}>
              <div>
                <FontAwesomeIcon icon={faBuilding} style={{ color: "red" }} />
              </div>
              <div style={{ marginLeft: "10px", marginTop: "3px" }}>
                <h6 > {house?.offerType}</h6>
              </div>
            </div>
          </div>
        </div>
      </Link>

      {/* <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
        <FontAwesomeIcon icon={faEllipsisV} onClick={toggleMiniCard} style={{ cursor: 'pointer' }} />
      </div> */}
      <div style={{ position: 'absolute', bottom: '20px', right: '10px' ,width:'15px',height:'15px',}}>
        <FontAwesomeIcon icon={faEllipsisV} onClick={toggleMiniCard} style={{ cursor: 'pointer' }} />
      </div>

      {miniCardVisible && (
        <div 
        ref={miniCardRef}
        style={{
          position: 'absolute',
          bottom: '40px',
          right: '10px',
          backgroundColor: 'white',
          border: '1px solid #ddd',
          borderRadius: '5px',
          boxShadow: '0 0 10px rgba(0,0,0,0.1)',
          zIndex: 1000,
          padding: '10px',
        }}>
          <button  style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', background: 'none', border: 'none', cursor: 'pointer' }}>
            {/* <FontAwesomeIcon icon={faShareAlt} style={{ marginRight: '5px' }} /> */}
            <Link

to='#'
>
Share
</Link>
          </button>
          <button style={{ display: 'flex', alignItems: 'center', background: 'none', border: 'none', cursor: 'pointer' }}>
            {/* <FontAwesomeIcon icon={faPhone} style={{ marginRight: '5px' }} /> */}
            <Link

to='#'
//  style={{fontSize:"14px"}}
>
Contact
</Link>
          </button>
        </div>
      )}
    </div>
  );
};

export default CardView;

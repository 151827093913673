// import React, { useContext, useState, useEffect } from 'react'
// import { Link, useParams } from 'react-router-dom'
// import { AppContext } from '../../context/AppContext'
// import Sidebar from '../../components/Sidebar'
// import Header from '../../components/Header'
// // import HousingTabsHeader from "./HousingTabsHeader";
// import thumbImg from '../../assets/images/house01.png'
// import location_icon from '../../assets/images/location_icon.svg'
// import gray_location_icon from '../../assets/images/gray_location_icon.svg'
// import tooltip_icon from '../../assets/images/tooltip_icon.svg'
// import {
//   simpleGetCallWithErrorResponse,
//   simplePostCall,
// } from '../../api/ApiServices'
// import ApiConfig from '../../api/ApiConfig'
// import LoaderCenter from '../../sharedComponent/LoaderCenter'
// import swal from 'sweetalert'
// import { ShareSocial } from 'react-share-social'
// import { Modal, Button } from 'react-bootstrap'
// import { CopyToClipboard } from 'react-copy-to-clipboard'


// const style = {
//   background: '#F5F5F5',
//   borderRadius: 3,
//   border: 0,
//   color: 'white',
//   padding: '0 0px',
//   // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
//   height: '200px',
//   marginTop: '20px',
//   // width: '20px',
// }

// function getWindowDimensions() {
//   const { innerWidth: width, innerHeight: height } = window
//   return {
//     width,
//     height,
//   }
// }

// function EventDetails() {
//   const params = useParams()
//   let id = params.id
//   const { sidebar, setSidebar } = useContext(AppContext)
//   const { customerData } = useContext(AppContext)
//   const [loading, setLoading] = useState(false)
//   const [screenWidth, setScreenWidth] = useState(window.innerWidth)
//   const [EventDetalis, setEventDetalis] = useState([{}])
//   const [showModal, setShow] = useState(false)
//   const handleClose = () => setShow(false)
//   const handleShow = () => {
    
//     setShow(true)
//     ShareHandal()
//   }
//   const [showElement, setShowElement] = React.useState(true)
//   const [ShareData, setShare] = useState('')

//   const [windowDimensions, setWindowDimensions] = useState(
//     getWindowDimensions(),
//   )
//   const [toolOpen, setToolOpen] = useState(false)
//   useEffect(() => {
//     geDetailsEvent()
//   }, [])

//   const geDetailsEvent = () => {
//     setLoading(true)

//     simpleGetCallWithErrorResponse(
//       ApiConfig.EVENT_DETAIL + id + '/',
//       JSON.stringify({ ...customerData }),
//     ).then((res) => {
//       console.log(res)
//       setLoading(false)
//       setEventDetalis(res.json.event)
//     })
//   }

//   const buyEvent = (id) => {
//     simplePostCall(
//       ApiConfig.UNIVERSR_EVENT_LIST_BUY,
//       JSON.stringify({ event_id: id }),
//     )
//       .then((res) => {
//         console.log(res)

//         swal(res.message)
//       })
//       .catch((err) => {
//         console.log(err)
//       })
//   }


//   const ShareHandal = async () => {
//     let requestBody = JSON.stringify({
//       dynamicLinkInfo: {
//         domainUriPrefix: 'https://myuniverse.page.link',

//         link:
//           'https://my-univrs.app.redbytes.in/event_details/' +
//           String(id) +
//           '/?user=' +
//           String(customerData.id),

//         androidInfo: {
//           androidPackageName: 'com.my_universe',
//         },

//         iosInfo: {
//           iosBundleId: 'org.myUniverse',
//         },
//       },
//     })

//     await fetch(
//       'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBmt92Aby2xlZWOCIHeTutj8ywpF-4reyw',

//       {
//         method: 'POST',

//         headers: {
//           Accept: 'application/json',

//           'Content-Type': 'application/json',
//         },

//         body: requestBody,
//       },
//     )
//       .then((response) => response.json())

//       .then((data) => {
//         console.log('response', data)
//         setShare(data.shortLink)
//       })

//       .catch((err) => {
//         // this.setState({isVisible:false})

//         console.log('error', err)
//       })
//   }



//   return (
//     <div>
//       <Sidebar />

//       {/* <Header /> */}
//       <main id="cxMain" className={sidebar ? 'cx-main cx-active' : 'cx-main'}>
//         {loading ? (
//           <LoaderCenter />
//         ) : (
//           <section className="content-main">
//               <Modal
//               className="custom-dialog"
//               aria-labelledby="contained-modal-title-vcenter"
//               centered
//               show={showModal}
//               onHide={handleClose}
//             >
//               <Modal.Header
//                 style={{ padding: '12px', width: '99%' }}
//                 closeButton
//               >
//                 <Modal.Title></Modal.Title>
//               </Modal.Header>
//               <Modal.Body>
//               {/* Are you sure you want to cancel this Friend Request? */}
//               <div class="modal-body" style={{ marginTop: '-37px' }}>
//                 <ShareSocial
//                   style={style}
//                   url={ShareData}
//                   socialTypes={[
//                     'facebook',
//                     'twitter',
//                     'reddit',
//                     'linkedin',
//                     'email',
//                     'pinterest',
//                   ]}
//                 />
//                 <CopyToClipboard
//                     text={ShareData}
//                     onCopy={() => swal('Copied to Clipboard')}
//                   >
//                     <Link to="#" className='copy-icon'>
//                       <i class="fa-solid fa-copy"></i>
//                     </Link>
//                   </CopyToClipboard>
//               </div>
//             </Modal.Body>
//             </Modal>
//             <div className="row">
//               <div className="col-xl-5 detail-section col-12 order-2 order-xl-1">
//                 <div
//                   className="gbl_thumbWrapper gbl_thumbWrapper02"
//                   style={{ marginTop: '0 ' }}
//                 >
//                   <div
//                     className="img_thumbWrapper img_thumbWrapperAdj01"
//                     style={{ padding: '20px' }}
//                   >
//                     <Link to="/events/all">
//                       <i class="fa-solid fa-arrow-left"></i>
//                     </Link>

//                     <div class="dropdown dot-button">
//                       <button
//                         class="btn"
//                         type="button"
//                         id="dropdownMenuButton1"
//                         data-bs-toggle="dropdown"
//                         aria-expanded="false"
//                         style={{
//                           height: '34px',
//                           width: '34px',
//                         }}
//                       >
//                         <i class="fa-solid fa-ellipsis-vertical"></i>
//                       </button>
//                       <ul
//                         class="dropdown-menu"
//                         aria-labelledby="dropdownMenuButton1"
//                       >
//                         <li>
//                           <Link
//                             onClick={handleShow}
//                             to="#"
//                             class="dropdown-item"
//                             href="#"
//                           >
//                             Share
//                           </Link>
//                         </li>
//                       </ul>
//                     </div>
//                     <img
//                       className="thumbImage"
//                       src={EventDetalis.image}
//                       alt=""
//                       style={{ objectFit: 'cover' }}
//                     />
//                   </div>

//                   <div className="gbl_thumbContent">
//                     <div className="detail-heading">
//                       <h1
//                         style={{
//                           color: '#1D1D25',
//                           fontSize: '20px',
//                           fontWeight: 'bold',
//                         }}
//                       >
//                         {' '}
//                         {EventDetalis.event_type}
//                       </h1>
//                       <div className="icons-container d-flex align-items-center">
//                         <label
//                           className="heart-check"
//                           style={{ position: 'static' }}
//                         >
//                           <input class="check" type="checkbox" />
//                           <span class="label-text"></span>
//                         </label>
//                         <div className="details-actions ms-4">
//                           {/* <Link
//                             className=""
//                             to=""
//                             onClick={() => setToolOpen(!toolOpen)}
//                           >
//                             <img src={tooltip_icon} alt="" />
//                           </Link> */}
//                         </div>
//                       </div>
//                     </div>

//                     <h6
//                       style={{
//                         color: '#1D1D25',
//                         fontSize: '16px ',
//                         fontWeight: '600',
//                       }}
//                     >
//                       by{' '}
//                       <Link to="" style={{ textDecoration: 'underline' }}>
//                         {EventDetalis.name}
//                       </Link>
//                     </h6>
//                     <h4>
//                       {' '}
//                       <i>
//                         <img
//                           src={gray_location_icon}
//                           alt=""
//                           style={{ marginRight: '10px' }}
//                         />
//                       </i>{' '}
//                       {EventDetalis.address}
//                     </h4>

//                     <div className="gbl_thumbControlWrapper gbl_thumbControlWrapper03">
//                       <p
//                         style={{
//                           lineHeight: 'normal',
//                           fontFamily: 'Nunito',
//                         }}
//                       >
//                         <i
//                           class="fa-regular fa-calendar"
//                           style={{ marginRight: '10px' }}
//                         ></i>
//                         {EventDetalis.start_time} - {EventDetalis.end_time}
//                       </p>

//                       <p style={{ lineHeight: 'normal' }}>
//                         <i class="fa-regular fa-calendar"></i>
//                         {EventDetalis.start_date} - {EventDetalis.end_date}
//                       </p>
//                     </div>

//                     <div className="gbl_thumbControlWrapper gbl_thumbControlWrapper02">
//                       <h1
//                         style={{
//                           color: '#1D1D25',
//                           fontSize: '16px',
//                           fontWeight: '600',
//                         }}
//                       >
//                         About Event
//                       </h1>
//                       <p
//                         style={{
//                           color: '#707070',
//                           fontSize: '14px',
//                           fontWeight: '600',
//                           marginTop: '10px',
//                         }}
//                       >
//                         {EventDetalis.description}
//                       </p>
//                     </div>
//                     <div className="d-flex justify-content-center">
//                       <button
//                         onClick={() => {
//                           buyEvent(EventDetalis.id)
//                         }}
//                         className="ep-action"
//                       >
//                         {EventDetalis.is_bought === 'bought'
//                           ? 'Bought'
//                           : 'Buy Ticket'}
//                       </button>
//                     </div>
//                   </div>
//                   <div
//                     className={`mainLocWrapper ${
//                       windowDimensions.width >= 1200
//                         ? 'right-popup'
//                         : 'left-popup'
//                     }`}
//                   >
//                     {' '}
//                     <div
//                       className={`tooltipInfoWrapper ${
//                         toolOpen ? 'tool-open' : 'tool-close'
//                       }`}
//                     >
//                       <div
//                         className={`triangle-left ${
//                           windowDimensions.width >= 1200
//                             ? 'right-icon'
//                             : 'left-icon'
//                         } `}
//                       ></div>
//                       <div className="ttInfoHeader">
//                         <h1>Location & Address</h1>
//                         <Link
//                           to="#"
//                           style={{ margin: '0' }}
//                           onClick={() => setToolOpen(false)}
//                         >
//                           <i class="fa-solid fa-xmark"></i>
//                         </Link>
//                       </div>

//                       <div className="ttMapWrapper">
//                         <iframe
//                           className="actAsDiv"
//                           frameborder="0"
//                           scrolling="no"
//                           marginheight="0"
//                           marginwidth="0"
//                           src="https://maps.google.com/maps?f=q&amp;source=s_q&amp;q=Adobe%20Systems%20Inc%2C%20Park%20Avenue%2C%20San%20Jose%2C%20CA&amp;aq=0&amp;ie=UTF8&amp;t=m&amp;z=12&amp;iwloc=A&amp;output=embed"
//                         >
//                           {' '}
//                         </iframe>
//                       </div>

//                       <div className="ttAddressWrapper">
//                         <h1>Address</h1>
//                         <p>4111 Milford Street Portsmouth 603-810-0381</p>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-xl-7  col-12  order-1 order-xl-2 d-flex justify-content-end align-items-start mb-3">
//                 <div className="globalPages_tabWrapper">
//                   <div className="gb_tabs">
//                     <div className="create-location">
//                       <div>
//                         <Link to="#" className="location-button">
//                           <p>
//                             <span
//                               className="content"
//                               style={{
//                                 marginLeft: '8px',
//                                 width: 'fit-content',
//                                 maxWidth: '100px',
//                                 textAlign: 'start',
//                               }}
//                             >
//                               {customerData.location}{' '}
//                             </span>
//                             <span className="dot mx-1"></span>
//                             <span>{customerData.radius} kms</span>
//                           </p>
//                         </Link>
//                       </div>
//                       <div>
//                         <Link
//                           className="create-event-button"
//                           to="/create_event"
//                         >
//                           Create Event
//                         </Link>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </section>
//         )}
//       </main>
//     </div>
//   )
// }

// export default EventDetails


import React, { useContext, useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { AppContext } from '../../context/AppContext'
import Sidebar from '../../components/Sidebar'
import Header from '../../components/Header'
// import HousingTabsHeader from "./HousingTabsHeader";
import thumbImg from '../../assets/images/house01.png'
import location_icon from '../../assets/images/location_icon.svg'
import gray_location_icon from '../../assets/images/gray_location_icon.svg'
import tooltip_icon from '../../assets/images/tooltip_icon.svg'
import {
  simpleGetCallWithErrorResponse,
  simplePostCall,
} from '../../api/ApiServices'
import ApiConfig from '../../api/ApiConfig'
import LoaderCenter from '../../sharedComponent/LoaderCenter'
import swal from 'sweetalert'
import { ShareSocial } from 'react-share-social'
import { Modal, Button } from 'react-bootstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const style = {
  background: '#F5F5F5',
  borderRadius: 3,
  border: 0,
  color: 'white',
  padding: '0 0px',
  // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  height: '200px',
  marginTop: '20px',
  // width: '20px',
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

function EventDetails() {
  const params = useParams()
  let id = params.id
  const { sidebar, setSidebar } = useContext(AppContext)
  const { customerData } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [eventDetails, setEventDetails] = useState([{}])
  const [showModal, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => {
    
    setShow(true)
    shareHandle()
  }
  const [showElement, setShowElement] = React.useState(true)
  const [shareData, setShare] = useState('')

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  )
  const [toolOpen, setToolOpen] = useState(false)
  useEffect(() => {
    getEventDetails()
  }, [])

  const getEventDetails = () => {
    setLoading(true)

    simpleGetCallWithErrorResponse(
      ApiConfig.EVENT_DETAIL + id + '/',
      JSON.stringify({ ...customerData }),
    ).then((res) => {
      console.log(res)
      setLoading(false)
      setEventDetails(res.json.event)
    })
  }

  const buyEvent = (id) => {
    simplePostCall(
      ApiConfig.UNIVERSR_EVENT_LIST_BUY,
      JSON.stringify({ event_id: id }),
    )
      .then((res) => {
        console.log(res)

        swal(res.message)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const shareHandle = async () => {
    let requestBody = JSON.stringify({
      dynamicLinkInfo: {
        domainUriPrefix: 'https://myuniverse.page.link',

        link:
          'https://my-univrs.app.redbytes.in/event_details/' +
          String(id) +
          '/?user=' +
          String(customerData.id),

        androidInfo: {
          androidPackageName: 'com.my_universe',
        },

        iosInfo: {
          iosBundleId: 'org.myUniverse',
        },
      },
    })

    await fetch(
      'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=YOUR_FIREBASE_API_KEY',

      {
        method: 'POST',

        headers: {
          Accept: 'application/json',

          'Content-Type': 'application/json',
        },

        body: requestBody,
      },
    )
      .then((response) => response.json())

      .then((data) => {
        console.log('response', data)
        setShare(data.shortLink)
      })

      .catch((err) => {
        console.log('error', err)
      })
  }

  return (
    <div>
      <Sidebar />

      {/* <Header /> */}
      <main id="cxMain" className={sidebar ? 'cx-main cx-active' : 'cx-main'}>
        {loading ? (
          <LoaderCenter />
        ) : (
          <section className="content-main">
            <Modal
              className="custom-dialog"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={showModal}
              onHide={handleClose}
            >
              <Modal.Header
                style={{ padding: '12px', width: '99%' }}
                closeButton
              >
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* Are you sure you want to cancel this Friend Request? */}
                <div class="modal-body" style={{ marginTop: '-37px' }}>
                  <ShareSocial
                    style={style}
                    url={shareData}
                    socialTypes={[
                      'facebook',
                      'twitter',
                      'reddit',
                      'linkedin',
                      'email',
                      'pinterest',
                    ]}
                  />
                  <CopyToClipboard
                    text={shareData}
                    onCopy={() => swal('Copied to Clipboard')}
                  >
                    <Link to="#" className='copy-icon'>
                      <i class="fa-solid fa-copy"></i>
                    </Link>
                  </CopyToClipboard>
                </div>
              </Modal.Body>
            </Modal>
            <div className="row">
              <div className="col-xl-5 detail-section col-12 order-2 order-xl-1">
                <div
                  className="gbl_thumbWrapper gbl_thumbWrapper02"
                  style={{ marginTop: '0 ' }}
                >
                  <div
                    className="img_thumbWrapper img_thumbWrapperAdj01"
                    style={{ padding: '20px' }}
                  >
                    <Link to="/events/all">
                      <i class="fa-solid fa-arrow-left"></i>
                    </Link>

                    <div class="dropdown dot-button">
                      <button
                        class="btn"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          height: '34px',
                          width: '34px',
                        }}
                      >
                        <i class="fa-solid fa-ellipsis-vertical"></i>
                      </button>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <Link
                            onClick={handleShow}
                            to="#"
                            class="dropdown-item"
                            href="#"
                          >
                            Share
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <img
                      className="thumbImage"
                      src={eventDetails.image}
                      alt=""
                      style={{ objectFit: 'cover' }}
                    />
                  </div>

                  <div className="gbl_thumbContent">
                    <div className="detail-heading">
                      <h1
                        style={{                          color: '#1D1D25',
                        fontSize: '20px',
                        fontWeight: 'bold',
                      }}
                    >
                      {' '}
                      {eventDetails.event_type}
                    </h1>
                    <div className="icons-container d-flex align-items-center">
                      <label
                        className="heart-check"
                        style={{ position: 'static' }}
                      >
                        <input class="check" type="checkbox" />
                        <span class="label-text"></span>
                      </label>
                      <div className="details-actions ms-4">
                        {/* <Link
                          className=""
                          to=""
                          onClick={() => setToolOpen(!toolOpen)}
                        >
                          <img src={tooltip_icon} alt="" />
                        </Link> */}
                      </div>
                    </div>
                  </div>

                  <h6
                    style={{
                      color: '#1D1D25',
                      fontSize: '16px ',
                      fontWeight: '600',
                    }}
                  >
                    by{' '}
                    <Link to="" style={{ textDecoration: 'underline' }}>
                      {eventDetails.name}
                    </Link>
                  </h6>
                  <h4>
                    {' '}
                    <i>
                      <img
                        src={gray_location_icon}
                        alt=""
                        style={{ marginRight: '10px' }}
                      />
                    </i>{' '}
                    {eventDetails.address}
                  </h4>

                  <div className="gbl_thumbControlWrapper gbl_thumbControlWrapper03">
                    <p
                      style={{
                        lineHeight: 'normal',
                        fontFamily: 'Nunito',
                      }}
                    >
                      <i
                        class="fa-regular fa-calendar"
                        style={{ marginRight: '10px' }}
                      ></i>
                      {eventDetails.start_time} - {eventDetails.end_time}
                    </p>

                    <p style={{ lineHeight: 'normal' }}>
                      <i class="fa-regular fa-calendar"></i>
                      {eventDetails.start_date} - {eventDetails.end_date}
                    </p>
                  </div>

                  <div className="gbl_thumbControlWrapper gbl_thumbControlWrapper02">
                    <h1
                      style={{
                        color: '#1D1D25',
                        fontSize: '16px',
                        fontWeight: '600',
                      }}
                    >
                      About Event
                    </h1>
                    <p
                      style={{
                        color: '#707070',
                        fontSize: '14px',
                        fontWeight: '600',
                        marginTop: '10px',
                      }}
                    >
                      {eventDetails.description}
                    </p>
                  </div>
                  <div className="d-flex justify-content-center">
                    <button
                      onClick={() => {
                        buyEvent(eventDetails.id)
                      }}
                      className="ep-action"
                    >
                      {eventDetails.is_bought === 'bought'
                        ? 'Bought'
                        : 'Buy Ticket'}
                    </button>
                  </div>
                </div>
                <div
                  className={`mainLocWrapper ${
                    windowDimensions.width >= 1200
                      ? 'right-popup'
                      : 'left-popup'
                  }`}
                >
                  {' '}
                  <div
                    className={`tooltipInfoWrapper ${
                      toolOpen ? 'tool-open' : 'tool-close'
                    }`}
                  >
                    <div
                      className={`triangle-left ${
                        windowDimensions.width >= 1200
                          ? 'right-icon'
                          : 'left-icon'
                      } `}
                    ></div>
                    <div className="ttInfoHeader">
                      <h1>Location & Address</h1>
                      <Link
                        to="#"
                        style={{ margin: '0' }}
                        onClick={() => setToolOpen(false)}
                      >
                        <i class="fa-solid fa-xmark"></i>
                      </Link>
                    </div>

                    <div className="ttMapWrapper">
                      <iframe
                        className="actAsDiv"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                        src="https://maps.google.com/maps?f=q&amp;source=s_q&amp;q=Adobe%20Systems%20Inc%2C%20Park%20Avenue%2C%20San%20Jose%2C%20CA&amp;aq=0&amp;ie=UTF8&amp;t=m&amp;z=12&amp;iwloc=A&amp;output=embed"
                      >
                        {' '}
                      </iframe>
                    </div>

                    <div className="ttAddressWrapper">
                      <h1>Address</h1>
                      <p>4111 Milford Street Portsmouth 603-810-0381</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-7  col-12  order-1 order-xl-2 d-flex justify-content-end align-items-start mb-3">
              <div className="globalPages_tabWrapper">
                <div className="gb_tabs">
                  <div className="create-location">
                    <div>
                      <Link to="#" className="location-button">
                        <p>
                          <span
                            className="content"
                            style={{
                              marginLeft: '8px',
                              width: 'fit-content',
                              maxWidth: '100px',
                              textAlign: 'start',
                            }}
                          >
                            {customerData.location}{' '}
                          </span>
                          <span className="dot mx-1"></span>
                          <span>{customerData.radius} kms</span>
                        </p>
                      </Link>
                    </div>
                    <div>
                      <Link
                        className="create-event-button"
                        to="/create_event"
                      >
                        Create Event
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </main>
  </div>
)
}

export default EventDetails


const BASE_URL = 'http://localhost:8000/api/'
// const NODE_URL ='http://localhost:3080/'
// const BASE_NEW_URL = 'http://localhost:3080/'
const NODE_URL ='https://api.myunivrs.com/'
const BASE_NEW_URL = 'https://api.myunivrs.com/'

const ApiConfig = {
  BASE_URL: BASE_URL,
  NODE_URL: NODE_URL,
  BASE_URL_FOR_IMAGES: "http://myuniverse.redbytes.in/",
  BASE_NEW_FOR_IMAGES: "http://localhost:5000",
  BASE_NEW_URL: BASE_NEW_URL,
  /* Universal API url Start*/
  UNIVERSR_LOGIN_API: BASE_URL + "login",
  UNIVERSR_ACCOUNT_DELETE: BASE_URL + "delete_account/",
  SOCIAL_LOGIN: BASE_URL + "web_login/",
  NOTIFICATION: BASE_URL + "in_app_notifications/",
  NOTIFICATION_COUNT: BASE_URL + "in_app_notification_count/",
  UNIVERSR_FORGET_EMAIL: BASE_URL + "password_reset",
  UNIVERSR_SET_PASSWORD: BASE_URL + "set_password",
  UNIVERSR_TERMSANDCONDITION_API: BASE_URL + "terms_and_condition/",
  UNIVERSR_ABOUT_US: BASE_URL + "about_us/",
  UNIVERSR_CONTACTUS: BASE_URL + "contact_us/",
  UNIVERSR_PRIVACY_POLICY: BASE_URL + "privacy_policy/",
  UNIVERSR_USER_PROFILE: BASE_URL + "user_profile/",
  UNIVERSR_LIST: BASE_URL + "institutes_list/",
  UNIVERSR_PRIVACY_SETING: BASE_URL + "privacy_settings/",
  GET_PRIVACY_SETING: BASE_URL + "privacy_settings/",
  CONTACT_API: BASE_URL + "contact_us/",
  UNIVERSR_FRIEND_LIST_ACCEPTED:
    BASE_URL + "friend_list/?request_status=Accepted",
  UNIVERSR_FRIEND_LIST_PENDDING:
    BASE_URL + 'friend_list/?request_status=Pending',
  UNIVERSR_FRIEND_REJECT: BASE_URL + 'accept_reject/',
  UNIVERSR_FRIEND_ACCEPTED: BASE_URL + 'accept_reject/',
  UNIVERSR_INSTITUTE_INTEREST: BASE_URL + 'institute_interest/',
  UNIVERSR_USER_BLOCK: BASE_URL + 'block_list/',
  UNIVERSR_UNBLOCK: BASE_URL + 'blocked_contacts/',
  UNIVERSR_BLOCK_USER: BASE_URL + 'blocked_contacts/',
  UNIVERSR_SEARCH_FRIEND: BASE_URL + 'search_friend/?keyword=',
  UNIVERSR_FRIEND_REMOVE: BASE_URL + 'delete_friend_request/',
  UNIVERSR_FRIEND_REQUEST: BASE_URL + 'friend_request/',
  UNIVERSR_CONNECTION_PROFILE: BASE_URL + 'user_profile/?pk=',
  UNIVERSR_REPORT_USER: BASE_URL + 'report_user/',
  UNIVERSR_EDIT_PROFILE: BASE_URL + 'user_profile/',

  //Ad
  UNIVERSR_AD_CREATE_PROPERTY: BASE_NEW_URL + 'posts/create/',
  //HOUSING
  UNIVERSR_HOUSING_MANGE_INTERESTED: BASE_URL + "list_interested_property/",
  // UNIVERSR_HOUSING_CREATE_PROPERTY: BASE_URL + 'create_housing_property/',
  UNIVERSR_HOUSING_CREATE_PROPERTY: BASE_NEW_URL + "posts/create/",


  UNIVERSR_HOUSING_MANGE_LIST:
    // BASE_URL + 'list_housing_property/?type_of_listing=Manage',
    BASE_NEW_URL + "posts/view?type=housing",

  UNIVERSR_HOUSING_MANGE_DELET: BASE_URL + "create_housing_property/?pk=",
  UNIVERSR_HOUSING_MANGE_GET:
    // BASE_URL + 'create_housing_property/?pk=',
    BASE_NEW_URL + "property/getOne/",
  UNIVERSR_HOUSING_CREATE_PROPERTY_UPDATE:
    BASE_URL + "create_housing_property/?pk=",
    UNIVERSR_HOUSING_UPDATE:
    // BASE_URL + 'create_housing_property/?pk=',
    BASE_NEW_URL + "property/update/",
  UNIVERSR_HOUSING_MANGE_UPDATE:
    // BASE_URL + 'create_housing_property/?pk=',
    BASE_NEW_URL + "posts/update/",
  UNIVERSR_USER_INTERSTED:
    BASE_URL + "interested_users_housing_property/?housing_property_id=",
  UNIVERSR_HOUSING_ALL_PROPTIE_LIST:
    // BASE_URL + 'list_housing_property/?type_of_listing=AllProperties',
    BASE_NEW_URL + 'posts/view?type=housing',
    UNIVERSR_FILTER_ALL_PROPTIE_LIST:
    // BASE_URL + 'list_housing_property/?type_of_listing=AllProperties',
    BASE_NEW_URL + 'posts/view/',

  UNIVERSR_ADD_USER_INTERSTED: BASE_URL + 'interested_users_housing_property/',
  UNIVERSR_HOUSING_ADD_PROPTIE_LIST:
    // BASE_URL + 'list_housing_property/?type_of_listing=AllProperties',
    BASE_NEW_URL + 'posts/view?type=ads',
  UNIVERSR_HOUSING_ALL_PROPTIE_DETALES:
  BASE_NEW_URL + 'create_housing_property/?pk=',
  UNIVERSR_HOUSING_ALL_PROPTIE_FILTER: BASE_URL + 'filter_housing_property/',
  UNIVERSR_CREATE_FORUM: BASE_URL + 'create_forum/',
  UNIVERSR_ALL_FORUM_LIST: 'https://api.myunivrs.com:3000/api/forum-list/?page=',
  UNIVERSR_ALL_FORUM_TYPE: NODE_URL + 'api/forum-type-list',
  UNIVERSR_HOUSING_CAREER_PROPTIE_LIST:
    BASE_NEW_URL + 'posts/view?type=career',
  UNIVERSR_ALL_Following_TYPE: NODE_URL + 'api/following-type-list',
  UNIVERSR_ALL_FORUM_FILTER: NODE_URL + 'api/forum-filter-list',
  UNIVERSR_ALL_FORUM_LIST_MANAGE : NODE_URL + 'api/view-forums/?page=',
  UNIVERSR_GET_FORUM_EDIT_DATA : NODE_URL + 'api/forum-details/?forum_id=',

  CREATE_FORUM: NODE_URL + "api/create_forum",
  UNIVERSR_DELETE_FORUM_NEW: NODE_URL + "api/delete-forum?forum_id=",
  UNIVERSR_MY_DELETE_FORUM_NEW: NODE_URL + "api/delete-comment",
  UNIVERSR_LIKE_DISLIKE_FORUM: BASE_URL + "like_forum/",
  UNIVERSR_GET_COMMENT_LIST_FORUM:
    NODE_URL + "api/forum-comment-list/?forum_id=",
  UNIVERSR_MAIN_COMMENT_LIST_FORUM: NODE_URL + "api/forum-details?forum_id=",
  UNIVERSR_ADD_COMMENT_ON_FORUM: NODE_URL + "api/search-forum",
  UNIVERSR_FILTER_FORUM: BASE_URL + "search_forum/",
  UNIVERSR_CHANGE_LOCATION: BASE_URL + "change_location/",
  UNIVERSR_CHANGE_LOCATION: BASE_URL + "change_location/",
  UNIVERSR_UPDATE_FORUM: BASE_URL + "create_forum/",
  UNIVERSR_UPDATE_FORUM_DATA: NODE_URL + "api/update_forum",
  UNIVERSR_COMMENT_DELETE: BASE_URL + "comment_forum/?comment_id=",
  LIST_EVENT_BUYERS: BASE_URL + "list_buy_event/",
  JOB_LISTING: BASE_URL + "job_list/",

  //Event
  UNIVERSR_DELETE_FORUM: BASE_URL + "posts/create?type=event",
  UNIVERSR_LIKE_DISLIKE_FORUM: BASE_URL + "like_forum/",
  UNIVERSR_CANCEL_EVENT: BASE_URL + "cancel_events/",
  UNIVERSR_EVENT_ALL_LIST: BASE_NEW_URL + "posts/view?type=event",
  UNIVERSR_EVENT_HOSTING: BASE_URL + "host_events/",
  UNIVERSR_EVENT_LIST_BUY: BASE_URL + "my_events_list/",
  UNIVERSR_EVENT_ALL_LIST_SAVE: BASE_URL + "save_events/",
  EVENT_DETAIL: BASE_NEW_URL + "all_events_by_id/",
  EVENT_GET: BASE_URL + "events_list/",
  UNIVERSR_EVENT_HOSTING_DELET: BASE_URL + "delete_events/?event_id=",
  UNIVERSR_EVENT_SAVE: BASE_URL + "save_events/",
  UNIVERSR_ADD_EVENT: BASE_NEW_URL + "posts/create/",
  UNIVERSR_UPDATE_EVENT: BASE_URL + "save_events/",
  MY_EVENTS: BASE_URL + "my_events_list/",
  EVENTS_IM_INTERESTED: BASE_URL + "interested_events/",
  EVENTS_INTERESTED_USERS: BASE_URL + "interested_events/?event_id=",
  DELETE_SAVE_EVENTS: BASE_URL + "save_events/?pk=",
  JOB_LISTING: BASE_URL + "job_list/",
  JOB_APPLY: BASE_URL + "job_application/",

  ////  Offeres Screen ///

  // DINING_VOUCHERS: BASE_URL + "dining_type/?Offer_Subtype=vouchers",
  // DINING_PRODUCTS: BASE_URL + "dining_type/?Offer_Subtype=products",
  DINING_SEARCH: BASE_URL + "search_dining_product/",
  DINING_LIKE: BASE_URL + "like_dislike_gifts/",
  DINING_DISLIKE: BASE_URL + "like_dislike_gifts/",
  DINING_WISHLIST: BASE_URL + "dining_wishlist/",
  DINING_DELETE: BASE_URL + "delete_dining/?product_id=",
  DINING_DETAILS: BASE_URL + "dining_detail/?id=",

  /// GIFTES
  JEWELLERY_VOUCHER: BASE_URL + "gifts_type/?Offer_Subtype=vouchers",
  JEWELLERY_PRODUCT: BASE_URL + "gifts_type/?Offer_Subtype=products",
  JEWELLERY_PRODUCT_LIKE: BASE_URL + "like_dislike_gifts/",
  JEWELLERY_WISHLIST: BASE_URL + "gifts_wish_list/",
  DELETE_WISHLIST: BASE_URL + "gifts_wish_list/?product_id=",
  JEWELLERY_PRODUCT_DETALIS: BASE_URL + "gifts_detail/?id=",

  /// DIGITAL ////
  DIGITAL_VOUCHER: BASE_URL + "digital_type/?Offer_Subtype=vouchers",
  DIGITAL_PRODUCT: BASE_URL + "digital_type/?Offer_Subtype=products",
  LIKE_DISLIKE_DIGITAL: BASE_URL + "like_dislike_digital/",
  DIGITAL_MYWISHLIST: BASE_URL + "digital_wish_list/",
  DELETE_DIGITAL: BASE_URL + "digital_wish_list/?product_id=",
  DIGITAL_PRODUCT_DETAILS: BASE_URL + "digital_detail/?id=",

  //Sell/Swap
  SELL_SWAP_ALL_LIST:BASE_NEW_URL+'swap-and-sell/view/',
  ITEM_LIST: BASE_URL + "item_list/",
  CREATE_ITEM: BASE_URL + "item_list/",
  SELL_SWAP_CREATE : BASE_NEW_URL + 'swap-and-sell/create/',
  SELL_SWAP_UPDATE : BASE_NEW_URL + 'swap-and-sell/update/',
  UPDATE_ITEM: BASE_URL + "item_list/",
  GET_ITEM_LIST: BASE_URL + "my_items/",
  MANAGE_ITEM: BASE_URL + "my_items/",
  DETAILS_ITEM: BASE_URL + "my_items/",
  DELETE_ITEM: BASE_URL + "my_items/",
  SAVE_ITEM_ALL: BASE_URL + "save_items/",
  SAVE_ITEM: BASE_URL + "save_items/",
  IM_INTERESTED: BASE_URL + "interested_items/",
  INTERESTED_USERS: BASE_URL + "/interested_items/?item_id=",
  UNSAVE_ITEM: BASE_URL + "unsave_items/",
  CATEGORY_LIST: BASE_URL + "values_category_filter/",
  ITEM_FILTER: BASE_URL + "item_filter/",

  ///Offer Seaction /////
  HEALTHVOUCHER_LIST: BASE_URL + "helth_vouchers/?Offer_Subtype=vouchers",
  HEALTHPRODUCT_LIST: BASE_URL + "helth_vouchers/?Offer_Subtype=products",
  HEALTH_SEARCH: BASE_URL + "search_helth_product/?keyword=",

  // HEALTHPRODUCT_SERACH: BASE_URL + "search_helth_product/?keyword=",
  GET_HEALTH_MYWISHLIST: BASE_URL + "wish_list/",
  LIKE_DISLIKE_HEALTH_PRODUCT: BASE_URL + "helth_vouchers/",
  HEALTHPRODUCT_DELETE: BASE_URL + "delete_helth_product/?product_id=",
  HEALTHPRODUCT_DETAILS: BASE_URL + "helth_product_details/?id=",
  ADD_TO_CART_HEAL: BASE_URL + "add_to_cart/",

  /// Fashin///
  FASHIN_VOUCHERS_LIST: BASE_URL + "fashion_type/?Offer_Subtype=vouchers",
  FASHIN_PRODUCT_LIST: BASE_URL + "fashion_type/?Offer_Subtype=products",
  LIKE_FASHION: BASE_URL + "like_dislike_fashion/",
  FASHIN_MYWISHILIST: BASE_URL + "fashion_wish_list/",
  DELET_FASHIN: BASE_URL + "remove_fashion_product/?product_id=",
  FASHIN_DETALS: BASE_URL + "fashion_details_id/?id=",

  /// Electronics ///
  ELECTRONICS_VOUCHERS: BASE_URL + "electronic_type/?Offer_Subtype=vouchers",
  ELECTRONICS_PRODUCT: BASE_URL + "electronic_type/?Offer_Subtype=products",
  LIKE_DISLIKE_ELECTRONIC: BASE_URL + "like_dislike_electronic/",
  ELECTRONICS_MYWISHLIST: BASE_URL + "electronic_wish_list/",
  DELETE_ELECTROINC: BASE_URL + "delete_electronics/?product_id=",
  ELECTRONICS_DETALS: BASE_URL + "electronic_detail/?product_id=",
  ADD_TO_CART_ELECTRONIC: BASE_URL + "add_to_cart/",

  ///Offer Book ///
  OFFER_VOUCHERS: BASE_URL + "office_type/?Offer_Subtype=vouchers",
  OFFER_PRODUCT: BASE_URL + "office_type/?Offer_Subtype=products",
  LIKE_DISLIKE_OFFER: BASE_URL + "like_dislike_office/",
  OFFER_MYWISHILIST: BASE_URL + "office_wish_list/",
  DELETE_OFFER: BASE_URL + "office_wish_list/?product_id=",
  OFFER_PRODUCT_DETAILS: BASE_URL + "office_product_detail/?id=",
  ADD_TO_CART_OFFER: BASE_URL + "add_to_cart/",

  /// Sport ///
  SPORT_VOUCHER: BASE_URL + "sports_type/?Offer_Subtype=vouchers",
  SPORT_VOUCHERS:
    BASE_NEW_URL + "offers-manegment?offerType=sport&category=voucher",
  SPORT_PRODUCTS:
    BASE_NEW_URL + "offers-manegment?offerType=sport&category=product",
  LIKE_DISLIKE_SPORT: BASE_URL + "like_dislike_sports/",
  SPORT_PRODUCT_WISHLIST: BASE_URL + "sports_wish_list/",
  DELETE_SPORT: BASE_URL + "delete_sports/?product_id=",
  SPORT_PRODUCT_DETALIS: BASE_URL + "sports_detail/?id=",
  ADD_TO_CART_SPORT: BASE_URL + "add_to_cart/",

  /// digital ///
  DIGITAL_VOUCHERS:
    BASE_NEW_URL + "offer-digital/view?offerType=digital&category=voucher",
  DIGITAL_PRODUCTS:
    BASE_NEW_URL + "offer-digital/view?offerType=digital&category=product",

  /// dining ///
  DINING_VOUCHERS: BASE_NEW_URL + 'offer-dining/view?offerType=dining&category=voucher',
  DINING_PRODUCTS: BASE_NEW_URL + 'offer-dining/view?offerType=dining&category=product',

  /// electronics ///
  ELECTRONICS_VOUCHERS: BASE_NEW_URL + 'offer-electronics/view?offerType=electronics&category=voucher',
  ELECTRONICS_PRODUCTS: BASE_NEW_URL + 'offer-electronics/view?offerType=electronics&category=product',

  /// fashion ///
  FASHION_VOUCHERS: BASE_NEW_URL + 'offer-fashion/view?offerType=fashion&category=voucher',
  FASHION_PRODUCTS: BASE_NEW_URL + 'offer-fashion/view?offerType=fashion&category=product',

  /// health ///
  HEALTH_VOUCHERS: BASE_NEW_URL + 'offer-health/view?offerType=health&category=voucher',
  HEALTH_PRODUCTS: BASE_NEW_URL + 'offer-health/view?offerType=health&category=product',

  /// jewellery ///
  JEWELLERY_VOUCHERS: BASE_NEW_URL + 'offer-jewellery/view?offerType=jewellery&category=voucher',
  JEWELLERY_PRODUCTS: BASE_NEW_URL + 'offer-jewellery/view?offerType=jewellery&category=product',

  /// office ///
  OFFICE_VOUCHERS: BASE_NEW_URL + 'offer-office/view?offerType=office&category=voucher',
  OFFICE_PRODUCTS: BASE_NEW_URL + 'offer-office/view?offerType=office&category=product',

  // Offer Api //
  ORDER_LIST: BASE_URL + "orders/",
  ADD_TO_CART: BASE_URL + "add_to_cart/",
  BUY_NOW: BASE_URL + "buy_now/",
  OFFER_ADDRES: BASE_URL + "offer_address/",
  ADD_ADDRES: BASE_URL + "offer_address/",
  ADD_ATM: BASE_URL + "add_card_details/",
  ATM_CARD_LISTING: BASE_URL + "add_card_details/",
  PLACE_ORDER: BASE_URL + "place_order/",
  ORDER_DETAILS: BASE_URL + "order_detail/?id=",
  BUY_NOW: BASE_URL + "buy_now/",

  //FilterHealth
  HEALTH_DROPDOWNLIST: BASE_URL + "helth_dropdown_brand/",
  HEALTH_DROPDOWNLIST_PRODUCT: BASE_URL + "helth_dropdown_product/",
  HEALTH_DROPDOWNLIST_CONCERN: BASE_URL + "helth_dropdown_concern/",
  FILTER_HEALTH: BASE_URL + "helth_filter/",
  HEALTH_PROPERTY_LIST_PRODUCT:
    BASE_NEW_URL + "/offers-manegment?offerType=health&category=product",

  //FilterFashion
  FASHION_CATEGORY_DROPDOWNLIST:
    BASE_URL + "fashion_categories_dropdown/?gender=Men",
  FASHION_BRANDS_DROPDOWNLIST: BASE_URL + "fashion_brands_dropdown/?gender=Men",
  FASHION_COLOURS_DROPDOWNLIST:
    BASE_URL + "fashion_colours_dropdown/?gender=Men",
  FASHION_STORE_DROPDOWNLIST: BASE_URL + "fashion_stores_dropdown/?gender=Men",
  FILTER_FASHION: BASE_URL + "fashion_filter/",

  //FilterElectronics
  ELECTRONICS_DROPDOWNLIST_DEPARTMENT:
    BASE_URL + "electronics_departments_dropdown/",
  ELECTRONICS_DROPDOWNLIST_BRAND: BASE_URL + "electronics_brands_dropdown/",
  ELECTRONICS_DROPDOWNLIST_SELLERS: BASE_URL + "electronics_sellers_dropdown/",
  FILTER_ELECTRONICS: BASE_URL + "electronics_filter/",

  //FilterOffice
  OFFICE_DEPARTMENT_DROPDOWNLIST: BASE_URL + "office_departments_dropdown/",
  OFFICE_AUTHORS_DROPDOWNLIST: BASE_URL + "office_authors_dropdown/",
  FILTER_OFFICE: BASE_URL + "office_filter/",

  //FilterSports
  SPORTS_CATEGORIES_DROPDOWNLIST: BASE_URL + "sports_categories_dropdown/",
  SPORTS_BRANDS_DROPDOWNLIST: BASE_URL + "sports_brands_dropdown/",
  FILTER_SPORTS: BASE_URL + "sports_filter/",

  //FilterGifts
  GIFTS_TYPES_DROPDOWNLIST: BASE_URL + "gifts_types_dropdown/",
  GIFTS_BRANDS_DROPDOWNLIST: BASE_URL + "gifts_brands_dropdown/",
  FILTER_GIFTS: BASE_URL + "gifts_filter/",

  //FilterDining
  FILTER_DINING: BASE_URL + "filter_dining/",

  //FilterDigital
  FILTER_DIGITAL: BASE_URL + "filter_digital/",
  /// DELETE CART_ITEM //
  DELETE_CART_ITEM: BASE_URL + "add_to_cart/?product_id=",
  /// Chat ....///

  FRIENDS_LIST_CHAT: NODE_URL + "friends-chat/recent-chats",
  INTERSTED_LIST_CHAT: NODE_URL + "housing-chat/recent-chats",
  SAVE_CHAT_FILE: NODE_URL + "private-chat/upload-file",
  GROUP_SAVE_CHAT_FILE: NODE_URL + "group-chat/upload-file",
  GET_CHAT: BASE_URL + "chat-room-history/",
  FRIENDS_CHAT_HISTERY: NODE_URL + "friends-chat/chat-conversation",
  HoUSING_CHAT_HISTERY: NODE_URL + "housing-chat/chat-conversation",
  CREATE_GROUP_HOUSING: NODE_URL + "group/create-group",
  GROUP_FRIEND_LIST: NODE_URL + "group/friends-list",
  GROUP_LIST: NODE_URL + "group/groups-list",
  GROUP_CHAT_HISTORY: NODE_URL + "group/group-chat",
  GROUP_DETAILS: NODE_URL + "group/group-details?group_id=",
  GROUP_EDIT: NODE_URL + "group/edit-group",
  PAYMENT_API: BASE_URL + "initiate_payment/",

  /* Universal API url End*/
};
export default ApiConfig;

import React from "react";
import { Link } from "react-router-dom";
import call_icon from "../../assets/images/call_icon.svg";
import chatpp from "../../assets/images/chatListAvatar01.png";
import send_icon from "../../assets/images/send_icon.svg";
import micrphone_icon from "../../assets/images/micrphone_icon.svg";
import addImage_file_icon from "../../assets/images/addImage_file_icon.svg";
import chat_bodyContentAvatar from "../../assets/images/chat_bodyContentAvatar.png";
import chatAudio_bubble from "../../assets/images/chatAudio_bubble.svg";
import chatImageBubble from "../../assets/images/chatImageBubble.svg";
import chatTextBubble from "../../assets/images/chatTextBubble.svg";
import gc_img from "../../assets/images/groupCallImg.png";
import speaker_notselected_icon from "../../assets/images/speaker_notselected_icon.svg";
import mute_icon from "../../assets/images/mute_icon.svg";
import endcall_icon from "../../assets/images/endcall_icon.svg";

function ChatBody() {
  return (
    <div className="chat_bodyWrapper">
      <div
        className="group_callingWrapper"
        style={{ boxShadow: "0px 3px 12px #0000000F" }}
      >
        <div className="gc_imgWrapper">
          <img src={gc_img} alt="" />
        </div>
        <h1
          style={{
            font: " normal normal normal 20px/24px SF Pro Text",
            color: "#21212C",
            letterSpacing: "0px",
          }}
        >
          Work Friends 
        </h1>
        <p style={{ color: "lightgrey" }}>Calling...</p>
        <div className="gc_dailup">
          <Link to="/">
            <img src={speaker_notselected_icon} alt="" />
          </Link>
          <Link to="/">
            <img src={mute_icon} alt="" />
          </Link>
          <Link to="/groups">
            <img src={endcall_icon} alt="" />
          </Link>
        </div>
      </div>

      <div className="chat_bodyHeader">
        <div className="chat_bodyAvatar">
          <div className="chat_bodyAvatarInnerHolder">
            <img src={chatpp} alt="" />
          </div>

          <h2>Thomas Muller</h2>
        </div>
        <div className="chat_HeaderControlsWrapper">
          <button>
            <img src={call_icon} alt="" />
          </button>

          <div class="dropdown">
            <button
              class="btn"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="fa-solid fa-ellipsis-vertical"></i>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <Link to="/add_device" class="dropdown-item" href="#">
                  Edit
                </Link>
              </li>

              <li>
                <Link to="/device_details" class="dropdown-item" href="#">
                  View Details
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="chat_contentWrapper">
        <div className="CC_rowWrapper jcLeft">
          <div className="CC_avatarWrapper">
            <img src={chat_bodyContentAvatar} alt="" />
            <h1
              style={{
                // font: "normal normal medium 14px/16px SF Pro Text",
                fontFamily: "Source Sans Pro, sans-serif",
                fontSize: "14px",
                lineHeight: "19px",
                fontStyle: "normal",
                color: "#707070",
              }}
            >
              Monica Sebastian
            </h1>
          </div>
        </div>

        <div className="CC_rowWrapper jcLeft">
          <img src={chatAudio_bubble} alt="" />
        </div>

        <div className="CC_rowWrapper jcRight">
          <img src={chatTextBubble} alt="" />
        </div>
        <div className="chatDateWrapper">
          <h3
            style={{
              font: "normal normal normal 12px/15px SF Pro Tex",
              color: "#C5C4C3",
              letterSpacing: "0px",
            }}
          >
            Today
          </h3>
        </div>

        <div className="CC_rowWrapper jcRight">
          <img src={chatImageBubble} alt="" />
        </div>

        <div className="CC_rowWrapper jcCenter">
          <h4
            style={{
              font: "normal normal normal 14px/19px Nunito",
              color: "#BBBBBB",
              letterSpacing: "0px",
            }}
          >
            James typing...
          </h4>
        </div>
      </div>

      <div className="chat_bodyFooter ">
        <div className="chat_footer_inputWrapper ">
          <input type="text" placeholder="type here" />
        </div>

        {/* here I dont know which bot will be used so I used buttons only, once confirmed I will make changes accordingly */}
        <div className="chat_footerControls">
          <button>
            <img src={addImage_file_icon} alt="" />
          </button>

          <button>
            <img src={micrphone_icon} alt="" />
          </button>

          <button>
            <img className="send-chat-web" src={send_icon} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChatBody;

import React, { useContext, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AppContext } from '../../context/AppContext'
import Sidebar from '../../components/Sidebar'
import Header from '../../components/Header'
import profile_picture from '../../assets/images/profile_picture.jpg'
import createselectAvatar from '../../assets/images/createselectAvatar.png'
import createadd_avatar from '../../assets/images/createadd_avatar.png'
import thumbImg from '../../assets/images/house01.png'
import upload from '../../assets/images/upload_icon.svg'
import swal from 'sweetalert'
import ApiConfig from '../../api/ApiConfig'
import {
  getWithAuthCall,
  multipartPostCall,
  postWithAuthCallWithErrorResponse,
} from '../../api/ApiServices'
import ImageUplodeSell from '../../sharedComponent/ImageUplodeSell'
import LoaderCenter from '../../sharedComponent/LoaderCenter'
import {Modal} from "react-bootstrap";
import Payment from "../payment/Payment";

function CreateSell() {
  const navigate = useNavigate()
  const { sidebar, setSidebar, customerData } = useContext(AppContext)
  const [isEnabled, setIsEnabled] = useState(false);
  const [loading, setLoading] = useState(false)
  const [addSell, setAddSell] = useState({
    type: '',
    category: '',
    title: '',
    description: '',
    price: '',
    lat: customerData.lat,
    long: customerData.long,
    image: [],
    featured_ads: false,
    featured_ads_duration: null,
    featured_ads_cost: null,
  });

    const [featureAdd, setFeatureAdd] = useState({
        featured_ads: false,
        featured_ads_duration: "select",
        featured_ads_cost: "£ 1.98",
    });
  const [errMsg, setErrMsg] = useState({
    title: '',
    type: '',
    category: '',
    // title: "",
    description: '',
    price: '',
    image: '',
  })
  const [sellType, setSellType] = useState([])
  const [showElement, setShowElement] = React.useState(true)
  const [openFeatureAddModel, setOpenFeatureAddModel] = useState(false);
  const [openPaymentAddModel, setOpenPaymentAddModel] = useState(false);

  useEffect(() => {
    setTimeout(function () {
      setShowElement(false)
    }, 5000)
  }, [])

  useEffect(() => {
    getSellTypes()
  }, [])

  const AddCreateSell = (e) => {
    if (
      addSell.title === '' ||
      addSell.category === '' ||
      addSell.description === '' ||
      !addSell.image[0] ||
      addSell.type === '' ||
      addSell.price === ''
    ) {
      if (addSell.title === '') {
        setErrMsg({ ...errMsg, title: 'Please Enter title.' })
        window.scrollTo(0, 0)
        return
      }
      if (addSell.category === '') {
        setErrMsg({ ...errMsg, category: 'Please choose category.' })
        window.scrollTo(0, 0)
        return
      }
      if (addSell.description === '') {
        setErrMsg({ ...errMsg, description: 'Please enter description.' })
        window.scrollTo(0, 0)
        return
      }
      if (!addSell.image[0]) {
        setErrMsg({ ...errMsg, image: 'Please enter image.' })
        window.scrollTo(0, 0)
        return
      }

      if (addSell.type === '') {
        setErrMsg({ ...errMsg, type: 'Please enter type.' })
        window.scrollTo(0, 0)
        return
      }
      if (addSell.price === '') {
        setErrMsg({ ...errMsg, price: 'Please enter price.' })
        window.scrollTo(0, 0)
        return
      }
    } else {
     
      setLoading(true)
      let formData = new FormData()
      formData.append('type', addSell.type)
      formData.append('category', addSell.category)
      formData.append('title', addSell.title)
      formData.append('description', addSell.description)
      formData.append('price', addSell.price)
      formData.append('lat', addSell.lat)
      formData.append('long', addSell.long)
      formData.append('image', addSell.image[0])

      multipartPostCall(ApiConfig.CREATE_ITEM, formData)
        .then((res) => {
          setLoading(false)
          swal(res.detail)
          navigate('/sell_list/all')
        })
        .catch((err) => console.log(err))
      setLoading(false)
    }
  }

  const getSellTypes = () => {
    getWithAuthCall(
      ApiConfig.CATEGORY_LIST,
      // JSON.stringify({ ...customerData })
    ).then((res) => {
      console.log('res', res)
      setSellType(res.filter)
    })
  }
  const removeImage = (index) => {
    let image = addSell.image
    
    image = image.filter((img) => image.indexOf(img) != index)
  
    setAddSell({ ...addSell, image: image })
  }

    const toggleSwitch = () => {
        setOpenFeatureAddModel(true);
        setIsEnabled(!isEnabled);
        setFeatureAdd(prevState => ({...prevState, featured_ads: !prevState.featured_ads}))
    };

    const handleClose = () => {
        setIsEnabled(false);
        setOpenFeatureAddModel(false);
        setOpenPaymentAddModel(false);
        setFeatureAdd(prevState => ({...prevState, featured_ads: false}))
    };

    const onPaymentSuccess = () => {
        setAddSell(prevState => ({...prevState, ...featureAdd}))
        setOpenFeatureAddModel(false)
        swal("Payment Successfully");
    }
    console.log("render-create-sell", {featureAdd, addSell})

  return (
    <div>
      <Sidebar />

      {/* <Header /> */}
      <main
        id="cxMain"
        className={sidebar ? 'cx-main cx-active' : 'cx-main'}
        style={{
          height: 'calc(100vh - 156px)',
        }}
      >
        <section
          className="content-main"
          style={{
            backgroundColor: 'white',
            borderRadius: '24px',
            padding: '20px',
            height: 'calc(100vh - 156px)',
            overflow: 'hidden auto',
          }}
        >
          <p className="fo-heading" style={{ marginBottom: '10px' }}>
            Create an item
          </p>
          <div className="row">
            {loading && <LoaderCenter />}
            <div className="col-lg-6">
              <label htmlFor="">Title</label>
              <input
                value={addSell.title}
                type="text"
                name="text"
                id="text"
                className="ce-input"
                placeholder="Enter title here"
                onChange={(e) => {
                  setErrMsg({ ...errMsg, title: '' })

                  setAddSell({
                    ...addSell,
                    title: e.target.value,
                  })
                }}
              />
              <small
                style={{
                  color: 'red',
                }}
              >
                {errMsg.title}
              </small>
            </div>
            {/* {errMsg.title.length > 0 && (
              <div className="text-danger">
                <span> {errMsg.title}</span>
              </div>
            )} */}
            <div className="col-lg-6">
              <div className="uploadPP_inputTextRow usalabel">
                <label class="form-label">Category</label>
                <div className="upp_selectWrapper ">
                  <select
                    onChange={(e) => {
                      setErrMsg({ ...errMsg, category: '' })

                      setAddSell({
                        ...addSell,
                        category: e.target.value,
                      })
                    }}
                    className="Property-Type-midal form-select  ce-input"
                    aria-label="Default select example"
                    style={{
                      height: '40px',
                      backgroundColor: '#FBF8F8',
                      margin: '0',
                    }}
                  >
                    {sellType &&
                      sellType.length &&
                      sellType.map((sellType, index) => {
                        
                        return (
                          <>
                            {/* <option value="select">Select Category Type</option> */}
                            <option key={'delivery' + index} value={sellType}>
                              {/* {sellType.category.charAt(0).toUpperCase() +
                              sellType.category.slice(1)} */}
                              {sellType}
                            </option>
                          </>
                        )
                      })}
                  </select>
                  <small
                    style={{
                      color: 'red',
                    }}
                  >
                    {errMsg.category}
                  </small>
                  {/* {errMsg.category.length > 0 && (
                    <div className="text-danger">
                      <span> {errMsg.category}</span>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <label htmlFor="">Description</label>
              <textarea
                name="text"
                value={addSell.description}
                id="text"
                cols="30"
                rows="10"
                className="ce-input"
                placeholder="Describe your product"
                onChange={(e) => {
                  setErrMsg({ ...errMsg, description: '' })

                  setAddSell({
                    ...addSell,
                    description: e.target.value,
                  })
                }}
                style={{
                  height: '100px',
                  padding: '10px 20px',
                }}
              />
              <small
                style={{
                  color: 'red',
                }}
              >
                {errMsg.description}
              </small>
            </div>
            {/* {errMsg.description.length > 0 && (
              <div className="text-danger">
                <span> {errMsg.description}</span>
              </div>
            )} */}
          </div>
          <div
            className="row  d-flex align-items-center"
            style={{
              columnGap: '10',
            }}
          >
            <div className="col-12 col-sm-6 col-lg-2 custom-resp ">
              <div
                className=""
                style={{ height: '148px', margin: '0', padding: '0' }}
              >
                <ImageUplodeSell
                  setFile={(files) => {
                    setErrMsg({ ...errMsg, image: '' })

                    setAddSell({
                      ...addSell,
                      image: [...addSell.image, ...files],
                    })
                  }}
                />
                <small
                  style={{
                    color: 'red',
                  }}
                >
                  {errMsg.image}
                </small>
              </div>
            </div>

            {addSell.image.map((img, index) => {
              return (
                <div key={`unique-name-${index}`}  className="col-12 col-sm-6 col-lg-2   mt-3 mt-sm-0 ">
                  <div
                    className="uploaded-image uploaded-reps"
                    style={{
                      maxWidth: '148px',
                    }}
                    key={'img' + index}
                  >
                    <img
                      className="img"
                      src={URL.createObjectURL(img)}
                      alt=""
                      style={{
                        width: '148px',
                        height: '148px',
                      }}
                    />
                    <a
                      style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        padding: '10px',
                        background: 'white',
                        borderRadius: '10px',
                        width: '34px',
                        height: '34px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      href="#"
                      className=""
                    >
                      <i
                        onClick={() => removeImage(index)}
                        class="fa-solid fa-xmark"
                      ></i>
                    </a>
                  </div>
                </div>
              )
            })}

            <div className="col-12 col-sm-6 col-xl-3 ms-3">
              <label htmlFor="name" style={{ marginTop: '20px' }}>
                Type
              </label>
              <select
                onChange={(e) => {
                  setErrMsg({ ...errMsg, type: '' })

                  setAddSell({
                    ...addSell,
                    type: e.target.value,
                  })
                }}
                class="form-select ce-input"
                aria-label="Default select example"
              >
                <option value="select">Select Type</option>
                <option value="Sell">Sell</option>
                <option value="Swap">Swap</option>
              </select>
              <small
                style={{
                  color: 'red',
                }}
              >
                {errMsg.type}
              </small>
              {/* {errMsg.type.length > 0 && (
                <div className="text-danger">
                  <span> {errMsg.type}</span>
                </div>
              )} */}
            </div>

            <div className="col-12 col-sm-6 col-xl-3">
              <label htmlFor="name" style={{ marginTop: '20px' }}>
                Item Price
              </label>
              <div
                className="ips"
                style={{
                  position: 'relative',
                }}
              >
                <input
                  type="text"
                  name="numb"
                  id="text"
                  className="ce-input"
                  placeholder="  Price"
                  onChange={(e) => {
                    setErrMsg({ ...errMsg, price: '' })

                    setAddSell({
                      ...addSell,
                      price: e.target.value,
                    })
                  }}
                  style={{
                    textAlign: 'start',
                  }}
                />
                <span
                  style={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    left: '10px',
                  }}
                >
                  £
                </span>
              </div>
              <small
                style={{
                  color: 'red',
                }}
              >
                {errMsg.price}
              </small>
              {/* {errMsg.price.length > 0 && (
                <div className="text-danger">
                  <span> {errMsg.price}</span>
                </div>
              )} */}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div
                  style={{
                    marginTop: "10px",
                  }}
              >
                <h5 class="modal-title">Feature-Add</h5>

                <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "5px",
                      paddingLeft: "2px",
                      paddingRight: "2px",
                    }}
                >
                  <div
                      style={{
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        marginTop: "5px",
                        width: "100%",
                        // marginLeft: '4px',
                        backgroundColor: "black",
                        padding: "10px",
                      }}
                  >
                    <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          paddingLeft: "4px",
                          marginTop: "4px",
                          marginBottom: "3px",
                          alignItems: "center",
                        }}
                    >
                      <p
                          className="reporttext"
                          style={{ color: "white" }}
                      >
                        Make this a featured post?
                      </p>

                      <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                            transition: "all 200ms ease-in-out",
                            marginRight: "15px",
                          }}
                      >
                        <label
                            style={{
                              position: "relative",
                            }}
                        >
                          <input
                              type="checkbox"
                              className="sr-only"
                              checked={isEnabled}
                              onChange={toggleSwitch}
                          />

                          <div
                              style={{
                                width: "50px",
                                height: "28px",
                                borderRadius: "50px",
                                transition: "all 300ms",
                                backgroundColor: isEnabled
                                    ? "#00B4BD"
                                    : "gray",
                              }}
                          >
                            <div
                                style={{
                                  width: "28px",
                                  height: "28px",
                                  borderRadius: "50%",
                                  transition: "all 300ms",
                                  transform: isEnabled
                                      ? "translateX(28px)"
                                      : "",
                                  backgroundColor: "white",
                                  boxShadow:
                                      "0 4px 6px rgba(0, 0, 0, 0.1)",
                                }}
                            ></div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {openFeatureAddModel && isEnabled ? (
                  <Modal
                      className="custom-dialog"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      show={openFeatureAddModel}
                      onHide={handleClose}
                  >
                    <Modal.Header
                        style={{ padding: "12px", width: "99%" }}
                        closeButton
                    >
                      <Modal.Title>About Featured Post</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {/* Are you sure you want to cancel this Friend Request? */}
                      <div
                          class="modal-body"
                          style={{ marginTop: "-37px" }}
                      >
                        <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "1.25rem",
                              marginTop: "1.25rem",
                            }}
                        >
                          <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                          >
                            <span>Duration</span>

                            <span style={{ fontWeight: "bold" }}>
                                    Boost this post for
                                  </span>

                            <select
                                style={{
                                  width: "150px",
                                  color: "#707070",
                                  height: "50px",
                                  fontSize: "12px",
                                  background: "white",
                                  display: "flex",
                                  alignItems: "center",
                                  borderRadius: "10px",
                                  border: "1px solid #01B3BB",
                                  paddingTop: "14px",
                                  paddingRight: "30px",
                                  paddingBottom: "14px",
                                  paddingLeft: "20px",
                                  fontWeight: "bold",
                                }}
                                value={featureAdd.featured_ads_duration}
                                onChange={(e) =>
                                    setFeatureAdd(prevState => ({...prevState,
                                        featured_ads_duration: e.target.value,}))

                                }
                            >
                              <option value="select">
                                Select Duration
                              </option>

                              <option value="1 day">1 day</option>

                              <option value="1 week">1 Week</option>

                              <option value="2 week">2 Week</option>

                              <option value="3 Week">3 Week</option>

                              <option value="1 Month">1 Month</option>
                            </select>
                          </div>

                          <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                          >
                            <span>Price</span>

                            <input
                                style={{
                                  width: "150px",
                                  color: "#707070",
                                  height: "50px",
                                  fontSize: "12px",
                                  background: "white",
                                  display: "flex",
                                  alignItems: "center",
                                  borderRadius: "10px",
                                  border: "1px solid #01B3BB",
                                  paddingTop: "14px",
                                  paddingRight: "30px",
                                  paddingBottom: "14px",
                                  paddingLeft: "20px",
                                  fontWeight: "bold",
                                }}
                                // placeholder="Feature Add Cost"
                                value={featureAdd.featured_ads_cost}
                                readOnly={true}
                                onChange={(e) =>
                                    setFeatureAdd(prevState => ({...prevState,
                                        featured_ads_cost: e.target.value,}))
                                }
                            />
                          </div>

                          <button
                              style={{
                                marginTop: "8px",
                                width: "100%",
                                height: "67.19px",
                                flexShrink: 0,
                                border: "10px solid transparent",
                                borderRadius: "10px",
                                backgroundColor: "#01B3BB",
                                color: "#FEFCFE",
                                fontFamily: "montserrat",
                                fontWeight: "bold",
                                fontSize: "18px",
                                textTransform: "uppercase",
                              }}
                              onClick={() => {
                                  setOpenPaymentAddModel(true)
                              }}
                          >
                            Proceed Payment
                          </button>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
              ) : (
                  <></>
              )}

              {openPaymentAddModel && (
                  <Modal
                      className="custom-dialog"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      show={openFeatureAddModel}
                      onHide={handleClose}
                  >
                    <Modal.Header
                        style={{ padding: "12px", width: "99%" }}
                        closeButton
                    >
                      <Modal.Title>About Payment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {/* Are you sure you want to cancel this Friend Request? */}
                      <div
                          class="modal-body"
                          style={{ marginTop: "-37px" }}
                      >
                        <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "1.25rem",
                              marginTop: "1.25rem",
                            }}
                        >

                          <Payment onSuccess={onPaymentSuccess} setOpenPaymentAddModel={setOpenPaymentAddModel} />

                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
              )}
            </div>
          </div>
          <div
            className="row"
            style={{ marginTop: '60px', marginBottom: '20px' }}
          >
            <div className="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
              <Link to={'/sell_list/all'}>
                <button className="cancel">Cancel</button>
              </Link>
            </div>
            <div className="col-12 col-sm-6 d-flex align-items-center justify-content-center mt-3 mt-sm-0 justify-content-sm-start  ">
              <button onClick={AddCreateSell} className="create">
                {' '}
                Create Item
              </button>
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}

export default CreateSell

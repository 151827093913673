import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ApiConfig from "../../../api/ApiConfig";
import { AppContext } from "../../../context/AppContext";
import { ShareSocial } from "react-share-social";
import { Modal, Button } from "react-bootstrap";
import {
  deleteWithAuthCall,
  postMultipartWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
  simplePostCall,
  simpleGetCall,
} from "../../../api/ApiServices";
import swal from "sweetalert";

import eventthumbImg01 from "../../../assets/images/house01.png";
import LoaderCenter from "../../../sharedComponent/LoaderCenter";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Slider } from "../../../components/Slider";
import './AllProperty.css'
import ManageSellCardView from './ManageCard'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faBuilding,
  faUsers,
  faBed,
  faBath,
  faMoneyBill,
  faDollarSign,
  faUser,
  faClock ,
  faTrashAlt,
  faHeart
} from "@fortawesome/free-solid-svg-icons";


const AllManageSellSwapProperty = () => {
   const baseUrl = window.location.origin;
  const { keyword } = useContext(AppContext);
  const [dismiss, setDismiss] = useState(false);
  const [ShareData, setShare] = useState("");

  const filterModal = useRef(null);

  // filter api starts
  const [errorMsg, setErrorMsg] = useState({
    title: "",
    property_type: "",
    gender: "",
    term: "",
    availability: "",
    minimum: "",
    maximum: "",
  });
  const { customerData } = useContext(AppContext);
  console.log("customerData", customerData.id);
  const [propertyfilterType, setPropertyFilterType] = useState({
    title: "",
    propertyType: "select",
    gender: "select",
    term: "select",
    availability: "select",
    billsIncluded: "No",
    smokedAllowed: "No",
    minimum: "",
    maximum: "",
  });
  const style = {
    background: "#F5F5F5",
    borderRadius: 3,
    border: 0,
    color: "white",
    padding: "0 0px",
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    height: "200px",
    marginTop: "20px",
    // width: '20px',
  };
  const [showElement, setShowElement] = React.useState(true);
  const currentRoute = useLocation().pathname;
  const [loading, setLoading] = useState(false);
  //const [HouseingList, setHouseingList] = useState([]);
  const [HouseingList, setHouseingList] = useState([]);
  const [HouseingAdsList, setHouseingAdsList] = useState([])
  const [houseingListadd, setHouseingAdd] = useState([]);
  const [HouseingMange, setHouseingMange] = useState([]);
  const [selectedID, setselectedID] = useState("");

 

  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    ShareHandal();
  };
  const navigate = useNavigate();

  useEffect(() => {
    getHouselist();
  }, []);

  useEffect(() => {
    getHouselist();
  }, [keyword]);

  useEffect(() => {
    if (keyword.length > 0) {
      getSearchedProducts(HouseingList);
    } else {
      getHouselist();
    }
  }, [keyword]);
  // useEffect(() => {
  //   getHouselistMange();
  // }, [houseingListadd]);

  // const getHouselist = () => {
  //   setLoading(true);

  //   // simpleGetCallWithErrorResponse(
  //     simpleGetCall(
  //     ApiConfig.UNIVERSR_HOUSING_ALL_PROPTIE_LIST
  //     // JSON.stringify({
  //     //   radius: customerData.radius,
  //     //   lat: customerData.lat,
  //     //   long: customerData.long,
  //     // })
  //   ).then((res) => {
  //     setLoading(false);
  //     console.log("radius", res.housing_properties);
  //     if (keyword.length > 0) {
  //       getSearchedProducts(res.json.housing_properties);
  //     } else {
  //       setHouseingList(res.housing_properties);
  //       setHouseingAdsList(res.housing_properties.filter((e) => e.featured_ads == true))
  //     }
  //   });
  // };

 const getHouselist = async () => {
    setLoading(true);

    await fetch(ApiConfig.SELL_SWAP_ALL_LIST)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setLoading(false);
        console.log("sellswap",data)
        if (keyword.length > 0) {
          getSearchedProducts(data.swapandsell);
        } else {
          
          setHouseingList(data.swapandsell);
          // setHouseingAdsList(data.posts.filter(e => e.featured_ads === true));
        }
      })
      .catch(error => {
        setLoading(false);
        console.error('There was a problem fetching the data:', error);
      });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    // Options for formatting the date as you wish
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true, // Whether to use 12-hour time (true) or 24-hour time (false)
    };
    return date.toLocaleString('en-US', options);
  };

//   const getHouselist =async() => {
//     setLoading(true);

//    await fetch(ApiConfig.UNIVERSR_HOUSING_ALL_PROPTIE_LIST)
//       .then(response => {
//         if (!response.ok) {
//           throw new Error('Network response was not ok');
//         }
//         return response.json();
//       })
//       .then(data => {
//         setLoading(false);
//        console.log("housing_properties", data.posts);
//         // if (keyword.length > 0) {
//         //   getSearchedProducts(data.housing_properties);
//         // } else {
//           setHouseingList(data.posts);
//           console.log("res housing", HouseingList);
//          // setHouseingAdsList(data.housing_properties.filter(e => e.featured_ads == true));
//          //}
//       })
//       .catch(error => {
//         setLoading(false);
//         console.error('There was a problem fetching the data:', error);
//       });
// };


  const Delete = (id) => {
    deleteWithAuthCall(ApiConfig.UNIVERSR_HOUSING_MANGE_DELET + id)
      .then((res) => {
        console.log(res);
        swal(res.detail);
        getHouselist();
        navigate("/sell_list/all");
      })
      .catch((err) => {
        setLoading(false);

        console.log(err);
      });
  };

const getSearchedProducts = (data) => {
    let matches = [];
    matches = data.filter((product) => {
      return product && product.title.toLowerCase().includes(keyword.toLowerCase());
    });
    setHouseingList(matches);
  };

  const AddIntersted = (id) => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.UNIVERSR_ADD_USER_INTERSTED,
      JSON.stringify({ housing_property_id: id })
    )
      .then((res) => {
        setHouseingAdd(res);

        console.log(res);
        swal(res?.json);
        getHouselist();
      })
      .then((data) => {
        swal(data?.json);
        setHouseingAdd(data);
      })
      .catch((err) => {
        setLoading(false);

        console.log(err);
      });
  };
  // ===========> search start =================

  // ================= end ================
  const propertyFilter = (e) => {
    setDismiss(false);

    let formData = new FormData();
    formData.append("id", customerData.id);
    formData.append("type", propertyfilterType.title);
    formData.append("propertyType", propertyfilterType.propertyType);
    formData.append("gender", propertyfilterType.gender);
    formData.append("term", propertyfilterType.term);
    formData.append("availability", propertyfilterType.availability);
    formData.append("bills_included", propertyfilterType.billsIncluded);
    formData.append("smokers_allowed", propertyfilterType.smokedAllowed);
    formData.append("minimum", propertyfilterType.minimum);
    formData.append("maximum", propertyfilterType.maximum);

    if (!(propertyfilterType.type === "")) {
      setErrorMsg({ title: "" });

      if (!(propertyfilterType.property_type === "select")) {
        setErrorMsg({ propertyType: "" });
        if (!(propertyfilterType.gender === "select")) {
          setErrorMsg({ gender: "" });
          if (!(propertyfilterType.term === "select")) {
            setErrorMsg({ term: "" });
            if (!(propertyfilterType.availability === "select")) {
              setErrorMsg({ availability: "" });
              if (!(propertyfilterType.minimum === "")) {
                setErrorMsg({ minimum: "" });
                if (!(propertyfilterType.maximum === "")) {
                  setErrorMsg({ maximum: "" });
                  // =======> api call starts <==========
                  setLoading(true);
                  // filterModal.current.modal("hide");
                  postMultipartWithAuthCallWithErrorResponse(
                    ApiConfig.UNIVERSR_HOUSING_ALL_PROPTIE_FILTER,
                    formData
                  )
                    .then((res) => {
                      setHouseingList(res?.json?.housing_property);
                      setHouseingMange([]);
                      setLoading(false);
                    })
                    .catch((err) => console.log(err));
                  // =======> api call end <==============
                } else {
                  setErrorMsg({
                    maximum: "Please enter  maximum price",
                  });
                }
              } else {
                setErrorMsg({
                  minimum: "Please enter  mininum price",
                });
              }
            } else {
              setErrorMsg({
                availability: "Please enter  availability",
              });
            }
          } else {
            setErrorMsg({
              term: "Please enter term",
            });
          }
        } else {
          setErrorMsg({
            gender: "Please enter  gender",
          });
        }
      } else {
        setErrorMsg({
          propertyType: "Please enter  property type",
        });
      }
    } else {
      setErrorMsg({ type: "Please enter type" });
    }
  };
  const HousingId = (id) => {
    setselectedID(id);
  };
  const ShareHandal = async () => {
    let requestBody = JSON.stringify({
      dynamicLinkInfo: {
        domainUriPrefix: "https://myuniverse.page.link",

        link:
          "https://my-univrs.app.redbytes.in/housing-details/" +
          String(selectedID) +
          "/?user=" +
          String(customerData.id),

        androidInfo: {
          androidPackageName: "com.my_universe",
        },

        iosInfo: {
          iosBundleId: "org.myUniverse",
        },
      },
    });

    await fetch(
      "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBmt92Aby2xlZWOCIHeTutj8ywpF-4reyw",

      {
        method: "POST",

        headers: {
          Accept: "application/json",

          "Content-Type": "application/json",
        },

        body: requestBody,
      }
    )
      .then((response) => response.json())

      .then((data) => {
        console.log("response", data);
        setShare(data.shortLink);
      })

      .catch((err) => {
        // this.setState({isVisible:false})

        console.log("error", err);
      });
  };

  const [showPopup, setShowPopup] = useState(false);

  // Function to toggle popup visibility
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const [clicked,setClicked]=useState(false);

  const handleHeartClick = (event) => {
    setClicked(!clicked)
    event.preventDefault(); // Prevent default action
    event.stopPropagation(); // Stop event propagation
    // Add your logic for what happens when the heart icon is clicked
    console.log('Heart icon clicked');
  };


  return (
    <>
      <div className="row  g-4">
        {loading ? (
          <LoaderCenter/>
        ) : 
        HouseingList && HouseingList.length > 0 ?
         (  
            <div style={{ width: "100%",padding:"20px" }}>
            <div style={{ display: "flex", flexWrap: "wrap",gap:"25px"
            // , justifyContent: "space-between" 

            }}>
              {HouseingList.map((house, index) => (
               <ManageSellCardView key={index} house={house} index={index} />
            
              ))}
            </div>
          </div>
        ) 
        : (
          <p
            style={{
              fontSize: "20px",
              marginTop: "200px",
            }}
            className=" text-center justify-content-center align-items-center"
          >
            Oops! It doesn't look like there are any properties in your area.
            <br /> Why don't you try expanding your search criteria?
          </p>
        )
        }
      </div>

      {/* modal start */}
     
    </>
  );
};

export default AllManageSellSwapProperty;

import React, { useState, useEffect } from 'react';
import './AllEventRegister.css';

const AllEventRegister = ({ show, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    attendees: 1,
    startdate: '',
  });

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    setFormData((prevFormData) => ({ ...prevFormData, startdate: today }));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Data:', formData);
    // You can handle form submission here (e.g., send data to an API)
  };

  if (!show) {
    return null;
  }

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h5>Add Up</h5>
        <form onSubmit={handleSubmit}>
          <div className="form-group" style={{ marginTop: '15px' }}>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="number">Attendees:</label>
            <input
              type="number"
              id="attendees"
              name="attendees"
              value={formData.attendees}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="startdate">Start Date:</label>
            <input
              type="date"
              id="startdate"
              name="startdate"
              value={formData.startdate}
              onChange={handleChange}
              required
            />
          </div>
          <button type="button" onClick={onClose}>
            Close
          </button>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default AllEventRegister;

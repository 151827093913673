import React, { useContext, useState, useEffect, useRef } from 'react'
import { Link, useLocation, NavLink, Outlet } from 'react-router-dom'
import { AppContext } from '../../context/AppContext'
import Sidebar from '../../components/Sidebar'
import Header from '../../components/Header'
import thumbImg from '../../assets/images/house01.png'
import location_icon from '../../assets/images/location_icon.svg'
import gray_location_icon from '../../assets/images/gray_location_icon.svg'
import green_arrow_icon from '../../assets/images/green_arrow_icon.svg'
import fillter_icon from '../../assets/images/fillter_icon.svg'
import Voucher from './Voucher'

import search_icon from '../../assets/images/search_icon.svg'
import Product from './HealthProducts'
import OffersProduct from './HealthProducts'

import Tabs from './Tabs'
function HealthProducts() {
  const { sidebar, setSidebar, setKeyword } = useContext(AppContext)
  const [filter, setFilter] = useState(
    useLocation().pathname === '/electronics/products',
  )
  const [showElement, setShowElement] = React.useState(true)

  const ipRef = useRef(null)

  useEffect(() => {
    setKeyword('')
  }, [])

  useEffect(() => {
    setTimeout(function () {
      setShowElement(false)
    }, 5000)
  })

  const handleSearch = () => {
    setKeyword(ipRef.current.value)
  }

  function refreshPage() {
    setTimeout(() => {
      window.location.reload(false)
    }, 0)
   
  }

  return (
    <>
      <Sidebar />

      {/* <Header /> */}
      <main id="cxMain" className={sidebar ? 'cx-main cx-active' : 'cx-main'}>
        <section
          className="content-main"
          style={{
            backgroundColor: 'white',
            borderRadius: '20px',
            height: 'fit-content',
            minHeight: 'calc(100vh - 156px)',
            padding: '20px',
          }}
        >
          <div
            className="globalPages_tabWrapper"
            style={{
              marginBottom: '20px',
              width: '100%',
              marginRight: '0px',
              marginLeft: '0px',
            }}
          >
            <div
              className="gb_tabs"
              style={{
                minWidth: '852px',
                overflow: 'auto',
              }}
            >
              <div
                className="tabs"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  minWidth: '1024px',
                  overflowX: 'auto',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    columnGap: '20px',
                  }}
                >
                  <div>
                    <NavLink
                      onClick={() => setFilter(false)}
                      to={`/health/voucher`}
                      className={({ isActive }) =>
                        isActive ? 'nav-active' : 'nav-inactive'
                      }
                      style={{
                        width: '130px',
                      }}
                    >
                      Voucher
                    </NavLink>
                  </div>
                  <div>
                    <NavLink
                      onClick={() => setFilter(true)}
                      to={`/health/products`}
                      className={({ isActive }) =>
                        isActive ? 'nav-active' : 'nav-inactive'
                      }
                      style={{
                        width: '130px',
                      }}
                    >
                      Product
                    </NavLink>
                  </div>
                </div>

                {filter ? (
                  <button className="filter-ic-btn border-0">
                    <Link
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      to="#"
                      style={{
                        border: 'none',
                        margin: '0',
                        padding: '0px 0px',
                        zIndex: '1061',
                        marginRight:
                          '                                                                                                                                                                                                                                                               m,mm,mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,llllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllll,l,l,,,ll                             ,l  mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,l,llllllllllllllllllllllllllllllllllmmmmmmm,lllllllllllllllllllllllllllllll                                                                                                                                                                                                                                                                                                                                                                                                                                                  0px',
                      }}
                    >
                      <img src={fillter_icon} alt="" />
                    </Link>
                  </button>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    columnGap: '20px',
                  }}
                >
                  <div
                    className="search-input"
                    style={{
                      width: '224px',
                    }}
                  >
                    <input
                      ref={ipRef}
                      type="text"
                      // value={keyword}
                      name="text"
                      id="text"
                      placeholder="Search health"
                      style={{
                        width: '100%',
                      }}
                      // onChange={(e)=>setKeyword(e.target.value)}
                    />
                    <img
                      src={search_icon}
                      alt="search"
                      onClick={() => handleSearch()}
                    />
                  </div>
                  <div>
                    <NavLink
                      onClick={() => {
                        setFilter(false); refreshPage()
                      }}
                      to="/health/my-cart"
                      // onClick={refreshPage}
                      className={({ isActive }) =>
                        isActive ? 'nav-active' : 'nav-inactive'
                      }
                      style={{
                        width: '130px',
                      }}
                    >
                      My Cart
                    </NavLink>
                  </div>
                  <div>
                    <NavLink
                      onClick={() => setFilter(false)}
                      to="/health/orders"
                      className={({ isActive }) =>
                        isActive ? 'nav-active' : 'nav-inactive'
                      }
                      style={{
                        width: '130px',
                      }}
                    >
                      My Orders
                    </NavLink>
                  </div>
                  <div>
                    <NavLink
                      onClick={() => setFilter(false)}
                      to="/health/wishlist"
                      className={({ isActive }) =>
                        isActive ? 'nav-active' : 'nav-inactive'
                      }
                      style={{
                        width: '130px',
                      }}
                    >
                      My Wishlist
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Outlet />
        </section>
      </main>
    </>
  )
}

export default HealthProducts

{
  /* <div class="sitemainBGwrapper">
<Sidebar />

<Header />
<main id="cxMain" className={sidebar ? "cx-main cx-active" : "cx-main"}>
  <section className="content-main">
    <div className="row">
      <div className="col-md-12">
        <div className="gblThumb_outerWrapper">
          <div className="row">
            <div className="col-md-4">
              <div className="gbl_thumbWrapper">
                <div className="img_thumbWrapper">
                  <img src={thumbImg} height="158px" alt="" />
                </div>

                <div className="gbl_thumbContent">
                  <h1
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "hsla(240, 12%, 13%, 1)",
                      marginTop: "5px",
                      letterSpacing: "0",
                      font: "normal normal 600 16px/22px Nunito",
                      opacity: "0px",
                    }}
                  >
                    Wrist Watch
                  </h1>
                  <p
                    style={{
                      color: "var(--unnamed-color-707070)",
                      font: "normal normal normal 14px/19px Nunito",
                      letterSpacing: "0px",
                      color: "hsla(0, 0%, 44%, 1)",
                      opacity: "1px",
                      marginTop: "12px",
                    }}
                  >
                    1,999/-
                  </p>
                  <img
                    style={{
                      marginTop: "-25px",
                      float: "right",
                    }}
                    src={back}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="gbl_thumbWrapper">
                <div className="img_thumbWrapper">
                  <img src={thumbImg} height="158px" alt="" />
                </div>

                <div className="gbl_thumbContent">
                  <h1
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "hsla(240, 12%, 13%, 1)",
                      marginTop: "5px",
                      letterSpacing: "0",
                      font: "normal normal 600 16px/22px Nunito",
                      opacity: "0px",
                    }}
                  >
                    Camera
                  </h1>
                  <p
                    style={{
                      color: "var(--unnamed-color-707070)",
                      font: "normal normal normal 14px/19px Nunito",
                      letterSpacing: "0px",
                      color: "hsla(0, 0%, 44%, 1)",
                      opacity: "1px",
                      marginTop: "12px",
                    }}
                  >
                    1,999/-
                  </p>
                  <img
                    style={{
                      marginTop: "-25px",
                      float: "right",
                    }}
                    src={back}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="gbl_thumbWrapper">
                <div className="img_thumbWrapper">
                  <img src={thumbImg} height="158px" alt="" />
                </div>

                <div className="gbl_thumbContent">
                  <h1
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "hsla(240, 12%, 13%, 1)",
                      marginTop: "5px",
                      letterSpacing: "0",
                      font: "normal normal 600 16px/22px Nunito",
                      opacity: "0px",
                    }}
                  >
                    Cooling Glass
                  </h1>
                  <p
                    style={{
                      color: "var(--unnamed-color-707070)",
                      font: "normal normal normal 14px/19px Nunito",
                      letterSpacing: "0px",
                      color: "hsla(0, 0%, 44%, 1)",
                      opacity: "1px",
                      marginTop: "12px",
                    }}
                  >
                    1,999/-
                  </p>
                  <img
                    style={{
                      marginTop: "-25px",
                      float: "right",
                    }}
                    src={back}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="gbl_thumbWrapper">
                <div className="img_thumbWrapper">
                  <img src={thumbImg} height="158px" alt="" />
                </div>

                <div className="gbl_thumbContent">
                  <h1
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "hsla(240, 12%, 13%, 1)",
                      marginTop: "5px",
                      letterSpacing: "0",
                      font: "normal normal 600 16px/22px Nunito",
                      opacity: "0px",
                    }}
                  >
                    Smart Watch
                  </h1>
                  <p
                    style={{
                      color: "var(--unnamed-color-707070)",
                      font: "normal normal normal 14px/19px Nunito",
                      letterSpacing: "0px",
                      color: "hsla(0, 0%, 44%, 1)",
                      opacity: "1px",
                      marginTop: "12px",
                    }}
                  >
                    1,999/-
                  </p>
                  <img
                    style={{
                      marginTop: "-25px",
                      float: "right",
                    }}
                    src={back}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="gbl_thumbWrapper">
                <div className="img_thumbWrapper">
                  <img src={thumbImg} height="158px" alt="" />
                </div>

                <div className="gbl_thumbContent">
                  <h1
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "hsla(240, 12%, 13%, 1)",
                      marginTop: "5px",
                      letterSpacing: "0",
                      font: "normal normal 600 16px/22px Nunito",
                      opacity: "0px",
                    }}
                  >
                    Smart Watch
                  </h1>
                  <p
                    style={{
                      color: "var(--unnamed-color-707070)",
                      font: "normal normal normal 14px/19px Nunito",
                      letterSpacing: "0px",
                      color: "hsla(0, 0%, 44%, 1)",
                      opacity: "1px",
                      marginTop: "12px",
                    }}
                  >
                    1,999/-
                  </p>
                  <img
                    style={{
                      marginTop: "-25px",
                      float: "right",
                    }}
                    src={back}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="gbl_thumbWrapper">
                <div className="img_thumbWrapper">
                  <img src={thumbImg} height="158px" alt="" />
                </div>

                <div className="gbl_thumbContent">
                  <h1
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "hsla(240, 12%, 13%, 1)",
                      marginTop: "5px",
                      letterSpacing: "0",
                      font: "normal normal 600 16px/22px Nunito",
                      opacity: "0px",
                    }}
                  >
                    X box Controller
                  </h1>
                  <p
                    style={{
                      color: "var(--unnamed-color-707070)",
                      font: "normal normal normal 14px/19px Nunito",
                      letterSpacing: "0px",
                      color: "hsla(0, 0%, 44%, 1)",
                      opacity: "1px",
                      marginTop: "12px",
                    }}
                  >
                    1,999/-
                  </p>
                  <img
                    style={{
                      marginTop: "-25px",
                      float: "right",
                    }}
                    src={back}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

<div className="gray_modalWrapper">
  <div
    class="modal fade"
    id="exampleModal2"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Interested Users
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div className="grayfriendsListWrapper">
            <ul>
              <li>
                <div className="fl_itemInner_Wrapper">
                  <div className="fli_leftWrapper">
                    <img src={chatListAvatar01} alt="" />
                    <h1>george96</h1>
                  </div>

                  <Link to="#">
                    <img src={chat_icon_sidebar} alt="" />
                  </Link>
                </div>
              </li>

              <li>
                <div className="fl_itemInner_Wrapper">
                  <div className="fli_leftWrapper">
                    <img src={chatListAvatar01} alt="" />
                    <h1>george96</h1>
                  </div>

                  <Link to="#">
                    <img src={chat_icon_sidebar} alt="" />
                  </Link>
                </div>
              </li>

              <li>
                <div className="fl_itemInner_Wrapper">
                  <div className="fli_leftWrapper">
                    <img src={chatListAvatar01} alt="" />
                    <h1>george96</h1>
                  </div>

                  <Link to="#">
                    <img src={chat_icon_sidebar} alt="" />
                  </Link>
                </div>
              </li>

              <li>
                <div className="fl_itemInner_Wrapper">
                  <div className="fli_leftWrapper">
                    <img src={chatListAvatar01} alt="" />
                    <h1>george96</h1>
                  </div>

                  <Link to="#">
                    <img src={chat_icon_sidebar} alt="" />
                  </Link>
                </div>
              </li>

              <li>
                <div className="fl_itemInner_Wrapper">
                  <div className="fli_leftWrapper">
                    <img src={chatListAvatar01} alt="" />
                    <h1>george96</h1>
                  </div>

                  <Link to="#">
                    <img src={chat_icon_sidebar} alt="" />
                  </Link>
                </div>
              </li>

              <li>
                <div className="fl_itemInner_Wrapper">
                  <div className="fli_leftWrapper">
                    <img src={chatListAvatar01} alt="" />
                    <h1>george96</h1>
                  </div>

                  <Link to="#">
                    <img src={chat_icon_sidebar} alt="" />
                  </Link>
                </div>
              </li>

              <li>
                <div className="fl_itemInner_Wrapper">
                  <div className="fli_leftWrapper">
                    <img src={chatListAvatar01} alt="" />
                    <h1>george96</h1>
                  </div>

                  <Link to="#">
                    <img src={chat_icon_sidebar} alt="" />
                  </Link>
                </div>
              </li>

              <li>
                <div className="fl_itemInner_Wrapper">
                  <div className="fli_leftWrapper">
                    <img src={chatListAvatar01} alt="" />
                    <h1>george96dd</h1>
                  </div>

                  <Link to="#">
                    <img src={chat_icon_sidebar} alt="" />
                  </Link>
                </div>
              </li>

              <li>
                <div className="fl_itemInner_Wrapper">
                  <div className="fli_leftWrapper">
                    <img src={chatListAvatar01} alt="" />
                    <h1>george96dd</h1>
                  </div>

                  <Link to="#">
                    <img src={chat_icon_sidebar} alt="" />
                  </Link>
                </div>
              </li>

              <li>
                <div className="fl_itemInner_Wrapper">
                  <div className="fli_leftWrapper">
                    <img src={chatListAvatar01} alt="" />
                    <h1>george96dd</h1>
                  </div>

                  <Link to="#">
                    <img src={chat_icon_sidebar} alt="" />
                  </Link>
                </div>
              </li>

              <li>
                <div className="fl_itemInner_Wrapper">
                  <div className="fli_leftWrapper">
                    <img src={chatListAvatar01} alt="" />
                    <h1>george96dd</h1>
                  </div>

                  <Link to="#">
                    <img src={chat_icon_sidebar} alt="" />
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div> */
}

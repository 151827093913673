import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ApiConfig from "../../../../api/ApiConfig";
import { AppContext } from "../../../../context/AppContext";
import { ShareSocial } from "react-share-social";
import { Modal, Button } from "react-bootstrap";
import {
  deleteWithAuthCall,
  postMultipartWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
  simplePostCall,
  simpleGetCall,
} from "../../../../api/ApiServices";
import swal from "sweetalert";

import eventthumbImg01 from "../../../../assets/images/house01.png";
import LoaderCenter from "../../../../sharedComponent/LoaderCenter";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Slider } from "../../../../components/Slider";
import './OfferCardList.css'
import CardView from "./CardView";

const DigitalVoucherCard = () => {
  const { keyword } = useContext(AppContext);
  const [dismiss, setDismiss] = useState(false);
  const [ShareData, setShare] = useState("");

  const filterModal = useRef(null);

  const [errorMsg, setErrorMsg] = useState({
    title: "",
    property_type: "",
    gender: "",
    term: "",
    availability: "",
    minimum: "",
    maximum: "",
  });
  const { customerData } = useContext(AppContext);
  console.log("customerData", customerData.id);
  const [propertyfilterType, setPropertyFilterType] = useState({
    title: "",
    propertyType: "select",
    gender: "select",
    term: "select",
    availability: "select",
    billsIncluded: "No",
    smokedAllowed: "No",
    minimum: "",
    maximum: "",
  });
  const style = {
    background: "#F5F5F5",
    borderRadius: 3,
    border: 0,
    color: "white",
    padding: "0 0px",
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    height: "200px",
    marginTop: "20px",
    // width: '20px',
  };
  const [showElement, setShowElement] = React.useState(true);
  const currentRoute = useLocation().pathname;
  const [loading, setLoading] = useState(false);
  //const [HouseingList, setHouseingList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [HouseingAdsList, setHouseingAdsList] = useState([])
  const [houseingListadd, setHouseingAdd] = useState([]);
  const [HouseingMange, setHouseingMange] = useState([]);
  const [selectedID, setselectedID] = useState("");

  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    ShareHandal();
  };
  const navigate = useNavigate();

  useEffect(() => {
    getHouselist();
  }, []);
  useEffect(() => {
    getHouselist();
  }, [keyword]);
  // useEffect(() => {
  //   getHouselistMange();
  // }, [houseingListadd]);

  // const getHouselist = () => {
  //   setLoading(true);

  //   // simpleGetCallWithErrorResponse(
  //     simpleGetCall(
  //     ApiConfig.UNIVERSR_HOUSING_ALL_PROPTIE_LIST
  //     // JSON.stringify({
  //     //   radius: customerData.radius,
  //     //   lat: customerData.lat,
  //     //   long: customerData.long,
  //     // })
  //   ).then((res) => {
  //     setLoading(false);
  //     console.log("radius", res.housing_properties);
  //     if (keyword.length > 0) {
  //       getSearchedProducts(res.json.housing_properties);
  //     } else {
  //       setHouseingList(res.housing_properties);
  //       setHouseingAdsList(res.housing_properties.filter((e) => e.featured_ads == true))
  //     }
  //   });
  // };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    // Options for formatting the date as you wish
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true, // Whether to use 12-hour time (true) or 24-hour time (false)
    };
    return date.toLocaleString('en-US', options);
  };

  const getHouselist =async() => {
    setLoading(true);

   await fetch(ApiConfig.DIGITAL_VOUCHERS)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setLoading(false);
       console.log("voucher offer", data.offerdigital);
       if (keyword.length > 0) {
        getSearchedProducts(data.offerdigital);
      } else {
        setProductList(data.offerdigital);
      }
         // setHouseingAdsList(data.housing_properties.filter(e => e.featured_ads == true));
         //}
      })
      .catch(error => {
        setLoading(false);
        console.error('There was a problem fetching the data:', error);
      });
};


  const Delete = (id) => {
    deleteWithAuthCall(ApiConfig.UNIVERSR_HOUSING_MANGE_DELET + id)
      .then((res) => {
        console.log(res);
        swal(res.detail);
        getHouselist();
        navigate("/house_listing/all");
      })
      .catch((err) => {
        setLoading(false);

        console.log(err);
      });
  };

  const getSearchedProducts = (data) => {
    let matches = [];
    matches = data.filter((product, index) => {
      if (
        product &&
        product.voucher.voucherName.toLowerCase().includes(keyword.toLowerCase())
      )
        return product;
    });

    setProductList(matches);
  };

  const AddIntersted = (id) => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.UNIVERSR_ADD_USER_INTERSTED,
      JSON.stringify({ housing_property_id: id })
    )
      .then((res) => {
        setHouseingAdd(res);

        console.log(res);
        swal(res?.json);
        getHouselist();
      })
      .then((data) => {
        swal(data?.json);
        setHouseingAdd(data);
      })
      .catch((err) => {
        setLoading(false);

        console.log(err);
      });
  };
  // ===========> search start =================

  // ================= end ================
  const propertyFilter = (e) => {
    setDismiss(false);

    let formData = new FormData();
    formData.append("id", customerData.id);
    formData.append("type", propertyfilterType.title);
    formData.append("propertyType", propertyfilterType.propertyType);
    formData.append("gender", propertyfilterType.gender);
    formData.append("term", propertyfilterType.term);
    formData.append("availability", propertyfilterType.availability);
    formData.append("bills_included", propertyfilterType.billsIncluded);
    formData.append("smokers_allowed", propertyfilterType.smokedAllowed);
    formData.append("minimum", propertyfilterType.minimum);
    formData.append("maximum", propertyfilterType.maximum);

    if (!(propertyfilterType.type === "")) {
      setErrorMsg({ title: "" });

      if (!(propertyfilterType.property_type === "select")) {
        setErrorMsg({ propertyType: "" });
        if (!(propertyfilterType.gender === "select")) {
          setErrorMsg({ gender: "" });
          if (!(propertyfilterType.term === "select")) {
            setErrorMsg({ term: "" });
            if (!(propertyfilterType.availability === "select")) {
              setErrorMsg({ availability: "" });
              if (!(propertyfilterType.minimum === "")) {
                setErrorMsg({ minimum: "" });
                if (!(propertyfilterType.maximum === "")) {
                  setErrorMsg({ maximum: "" });
                  // =======> api call starts <==========
                  setLoading(true);
                  // filterModal.current.modal("hide");
                  postMultipartWithAuthCallWithErrorResponse(
                    ApiConfig.UNIVERSR_HOUSING_ALL_PROPTIE_FILTER,
                    formData
                  )
                    .then((res) => {
                      setProductList(res?.json?.housing_property);
                      setHouseingMange([]);
                      setLoading(false);
                    })
                    .catch((err) => console.log(err));
                  // =======> api call end <==============
                } else {
                  setErrorMsg({
                    maximum: "Please enter  maximum price",
                  });
                }
              } else {
                setErrorMsg({
                  minimum: "Please enter  mininum price",
                });
              }
            } else {
              setErrorMsg({
                availability: "Please enter  availability",
              });
            }
          } else {
            setErrorMsg({
              term: "Please enter term",
            });
          }
        } else {
          setErrorMsg({
            gender: "Please enter  gender",
          });
        }
      } else {
        setErrorMsg({
          propertyType: "Please enter  property type",
        });
      }
    } else {
      setErrorMsg({ type: "Please enter type" });
    }
  };
  const HousingId = (id) => {
    setselectedID(id);
  };
  const ShareHandal = async () => {
    let requestBody = JSON.stringify({
      dynamicLinkInfo: {
        domainUriPrefix: "https://myuniverse.page.link",

        link:
          "https://my-univrs.app.redbytes.in/housing-details/" +
          String(selectedID) +
          "/?user=" +
          String(customerData.id),

        androidInfo: {
          androidPackageName: "com.my_universe",
        },

        iosInfo: {
          iosBundleId: "org.myUniverse",
        },
      },
    });

    await fetch(
      "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBmt92Aby2xlZWOCIHeTutj8ywpF-4reyw",

      {
        method: "POST",

        headers: {
          Accept: "application/json",

          "Content-Type": "application/json",
        },

        body: requestBody,
      }
    )
      .then((response) => response.json())

      .then((data) => {
        console.log("response", data);
        setShare(data.shortLink);
      })

      .catch((err) => {
        // this.setState({isVisible:false})

        console.log("error", err);
      });
  };

  const [showPopup, setShowPopup] = useState(false);

  // Function to toggle popup visibility
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <>
      <div className="row  g-4">
        {loading ? (
          <LoaderCenter/>
        ) : 
        productList && productList.length >0  ?
         (  
            <div style={{ width: "100%",padding:"20px" }}>
            <div style={{ display: "flex", flexWrap: "wrap", gap:"25px" }}>
            {/* <Link to={`/housing-manage/${house._id}`}>Details</Link>
              const { id } = useParams();
            */}
              {productList.map((house, index) => (
                                             <CardView key={index} house={house} index={index} />

            
              ))}
            </div>
          </div>
        ) 
        : (
          <p
            style={{
              fontSize: "20px",
              marginTop: "200px",
            }}
            className=" text-center justify-content-center align-items-center"
          >
            Oops! It doesn't look like there are any properties in your area.
            <br /> Why don't you try expanding your search criteria?
          </p>
        )
        }
      </div>

      {/* modal start */}
      <div
        // show={false}
        ref={filterModal}
        class="modal fade "
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered  "
          style={{
            width: "80%",
            maxWidth: "656px",
          }}
        >
          <div
            class="modal-content "
            style={{
              padding: "20px",
              borderRadius: "20px",
            }}
          >
            <div
              class="modal-header p-0"
              style={{ borderBottom: "none", marginBottom: "-25px" }}
            >
              <p
                class="modal-title"
                id="exampleModalLabel"
                style={{
                  color: "black",
                  fontSize: "20px",
                  fontFamily: "Nunito",
                  color: "#1D1D25",
                  fontWeight: "bold",
                }}
              >
                Sort Housing by
              </p>
            </div>
            <div class="modal-body p-0 mt-4">
              <div class="col-md-12 ">
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <div className="col-lg-12" style={{ marginTop: "15px" }}>
                        <div className="uploadPP_inputTextRow usalabel">
                          <label
                            style={{
                              fontSize: "14px",
                              fontFamily: "Nunito",
                              color: "#707070",
                              // fontWeight: "bold",
                            }}
                            class="form-label"
                          >
                            {" "}
                            Post Type
                          </label>
                          <div className="upp_selectWrapper">
                            <select
                              value={propertyfilterType.postType}
                              onChange={(e) => {
                                setPropertyFilterType({
                                  ...propertyfilterType,
                                  postType: e.target.value,
                                });
                              }}
                              style={{
                                backgroundColor: "#ffffff",
                                width: "100%",
                                height: "50px",
                                color: "#1D1D25",
                                fontSize: "16px",
                                fontWeight: "600",
                              }}
                              class="form-select upp_selectWrapper-form-select"
                              aria-label="Default select example"
                            >
                              <option value="select">Select Type</option>
                              <option value="individual">Individual</option>
                              <option value="room share">Room share</option>
                            </select>
                            <small
                              style={{
                                color: "red",
                              }}
                            >
                              {errorMsg.type}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12" style={{ marginTop: "15px" }}>
                        <div className="uploadPP_inputTextRow usalabel">
                          <label
                            style={{
                              fontSize: "14px",
                              fontFamily: "Nunito",
                              color: "#707070",
                              // fontWeight: "bold",
                            }}
                            class="form-label"
                          >
                            Gender
                          </label>
                          <div className="upp_selectWrapper">
                            <select
                              onChange={(e) => {
                                setPropertyFilterType({
                                  ...propertyfilterType,
                                  gender: e.target.value,
                                });
                              }}
                              style={{
                                backgroundColor: "#ffffff",
                                height: "50px",
                                color: "#1D1D25",
                                fontSize: "16px",
                                fontWeight: "600",
                              }}
                              class="form-select upp_selectWrapper-form-select"
                              aria-label="Default select example"
                            >
                              <option value="select">Select Gender</option>
                              <option value="male only">Male only</option>
                              <option value="Female only">Female only</option>
                              <option value="Mixed">Mixed</option>
                              <option value="LGBTQ+">LGBTQ+</option>
                            </select>
                            <small
                              style={{
                                color: "red",
                              }}
                            >
                              {errorMsg.gender}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12" style={{ marginTop: "15px" }}>
                        <div className="uploadPP_inputTextRow usalabel">
                          <label
                            style={{
                              fontSize: "14px",
                              fontFamily: "Nunito",
                              color: "#707070",
                              // fontWeight: "bold",
                            }}
                            class="form-label"
                          >
                            Availabilty
                          </label>
                          <div className="upp_selectWrapper">
                            <select
                              onChange={(e) => {
                                setPropertyFilterType({
                                  ...propertyfilterType,
                                  availability: e.target.value,
                                });
                              }}
                              style={{
                                backgroundColor: "#ffffff",
                                height: "50px",
                                color: "#1D1D25",
                                fontSize: "16px",
                                fontWeight: "600",
                              }}
                              class="form-select upp_selectWrapper-form-select"
                              aria-label="Default select example"
                            >
                              <option value="select">Select Availabilty</option>
                              <option value="Immediately">Immediately</option>

                              <option value="Within 1 week">
                                Within 1 week
                              </option>
                              <option value="Within 1 months">
                                Within 1 months
                              </option>
                              <option value="Within 3 months">
                                Within 3 months
                              </option>
                            </select>
                            <small
                              style={{
                                color: "red",
                              }}
                            >
                              {errorMsg.availability}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div className="col-lg-12" style={{ marginTop: "15px" }}>
                        <div className="uploadPP_inputTextRow usalabel">
                          <label
                            style={{
                              fontSize: "14px",
                              fontFamily: "Nunito",
                              color: "#707070",
                              // fontWeight: "bold",
                            }}
                            class="form-label"
                          >
                            Property Type
                          </label>
                          <div className="upp_selectWrapper">
                            <select
                              onChange={(e) => {
                                setPropertyFilterType({
                                  ...propertyfilterType,
                                  propertyType: e.target.value,
                                });
                              }}
                              style={{
                                backgroundColor: "#ffffff",
                                height: "50px",
                                color: "#1D1D25",
                                fontSize: "16px",
                                fontWeight: "600",
                              }}
                              class="form-select upp_selectWrapper-form-select"
                              aria-label="Default select example"
                            >
                              <option value="select">
                                Select Property Type
                              </option>
                              <option value="flat">Flat</option>
                              <option value="house">House</option>
                              <option value="studio">Studio</option>
                            </select>
                            <small
                              style={{
                                color: "red",
                              }}
                            >
                              {errorMsg.propertyType}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12" style={{ marginTop: "15px" }}>
                        <div className="uploadPP_inputTextRow usalabel">
                          <label
                            style={{
                              fontSize: "14px",
                              fontFamily: "Nunito",
                              color: "#707070",
                              // fontWeight: "bold",
                            }}
                            class="form-label"
                          >
                            Term
                          </label>
                          <div className="upp_selectWrapper">
                            <select
                              onChange={(e) => {
                                setPropertyFilterType({
                                  ...propertyfilterType,
                                  term: e.target.value,
                                });
                              }}
                              style={{
                                backgroundColor: "#ffffff",
                                height: "50px",
                                color: "#1D1D25",
                                fontSize: "16px",
                                fontWeight: "600",
                              }}
                              class="form-select upp_selectWrapper-form-select"
                              aria-label="Default select example"
                            >
                              <option value="select">Select Term</option>
                              <option value="Short-term (6 months or less)">
                                Short-term (6 months or less)
                              </option>
                              <option value="Long-term (over 6 months)">
                                Long-term (over 6 months)
                              </option>
                            </select>
                            <small
                              style={{
                                color: "red",
                              }}
                            >
                              {errorMsg.term}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div
                          className="uploadPP_inputTextRow usalabel"
                          style={{ marginTop: "15px" }}
                        >
                          <label
                            style={{
                              fontSize: "14px",
                              fontFamily: "Nunito",
                              color: "#707070",
                              // fontWeight: "bold",
                            }}
                            class="form-label"
                          >
                            Budget
                          </label>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <div>
                              <input
                                type="text"
                                placeholder="Minimum"
                                class="col-md-12"
                                onChange={(e) => {
                                  setPropertyFilterType({
                                    ...propertyfilterType,
                                    minimum: e.target.value,
                                  });
                                }}
                                style={{
                                  padding: "10px",
                                  fontSize: "12px",
                                  backgroundColor: "#ffffff",
                                  border: "none",
                                  borderRadius: "8px",
                                  border: "1px solid #E1E1E1",
                                  width: "86px",
                                  textAlign: "center",
                                  height: "50px",
                                  color: "#1D1D25",
                                  fontWeight: "600",
                                }}
                              />
                            </div>
                            <br />

                            <div>
                              <p
                                style={{
                                  alignItems: "center",
                                  marginTop: "7px",
                                  padding: "0 10px",
                                }}
                              >
                                to
                              </p>
                            </div>
                            <div
                              // className="upp_selectWrapper"
                              style={{ backgroundColor: "white" }}
                            >
                              <input
                                type="text"
                                onChange={(e) => {
                                  setPropertyFilterType({
                                    ...propertyfilterType,
                                    maximum: e.target.value,
                                  });
                                }}
                                placeholder="Maximum"
                                class="col-md-12"
                                style={{
                                  padding: "10px",
                                  fontSize: "12px",
                                  backgroundColor: "#ffffff",
                                  border: "none",
                                  borderRadius: "8px",
                                  border: "1px solid #E1E1E1",
                                  width: "86px",
                                  textAlign: "center",
                                  height: "50px",
                                  color: "#1D1D25",
                                  fontWeight: "600",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <small
                          style={{
                            color: "red",
                          }}
                        >
                          {errorMsg.minimum}
                        </small>
                        <small
                          style={{
                            color: "red",
                          }}
                        >
                          {errorMsg.maximum}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <label
                style={{
                  fontSize: "14px",
                  fontFamily: "Nunito",
                  color: "#707070",
                  // fontWeight: "bold",
                  marginTop: "20px",
                }}
                className="form-label"
              >
                Term
              </label>
              <div className="row ">
                <div className="col-12 col-md-4">
                  <div className="house-radio">
                    <input
                      // onChange={(e) => {
                      //   setPropertyFilterType({
                      //     ...propertyfilterType,
                      //     bills_included: e.target.value,
                      //   });
                      // }}
                      type="checkbox"
                      name="l1"
                      id="l1"
                      onChange={(e) => {
                        e?.target?.checked
                          ? setPropertyFilterType({
                            ...propertyfilterType,
                            bills_included: "Yes",
                          })
                          : setPropertyFilterType({
                            ...propertyfilterType,
                            bills_included: "No",
                          });
                      }}
                    />
                    <label htmlFor="l1">Bills Included</label>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="house-radio">
                    <input
                      // onChange={(e) => {
                      //   setPropertyFilterType({
                      //     ...propertyfilterType,
                      //     smokers_allowed: e.target.value,
                      //   });
                      // }}
                      type="checkbox"
                      name="l2"
                      id="l2"
                      onChange={(e) => {
                        e?.target?.checked
                          ? setPropertyFilterType({
                            ...propertyfilterType,
                            smokers_allowed: "Yes",
                          })
                          : setPropertyFilterType({
                            ...propertyfilterType,
                            smokers_allowed: "No",
                          });
                      }}
                    />
                    <label htmlFor="l2">Smokers Allowed</label>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="house-radio">
                    <input
                      type="checkbox"
                      name="l3"
                      id="l3"
                    // onChange={(e) => {
                    //   e?.target?.checked
                    //     ? setPropertyFilterType({
                    //         ...propertyfilterType,
                    //         smokers_allowed: "Yes",
                    //       })
                    //     : setPropertyFilterType({
                    //         ...propertyfilterType,
                    //         smokers_allowed: "No",
                    //       });
                    // }}
                    />
                    <label htmlFor="l3">Pets Allowed</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer ">
              <div
                className="usa-btn"
                style={{
                  marginTop: "0px",
                  marginBottom: "-10px",
                  width: "100%",
                }}
              >
                <Link
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="primary"
                  to="#"
                  onClick={propertyFilter}
                  style={{
                    padding: "13px",
                    width: "130px",
                    backgroundColor: "#00B4BD",
                  }}
                >
                  Apply
                </Link>
              </div>
            </div>
          </div>
          <Modal
            className="custom-dialog"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showModal}
            onHide={handleClose}
          >
            <Modal.Header style={{ padding: "12px", width: "99%" }} closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* Are you sure you want to cancel this Friend Request? */}
              <div class="modal-body" style={{ marginTop: "-37px" }}>
                <ShareSocial
                  style={style}
                  url={ShareData}
                  socialTypes={[
                    "facebook",
                    "twitter",
                    "reddit",
                    "linkedin",
                    "email",
                    "pinterest",
                  ]}
                />
                <CopyToClipboard
                  text={ShareData}
                  onCopy={() => swal("Copied to Clipboard")}
                >
                  <Link to="#" className="copy-icon">
                    <i class="fa-solid fa-copy"></i>
                  </Link>
                </CopyToClipboard>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default DigitalVoucherCard;



// import React, { useContext, useState, useEffect } from 'react'
// import { AppContext } from '../../context/AppContext'
// import { NavLink, useLocation, Link } from 'react-router-dom'
// import thumbImg from '../../assets/images/ofsports.png'
// import back from '../../assets/images/back.svg'
// import ApiConfig from '../../api/ApiConfig'
// import LoaderCenter from '../../sharedComponent/LoaderCenter'
// import {
//   getWithAuthCall,
//   multipartPostCall,
//   simpleGetCallWithErrorResponse,
//   simplePostCall,
// } from '../../api/ApiServices'
// import swal from 'sweetalert'

// const Accordion = ({ title, children }) => {
//   const [isOpen, setOpen] = React.useState(false)
//   return (
//     <div className="accordion-wrapper">
//       <div
//         className={`accordion-title ${isOpen ? 'open' : ''}`}
//         onClick={() => setOpen(!isOpen)}
//       >
//         {title}
//       </div>
//       <div className={`accordion-item ${!isOpen ? 'collapsed' : ''}`}>
//         <div className="accordion-content">{children}</div>
//       </div>
//     </div>
//   )
// }

// const SportsCard = () => {
//   const [categoriesType, setCategoriesType] = useState([])

//   const [brandsType, setBrandsType] = useState([])
//   const { customerData, keyword } = useContext(AppContext)
//   const [loading, setLoading] = useState(false)
//   const [prouctssearch, setProuctsSearch] = useState([])
//   const [products, setProducts] = useState([])
//   const [categoriesFilter, setCategoriesFilter] = useState([])
//   const [BrandsFilter, setBrandsFilter] = useState([])
//   const [filter, setFilter] = useState({
//     min: 0,
//     max: 0,
//   })
//   useEffect(() => {
  
//   }, [filter])
//   const handleFilter = (e, type) => {
   
//     if (type == 'min') setFilter({ ...filter, min: e.target.value })
//     if (type == 'max') setFilter({ ...filter, max: e.target.value })
//   }

//   const getSearchedProducts = (data) => {
//     let matches = []
//     matches = data.filter((product, index) => {
//       if (
//         product &&
//         product.product_name.toLowerCase().includes(keyword.toLowerCase())
//       )
//         return product
//     })

//     setProducts(matches)
//   }

//   useEffect( () => {
//      getProduct()
//   }, [])

//   useEffect(() => {
//     getProduct()
//   }, [keyword])

//   const getProduct = () => {
//     setLoading(true)

//     simpleGetCallWithErrorResponse(
//       ApiConfig.SPORT_PRODUCT,
//       JSON.stringify({ ...customerData }),
//     ).then((res) => {
//       console.log(res)
//       setLoading(false)
//       // setProducts(res.json.sport);
//       if (keyword.length > 0) {
//         getSearchedProducts(res.json.sport)
//       } else {
//         setProducts(res.json.sport)
//       }
//     })
//   }

//   const productSave = async (status, id) => {
//     if (status) {
//       const data = await simplePostCall(
//         ApiConfig.LIKE_DISLIKE_SPORT,
//         JSON.stringify({
//           product_id: id,
//           like_dislike_status: 'like',
//         }),
//       )
//       swal(data.detail)
//       refreshPage()
//     } else {
//       const data = await simplePostCall(
//         ApiConfig.LIKE_DISLIKE_SPORT,
//         JSON.stringify({
//           product_id: id,
//           like_dislike_status: 'dislike',
//         }),
//       )
//       swal(data.detail)
//       refreshPage()
//     }
//   }

//   //// Filter ////

//   const FilterSportsItem = (e) => {
//     setLoading(true)
//     let formData = new FormData()

//     formData.append('category', categoriesFilter)
//     formData.append('brand', BrandsFilter)
//     formData.append('min', filter.min)
//     formData.append('max', filter.max)

//     multipartPostCall(ApiConfig.FILTER_SPORTS, formData)
//       .then((res) => {
//         setLoading(false)
//         swal(res.message)
//         setProducts(res?.Fashion)

//         // getProduct()
//       })
//       .catch((err) => console.log(err))
//     setLoading(false)
//   }

//   useEffect(() => {
//     getCategories()
//     getBrands()
//   }, [])

//   const getCategories = () => {
//     getWithAuthCall(ApiConfig.SPORTS_CATEGORIES_DROPDOWNLIST).then((res) => {
//       console.log('res', res)
//       setCategoriesType(res.categories)
//     })
//   }
//   const getBrands = () => {
//     getWithAuthCall(ApiConfig.SPORTS_BRANDS_DROPDOWNLIST).then((res) => {
//       console.log('res', res)
//       setBrandsType(res.brands)
//     })
//   }

//   function refreshPage() {
//     setTimeout(() => {
//       window.location.reload(false)
//     }, 0)
  
//   }
//   return (
//     <>
//       {loading ? (
//         <LoaderCenter />
//       ) : (
//         <div className="row row-gap-20">
//           {products && products.length > 0 ? (
//             products.map((productsAll, index) => {
            
//               return (
//                 <div className="col-12 col-sm-6  col-lg-4 col-xxl-3">
//                   <div className="gbl_thumbWrapper">
//                     <label className="heart-check">
//                       <input
//                         checked={productsAll.is_like}
//                         onChange={(e) =>
//                           productSave(e.target.checked, productsAll.id)
//                         }
//                         class="check"
//                         type="checkbox"
//                       />
//                       <span class="label-text"></span>
//                     </label>

//                     <div className="img_thumbWrapper">
//                       <img
//                         src={productsAll.image}
//                         height="158px"
//                         alt=""
//                         style={{
//                           objectFit: 'cover',
//                         }}
//                       />
//                     </div>

//                     <div className="gbl_thumbContent">
//                       <h1
//                         style={{
//                           fontSize: '16px',
//                           fontWeight: '500',
//                           color: 'hsla(240, 12%, 13%, 1)',
//                           marginTop: '5px',
//                           letterSpacing: '0',
//                           font: 'normal normal 600 16px/22px Nunito',
//                           opacity: '0px',
//                         }}
//                       >
//                         {productsAll.product_name}
//                       </h1>
//                       <p
//                         style={{
//                           color: 'var(--unnamed-color-707070)',
//                           font: 'normal normal normal 14px/19px Nunito',
//                           letterSpacing: '0px',
//                           color: 'hsla(0, 0%, 44%, 1)',
//                           opacity: '1px',
//                           marginTop: '12px',
//                         }}
//                       >
//                         {productsAll.price}/-
//                       </p>
//                       <Link to={`/office-Sport/${productsAll.id}`}>
//                         <img
//                           style={{
//                             marginTop: '-25px',
//                             float: 'right',
//                           }}
//                           src={back}
//                           alt=""
//                         />
//                       </Link>
//                     </div>
//                   </div>
//                 </div>
//               )
//             })
//           ) : (
//             <p
//               style={{
//                 fontSize: '25px',
//                 margin: 'auto',
//               }}
//               className=" text-center justify-content-center"
//             >
//               Data not found
//             </p>
//           )}
//         </div>
//       )}

//       <div
//         class="modal fade"
//         id="exampleModal"
//         tabindex="-1"
//         aria-labelledby="exampleModalLabel"
//         aria-hidden="true"
//       >
//         <div
//           class="modal-dialog modal-dialog-centered"
//           style={{
//             margin: 'auto',
//             width: '80%',
//             maxWidth: '377px',
//           }}
//         >
//           <div
//             class="modal-content"
//             style={{
//               borderRadius: '20px',
//               backgroundColor: '#F5F5F5',
//               padding: '20px',
//             }}
//           >
//             <p className="pf-heading">Sports, Hobbies & Leisure</p>
//             <div className="pf-body">
//               <p className="pf-label">Choose Product Type</p>
//               <Accordion title="Category">
//                 {categoriesType &&
//                   categoriesType.length &&
//                   categoriesType.map((categoriesTypeItem, index) => {
//                     return (
//                       <div className="pf-option">
//                         <input
//                           type="checkbox"
//                           id={'check1' + index}
//                           name="same"
//                           onChange={(e) => {
//                             if (e.target.checked) {
//                               setCategoriesFilter([
//                                 ...categoriesFilter,
//                                 categoriesTypeItem,
//                               ])
//                             } else {
//                               setCategoriesFilter(
//                                 categoriesFilter.filter(
//                                   (data) => data.id !== categoriesTypeItem.id,
//                                 ),
//                               )
//                             }
//                           }}
//                         />

//                         <label
//                           value={categoriesTypeItem.id}
//                           key={index}
//                           htmlFor={'check1' + index}
//                         >
//                           {categoriesTypeItem}
//                         </label>
//                       </div>
//                     )
//                   })}
//               </Accordion>
//               <Accordion title="Brand">
//                 {brandsType &&
//                   brandsType.length &&
//                   brandsType.map((brandsTypeItem, index) => {
//                     return (
//                       <div className="pf-option">
//                         <input
//                           type="checkbox"
//                           id={'check2' + index}
//                           name="same"
//                           onChange={(e) => {
//                             if (e.target.checked) {
//                               setBrandsFilter([...BrandsFilter, brandsTypeItem])
//                             } else {
//                               setBrandsFilter(
//                                 BrandsFilter.filter(
//                                   (data) => data.id !== brandsTypeItem.id,
//                                 ),
//                               )
//                             }
//                           }}
//                         />

//                         <label
//                           value={brandsTypeItem.id}
//                           key={index}
//                           htmlFor={'check2' + index}
//                         >
//                           {brandsTypeItem}
//                         </label>
//                       </div>
//                     )
//                   })}
//               </Accordion>
//               <Accordion title="Minum Price">
//                 <div className="pf-option">
//                   <input
//                     value={'100'}
//                     type="radio"
//                     id="min1"
//                     name="min"
//                     onChange={(e) => {
//                       handleFilter(e, 'min')
//                     }}
//                   />
//                   <label htmlFor="min1">100</label>
//                 </div>
//                 <div className="pf-option">
//                   <input
//                     value={'400'}
//                     type="radio"
//                     id="min2"
//                     name="min"
//                     onChange={(e) => {
//                       handleFilter(e, 'min')
//                     }}
//                   />
//                   <label htmlFor="min2">400</label>
//                 </div>
//                 <div className="pf-option">
//                   <input
//                     value={'600'}
//                     type="radio"
//                     id="min3"
//                     name="min"
//                     onChange={(e) => {
//                       handleFilter(e, 'min')
//                     }}
//                   />
//                   <label htmlFor="min3">600</label>
//                 </div>
//                 <div className="pf-option">
//                   <input
//                     value={'1000'}
//                     type="radio"
//                     id="min4"
//                     name="min"
//                     onChange={(e) => {
//                       handleFilter(e, 'min')
//                     }}
//                   />
//                   <label htmlFor="min4">1000</label>
//                 </div>
//               </Accordion>
//               <Accordion title="Maximum Price">
//                 <div className="pf-option">
//                   <input
//                     value={'200'}
//                     type="radio"
//                     id="max1"
//                     name="max"
//                     onChange={(e) => {
//                       handleFilter(e, 'max')
//                     }}
//                   />
//                   <label htmlFor="max1">200</label>
//                 </div>
//                 <div className="pf-option">
//                   <input
//                     value={'500'}
//                     type="radio"
//                     id="max2"
//                     name="max"
//                     onChange={(e) => {
//                       handleFilter(e, 'max')
//                     }}
//                   />
//                   <label htmlFor="max2">500</label>
//                 </div>
//                 <div className="pf-option">
//                   <input
//                     value={'900'}
//                     type="radio"
//                     id="max3"
//                     name="max"
//                     onChange={(e) => {
//                       handleFilter(e, 'max')
//                     }}
//                   />
//                   <label htmlFor="max3">900</label>
//                 </div>
//                 <div className="pf-option">
//                   <input
//                     value={'1500'}
//                     type="radio"
//                     id="max4"
//                     name="max"
//                     onChange={(e) => {
//                       handleFilter(e?.target?.checked, 'max')
//                     }}
//                   />
//                   <label htmlFor="max4">1500</label>
//                 </div>
//               </Accordion>

//               {/* <div className="input-cover">
//                 <input
//                   type="text"
//                   name="text"
//                   id="text"
//                   className="pf-search"
//                   placeholder="Search Dining"
//                 />
//                 <img src={search_icon} alt="search" />
//               </div>
//               <div className="search-results">
//                 <div className="search-result d-flex flex-column  flex-sm-row align-items-center ">
//                   <img src={result_icon} alt="icon" />
//                   <span className="mt-2 mt-sm-0">The Kings Restaurant</span>
//                 </div>
//                 <div className="search-result d-flex flex-column  flex-sm-row align-items-center ">
//                   <img src={result_icon} alt="icon" />
//                   <span className="mt-2 mt-sm-0">The Kings Restaurant</span>
//                 </div>
//               </div> */}
//             </div>

//             <div
//               className="text-center"
//               style={{
//                 marginTop: '20px',
//               }}
//             >
//               <button
//                 onClick={FilterSportsItem}
//                 className="ep-action"
//                 data-bs-dismiss="modal"
//                 aria-label="Close"
//                 style={{
//                   marginTop: '0px',
//                 }}
//               >
//                 Apply Filter
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   )
// }

// export default SportsCard

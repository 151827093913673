import React, { useState, useRef,useEffect } from "react";
import { Link,useNavigate,useParams } from "react-router-dom";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faEllipsisV, faShareAlt, faPhone } from "@fortawesome/free-solid-svg-icons";
import './AllPropertyHousing.css';
import swal from "sweetalert";

const ManageCardView = ({ house, index }) => {
  const { id } = useParams();
  const [miniCardVisible, setMiniCardVisible] = useState(false);

  const miniCardRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (miniCardRef.current && !miniCardRef.current.contains(event.target)) {
        setMiniCardVisible(false);
      }
    };
    document.body.addEventListener('click', handleOutsideClick);
    return () => {
      document.body.removeEventListener('click', handleOutsideClick);
    };
  }, []);


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    };
    return date.toLocaleString('en-US', options);
  };

  const toggleMiniCard = (event) => {
    setMiniCardVisible(!miniCardVisible);
    event.preventDefault(); // Prevent default action
    event.stopPropagation(); // Stop event propagation
    // Add your logic for what happens when the heart icon is clicked
    console.log('Heart icon clicked');

  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDeleteClick = () => {
    setIsModalOpen(true);
    setMiniCardVisible(false);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const navigate = useNavigate();

  const handleDeleteConfirm = async () => {
    try {
      const response = await fetch(`${ApiConfig.BASE_NEW_URL}posts/delete/${house._id}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
        swal({
          title: "Deletion unsuccesfull",
          icon: "error",
        });
      }

      await response.json();
      setIsModalOpen(false);
      swal({ title: "Deleted successfully", icon: "success" });
      navigate("/house_listing/all");
    } catch (error) {
      console.error("Failed to delete the post", error);
      swal({
        title: "Error Occurred",
        icon: "error",
      });
    }
  };

  return (
    <div key={index} className="cards-housing" style={{ position: 'relative' }}>
      <Link to={`//${window.location.host}/house_listing/manage/details/${house._id}`}>
        <div style={{ width: "100%", height: "40%", display: "flex" }}>
          {house.images[0] ? (
            <img
              src={house.images[0]}
              alt="pic"
              style={{
                width: "85%",
                objectFit: 'cover',
                height: "135px",
                padding: "5px",
                borderRadius: "10px"
              }}
            />
          ) : (
            <div
              style={{
                backgroundColor: "#E8E9E8",
                width: "85%",
                height: "125px",
                margin: "5px",
                padding: "15px",
                borderRadius: "10px"
              }}
            ></div>
          )}

          <div style={{ width: "40%", paddingRight: "5px" }}>
            {house.images[1] ? (
              <img
                src={house.images[1]}
                alt="pic"
                style={{
                  width: "100%",
                  objectFit: 'cover',
                  height: "60px",
                  marginTop: "5px",
                  borderRadius: "5px"
                }}
              />
            ) : (
              <div
                style={{
                  backgroundColor: "#E8E9E8",
                  width: "100%",
                  height: "60px",
                  margin: '5px 5px 5px 0',
                  paddingTop: "5px",
                  borderRadius: "5px"
                }}
              ></div>
            )}

            {house.images[2] ? (
              <img
                src={house.images[2]}
                alt="pic"
                style={{
                  width: "100%",
                  objectFit: 'cover',
                  height: "60px",
                  marginTop: "5px",
                  borderRadius: "5px"
                }}
              />
            ) : (
              <div
                style={{
                  backgroundColor: "#E8E9E8",
                  width: "100%",
                  height: "60px",
                  margin: '5px 5px 5px 0',
                  paddingTop: "5px",
                  borderRadius: "5px"
                }}
              ></div>
            )}
          </div>
        </div>

        <div style={{ padding: "20px", marginTop: "15px" }}>
          <p style={{ fontSize: '12px', marginTop: "5px" }}>
            posted date: {formatDate(house.postedDate)}
          </p>
          <h6 style={{ marginTop: "5px" }}>{house.title}</h6>
          <div style={{ display: "flex", marginTop: "8px", width: "100%" }}>
            {/* <div style={{ display: "flex" }}>
              <div>
                <FontAwesomeIcon icon={faBuilding} style={{ color: "red" }} />
              </div>
              <div style={{ marginLeft: "10px", marginTop: "3px" }}>
                <h6 > {house.housingDetails.propertyType}</h6>
              </div>
            </div> */}
          </div>
        </div>
      </Link>
      <div className="buttonInterestedContainer">
  <button className="buttonInterested">
    <h6 style={{ fontSize: "12px", color: 'white' }}>Interested users</h6>
  </button>
</div>



      <div style={{ position: 'absolute', bottom: '20px', right: '10px' ,width:'15px',height:'15px',}}>
        <FontAwesomeIcon icon={faEllipsisV} onClick={toggleMiniCard} style={{ cursor: 'pointer' }} />
      </div>
      
{/* Mini Card Options */}
      {miniCardVisible && (
    <div
    ref={miniCardRef}
    style={{
      width:"90px",
      position: 'absolute',
      bottom: '40px',
      right: '10px',
      backgroundColor: 'white',
      border: '1px solid #ddd',
      borderRadius: '5px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      zIndex: 1000,
    }}
  >
    <button style={{ display: 'flex', background: 'none', border: 'none', cursor: 'pointer',paddingTop:0, marginBottom:0,marginTop:'3px' }}>
      {/* <FontAwesomeIcon icon={faShareAlt} style={{ marginRight: '5px' }} /> */}
      <Link to={`/house_listing/edit/${house._id}`}>
        Edit
      </Link>
    </button>
    <button style={{ display: 'flex', background: 'none', border: 'none', cursor: 'pointer',paddingTop:0, marginBottom:0,marginTop:'1px'}}>
      {/* <FontAwesomeIcon icon={faPhone} style={{ marginRight: '5px' }} /> */}
      <Link to="#">
        Share
      </Link>
    </button>
    <button
      style={{
        display:"flex",
        paddingTop:0,marginTop:'3px',
        marginBottom:"5px",
        marginLeft:"5px",
        backgroundColor: '#f44336',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        alignItems: 'center'
        // transition: 'backgroundColor 0.3s ease', // Uncomment for transition effect
      }}
      // className="btns toolbar"
      onClick={handleDeleteClick}
    >
      Delete
    </button>
  </div>
  
      
      )}
      {/* Modal */}
      {isModalOpen && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex:10000,
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "10px",
              width: "350px",
              height: "150px",
              textAlign: "center",
            }}
          >
            <h4 style={{ marginBottom: "20px" }}>Delete this post?</h4>
            <button
              onClick={handleModalClose}
              style={{
                padding: "10px 20px",
                marginTop: "10px",
                marginRight: "10px",
                backgroundColor: "#ccc",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Cancel
            </button>
            <button 
              onClick={handleDeleteConfirm}
              style={{
                padding: "10px 20px",
                backgroundColor: "#f44336",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Delete
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageCardView;

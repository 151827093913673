import React, { useContext, useState, useEffect } from 'react'
import { AppContext } from '../../context/AppContext'
import Sidebar from '../../components/Sidebar'
import ChatBody from './ChatBody'
import ChatSidebar from './ChatSidebar'
import openSocket from 'socket.io-client'

function Groups() {
  const { sidebar, setSidebar } = useContext(AppContext)
  const [showElement, setShowElement] = React.useState(true)
  


  useEffect(() => {
    setTimeout(function () {
      setShowElement(false)
    }, 5000)
  }, [])

  /// Chat ////



  return (
    <>
      <Sidebar />

      {/* <Header /> */}
      <main id="cxMain" className={sidebar ? 'cx-main cx-active' : 'cx-main'}>
        <section className="content-main">
          <div className="row chat_mobWrapper">
            <div className="col-lg-12 col-md-4">
              <ChatSidebar />
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default Groups

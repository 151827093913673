import React, { useEffect, useState, useContext } from "react";
import login_logo from "../assets/images/login_logo.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import email_icon from "../assets/images/email_icon.svg";
import { AppContext } from "../context/AppContext";
import swal from "sweetalert";

import lock_icon from "../assets/images/lock_icon.svg";
import facebook_icon from "../assets/images/facebook_icon.svg";
import gmail_icon from "../assets/images/gmail_icon.svg";
import login_logo_lottie from "../assets/images/login_logo_lottie";
import image from "../assets/images/login_banner.png";
import Barloader from "./BarLoader";

import {
  postWithAuthCallWithErrorResponse,
  simplePostCall,
  simplePostCallAll,
} from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";

function ConfirmMail() {
  let ForgotData = useLocation().state;
  const { customerData } = useContext(AppContext);

  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  console.log(loading);
  const [state, setState] = useState({ ...ForgotData, email: "" });
  const [errMsg, setErrMsg] = useState({ email: "" });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: login_logo_lottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (state.email === "") {
      setErrMsg({ ...errMsg, email: "Enter Email ID " });
      return;
    } else {
      setLoading(true);
      simplePostCallAll(
        ApiConfig.UNIVERSR_FORGET_EMAIL,
        JSON.stringify({ ...state })
      )
        .then((data) => {
          setLoading(false);

       
          if (data.detail == "OTP sent on your email.") {
            navigate("/new", { state: { ...state } });
            swal(data.detail);
          } else {
            swal(data.detail);
          }
        })
        .catch((err) => {
          setLoading(false);
          // swal("User Does not Exist! or Please Check Credentials");
          console.log(err);
        });
    }
  };
  return (
    <>
      <div
        className="row m-0"
        style={{
          padding: "0px",
        }}
      >
        <div
          className="col-12 col-xl-8 banner-img p-0"
          style={{
            position: "relative",
          }}
        >
          {loading && <Barloader />}
          <img
            src={image}
            alt=""
            style={{
              height: "100%",
              width: "100%",
              objectFit: "cover",
              borderRadius: "20px",
            }}
          />
          <div className="tag_line d-flex flex-column align-items-center align-items-xl-start flex-xl-row w-100">
            <img
              src={login_logo}
              alt=""
              style={{
                height: "80px",
                width: "40px",
                objectFit: "cover",
              }}
            />
            <span
              style={{
                color: "#FFFFFF",
                fontSize: "30px",
                fontWeight: "normal",
              }}
            >
              We use tech to create,
              <br />
              connect, and grow
            </span>
          </div>
        </div>
        <div className="col-12 col-xl-4">
          <div
            className="d-flex flex-column align-items-center align-items-xl-start"
            style={{
              padding: " 80px 30px 0",
            }}
          >
            <h3
              style={{
                color: "#1D1D25",
                fontSize: "30px",
                fontWeight: "bold",
              }}
            >
              Show us it's you!
            </h3>
            <p
              style={{
                color: "#707070",
                fontSize: "14px",
                fontWeight: "normal",
              }}
            >
              Please verify your email to continue
            </p>
            <form>
              <div
                className="conf-div"
                style={{
                  marginTop: "30px",
                }}
              >
                <input
                  className="confirm-input"
                  type="email"
                  name="email"
                  id="e"
                  onChange={(e) => {
                    setErrMsg({ ...errMsg, email: "" });
                    setState({ ...state, email: e.target.value });
                  }}
                  placeholder="Email Address"
                  style={{
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #E1E1E1",
                    borderRadius: "25px",
                    height: "50px",
                    padding: "0 50px 0 20px",
                    width: "100%",
                    minWidth: "338px",
                  }}
                />

                <img src={email_icon} alt="" />
              </div>
              <small
                style={{
                  color: "red",
                }}
              >
                {errMsg.email ? "Please Enter Mail" : <>&nbsp;</>}
              </small>

              <div className="row">
                <div className="col-lg-12 ">
                  <div
                    className="d-flex justify-content-center w-100"
                    style={{
                      marginTop: "30px",
                    }}
                  >
                    <button
                      type="submit"
                      className="continue-button"
                      style={{
                        backgroundColor: "#00B4BD",
                        width: "228px",
                        height: "50px",
                        borderRadius: "25px",

                        border: "none",
                        color: "#FFFFFF",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                      onClick={handleSubmit}
                    >
                      <Link
                        to="/new"
                        style={{
                          color: "white",
                          display: "inline-block",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Continue
                      </Link>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfirmMail;

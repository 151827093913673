import React, { useContext, useState, useEffect } from 'react'
import { Link, useLocation, NavLink } from 'react-router-dom'
import { AppContext } from '../../context/AppContext'
import Sidebar from '../../components/Sidebar'
import Header from '../../components/Header'
import thumbImg from '../../assets/images/house01.png'
import location_icon from '../../assets/images/location_icon.svg'
import gray_location_icon from '../../assets/images/gray_location_icon.svg'
import green_arrow_icon from '../../assets/images/green_arrow_icon.svg'
import fillter_icon from '../../assets/images/fillter_icon.svg'
import Tabs from './Tabs'
import LoaderCenter from '../../sharedComponent/LoaderCenter'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import swal from 'sweetalert'


import {
  simpleGetCallWithErrorResponse,
  simplePostCall,
} from '../../api/ApiServices'
import ApiConfig from '../../api/ApiConfig'
import HealthCard from './HealthCard'

const VoucherHealth = () => {
  const { customerData } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const [healthList, setHealthList] = useState([])

  //  const [EventDetalis, setEventDetalis] = useState([]);
  // const url = `https://www.npmjs.com/package/react-google-autocomplete`;
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  useEffect(() => {
    getHealthlist()
  }, [])

  const getHealthlist = () => {
    setLoading(true)
    let url = simpleGetCallWithErrorResponse(
      ApiConfig.HEALTHVOUCHER_LIST,

      JSON.stringify({ ...customerData }),
    ).then((res) => {
      console.log(res)
      setLoading(false)
      setHealthList(res.json.helth)
    })
  }

  return (
    <>
      {loading ? (
        <LoaderCenter />
      ) : (
        <div
          className="row"
          style={{
            rowGap: '20px',
          }}
        >
          {healthList && healthList.length > 0 ? (
            healthList.map((healthListAll, index) => {
             
              return (
                <div className="col-12 col-lg-6 col-xl-4 col-xxl-3">
                  <div
                    className="offersThumbWrapper"
                    style={{
                      height: '249px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      margin: '0',
                    }}
                  >
                    <h1>{healthListAll.offer_name}</h1>
                    <div className="couponWrapper">
                      <div className="codeTitle">
                        <h2>CODE</h2>
                      </div>
                      <div className="codeapplied d-flex justify-content-between">
                        <h1>{healthListAll.code}</h1>
                        <CopyToClipboard
                          
                          text={healthListAll.code}
                          onCopy={() => swal("Copied to Clipboard")}
                        >
                          <Link to="#">
                            <i class="fa-solid fa-copy"></i>
                          </Link>
                        </CopyToClipboard>
                      </div>
                    </div>
                    <div className="swaipWrapper text-truncate">
                      <Link
                        className="content col-8 "
                        to=""
                        style={{
                          padding: '0 10px',
                          // width: "20px",
                        }}
                      >
                        {healthListAll.link}
                      </Link>

                      <img
                        style={{
                          cursor: 'pointer',
                        }}
                        onClick={() => openInNewTab(healthListAll.link)}
                        src={green_arrow_icon}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              )
            })
          ) : (
            <p
              style={{
                fontSize: '25px',
                margin: 'auto',
              }}
              className=" text-center justify-content-center"
            >
              Data not found
            </p>
          )}
        </div>
      )}
    </>
  )
}

export default VoucherHealth

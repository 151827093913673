import React, { useContext, useState, useEffect } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import search_icon from "../../assets/images/search_icon.svg";

import eventthumbImg01 from "../../assets/images/eventThumb01.png";
import eventthumbImg02 from "../../assets/images/eventThumb02.png";
import eventthumbImg03 from "../../assets/images/eventThumb03.png";
import location_icon from "../../assets/images/location_icon.svg";
import gray_location_icon from "../../assets/images/gray_location_icon.svg";

function EventsList() {
  const { sidebar, setSidebar, customerData } = useContext(AppContext);

  const [showElement, setShowElement] = React.useState(true);
  useEffect(() => {
    setTimeout(function () {
      setShowElement(false);
    }, 5000);
  }, []);
  return (
    <>
      <Sidebar />

      {/* <Header /> */}
      <main id="cxMain" className={sidebar ? "cx-main cx-active" : "cx-main"}>
        <section className="content-main" style={{}}>
          <div className="row">
            <div className="col-md-12">
              <div
                className="row"
                style={{
                  overflow: "auto",
                }}
              >
                <div className="col-md-12">
                  <div className="globalPages_tabWrapper">
                    <div className="gb_tabs">
                      <div className="tabs">
                        <div>
                          <NavLink
                            to="/events/all"
                            className={({ isActive }) =>
                              isActive ? "nav-active" : "nav-inactive"
                            }
                          >
                            All Events
                          </NavLink>
                        </div>
                        <div>
                          <NavLink
                            to="/events/hosting"
                            className={({ isActive }) =>
                              isActive ? "nav-active" : "nav-inactive"
                            }
                          >
                            Hosting
                          </NavLink>
                        </div>

                        <div>
                          <NavLink
                            to="/events/myevent"
                            className={({ isActive }) =>
                              isActive ? "nav-active" : "nav-inactive"
                            }
                          >
                            My Events
                          </NavLink>
                        </div>

                        <div>
                          <NavLink
                            to="/events/saved"
                            className={({ isActive }) =>
                              isActive ? "nav-active" : "nav-inactive"
                            }
                          >
                            Saved
                          </NavLink>
                        </div>
             
                      </div>
              
                      <div className="create-location">
                        {/* <div
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModalset"
                        >
                          <Link to="#" className="location-button">
                            <p>
                              <span
                                className="content"
                                style={{
                                  marginLeft: "8px",
                                  width: "fit-content",
                                  maxWidth: "100px",
                                  textAlign: "start",
                                }}
                              >
                                {customerData.location}{" "}
                              </span>
                              <span className="dot mx-1"></span>
                              <span>{customerData.radius} Miles </span>
                            </p>
                          </Link>
                        </div> */}
                        <div
          className="forumsSearchWrapperss"
          style={{
            marginLeft:'5px',
            width: "233px",
            height: "40px",
            borderRadius: "25px",
            position: "relative",
            border: "1px solid #E1E1E1",
            backgroundColor: "white",
          }}
        >
             <input
            type="text"
            id="hif"
            placeholder="Search property"
            style={{
              paddingLeft:'5px',
              border:0,
              display: "block",
              height: "100%",
              width: "100%",
              backgroundColor: "transparent",
              color: "#BBBBBB",
              fontWeight: "600",
              fontSize: "16px",
            }}
          /> 
          <button style={{ backgroundColor: "transparent", position: "absolute",top:'1px', right: "5px" }}>
            <img src={search_icon} alt="" />
          </button>
        </div>
                        <div>
                          <Link
                            className="create-event-button"
                            to="/create_event"
                          >
                            Create Event
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="card-container"
                style={{
                  width: "fit-content",
                  background: "white",
                  padding: "20px",
                  borderRadius: "20px",
                  marginTop: "20px",
                  height: "fit-content",
                  maxHeight: "calc(100vh - 216px)",
                  minHeight: "calc(100vh - 216px)",
                  width: "100%",
                  overflow: "auto",
                }}
              >
                <Outlet />
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default EventsList;

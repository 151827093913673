import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from '../../context/AppContext'
import like from '../../assets/images/like.png'
import ApiConfig from '../../api/ApiConfig'
import eventthumbImg01 from '../../assets/images/eventThumb01.png'

import swal from 'sweetalert'
import {
  deleteWithAuthCall,
  simpleGetCallWithErrorResponse,
  simplePostCall,
} from '../../api/ApiServices'
import LoaderCenter from '../../sharedComponent/LoaderCenter'
import { ShareSocial } from 'react-share-social'
import { Modal, Button } from 'react-bootstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard'


const style = {
  background: '#F5F5F5',
  borderRadius: 3,
  border: 0,
  color: 'white',
  padding: '0 0px',
  // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  height: '200px',
  marginTop: '20px',
  // width: '20px',
}

function SavedEvents() {
  const [showElement, setShowElement] = React.useState(true)
  const { customerData } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const [EventSave, setEventSave] = useState([])
  const [showModal, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    setTimeout(function () {
      setShowElement(false)
    }, 5000)
  }, [])

  useEffect(() => {
    getSaveEvent()
  }, [])

  const getSaveEvent = () => {
    setLoading(true)

    simpleGetCallWithErrorResponse(
      ApiConfig.UNIVERSR_EVENT_SAVE,
      JSON.stringify({ ...customerData }),
    ).then((res) => {
      console.log(res)
      setLoading(false)
      setEventSave(res.json.list)
    })
  }
  const buyEvent = (id) => {

    simplePostCall(
      ApiConfig.UNIVERSR_EVENT_LIST_BUY,
      JSON.stringify({ event_id: id }),
    )
      .then((res) => {
        console.log(res)

        swal(res.message)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const cancelEvent = (id) => {
    console.log(id)
    simplePostCall(
      ApiConfig.UNIVERSR_CANCEL_EVENT,
      JSON.stringify({ event_id: id }),
    )
      .then((res) => {
        console.log(res)

        swal(res.message)
        getSaveEvent()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const UnsaveEvent = (id) => {
    deleteWithAuthCall(ApiConfig.DELETE_SAVE_EVENTS + id)
      // JSON.stringify({ event_id: id })

      .then((res) => {
        console.log(res)
        swal(res.detail)
        getSaveEvent()
      })
      .catch((err) => {
        setLoading(false)

        console.log(err)
      })
  }

  return (
    <>
      {loading ? (
        <LoaderCenter />
      ) : (
        <div className="row">
          <Modal
            className="custom-dialog"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showModal}
            onHide={handleClose}
          >
            <Modal.Header style={{ padding: '12px', width: '99%' }} closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* Are you sure you want to cancel this Friend Request? */}
              <div class="modal-body" style={{ marginTop: '-37px' }}>
                <ShareSocial
                  style={style}
                  url={`https://my-univrs.app.redbytes.in/events/saved`}
                  socialTypes={[
                    'facebook',
                    'twitter',
                    'reddit',
                    'linkedin',
                    'email',
                    'pinterest',
                  ]}
                />
                <CopyToClipboard
                  // text={`http://my-univrs.app.redbytes.in/house_listing/manage/${id}`}
                  text={`https://my-univrs.app.redbytes.in/events/saved`}
                  onCopy={() => swal("Copied to Clipboard")}
                >
                  <Link to="#" className="copy-icon">
                    <i class="fa-solid fa-copy"></i>
                  </Link>
                </CopyToClipboard>
              </div>
            </Modal.Body>
          </Modal>
          {EventSave && EventSave.length > 0 ? (
            EventSave.map((EventSave, index) => {
              console.log(EventSave.event.is_canceled)
              return (
                <>
                  <div className="col-12 col-lg-6 col-xxl-3 col-xl-4 my-3">
                    <div
                      className={
                        EventSave.event.is_canceled === true
                          ? 'gbl_thumbWrapper cancled-details'
                          : 'gbl_thumbWrapper'
                      }
                    >
                      <div className="img_thumbWrapper">
                        <button
                          class="eventHeart"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            onClick={() => {
                              UnsaveEvent(EventSave.id)
                            }}
                            src={like}
                            alt="saved"
                            style={{ width: '18px', height: '16px' }}
                          />
                        </button>
                        <div class="dropdown">
                          <button
                            class="eventMenu"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i class="fa-solid fa-ellipsis-vertical"></i>
                          </button>

                          <ul
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <Link
                                onClick={() => {
                                  cancelEvent(EventSave.event.id)
                                }}
                                to="#"
                              >
                                Cancel
                              </Link>
                            </li>
                            {/* <li>
                              <Link onClick={handleShow} to={'#'}>
                                Share
                              </Link>{' '}
                            </li> */}
                          </ul>
                        </div>
                        <img
                          src={EventSave.event.image}
                          alt=""
                          className="event-thumbnail"
                        />
                      </div>

                      <div className="event-details">
                        <h1 className="event-title">{EventSave.event.name}</h1>
                        <h2 className="event-price">
                          {EventSave.event.ticket_price}
                        </h2>
                        <h4 className="event-address content">
                          {EventSave.event.address}
                        </h4>
                        <div className="event-action-wrapper d-flex flex-column align-items-start align-items-sm-end flex-sm-row">
                          <p className="event-time">
                            {EventSave.event.start_time} -{' '}
                            {EventSave.event.end_time}
                          </p>
                          <Link
                            to="#"
                            onClick={() => {
                              buyEvent(EventSave.event.id)
                            }}
                            className={
                              EventSave.event.is_canceled === true
                                ? 'mt-2  mt-sm-0 cancle-button'
                                : 'mt-2  mt-sm-0'
                            }
                          >
                            {EventSave.event.is_canceled === true
                              ? 'Cancelled'
                              : EventSave.event.is_bought === 'bought'
                                ? 'Bought'
                                : 'Buy Ticket'}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-12 col-lg-6 col-xxl-3 col-xl-4 ">
                    <div className="gbl_thumbWrapper cancled-details">
                      <div className="img_thumbWrapper">
                        <button
                          class="eventHeart"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            src={like}
                            alt="saved"
                            style={{ width: '18px', height: '16px' }}
                          />
                        </button>
                        <div class="dropdown">
                          <button
                            class="eventMenu"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i class="fa-solid fa-ellipsis-vertical"></i>
                          </button>
                          <ul
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <Link
                                to="/event_details"
                                class="dropdown-item"
                                href="#"
                              >
                                View Details
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <img
                          src={eventthumbImg01}
                          alt=""
                          className="event-thumbnail"
                        />
                      </div>

                      <div className="event-details ">
                        <h1 className="event-title">3BHK Residential House</h1>
                        <h2 className="event-price">500 /-</h2>
                        <h4 className="event-address">Phoenix Market City</h4>
                        <div className="event-action-wrapper d-flex flex-column align-items-start align-items-sm-end flex-sm-row">
                          <p className="event-time ">10:00 - 20:30</p>
                          <Link to="#" className="mt-2  mt-sm-0 cancle-button">
                            Cancelled
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </>
              )
            })
          ) : (
            <p
              style={{
                fontSize: '20px',
                marginTop: '200px',
              }}
              className=" text-center justify-content-center align-items-center"
            >
              Oops! It doesn't look like there are any Event in your area.
              <br /> Why don't you try expanding your search criteria?
            </p>
          )}
        </div>
      )}
    </>
  )
}

export default SavedEvents
